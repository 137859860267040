import { Farm } from '@models/Farm'
import {
  ChatMessage,
  ChatSession,
  isAccountParticipant,
  isUserParticipant,
  MessageParticipant,
  ParticipantType,
  SendMessageProps,
} from '@models/Messaging'
import { User } from '@models/User'
import { DateTime } from 'luxon'
import { haveSameItems } from './helpers'
import { AccessRight, hasFarmAdminPermission, Permission } from './Permission'
import { isSameDay } from './time'
import { pick, values } from './typescript'
import { userName } from './user'

/** Default limit for messages. Used on pagination on chat messages */
export const DEFAULT_MSG_LIMIT = 10
export const DEFAULT_CONV_LIMIT = 10

/**
 * Finds and returns the participant data for the current user in a chat session.
 *
 * @param participants - Object containing all participants in the chat session
 * @param currUserId - ID of the current user/participant to find
 */
export const getCurrentParticipant = (participants: ChatSession['participants'], currUserId: string) => {
  if (!participants || !currUserId) {
    return undefined
  }
  const participantsList = values(participants)

  // First try to find direct user participant match
  const userParticipant = participantsList.find(
    (participant) => !isAccountParticipant(participant) && participant.user.id === currUserId,
  )
  if (userParticipant) {
    return userParticipant
  }

  // Then look for farm participant where user is a manager
  const farmParticipant = participantsList.find(
    (participant) =>
      isAccountParticipant(participant) &&
      (participant.account.managers ?? []).some((manager) => manager.user.id === currUserId),
  )

  return farmParticipant
}

/**
 * Finds and returns the participant data for the other participant in a chat session.
 * In a chat between a user and a farm, if the current user is the customer, it returns the farm participant,
 * and if the current user is a farm manager, it returns the customer participant.
 */
export const getOtherParticipant = (participants: ChatSession['participants'], currentUserId: string) => {
  if (!participants || !currentUserId) {
    return undefined
  }

  const participantsList = values(participants)

  // Find current participant first
  const currentParticipant = getCurrentParticipant(participants, currentUserId)
  if (!currentParticipant) {
    return undefined
  }

  // Return the other participant
  return participantsList.find((participant) => participant.participantId !== currentParticipant.participantId)
}

/** Gets the text that should be displayed
 * - Only text is supported for now
 */
export const getMessageText = (item: Pick<ChatMessage, 'content'>): string => {
  if (item.content.type === 'text') {
    return item.content.content
  }

  return ''
}

/** Filters conversations by a text value
 * - If the other participant is a user, checks for the user's firstName and lastName
 * - If the other participant is a farm, checks for the farm's name
 */
export const filterChatsBySearchValue = (chat: Pick<ChatSession, 'participants'>, searchValue: string): boolean => {
  if (!searchValue.length) return true

  const participantsNames = values(chat.participants).map((participant) =>
    isAccountParticipant(participant) ? participant.account.name : userName(participant.user),
  )

  return participantsNames.some((partName) => partName.toLowerCase().includes(searchValue.toLowerCase()))
}

/**
 * Returns a string that represents the relative time difference between a given date and a reference date,
 * such as "2 days ago". The smallest unit of time displayed is minutes.
 * @param value target date to get the relative terms
 * @param base the reference date to compare with. Defaults to current date if not provided
 */
export const getRelativeTimeString = (value: DateTime, base?: DateTime) => {
  const result = value.toRelative({ base })
  // Do not show seconds
  if (result.includes('second')) return 'just now'

  return result
}

export type MessagesWithDate = ChatMessage & { shouldShowDate?: boolean }

/**
 * Processes a list of chat messages to determine which messages should display their date.
 * The date is shown for:
 * 1. The first message in the list.
 * 2. Messages sent on a different day from the next message.
 *
 * The list of messages is expected to be sorted in descending order by date (most recent first).
 *
 * @param data - An array of chat messages, each containing a `sentAt` date.
 * @returns A new array of chat messages where some messages are flagged to display their date.
 */
export const flagMessagesWithDates = (data: ChatMessage[]): MessagesWithDate[] => {
  const itemsCopy = [...data].reverse()

  return itemsCopy.map((el, idx) => {
    // Show the date for the last message
    if (idx === 0) return { ...el, shouldShowDate: true }

    // Show the date if the next message is from a different day
    if (!isSameDay(el.sentAtUtc.toLocal(), itemsCopy[idx - 1].sentAtUtc.toLocal())) {
      return { ...el, shouldShowDate: true }
    }

    return el
  })
}

/**
 * Creates the participant IDs for a farm participant.
 * This includes the IDs the managers with Admin / Manager role associated with the farm. */
export const createFarmParticipantIds = (participant: Pick<Farm, 'managers'>): ChatSession['participantIds'] => {
  return (participant.managers ?? [])
    .filter((manager) =>
      hasFarmAdminPermission(
        { role: manager.farmAssociation.role, permissions: manager.farmAssociation.customPermissions },
        Permission.Messaging,
        AccessRight.Edit,
      ),
    )
    .map((manager) => manager.user.id)
}

/** Create the participant ID for a user participant.
 * @returns An array containing the user's ID.
 */
export const createUserParticipantIds = (participant: Pick<User, 'id'>): ChatSession['participantIds'] => {
  return [participant.id]
}

/**
 * Creates a record of farm participant information.
 * @returns A record mapping the farm ID to its participant details.
 */
export const createFarmParticipantInfo = (
  participant: Pick<Farm, 'id' | 'name' | 'logo' | 'managers'>,
): ChatSession['participants'] => {
  return {
    [participant.id]: {
      participantId: participant.id,
      account: pick(participant, 'id', 'name', 'logo', 'managers'),
      type: ParticipantType.farm,
      hasUnreadMessage: false,
    },
  }
}

/**
 * Creates a record of user participant information.
 * @returns A record mapping the user ID to their participant details.
 */
export const createUserParticipantInfo = (
  participant: Pick<User, 'id' | 'name' | 'avatar'>,
): ChatSession['participants'] => {
  return {
    [participant.id]: {
      participantId: participant.id,
      user: pick(participant, 'id', 'name', 'avatar'),
      type: ParticipantType.user,
      hasUnreadMessage: false,
    },
  }
}

/** Adapter that creates participants data for sending a message */
export const createParticipantsData = (
  chatSession: ChatSession,
  senderId: string,
  user: User,
): Pick<SendMessageProps, 'from' | 'to'> | undefined => {
  const other = getOtherParticipant(chatSession?.participants, senderId)
  const current = getCurrentParticipant(chatSession.participants, senderId)

  if (!other || !current) return undefined

  return {
    to: { id: other.participantId, type: other.type },
    from: {
      id: current.participantId,
      sender: { id: user.id, name: user.name },
      type: current.type,
    },
  }
}

/**
 * Gets the trimmed & limited text message.
 * - Will remove empty lines and replace new lines with spaces.
 * - Trims the message to a specified limit and appends '...' if truncated.
 */
export const getCardTrimmedMessage = (chatMsg: Pick<ChatMessage, 'content'>, limit = 50) => {
  const msgRaw = getMessageText(chatMsg)

  // Remove empty lines and replace newlines with a space
  const messageValue = msgRaw
    .replace(/^\s*[\r\n]+/gm, '')
    .replace(/\n+/g, ' ')
    .trim()

  if (messageValue.length > limit) {
    return messageValue.slice(0, limit).trim() + '...'
  }

  return messageValue
}

/** Adapter that formats different participants data to a single interface */
export const getAdaptedParticipantInfo = (participant: ChatSession['participants'][string]) => {
  if (isAccountParticipant(participant)) {
    return {
      logo: participant.account.logo,
      name: participant.account.name,
    }
  }

  return {
    logo: participant.user.avatar,
    name: userName(participant.user),
  }
}

type ParticipantsCommonInfo = {
  other?: {
    logo: string | undefined
    name: string
  }
  own?: { logo: string | undefined; name: string }
}

/** Returns the adapted props (`logo`, `name`) for all participants.*/
export const getParticipantsCommonInfo = (
  participants: ChatSession['participants'],
  currentUserId: string,
): ParticipantsCommonInfo => {
  const result: ParticipantsCommonInfo = {}

  const otherParticipant = getOtherParticipant(participants, currentUserId)
  if (otherParticipant) {
    result.other = getAdaptedParticipantInfo(otherParticipant)
  }

  const currParticipant = getCurrentParticipant(participants, currentUserId)
  if (currParticipant) {
    result.own = getAdaptedParticipantInfo(currParticipant)
  }

  return result
}

/**
 * Determines the IDs of participants who should have the chat session marked as unread based on the sender type.
 * - If the sender is a farm, only the user should be marked as unread
 * - If the sender is a user, all farm admins should be marked as unread
 */
export const getUnreadIds = (
  from: MessageParticipant,
  to: MessageParticipant,
  chatSession: Pick<ChatSession, 'participantIds'>,
): string[] => {
  if (from.type === ParticipantType.farm) {
    return [to.id]
  } else {
    return chatSession.participantIds.filter((partId) => partId !== from.id)
  }
}

/**
 * Determines and returns the updated list of unread participant IDs based on the current and other participants in a chat session
 * @returns An array of updated unread participant IDs, or `undefined` if the unread IDs did not change.
 */
export const getUpdatedUnreadIds = (chatSession: ChatSession, senderId: string): string[] | undefined => {
  const current = getCurrentParticipant(chatSession.participants, senderId)
  const other = getOtherParticipant(chatSession.participants, senderId)

  if (!current || !other) throw Error('Data not found')

  let updatedIds: string[] = []

  if (current?.type === ParticipantType.farm) {
    updatedIds = chatSession.unreadIds.includes(other.participantId) ? [other.participantId] : []
  } else if (current.type === ParticipantType.user) {
    updatedIds = chatSession.unreadIds.filter((id) => id !== current.participantId)
  }

  // Return undefined if data did not change
  if (haveSameItems(updatedIds, chatSession.unreadIds)) {
    return undefined
  }

  return updatedIds
}

/**
 * Gets the appropriate logo/avatar for a chat message based on the sender and participant types.
 */
export const getMessageLogo = (chat: ChatSession | undefined, message: ChatMessage, currUserId: string) => {
  if (!chat) return undefined

  const current = getCurrentParticipant(chat.participants, currUserId)
  const other = getOtherParticipant(chat.participants, currUserId)

  const senderId = message.sender.id

  if (!current || !other) return undefined

  // If the message owner is the current user
  if (isUserParticipant(current) && current.user.id === senderId) {
    return current.user.avatar
  }

  // If the sender is the other user and is a user participant
  if (isUserParticipant(other) && other.user.id === senderId) {
    return other.user.avatar
  }

  // If current is farm participant and the sender is a farm admin of this farm
  if (isAccountParticipant(current) && current.account.managers?.some((man) => man.user.id === senderId)) {
    return current.account.logo
  }

  // If other is farm participant and the sender is a farm admin of that farm
  if (isAccountParticipant(other) && other.account.managers?.some((man) => man.user.id === senderId)) {
    return other.account.logo
  }

  // If the message owner is not the current user nor a farm admin
  if (isAccountParticipant(other)) {
    return other.account.logo
  }
  return other.user.avatar
}
