import { createStackNavigator } from '@react-navigation/stack'
import FarmGenAdminUserLogin from '@screens/FarmGenAdmin/FarmGenAdminUserLogin'

import { AnalyticsAdmin } from '../screens/FarmGenAdmin/AnalyticsAdmin'
import { FarmGenAdminPanel as FarmGenAdminScreen } from '../screens/FarmGenAdmin/FarmGenAdminPanel'
import { FarmGenAdminParamList } from './types'

const FarmGenAdminStack = createStackNavigator<FarmGenAdminParamList>()

/** The navigator for the FarmGenAdmin screens. */
export function FarmGenAdminNavigator() {
  return (
    <FarmGenAdminStack.Navigator screenOptions={{ headerShown: false }}>
      <FarmGenAdminStack.Screen name="FarmGenAdmin" component={FarmGenAdminScreen} />
      <FarmGenAdminStack.Screen name="AnalyticsAdmin" component={AnalyticsAdmin} />
      <FarmGenAdminStack.Screen name="FarmGenAdminUserLogin" component={FarmGenAdminUserLogin} />
    </FarmGenAdminStack.Navigator>
  )
}
