import { Alert } from '@elements'
import { StackNavigationProp } from '@react-navigation/stack'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useValidateCartAlert } from '@/hooks/useValidateCart'
import { ShoppingStackParamList } from '@/navigation/types'
import { setNavProps } from '@/redux/actions/appState'
import { wholesaleSelector } from '@/redux/selectors'
import { useNavigation } from '@react-navigation/native'

/** Custom hook to handle cart validation and navigation to checkout. */
export function useCartValidation() {
  const navigation = useNavigation<StackNavigationProp<ShoppingStackParamList>>()
  const dispatch = useDispatch()
  const { isWholesale } = useSelector(wholesaleSelector)
  const validateCartAlert = useValidateCartAlert({ cartServiceType: 'consumer', isWholesale })

  const [isValidating, setIsValidating] = useState(false)

  const goToCheckout = useCallback(
    async (farmId: string) => {
      if (!farmId) return Alert('Invalid Cart!', 'No farm association was found for this cart.') // This should never happen if there's products in cart

      // Update or delete all invalid items from cart
      setIsValidating(true)

      /** This async validation doesn't need trycatch because any error will be caught inside. The result will contain the user input from an awaited alert that handles the error */
      const res = await validateCartAlert({
        farmId,
        onGoToShopPress() {
          // This will be called if the user decided to continue shopping, to make up for any unavailable items
          dispatch(setNavProps()) // This will clear the farm cache, to force loading updated product data
          navigation.navigate('FarmShop', { farmSlug: farmId })
        },
      })
      setIsValidating(false)

      if (res.cancelCheckout || res.unavailCount > 0) dispatch(setNavProps()) // This will clear the farm cache, to force loading updated product data

      // If the user decided to cancel checkout, we abort.
      // If the cart still has items in it after validation, we can continue to checkout
      if (!res.cancelCheckout && res.newCartLength > 0) {
        return navigation.navigate('Checkout', { cartFarmId: farmId })
      }
    },
    [navigation, validateCartAlert, dispatch],
  )

  return { isValidating, goToCheckout }
}
