import Colors from '@/constants/Colors'
import { Icon, Text } from '@elements'
import { formatMoney } from '@helpers/display'
import { MoneyCalc } from '@helpers/money'
import { Money } from '@models/Money'
import { memo } from 'react'
import { StyleSheet, View } from 'react-native'
import { LinearProgress } from 'react-native-elements'

type FarmMinimumProps = {
  orderMin: Money
  subtotal: Money
  hasNonStandard: boolean
}

/** Component that shows if the farm minimum was met or its completion progress */
export const FarmMinimum = memo(function FarmMinimum({ orderMin, subtotal, hasNonStandard }: FarmMinimumProps) {
  if (MoneyCalc.isGTE(subtotal, orderMin))
    return (
      <View style={styles.main}>
        <Text>{`${formatMoney(orderMin)} minimum met`}</Text>
        <Icon noHover solid name="check-circle" />
      </View>
    )

  return (
    <>
      <View style={styles.main}>
        <Text>{`${formatMoney(orderMin)} minimum`}</Text>
        <LinearProgress
          animation={{ duration: 500 }}
          value={subtotal.value / orderMin.value}
          color={Colors.green}
          style={styles.linearProgress}
        />
      </View>
      {hasNonStandard && (
        <Text size={10} color={Colors.shades[400]}>
          *Shares are not counted in minimum
        </Text>
      )}
    </>
  )
})

const styles = StyleSheet.create({
  main: {
    gap: 10,
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  linearProgress: {
    height: 12,
    borderRadius: 8,
    width: 100,
  },
})
