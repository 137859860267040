import { YUP_MONEY_OPTIONAL } from '@helpers/Yup'
import { PromoCode } from '@models/Coupon'
import * as yup from 'yup'
import { couponSchema } from '../CouponBuilder'

export const promoSchema: yup.ObjectSchema<PromoCode> = yup.object({
  id: yup.string().defined(),
  coupon: yup
    .object({
      id: yup.reach(couponSchema, 'id'),
      farm: yup.reach(couponSchema, 'farm'),
    })
    .defined() as yup.ObjectSchema<PromoCode['coupon']>,
  code: yup.string().defined('Promo code is required'),
  customers: yup.array(
    yup.object({
      id: yup.string().defined(),
      email: yup.string().defined(),
    }),
  ),
  orderMinimum: YUP_MONEY_OPTIONAL('Order Minimum', { allowZero: true, requireCurrency: true }),
  maxRedemptions: yup.number(),
  timesRedeemed: yup.number().defined(),
  expiration: yup.mixed().isDateTime(),
  oncePerCustomer: yup.boolean().defined(),
})
