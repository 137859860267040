import { AdminCard } from '@/admin/components/AdminCard'
import { snapshotCustomShare } from '@api/CustomShares'
import { customSharesCollection } from '@api/framework/ClientCollections'
import { InfoRow } from '@components'
import { Alert, Divider, Loader, LoadingView, Text, Toast } from '@elements'
import { AccessRight, Permission } from '@helpers/Permission'
import { formatRunTime, formatShareRunType } from '@helpers/custom_shares/display'
import { formatPickupDate, formatTime } from '@helpers/display'
import { sortByLatest } from '@helpers/sorting'
import { ArrElement } from '@helpers/typescript'
import { CustomShare, CustomShareRunTypes, isScheduledCustomShare } from '@models/CustomShare'
import { RouteProp, useRoute } from '@react-navigation/native'
import { ShareRunControl } from '@screens/Shopping/CustomShares/ShareRunControl'
import { arrayRemove, arrayUnion } from 'firebase/firestore'
import React, { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { AdminView } from '../../../admin/components/AdminView'
import { AdminProductsParamList } from '../../../admin/navigation/types'
import { Logger } from '../../../config/logger'
import Colors from '../../../constants/Colors'
import { globalStyles } from '../../../constants/Styles'
import { useSnapshot } from '../../../hooks/useSnapshot'
import { withAdminAuth } from '../../../hooks/withAdminAuth'
import { CustomShareRunCard } from './CustomShareRunCard'
import { PrimarySharesCard } from './PrimarySharesCard'
import { ProductRankingCard } from './components/ProductRankingCard'
import { UnspentValueCard } from './components/UnspentValueCard'

//TODO: This is not used now but will be when this becomes an add and edit page
enum PageType {
  ADD = 'AddCustomShare',
  EDIT = 'EditCustomShare',
  VIEW = 'ViewCustomShare',
}

/** This will confirm that the user meant to run this action as these actions are more for edge cases and will most likely be removed in the future. */
export function alertRunAction(): Promise<boolean> {
  return new Promise((resolve) =>
    Alert(
      'Warning',
      'Please confirm you mean to run this action. These actions are only available to GrownBy admin and should be rarely required. Auto running should handle most every scenario.',
      [
        { text: 'Cancel', style: 'cancel', onPress: () => resolve(false) },
        { text: 'Continue', onPress: () => resolve(true) },
      ],
    ),
  )
}

/** UI to render the farms custom share configuration. For right now we only support a single custom share at a time. */
function AdminCustomSharesComponent() {
  const { params } =
    useRoute<RouteProp<AdminProductsParamList, 'AddCustomShare' | 'EditCustomShare' | 'ViewCustomShare'>>()
  const customShareId = params?.shareId
  const shareApi = useSnapshot('snapshotCustomShare', snapshotCustomShare, [customShareId!], !!customShareId)

  const hasActiveRun = useMemo(() => !!shareApi.data?.runs.some((run) => run.status === 'running'), [shareApi.data])
  const shareRuns = useMemo(() => [...(shareApi.data?.runs ?? [])].sort(sortByLatest('date')), [shareApi.data?.runs])

  const updateRankedProduct = async (prod: ArrElement<CustomShare['rankedProducts']>, type: 'add' | 'remove') => {
    const share = shareApi.data
    if (!share) return
    if (hasActiveRun) {
      return Alert("Can't update products", 'Please wait until the current run has finished before updating products.')
    }

    // Update the products in our data, we target only the exact item for updating so there is no data contention.
    Loader(true)
    try {
      if (type === 'add') {
        await customSharesCollection.update({ id: share.id }, { rankedProducts: arrayUnion(prod) })
        Toast(`You have added ${prod.product.name} to your share rankings.`)
      } else if (type === 'remove') {
        await customSharesCollection.update({ id: share.id }, { rankedProducts: arrayRemove(prod) })
        Toast(`You have removed ${prod.product.name} from your share rankings.`)
      }
    } catch (err: unknown) {
      Logger.error(err)
      Alert('Error', 'There was an error saving this product please check your connection and try again.')
    } finally {
      Loader(false)
    }
  }

  return (
    <AdminView hideFooter style={styles.container}>
      <LoadingView loading={shareApi.loading} error={shareApi.error} success={shareApi.data}>
        {(share) => (
          <>
            <AdminCard>
              <ShareRunControl share={share} hasActiveRun={hasActiveRun} />
              <Divider clear />
              <View>
                <Text size={16} type="bold" style={globalStyles.marginVertical10}>
                  Schedule Information
                </Text>
                <View>
                  <InfoRow
                    data={{
                      left: 'Orders Open',
                      right: formatRunTime(share, CustomShareRunTypes.OpenOrdering),
                    }}
                  />
                  <InfoRow
                    data={{
                      left: 'Orders Close',
                      right: formatRunTime(share, CustomShareRunTypes.CloseOrdering),
                    }}
                  />
                  <InfoRow
                    data={{
                      left: 'Next Scheduled Run',
                      right: isScheduledCustomShare(share)
                        ? `${formatShareRunType(share.nextRun.type)} on ${formatPickupDate(
                            share.nextRun.date,
                          )} at ${formatTime(share.nextRun.date)}`
                        : 'No scheduled run',
                    }}
                  />
                </View>
              </View>
            </AdminCard>
            <PrimarySharesCard primaryShares={share.primaryShares} />
            <UnspentValueCard share={share} hasActiveRun={hasActiveRun} />
            <ProductRankingCard share={share} onUpdateRankedProduct={updateRankedProduct} />

            <View style={styles.runsWrapper}>
              <Divider clear />
              <Text size={18} type="bold">
                Run History
              </Text>
              {shareRuns?.map((run) => (
                <CustomShareRunCard key={run.id} run={run} />
              ))}
              {share.runs.length === 0 && <Text color={Colors.shades[400]}>No runs recorded yet</Text>}
            </View>
          </>
        )}
      </LoadingView>
    </AdminView>
  )
}

export const AdminCustomShareDetailScreen = withAdminAuth(
  AdminCustomSharesComponent,
  Permission.ProductSetup,
  AccessRight.Edit,
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  runsWrapper: {
    alignItems: 'center',
    marginVertical: 10,
  },
})
