import { addOneTimePin } from '@api/Payments'
import { Timestamp } from '@api/encoding/Time'
import { Alert, Button, ErrorText } from '@elements'
import { errorToString } from '@helpers/helpers'
import { uuidString } from '@helpers/uuid'
import { addHours } from 'date-fns'
import { useCallback, useRef, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { EProtectClientInterface } from './eProtect/helpers/types'
import { EProtect } from './eProtect/eProtect'

/** This modal shows the pin verification for EBT cards */
export function VerifyEbtCard({
  onSuccess,
  payId,
  userId,
}: {
  onSuccess: (pin: { token: string; expires: Timestamp }) => void
  userId: string
  payId: string
}) {
  const [isLoading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const eProtectClient = useRef<EProtectClientInterface>()

  const onSubmit = async () => {
    const uniqueId = uuidString(16)

    setLoading(true)
    const message = {
      id: uniqueId,
      orderId: uniqueId + '-order',
    }
    try {
      const res = await eProtectClient.current?.getCheckoutPin(message)

      setLoading(false)

      // Show any WorldPay errors here
      if (!res) return setError('No response from caller')

      if (res?.message !== 'Success') {
        return setError(res?.message)
      }

      const pin = {
        token: res.pinCheckoutId,
        // Set expiration date for 24 hours in the future
        expires: addHours(Date.now(), 24).getTime(),
      }
      await addOneTimePin(userId, { id: payId, pin })
      onSuccess?.(pin)
    } catch (e) {
      Alert('Error adding card', 'There was an error verifying your EBT card: ' + errorToString(e))
      setLoading(false)
    }
  }
  const onFinishLoading = useCallback(() => setLoading(false), [])

  return (
    <View style={styles.container}>
      <EProtect onFinishLoading={onFinishLoading} eProtectRef={eProtectClient} mode="pin" />
      <ErrorText>{error}</ErrorText>
      <Button loading={isLoading} title="Verify EBT Card" onPress={onSubmit} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 20,
  },
})
