import { Product } from '@models/Product'
import { memo, useContext } from 'react'
import { OrderCreatorScreenContext } from '../../OrderCreatorScreen.helper'
import { AddBtnStandard } from '../AddCartButtons/StandardBtn'
import { StandardRow_Reusable } from './StandardRow_Reusable'

type StandardRowProps = {
  prod: Product
  index: number
}

/** Row component for standard products of the order creator table */
export const StandardRow = memo(function StandardRow({ prod: p, index }: StandardRowProps) {
  const { isWholesaleOrderCreator } = useContext(OrderCreatorScreenContext)

  if (isWholesaleOrderCreator === undefined) return null

  return (
    <StandardRow_Reusable AddCartBtn={AddBtnStandard} index={index} prod={p} isWholesale={isWholesaleOrderCreator} />
  )
})
