import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { useCartService } from '@/hooks/useCart'
import { Image } from '@components'
import { Icon, Picker, Text } from '@elements'
import { canUpdateQuantity } from '@helpers/canUpdateQuantity'
import { CartItem } from '@models/Order'
import React, { useCallback, useMemo, useState } from 'react'
import { StyleSheet, View } from 'react-native'

type CartSidebarItemProps = {
  item: CartItem

  onUpdateQuantity: (itemId: string, value: number) => Promise<CartItem | void>
}

/** Component for displaying an item in the cart sidebar.*/
export function CartSidebarItem({ item, onUpdateQuantity }: CartSidebarItemProps) {
  const { cart } = useCartService()

  const [loading, setLoading] = useState(false)
  const quantityOptions = useMemo(() => getQuantityOptions(item, cart), [cart, item])

  const onValueChange = useCallback(
    async (value: string) => {
      setLoading(true)
      await onUpdateQuantity(item.id, parseInt(value, 10))
      setLoading(false)
    },
    [item.id, onUpdateQuantity],
  )

  return (
    <View style={styles.itemCont}>
      <Image style={styles.image} source={{ uri: item.product.images[0] }} />
      <Text numberOfLines={1} style={globalStyles.flex1} size={10}>
        {item.product.name}
      </Text>
      <Picker
        onValueChange={onValueChange}
        loading={loading}
        placeholder={null}
        value={item.quantity.toString()}
        items={quantityOptions}
      />
      <Icon
        color={Colors.shades['400']}
        iconSet="AntDesign"
        onPress={() => onUpdateQuantity(item.id, 0)}
        name="delete"
        disabled={loading}
        size={15}
      />
    </View>
  )
}

/** Generates a range of items based on a current quantity */
const generateQuantityOptions = (quantity: number) => {
  const maxQuantity = Math.max(10, quantity + 5)
  return Array.from({ length: maxQuantity }, (_, i) => i + 1).map((val) => ({
    value: val.toString(),
    label: val.toString(),
  }))
}

/** Gets the quantity options that are available and valid for the product */
const getQuantityOptions = (item: CartItem, cart: CartItem[]) => {
  const baseOptions = generateQuantityOptions(item.quantity)
  const availableOpts = []
  for (const option of baseOptions) {
    const delta = parseInt(option.value, 10) - item.quantity

    const canUpdate = canUpdateQuantity({ cartItem: item, cart, delta })

    // Return early and do not continue on checking greater quantities if cannot update on the current
    if (!canUpdate) {
      return availableOpts
    }

    availableOpts.push(option)
  }

  return availableOpts
}

const styles = StyleSheet.create({
  itemCont: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  image: {
    height: 25,
    width: 25,
    borderRadius: 10,
  },
})
