import { EditHeader } from '@/admin/components/EditHeader'
import { useFocusFx } from '@/hooks/useFocusFx'
import useKeyedState from '@/hooks/useKeyedState'
import { setAdminNav } from '@/redux/actions/adminState'
import { adminParamsSelector } from '@/redux/selectors'
import { loadProduct } from '@api/Products'
import { MessageWithIcon } from '@components'
import { LoadingView, Text } from '@elements'
import { StackScreenProps } from '@react-navigation/stack'
import { StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { AdminProductsParamList } from '../../navigation/types'

/** Admin screen for viewing a product */
export function AdminViewProductScreen({
  navigation,
  route: {
    params: { prodId },
  },
}: StackScreenProps<AdminProductsParamList, 'ViewProduct'>) {
  const { product } = useSelector(adminParamsSelector)
  const [{ loading, invalidErr }, set] = useKeyedState<{ loading: boolean; invalidErr?: string }>({ loading: true })
  const dispatch = useDispatch()

  useFocusFx(() => {
    if (!prodId) set('invalidErr', 'Missing the ID of the product you wish to view')
    if (prodId && product?.id !== prodId) {
      set('loading', true)
      loadProduct(prodId).then((product) => {
        dispatch(setAdminNav({ product }))
        set('loading', false)
      })
    } else {
      set('loading', false)
    }
  }, [prodId, product?.id, dispatch, set])

  const goBack = () => navigation.replace('ProductList')

  function editProduct() {
    navigation.replace('EditProduct', { prodId })
  }

  return (
    <LoadingView loading={loading} style={styles.container}>
      {invalidErr ? (
        <MessageWithIcon children={invalidErr} />
      ) : product ? (
        <>
          <EditHeader goBack={goBack} title={product.name} actionTitle="Edit" onPress={editProduct} />
          <Text>{JSON.stringify(product, undefined, 2)}</Text>
        </>
      ) : null}
    </LoadingView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
