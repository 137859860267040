import { productsCollection } from '@api/framework/ClientCollections'
import { overWriteProduct } from '@api/Products'
import DecimalCalc from '@helpers/decimal'
import { getCurrency, makeMoney } from '@helpers/money'
import { hasUnitStock, StockType, UnitBase, UnitStandard } from '@models/Product'

/** Updates the price per unit of a UnitStandard */
export const savePricePerUnit = async (newUnitPrice: number, prodId: string) => {
  const prod = await productsCollection.fetch(prodId)
  if (!hasUnitStock(prod)) throw new Error('Price per unit is only for UnitStock products')

  const currency = getCurrency(prod.farm)

  const updatedProduct: UnitStandard = {
    ...prod,
    pricePerUnit: makeMoney(newUnitPrice, currency),
  }

  //Must also update the amounts of each unit price, which are derived from the pricePerUnit
  updatedProduct.units = updatedProduct.units.map(
    (unit): UnitBase<StockType.Unit> => ({
      ...unit,
      prices: unit.prices.map((price) => ({
        ...price,
        amount: makeMoney(DecimalCalc.multiply(newUnitPrice, unit.multiplier), currency),
      })),
    }),
  )
  return overWriteProduct(updatedProduct)
}
