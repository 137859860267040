import { OrderProductType } from '@/admin/navigation/types'
import { useAlgoliaState } from '@/hooks/useAlgoliaState'
import { useFocusFx } from '@/hooks/useFocusFx'
import { KeyedState } from '@/hooks/useKeyedState'
import { MIN_MINUTES_FROM_NOW, getMinAvailDate } from '@/hooks/useSearchScreenConfigure/useSearchScreenConfigure'
import { useSnapshot } from '@/hooks/useSnapshot'
import { snapshotProductsOrderCreator } from '@api/Products'
import { Toast } from '@elements'
import { AlgoliaAdminProduct, asNumFilter } from '@models/Algolia'
import { Farm } from '@models/Farm'
import { useMemo, useRef, useState } from 'react'
import { useConfigure, useHits, useSearchBox } from 'react-instantsearch'
import { Hit } from 'react-instantsearch-core'
import {
  OrderCreatorStateProductsFilters,
  buildProdFilters,
  getIsDataUpdated,
  getPickupsForSelectedDistro,
  makeTableData,
} from './OrderCreatorScreen.helper'

/** State management for data related to products for the tables in the order creator and order edit screens. This is designed to be reusable so it works identically in both screens.
 * - Software constraints: The code in this hook should be not screen-specific. (I.e. DO NOT use any hooks that refer to the value of only one screen, like the admin farm, or the order creator isWholesale in redux) Those kinds of things should come from the hook's arguments, to ensure the values can be customized on each screen and aren't coupled with any specific one.
 */
export function useProductsReusable({
  keyedState: [{ csa, pickupDate, prodSearchTerm, schedule }, , setState],
  farmId,
  isWholesale,
  orderType,
}: {
  keyedState: KeyedState<OrderCreatorStateProductsFilters>
  orderType: OrderProductType
  farmId: Farm['id']
  isWholesale: boolean | undefined
}) {
  const { hits = [] } = useHits<Hit<AlgoliaAdminProduct>>()
  const { refine: refineSearch, clear: clearSearch } = useSearchBox()
  const { loadingSearch } = useAlgoliaState()

  const [minDate, setMinDate] = useState(getMinAvailDate())

  const updateCount = useRef(0)
  useFocusFx(() => {
    const intervalId = setInterval(() => {
      updateCount.current++
      // We will only update for a max of 5 times so that it does not keep updating infinity if the user keeps the screen open
      if (updateCount.current > 5) {
        clearInterval(intervalId)
        return
      }

      setMinDate(getMinAvailDate())
    }, MIN_MINUTES_FROM_NOW * 60 * 1000)

    return () => clearInterval(intervalId)
  }, [])

  useConfigure({
    filters: buildProdFilters(farmId, orderType, schedule?.id, csa?.id, isWholesale),
    numericFilters: [asNumFilter<AlgoliaAdminProduct, 'lastAvailStamp'>(`lastAvailStamp > ${minDate}`)],
    hitsPerPage: 400,
  })

  /** Fetches active farm products from database in response to changes in farm id & order type */
  const {
    data: dbProds,
    loading: loadingDbProds,
    error: dbProdsError,
  } = useSnapshot(
    'snapshotProductsOrderCreator',
    snapshotProductsOrderCreator,
    [farmId, orderType, isWholesale],
    !!farmId && !!orderType,
  )

  /** Display toast on product load error */
  useFocusFx(() => {
    if (dbProdsError) Toast('Something went wrong while loading products')
  }, [dbProdsError])

  /** Product search: In response to changes in search string, calls algolia refineSearch and sets loading products to true */
  useFocusFx(() => {
    if (!prodSearchTerm) return clearSearch()
    refineSearch(prodSearchTerm)
  }, [prodSearchTerm, clearSearch, refineSearch])

  /**  Prepares the data for the table.
   * dbProds will be filtered by orderType and the ids of the algolia search */
  const tableData = useMemo(() => {
    return makeTableData(dbProds ?? [], orderType, hits, isWholesale, schedule, csa, pickupDate)
  }, [dbProds, orderType, hits, isWholesale, schedule, csa, pickupDate])

  /** Indicates whether the table data currently represents the active filters */
  const isDataUpdated = useMemo(
    () => getIsDataUpdated(hits, orderType, schedule, csa, tableData),
    [hits, orderType, schedule, csa, tableData],
  )

  /** This loading state is intended to block the table with a spinner, so it does not show any data while true. This is extremely important, because otherwise the initial seconds the UI might see the empty table message. Everything is configured correctly though, and the reason why we need this is when these things are uninitialized, we tell algolia to return no products by passing the null filter. */
  const isLoadingProductsInit = !farmId || typeof isWholesale !== 'boolean'

  /** This loading state is intended to not block the table UI while true, but would inform the list empty component whether the table data is in progress.
   * - "loadingSearch" must be here because it is the algolia search loading state
   * - "loadingDbProds" must be here because it is the db products loading state
   * - "isDataUpdated" must be here because if the table data currently doesn't represent the active filters, that would be considered "in progress". And from empirical testing, there are times the algolia loading state will be false even though a new filter was applied and the hits don't yet represent the change in filters.
   */
  const isLoadingProducts = loadingSearch || loadingDbProds || !isDataUpdated

  /** Uses the db products to calculate the pickup dates for the selected distro */
  useFocusFx(() => {
    if (!dbProds || loadingDbProds) return

    if (schedule) {
      return setState((p) => ({ ...p, pickupDates: getPickupsForSelectedDistro(schedule, dbProds) }))
    } else {
      //If the distro selector was reset, also reset the pickup date selected and available dates
      return setState((p) => ({ ...p, pickupDate: undefined, pickupDates: [] }))
    }
  }, [schedule, setState, dbProds, loadingDbProds])

  return {
    refineSearch,
    clearSearch,
    dbProds,
    isLoadingProductsInit,
    isLoadingProducts,
    tableData,
  }
}
