import Colors from '@/constants/Colors'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { useDeviceSize } from '@/hooks/useLayout'
import { adminCurrencySelector } from '@/redux/selectors'
import { ToolTips } from '@components'
import { ButtonClear, FormInput, FormInputLabel, FormMoneyInput } from '@elements'
import { DefaultCatalog } from '@models/Product'
import { useMemo } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { BuyingOptionSectionProps } from '../../AdvancedPricing-helpers'
import { PricePerCatalog } from './PricePerCatalog'

/** The horizontal controls followed by the remove button */
export function StandardBuyingOption({ formik, arrayHelpers, index, formValues }: BuyingOptionSectionProps) {
  const { values: boValues, showSimpleError, setFieldValue, handleBlur, handleChange } = formik

  const isFirst = index === 0
  const canDeleteBo = formValues.buyingOptions.length > 1
  const { isLargeDevice } = useDeviceSize()
  const shouldShowLabel = formValues.catalog !== DefaultCatalog.WholesaleRetail ? isFirst || !isLargeDevice : true
  const styles = useStyles()

  const priceLabel = useMemo(
    () => (
      <FormInputLabel
        label={`Price${formValues.unitStock ? ' (calculated-editable)' : '*'}`}
        tooltipId={formValues.unitStock ? ToolTips.PRICE_OPTION : ToolTips.PRICE_GLOBAL}
      />
    ),
    [formValues.unitStock],
  )
  const currency = useSelector(adminCurrencySelector)

  return (
    <View style={styles.buyingOptionWrapper}>
      <View style={styles.filledRow}>
        <View style={styles.buyingOptionCont}>
          <FormInput
            row
            containerStyle={styles.mediumInput}
            placeholder="1 Pound"
            label={
              shouldShowLabel ? (
                <FormInputLabel label="Buying Option Name" tooltipId={ToolTips.OPTION_NAME} required />
              ) : null
            }
            onChangeText={handleChange('name')}
            value={boValues.name}
            errorMessage={showSimpleError('name')}
            onBlur={handleBlur('name')}
          />
          <FormInput
            row
            containerStyle={styles.smallInput}
            placeholder="1"
            label={
              shouldShowLabel ? <FormInputLabel label="Units Each" tooltipId={ToolTips.OPTION_UNIT} required /> : null
            }
            value={boValues.multiplier.toString()}
            onChangeText={handleChange('multiplier')}
            errorMessage={showSimpleError('multiplier')}
            onBlur={handleBlur('multiplier')}
          />
          {formValues.catalog !== DefaultCatalog.WholesaleRetail && (
            // For either wholesale or retail only, we show a single price input
            <FormMoneyInput
              row
              containerStyle={styles.smallInput}
              // It's OK to use prices[0] here because there should be a single price if it's not 'Wholesale-Retail'
              value={boValues.prices[0].amount}
              label={shouldShowLabel ? priceLabel : null}
              onChangeText={(value) => {
                setFieldValue('prices[0].amount', value)
              }}
              errorMessage={showSimpleError('prices[0].amount')}
              onBlur={handleBlur('prices[0].amount')}
              currency={currency}
            />
          )}
          <FormInput
            row
            containerStyle={styles.smallInput}
            placeholder="123456"
            label={shouldShowLabel ? <FormInputLabel label="SKU" tooltipId={ToolTips.OPTION_SKU} /> : null}
            onChangeText={handleChange('sku')}
            value={boValues.sku}
            errorMessage={showSimpleError('sku')}
            onBlur={handleBlur('sku')}
          />
          {!!formValues.unitStock && (
            <FormInput
              row
              containerStyle={styles.smallInput}
              onChangeText={handleChange('quantity')}
              value={boValues.quantity ? boValues.quantity.toString() : ''}
              placeholder="Eg. 10"
              label={
                shouldShowLabel ? <FormInputLabel label="In Stock" tooltipId={ToolTips.IN_STOCK} required /> : null
              }
              errorMessage={showSimpleError('quantity')}
              onBlur={handleBlur('quantity')}
            />
          )}
        </View>

        <ButtonClear
          style={styles.removeBuyingOptBtn}
          icon="times"
          disabled={!canDeleteBo}
          size={14}
          onPress={() => arrayHelpers.remove(index)}
        />
      </View>
      {formValues.catalog === DefaultCatalog.WholesaleRetail && (
        <PricePerCatalog formValues={formValues} formik={formik} />
      )}
    </View>
  )
}

const useStyles = () =>
  useSizeFnStyles(({ isSmallDevice }) => ({
    smallInput: {
      minWidth: 120,
    },
    mediumInput: {
      minWidth: 200,
    },

    filledRow: {
      flex: 1,
      flexDirection: 'row',
    },
    removeBuyingOptBtn: isSmallDevice
      ? {
          alignSelf: 'flex-start',
          height: 41.5, // matches the input height in order to be centered
          width: 41.5,
        }
      : {
          alignSelf: 'flex-end',
          height: 41.5, // matches the input height in order to be centered
          width: 41.5,
          marginBottom: 15,
        },

    buyingOptionCont: isSmallDevice
      ? {
          flexDirection: 'column',
          flex: 1,
          marginVertical: 5,
        }
      : {
          alignItems: 'center',
          marginVertical: 5,
          flex: 1,
          flexDirection: 'row',
          flexWrap: 'wrap',
        },

    buyingOptionWrapper: isSmallDevice
      ? {
          borderColor: Colors.shades['100'],
          borderWidth: 1,
          borderRadius: 10,
          marginVertical: 10,
          marginHorizontal: 10,
        }
      : {
          marginVertical: 20,
        },
  }))
