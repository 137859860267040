import { YUP_MONEY_REQUIRED, YUP_WHOLE_NUMBER_OPTIONAL_REAL, YUP_WHOLE_NUMBER_REAL } from '@helpers/Yup'
import { DraftOrder } from '@models/DraftOrder'
import { CartItem, Order, OrderItem, PickupItem, PickupItemStatus, isCartItem } from '@models/Order'
import { ProductType } from '@models/Product'
import * as Yup from 'yup'
import { Builder } from './Builder'
import { paymentScheduleSchema } from './PaymentScheduleSchema'
import { ValidationError, isValidationError, validateFromSchema } from './validators/helpers'

export const pickupItemSchema: Yup.ObjectSchema<PickupItem> = Yup.object().shape({
  id: Yup.string().required(),
  invoiceId: Yup.string().optional(),
  status: Yup.mixed<PickupItemStatus>().oneOf(Object.values(PickupItemStatus)).required(),
  oldDistribution: Yup.object().shape({ id: Yup.string().required() }).default(undefined),
  csaChangeOptions: Yup.object()
    .shape({
      blockLocationSwitching: Yup.mixed<true>().oneOf([true]).optional(),
      blockRescheduling: Yup.mixed<true>().oneOf([true]).optional(),
      changeWindow: YUP_WHOLE_NUMBER_REAL('changeWindow', { allowZero: true }),
    })
    .default(undefined),
  orderItem: Yup.object().shape({
    quantity: YUP_WHOLE_NUMBER_REAL('quantity', { allowDecimal: true }),
    // We do not want to be validating product data from the pickup validator, these would be validated as part of the product
    purchasedUnit: Yup.object().nullable() as Yup.ObjectSchema<PickupItem['orderItem']['purchasedUnit']>,
    unadjustedQuantity: YUP_WHOLE_NUMBER_OPTIONAL_REAL('unadjusted quantity'),
    paymentSchedule: paymentScheduleSchema
      .shape({
        // For now we're not requiring currency here, but eventually this schema reshape should go and the entire payment schedule schema should be enforced
        amount: YUP_MONEY_REQUIRED('schedule amount', { allowZero: true }),
        deposit: YUP_MONEY_REQUIRED('deposit', { allowZero: true }),
      })
      .required(),
  }),
  product: Yup.object().shape({
    id: Yup.string().required(),
    image: Yup.string().required(),
    name: Yup.string().required(),
    type: Yup.mixed<ProductType>().oneOf(Object.values(ProductType)).required(),
  }),
  order: Yup.object().shape({
    id: Yup.string().required(),
    orderNum: YUP_WHOLE_NUMBER_REAL('orderNum', { allowZero: true }),
    date: Yup.mixed().isDateTime().required(),
  }),
})

/**
 * This builder creates a PickupItem object */
export class PickupItemBuilder extends Builder<PickupItem> {
  constructor() {
    super('PickupItem')
  }

  build(item: OrderItem | CartItem, order: Pick<Order | DraftOrder, 'id' | 'orderNum' | 'date'>) {
    const purchasedUnit = isCartItem(item) ? item.unit ?? null : item.purchasedUnit
    const productImage = isCartItem(item) ? item.product.images[0] : item.product.image
    const csaChangeOptions = item.csa
      ? {
          blockLocationSwitching: item.csa.changeOptions?.blockRescheduling,
          blockRescheduling: item.csa.changeOptions?.blockRescheduling,
          changeWindow: item.csa.changeWindow,
        }
      : undefined

    // We are specifically omitting invoiceId as that is not part of the builder process and the type should enforce that
    // it will never exist there
    const pickupItem: Omit<PickupItem, 'invoiceId'> = {
      id: item.id,
      status: PickupItemStatus.Active,
      orderItem: {
        purchasedUnit,
        quantity: item.quantity,
        unadjustedQuantity: item.unadjustedQuantity,
        paymentSchedule: item.paymentSchedule,
      },
      csaChangeOptions,
      product: {
        id: item.product.id,
        image: productImage,
        name: item.product.name,
        type: item.product.type,
      },
      order: {
        id: order.id,
        orderNum: order.orderNum,
        date: order.date,
      },
    }

    return this.validate(pickupItem)
  }

  /** This function validates pickupItem object
   * @param pickupItem the pickupItem object to validate */
  validate(pickupItem: PickupItem): PickupItem {
    try {
      return validateFromSchema(pickupItemSchema, pickupItem)
    } catch (error) {
      if (isValidationError(error)) {
        throw new ValidationError({ path: 'pickupItem.' + (error.data?.path ?? ''), msg: error.message })
      }
      throw error
    }
  }
}
