import { ShortState } from '@/assets/data/states'
import { CountryCode } from '@helpers/international/types'
import type { GeocodeResult } from 'use-places-autocomplete'
import { Coordinate } from './Coordinate'

/** This address model represents a generic universal address of no specific country.
 * - Any model that extends this generic address is expected to have the country defined and the data must be validated for the specific country requirements
 */
export type Address = {
  coordinate: Coordinate
  street1: string
  street2?: string
  city: string
  state: string
  zipcode: string
  country: CountryCode
}

/** US 5-digit zip code as a literal string type */
export type ShortZip = `${number}${number}${number}${number}${number}`

/** A valid US address */
export type AddressUS = Address & {
  coordinate: Coordinate
  street1: string
  street2?: string
  city: string
  state: ShortState
  zipcode: ShortZip
  country: CountryCode
}

/** Identifies an address is for a PO box */
export const isPO = (address: Pick<Address, 'street1' | 'street2'>): boolean => {
  /** https://stackoverflow.com/a/25271289 */
  const pattern = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^ *((#\d+)|((box|bin)[-. \/\\]?\d+)|(.*p[ \.]? ?(o|0)[-. \/\\]? *-?((box|bin)|b|(#|num|number)?\d+))|(p(ost|ostal)? *(o(ff(ice)?)?)? *((box|bin)|b)? *(#|num|number)*\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(#|num|number)? *\d+|(#|num|number) *\d+)/i,
  )

  return pattern.test(address.street1) || (!!address.street2 && pattern.test(address.street2))
}

/** This custom type is a simplified universal representation of a google places result item, which may be found while searching for anything in the google places api (cities, zipcodes, addresses, establishments, etc) */
export type GooglePlace = {
  coordinate: Coordinate
  address_components?: GeocodeResult['address_components']
  /** The name of the google place. It will be the city name when searching for cities, or the establishment name if searching for establishments, or it may be the address when searching for addresses, etc. */
  name?: string
}
