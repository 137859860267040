import { InvoicePaymentMethod } from '@models/Invoice'
import { InfinitePaymentMethodDefinition, PaymentMethodsDefinitionInterface } from './PaymentDefinition'
import { PaymentSources } from '@models/PaymentMethod'

/** This is a deprecated payment method that uses Stripe Invoicing. It only remains for display purposes but can no longer
 * be used to make payments in the app. We will slowly remove this from everywhere. */
export class StripeInvoicePaymentDefinition
  extends InfinitePaymentMethodDefinition<PaymentSources.STRIPE_INVOICE>
  implements PaymentMethodsDefinitionInterface<PaymentSources.STRIPE_INVOICE>
{
  constructor() {
    super(PaymentSources.STRIPE_INVOICE, 'Card')
  }

  formatInvPayMethod(_: InvoicePaymentMethod<PaymentSources.STRIPE_INVOICE>) {
    return this.formatShort()
  }
}
