import { SearchOptions } from '@algolia/client-search/dist/client-search'
import { GetDeliveryFeeResult, NoDeliveryFees } from '@helpers/location'
import { Zero } from '@helpers/money'
import { getPickups } from '@helpers/order'
import { isActive, isInStock } from '@helpers/products'
import {
  AlgoliaAdminProduct,
  FILTERS_QUERY,
  FacetFilterBase,
  ProductTypeFilter,
  QueryFilterArray,
  asFilter,
} from '@models/Algolia'
import { CSA } from '@models/CSA'
import { Distribution } from '@models/Distribution'
import { Money } from '@models/Money'
import { SplitTenderPayment } from '@models/Order'
import { DefaultCatalog, Product, ProductType, isDigital, isPhysical, isShare, isStandard } from '@models/Product'
import { CartItemGroupType } from '@screens/Shopping/Checkout/CartItemsCheckout'
import { initialTotal } from '@screens/Shopping/Checkout/helpers'
import { MarkAsPaidInfo } from '@shared/types/v2/invoice'
import { DateTime } from 'luxon'
import { Hit } from 'react-instantsearch-core'

import { OrderCreatorScreenParams, OrderProductType } from '../../../navigation/types'

import { Total } from '@/constants/types'
import { UseApiFxReturn } from '@/hooks/useApiFx'
import { buildQueryFilter } from '@helpers/algolia-client'
import { isSameDay } from '@helpers/time'
import { ProductFeesForInvoice } from '@models/ProductFee'
import { createContext } from 'react'
import { useOrderCreatorScreenData } from './useOrderCreatorScreenData'

export const goBackString = (goBack: OrderCreatorScreenParams['goBack'] | undefined): string => {
  switch (goBack) {
    case 'orders':
      return 'All orders'
    case 'customers':
      return 'All Customers'
    case 'customerDetails':
      return 'Customer Details'
    default:
      return 'Previous Screen'
  }
}

/** Returns a url that should navigate back to the screen that came to the order creator */
export const goBackUrl = (params?: Pick<OrderCreatorScreenParams, 'custId' | 'goBack'>): string => {
  switch (params?.goBack) {
    case 'orders':
      return '/admin/orders/list'
    case 'customers':
      return '/admin/customers/list'
    case 'customerDetails': {
      if (params?.custId) return `/admin/customers/${params.custId}`
      else return `/admin/customers`
    }
    default:
      return 'admin/orders'
  }
}

/** Generates the product type filter for the query.
 * @returns A product type filter if parameters are valid. If it returns null it means something is invalid and the search should produce no results
 */
const buildProductTypeFilter = ({
  orderType,
  isWholesaleOrderCreator,
}: {
  orderType: OrderProductType
  isWholesaleOrderCreator: boolean
}): ProductTypeFilter[] | ProductTypeFilter | null => {
  switch (orderType) {
    case 'standard':
      return `type:${orderType}`
    case 'digital': {
      if (isWholesaleOrderCreator === false) return `type:${orderType}`
      return null
    }
    case 'share': {
      if (isWholesaleOrderCreator === false) return ['type:addon', 'type:primary']
      return null
    }
  }
}

/** Returns a composite (OR) facet filter to include products for the current DefaultCatalog mode */
function buildDefaultCatalogFilter(isWholesaleOrderCreator: boolean): FacetFilterBase[] {
  const bothCatalogsFilter = asFilter<AlgoliaAdminProduct, 'defaultCatalog'>(
    `defaultCatalog:${DefaultCatalog.WholesaleRetail}`,
  )

  const catalogSpecificFilter = asFilter<AlgoliaAdminProduct, 'defaultCatalog'>(
    `defaultCatalog:${isWholesaleOrderCreator ? DefaultCatalog.Wholesale : DefaultCatalog.Retail}`,
  )

  return [bothCatalogsFilter, catalogSpecificFilter]
}

/** Builds the facetFilters for the product search. These filters are targeting the admin index */
export const buildProdFilters = (
  farmId: string | undefined,
  orderType: OrderProductType,
  distroId?: string,
  csaId?: string,
  isWholesaleOrderCreator?: boolean,
): SearchOptions['filters'] => {
  if (typeof isWholesaleOrderCreator !== 'boolean' || !farmId) {
    // wait for the mode to be initialized
    return FILTERS_QUERY.NullDocType
  }

  const prodTypeFilter = buildProductTypeFilter({ orderType, isWholesaleOrderCreator })
  if (prodTypeFilter === null) {
    // means the orderType is incompatible with the catalog mode
    return FILTERS_QUERY.NullDocType
  }

  const filters: QueryFilterArray = [
    FILTERS_QUERY.Product,
    prodTypeFilter,
    asFilter<AlgoliaAdminProduct, 'farmId'>(`farmId:${farmId}`),
    buildDefaultCatalogFilter(isWholesaleOrderCreator),
    FILTERS_QUERY.NotHidden,
  ]

  if (distroId && orderType !== 'digital')
    filters.push(asFilter<AlgoliaAdminProduct, 'distributions'>(`distributions.id:${distroId}`))
  if (csaId && orderType === 'share') filters.push(asFilter<AlgoliaAdminProduct, 'csas'>(`csas.id:${csaId}`))

  return buildQueryFilter(filters)
}

/** Determines which products will be shown in the create order table.
 * - Filters products in table based on non-algolia selectors (distro and pickup date), as well as some product attributes. */
const showProd = (
  p: Product,
  orderType: OrderProductType,
  isWholesale?: boolean,
  distro?: Distribution,
  pickupDate?: DateTime,
  csa?: CSA,
): boolean => {
  // Filter by ordertype
  if (orderType === 'standard' && !isStandard(p)) return false
  if (orderType === 'share') {
    if (!isShare(p)) return false
    if (!!csa && !p.csa.includes(csa.id)) return false
  }
  if (orderType === 'digital' && !isDigital(p)) return false

  // Don't show products with no unhiden distros.
  if (isPhysical(p) && !p.distributions.some((d) => !d.isHidden)) return false

  if (!isInStock(p, { isWholesale })) return false

  // Filter physical products by distro selected
  if (distro && isPhysical(p)) {
    if (!p.distributions.find((pd) => pd.id === distro.id)) return false

    const pickups = getPickups(distro, p, {
      ignoreOrderCutoffWindow: true,
      ignoreDisableBuyInFuture: true,
    })
    const minPickups = !isWholesale && isStandard(p) ? p.minPickups ?? 1 : 1

    if (pickups.length < minPickups) return false

    // Filter standard by date selected
    if (orderType === 'standard' && pickupDate && !pickups.find((date) => date.toISODate() === pickupDate.toISODate()))
      return false
  } else {
    // If there's no distro selected, show products with pickups left
    if (
      !isActive(p, {
        excludeClosedDistros: false,
        ignoreOrderCutoffWindow: true,
        isWholesale,
      })
    )
      return false
  }

  return true
}

export const isSameOrderType = (prodType: ProductType, orderType: OrderProductType) => {
  if (orderType === 'share' && isShare(prodType)) return true
  if (orderType === 'standard' && prodType === ProductType.Standard) return true
  if (orderType === 'digital' && isDigital(prodType)) return true
  return false
}
/** When the orderType changes, algolia's current hits will be outdated. On subsequent re-renders, we can use this to tell if the new hits reflect the new orderType. This is necessary to know when to disable the loader state */
export const hitsAreUpdated = (
  hits: Hit<AlgoliaAdminProduct>[],
  orderType: OrderProductType,
  distroId?: string,
  csaId?: string,
): boolean => {
  if (!hits.length) return true
  return (
    hits.every((h) => isSameOrderType(h.type, orderType)) &&
    (distroId && orderType !== 'digital' ? hits.every((h) => h.distributions.find((d) => d.id === distroId)) : true) &&
    (csaId && orderType === 'share' ? hits.every((h) => h.csas.find((csa) => csa.id === csaId)) : true)
  )
}
/** Indicates whether the dbProds correspond to the current orderType */
export const dbProdsAreUpdated = (prods: Product[] | undefined, orderType: OrderProductType): boolean => {
  if (!prods) return false
  return !prods.length || prods.every((p) => isSameOrderType(p.type, orderType))
}
/** Whether both the hits and db prods reflect the current params */
export function getIsDataUpdated(
  hits: Hit<AlgoliaAdminProduct>[],
  orderType: OrderProductType,
  distro: Distribution | undefined,
  csa: CSA | undefined,
  dbProds: Product[] | undefined,
) {
  return hitsAreUpdated(hits, orderType, distro?.id, csa?.id) && dbProdsAreUpdated(dbProds, orderType)
}
/** Gets the results that correspond to the orderType selected and the algolia hit results. The filtering here is only necessary during the first re-renders after changing the orderType. On subsequent re-renders, the new results will all reflect the new orderType */
export const makeTableData = (
  results: Product[],
  orderType: OrderProductType,
  hits: { id: string; type: ProductType }[],
  isWholesale?: boolean,
  distro?: Distribution,
  csa?: CSA,
  pickupDate?: DateTime,
): Product[] => {
  return results.filter(
    (res) =>
      isSameOrderType(res.type, orderType) &&
      hits.map((h) => h.id).includes(res.id) &&
      showProd(res, orderType, isWholesale, distro, pickupDate, csa),
  )
}

export const getProdTypes = (orderType: OrderProductType): ProductType[] => {
  if (orderType === 'standard') return [ProductType.Standard]
  if (orderType === 'share') return [ProductType.AddonShare, ProductType.PrimaryShare]
  if (orderType === 'digital') return [ProductType.Digital]
  return []
}

/** Screen state related to products and filters */
export type OrderCreatorStateProductsFilters = {
  prodSearchTerm: string
  schedule: Distribution | undefined
  pickupDates: DateTime[]
  pickupDate: DateTime | undefined
  csa: CSA | undefined
}

/** Screen state related to payments */
export type OrderCreatorStatePayments = {
  splitTender: SplitTenderPayment | undefined
  appliedFarmBalance: Money
  /** markAsPaid info will be used to decide if this order will be marked as paid on creation. will be passed into OderSummary for use by components downstream */
  markAsPaidInfo: MarkAsPaidInfo
  total: Total
  /** Additional fees that should be applied on products */
  additionalFees: ProductFeesForInvoice
  /** delivery fees total and details (due anytime). This data is supposed to reflect combined delivery dates based on future pickups */
  deliveryFeesData: GetDeliveryFeeResult &
    Pick<UseApiFxReturn<any>, 'err' | 'loading'> & {
      /** Whether the cart includes new delivery fees, regardless of of due date */
      hasDeliveryFees: boolean
    }
  /** grouping of cartitems by delivery fee locations */
  itemGroups: CartItemGroupType[]
  /** Whether the form submission is in progress */
  isSubmitting: boolean
}

export const orderCreatorInitialStateProductsFilters: OrderCreatorStateProductsFilters = {
  prodSearchTerm: '',
  pickupDates: [],
  csa: undefined,
  schedule: undefined,
  pickupDate: undefined,
}

export const orderCreatorInitialStatePayments: OrderCreatorStatePayments = {
  appliedFarmBalance: Zero,
  markAsPaidInfo: { markAsPaid: false, note: '' },
  total: initialTotal,
  additionalFees: [],
  splitTender: undefined,
  deliveryFeesData: { ...NoDeliveryFees, hasDeliveryFees: false, loading: false, err: undefined },
  itemGroups: [],
  isSubmitting: false,
}

export const getPickupsForSelectedDistro = (distro: Distribution, dbProds: Product[]): DateTime[] => {
  return dbProds
    .flatMap((p) =>
      //get the pickups for all the products at this schedule
      getPickups(distro, p, {
        ignoreOrderCutoffWindow: true,
        ignoreDisableBuyInFuture: true,
      }),
    )
    .reduce<DateTime[]>(
      //combine the pickups with no duplicates
      (agg, next) => (agg.find((d) => isSameDay(d, next, distro.location.timezone)) ? agg : agg.concat(next)),
      [],
    )
}

export const catalogSelectorItems = [
  { value: DefaultCatalog.Retail, label: 'Retail' },
  { value: DefaultCatalog.Wholesale, label: 'Wholesale' },
]

type OrderCreatorScreenContextType = ReturnType<typeof useOrderCreatorScreenData>

export const OrderCreatorScreenContext = createContext<OrderCreatorScreenContextType>(
  {} as OrderCreatorScreenContextType,
)
