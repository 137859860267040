// The amount that farms will be charged monthly -- if they do not have a custom pricing
import { MoneyCalc } from '@helpers/money'

export const DEFAULT_HOSTING_CHARGE = 1000
/** The percent that farms will be charged per purchase -- if they do not have a custom pricing */
export const DEFAULT_APP_FEE = 2
/** The percent that Stripe charges per CC transaction. */
export const STRIPE_CREDIT_CARD_FEE_PERCENT = 2.9
/** The percent that Stripe charges per ACH transaction. */
export const STRIPE_ACH_FEE_PERCENT = 1
/** The maximum we charge for Stripe ACH service fee */
export const MAX_STRIPE_ACH_FEE = MoneyCalc.fromCents(500)
/** One time ACH fee for adding a new card. Paid for the farmer on first purchase with the Account */
export const STRIPE_ACH_NEW_CARD_FEE = MoneyCalc.fromCents(150)
/** The additional $0.30 cents Stripe charges per transaction*/
export const STRIPE_CREDIT_ADDITIONAL_FEE = MoneyCalc.fromCents(30)
/** The percent that Fortis charges per ACH transaction. */
export const FORTIS_ACH_FEE_PERCENT = 0.5
/** The percent that Fortis charges per CC transaction. */
export const FORTIS_CREDIT_CARD_FEE_PERCENT = 2.8
/** The maximum we charge for Fortis ACH service fee */
export const MAX_FORTIS_ACH_FEE = MoneyCalc.fromCents(500)
/** The additional $0.30 cents Fortis charges per transaction*/
export const FORTIS_CREDIT_ADDITIONAL_FEE = MoneyCalc.fromCents(30)
/** The percent that member farms will be charged per purchase -- if they do not have a custom pricing */
export const DEFAULT_MEMBER_APP_FEE = 2
// constant of the maxSessionDuration, set to 2 weeks
export const MAX_SESSION_DURATION = 1000 * 60 * 60 * 24 * 14
