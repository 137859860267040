import { loadFarm, searchFarmByName } from '@api/Farms'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { openUrl } from '@helpers/client'
import { AlgoliaGeoDoc, AlgoliaGeoFarm, FILTERS } from '@models/Algolia'
import { FarmStatus } from '@models/Farm'
import { SignInProviders, isFarmAdmin } from '@models/User'
import { RouteProp } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import * as Linking from 'expo-linking'
import { useEffect, useState } from 'react'
import { FlatList, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'

import { useApiFx } from '@/hooks/useApiFx'
import { useCancelableDeepFocusFx } from '@/hooks/useCancelablePromise'
import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { FormInput, LoadingView, ScreenView, Spinner } from '@elements'
import { createObjectStyles } from '@helpers/client/createObjectStyles'
import { wait } from '@helpers/helpers'
import { SUPPORT_EMAIL } from '@shared/BaseUrl'
import { Button } from '../../../components/elements/Button'
import { Divider } from '../../../components/elements/Divider'
import { Icon } from '../../../components/elements/Icon/Icon'
import { Toast } from '../../../components/elements/Overlays/Popups'
import { TextH1, TextH4 } from '../../../components/elements/Text'
import Colors from '../../../constants/Colors'
import { globalStyles } from '../../../constants/Styles'
import { useDebouncedValue } from '../../../hooks/useDebounce'
import { withAuth } from '../../../hooks/withAuth'
import { withBuyerBlock } from '../../../hooks/withRetailCheck/withBuyerBlock'
import { FarmerWalkthroughParamList } from '../../../navigation/types'
import { farmsSelector, wholesaleSelector } from '../../../redux/selectors'
import { adminCard } from '../../constants/AdminCards'

interface Props {
  navigation: StackNavigationProp<FarmerWalkthroughParamList, 'RegisterFarm'>
  route: RouteProp<FarmerWalkthroughParamList, 'RegisterFarm'>
}

function FarmRegisterScreenComp({ navigation, route }: Props) {
  const { isWholesale } = useSelector(wholesaleSelector)

  const fAssocs = useSelector(farmsSelector)

  const { data: farm, loading: loadingFarm } = useApiFx(
    async (farmId: string | undefined) => {
      if (!farmId) {
        // When undefined this is meant to simply clear the state, and not run loadFarm
        return undefined
      }
      return loadFarm(farmId)
    },
    [route.params?.farmId],
  )

  const [results, setResults] = useState<AlgoliaGeoDoc<AlgoliaGeoFarm>[]>([])

  const [query, setQuery] = useState<string>('')
  const debouncedQuery = useDebouncedValue(query, 500)

  const [pageIndex, setPageIndex] = useState<number>(0)
  const [pageTotal, setPageTotal] = useState<number>(0)
  const [totalHits, setTotalHits] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const onClose = () => {
    navigation.setParams({ farmId: undefined })
  }

  const handleClaim = async () => {
    if (!farm) {
      return Toast('Could not load farm')
    }

    // Check if the user is already an admin for the farm being claimed
    for (const assoc of fAssocs.filter(isFarmAdmin)) {
      if (assoc.id === farm.id && isFarmAdmin(assoc)) {
        Toast('You are already an admin for this farm, loading it now.')
        const url = Linking.createURL('/admin/welcome')
        openUrl(url)
        return
      }
    }
    navigation.navigate('ClaimScreen', { farmSlug: farm.urlSafeSlug })
  }

  useCancelableDeepFocusFx(
    async (isCurrent) => {
      if (typeof isWholesale !== 'boolean' || !debouncedQuery) return

      setLoading(true)
      const searchResults = await searchFarmByName(debouncedQuery, undefined, {
        facetFilters: [
          FILTERS.Farm,
          FILTERS.NotHidden,
          // Passing these facetFilters here will overwrite the facet filters inside the api fn.
          // we're doing it here because the default filters don't have the NotRegistered filter, and we want it for this screen specifically
          FILTERS.NotRegistered,
          FILTERS.NotInactiveFarm,
          isWholesale ? FILTERS.Wholesale : FILTERS.Retail,
        ],
        page: pageIndex,
      })
      if (!isCurrent) return

      setPageTotal(searchResults.nbPages)
      setTotalHits(searchResults.nbHits)
      setResults(searchResults.hits ?? [])

      if (searchResults.nbPages <= pageIndex && searchResults.nbPages > 0) {
        setPageIndex(searchResults.nbPages - 1)
      }

      await wait(300)
      if (!isCurrent) return

      setLoading(false)
    },
    [debouncedQuery, pageIndex, isWholesale],
  )

  useEffect(() => {
    return function resetPage() {
      navigation.setParams({ farmId: undefined })
      setQuery('')
      setResults([])
    }
  }, [navigation])

  const fStyles = useStyles()

  return (
    <ScreenView>
      <View style={styles.container}>
        {!farm && (
          <View style={fStyles.noFarmContainer}>
            <TextH1 style={styles.searchForYourFarmText}>Search for your farm on GrownBy</TextH1>
            <TouchableOpacity style={styles.missingFarmButton} onPress={() => navigation.navigate('AddFarmScreen')}>
              <TextH4 style={styles.cantFindFarmText}>Can't find your farm? Register it.</TextH4>
            </TouchableOpacity>
          </View>
        )}
      </View>
      <LoadingView loading={loadingFarm}>
        {!farm ? (
          // Show the search input if no farm is selected
          <View style={styles.searchInputCont}>
            <FormInput
              label={null}
              placeholder="Search for your farm"
              onChangeText={setQuery}
              value={query}
              rightIcon={debouncedQuery !== query || loading ? <Spinner color={Colors.green} /> : undefined}
            />
            {pageTotal > 1 && (
              <>
                <View style={styles.searchResultMetaBar}>
                  <View style={[styles.searchPageButtonContainer, globalStyles.alignCenter]}>
                    <TouchableOpacity
                      disabled={pageIndex === 0}
                      onPress={() => {
                        setPageIndex(pageIndex - 1)
                      }}
                    >
                      <MaterialCommunityIcons
                        name="arrow-left"
                        size={24}
                        color={pageIndex === 0 ? Colors.shades[200] : Colors.shades[600]}
                      />
                    </TouchableOpacity>
                    <Text style={styles.bold}>
                      Page {pageIndex + 1} / {pageTotal} :
                    </Text>
                    <Text>{totalHits} farms found</Text>
                    <TouchableOpacity
                      disabled={pageIndex === pageTotal - 1}
                      onPress={() => {
                        setPageIndex(pageIndex + 1)
                      }}
                    >
                      <MaterialCommunityIcons
                        name="arrow-right"
                        size={24}
                        color={pageIndex === pageTotal - 1 ? Colors.shades[200] : Colors.shades[600]}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                <Text style={styles.resultsFromGoogleText}>Results from Google</Text>
              </>
            )}
            {!!query && query === debouncedQuery && !loading && (
              <FlatList
                data={results}
                keyExtractor={(item) => item.id}
                renderItem={({ item }) => (
                  <FarmResult algoliaFarm={item} onPress={() => navigation.setParams({ farmId: item.id })} />
                )}
                ItemSeparatorComponent={() => <Divider top={1} bottom={1} />}
                ListEmptyComponent={() => (
                  <Text style={[styles.errMsg, styles.farmResultTitle, globalStyles.margin20]}>No farms found</Text>
                )}
              />
            )}
            {!!query && loading && <Spinner color={Colors.green} />}
          </View>
        ) : (
          //Show the farm details if a farm is selected
          <ScrollView>
            <View style={fStyles.farmDetailsMain}>
              <Icon name="times" size={30} onPress={onClose} style={styles.closeIcon} />
              <View style={fStyles.farmDetailsCenteredContainer}>
                <View style={fStyles.farmDetailsSection}>
                  <TextH1 style={styles.claimStatusText}>
                    {farm.status !== FarmStatus.Listed && farm.status !== FarmStatus.Pending
                      ? 'This farm already has an Admin.'
                      : 'This farm is claimable'}
                  </TextH1>
                  <View>
                    <Text style={styles.farmNameText}>
                      By claiming this farm, you certify that you are the owner or operator of this farm.
                    </Text>
                  </View>
                </View>
                <View style={fStyles.farmDetailsSection}>
                  <View style={styles.farmNameContainer}>
                    <Text style={fStyles.farmNameText}>{farm.name}</Text>
                    <Text style={styles.cardAddress}>{farm.address.street1}</Text>
                    <Text style={styles.cardAddress}>
                      {farm.address.city}, {farm.address.state} {farm.address.zipcode}
                    </Text>
                  </View>
                  <Button
                    title="Claim this farm"
                    disabled={farm.status !== FarmStatus.Listed && farm.status !== FarmStatus.Pending}
                    onPress={handleClaim}
                    style={fStyles.buttonNav}
                  />
                </View>
              </View>
            </View>
            <View style={styles.farmDetailsBottom}>
              <View style={fStyles.farmDetailsBottomInnerCont}>
                <TextH4 style={styles.needHelpText}>Need help? </TextH4>
                <View style={globalStyles.flexRow}>
                  <TextH4 style={styles.helpText}>Contact </TextH4>
                  <TextH4 style={styles.supportEmailText}>{SUPPORT_EMAIL}.</TextH4>
                </View>
              </View>
            </View>
          </ScrollView>
        )}
      </LoadingView>
    </ScreenView>
  )
}

export const FarmRegisterScreen = withAuth(
  withBuyerBlock(FarmRegisterScreenComp, { blockWholesaleAuthorizedUser: true }),
  {
    provider: SignInProviders.Email,
    type: 'signup',
    noSafeAreaInsets: true,
  },
)

function FarmResult({
  algoliaFarm,
  onPress,
}: {
  algoliaFarm: AlgoliaGeoDoc<AlgoliaGeoFarm>
  onPress(arg0: AlgoliaGeoDoc<AlgoliaGeoFarm>): void
}) {
  const fStyles = useFarmResultStyles()
  return (
    <TouchableOpacity key={algoliaFarm.id} style={fStyles.farmResultCont} onPress={() => onPress(algoliaFarm)}>
      <View style={styles.farmResultText}>
        <Text numberOfLines={1} ellipsizeMode="tail" style={styles.farmResultTitle}>
          {algoliaFarm.name}
        </Text>
        <Text numberOfLines={1} ellipsizeMode="tail">
          {algoliaFarm.address.street1}
        </Text>
        <Text numberOfLines={1} ellipsizeMode="tail">
          {algoliaFarm.address.city}, {algoliaFarm.address.state} {algoliaFarm.address.zipcode}
        </Text>
      </View>

      <View style={fStyles.claimFarmCont}>
        {algoliaFarm.farm.status === FarmStatus.Listed ? (
          <Button
            title="Claim this farm"
            outline
            textStyle={styles.claimThisFarmBtn}
            style={{ backgroundColor: Colors.white }}
            onPress={() => onPress(algoliaFarm)}
          />
        ) : (
          <Text style={styles.statusText}>{`Status: ${algoliaFarm.farm.status}`}</Text>
        )}
      </View>
    </TouchableOpacity>
  )
}

const useFarmResultStyles = () =>
  useLayoutFnStyles(({ isSmallDevice }) => ({
    farmResultCont: {
      marginVertical: 8,
      paddingVertical: 12,
      margin: 4,
      justifyContent: 'space-between',
      flexDirection: isSmallDevice ? 'column' : 'row',
    },
    claimFarmCont: isSmallDevice ? { marginTop: 10, justifyContent: 'center' } : { justifyContent: 'center' },
  }))

const useStyles = () =>
  useLayoutFnStyles((layout) => {
    const { width, isLargeDevice, isSmallDevice } = layout
    return {
      farmDetailsMain: { ...adminCard(layout), alignSelf: 'center', minHeight: 300 },
      farmDetailsCenteredContainer: {
        flexDirection: isLargeDevice ? 'row' : 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginHorizontal: 32,
      },
      farmDetailsSection: { flexDirection: 'column', width: isLargeDevice ? '45%' : '100%' },
      noFarmContainer: isLargeDevice
        ? { flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }
        : { flexDirection: 'column', marginTop: 10 },
      buttonNav: {
        width: width / 3,
        maxWidth: 200,
      },
      farmNameText: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 24,
        lineHeight: 24,
        marginBottom: isLargeDevice ? 32 : 0,
      },
      farmDetailsBottomInnerCont: { flexDirection: isSmallDevice ? 'column' : 'row' },
    }
  })

const styles = createObjectStyles(({ merge }) => ({
  container: {
    marginHorizontal: '2%',
  },
  containerRow: {
    flexDirection: 'row',
  },
  errMsg: {
    color: Colors.red,
    fontSize: 12,
    marginHorizontal: 10,
  },
  contactButton: {
    marginHorizontal: 8,
  },
  buttonWithBorder: {
    borderColor: Colors.black,
    borderWidth: 1,
    borderRadius: 10,
    alignContent: 'center',
    alignItems: 'center',
  },
  buttonRow: {
    alignSelf: 'center',
  },
  farmInformation: {
    textAlign: 'center',
    lineHeight: 24,
    fontSize: 16,
  },
  missingFarmButton: {
    fontSize: 16,
    margin: 4,
    marginBottom: 20,
  },
  farmResultText: {
    marginRight: 56,
  },
  farmResultTitle: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  milesText: {
    fontSize: 12,
    textAlign: 'center',
    marginTop: 4,
  },
  searchResultMetaBar: {
    padding: 5,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: Colors.semiTransparent,
    height: 50,
  },
  searchPageButtonContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    height: 20,
  },
  input: {
    height: 48,
    width: 300,
    backgroundColor: Colors.transparent,
    fontSize: 16,
    borderRadius: 14,
  },
  cardAddress: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 24,
    lineHeight: 29,
  },
  helpText: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 14,
    lineHeight: 17,
  },
  statusText: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 14,
    color: Colors.green,
    marginHorizontal: 10,
    marginVertical: 5,
    paddingHorizontal: 20,
  },
  cantFindFarmText: {
    fontStyle: 'normal',
    fontSize: 18,
    lineHeight: 24,
    color: Colors.green,
    textDecorationLine: 'underline',
  },
  searchForYourFarmText: { fontStyle: 'normal', fontSize: 24, lineHeight: 29 },
  searchInputCont: { flex: 1, marginHorizontal: '2%' },
  bold: { fontWeight: 'bold' },
  resultsFromGoogleText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: 24,
    color: Colors.shades[300],
  },
  farmNameText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 18,
    lineHeight: 24,
    marginBottom: 10,
  },
  claimStatusText: { fontStyle: 'normal', fontWeight: 'bold', fontSize: 24, lineHeight: 29, marginBottom: 16 },
  closeIcon: { position: 'absolute', top: 15, left: 15 },
  farmNameContainer: { marginBottom: 32 },
  farmDetailsBottom() {
    return merge(this.containerRow, { marginTop: 20, justifyContent: 'center' })
  },
  needHelpText() {
    return merge(this.helpText, { fontWeight: 'bold' })
  },
  supportEmailText() {
    return merge(this.helpText, { color: Colors.green, textDecorationLine: 'underline' })
  },
  claimThisFarmBtn: { fontStyle: 'normal', fontWeight: 'bold', fontSize: 14, lineHeight: 17, color: Colors.green },
}))
