import { addUserAddress } from '@api/UserAddress'
import { updateUser, validatePhoneNumber } from '@api/Users'
import { Alert } from '@elements'
import { errorToString, isEmptyValue, nonEmptyString } from '@helpers/helpers'
import { Address } from '@models/Address'
import { FormikErrors } from 'formik'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setUser } from '../../../redux/actions/user'
import { userSelector } from '../../../redux/selectors'
import { CheckoutFormType } from './helpers'
import { checkoutInitialState } from './useCheckoutData'

import { Logger } from '@/config/logger'
import { SetHelper } from '@/hooks/useKeyedState'
import { addressBuilder } from '@helpers/builders'
import { bullet } from '@helpers/display'

/** Will do formik validation on the address in addition to the Yup schema, to have more control over the async flow, and be able to show toasts and alerts, and properly save user data.
 * - We need an async validator to check for duplicate phone numbers.
 * - If errors are found, they will be returned in the formik format. Undefined means no errors.
 * @param shouldRequireAddress is meant to be true if the user address is either empty or invalid. A user who has previously placed orders and has a valid address should not be required to fill this address again */
export const useValidateCheckoutForm = (set: SetHelper<typeof checkoutInitialState>, shouldRequireAddress: boolean) => {
  const dispatch = useDispatch()
  const user = useSelector(userSelector)

  return useCallback(
    async (values: CheckoutFormType): Promise<FormikErrors<CheckoutFormType> | void> => {
      set('formValidationLoading', true)
      const errors: FormikErrors<CheckoutFormType> = {}

      if (shouldRequireAddress) {
        //Validate the phone number, if one was provided. It is optional
        let newPhoneNumber = values.billingAddress.phoneNumber || ''
        if (nonEmptyString(newPhoneNumber)) {
          try {
            newPhoneNumber = await validatePhoneNumber(newPhoneNumber, user.id, false)
          } catch (err) {
            const msg = errorToString(err)
            errors.billingAddress = { phoneNumber: msg }
          }
        }

        //Validate address
        const newAddress: Omit<Address, 'coordinate'> = {
          street1: values.billingAddress.street1,
          street2: values.billingAddress.street2,
          city: values.billingAddress.city,
          state: values.billingAddress.state,
          zipcode: values.billingAddress.zipcode,
          country: values.billingAddress.country,
        }
        // We allow PO box addresses in the validation of billing address for checkout
        errors.billingAddress = {
          ...errors.billingAddress,
          ...addressBuilder.getAddressErrors(newAddress, { allowPO: true, noCheckCoords: true }),
        }

        if (!isEmptyValue(errors.billingAddress)) {
          Alert(
            'Need address',
            // This alert message will show a list of address field errors from the schema validation
            // Let's not mention the word "Error" here. A user going here for the first time would be confused as to why they're getting an error if they have done nothing wrong yet
            `Please add a valid billing address to proceed.\n${Object.values(errors.billingAddress!).map(
              (err) => `\n ${bullet} ${err}`,
            )}`,
          )
          set('formValidationLoading', false)
          return errors
        } else {
          try {
            const [savedAddress] = await Promise.all([
              //Save the address to user's addresses subCollection, and user.address
              addUserAddress(user.id, { ...newAddress, isDefault: true }, { allowPO: true }),
              // And save phone number to user
              updateUser({ id: user.id, phoneNumber: newPhoneNumber }),
            ])
            dispatch(
              setUser({
                ...user,
                address: savedAddress,
                phoneNumber: newPhoneNumber,
              }),
            )
          } catch (error) {
            Logger.error(error)
            Alert(
              'Error',
              `There was a problem while trying to update your address or phone number. Please contact support`,
            )
            set('formValidationLoading', false)
            return {
              ...errors,
              billingAddress: {
                ...errors.billingAddress,
                updateError: errorToString(error),
              },
            }
          }
        }
      }
      //If address isn't required, nothing else to validate
      set('formValidationLoading', false)
    },
    [dispatch, set, shouldRequireAddress, user],
  )
}
