import { sortByConstraint } from '@helpers/sorting'
import { Optional } from '@helpers/typescript'
import { UserAddress } from '@models/UserAddress'
import { limit, where } from 'firebase/firestore'

import { geocode } from './Addresses'
import { addressCollection, usersCollection } from './framework/ClientCollections'

import { getState } from '@helpers/address'
import { addressBuilder } from '@helpers/builders'
import { ValidateAddrOpts } from '@helpers/builders/AddressBuilder'

/**
 * Will load the default address or first one if no default
 * @param userId
 */
export async function getDefaultAddress(userId: string): Promise<UserAddress | undefined> {
  const addresses = await addressCollection.resolve(userId).fetchAll(where('isDefault', '==', true), limit(1))
  return addresses[0]
}

/**
 * Will load all addresses and put the default one first
 * @param userId
 */
export async function loadUserAddresses(userId: string): Promise<UserAddress[]> {
  const addresses = await addressCollection.resolve(userId).fetchAll()

  return addresses.filter((a) => !a.deleted).sort(sortByConstraint((a) => !!a.isDefault))
}

/** Saves an address to a user  */
export async function addUserAddress(
  userId: string,
  /** An address without id and coords */
  userAddress: Optional<UserAddress, 'id' | 'coordinate'>,
  opts?: ValidateAddrOpts,
): Promise<UserAddress> {
  const geocodeRes = await geocode(userAddress)

  const stateAbbr = getState(userAddress.state, userAddress.country)?.abbreviation

  addressBuilder.validate(
    {
      street1: userAddress.street1,
      street2: userAddress.street2,
      city: userAddress.city,
      zipcode: userAddress.zipcode,
      state: stateAbbr,
      country: userAddress.country,
      coordinate: geocodeRes.coordinate,
    },
    opts,
  )

  // If we are setting a new default, make sure to clear the old one
  if (userAddress.isDefault) await removeDefaultAddress(userId)

  const newUserAddress = await addressCollection.resolve(userId).create({
    ...userAddress,
    state: stateAbbr,
    coordinate: geocodeRes.coordinate,
  })

  // only when the address is default, then we update the user's main address as default address
  if (userAddress.isDefault) await usersCollection.update({ id: userId, address: newUserAddress })

  return newUserAddress
}

/**
 * Will validate and update the address with the new data.
 * @param userId the user to update the address on
 * @param userAddress the address object we are updating
 */
export async function updateUserAddress(
  userId: string,
  userAddress: Omit<UserAddress, 'coordinate'>,
): Promise<UserAddress> {
  const geocodeRes = await geocode(userAddress)

  const stateAbbr = getState(userAddress.state, userAddress.country)?.abbreviation

  addressBuilder.validate({
    street1: userAddress.street1,
    street2: userAddress.street2,
    city: userAddress.city,
    zipcode: userAddress.zipcode,
    state: stateAbbr,
    country: userAddress.country,
    coordinate: geocodeRes.coordinate,
  })

  // If we are setting a new default, make sure to clear the old one
  if (userAddress.isDefault) await removeDefaultAddress(userId)

  const newUserAddress: UserAddress = { ...userAddress, state: stateAbbr!, coordinate: geocodeRes.coordinate }

  await addressCollection.resolve(userId).update(newUserAddress)

  // only when the address is default, then we update the user's main address as default address
  if (userAddress.isDefault) await usersCollection.update({ id: userId, address: newUserAddress })

  return newUserAddress
}

/**
 * Will mark an address as deleted which hides it from the user. It still exists for reference
 * @param userId the user to remove the address from
 * @param address the address object we are removing
 */
export async function removeUserAddress(userId: string, address: UserAddress): Promise<void> {
  return addressCollection.resolve(userId).update({ id: address.id, deleted: true, isDefault: false })
}

/** Will remove the address currently marked as default.
 * - Should be called whenever setting a new default address
 */
async function removeDefaultAddress(userId: string) {
  const collection = addressCollection.resolve(userId)
  const addresses = await collection.fetchAll(where('isDefault', '==', true))

  if (!addresses.length) return Promise.resolve()

  // Wait for all updates to be completed
  const writes = addresses.map((address) => collection.update({ id: address.id, isDefault: false }))
  return Promise.all(writes)
}
