import { omit } from '@helpers/typescript'
import { Payout } from '@models/Payout'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { marshalDate, unmarshalDate } from './Time'
import { prepareMarshal, prepareUnmarshal } from './encoding'
import { PaymentSources } from '@models/PaymentMethod'

/** marshalPayout encodes the payout object for storage in Firestore. */
export function marshalPayout(payout: Partial<Payout>): DocumentData {
  const data: DocumentData = omit(prepareMarshal(payout), 'id')

  if (payout.arrivalDate) {
    data.arrivalDate = marshalDate(payout.arrivalDate)
  }

  return data
}

/** marshalPayout decodes the payout object from Firestore into a Payout object. */
export function unmarshalPayout(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): Payout {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)
  const payout = { ...data, id } as Payout

  if (data.arrivalDate) {
    payout.arrivalDate = unmarshalDate(data.arrivalDate)
  }

  // Map deprecated stripeRef field onto ref field.
  if (data.stripeRef) {
    payout.ref = data.stripeRef
    payout.source = PaymentSources.STRIPE
  }

  return payout
}
