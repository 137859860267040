import { isAchPayment, isCashPayment, PaymentMethod } from '@models/PaymentMethod'
import { useContext } from 'react'
import { PaymentOptionsContext } from './PaymentOptionsContext'
import { View } from 'react-native'
import { PaymentButton } from '../PaymentButton'
import { TextH2, Tooltip } from '@elements'
import { ToolTips } from '@components'
import { sharedStyles } from './helpers/styles'
import { formatOfflinePaymentName, showInfinitePaymentsHelper } from './helpers/display'
import { wholesaleSelector } from '@/redux/selectors'
import { useSelector } from 'react-redux'
import { isInfinitePayment } from '@helpers/paymentMethods'

/** This section will show all infinite options to the user as well as the option to add new payment options */
export function InfinitePaymentSection({ payments }: { payments: PaymentMethod[] }) {
  const { updateSplitTender, splitTender, options } = useContext(PaymentOptionsContext)
  const showInfinitePayments = showInfinitePaymentsHelper(splitTender, options)
  // If we have any finite payments then we cannot have an offline payment
  const hasFinitePayment = splitTender.some((tender) => !isInfinitePayment(tender.paymentMethod.source))
  const { isWholesale } = useSelector(wholesaleSelector)

  // Format the offline payment name based on wholesale status and due date tolerance. For retail we display it as offline,
  // but for wholesale we want to show a more appropriate name
  const offlineButtonText = formatOfflinePaymentName(!!isWholesale, options.farm.dueDateTolerance?.wholesale)

  /** If a payment method should be disabled then show the reason */
  const disabledText = (pmt: PaymentMethod) => {
    if (isCashPayment(pmt)) {
      return hasFinitePayment
        ? 'Cannot use offline payment as a backup method.'
        : options.hasFarmBalanceItem
        ? 'Cannot pay for farm credit increases offline'
        : undefined
    } else if (isAchPayment(pmt)) {
      return hasFinitePayment ? 'Cannot use bank account as a backup method.' : undefined
    } else {
      return undefined
    }
  }

  // Check if the payment method is part of the split tender
  const isPmtSelected = (pmt: PaymentMethod) => {
    return !!splitTender.find((tender) => tender.paymentMethod.id === pmt.id)
  }

  const selectPayment = (pmt: PaymentMethod) => {
    updateSplitTender({ paymentMethod: pmt })
  }

  if (!showInfinitePayments) return null

  return (
    <View>
      {/* We modify the text for selecting a backup payment depending on the reason it is required */}
      {showInfinitePayments === 'installment' ? (
        <TextH2 size={16} style={sharedStyles.headerText}>
          Installment payments <Tooltip id={ToolTips.INSTALLMENT_PAYMENTS} title="Installment payments" />
        </TextH2>
      ) : showInfinitePayments === 'delivery' ? (
        <TextH2 size={16} style={sharedStyles.headerText}>
          Delivery fee payments <Tooltip id={ToolTips.DELIVERY_FEE_PAYMENTS} title="Delivery fee payments" />
        </TextH2>
      ) : (
        // If neither of the above are required then we are selecting a generic backup payment method, or main pmt.
        <TextH2 size={16} style={sharedStyles.headerText}>
          Saved Payment Methods
        </TextH2>
      )}
      {payments.map((pmt) => (
        <PaymentButton
          key={pmt.id}
          payment={pmt}
          onPress={() => selectPayment(pmt)}
          isSelected={isPmtSelected(pmt)}
          disableText={disabledText(pmt)}
          customTitle={isCashPayment(pmt) ? offlineButtonText : undefined}
        />
      ))}
    </View>
  )
}
