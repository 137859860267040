import Colors from '@/constants/Colors'
import { SIDEBAR_WIDTH } from '@/constants/Layout'
import { useCartService } from '@/hooks/useCart'
import { wholesaleSelector } from '@/redux/selectors'
import { Button, ButtonClear, ScreenView, typography } from '@elements'
import { plural } from '@helpers/display'
import { getFarmCartItems } from '@models/Cart'
import { Farm } from '@models/Farm'
import { CartItem } from '@models/Order'
import { useFarmCartDetails } from '@screens/Shopping/Checkout/useCartFarmInfo'
import { useCartValidation } from '@screens/Shopping/Checkout/useCartValidation'
import { memo, useMemo } from 'react'
import { FlatList, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import { CartSidebarHeader } from './CartSidebarHeader'
import { CartSidebarItem } from './CartSidebarItem'
import { CartSidebarListHeader } from './CartSidebarListHeader'

type CartSidebarProps = {
  farm: Farm
}

/**
 * CartSidebar component displays the cart items for a specific farm.
 * It includes functionalities to update item quantities, proceed to checkout, or cart navigation.
 */
export const CartSidebar = memo(function CartSidebar({ farm }: CartSidebarProps) {
  const { updateQuantity, cart: cartItems } = useCartService()
  const { isValidating, goToCheckout } = useCartValidation()
  const { isWholesale } = useSelector(wholesaleSelector)

  const farmCartItems = useMemo(() => {
    return getFarmCartItems({ items: cartItems, farmId: farm.id, isWholesale })
  }, [cartItems, farm.id, isWholesale])

  const { subtotal, disableCheckout, orderMin, standardProdSubtotal } = useFarmCartDetails({
    farm,
    items: farmCartItems,
  })

  const otherFarmsInCartNo = useMemo(() => {
    return countOtherFarmsInCart(cartItems, farm.id)
  }, [cartItems, farm.id])

  if (!farmCartItems.length) return undefined

  return (
    // ScreenView is necessary here in order to ensure the cart component is not overflowing the screen when having many items
    <ScreenView style={styles.wrapper}>
      <View style={styles.main}>
        <CartSidebarHeader subtotal={subtotal} />
        <View style={styles.listWrapper}>
          <FlatList
            alwaysBounceVertical={false}
            contentContainerStyle={styles.prodsWrapper}
            data={farmCartItems}
            ListHeaderComponent={
              <CartSidebarListHeader
                farm={farm}
                orderMin={orderMin}
                standardProdSubtotal={standardProdSubtotal}
                subtotal={subtotal}
              />
            }
            renderItem={({ item }) => <CartSidebarItem item={item} onUpdateQuantity={updateQuantity} />}
            showsVerticalScrollIndicator={false}
          />
          <Button
            style={styles.checkoutBtn}
            disabled={disableCheckout}
            title="Checkout"
            loading={isValidating}
            onPress={() => goToCheckout(farm.id)}
          />

          {otherFarmsInCartNo > 0 && (
            <ButtonClear
              style={styles.otherFarmsBtn}
              textStyle={styles.btnText}
              small
              icon="chevron-right"
              title={`Products from ${otherFarmsInCartNo} other ${plural(otherFarmsInCartNo, 'farm')} in cart`}
              url="/farms/shop/my-cart"
            />
          )}
        </View>
      </View>
    </ScreenView>
  )
})
const styles = StyleSheet.create({
  wrapper: {
    // ScreenView by default has flex 1, we don't want it here, because it will take half of the horizontal screen space
    flex: undefined,
  },
  main: {
    margin: 10,
    flex: 1,
  },
  prodsWrapper: {
    width: SIDEBAR_WIDTH,
    gap: 20,
    flexGrow: 1,
    padding: 10,
  },
  listWrapper: {
    borderWidth: 1,
    borderRadius: 20,
    borderColor: Colors.shades['100'],
    flex: 1,
  },

  checkoutBtn: {
    marginTop: 40,
  },
  btnText: {
    fontFamily: typography.body.regular,
    fontSize: 12,
    color: Colors.shades[600],
  },
  otherFarmsBtn: {
    marginVertical: 10,
    flexDirection: 'row-reverse',
    gap: 10,
  },
})

/**Counts the number of unique farms in the cart, excluding the current farm.*/
const countOtherFarmsInCart = (items: CartItem[], currFarmId: string) => {
  const uniqueFarmIds = new Set(items.map((ci) => ci.product.farm.id))
  uniqueFarmIds.delete(currFarmId)

  return uniqueFarmIds.size
}
