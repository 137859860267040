import { InvoicePaymentMethod } from '@models/Invoice'
import { InfinitePaymentMethodDefinition, PaymentMethodsDefinitionInterface } from './PaymentDefinition'
import { CurrencyCode, Money } from '@models/Money'
import { MoneyCalc } from '@helpers/money'
import { MAX_STRIPE_ACH_FEE, STRIPE_ACH_FEE_PERCENT } from '../../../constants/CONSTANTS'
import { PaymentSources } from '@models/PaymentMethod'

/** A bank transfer payment method handled by Stripe */
export class StripeACHPaymentDefinition
  extends InfinitePaymentMethodDefinition<PaymentSources.STRIPE_ACH>
  implements PaymentMethodsDefinitionInterface<PaymentSources.STRIPE_ACH>
{
  chargePlatformFee = true
  constructor() {
    super(PaymentSources.STRIPE_ACH, 'Bank')
  }

  calculateFee(amount: Money) {
    // Calculate the Stripe portion of the fee
    const stripeFee = MoneyCalc.math(Math.ceil, MoneyCalc.multiply(amount, STRIPE_ACH_FEE_PERCENT / 100))

    // The Stripe fee is maxed out at $5
    return MoneyCalc.min(stripeFee, MAX_STRIPE_ACH_FEE)
  }

  formatInvPayMethod({ last4 }: InvoicePaymentMethod<PaymentSources.STRIPE_ACH>) {
    if (last4) return `Bank *${last4}`
    return 'Bank Acct'
  }

  /** Ach does not exist in Canada and should only be available to pay for a USD purchase */
  hasCurrencySupport(currency: CurrencyCode): boolean {
    return ['usd'].includes(currency)
  }
}
