import { DefaultCatalog, DefaultCatalogPriceGroup, UnitPrice } from '@models/Product'
import * as Yup from 'yup'
import { YUP_MONEY_REQUIRED } from '../Yup'

export const unitPriceSchema: Yup.ObjectSchema<UnitPrice> = Yup.object()
  .defined()
  .shape({
    id: Yup.string().defined(),
    name: Yup.string().defined('Price Name is required'),
    amount: YUP_MONEY_REQUIRED('Price', { allowZero: true, requireCurrency: true }),
    maxCount: Yup.number(),
    purchasedCount: Yup.number(),
    skuSuffix: Yup.string(),
    priceGroup: Yup.object()
      .default(undefined)
      .shape({
        // This is validating only for a default catalog type, since we don't have custom catalogs yet, and we're not yet using ebt or partner price groups
        type: Yup.string<DefaultCatalogPriceGroup['type']>().defined().oneOf(['default-catalog']),
        catalog: Yup.string<DefaultCatalogPriceGroup['catalog']>()
          .defined()
          .oneOf([DefaultCatalog.Retail, DefaultCatalog.Wholesale]),
      }),
  })
