import { Text } from '@elements'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { formatMoney } from '@helpers/display'
import { Invoice, InvoiceStatus, invoiceSubtotal } from '@models/Invoice'
import React, { memo } from 'react'
import { View } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import { getTotalRefunds, groupByProcessor } from '../invoiceUtils'

import Colors from '@/constants/Colors'
import { MoneyCalc } from '@helpers/money'
import { entries } from '@helpers/typescript'
import { TenderItemsSummary } from './TenderItemsSummary'
import { formatInvoicePmtInfo } from '@helpers/paymentMethodDisplay'

export const SplitInvoiceSummary = memo(function SplitInvoiceSummary({ invoice }: { invoice: Invoice }) {
  const styles = useStyles()
  /** This is used to display items which are grouped by paymentSource.  */
  const invoiceItemsPayments = groupByProcessor(invoice.items)
  /** This shows the true detail for payments condition for a invoice.
   * For example, a invoice can be paid with Stripe and refunded by Farm Credit.
   * If we only use invoiceItemsPayments, we could miss correct refunded details.
   */
  const invoicePayments = entries(invoice.payments).map(([_, payment]) => payment)
  const totalRefunds = getTotalRefunds(invoice)

  return (
    <View style={styles.borderedCont}>
      {invoiceItemsPayments?.map((payment, idx) => (
        <TenderItemsSummary
          invoice={invoice}
          key={payment.source}
          payment={payment}
          index={idx}
          totalPaymentsLength={invoiceItemsPayments.length}
        />
      ))}
      <View style={styles.totalsContainer}>
        <View style={styles.leftColumn}>
          <Text type="medium" style={styles.text} size={14}>
            ORDER TOTAL
          </Text>
          {!MoneyCalc.isZero(totalRefunds) && (
            <Text type="medium" style={styles.text} color={Colors.red} size={13}>
              TOTAL REFUNDS
            </Text>
          )}
          {invoicePayments.map((payment) => (
            <View key={invoice.id + payment.source + '_refund'}>
              {invoice.payments[payment.source]?.totalPaid && (
                <Text style={styles.text}>Paid {formatInvoicePmtInfo(invoice.payments[payment.source]!)}</Text>
              )}
              {invoice.payments[payment.source]?.refundedAmount &&
                invoice.payments[payment.source]!.refundedAmount!.value > 0 && (
                  <Text style={styles.text} color={Colors.red}>
                    Refunded {formatInvoicePmtInfo(invoice.payments[payment.source]!)}
                  </Text>
                )}
            </View>
          ))}
        </View>
        <View style={styles.rightColumn}>
          <Text type="medium" style={styles.text} size={15}>
            {formatMoney(invoice.status === InvoiceStatus.Void ? invoiceSubtotal(invoice) : invoice.amountTotal)}
          </Text>
          {!MoneyCalc.isZero(totalRefunds) && (
            <Text type="medium" style={styles.text} color={Colors.red} size={14}>
              {formatMoney(totalRefunds)}
            </Text>
          )}
          {invoicePayments.map((payment) => (
            <View key={invoice.id + payment.source + '_paid'}>
              {invoice.payments[payment.source]?.totalPaid && (
                <Text style={styles.text}>{formatMoney(invoice.payments[payment.source]!.totalPaid!)}</Text>
              )}
              {invoice.payments[payment.source]?.refundedAmount &&
                invoice.payments[payment.source]!.refundedAmount!.value > 0 && (
                  <Text style={styles.text} color={Colors.red}>
                    {formatMoney(invoice.payments[payment.source]!.refundedAmount!.value!)}
                  </Text>
                )}
            </View>
          ))}
        </View>
      </View>
    </View>
  )
}, propsAreDeepEqual)

const useStyles = CreateResponsiveStyle(
  {
    borderedCont: {
      borderWidth: 1,
      borderColor: Colors.shades[100],
      borderRadius: 10,
      padding: 20,
      marginVertical: 10,
    },
    text: {
      marginVertical: 5,
    },
    totalsContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginVertical: 10,
    },
    leftColumn: {
      marginRight: 30,
      alignItems: 'flex-end',
    },
    rightColumn: {
      alignItems: 'flex-end',
    },
  },
  {
    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      borderedCont: {
        padding: 5,
      },
    },
  },
)
