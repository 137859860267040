import { createRef, memo, useMemo, useState } from 'react'
import { Pressable, StyleSheet, TextInput, View } from 'react-native'

import Colors from '../../../constants/Colors'
import { CloseIcon } from '../Icon/CloseIcon'
import { Icon } from '../Icon/Icon'
import { LoadingView } from '../LoadingView'

import { isWeb } from '@/constants/Layout'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { typography } from '../Text'
import { SearchInputBarProps, defaultIconName, defaultPlaceholder } from './SearchInputBar.helpers'

/** Versatile text input that implements the default react-naive TextInput, and manages loading spinner and a clear button */
export const SearchInputBar = memo(function SearchInputBar({
  contStyle,
  style,
  loading = false,
  onIconPress,
  inputRef: inputRefProp,
  contRef,
  icon = defaultIconName,
  hideIcon = false,
  clear,
  placeholder = defaultPlaceholder,
  ...props
}: SearchInputBarProps) {
  const [isFocused, setIsFocused] = useState(false)

  /** This inputRef should not have a default value defined on prop destructuring, as is normally done with props objects or the ref might misbehave. The inputRefProp?.current should be in the deps, so that on each re-render, the inputRef used will be either the prop if defined, or will be created if the prop is nullish*/
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const inputRef = useMemo(() => inputRefProp ?? createRef<TextInput>(), [inputRefProp?.current])

  return (
    <View
      ref={contRef}
      style={[
        styles.cont,
        // Apply focused border style only on web, to ensure consistency across the app
        isFocused && styles.focusedCont,
        contStyle,
      ]}
    >
      {!hideIcon && (
        <LoadingView loading={loading} style={styles.clearCont} noDefaultLoadingContainerStyle>
          <Icon
            iconSet="Feather"
            name={icon}
            size={20}
            color={Colors.shades['300']}
            onPress={(e) => {
              inputRef.current?.focus()
              onIconPress?.(e)
            }}
          />
        </LoadingView>
      )}

      <TextInput
        placeholderTextColor={Colors.shades['200']}
        ref={inputRef}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        style={[
          styles.input,
          isWeb && styles.webInput,
          // Apply padding only if the icon is not visible
          hideIcon && styles.leftPadding,
          style,
        ]}
        placeholder={placeholder}
        {...props}
      />
      {clear && (
        <Pressable style={styles.clearCont} onPress={clear}>
          <CloseIcon size={20} />
        </Pressable>
      )}
    </View>
  )
},
propsAreDeepEqual)

const styles = StyleSheet.create({
  cont: {
    flexDirection: 'row',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.shades['100'],
    backgroundColor: Colors.white,
    height: 50,
    overflow: 'hidden',
  },
  focusedCont: {
    borderColor: Colors.green,
  },

  input: {
    flex: 1,
    minWidth: 50,
    fontFamily: typography.body.regular,
    paddingRight: 5,
  },
  leftPadding: {
    paddingLeft: 5,
  },

  webInput: {
    // This will remove the default border applied on focus (WEB only) as it will change the borderColor only for the input and will not include the icons as well
    //@ts-ignore
    outlineStyle: 'none',
    textOverflow: 'ellipsis', // Breaks the placeholder if overflows. On mobile is enabled by default
  },

  clearCont: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '10%',
    maxWidth: 50,
    minWidth: 30,
  },
})
