import { CountryCode } from '@helpers/international/types'
import { yupToFormErrors } from '@helpers/yupToFormErrors'
import { Address, isPO } from '@models/Address'
import { ErrorTypes, ErrorWithCode } from '@shared/Errors'
import { FormikErrors } from 'formik'
import * as yup from 'yup'
import { ValidateOptions } from 'yup'
import { Optional } from '../typescript'
import { Builder } from './Builder'
import { isValidationError, validateFromSchema } from './validators/helpers'
import {
  StateSchemaCountry,
  ZipCodeSchemaCountry,
  addressFieldsGenericSchemas,
} from './validators/sharedSchemasAddress'

/** Validates a generic address which is not country-specific. This will not check if the zipcode and state are valid for the country */
export const addressSchemaGeneric: yup.ObjectSchema<Address> = yup.object(addressFieldsGenericSchemas).defined()

export type AddressSchemaContext = {
  /** The address fields will be validated for the country in this context if defined. If undefined, the address will be validated based on the value of the country field */
  country?: CountryCode
}

/** An address schema that validates zipcode and state based on the country in the address */
export const addressSchema: yup.ObjectSchema<Address, AddressSchemaContext> = addressSchemaGeneric.shape({
  zipcode: ZipCodeSchemaCountry,
  state: StateSchemaCountry({ errorMsgMode: 'data' }),
})

/** An address schema that validates zipcode and state based on the country field, and is ready to be used in any form that omits the coordinates from the address model
 * - It omits the coordinate validation
 * - Error messages from yup will be adequate for usage inside a form UI
 */
export const addressSchemaForm: yup.ObjectSchema<Omit<Address, 'coordinate'>, AddressSchemaContext> = addressSchema
  .omit(['coordinate'])
  .shape({
    /** This state schema assumes the form uses a picker component for state selection. If the form uses a text input the schema might need a different error message */
    state: StateSchemaCountry({ errorMsgMode: 'picker' }),
  })

export type ValidateAddrOpts = {
  /** If the validation should allow PO boxes in the address, this should be set to true. Otherwise PO boxes will be considered invalid by default */
  allowPO?: boolean
  /** If the validation should not consider the address coordinates this should be true. Otherwise the address will be expected to have coordinates in the expected format */
  noCheckCoords?: boolean
  useDefaultYupError?: boolean
} & Omit<ValidateOptions, 'strict'>

/** An address builder for a specific country's address format */
export class AddressBuilder extends Builder<Address> {
  readonly schema = addressSchema

  constructor() {
    super('AddressBuilder')
  }

  build(address: Partial<Address>, opts: ValidateAddrOpts): Address {
    return this.validate(address, opts)
  }

  validate(
    address: Partial<Address>,
    { allowPO = false, noCheckCoords, ...opts }: ValidateAddrOpts = { allowPO: false },
  ): Address {
    const validAddress = validateFromSchema(
      noCheckCoords ? this.schema.omit(['coordinate']) : this.schema,
      address,
      opts,
    ) as Address

    if (!allowPO && isPO(validAddress)) {
      throw new ErrorWithCode({
        type: ErrorTypes.Validation,
        code: 'no-po-address',
        uiMsg: 'PO Boxes are not allowed',
        devMsg: 'The address provided is a PO box which is not allowed.',
        data: validAddress,
      })
    }

    return validAddress
  }

  /** Will check for errors in an address except for its coordinate and return the errors object.
   * - If it returns void, means there were no errors.
   * - This validator ignores the coordinate, so it doesn't validate that the address corresponds to a real place. It only checks that the address superficially conforms to the address format.
   */
  getAddressErrors(
    address: Optional<Address, 'coordinate'>,
    opts?: Omit<ValidateAddrOpts, 'abortEarly' | 'useDefaultYupError'>,
  ): FormikErrors<typeof address> | void {
    try {
      /** This call to validate must pass useDefaultYupError: true in order for yupToFormErrors to receive the default schema error. otherwise it will get a custom erro which cannot be parsed as expected */
      this.validate(address, { ...opts, useDefaultYupError: true, abortEarly: false })
    } catch (error) {
      if (isValidationError(error)) {
        // We do not want this custom error here because it will not be parsed correctly.
        // So I'm doing this check just in case this gets modified later
        throw new Error(
          'getAddressErrors is trying to parse a wrong error type which does not generate the full list of address errors',
        )
      }
      return yupToFormErrors(error)
    }
  }

  /** Will indicate whether an address is valid, without considering its coordinate */
  isValidAddress(address?: Omit<Address, 'coordinate'>, opts?: ValidateAddrOpts): address is Omit<Address, 'coordinate'>
  isValidAddress(address?: Partial<Address>, opts?: ValidateAddrOpts): address is Address
  isValidAddress(
    address?: Optional<Address, 'coordinate'>,
    opts?: ValidateAddrOpts,
  ): address is Address | Omit<Address, 'coordinate'> {
    return !!address && !this.getAddressErrors(address, opts)
  }
}
