import { useFocusFx } from '@/hooks/useFocusFx'
import { Button, ErrorText } from '@elements'
import { nonEmptyString } from '@helpers/helpers'
import { MoneyCalc, isMoney } from '@helpers/money'
import { DefaultCatalog, ProductType } from '@models/Product'
import { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import {
  BuyingOptionSectionProps,
  buildBuyingOption,
  getSinglePrice,
  toPriceCatalog,
} from '../../AdvancedPricing-helpers'
import { FarmBalanceBuyingOption } from '../../AdvancedPricing/components/FarmBalanceBuyingOption'
import { StandardBuyingOption } from '../../AdvancedPricing/components/StandardBuyingOption'

type RenderItemProps = BuyingOptionSectionProps & {
  firstRenderIsDone: boolean
}

/** This component is rendered for each buying option */
export function RenderBuyingOption({ formik, arrayHelpers, index, formValues, firstRenderIsDone }: RenderItemProps) {
  const { values: boValues, setFieldValue, errors } = formik
  // This will tell app in what conditions to show the label for AdvancedPricingComponent

  /** When multiplier changes on a unit stock product, will recalculate prices based on the price per unit and new multiplier */
  useFocusFx(() => {
    if (!firstRenderIsDone || !formValues.unitStock || !isMoney(formValues.pricePerUnit)) {
      // Should only run after first render, on unitStock products
      return
    }
    const multiplier = boValues.multiplier || 0

    const newAmount = MoneyCalc.multiply(formValues.pricePerUnit, multiplier)

    if (formValues.catalog !== DefaultCatalog.WholesaleRetail) {
      const price = getSinglePrice(boValues, formValues.catalog, formValues.unitStock)

      setFieldValue('prices', [{ ...price, amount: newAmount }])
    } else {
      setFieldValue(
        'prices',
        boValues.prices.map((pr) => ({ ...pr, amount: newAmount })),
      )
    }

    // Only listen multiplier change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boValues.multiplier])

  const BuyingOptionsInputs = useMemo(() => {
    if (formValues.type === ProductType.FarmBalance) {
      return (
        <FarmBalanceBuyingOption formValues={formValues} formik={formik} index={index} arrayHelpers={arrayHelpers} />
      )
    }

    return <StandardBuyingOption arrayHelpers={arrayHelpers} formValues={formValues} formik={formik} index={index} />
  }, [arrayHelpers, formValues, formik, index])

  return (
    <View key={`units[${index}]`}>
      {BuyingOptionsInputs}

      {!!errors.prices && (
        <ErrorText>{nonEmptyString(errors.prices) ? errors.prices : 'There is a problem with the prices'}</ErrorText>
      )}

      {index === formValues.buyingOptions.length - 1 && (
        <Button
          title="Add buying option"
          icon="plus"
          outline
          style={styles.addBuyingOpt}
          onPress={() =>
            arrayHelpers.push(
              buildBuyingOption({
                unitStock: !!formValues.unitStock,
                catalog: toPriceCatalog(formValues.catalog),
              }),
            )
          }
        />
      )}
    </View>
  )
}

export const styles = StyleSheet.create({
  addBuyingOpt: {
    width: 250,
  },
})
