import { Certification, Farm } from '@models/Farm'

export function getCertificationsForFarm(certifications: Certification[], farm: Farm) {
  return certifications.filter((cert) => {
    // If the certification does not have a country code, it is available to all farms
    if (!cert.countryCode) {
      return true
    }
    // If the certification has a country code, it is only available to farms in that country
    return cert.countryCode === farm.address.country
  })
}
