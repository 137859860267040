import Colors from '@/constants/Colors'
import { UserType } from '@models/User'
import React from 'react'
import { StyleSheet } from 'react-native'
import { Tag } from '../ProductTag'

type UserCatalogFlagTag = {
  type: UserType
}

/** Flag that displays the user type (`Retail` / `Wholesale`) */
export function UserCatalogTag({ type }: UserCatalogFlagTag) {
  const flagDetails = {
    [UserType.wholesale]: {
      textColor: Colors.purple,
      label: 'Wholesale',
    },
    [UserType.retail]: {
      textColor: Colors.brightGreen,
      label: 'Retail',
    },
  }

  const { textColor, label } = flagDetails[type]

  return <Tag contStyle={styles.baseCont} title={label} color={textColor} />
}

const styles = StyleSheet.create({
  baseCont: {
    width: 80,
    margin: 0,
  },
})
