import { stateItemList } from '@helpers/address'
import { capitalizeFirst, getStateKind } from '@helpers/display'
import { CountryCode } from '@helpers/international/types'
import { FormPickerInput, FormPickerInputProps } from './FormPickerInput'

export type FormStatePickerProps = {
  country: CountryCode
} & Omit<FormPickerInputProps, 'label' | 'placeholder' | 'items'>

/** Wrapper of the form picker customized for selecting a state or province in a given country */
export function FormStatePicker({ country, ...props }: FormStatePickerProps) {
  return (
    <FormPickerInput
      label={capitalizeFirst(getStateKind(country))}
      placeholder={{ label: `Select a ${getStateKind(country)}...`, value: '' }}
      items={stateItemList(country) || []}
      {...props}
    />
  )
}
