import { getNextShareCloseDateTime, getNextShareOpenDateTime } from '@helpers/custom_shares/share_window'
import { formatTime, getDayofWeekName, plural } from '@helpers/display'
import { isBefore } from '@helpers/time'
import { CustomShare, CustomShareRunTypes } from '@models/CustomShare'
import { dateTimeInZone } from '@models/Timezone'

/** Will display the custom share run time in the format Thursday at 11:59pm */
export function formatRunTime(share: CustomShare, type: CustomShareRunTypes) {
  const dateTime =
    type === CustomShareRunTypes.OpenOrdering ? getNextShareOpenDateTime(share) : getNextShareCloseDateTime(share)

  return getDayofWeekName(dateTime.weekday) + ' at ' + formatTime(dateTime)
}

/** Returns what type of run will be next for the custom share. */
export const formatShareRunType = (runType: CustomShareRunTypes) => {
  if (runType === CustomShareRunTypes.OpenOrdering) return 'Open Ordering'
  if (runType === CustomShareRunTypes.CloseOrdering) return 'Close Ordering'
  if (runType === CustomShareRunTypes.OpenWarning) return 'Test Opening'
  if (runType === CustomShareRunTypes.CloseWarning) return 'Test Closing'
}

/** Returns the time left before the ordering window opens. */
export function getTimeLeftBeforeOrderingOpens(share: CustomShare): string | null {
  // We should use the share next run date because it can handle monthly schedules, we use the next share open date as a backup, but it will be every week.
  const nextOpenDate =
    share.nextRun?.type === CustomShareRunTypes.OpenOrdering
      ? share.nextRun.date
      : // If the next run is an open warning, we need to add 12 hours to the date because the warning is 12 hours before the open ordering window.
      share.nextRun?.type === CustomShareRunTypes.OpenWarning
      ? share.nextRun.date.plus({ hours: 12 })
      : getNextShareOpenDateTime(share)

  const diffInMs = nextOpenDate.toJSDate().getTime() - new Date().getTime()

  const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24))
  const hours = Math.floor((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60))

  const parts = []
  if (days > 0) parts.push(`${days} ${plural(days, 'day')}`)
  // Only show hours if we have less than 3 days left
  if (hours > 0 && days < 3) parts.push(`${hours} ${plural(hours, 'hour')}`)
  // Only show minutes if we have less than a day left
  if (minutes > 0 && days < 1) parts.push(`${minutes} ${plural(minutes, 'minute')}`)

  const timeString = parts.join(' ')

  if (isBefore(nextOpenDate, dateTimeInZone(share.farm.timezone))) return 'soon'

  // Don't show the text if we have more than a week left
  if (days > 7) return null

  return 'in ' + timeString
}
