import { keys } from '@helpers/typescript'

/** Money represents a transactional value of currency */
export type Money = {
  /** The decimal amount of money. */
  value: number

  /** The currency of the money */
  currency?: CurrencyCode
}

/** A complete money object with a defined currency */
export type MoneyWithCurrency = Money & { currency: NonNullable<Money['currency']> }

/** Available currencies from the ISO 4217 specification https://en.wikipedia.org/wiki/ISO_4217
 * - New currencies should be obtained from the same ISO specification.
 */
export type CurrencyCode = 'usd' | 'cad'

/** This object is intended to produce a type error if someone ever adds a code to the CountryCode type and forgets to add it here. This helps ensure the CurrencyCodes array will always include a value for every member of the CurrencyCode union type */
const CurrencyCodeArrayCompletionHelper: Record<CurrencyCode, 1> = { cad: 1, usd: 1 }

/** Array of all the values in the CurrencyCode type */
export const CurrencyCodes: readonly CurrencyCode[] = keys(CurrencyCodeArrayCompletionHelper)

/** This function will validate that a given string is one of our supported currencies */
export function isSupportedCurrency(currency: string): currency is CurrencyCode {
  return CurrencyCodes.includes(currency as CurrencyCode)
}
