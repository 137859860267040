import { useApiFx } from '@/hooks/useApiFx'
import { useDeviceSize } from '@/hooks/useLayout'
import { adminFarmSelector } from '@/redux/selectors'
import { loadFarmCategories } from '@api/Categories'
import { AddNewDropdownItem, FormInput, FormInputLabel, FormPickerInput, ToolTips } from '@elements'
import { formatCatalog } from '@helpers/wholesale'
import { DefaultCatalog, ProductType, hasUnits, isDigital, isPhysical, isStandard } from '@models/Product'
import { useFormikContext } from 'formik'
import { useCallback, useMemo } from 'react'
import { StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'
import { AdvancedPricingForm } from '../../AdvancedPricing'
import { ProductTypeForm } from '../../helpers/ProductTypeInfo'
import { createUnitOrSetValue } from '../../helpers/baseUnitAdd.helper'
import { BasicInfoForm, ebtOptsPickerItems } from '../BasicInformation.helpers'

/** Form content inputs for non-share Products */
export function ProductDescriptionFields() {
  const farm = useSelector(adminFarmSelector)
  const { isLargeDevice } = useDeviceSize()

  const { handleChange, values, errors, handleBlur, touched, setFieldValue, setTouched } = useFormikContext<
    BasicInfoForm & AdvancedPricingForm & ProductTypeForm
  >()

  const categoriesFx = useApiFx(loadFarmCategories, [farm.localCategories])
  const producers = useMemo(() => [...(farm.localProducers ?? [])].sort(), [farm.localProducers])

  const categoryOnChange = useCallback(
    (value: string) => {
      createUnitOrSetValue(value, 'category', farm.id, (v) =>
        setFieldValue('category', v).then(() => setTouched({ ...touched, category: true })),
      )
    },
    [farm.id, setFieldValue, setTouched, touched],
  )

  // Producer dropdown picker handler
  const producerOnChange = useCallback(
    (value: string) => {
      createUnitOrSetValue(value, 'producer', farm.id, (v) =>
        setFieldValue('producer', v).then(() => setTouched({ ...touched, producer: true })),
      )
    },
    [farm.id, setFieldValue, setTouched, touched],
  )

  const ebtEligibilityOnChange = useCallback(
    async (value: string) => {
      await setFieldValue('ebtEligibility', value)
      setTouched({ ...touched, ebtEligibility: true })
    },
    [setFieldValue, setTouched, touched],
  )

  const type = values.type

  return (
    <>
      <FormInput
        value={values.name}
        placeholder="What are you selling?"
        label={<FormInputLabel label="Product Name" tooltipId={ToolTips.BASIC_NAME} required />}
        onChangeText={handleChange('name')}
        onBlur={handleBlur('name')}
        errorMessage={touched.name ? errors.name : ''}
      />

      {farm.isWholesale && type !== ProductType.FarmBalance && (
        <FormPickerInput
          label="Catalog"
          items={
            // It is a requirement that the catalogs be sorted in the following order
            // That's the reason why this isn't using the standard Object.values(enum) approach
            [DefaultCatalog.Retail, DefaultCatalog.Wholesale, DefaultCatalog.WholesaleRetail].map((catalog) => ({
              label: formatCatalog(catalog),
              value: catalog,
            }))
          }
          onValueChange={async (val) => {
            await setFieldValue('catalog', val)
            await setTouched({ ...touched, catalog: true })
          }}
          value={values.catalog}
        />
      )}

      {hasUnits({ type }) && (
        <FormInput
          value={values.unitSkuPrefix}
          label={<FormInputLabel label="SKU-Prefix" tooltipId={ToolTips.OPTION_SKU} />}
          placeholder="SKU-Prefix"
          onChangeText={handleChange('unitSkuPrefix')}
          onBlur={handleBlur('unitSkuPrefix')}
          errorMessage={touched.unitSkuPrefix ? errors.unitSkuPrefix : ''}
        />
      )}
      <FormInput
        value={values.shortDescription}
        label={<FormInputLabel label="Short Description" tooltipId={ToolTips.SHORTDESCRIPTION} required />}
        placeholder="Short Description"
        onChangeText={handleChange('shortDescription')}
        onBlur={handleBlur('shortDescription')}
        errorMessage={touched.shortDescription ? errors.shortDescription : ''}
      />
      <FormInput
        value={values.longDescription}
        label={<FormInputLabel label="Long Description" tooltipId={ToolTips.LONGDESCRIPTION} required />}
        placeholder="A detailed description of the product"
        onChangeText={handleChange('longDescription')}
        onBlur={handleBlur('longDescription')}
        errorMessage={touched.longDescription ? errors.longDescription : ''}
        numberOfLines={isLargeDevice ? 4 : 2}
        multiline
        style={styles.longDescriptionCont}
      />
      {isPhysical({ type }) && (
        <FormInput
          value={values.productionMethod?.toString() || ''}
          label="Production Method"
          placeholder="Organic, Conventional, Grass-fed, etc"
          onChangeText={handleChange('productionMethod')}
          onBlur={handleBlur('productionMethod')}
          errorMessage={touched.productionMethod ? errors.productionMethod : ''}
        />
      )}

      {type !== ProductType.FarmBalance ? (
        <FormPickerInput
          label={<FormInputLabel label="Category" tooltipId={ToolTips.BASIC_CATEGORY} required />}
          loading={categoriesFx.loading}
          onValueChange={categoryOnChange}
          value={values.category}
          items={(categoriesFx.data ?? [])
            .map((category) => ({ label: category.name, value: category.name }))
            .concat([AddNewDropdownItem])}
          errorMessage={touched.category ? errors.category : ''}
        />
      ) : (
        <>
          <FormInput disabled label="Category" value="Farm Credit" />
          <FormInput disabled label="Unit" value="USD (Local currency is default)" />
        </>
      )}

      {!isDigital({ type }) && (
        <FormPickerInput
          label={<FormInputLabel label="Producer" tooltipId={ToolTips.BASIC_PRODUCER} />}
          items={
            producers.length
              ? producers.map((producer) => ({ label: producer, value: producer })).concat([AddNewDropdownItem])
              : [AddNewDropdownItem]
          }
          onValueChange={producerOnChange}
          value={values.producer}
          errorMessage={errors.producer}
        />
      )}
      {/* If it's a standard product and farm is ebt enabled, it can be managed by the user in the form UI. Otherwise it should be false by default in the form, and should get saved with the final data as false for standard. */}
      {isStandard({ type }) && farm.paymentTypes.ebt && values.catalog !== DefaultCatalog.Wholesale && (
        <FormPickerInput
          label={<FormInputLabel label="SNAP/EBT Eligibility" tooltipId={ToolTips.SNAP_EBT_ELIGIBILITY} required />}
          value={values.ebtEligibility}
          items={ebtOptsPickerItems}
          onValueChange={ebtEligibilityOnChange}
          errorMessage={errors.ebtEligibility}
        />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  sizeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  sizeInput: {
    flex: 1,
    flexBasis: 150,
  },
  longDescriptionCont: {
    height: 100,
  },
})
