import { getCoordString, validCoords } from '@helpers/coordinate'
import { errorToString, nonEmptyString, withTimeout } from '@helpers/helpers'
import { Optional, entries } from '@helpers/typescript'
import { Address, GooglePlace } from '@models/Address'
import { Coordinate } from '@models/Coordinate'
import { DataError, DualMessageError } from '@shared/Errors'
import * as Location from 'expo-location'
import * as Yup from 'yup'

import { env } from '../config/Environment'
import { CurrentLocation } from '../constants/types'

import { Logger } from '@/config/logger'
import { GoogleAddressParser } from '@/constants/GoogleAddressParser'
import { getShortState, isValidShortState, isValidZipcode, parsePostalCodeGeneric } from '@helpers/address'
import { addressSchema } from '@helpers/builders/AddressBuilder'
import { CountryCodeSchema, addressFieldsGenericSchemas } from '@helpers/builders/validators/sharedSchemasAddress'
import { CountryCode, findCountryData } from '@helpers/international/types'

export const GOOGLE_API_URL = 'https://maps.googleapis.com/maps/api/geocode/json'

/** These types were borrowed from expo Location */
export type GoogleApiGeocodingAddressComponent = {
  long_name: string
  short_name: string
  types: string[]
}

export type GoogleApiGeocodingResult = {
  address_components: GoogleApiGeocodingAddressComponent[]

  formatted_address: string

  geometry: {
    location: {
      lat: number

      lng: number
    }
  }
}

export type GoogleApiGeocodingResponse = {
  results: GoogleApiGeocodingResult[]

  status: string

  error_message?: string
}

/** Receives an address with no coordinates, geocodes it and returns the same address with the coordinates assigned. */
export async function geocode(address: Optional<Address, 'coordinate'>): Promise<Address> {
  // First create a basic string for the address. It is purposefully ignoring the street fields
  let query = `${address.city} ${address.state} ${address.zipcode}`

  if (address.street1.includes('#')) {
    /** If it has a "#" sign it may cause the geocoding to fail because as of now google api doesn't handle that correctly */

    // Any "#" signs should be removed because they will make it fail
    query = `${address.street1.replace('#', '').trim()} ` + query
  } else {
    // If it has no "#" sign just insert the street1 at the beginning with no modifications needed
    query = `${address.street1.trim()} ` + query
  }

  const fetchUrl = `${GOOGLE_API_URL}?address=${encodeURI(query)}&key=${env.API_KEY}`
  const response = await fetch(fetchUrl)

  const geocodeResponse: GoogleApiGeocodingResponse = await response.json()
  if (!geocodeResponse.results?.[0] || !validCoords(geocodeResponse.results[0].geometry.location)) {
    /** In case of error, please pass a data error to Logger.error, which contains data that can help debug the problem */
    const err = new DataError('Could not geo-code address', { geocodeResponse, fetchUrl, address })
    Logger.error(err)
    throw err
  }

  return {
    ...address,
    coordinate: {
      latitude: geocodeResponse.results[0].geometry.location.lat,
      longitude: geocodeResponse.results[0].geometry.location.lng,
    },
  }
}

/** Converts a coordinate into a set of results that include raw address data */
export async function reverseGeocode(coords: Coordinate): Promise<GoogleApiGeocodingResult[]> {
  const params = {
    latlng: getCoordString(coords),
  }
  const query = Object.entries(params)
    .map((entry) => `${entry[0]}=${encodeURI(entry[1])}`)
    .join('&')
  const fetchUrl = `${GOOGLE_API_URL}?key=${env.API_KEY}&${query}`
  const fetchResponse = await withTimeout(fetch(fetchUrl), 3000)
  const geocodingResponse = (await withTimeout(fetchResponse.json(), 3000)) as GoogleApiGeocodingResponse

  if (geocodingResponse.error_message) {
    /** In case of error, pass a data error to Logger.error, which contains data that can help debug the problem */
    const err = new DataError('Could not reverse geo-code', { coords, fetchUrl, geocodingResponse })
    Logger.error(err)
    throw err
  }

  return geocodingResponse.results
}

/** Reverse geo-codes the coords, and parses the google address component results */
export async function getParsedAddressFromCoords(coords: Coordinate) {
  const res = await withTimeout(reverseGeocode(coords), 5000)
  return new GoogleAddressParser(res[0].address_components)
}

/** geo location based on true device coordinates
 * - If this returns null it means permission was not obtained
 * - If this returns a Coordinate, it means it is the real exact location. No fallback location should be used
 */
export const loadExactCoords = async (): Promise<Coordinate | null> => {
  const { status } = await Location.requestForegroundPermissionsAsync().catch(() => {
    // If something goes wrong here we have earlier decided to not log this error
    // Perhaps should be reconsidered. Not sure why this would error out unless it was something wrong
    return { status: Location.PermissionStatus.DENIED }
  })

  if (status !== Location.PermissionStatus.GRANTED) {
    return null
  }

  const currPosition = await Location.getCurrentPositionAsync({
    accuracy: Location.Accuracy.High,
    timeInterval: 5000,
  })
  const coords: Coordinate = { latitude: currPosition.coords.latitude, longitude: currPosition.coords.longitude }
  if (validCoords(coords)) return coords
  throw new Error('Could not get valid exact coordinates')
}

/** geolocation based on ip address. Will get an approximate location for the device
 * - This should NOT return any fallback location if the IP geolocation fails or is invalid
 */
export async function geoLocateIPAddr(): Promise<CurrentLocation> {
  const corsUrl = `https://geolocation-db.com/json/`

  // The IP location calls might take a long time by nature, so we should impose a max wait limit.
  const response = await withTimeout(fetch(corsUrl), 3000)
  const body = (await withTimeout(response.json(), 3000)) ?? {}

  const coordinate: Coordinate = {
    latitude: body.latitude,
    longitude: body.longitude,
  }
  if (!validCoords(coordinate)) {
    // If the coordinates geocoded are not valid, we fall back to the default location
    throw new Error('Could not get a valid coordinate from the IP geolocation')
  }

  const loc: CurrentLocation = {
    coordinate,
    // city is not always found by the service (when using VPN for example)
    // If the city is not valid or missing, we return the current location, with this as an empty string
    name: !nonEmptyString(body.city) || body.city === 'Not found' ? '' : body.city,
    timestamp: Date.now(),
  }

  if (!loc.name) {
    Logger.warn(new DataError('IP location service is not obtaining the city correctly', { loc }))
  }

  return loc
}

/** The data result type for the api.geonames.org api.
 * - This is based on observation and may at some point not reflect the most up to date data structure
 * - The actual data returned may include other fields that are not in this type
 */
type NearbyZipcodesResponseData = {
  postalCodes?: { postalCode: string }[]
  status?: {
    message: string
    value: number
  }
}

/** Fetches a set of zipcodes around a supplied zipcode
 * @param radius is the radius to include around the zip code. Max 30 for the free service.
 */
export async function getNearbyZipcodes(zipcode: string, radius = 30, country: CountryCode): Promise<string[]> {
  if (!isValidZipcode(zipcode, country))
    throw new DataError('The zipcode is not valid for the current country', { zipcode, country })

  const url = `http://api.geonames.org/findNearbyPostalCodesJSON?postalcode=${zipcode}&country=${country.toUpperCase()}&radius=${radius}&username=khevamann&maxRows=500&style=short`

  const response = await fetch(url).catch()
  const data = await response.json()

  // Use a schema to validate the response data
  const NearbyZipCodesResponseSchema: Yup.ObjectSchema<NearbyZipcodesResponseData> = Yup.object().shape({
    postalCodes: Yup.array()
      .of(
        Yup.object().shape({
          /** In this postalCode string schema I'm intentionally not using any specialized schema other than to check it is a string.
           * Do not change that.
           * This fn in later steps will parse and filter some of these codes and keep only the ones that fulfill the format we want to keep
           * Why? Canadian google places may sometimes have 3-digit postal codes which are invalid as a full postal code, but they're used in canada to refer to a larger area
           */
          postalCode: Yup.string().required(),
        }),
      )
      .default(undefined),
    status: Yup.object()
      .shape({
        message: Yup.string().required(),
        value: Yup.number().required(),
      })
      .default(undefined),
  })

  const dataValidated = await NearbyZipCodesResponseSchema.validate(data).catch((e) => {
    const err = new DualMessageError({
      devMsg: `The findNearbyPostalCodesJSON API is not returning the expected data structure. ${errorToString(e)}.`,
      uiMsg: 'Something went wrong while fetching the nearby zip codes.',
      data: {
        zipcode,
        radius,
        data,
      },
    })
    Logger.error(err)
    throw err
  })

  if (dataValidated.postalCodes) {
    return (
      dataValidated.postalCodes
        .map((itm) => parsePostalCodeGeneric(itm.postalCode))
        // For Canada, this will only keep those nearby zipcodes that have the full 6-characters, and omit any 3-digit FSA codes
        .filter((code) => isValidZipcode(code, country))
    )
  } else if (dataValidated.status) {
    throw new DualMessageError({
      devMsg: 'The postal codes were not returned. ' + dataValidated.status.message,
      uiMsg: 'Something went wrong while fetching the nearby zip codes.',
      data: {
        zipcode,
        radius,
        data,
      },
    })
  } else {
    throw new DataError('Could not fetch the nearby zip codes for this zipcode.', { zipcode, radius, data })
  }
}

type PostalCodeLookupJSONResponse = {
  postalcodes: {
    /** Appears to be a 3 digit number */
    adminCode2?: string
    adminCode1: string
    /** Appears to be the county */
    adminName2?: string
    lat: number
    lng: number
    countryCode: CountryCode
    postalcode: string
    /** This is the state in the long form */
    adminName1: string
    /** This is the city */
    placeName: string
  }[]
}

const PostalCodeLookupJSONResponseSchema: Yup.ObjectSchema<PostalCodeLookupJSONResponse> = Yup.object().shape({
  postalcodes: Yup.array()
    .required()
    .of(
      Yup.object().shape({
        adminCode2: Yup.string(),
        adminCode1: addressFieldsGenericSchemas.state,
        adminName2: Yup.string(),
        lat: Yup.number().required(),
        lng: Yup.number().required(),
        countryCode: CountryCodeSchema,
        postalcode: addressFieldsGenericSchemas.zipcode,
        adminName1: Yup.string().required(),
        placeName: Yup.string().required(),
      }),
    )
    .default(undefined),
})

/** Geo-codes a zip code into an address with coordinates.
 * - The address will be missing the street field.
 */
export async function geocodeZipcode(
  zipcode: string,
  country: CountryCode,
): Promise<Omit<Address, 'street1' | 'street2'>> {
  if (!isValidZipcode(zipcode, country))
    throw new DataError('The zipcode is not valid for the current country', { zipcode, country })

  const url = `http://api.geonames.org/postalCodeLookupJSON?postalcode=${zipcode}&country=${country.toUpperCase()}&maxRows=1&username=khevamann`

  const response = await fetch(url).catch()
  const data = await response.json()
  const dataValidated = await PostalCodeLookupJSONResponseSchema.validate(data).catch((e) => {
    const err = new DualMessageError({
      devMsg: `The postalCodeLookupJSON API is not returning the expected data structure. ${errorToString(e)}.`,
      uiMsg: 'Something went wrong while fetching the zip code data',
      data: {
        zipcode,
        data,
      },
    })
    Logger.error(err)
    throw err
  })

  if (dataValidated.postalcodes) {
    const zipcodeDetails = dataValidated.postalcodes[0]
    const coordinate: Coordinate = { latitude: zipcodeDetails.lat, longitude: zipcodeDetails.lng }

    const address: Omit<Address, 'street1' | 'street2'> = {
      coordinate,
      city: zipcodeDetails.placeName,
      state: getShortState(zipcodeDetails.adminCode1, zipcodeDetails.countryCode)!,
      zipcode: parsePostalCodeGeneric(zipcodeDetails.postalcode),
      country,
    }

    const fieldErrors: string[] = []
    entries(address).map(async ([field, value]) => {
      addressSchema.validateAt(field, value, { context: { country } }).catch((e) => fieldErrors.push(errorToString(e)))
    })

    if (fieldErrors.length) {
      const err = new DualMessageError({
        devMsg: `The postalCodeLookupJSON API is not returning the expected data structure.`,
        uiMsg: 'Something went wrong while fetching the zip code data',
        data: {
          zipcode,
          data,
          fieldErrors,
        },
      })
      Logger.error(err)
      throw err
    }

    return address
  } else {
    throw new DataError('Something went wrong while fetching the zip code data', { zipcode, data })
  }
}

/**
 * Extracts location components from a Google Place object, falling back to reverse geocoding if needed.
 * - Fallback happens when GooglePlaces doesn't detect `state` and / or `zipcode` on search. Coordinate will always be defined
 */
export const extractLocationComponents = async (place: GooglePlace) => {
  // Try to get components from place first
  const parsed = place.address_components ? new GoogleAddressParser(place.address_components).getResult() : undefined

  let zipcode = parsed?.postal_code
  let state = parsed?.state
  let rawCountry = parsed?.country

  // Fall back to reverse geocoding if needed
  if (!zipcode || !state || !rawCountry) {
    try {
      const parser = await getParsedAddressFromCoords(place.coordinate)
      const result = parser.getResult()

      zipcode = result.postal_code
      state = result.state
      rawCountry = result.country
    } catch (err) {
      Logger.error(`Reverse geocoding of location failed for ${JSON.stringify(place)}`, err)
    }
  }

  const countryCode = findCountryData(rawCountry || '')?.code
  if (countryCode) {
    zipcode = isValidZipcode(zipcode || '', countryCode) ? zipcode : undefined
    state = isValidShortState(state || '', countryCode) ? state : undefined
  } else {
    zipcode = undefined
    state = undefined
  }
  return {
    zipcode,
    state,
    country: countryCode,
    coords: place.coordinate,
  }
}
