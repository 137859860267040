import { getNearbyZipcodes } from '@api/Addresses'
import { getState, isValidZipcode } from '@helpers/address'
import { buildQueryFilter } from '@helpers/algolia-client'
import { FSASchemaCanada } from '@helpers/builders/validators/sharedSchemasAddress'
import { removeDuplicates } from '@helpers/helpers'
import { CountryCode } from '@helpers/international/types'
import { FILTERS_QUERY, QueryFilterArray, QueryFilterBase } from '@models/Algolia'
import { Coordinate } from '@models/Coordinate'
import { LocType } from '@screens/SearchScreen/searchScreen-helpers'
import { UseApiFxReturn } from '../useApiFx'

type GetFilterStringProps = {
  isWholesale: boolean
  locType: LocType | undefined
  region: string | undefined
  includeNearbyZipcodes: boolean | undefined
  nearbyZipFx: UseApiFxReturn<typeof getNearbyZipcodes>
  center: Coordinate | undefined
  country: CountryCode
  farmIdsFilter: string[] | undefined
}

/**
 * Creates an Algolia filter string by combining various search parameters and conditions.
 * The resulting string is used to filter products based on wholesale/retail status,
 * location preferences, and specific farm selections. */
export function getFiltersString({
  isWholesale,
  locType,
  region,
  includeNearbyZipcodes,
  nearbyZipFx,
  center,
  country,
  farmIdsFilter,
}: GetFilterStringProps) {
  const filters: QueryFilterArray = [
    FILTERS_QUERY.Product,
    FILTERS_QUERY.NotInactiveFarm,
    FILTERS_QUERY.NotHidden,
    FILTERS_QUERY.NotPrivateProd,
  ]
  if (isWholesale) {
    filters.unshift(FILTERS_QUERY.Wholesale, FILTERS_QUERY.WholesaleFarm)
  } else {
    filters.unshift(FILTERS_QUERY.Retail)
  }

  if (farmIdsFilter?.length) {
    // Add farms filter as an array so it will generate a OR operation
    filters.push(farmIdsToFilter(farmIdsFilter))
  }

  const locFilterString = getLocationFilterString(locType, region, includeNearbyZipcodes, center, country, nearbyZipFx)

  if (locFilterString?.length) {
    // Add locations filter as an array so it will generate a OR operation
    filters.push(locFilterString)
  }

  return buildQueryFilter(filters)
}

/** Gets location-specific filters based on the location type and other flags*/
export function getLocationFilterString(
  locType: LocType | undefined,
  region: string | undefined,
  includeNearbyZipcodes: boolean | undefined,
  center: Coordinate | undefined,
  country: CountryCode,
  nearbyZipFx: UseApiFxReturn<typeof getNearbyZipcodes>,
): QueryFilterBase[] | undefined {
  if (!locType) return undefined

  switch (locType) {
    case 'coord':
      return handlePickup(center)
    case 'state':
      return handleShipping(region, country)
    case 'zip':
      return handleDelivery(region, country, nearbyZipFx, includeNearbyZipcodes)
  }
}

/** Will handle pickup type */
const handlePickup = (center: Coordinate | undefined): QueryFilterBase[] | undefined => {
  if (!center) {
    // If filtering by pickup, and the center coords is undefined, it must show any documents where there's no regions because these are the ones that belong to a pickup location
    // This must be done here because normally the geolocation search is controlled via the aroundLatLng property, but since the center is undefined, aroundLatLng is also undefined.
    // So this allows us to see products with at least a pickup option when clearing the center coords
    return [`regions:None`]
  }
}

/** Handles shipping location type. Will return the zipcode as filter if valid
 * - Can include nearby zipcodes as well
 */
const handleDelivery = (
  region: string | undefined,
  country: CountryCode,
  nearbyZipFx: UseApiFxReturn<typeof getNearbyZipcodes>,
  includeNearbyZipcodes: boolean | undefined,
): QueryFilterBase[] | undefined => {
  if (!region) {
    // If there's no region parameter, but the location type is a NonPickup type, we can show only documents that have any region
    return [`NOT regions:None`]
  }

  // If the location type is delivery the region must be a valid postal code for the country, or if Canada it also allows an FSA code
  if (!isValidZipcode(region, country) && (country !== 'CA' ? true : !FSASchemaCanada.isValidSync(region)))
    return undefined

  const regions = [region]

  if (includeNearbyZipcodes && !nearbyZipFx.loading && nearbyZipFx.data?.length) {
    regions.push(...nearbyZipFx.data)
  }

  // removeDuplicates is here because the flatMap might create duplicate filters for the FSA codes, since different postal codes might have the same 3 first characters if they're close together
  return removeDuplicates(
    regions
      // This is an artificial limit to the number of zip codes we'll consider nearby the user-selected region. If this is increased I recommend testing for performance
      // In Canada I'm setting a higher value because postal codes are much smaller and closer together than in the US
      .slice(0, country === 'CA' ? 300 : 100)
      // This flatMap might create two filters for each nearby code when country is Canada to match both the postal code and the FSA code in the algolia regions
      .flatMap<QueryFilterBase>((code) => {
        const filters: QueryFilterBase[] = [`regions:${code}`]
        if (country === 'CA' && code.length > 3) {
          // If Canada the search must find products at the specific postal code, and also at the FSA code area
          // So if the region is a full postal code (We can assume a region with more than 3 chars in length is a full Canadian postal code) then we must here also add an additional OR filter with the FSA code only (The first 3 chars)
          filters.push(`regions:${code.slice(0, 3)}`)
        }

        return filters
      }),
  )
}

/** Handles shipping location type. Will return the state as filter if valid */
const handleShipping = (region: string | undefined, country: CountryCode): QueryFilterBase[] | undefined => {
  if (!region) {
    // If there's no region parameter, but the location type is a NonPickup type, we can show only documents that have any region
    return [`NOT regions:None`]
  }

  // If location type is state, the region must be a valid state
  if (!getState(region, country)) return undefined

  return [`regions:${region}`]
}

/** Maps farm ids to filter query array */
const farmIdsToFilter = (farmIds: string[]): QueryFilterBase[] => {
  return farmIds.map((farmId) => {
    const value: QueryFilterBase = `farm.id:${farmId}`
    return value
  })
}
