import { globalStyles } from '@/constants/Styles'
import { Button, Text } from '@elements'
import { formatMoney } from '@helpers/display'
import { Money } from '@models/Money'
import { StyleSheet, View } from 'react-native'

type CartSidebarHeaderProps = {
  subtotal: Money
}

/** Sidebar header component, containing the subtotal of the farm's products and Cart button */
export function CartSidebarHeader({ subtotal }: CartSidebarHeaderProps) {
  return (
    <View style={styles.main}>
      <Text center type="medium" size={13}>
        Subtotal
      </Text>
      <Text center type="medium" size={18}>
        {formatMoney(subtotal)}
      </Text>
      <View style={globalStyles.margin10} />
      <Button url="/farms/shop/my-cart" outline title="Go to cart" />
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    alignItems: 'center',
    margin: 10,
  },
})
