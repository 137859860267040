// app.config.ts needs to be updated whenever this changes
export const GROWNBY_APP_URL = 'https://grownby.app'

export const COOP_WEB_URL = 'https://coop.grownby.com'
export const COOP_WEB_ONBOARD_URL = 'https://coop.grownby.com/farmers'
export const NEW_ZOHO_TICKET = 'https://support.grownby.com/portal/en/newticket'

/** NOTE: If updating the below emails you must make sure to update and redeploy the email extension, as well as mailgun */
export const SUPPORT_EMAIL = 'support@grownby.app'
export const NOREPLY_SUPPORT_EMAIL = 'noreply@grownby.app'

/** Constants containing retail urls*/
const RETAIL_URLS = {
  local: 'http://localhost:19006',
  dev: 'https://grownby-dev.web.app',
  staging: 'https://grownby-staging.web.app',
  beta: 'https://grownby-beta.web.app',
  prod: GROWNBY_APP_URL,
}

/** Constants containing wholesale urls*/
const WHOLESALE_URLS = {
  local: 'http://localhost:19006',
  dev: 'https://grownby-dev-wholesale.web.app',
  staging: 'https://grownby-staging-wholesale.web.app',
  beta: 'https://grownby-beta-wholesale.web.app',
  prod: 'https://wholesale.grownby.com',
}

type Options = {
  isWholesale: boolean | undefined
}

/** Will return the GrownBy App url for the given options */
export function getBaseUrl(env: 'dev' | 'staging' | 'beta' | 'prod' | 'local', { isWholesale }: Options) {
  const URL_SET = isWholesale ? WHOLESALE_URLS : RETAIL_URLS
  return URL_SET[env]
}
