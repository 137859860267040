import { CustomShareLogTypes, CustomShareRun } from '@models/CustomShare'
import Colors from '../../../constants/Colors'
import { AdminCard } from '../../components/AdminCard'
import { StyleSheet, View } from 'react-native'
import { Divider, Text } from '@elements'
import { formatPickupDate, formatTime } from '@helpers/display'
import React from 'react'
import { formatShareRunType } from '@helpers/custom_shares/display'

const getStatusColor = (status: CustomShareRun['status']) => {
  switch (status) {
    case 'success':
      return Colors.green
    case 'warning':
      return Colors.yellow
    case 'error':
      return Colors.red
    case 'running':
      return Colors.blue
    default:
      return Colors.shades[500]
  }
}

const getMessagePrefix = (type: CustomShareLogTypes) => {
  switch (type) {
    case CustomShareLogTypes.Error:
      return { text: 'ERROR:', color: Colors.red }
    case CustomShareLogTypes.Warn:
      return { text: 'WARNING:', color: Colors.yellow }
    default:
      return { text: '', color: Colors.shades[500] }
  }
}

/** The run card will hold a run of the custom share and show the status and any warnings from that run */
export function CustomShareRunCard({ run, isTestRun }: { run: CustomShareRun; isTestRun?: boolean }) {
  return (
    <AdminCard>
      <View style={styles.runHeader}>
        <View>
          <Text size={16} type="bold">
            {isTestRun ? `(TEST RUN): ` : ''}
            {formatShareRunType(run.type)}
          </Text>
          <Text size={12} color={Colors.shades[200]}>
            {formatPickupDate(run.date)} at {formatTime(run.date)}
          </Text>
        </View>
        <Text type="bold" color={getStatusColor(run.status)} style={styles.statusText}>
          {run.status.toUpperCase()}
        </Text>
      </View>

      {run.messages.length > 0 && (
        <View>
          <Divider />
          {run.messages.map((msg, index) => {
            const prefix = getMessagePrefix(msg.type)
            return (
              <Text key={index}>
                {!!prefix.text && (
                  <Text type="bold" color={prefix.color}>
                    {prefix.text}{' '}
                  </Text>
                )}
                <Text>{msg.message}</Text>
              </Text>
            )
          })}
        </View>
      )}
    </AdminCard>
  )
}

const styles = StyleSheet.create({
  runHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 10,
  },
  statusText: {
    textAlign: 'right',
  },
})
