import { Pickup, PickupItem } from '@models/Order'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { deepClone } from '@helpers/helpers'
import { unmarshalAddress } from './Address'
import { unmarshalDistribution } from './Distribution'
import { marshalPaymentSchedule, unmarshalPaymentSchedule } from './Product'
import { marshalDate, unmarshalDate } from './Time'
import { prepareMarshal, prepareUnmarshal } from './encoding'

/** marshalPickup transforms the Pickup structure into a Firestore data. */
export function marshalPickup(pickup: Partial<Pickup>): DocumentData {
  const data = prepareMarshal(pickup) as DocumentData

  if (pickup.date) {
    data.date = marshalDate(pickup.date)
  }

  if (Array.isArray(pickup.items)) {
    data.items = pickup.items.map((item) => marshalPickupItem(item))
  }
  return data
}

/** unmarshalPickup transforms the Firestore data to an Pickup object. */
export function unmarshalPickup(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): Pickup {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)
  const pickup = { ...data, id } as Pickup

  if (data.date) {
    pickup.date = unmarshalDate(data.date)!
  }
  if (Array.isArray(data.items)) {
    pickup.items = data.items.map((item) => unmarshalPickupItem(item))
  }

  if (data.distribution.address) {
    pickup.distribution.address = unmarshalAddress(data.distribution.address)
  }

  if (data.oldDistribution)
    pickup.oldDistribution = unmarshalDistribution(data.oldDistribution.id, data.oldDistribution)

  return pickup
}

/** marshalPickup transforms the Pickup structure into a Firestore data. */
export function marshalPickupItem(pickupItem: Partial<PickupItem>): DocumentData {
  const data = deepClone(pickupItem) as DocumentData

  if (pickupItem.orderItem?.paymentSchedule) {
    data.orderItem.paymentSchedule = marshalPaymentSchedule(pickupItem.orderItem.paymentSchedule)
  }

  if (pickupItem.order?.date) {
    data.order.date = marshalDate(pickupItem.order.date)
  }

  return data
}

/** unmarshalPickupItem transforms the Firestore data to an Pickup object. */
export function unmarshalPickupItem(data: DocumentData): PickupItem {
  const pickupItem = deepClone(data) as PickupItem

  if (data.orderItem?.paymentSchedule) {
    pickupItem.orderItem.paymentSchedule = unmarshalPaymentSchedule(data.orderItem.paymentSchedule)
  }

  if (data.order?.date) {
    pickupItem.order.date = unmarshalDate(data.order.date)
  }

  if (data.oldDistribution) pickupItem.oldDistribution = unmarshalDistribution(data.oldDistribution)

  return pickupItem
}
