import { makeUserWholesale } from '@api/Users'
import { Alert, Toast } from '@elements'
import { User } from '@models/User'

export const onInvitingExistingRetailCustomerAlert = (user: User, callback: () => void) => {
  Alert('Change to Wholesale Customer', 'User is a retail customer already. Invite them to your wholesale shop?', [
    { text: 'Cancel', style: 'cancel' },
    {
      text: 'Proceed',
      style: 'default',
      onPress: async () => {
        makeUserWholesale(user.id).then(() => {
          Toast('Customer successfully modified')
          callback()
        })
      },
    },
  ])
}

export const onInvitingAdminCustomerAlert = () => {
  Alert(
    'Unable to add user',
    'This customer is also the admin of a farm and cannot be added, please reach out to customer support for assistance.',
    [
      {
        text: 'Ok',
        style: 'default',
      },
    ],
  )
}
