import { AdminProductsParamList } from '@/admin/navigation/types'
import { globalStyles } from '@/constants/Styles'
import { useApiFx } from '@/hooks/useApiFx'
import { loadTemplateProductsByFarmId } from '@api/TemplateProduct'
import { AddNewDropdownItem, Alert, ButtonClear, CheckBox, ErrorText, FormPickerInput, ToolTips } from '@elements'
import { sortByName } from '@helpers/sorting'
import { Product, TemplateProduct } from '@models/Product'
import { Media } from '@models/shared/Media'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useFormikContext } from 'formik'
import { memo, useCallback, useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { ProductTypeForm } from '../../helpers/ProductTypeInfo'
import { BasicInfoForm } from '../BasicInformation.helpers'

type TemplateSectionProps = {
  product?: Product
  farmId: string
}

/** Component to handle the template checkbox and select templates functionality*/
export const TemplateSection = memo(function TemplateSection({ product, farmId }: TemplateSectionProps) {
  const navigation = useNavigation<StackNavigationProp<AdminProductsParamList, 'AddProduct' | 'EditProduct'>>()

  const { values, errors, touched, dirty, setValues, setTouched, setFieldValue } = useFormikContext<
    BasicInfoForm & ProductTypeForm
  >()
  const templatesFx = useApiFx(loadTemplateProductsByFarmId, [farmId], !!farmId && values.isChild, {
    initialState: { loading: false },
  })

  const templateUsed = !!(values.isChild && values.templateProductId)

  const exitScreen = useCallback(
    (onExit: () => void) => {
      if (dirty) {
        Alert("Your changes aren't saved!", 'Do you want to continue without saving or want to go back?', [
          { text: 'Cancel', style: 'cancel' },
          {
            text: 'Continue without saving',
            onPress: onExit,
          },
        ])
      } else {
        onExit()
      }
    },
    [dirty],
  )

  const onPickTemplate = (value: string) => {
    if (value === AddNewDropdownItem.value) {
      return exitScreen(() => {
        navigation.replace('AddTemplate', {
          goBack: product ? 'EditProduct' : 'AddProduct',
          prodId: product?.id,
          prodType: values.type,
        })
      })
    }

    const template = templatesFx.data?.find((t) => t.id === value)

    if (!value || !template) {
      setValues({ ...values, ...resetTemplateFields() }, false)
      setTouched({ ...touched, ...untouchedFields })
      return
    }

    setValues(
      {
        ...values,
        ...mapTemplateToFields(template),
      },
      true,
    )
  }

  const onCheckboxPress = async () => {
    if (values.isChild && values.templateProductId) {
      setValues((values) => ({ ...values, ...resetTemplateFields() }))
    }

    await setFieldValue('isChild', !values.isChild)
    setTouched({ ...touched, isChild: true })
  }

  const onEditTemplatePress = useCallback(() => {
    exitScreen(() => {
      if (values.templateProductId) {
        navigation.replace('EditTemplate', {
          templateId: values.templateProductId,
          goBack: product ? 'EditProduct' : 'AddProduct',
          prodId: product?.id,
          prodType: values.type,
        })
      }
    })
  }, [exitScreen, values.templateProductId, values.type, navigation, product])

  const items = useMemo(() => {
    if (!templatesFx.data) return []

    return [...templatesFx.data]
      .sort((a, b) => sortByName(a, b, (val) => val.name))
      .map((template) => ({ label: template.name, value: template.id }))
      .concat(AddNewDropdownItem)
  }, [templatesFx.data])

  return (
    <View>
      <CheckBox
        style={globalStyles.marginHorizontal10}
        checked={!!values.isChild}
        onChange={onCheckboxPress}
        title="Use a template"
        toolTipId={ToolTips.TEMPLATE}
        toolTipTitle="The Template"
      />
      {values.isChild && !!touched.isChild && !!errors.isChild && (
        <View style={globalStyles.margin10}>
          <ErrorText>{errors.isChild as string}</ErrorText>
        </View>
      )}
      {!!values.isChild && (
        <FormPickerInput
          value={values.templateProductId}
          placeholder={{
            label: !templateUsed ? 'Select an Option' : "Don't use template",
            value: '',
          }}
          loading={templatesFx.loading}
          items={items}
          onValueChange={onPickTemplate}
        />
      )}
      {templateUsed && (
        <ButtonClear small title="Edit Share Template" style={styles.editBtn} onPress={onEditTemplatePress} />
      )}
    </View>
  )
})

const styles = StyleSheet.create({
  editBtn: {
    alignSelf: 'flex-start',
    marginLeft: 10,
  },
})

const mapTemplateToFields = (template: TemplateProduct) => {
  return {
    shareInfo: template.shareInfo,
    templateProductName: template.name,
    templateProductId: template.id,
    longDescription: template.longDescription,
    shortDescription: template.description,
    category: template.category,
    media:
      template.images.map((img) => ({
        storageUrl: img,
        type: 'image',
      })) || ([] as Media[]),
    shareSku: template.sku,
    producer: template.producer,
    productionMethod: template.productionMethod,
  }
}

const resetTemplateFields = () => {
  return {
    templateProductId: '',
    shareInfo: { maximumNumberOfItems: 0, minimumNumberOfItems: 0, suggestedFamilySize: 0 },
    templateProductName: '',
    longDescription: '',
    shortDescription: '',
    category: '',
    media: [],
    shareSku: '',
    producer: '',
    productionMethod: '',
  }
}

const untouchedFields = {
  shareInfo: false,
  templateProductName: false,
  templateProductId: false,
  longDescription: false,
  shortDescription: false,
  category: false,
  media: false,
  shareSku: false,
  producer: false,
  productionMethod: false,
}
