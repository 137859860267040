import { createFullRefund, rechargeInvoice } from '@api/Invoices'
import { Alert, Loader, Modal, Toast } from '@elements'
import { formatInvoiceNum, formatMoney } from '@helpers/display'
import { errorToString } from '@helpers/helpers'
import { MoneyCalc } from '@helpers/money'
import { Farm } from '@models/Farm'
import { getInvoiceTips, Invoice, isOffline } from '@models/Invoice'

import { ChangePayments } from '../ActionModals/ChangePayments'
import { CreateInvoice } from '../ActionModals/CreateInvoice'
import { MarkPaid } from '../ActionModals/MarkPaid'
import { VoidInvoice } from '../ActionModals/VoidInvoice'

import { Logger } from '@/config/logger'
import { AdminRefundInvoiceModal } from '@/admin/screens/Invoice/AdminRefundInvoiceModal'
import { AdjustInvoice } from '../ActionModals/AdjustInvoice'

export const createInvoice = (userId: string, farm: Farm) => {
  Modal(<CreateInvoice userId={userId} farm={{ id: farm.id, name: farm.name }} />, { title: 'Create An Invoice' })
}

export const toggleVoidInvoice = (inv: Invoice, voidInv: boolean) => {
  Modal(<VoidInvoice invoiceId={inv.id} voidInv={voidInv} />, { title: `${voidInv ? 'Void' : 'Un-void'} Invoice` })
}

export const markInvoicePaid = (inv: Invoice, onPaid?: () => void) => {
  Modal(<MarkPaid invoiceId={inv.id} onPaid={onPaid} />, { title: 'Mark Invoice as Paid' })
}

export const addInvoiceAdjustment = (inv: Invoice, onSuccess?: () => void) => {
  Modal(<AdjustInvoice invoiceId={inv.id} onSuccess={onSuccess} />, { title: 'Add Invoice Adjustment' })
}

export const changeInvoicePayments = (invoice: Invoice) => {
  Modal(<ChangePayments invoice={invoice} />, { title: 'Change how this invoice will be paid' })
}

export const chargeInvoice = (inv: Invoice) => {
  Alert('Charge Invoice', 'This will attempt to charge the invoice with the payment method on file.', [
    {
      text: 'Cancel',
      style: 'cancel',
    },
    {
      text: 'Charge',
      onPress: () => {
        Loader(true)
        rechargeInvoice(inv.id)
          .then(() => {
            Loader(false)
            Alert('Success', 'Charge Successful. The changes will show up shortly.')
          })
          .catch((err) => {
            Logger.error(err)
            Loader(false)
            Alert('Charge Failed', `${errorToString(err)}`)
          })
      },
    },
  ])
}

export const refundInvoice = (inv: Invoice) => {
  if (!inv.amountPaid || MoneyCalc.isZero(inv.amountPaid))
    return Toast('Cannot refund an invoice that has not been paid')

  if (isOffline(inv)) {
    const refundAmount = MoneyCalc.subtract(inv.amountPaid, getInvoiceTips(inv))

    const refundTitle = 'Mark Refunded Offline'
    const refundText =
      'If you have received an offline payment, you can mark this invoice as refunded; however, this will not credit funds to the customer. You can refund offline or issue farm credit on the customer detail page.'

    Alert(`${refundTitle} (${formatMoney(refundAmount)})`, refundText, [
      {
        text: 'Cancel',
        style: 'cancel',
      },
      {
        text: 'Mark Refund',
        onPress: () => {
          Loader(true)
          createFullRefund(inv)
            .then((res) => {
              Loader(false)
              if (res.success) {
                Alert(
                  'Success',
                  'Invoice has been successfully marked as refunded offline. The changes will show up shortly.',
                )
              } else {
                Alert('Fail', 'Mark Refunded Offline Failed. Please contact support for assistance.')
              }
            })
            .catch((err) => {
              Logger.error(err)
              Loader(false)
              Alert('Mark Refunded Offline Failed', `${errorToString(err)}`)
            })
        },
      },
    ])
  } else {
    Modal(<AdminRefundInvoiceModal invoice={inv} />, {
      title: 'Refund Invoice - ' + formatInvoiceNum(inv.invoiceNum),
    })
  }
}
