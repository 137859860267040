import { ToolTips } from '@components'
import { Button, HeaderText, Icon, Tooltip } from '@elements'
import { StyleSheet, View } from 'react-native'

import Colors from '@/constants/Colors'
import { userSelector } from '@/redux/selectors'
import { UserRole, hasGrownByPrivilege } from '@models/User'
import { memo } from 'react'
import { useSelector } from 'react-redux'

type Props = {
  goBack(): void
  title: string
  actionTitle: 'Edit' | 'Save' | 'Back' | 'Submit'
  onPress(): void
  isLoading?: boolean
  toolTipId?: ToolTips
  toolTipTitle?: string
  dataId?: string
}

export const EditHeader = memo(function EditHeader({
  goBack,
  onPress: goAction,
  actionTitle,
  title,
  isLoading,
  toolTipId,
  toolTipTitle,
  dataId,
}: Props) {
  const user = useSelector(userSelector)
  const isGrownByAdmin = hasGrownByPrivilege(user, UserRole.Admin)

  return (
    <View style={styles.container}>
      <Icon name="times" color={Colors.shades[500]} onPress={goBack} />
      <View style={styles.headerRow}>
        <HeaderText size={18}>
          {title} {toolTipId && <Tooltip title={toolTipTitle ?? title} id={toolTipId} />}
        </HeaderText>
        {isGrownByAdmin && !!dataId && (
          <HeaderText style={styles.dataId} color={Colors.shades[300]} size={14}>
            ({dataId})
          </HeaderText>
        )}
      </View>
      <Button small title={actionTitle} onPress={goAction} loading={isLoading} />
    </View>
  )
})

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 5,
    paddingHorizontal: 20,
    borderBottomWidth: 1,
    borderBottomColor: Colors.shades['100'],
  },
  headerRow: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'flex-start',
    marginLeft: 20,
    marginRight: 10,
  },
  dataId: {
    marginLeft: 4,
  },
})
