import Colors from '@/constants/Colors'
import { useCartService } from '@/hooks/useCart'
import { wholesaleSelector } from '@/redux/selectors'
import { Text } from '@elements'
import { AntDesign } from '@expo/vector-icons'
import { canUpdateQuantity } from '@helpers/canUpdateQuantity'
import { useMemo } from 'react'
import { View } from 'react-native'
import { Button } from 'react-native-elements'
import { useSelector } from 'react-redux'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'
import { ProductDetailsProps } from './ProductDetails'

/** Stepper used inside CartItems on MyCart Screen */
export function QuantityStepper({ item, setQuantity, loading }: ProductDetailsProps) {
  const { isWholesale } = useSelector(wholesaleSelector)
  const { cart } = useCartService()
  const styles = useStyles()

  const canIncreaseQty = useMemo(
    () =>
      canUpdateQuantity({
        delta: 1,
        cartItem: item,
        cart,
        isWholesale,
      }),
    [cart, isWholesale, item],
  )

  return (
    <View style={styles.stepper}>
      <Button
        type="clear"
        onPress={() => setQuantity(-1)}
        containerStyle={[styles.stepperIconContainer, styles.stepperIconContainerLeft]}
        icon={<AntDesign name="minuscircleo" size={18} color={Colors.green} />}
        loading={loading}
      />
      <Text style={styles.amtText} size={14} type="medium">
        {item.quantity}
      </Text>
      <Button
        type="clear"
        onPress={() => setQuantity(1)}
        containerStyle={[styles.stepperIconContainer, styles.stepperIconContainerRight]}
        icon={<AntDesign name="pluscircleo" size={18} color={!canIncreaseQty ? Colors.shades[200] : Colors.green} />}
        disabled={!canIncreaseQty}
        loading={loading}
      />
    </View>
  )
}

const useStyles = CreateResponsiveStyle(
  {
    amtText: {
      textAlign: 'center',
      alignSelf: 'center',
    },
    stepper: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderWidth: 1,
      borderColor: Colors.green,
      borderRadius: 10,
      paddingVertical: 8,
      width: 100,
      height: 34,
      marginTop: 8,
    },
    stepperIconContainer: {
      flex: 1,
      width: 34,
      height: 34,
      justifyContent: 'center',
      alignItems: 'center',
    },
    stepperIconContainerLeft: {
      marginRight: 8,
    },
    stepperIconContainerRight: {
      marginLeft: 8,
    },
  },
  {
    [maxSize(DEVICE_SIZES.XS)]: {
      stepper: {
        flexDirection: 'column-reverse',
        marginTop: 0,
        paddingVertical: 0,
        height: 100,
        width: 40,
      },
      stepperIconContainerLeft: {
        marginRight: 0,
      },
      stepperIconContainerRight: {
        marginLeft: 0,
      },
    },
  },
)
