import { csasCollection, distrosCollection } from '@api/framework/ClientCollections'
import { CsaSelector, DateSelector } from '@components'
import { Loader, Modal, Toast, hideModal } from '@elements'
import { errorToString } from '@helpers/helpers'
import { findPriceForAppMode, isInStock, matchesAppModeBO } from '@helpers/products'
import { CSA } from '@models/CSA'
import { NonPickup, isNonPickupDistLocation } from '@models/Location'
import { CartItem, CartStandard, ItemNonPickup, isCartStandard } from '@models/Order'
import {
  PaymentSchedule,
  Product,
  Unit,
  UnitProduct,
  hasUnits,
  isAddon,
  isDigital,
  isShare,
  isStandard,
} from '@models/Product'
import { UserAddress } from '@models/UserAddress'
import { useCallback, useMemo, useState } from 'react'

import { useAvailAddons } from '../../useAvailAddons'
import { useCartService } from '../useCartService'

import { Logger } from '@/config/logger'
import { AddToCartServiceOpts, CartServiceType } from '@/constants/types/cartService'
import { addToCartConfirmation } from '@/hooks/useCart'
import { canUpdateQuantity } from '@helpers/canUpdateQuantity'
import { plural } from '@helpers/display'
import { formatDistributionType } from '@helpers/location'
import { defaultAddonNotAvailableText } from '@helpers/products-display'
import { Distribution } from '@models/Distribution'
import { DataError } from '@shared/Errors'
import { DateTime } from 'luxon'
import { EditDeliveryAddress } from '../../../components/AddToCartFlow-components/EditDeliveryAddress'
import { UnitSelection } from '../../../components/AddToCartFlow-components/UnitSelection'
import { clearGetPickupsCacheAddtoCartFlow } from './helpers'
import { SetLocationFlow, useSetLocationFlow } from './useSetLocationFlow'

export type AddToCartFlow = (
  /** These props are the most basic starting point for adding a product to the cart. They're all optional except the product because the flow guides the user through any necessary selection */
  opts: Pick<AddToCartServiceOpts, 'product' | 'unit' | 'csa' | 'pickups' | 'paymentSchedule' | 'distribution'>,
) => Promise<CartItem | null>

type UseAddToCartFlowReturn = {
  addToCartFlow: AddToCartFlow
  setLocationFlow: SetLocationFlow
  modifyDates: (id: CartStandard['id']) => Promise<void>
  isLoadingAddCartFlow: boolean
  selectCsa: (opts: {
    /** Product to add to cart */
    prod: Product
    /** Pre-selected csa for this product, if any */
    csa?: CSA
  }) => Promise<CSA | undefined>
  editDeliveryAddress: (
    /** a cartItem expected to have a delivery schedule */ item: ItemNonPickup,
  ) => Promise<NonNullable<NonPickup['address']> | void>
}

export type OnSuccessFn = (result: CartItem | void, opts?: Parameters<typeof addToCartConfirmation>[1]) => void

/** Provides a set of high level helpers for interacting with the cart service through elaborate sequences of modals that await each other in a flow that culminates in calling cart service APIs */
export const useAddToCartFlow = ({
  cartServiceType = 'consumer',
  isWholesale,
}: {
  cartServiceType: CartServiceType
  /** modifies behaviors specific to the given catalog mode */
  isWholesale?: boolean
}): UseAddToCartFlowReturn => {
  const [loading, setLoading] = useState(false)
  const { cart, addToCart, updateCartItem, isAdmin } = useCartService({
    cartServiceType,
    isWholesale,
    farmId: undefined, // This doesn't need a farm id because the addToCart flow isn't farm-specific
  })
  const { availAddonsResults } = useAvailAddons() // avail addons are only used in consumer cart service type
  const setLocationFlow = useSetLocationFlow({ cartServiceType, isWholesale })

  const selectCsa = useCallback<UseAddToCartFlowReturn['selectCsa']>(
    async ({ prod, csa: csaArg }) => {
      let selectedCsa: CSA | undefined = undefined

      // If there's more than 1 csa, allow choosing
      if (isShare(prod) && !csaArg && prod.csa.length > 1) {
        // Open csa selector modal
        selectedCsa = await new Promise<CSA | undefined>((resolve) =>
          Modal(
            <CsaSelector
              prod={prod}
              onSelect={(csa) => {
                hideModal()
                resolve(csa)
              }}
              isAdmin={isAdmin}
            />,
            {
              title: 'Select a CSA for this Share',
              onDismiss: () => {
                hideModal()
                resolve(undefined)
              },
              webWidth: 1000,
            },
          ),
        )
      } else {
        // Try to use any csaArg
        selectedCsa = csaArg

        if (!selectedCsa && isShare(prod)) {
          // If there's only one csa, fetch and assign if not hidden
          if (prod.csa[0]) {
            const csa = await csasCollection.fetch(prod.csa[0])
            if (!csa.isHidden) selectedCsa = csa
          } else {
            throw new Error(`Tried to add-to-cart a share without any CSA IDs. Product ID: (${prod.id})`)
          }
        }
      }
      return selectedCsa
    },
    [isAdmin],
  )

  /** This runs when a unit is selected, either by automatic or manual selection */
  const onUnitSelected = useCallback(
    async ({
      product,
      unit,
      onSuccess,
      onErr,
      csa,
      paymentSchedule,
      distribution: preselectedSchedule,
      pickups,
    }: {
      product: UnitProduct
      unit: Unit
      onSuccess: OnSuccessFn
      onErr: (err: unknown) => void
      csa?: CSA
      paymentSchedule?: PaymentSchedule
      distribution?: Distribution
      pickups?: DateTime[]
    }) => {
      if (typeof isWholesale !== 'boolean') {
        // This will never happen because the unit selection modal should not run if isWholesale is not defined
        return
      }

      // Stock check
      const canAddResult = canUpdateQuantity({
        cart,
        cartItem: { product, quantity: 1, unit },
        isWholesale,
      })
      if (!canAddResult) {
        return onSuccess(undefined, {
          msg: 'The stock is insufficient',
          alertType: 'alert',
          showMsg: true,
        })
      }

      if (isStandard(product)) {
        // Standard: Continue to location selection with the auto-selected unit
        return setLocationFlow({
          prod: product,
          unit,
          csa,
          paymentSchedule,
          preselectedSchedule,
          preselectedDates: pickups,
          onSuccess,
          onErr,
        })
      } else if (isDigital(product)) {
        // Digital: Add to cart now with the auto-selected unit

        try {
          hideModal()
          Loader(true)
          const res = await addToCart({
            product,
            unit,
            price: findPriceForAppMode(unit.prices, isWholesale),
            csa,
            isAdmin,
          })
          return onSuccess(res, { autoAddedDistro: false, autoAddedDates: false })
        } catch (error) {
          return onErr(error)
        }
      }
    },
    [addToCart, cart, isAdmin, isWholesale, setLocationFlow],
  )

  /**
   * The first step in adding a product to cart. Presents one or more modals to the user, with the appropriate options for adding the particular type of product to cart.
   */
  const addToCartFlow = useCallback<AddToCartFlow>(
    ({ product, unit: unitProp, csa: csaArg, pickups, paymentSchedule, distribution }) =>
      new Promise(async (resolve, reject) => {
        setLoading(true)

        /** onErr should only be called when there is an actual unexpected error */
        const onErr = (err: unknown) => {
          Logger.error(err)
          Loader(false)
          hideModal()
          setLoading(false)
          addToCartConfirmation(undefined, { msg: errorToString(err), showMsg: false })
          clearGetPickupsCacheAddtoCartFlow()
          reject(err)
        }

        /** onSuccess should be called either on early return, cancellation and on successful adding to cart */
        const onSuccess: OnSuccessFn = (result, opts) => {
          Loader(false)
          hideModal()
          setLoading(false)
          addToCartConfirmation(result, opts)
          clearGetPickupsCacheAddtoCartFlow()
          resolve(result || null)
        }

        if (isWholesale === undefined) {
          Logger.error(
            new Error("The addToCart flow is being called before the app mode is defined. This shouldn't happen"),
          )
          return onSuccess(undefined, { msg: 'Some resources are still initializing. Please try again', showMsg: true })
        }

        /** Validate the product has some stock to add. The UI should prevent this before the addToCart flow is called */
        if (!isInStock(product, { isWholesale })) {
          return onSuccess(undefined, { msg: 'The product is out of stock', alertType: 'alert', showMsg: true })
        }

        /** CSA Selection.
         *
         * This step will handle whether a CSA must be selected with a modal, or the flow can continue with auto-selection, based on the product type and its unique requirements */
        let selectedCsa = csaArg
        try {
          selectedCsa = await selectCsa({
            prod: product,
            csa: csaArg,
          })
          if (!selectedCsa && isShare(product)) {
            return onSuccess(undefined, { msg: 'There are no CSAs available for this product', showMsg: true })
          }
        } catch (err) {
          return onErr(err)
        }

        if (isAddon(product)) {
          // If it's an addon, restrict which distros can be selected based on cart and past purchases

          let matchingSchedulesAvailAddon: Distribution[] | undefined = undefined

          if (!isAdmin) {
            //If it's an addon in consumer mode, get the availAddonResult for the addon id
            const availAddonResult = availAddonsResults.find((addon) => addon.id === product.id)

            /** The addon result will only be calculated for the cart farm and the past purchases.
             * Therefore not all addons will be found within the availAddon results when the cart is empty
             * If the addon is not available and we're in consumer mode, should abort with onSuccess(), and provide the reason.
             * Ideally the UI would prevent this one too, but it's not as bad.
             * What matters is the user should be told why the addon isn't available  */
            if (!availAddonResult?.isAvail) {
              // If the addon is not available or is not in the results, should abort with onSuccess(), passing a void result, and a user friendly message.
              return onSuccess(undefined, {
                alertType: 'alert',
                showMsg: true,
                msg: availAddonResult?.unavailReason ?? defaultAddonNotAvailableText,
              })
            }

            // get the schedule data for the availAddon matchingDistIds
            matchingSchedulesAvailAddon = await distrosCollection.fetchByIds(availAddonResult.matchingDistIds)
          }

          return setLocationFlow({
            prod: product,
            matchingSchedulesAvailAddon,
            csa: selectedCsa,
            paymentSchedule,
            preselectedSchedule: distribution,
            onSuccess,
            onErr,
          })
        } else if (isShare(product)) {
          // Go straight to set location flow, because the product is a primary, so unit selection is not needed
          return setLocationFlow({
            prod: product,
            csa: selectedCsa,
            paymentSchedule,
            preselectedSchedule: distribution,
            preselectedDates: pickups,
            onSuccess,
            onErr,
          })
        } else if (hasUnits(product)) {
          // If it's a UnitProduct, a unit must be added with the item to the cart.

          // The unit could be either pre-defined as a prop, or must be selected in UnitSelection modal.
          const compatibleUnits = (unitProp ? [unitProp] : product.units).filter(matchesAppModeBO(isWholesale))

          if (compatibleUnits.length === 0) {
            // This shouldn't happen if working correctly
            return onSuccess()
          } else if (compatibleUnits.length === 1) {
            // Auto-select unit, because there's only one viable option
            const unitAutoSelected = compatibleUnits[0]

            onUnitSelected({
              unit: unitAutoSelected,
              product,
              onErr,
              onSuccess,
              csa: selectedCsa,
              distribution,
              paymentSchedule,
              pickups,
            })
          } else {
            // Show unit selector modal, because there's more than one unit

            return Modal(
              <UnitSelection
                product={product}
                close={(selectedUnit) => {
                  if (!selectedUnit) {
                    return onSuccess()
                  }

                  onUnitSelected({
                    unit: selectedUnit,
                    product,
                    onErr,
                    onSuccess,
                    csa: selectedCsa,
                    distribution,
                    paymentSchedule,
                    pickups,
                  })
                }}
                cartServiceType={cartServiceType}
                isWholesale={isWholesale}
              />,
              {
                halfModal: true,
                webWidth: 500,
                title: 'Select size to add to cart',
                onDismiss: () => {
                  onSuccess()
                },
              },
            )
          }
        } else {
          /** Implement add gift cards and memberships to cart */
          const err = new DataError('Tried to add an unsupported product type to cart. ', { product })
          return onErr(err)
        }
      }),
    [cart, availAddonsResults, setLocationFlow, selectCsa, cartServiceType, isAdmin, isWholesale, onUnitSelected],
  )

  /** Allows modifying the pickup dates for a standard product in cart */
  const modifyDates = useCallback<UseAddToCartFlowReturn['modifyDates']>(
    (id) =>
      new Promise(async (resolve, reject) => {
        try {
          if (isWholesale === undefined) return reject(new Error('Must wait for the app mode to be defined'))

          if (isWholesale && cart.length > 1) {
            // This check should never be true if all is working as expected because the places that use the modifyDates service should have their own checks to prevent showing this option in the UI
            // Nevertheles it is here as a 2nd layer of safety in case things change incorrectly in other places
            return reject(
              new Error(
                "Wholesale orders must have a single date per order, so we can't modify dates when there's multiple items in the cart.",
              ),
            )
          }

          setLoading(true)
          const cartItem = cart.find((ci) => ci.id === id)

          if (!cartItem) {
            Toast('The item could not be found')
            setLoading(false)
            return reject(new Error('Item not found: ' + id))
          }
          if (!isCartStandard(cartItem)) {
            Toast("Can't modify the dates for this item")
            setLoading(false)
            return reject(
              new DataError('Dates cannot be modified for this item type. ', { type: cartItem.product.type }),
            )
          }

          const onSelectDates = async (dates: DateTime[]) => {
            // We will close the modal immediately on selection end, instead of waiting for the server response.
            hideModal()

            try {
              if (!dates.length) {
                throw new Error('No dates were selected.')
              }

              if (
                !isWholesale &&
                cartItem.product.minPickups !== undefined &&
                dates.length < cartItem.product.minPickups
              ) {
                throw new Error(
                  `You must select at least ${cartItem.product.minPickups} ${plural(
                    cartItem.product.minPickups,
                    'date',
                  )}`,
                )
              }

              hideModal()
              Loader(true)
              await updateCartItem(id, { pickups: dates })
              Toast(
                `Your item now has ${dates.length} ${formatDistributionType(cartItem.distribution.location, {
                  action: true,
                })} dates`,
              )
              resolve()
            } catch (error) {
              Toast('Something went wrong while updating the item')
              Logger.error(error)
              reject(error)
            } finally {
              Loader(false)
              setLoading(false)
              clearGetPickupsCacheAddtoCartFlow()
            }
          }

          return Modal(
            <DateSelector
              distro={cartItem.distribution}
              product={cartItem.product}
              preselectedDates={cartItem.pickups}
              onSelect={onSelectDates}
              cartServiceType={cartServiceType}
              unit={cartItem.unit}
              initialMode={isWholesale ? 'single' : 'multi'}
              isWholesale={isWholesale}
            />,
            {
              webWidth: 1000,
              title: 'Select schedule',
              onDismiss: () => {
                hideModal()
                setLoading(false)
                clearGetPickupsCacheAddtoCartFlow()
                resolve()
              },
            },
          )
        } catch (err) {
          hideModal()
          setLoading(false)
          clearGetPickupsCacheAddtoCartFlow()
          return reject(err)
        }
      }),
    [cart, updateCartItem, isWholesale, cartServiceType],
  )

  /** Allows modifying the delivery address of an item in the cart */
  const editDeliveryAddress = useCallback<UseAddToCartFlowReturn['editDeliveryAddress']>(
    (item) =>
      new Promise((resolve, reject) => {
        try {
          setLoading(true)

          if (!isNonPickupDistLocation(item.distribution.location)) {
            setLoading(false)
            const err = new DataError(
              "An attempt was made to edit the address of an item that doesn't support address editing.",
              { item },
            )
            Logger.error(err)
            Toast('This item does not support address selection')
            return reject(err)
          }

          const onSelect = (newAddress: UserAddress) =>
            updateCartItem(item.id, {
              distribution: {
                ...item.distribution,
                location: {
                  ...item.distribution.location,
                  address: newAddress,
                },
              },
            })
              .then((newItm) => {
                hideModal()
                Toast(newItm ? 'Delivery Address updated' : 'There was a problem while updating the delivery address')
                setLoading(false)
                resolve(newItm ? (newItm as ItemNonPickup).distribution?.location.address : undefined)
              })
              .catch((e) => {
                Logger.error(e)
                hideModal()
                Toast('There was a problem while updating the delivery address')
                setLoading(false)
                reject(e)
              })

          return Modal(<EditDeliveryAddress item={item} onSelect={onSelect} />, {
            webWidth: 1000,
            title: 'Select Delivery Address',
            onDismiss: () => {
              hideModal()
              Toast('Editing canceled')
              setLoading(false)
              resolve()
            },
          })
        } catch (error) {
          Logger.error(error)
          hideModal()
          Toast('Something went wrong during address editing')
          setLoading(false)
          return reject(error)
        }
      }),
    [updateCartItem],
  )

  return useMemo<UseAddToCartFlowReturn>(
    () => ({
      addToCartFlow,
      setLocationFlow,
      modifyDates,
      isLoadingAddCartFlow: loading,
      selectCsa,
      editDeliveryAddress,
    }),
    [addToCartFlow, loading, modifyDates, selectCsa, editDeliveryAddress, setLocationFlow],
  )
}
