import { openUrl } from '@helpers/client'
import { hasGrownByPrivilege, UserRole } from '@models/User'
import * as Linking from 'expo-linking'
import { useEffect, useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { Button } from '../../components/elements/Button'
import { ButtonClear } from '../../components/elements/ButtonClear'
import { Text } from '../../components/elements/Text'
import LoaderWithMessage from '../../components/LoaderWithMessage'
import Colors from '../../constants/Colors'
import { globalStyles } from '../../constants/Styles'
import { userSelector } from '../../redux/selectors'
import { AppStatus } from '../AppStatus'
import { FarmGenAdminDataInspector } from './FarmGenAdminDataInspector'
import { FarmGenAdminFarm } from './FarmGenAdminFarm'
import { FarmGenAdminQuickGlance } from './FarmGenAdminQuickGlance'
import { FarmGenAdminUser } from './FarmGenAdminUser'

/** The main panel for admins that allows viewing and managing of farms and users.*/
export function FarmGenAdminPanel() {
  const user = useSelector(userSelector)
  const [isAllowed, setIsAllowed] = useState<boolean>()

  useEffect(() => {
    setIsAllowed(hasGrownByPrivilege(user, UserRole.Admin))
  }, [user])

  if (!isAllowed)
    return (
      <View style={styles.center}>
        <LoaderWithMessage loading={isAllowed === undefined} icon="user-times" title="Off Limits">
          <Text>It looks like you don't have permission to access this page.</Text>
          <Button title="Home" onPress={() => openUrl(Linking.createURL('/'))} />
        </LoaderWithMessage>
      </View>
    )
  return (
    <AppStatus scope="farmer">
      <View style={globalStyles.flex1}>
        <ButtonClear icon="arrow-left" title="Back to App" url="/" />
        <View style={styles.row}>
          <ScrollView style={styles.box}>
            <FarmGenAdminUser />
          </ScrollView>
          <ScrollView style={styles.box}>
            <FarmGenAdminFarm />
          </ScrollView>
        </View>
        <View style={styles.row}>
          <ScrollView contentContainerStyle={styles.quickGlanceContainer} style={styles.box}>
            <FarmGenAdminQuickGlance />
          </ScrollView>
          <ScrollView style={styles.box}>
            <FarmGenAdminDataInspector />
          </ScrollView>
        </View>
      </View>
    </AppStatus>
  )
}

const styles = StyleSheet.create({
  box: {
    flex: 1,
    margin: 16,
    padding: 16,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.shades['200'],
  },
  center: {
    flex: 1,
    justifyContent: 'center',
  },
  row: {
    flexDirection: 'row',
    flex: 1,
  },
  quickGlanceContainer: {
    flexGrow: 1,
  },
})
