import { CustomerParamList } from '@/admin/navigation/types'
import { Logger } from '@/config/logger'
import { adminFarmSelector, userSelector } from '@/redux/selectors'
import { addCustomerToFarm, createFarmCustomer, findUserByEmail } from '@api/Customers'
import { Toast } from '@elements'
import { AddCustomerDataForm, CustomerApiError } from '@models/Customer'
import { User, UserType } from '@models/User'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { isErrorWithCode } from '@shared/Errors'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { onInvitingAdminCustomerAlert, onInvitingExistingRetailCustomerAlert } from '../../alerts'

/** Hook for managing business logic in relation to adding a new farm customer */
export function useAddCustomerData({ userType }: { userType: UserType }) {
  const farm = useSelector(adminFarmSelector)
  const farmAdmin = useSelector(userSelector)
  const navigation = useNavigation<StackNavigationProp<CustomerParamList, 'AddCustomer'>>()
  const [loading, setLoading] = useState(false)

  const onError = useCallback(
    (error: unknown, customer?: User) => {
      setLoading(false)

      Logger.error(error)
      if (!isErrorWithCode(error)) {
        return Toast('Something went wrong. Please try again')
      }

      if (error.name === CustomerApiError.UserTypeMismatch && customer) {
        return onInvitingExistingRetailCustomerAlert(customer, () => navigation.navigate('CustomerList'))
      }

      if (error.name === CustomerApiError.UserRoleMismatch) {
        return onInvitingAdminCustomerAlert()
      }

      Toast(error.uiMsg)
    },
    [navigation],
  )

  const handleSubmit = useCallback(
    async (values: AddCustomerDataForm) => {
      setLoading(false)
      const customer = await findUserByEmail(values.email)

      try {
        if (customer) {
          await addCustomerToFarm(customer, farm, farmAdmin, userType)
        } else {
          await createFarmCustomer(values, farm)
          Toast('Customer successfully created!')

          navigation.navigate('CustomerList')
        }
        setLoading(false)
      } catch (error) {
        onError(error, customer)
      }
    },
    [farm, farmAdmin, userType, navigation, onError],
  )

  return { handleSubmit, loading }
}
