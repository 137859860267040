import { ActionBtn } from '@/admin/components/elements/ActionsMenuComponent'
import { FarmSetupTab } from '@/admin/navigation/types'
import { writeFarmAssociationRole } from '@api/FarmAssociations'
import { Alert, Loader, Modal, Toast } from '@elements'
import { isNonNullish } from '@helpers/helpers'
import { userName } from '@helpers/user'
import { ManagerType } from '@models/Farm'
import { ChangeAdminRole } from '../../components/modals'

/** This function will generate actions for manager to edit admin role. */
export const getActions = (farmId: string) => {
  const actions = [
    {
      title: 'Change role',
      onPress: (data: ManagerType) => Modal(<ChangeAdminRole manager={data} />, { title: 'Change Role' }),
    },
    {
      title: 'Delete',
      onPress: (data: ManagerType) => {
        Alert(
          'Confirmation',
          `Would you like to remove ${userName(
            data.user,
          )} as ${data.farmAssociation.role!.toUpperCase()} for your farm?`,
          [
            {
              text: 'Remove',
              onPress: async () => {
                Loader(true)
                await writeFarmAssociationRole(farmId, data.user.id, data.farmAssociation.role!, 'delete')
                Loader(false)
                Toast(`Successfully removed ${userName(data.user)} as ${data.farmAssociation.role!.toUpperCase()}`)
              },
            },
            { text: 'Cancel', style: 'cancel' },
          ],
        )
      },
    },
  ]
  //TODO: Add notification reference later
  // {title:'Notification Preference', onPress: (data) => {}}

  return actions.filter((action) => isNonNullish(action)).flat() as ActionBtn<ManagerType>[]
}

type GetVisibleTabsProps = {
  hasProductSetupPermission: boolean
  hasAccountingPermission: boolean
  hasSetupPermission: boolean
  hasWholesaleAccess: boolean
}

/** Gets the visible tabs based on the user's role */
export const getVisibleTabs = ({
  hasAccountingPermission,
  hasSetupPermission,
  hasProductSetupPermission,
  hasWholesaleAccess,
}: GetVisibleTabsProps): FarmSetupTab[] => {
  // Members will be always visible
  const tabs: FarmSetupTab[] = ['members']

  if (hasWholesaleAccess && hasSetupPermission) {
    tabs.unshift('wholesale')
  }

  if (hasSetupPermission) {
    tabs.unshift('memberships')
  }

  if (hasProductSetupPermission) {
    tabs.unshift('productFees')
  }

  if (hasAccountingPermission) {
    tabs.unshift('payouts')
  }

  if (hasProductSetupPermission) {
    tabs.unshift('payments')
  }

  return tabs
}
