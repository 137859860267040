import { CurrencyCode, CurrencyCodes, Money, MoneyWithCurrency } from '@models/Money'
import * as yup from 'yup'
import { validateFromSchema } from './helpers'

export const moneySchema: yup.ObjectSchema<Money> = yup
  .object({
    value: yup.number().defined().min(0),
    currency: yup.mixed<CurrencyCode>().oneOf(CurrencyCodes),
  })
  .typeError('Money must be an object')
  .defined()

export const moneyWCurrencySchema: yup.ObjectSchema<MoneyWithCurrency> = moneySchema.shape({
  currency: (yup.reach(moneySchema, 'currency') as yup.MixedSchema<Money['currency']>).defined(),
})

/** Validates a money object */
export const validateMoney = (money: object) => validateFromSchema(moneySchema, money)
/** Validates a money object whose currency is defined */
export const validateMoneyWCurrency = (money: object) => validateFromSchema(moneyWCurrencySchema, money)
