import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { InfoRow } from '@components'
import { Text } from '@elements'
import { getCartPickupDatesGroupedByLocationType } from '@helpers/cart'
import { formatMoney, formatPickupDate, getOrderNum } from '@helpers/display'
import { isEmptyValue, isNonNullish, nonEmptyString } from '@helpers/helpers'
import { MoneyCalc, Zero } from '@helpers/money'
import { calculatePayments } from '@helpers/order'
import { userName } from '@helpers/user'
import { Cart } from '@models/Cart'
import { DraftOrder } from '@models/DraftOrder'
import { memo } from 'react'
import { View } from 'react-native'

type DraftOrderSummaryProps = {
  draftOrder: DraftOrder
  draftCart: Cart
  isAdmin: boolean
}

export const DraftOrderSummary = memo(function DraftOrderSummary({
  draftOrder,
  draftCart,
  isAdmin,
}: DraftOrderSummaryProps): JSX.Element {
  const styles = useStyles()

  return (
    <View style={styles.summary}>
      <View style={styles.subTitle}>
        <Text size={12} style={globalStyles.flex1}>
          Order {getOrderNum(draftOrder.orderNum)}
        </Text>
        <Text numberOfLines={2} size={12} style={globalStyles.flex1}>
          Placed on: {formatPickupDate(draftOrder.date)}
        </Text>
      </View>

      <View style={styles.contents}>
        <View style={styles.contentsColumn}>
          <View>
            {(isAdmin ? customerRows(draftOrder) : farmRows(draftOrder)).map((row) => (
              <InfoRow key={row.left} data={row} infoRowContainerStyle={styles.infoRowContainer} />
            ))}
          </View>
          {Object.entries(getCartPickupDatesGroupedByLocationType({ items: Object.values(draftCart.items) })).map(
            ([locationType, pickupDates]) => (
              <View key={locationType}>
                <InfoRow
                  data={{ left: locationType, isHeader: true }}
                  infoRowContainerStyle={styles.infoRowContainer}
                />
                {pickupDates.map((pickupDate) => (
                  <InfoRow
                    key={pickupDate.toISO()}
                    data={{ left: formatPickupDate(pickupDate) }}
                    infoRowContainerStyle={styles.infoRowContainer}
                  />
                ))}
              </View>
            ),
          )}
        </View>
        <View style={styles.contentsColumn}>
          {!isEmptyValue(detailsRows(draftOrder, isAdmin)) && (
            <View>
              {detailsRows(draftOrder, isAdmin).map((row) => (
                <InfoRow key={row.left} data={row} infoRowContainerStyle={styles.infoRowContainer} />
              ))}
            </View>
          )}
          <View>
            {paymentRows(draftOrder, draftCart).map((row) => (
              <InfoRow key={row.left} data={row} infoRowContainerStyle={styles.infoRowContainer} />
            ))}
          </View>
        </View>
      </View>
    </View>
  )
})

const farmRows = (draftOrder: DraftOrder) => [{ left: 'Farm', isHeader: true }, { left: draftOrder.farm.name }]

const customerRows = (draftOrder: DraftOrder) => [
  { left: 'Customer', isHeader: true },
  { left: userName(draftOrder.user) },
  { left: draftOrder.user.email },
]

/**
 * Will create rows to display Purchase Order for consumer side, and Note and Purchase Order for admin side. If they exist
 */
const detailsRows = (draftOrder: DraftOrder, isAdmin: boolean) => {
  const hasValidNote = isAdmin && nonEmptyString(draftOrder.note)

  // If we don't have the purchaseOrder and we don't have the note then we should not display this section
  if (!nonEmptyString(draftOrder.purchaseOrder) && !hasValidNote) {
    return []
  }

  return [
    { left: 'Order Details', isHeader: true },
    isAdmin && nonEmptyString(draftOrder.note) ? { left: 'Note', right: draftOrder.note } : null,
    nonEmptyString(draftOrder.purchaseOrder) ? { left: 'Purchase Order', right: draftOrder.purchaseOrder } : null,
  ].filter(isNonNullish)
}

const paymentRows = (draftOrder: DraftOrder, draftCart: Cart) => {
  const payments = calculatePayments(
    {
      items: Object.values(draftCart.items),
      isAdmin: draftCart.isAdmin,
      discounts: draftCart.discounts,
    },
    {
      farmId: draftOrder.farm.id,
      isWholesale: draftOrder.isWholesale ?? false,
      splitTender: draftOrder.tender,
    },
  )
  if (!payments || !payments.length) return []

  const taxesAndFees = (payments[0].taxesAndFees ?? []).reduce((amount, taxOrFee) => {
    return MoneyCalc.add(amount, taxOrFee.amount)
  }, Zero)

  return [
    { left: 'Order Total', isHeader: true },
    { left: 'Subtotal', right: formatMoney(payments[0].subtotal) },
    {
      left: 'Taxes & Fees',
      right: formatMoney(taxesAndFees),
    },
    { left: 'Total', right: formatMoney(payments[0].total), isHeader: true },
  ]
}

const useStyles = () =>
  useSizeFnStyles(({ isSmallDevice, isExtraSmallDevice }) => ({
    summary: {
      borderWidth: 1,
      borderColor: Colors.lightGray,
      borderRadius: 10,
      overflow: 'hidden',
      marginTop: 15,
    },
    subTitle: {
      flexDirection: 'row',
      backgroundColor: Colors.shades[75],
      paddingVertical: 15,
      paddingHorizontal: isExtraSmallDevice ? 5 : 20,
    },
    contents: {
      flexDirection: isSmallDevice ? 'column' : 'row',
      gap: isSmallDevice ? 15 : 0,
      paddingHorizontal: isExtraSmallDevice ? 5 : 20,
      paddingVertical: isExtraSmallDevice ? 10 : 20,
    },
    contentsColumn: {
      flex: 1,
      gap: 15,
    },
    infoRowContainer: {
      marginHorizontal: 0,
    },
  }))
