import { MoneyCalc } from '@helpers/money'
import { Invoice, InvoicePayment, InvoiceStatus, isOffline, isPaid } from '@models/Invoice'
import { PaymentSources } from '@models/PaymentMethod'

import { PmtDef } from '@shared/helpers/PaymentDefinitions'
/** display paymentMethod with custom logic to be used in Admin Customer invoice section and SummaryInvoices report */
export const displayPaymentMethod = (invoice: Invoice): string => {
  // if the invoice is due and offline, we need to show paymentMethod empty string
  if (invoice.status === InvoiceStatus.Due && isOffline(invoice)) return ''
  else return chainPaymentsMethod(invoice, true).join(', ')
}

/** Will extract a single payment method from an invoices payments */
export function extractSinglePaymentMethod(source: PaymentSources, invoice: Invoice): string {
  // if invoice is due and offline, do not show payment method
  if (invoice.status === InvoiceStatus.Due && isOffline(invoice)) {
    return ''
  }
  const invPayment = invoice.payments[source]
  if (!invPayment) return 'No Payment'

  return PmtDef(invPayment).formatInvPayMethod(invPayment.paymentMethod)
}

/** For split-tender payments to get all payment methods
 * @arg inv the invoice to get payment methods from
 * @arg expandDetails (optional) default (false) - if true, will return payment methods with details for example:
 * expandDetails is false return ['Offline', 'Card', 'EBT', 'Farm Credit']
 * expandDetails is true return ['Offline', 'Visa ****1234', 'EBT', 'Farm Credit']
 */
export function chainPaymentsMethod(inv: Invoice, expandDetails = false): string[] {
  const paymentsGroup = inv.payments
  const chainResults: string[] = []

  Object.values(paymentsGroup).forEach((payment) => {
    const paymentDef = PmtDef(payment)

    // We will not show this payment if the invoice is paid and no payment with this source was made. This can happen
    // for example if we have a fixed discount that is OFFLINE, but there was no offline payment, so we shouldn't show it
    if (!isPaid(inv) || MoneyCalc.isGTZero(payment.totalPaid)) {
      const text = expandDetails ? paymentDef.formatInvPayMethod(payment.paymentMethod) : paymentDef.formatShort()
      chainResults.push(text)
    }
  })

  return chainResults
}

/** Will return a formatted string for an invoice payment */
export const formatInvoicePmtInfo = (payment: InvoicePayment): string => {
  return PmtDef(payment).formatInvPayMethod(payment.paymentMethod)
}
