import { canLocationBeDeleted, deleteLocation, snapshotLocationsByFarm } from '@api/Locations'
import { ToolTips } from '@components'
import {
  Alert,
  Button,
  DropdownMenu,
  DropdownMenuBtn,
  HeaderText,
  Picker,
  Text,
  TextH4,
  Toast,
  Tooltip,
  Touchable,
} from '@elements'
import { capitalize, formatMoney } from '@helpers/display'
import { Location, LocationTypes, isDelivery, isLocalPickup, isNonPickup, isShipping } from '@models/Location'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useCallback, useState } from 'react'
import { ListRenderItemInfo, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { AdminView } from '@/admin/components/AdminView'
import { ExpandableRow } from '@/admin/components/OfflineTable/ExpandableRow'
import { OfflineTable } from '@/admin/components/OfflineTable/OfflineTable'
import { LocationsAndZonesParamList } from '@/admin/navigation/types'
import Colors from '@/constants/Colors'
import { isWeb } from '@/constants/Layout'
import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { useDeviceSize } from '@/hooks/useLayout'
import { useSnapshot } from '@/hooks/useSnapshot'
import { withAdminAuth } from '@/hooks/withAdminAuth'
import { setAdminLocations } from '@/redux/actions/adminPersist'
import { setAdminNav } from '@/redux/actions/adminState'
import { adminFarmIdSelector, adminLocsSelector } from '@/redux/selectors'
import { Permission } from '@helpers/Permission'
import { globalStyles } from '../../../constants/Styles'

function LocationScreenComp() {
  const navigation = useNavigation<StackNavigationProp<LocationsAndZonesParamList, 'Locations'>>()
  const dispatch = useDispatch()

  const farmId = useSelector(adminFarmIdSelector)
  const locations = useSelector(adminLocsSelector)
  const [filter, setFilter] = useState<keyof typeof filterMap>('all')
  const { isExtraLargeDevice, isSmallDevice } = useDeviceSize()
  const styles = useStyles()

  /** Syncs the farm distros into admin redux */
  const { loading } = useSnapshot('snapshotLocationsByFarm', snapshotLocationsByFarm, [farmId!], !!farmId, {
    onStateChange: ({ data }) => dispatch(setAdminLocations(data ?? [])),
  })

  const editLocation = (location: Location) => {
    dispatch(setAdminNav({ location }))

    if (isNonPickup(location)) navigation.navigate('EditDeliveryShipping', { id: location.id, type: location.type })
    else navigation.navigate('EditLocation', { id: location.id })
  }
  const onDeletePress = async (location: Location) => {
    if (!farmId) return
    const canBeDeleted = await canLocationBeDeleted(farmId, location.id)
    if (!canBeDeleted)
      return Alert(
        'Permission denied',
        'This location cannot be deleted because it has a schedule associated with it.',
        [{ text: 'Ok', style: 'cancel' }],
      )
    Alert('Delete Location', 'Are you sure you want to delete this distribution?', [
      {
        text: 'Delete',
        style: 'destructive',
        onPress: () => {
          deleteLocation(location.id).then(() => Toast('Location deleted!'))
        },
      },
      {
        text: 'Cancel',
        style: 'cancel',
      },
    ])
  }
  const renderItem = useCallback(
    ({ item, index }: ListRenderItemInfo<Location>) => {
      return (
        <ExpandableRow
          columns={[
            { widthFlex: 2, process: (itm) => itm.name },
            { process: (itm) => capitalize(itm.type) },
            { widthFlex: 2, process: (itm) => renderLocationZone(itm) },
            { process: (itm) => (isNonPickup(itm) ? formatMoney(itm.cost) : '') },
            {
              widthFlex: 0.5,
              process: (itm) => (
                <Touchable onPress={() => editLocation(itm)} style={styles.rowBtnCont}>
                  <Text type="medium" color={Colors.green}>
                    Edit
                  </Text>
                </Touchable>
              ),
            },
            {
              widthFlex: 0.5,
              process: (itm) => (
                <Touchable onPress={() => onDeletePress(itm)} style={styles.rowBtnCont}>
                  <Text type="medium" color={Colors.green}>
                    Delete
                  </Text>
                </Touchable>
              ),
            },
          ]}
          item={item}
          index={index}
          onRowPress={() => editLocation(item)}
        />
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editLocation, onDeletePress],
  )

  return (
    <AdminView style={styles.container}>
      <View style={globalStyles.flexRowCenter}>
        <HeaderText size={30}>Locations & Zones</HeaderText>
        <Tooltip id={ToolTips.LOCATIONS_ZONES} size={15} title="Locations & Zones" />
      </View>

      <View style={styles.buttonsWrapper}>
        <Picker
          placeholder={null}
          value={filterMap[filter].value}
          items={Object.values(filterMap)}
          onValueChange={function (value: string) {
            if (value in filterMap) setFilter(value)
          }}
        />

        <View style={styles.buttonsCont}>
          {isExtraLargeDevice ? (
            <>
              <Button style={styles.button} title="Add Location" onPress={() => navigation.navigate('AddLocation')} />
              <Button
                style={styles.button}
                title="Add Shipping Zone"
                onPress={() => navigation.navigate('AddDeliveryShipping', { type: LocationTypes.Shipping })}
              />
              <Button
                style={styles.button}
                title="Add Delivery Zone"
                onPress={() => navigation.navigate('AddDeliveryShipping', { type: LocationTypes.Delivery })}
              />
            </>
          ) : (
            <DropdownMenu
              buttons={actions}
              data={{ nav: navigation }}
              otherProps={isSmallDevice ? { matchWidth: true } : undefined}
            >
              <View style={styles.dropdowmMenu}>
                <TextH4 color={Colors.white}>Add</TextH4>
              </View>
            </DropdownMenu>
          )}
        </View>
      </View>
      <OfflineTable<Location>
        /** By default the scrollEnable is false, so the mobile app can scroll on the list without problem.
           * The reason to set scrollEnabled
           - 'isWeb' => For mobile web, we have to set scrollEnable true to make table scrollable and then trigger entire screen can be scrollable as well. And for normal big screen web, the behavior stays same.
           - '!isSmallDevice', for bigger touch screen like IPad, we have to make the table scrollable and since we set breakpoint as isSmallDevice, we set !isSmallDevice for all bigger touch screen to be able to scroll on app, for web version, it is covered by setting 'isWeb' */
        scrollEnabled={isWeb || !isSmallDevice}
        headerColumns={[
          { title: 'Name', widthFlex: 2 },
          { title: 'Type' },
          { title: 'Location', widthFlex: 2 },
          { title: 'Fee' },
          { widthFlex: 0.5 },
          { widthFlex: 0.5 },
        ]}
        renderItem={renderItem}
        data={locations.filter(filterMap[filter].filter)}
        isLoading={loading}
        minWidth={800}
        containerStyle={styles.offlineTableContainer}
      />
    </AdminView>
  )
}
export const LocationScreen = withAdminAuth(LocationScreenComp, Permission.ProductSetup)

const filterMap: Record<string, any> = {
  all: {
    label: 'All Locations & Zones',
    value: 'all',
    filter: (_item: Location) => true,
  },
  locations: {
    label: 'Locations',
    value: 'locations',
    filter: (item: Location) => isLocalPickup(item),
  },
  delivery: {
    label: 'Delivery Zones',
    value: 'delivery',
    filter: (item: Location) => isDelivery(item),
  },
  shipping: {
    label: 'Shipping Zones',
    value: 'shipping',
    filter: (item: Location) => isShipping(item),
  },
}

const actions: DropdownMenuBtn<{ nav: StackNavigationProp<LocationsAndZonesParamList, 'Locations'> }>[] = [
  {
    title: 'Add Location',
    onPress: ({ nav }) => nav.navigate('AddLocation'),
  },
  {
    title: 'Add Delivery Zone',
    onPress: ({ nav }) => nav.navigate('AddDeliveryShipping', { type: LocationTypes.Delivery }),
  },
  {
    title: 'Add Shipping Zone',
    onPress: ({ nav }) => nav.navigate('AddDeliveryShipping', { type: LocationTypes.Shipping }),
  },
]

/** This function will render the location address, or for shipping and delivery will be a list of states and zipcodes */
const renderLocationZone = (location: Location): string => {
  // For shipping locations, show the first 5 state or zip codes with "..." if there are more than 5
  if (isShipping(location) || isDelivery(location)) {
    const regions = location.regions || []
    const limitedRegions = regions.slice(0, 5).join(', ')
    return regions.length > 5 ? `${limitedRegions}, ...` : limitedRegions
  }

  if (!location.address) return ''
  return `${location.address.street1}, ${location.address.state} ${location.address.zipcode}`
}

const useStyles = () =>
  useLayoutFnStyles(({ isSmallDevice, isExtraSmallDevice, height }) => ({
    container: {
      paddingHorizontal: isSmallDevice ? 10 : 30,
      paddingTop: isSmallDevice ? 10 : 30,
    },
    offlineTableContainer: {
      /** If it is not smallDevice or not extraSmallDevice, it means templates table is enabled to have nested scroll, so the maxHeight should be set to height * 0.71 (depend on the the table size and best view) to help to achieve scroll functionality. Otherwise, the maxHeight should be auto adjusted to correct height of table to be able to show all rows from scrolling entire screen if need.
       * Some case 'auto' maxHeight is not working, so we have to set maxHeight to 100% to make it work.
       */
      maxHeight: !isSmallDevice ? height * 0.71 : '100%',
    },
    actionBtns: {
      flexDirection: 'row',
      alignSelf: 'flex-end',
    },
    buttonsWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 5,
    },
    buttonsCont: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    button: { marginHorizontal: isExtraSmallDevice ? 5 : 10, paddingHorizontal: isExtraSmallDevice ? 5 : 10 },
    dropdowmMenu: {
      backgroundColor: Colors.green,
      justifyContent: 'center',
      paddingHorizontal: 50,
      borderRadius: 10,
      paddingVertical: 10,
      marginVertical: 5,
    },
    rowBtnCont: {
      alignItems: 'center',
      minWidth: 18,
    },
  }))
