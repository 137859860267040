import { FormDateTimeInput, FormInputLabel, FormMoneyInput, Text, TextH4, ToggleButton, typography } from '@elements'
import { MoneyCalc, getZero } from '@helpers/money'
import { PartialExcept, PartialPick } from '@helpers/typescript'
import { MoneyWithCurrency } from '@models/Money'
import { PaymentType } from '@models/Payment'
import { Product, ProductType, isShare } from '@models/Product'
import { useFormikContext } from 'formik'
import { DateTime } from 'luxon'
import { View } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize, minSize } from 'rn-responsive-styles'
import * as Yup from 'yup'

import { ProductSchemaContext } from '@helpers/builders/buildProduct'
import FormSectionHeader from '../components/FormSectionHeader'
import { ProductFormWrapper } from '../components/FormWrapper'
import { ProductFormikComponent } from './helpers/ProductFormikComponent'
import { calculateDiscount, calculateTotalPrice, totalPriceDisplay } from './helpers/ShareBillingOptions.helper'

import { adminCurrencySelector, adminFarmSelector } from '@/redux/selectors'
import { YUP_MONEY_OPTIONAL, YUP_MONEY_REQUIRED } from '@helpers/Yup'
import { nonEmptyString } from '@helpers/helpers'
import { calculateSharePricePerPickup } from '@helpers/products'
import { useSelector } from 'react-redux'
import { globalStyles } from '../../../../constants/Styles'

type PaymentScheduleFormType = {
  active: boolean
  pricePerPickup?: MoneyWithCurrency
  deposit?: MoneyWithCurrency
  finalPaymentDate?: DateTime
}

export type BillingFormType = {
  payInFull: Pick<PaymentScheduleFormType, 'pricePerPickup'>
  payMonthly: PaymentScheduleFormType
  payWeekly: PaymentScheduleFormType
}

function StatBox({ title, stat }: { title: string; stat: any }) {
  const styles = useStyles()
  return (
    <View style={globalStyles.flex1}>
      <Text style={styles.statHeaderText} size={14}>
        {title}
      </Text>
      <Text size={18}>{stat}</Text>
    </View>
  )
}

const pricingValidation: Yup.ObjectSchema<BillingFormType, ProductSchemaContext> =
  Yup.object<ProductSchemaContext>().shape({
    payInFull: Yup.object()
      .required()
      .shape({
        pricePerPickup: Yup.mixed<MoneyWithCurrency>().when('$type', {
          is: (type: ProductType) => isShare({ type }),
          then: () => YUP_MONEY_REQUIRED('Price per pickup', { allowZero: true, requireCurrency: true }),
          otherwise: () => YUP_MONEY_OPTIONAL('Price per pickup', { allowZero: true, requireCurrency: true }),
        }),
      }),
    payMonthly: Yup.object()
      .required()
      .shape({
        active: Yup.boolean().required(),
        pricePerPickup: Yup.mixed<MoneyWithCurrency>().when('active', {
          is: true,
          then: () => YUP_MONEY_REQUIRED('Price per pickup', { allowZero: true, requireCurrency: true }),
          otherwise: () => YUP_MONEY_OPTIONAL('Price per pickup', { allowZero: true, requireCurrency: true }),
        }),
        deposit: Yup.mixed<MoneyWithCurrency>().when('active', {
          is: true,
          then: () => YUP_MONEY_REQUIRED('Deposit', { allowZero: true, requireCurrency: true }),
          otherwise: () => YUP_MONEY_OPTIONAL('Deposit', { allowZero: true, requireCurrency: true }),
        }),
        finalPaymentDate: Yup.mixed<DateTime>().when('active', {
          is: true,
          then: (schema) => schema.required('Final payment date is required'),
        }),
      }),
    payWeekly: Yup.object()
      .required()
      .shape({
        active: Yup.boolean().required(),
        pricePerPickup: Yup.mixed<MoneyWithCurrency>().when('active', {
          is: true,
          then: () => YUP_MONEY_REQUIRED('Price per pickup', { allowZero: true, requireCurrency: true }),
          otherwise: () => YUP_MONEY_OPTIONAL('Price per pickup', { allowZero: true, requireCurrency: true }),
        }),
        deposit: Yup.mixed<MoneyWithCurrency>().when('active', {
          is: true,
          then: () => YUP_MONEY_REQUIRED('Deposit', { allowZero: true, requireCurrency: true }),
          otherwise: () => YUP_MONEY_OPTIONAL('Deposit', { allowZero: true, requireCurrency: true }),
        }),
        finalPaymentDate: Yup.mixed<DateTime>().when('active', {
          is: true,
          then: (schema) => schema.required('Final payment date is required'),
        }),
      }),
  })

const toFormik = (product: PartialExcept<Product, 'type'>): BillingFormType => {
  // Initial values, will be updated later in this function to hold correct values
  const base: BillingFormType = {
    payInFull: {
      pricePerPickup: undefined,
    },
    payMonthly: {
      active: false,
      pricePerPickup: undefined,
      deposit: undefined,
      finalPaymentDate: undefined,
    },
    payWeekly: {
      active: false,
      pricePerPickup: undefined,
      deposit: undefined,
      finalPaymentDate: undefined,
    },
  }

  if (product && isShare(product)) {
    product.paymentSchedules?.map((ps) => {
      if (ps.frequency === 'ONCE')
        base.payInFull.pricePerPickup = calculateSharePricePerPickup(product, ps) as MoneyWithCurrency
      if (ps.frequency === 'MONTHLY') {
        base.payMonthly.active = true
        base.payMonthly.pricePerPickup = calculateSharePricePerPickup(product, ps) as MoneyWithCurrency
        base.payMonthly.deposit = MoneyCalc.round(ps.deposit) as MoneyWithCurrency
        base.payMonthly.finalPaymentDate = ps.paymentDates.endDate
      }
      if (ps.frequency === 'WEEKLY') {
        base.payWeekly.active = true
        base.payWeekly.pricePerPickup = calculateSharePricePerPickup(product, ps) as MoneyWithCurrency
        base.payWeekly.deposit = MoneyCalc.round(ps.deposit) as MoneyWithCurrency
        base.payWeekly.finalPaymentDate = ps.paymentDates.endDate
      }
    })
  }
  return base
}

function fromFormik<T extends BillingFormType & { type?: ProductType; numberOfPickups?: number }>(
  values: T,
): Partial<Product> {
  const base: PartialPick<Product, 'type'> = {
    type: values.type!,
  }

  // If this is not a share don't return anything
  if (!isShare(base)) return {}

  // The pricePerPickup of payInFull is expected to be defined
  const currency = values.payInFull.pricePerPickup!.currency!

  base.paymentSchedules = []
  base.paymentSchedules.push({
    amount: MoneyCalc.round(MoneyCalc.multiply(values.payInFull.pricePerPickup!, values.numberOfPickups!)),
    deposit: getZero(currency),
    paymentDates: {
      // For upfront payments the dates are meaningless, so we set endDate to now
      endDate: DateTime.now(),
    },
    frequency: 'ONCE',
    paymentType: PaymentType.PAY_FULL,
  })
  if (values.payMonthly.active) {
    const monthlyPrice: MoneyWithCurrency = MoneyCalc.math(
      Math.round,
      MoneyCalc.multiply(values.payMonthly.pricePerPickup ?? getZero(currency), values.numberOfPickups!),
    )

    base.paymentSchedules?.push({
      amount: MoneyCalc.round(monthlyPrice),
      deposit: MoneyCalc.round(values.payMonthly.deposit ?? getZero(currency)),
      paymentDates: {
        endDate: values.payMonthly.finalPaymentDate!,
      },
      frequency: 'MONTHLY',
      paymentType: PaymentType.INSTALLMENTS,
    })
  }
  if (values.payWeekly.active) {
    const weeklyPrice: MoneyWithCurrency = MoneyCalc.math(
      Math.round,
      MoneyCalc.multiply(values.payWeekly.pricePerPickup ?? getZero(currency), values.numberOfPickups!),
    )

    base.paymentSchedules?.push({
      amount: MoneyCalc.round(weeklyPrice),
      deposit: MoneyCalc.round(values.payWeekly.deposit ?? getZero(currency)),
      paymentDates: {
        endDate: values.payWeekly.finalPaymentDate!,
      },
      frequency: 'WEEKLY',
      paymentType: PaymentType.INSTALLMENTS,
    })
  }

  return base
}

export const FormikShareBilling = new ProductFormikComponent(pricingValidation, toFormik, fromFormik)

/** Component of product form that handles share billing*/
export function ShareBilling() {
  /*
   * We are adding the type of numberOfPickups as it is defined in the outer form context, and we need it here
   */
  const { values, setFieldValue, touched, errors } = useFormikContext<BillingFormType & { numberOfPickups: string }>()
  const numberOfPickups = values.numberOfPickups ? parseInt(values.numberOfPickups, 10) : 0
  const payInFullTotal = calculateTotalPrice(numberOfPickups, values.payInFull.pricePerPickup)
  const payMonthlyTotal = calculateTotalPrice(numberOfPickups, values.payMonthly.pricePerPickup)
  const payWeeklyTotal = calculateTotalPrice(numberOfPickups, values.payWeekly.pricePerPickup)
  const { timezone } = useSelector(adminFarmSelector)

  // Get the max pricePerPickup to calculate discounts based off of
  const largestTotalPrice = MoneyCalc.max(MoneyCalc.max(payInFullTotal, payMonthlyTotal), payWeeklyTotal)
  const styles = useStyles()
  const currency = useSelector(adminCurrencySelector)

  return (
    <ProductFormWrapper>
      <FormSectionHeader title="Billing Options" subtitle="You can offer different box prices per payment schedule." />
      <View style={styles.billingOption}>
        <TextH4 style={styles.optionTitle}>Pay in full</TextH4>
        <View style={styles.payOptContainer}>
          <View style={styles.optionInputs}>
            <FormMoneyInput
              value={values.payInFull.pricePerPickup}
              label={<FormInputLabel label="Price Per Pickup" required />}
              onChangeText={(value) => setFieldValue('payInFull.pricePerPickup', value)}
              errorMessage={
                touched.payInFull?.pricePerPickup && nonEmptyString(errors.payInFull?.pricePerPickup)
                  ? errors.payInFull?.pricePerPickup
                  : ''
              }
              currency={currency}
            />
          </View>
          <View style={styles.statBoxContainer}>
            <StatBox
              title="Discount"
              stat={calculateDiscount(
                calculateTotalPrice(numberOfPickups, values.payInFull.pricePerPickup),
                largestTotalPrice,
              )}
            />
            <StatBox title="Pickups" stat={numberOfPickups} />
            <StatBox title="Total Price" stat={totalPriceDisplay(numberOfPickups, values.payInFull.pricePerPickup)} />
          </View>
        </View>
      </View>
      <View style={styles.billingOption}>
        <View style={styles.optionHeader}>
          <TextH4 style={styles.optionTitle}>Pay monthly</TextH4>
          <ToggleButton
            onChange={() => setFieldValue('payMonthly.active', !values.payMonthly.active)}
            title=""
            value={values.payMonthly.active}
          />
        </View>
        {values.payMonthly.active && (
          <View style={styles.payOptContainer}>
            <View style={styles.optionInputs}>
              <FormMoneyInput
                style={styles.formMoneyInputInnerStyle}
                containerStyle={globalStyles.flex1}
                value={values.payMonthly.pricePerPickup}
                label={<FormInputLabel label="Price Per Pickup" required />}
                onChangeText={(value) => setFieldValue('payMonthly.pricePerPickup', value)}
                errorMessage={
                  touched.payMonthly?.pricePerPickup && nonEmptyString(errors.payMonthly?.pricePerPickup)
                    ? errors.payMonthly.pricePerPickup
                    : ''
                }
                currency={currency}
              />
              <FormMoneyInput
                style={styles.formMoneyInputInnerStyle}
                containerStyle={globalStyles.flex1}
                value={values.payMonthly.deposit}
                label={<FormInputLabel label="Deposit" required />}
                onChangeText={(value) => setFieldValue('payMonthly.deposit', value)}
                errorMessage={
                  touched.payMonthly?.deposit && nonEmptyString(errors.payMonthly?.deposit)
                    ? errors.payMonthly.deposit
                    : ''
                }
                currency={currency}
              />
              <FormDateTimeInput
                label={<FormInputLabel label="Final Installment Date" required />}
                value={values.payMonthly.finalPaymentDate}
                onChange={(item) => setFieldValue('payMonthly.finalPaymentDate', item)}
                timezone={timezone}
                errorMessage={errors.payMonthly?.finalPaymentDate as string}
              />
            </View>
            <View style={styles.statBoxContainer}>
              <StatBox
                title="Discount"
                stat={calculateDiscount(
                  calculateTotalPrice(numberOfPickups, values.payMonthly.pricePerPickup),
                  largestTotalPrice,
                )}
              />
              <StatBox title="Pickups" stat={numberOfPickups} />
              <StatBox
                title="Total Price"
                stat={totalPriceDisplay(numberOfPickups, values.payMonthly.pricePerPickup)}
              />
            </View>
          </View>
        )}
      </View>
      <View style={styles.billingOption}>
        <View style={styles.optionHeader}>
          <TextH4 style={styles.optionTitle}>Pay Weekly</TextH4>
          <ToggleButton
            onChange={() => setFieldValue('payWeekly.active', !values.payWeekly.active)}
            title=""
            value={values.payWeekly.active}
          />
        </View>
        {values.payWeekly.active && (
          <View style={styles.payOptContainer}>
            <View style={styles.optionInputs}>
              <FormMoneyInput
                style={styles.formMoneyInputInnerStyle}
                containerStyle={globalStyles.flex1}
                value={values.payWeekly.pricePerPickup}
                label={<FormInputLabel label="Price Per Pickup" required />}
                onChangeText={(value) => setFieldValue('payWeekly.pricePerPickup', value)}
                errorMessage={
                  touched.payWeekly?.pricePerPickup && nonEmptyString(errors.payWeekly?.pricePerPickup)
                    ? errors.payWeekly.pricePerPickup
                    : ''
                }
                currency={currency}
              />
              <FormMoneyInput
                style={styles.formMoneyInputInnerStyle}
                containerStyle={globalStyles.flex1}
                value={values.payWeekly.deposit}
                label={<FormInputLabel label="Deposit" required />}
                onChangeText={(value) => setFieldValue('payWeekly.deposit', value)}
                errorMessage={
                  touched.payWeekly?.deposit && nonEmptyString(errors.payWeekly?.deposit)
                    ? errors.payWeekly.deposit
                    : ''
                }
                currency={currency}
              />
              <FormDateTimeInput
                label={<FormInputLabel label="Final Installment Date" required />}
                value={values.payWeekly.finalPaymentDate}
                onChange={(item) => setFieldValue('payWeekly.finalPaymentDate', item)}
                timezone={timezone}
                errorMessage={errors.payWeekly?.finalPaymentDate as string}
              />
            </View>
            <View style={styles.statBoxContainer}>
              <StatBox
                title="Discount"
                stat={calculateDiscount(
                  calculateTotalPrice(numberOfPickups, values.payWeekly.pricePerPickup),
                  largestTotalPrice,
                )}
              />
              <StatBox title="Pickups" stat={numberOfPickups} />
              <StatBox title="Total Price" stat={totalPriceDisplay(numberOfPickups, values.payWeekly.pricePerPickup)} />
            </View>
          </View>
        )}
      </View>
    </ProductFormWrapper>
  )
}

const useStyles = CreateResponsiveStyle(
  {
    optionHeader: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    optionTitle: {
      fontFamily: typography.body.medium,
      fontSize: 18,
      paddingHorizontal: 10,
    },
    billingOption: {
      paddingVertical: 8,
    },
    optionInputs: {
      paddingRight: 25,
    },
    payOptContainer: {
      flexDirection: 'row',
    },
    statBoxContainer: {
      flexDirection: 'row',
      paddingTop: 20,
    },
    formMoneyInputInnerStyle: {
      width: '100%',
    },
    statHeaderText: {
      marginBottom: 5,
    },
  },
  {
    [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
      payOptContainer: {
        flexDirection: 'column',
      },
      statBoxContainer: {
        paddingLeft: 20,
      },
    },

    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      optionInputs: {
        paddingRight: 10,
      },
      optionHeader: {
        justifyContent: 'space-between',
      },
      statBoxContainer: {
        paddingTop: 10,
      },
    },
    [minSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
      optionInputs: {
        minWidth: 400,
        flexDirection: 'row',
      },
      statBoxContainer: {
        flex: 1,
      },
    },
    [minSize(DEVICE_SIZES.LARGE_DEVICE)]: {
      optionInputs: {
        flex: 1,
      },
    },
    [minSize(DEVICE_SIZES.EXTRA_LARGE_DEVICE)]: {
      optionInputs: {
        minWidth: 550,
      },
    },
  },
)
