import useKeyedState from '@/hooks/useKeyedState'
import { HomeParamList } from '@/navigation/types'
import { internationalSelector } from '@/redux/selectors'
import { GooglePlacesInputImperative, GooglePlacesSearchProps } from '@components'
import { DropdownMenuBtn } from '@elements'
import { entries } from '@helpers/typescript'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import {
  LocTypeDisplay,
  locTypeToDisplayMap,
  submitEstablishment,
  switchLocType,
} from '@screens/SearchScreen/searchScreen-helpers'
import { useCallback, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useInitialAddress } from '../useInitialAddress'

/** Business logic adapter for the logistics on Search screen */
export function useLogistics() {
  const dispatch = useDispatch()
  const { country } = useSelector(internationalSelector)
  const navigation = useNavigation<StackNavigationProp<HomeParamList, 'SearchScreen'>>()
  const { locType = 'zip', center, region } = useRoute<RouteProp<HomeParamList, 'SearchScreen'>>().params ?? {}
  /** This ref controls the google places search input imperatively */
  const searchRef = useRef<GooglePlacesInputImperative>(null)
  const [{ inputValue }, , , setters] = useKeyedState({ inputValue: '' })
  const initialAddressFx = useInitialAddress({ currentParams: { center, region, locType } })

  /** This is the locType display derived from the location type parameter */
  const locTypeDisplay = useMemo<LocTypeDisplay>(() => {
    if (!locType) {
      throw new Error('There should be an initial value for the location type display')
    }
    const locTypeDisplay = locTypeToDisplayMap[locType]

    if (!locTypeDisplay) {
      throw new Error('The display location should always match a location type parameter.')
    }
    return locTypeDisplay
  }, [locType])

  const locTypeButtons = useMemo<DropdownMenuBtn[]>(
    () =>
      entries(locTypeToDisplayMap).map(([locTypeForButton, locTypeForButtonDisplay]) => ({
        title: locTypeForButtonDisplay,
        onPress: async () =>
          switchLocType({
            inputValue,
            searchRef,
            locType,
            newLocType: locTypeForButton,
            dispatch,
            navigation,
            country,
          }),
      })),
    [navigation, dispatch, locType, inputValue, searchRef, country],
  )

  const onSelectEstablishment = useCallback<NonNullable<GooglePlacesSearchProps<any>['onSelectGooglePlace']>>(
    (item) => {
      return submitEstablishment({
        place: item,
        locType,
        dispatch,
        handleParams: navigation.setParams,
      })
    },
    [dispatch, locType, navigation.setParams],
  )

  const onClearAddress = useCallback(
    () => navigation.setParams({ center: undefined, radius: undefined, region: undefined, isGlobal: undefined }),
    [navigation],
  )

  return {
    ref: searchRef,
    onClearAddress,
    onSelectEstablishment,
    locTypeButtons,
    locTypeDisplay,
    initialAddressFx,
    setInputValue: setters.inputValue,
  }
}
