import { AdminCard } from '@/admin/components/AdminCard'
import { Logger } from '@/config/logger'
import { globalStyles } from '@/constants/Styles'
import { adminCurrencySelector } from '@/redux/selectors'
import { customSharesCollection } from '@api/framework/ClientCollections'
import { Alert, Divider, DividerVertical, FormButton, FormMoneyInput, Loader, Text, ToggleButton } from '@elements'
import { YUP_MONEY_OPTIONAL } from '@helpers/Yup'
import { CustomShare } from '@models/CustomShare'
import { MoneyWithCurrency } from '@models/Money'
import { Formik, FormikProps } from 'formik'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

type FormValues = {
  isEnabled: boolean
  maxIssueAmt?: MoneyWithCurrency
}

type Props = {
  share: CustomShare
  hasActiveRun: boolean
}

const validationSchema = Yup.object().shape({
  isEnabled: Yup.boolean().required(),
  maxIssueAmt: YUP_MONEY_OPTIONAL('Max amount to issue', { allowZero: false, requireCurrency: true }),
})

/** Card to manage unspent share value settings */
export function UnspentValueCard({ share, hasActiveRun }: Props) {
  const currency = useSelector(adminCurrencySelector)

  const handleSubmit = async (values: FormValues) => {
    if (hasActiveRun) {
      return Alert(
        "Can't update settings",
        'Please wait until the current run has finished before updating unspent value settings.',
      )
    }
    const issueUnspentValue = {
      isEnabled: values.isEnabled,
      maxIssueAmt: values.isEnabled ? values.maxIssueAmt : undefined,
    }
    Loader(true)
    try {
      await customSharesCollection.update({ id: share.id }, { issueUnspentValue })
    } catch (err: unknown) {
      Logger.error(err)
      Alert('Error', 'There was an error saving the settings. Please check your connection and try again.')
    } finally {
      Loader(false)
    }
  }

  return (
    <Formik<FormValues>
      initialValues={{
        isEnabled: share.issueUnspentValue.isEnabled,
        maxIssueAmt: share.issueUnspentValue.maxIssueAmt,
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, dirty, handleSubmit, isSubmitting, errors, touched }: FormikProps<FormValues>) => (
        <AdminCard>
          <View style={styles.header}>
            <Text size={16} type="bold" style={globalStyles.marginVertical10}>
              Unspent Share Value
            </Text>
            {dirty && (
              <FormButton title="Save Changes" onPress={handleSubmit} disabled={hasActiveRun || isSubmitting} />
            )}
          </View>
          <Text>
            If a customer does not spend the full value of their share, the remainder can be added to their Farm Credit
            account. Farm Credit can only be used at your farm.
          </Text>
          <Divider clear />
          <View style={globalStyles.flexRow}>
            <ToggleButton
              title="Issue unspent value as Farm Credit"
              value={values.isEnabled}
              onChange={(value) => setFieldValue('isEnabled', value)}
            />
            <DividerVertical clear />
            {values.isEnabled && (
              <FormMoneyInput
                row
                value={values.maxIssueAmt}
                onChangeText={(value) => setFieldValue('maxIssueAmt', value)}
                label="Max. Credit to Award/Distribution (leave blank for no limit)."
                placeholder="No maximum"
                currency={currency}
                errorMessage={touched.maxIssueAmt ? errors.maxIssueAmt : undefined}
              />
            )}
          </View>
        </AdminCard>
      )}
    </Formik>
  )
}

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})
