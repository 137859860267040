import { MoneyCalc, Zero } from '@helpers/money'
import { Money } from '@models/Money'
import { useEffect } from 'react'
import useKeyedState from '../../../../hooks/useKeyedState'

type Props = {
  selectedAmt: Money
  setSelectedAmount: (amt: Money) => void
  maxAmount: Money
}

/** This hook provides helpers for keeping the finite payment options in two-way sync with external changes */
export function useFinitePayment({ selectedAmt, setSelectedAmount, maxAmount }: Props) {
  const [values, set] = useKeyedState<{
    isSelected: boolean
    isEditingAmt: boolean
    updatedValue?: Money
  }>({ isSelected: MoneyCalc.isGTZero(selectedAmt), isEditingAmt: false, updatedValue: selectedAmt })

  /** This hook will make sure that the isSelected prop is always updated when the state is changed externally, like when a
   * new EBT card is added */
  useEffect(() => {
    set('isSelected', MoneyCalc.isGTZero(selectedAmt))
    set('updatedValue', selectedAmt)
  }, [selectedAmt, set])

  const updatePmtAmount = (amt?: Money) => {
    set('isEditingAmt', false)
    // This will set the amount to the maximum available between the eligible payment amount and the selected amount
    const pmtAmt = amt ? MoneyCalc.min(amt, maxAmount) : maxAmount
    setSelectedAmount(pmtAmt)
    set('updatedValue', pmtAmt)

    // If we are clearing the input then we should set the toggle to false
    if (MoneyCalc.isZero(pmtAmt)) {
      set('isSelected', false)
    }
  }

  const onTogglePmt = (value: boolean) => {
    set('isSelected', value)
    // If we are toggling off the payment methods then we should set the amount to 0
    const pmtAmt = value ? maxAmount : Zero
    updatePmtAmount(pmtAmt)
  }

  return {
    ...values,
    setValue: set,
    onTogglePmt,
    updatePmtAmount,
  }
}
