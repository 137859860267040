import { Divider, Text } from '@elements'
import { formatMoney } from '@helpers/display'
import { MoneyCalc, Zero } from '@helpers/money'
import {
  Invoice,
  getInvoiceTips,
  invoiceItemSubtotal,
  invoiceItemTotal,
  invoiceSubtotal,
  invoiceTotal,
  isProductFee,
  isTipOrServiceFee,
} from '@models/Invoice'
import { Money } from '@models/Money'
import React from 'react'
import { StyleSheet, View } from 'react-native'

import { globalStyles } from '../../../constants/Styles'
import { ItemRow } from './ItemRow'

import Colors from '@/constants/Colors'
import { getInvItemDescription } from '@helpers/invoice'
import { getProductFeesFromInvoice } from '@helpers/productFee'
import { CoverFee } from '@helpers/serviceFee'
import { FeeType } from '@models/ProductFee'

type Props = {
  invoice: Invoice
  fcAmount?: Money
  tips?: CoverFee
  discount?: Money
}
export function OrderDetails({ invoice, fcAmount = Zero, discount = Zero, tips }: Props) {
  /** We have to make sure we clear existing invoice tip before add tip amount. The reason for that is because the invoice could have tip already or can be added tip on it later, so we want to rely on tips.value amount to be the trusty tip amount source */
  const total = MoneyCalc.subtract(
    MoneyCalc.add(MoneyCalc.subtract(invoiceTotal(invoice), getInvoiceTips(invoice)), tips?.value ?? Zero),
    fcAmount,
  )
  const tax = invoiceTotal({ items: getProductFeesFromInvoice(invoice, FeeType.Tax) })
  const additionalFees = getProductFeesFromInvoice(invoice, FeeType.Fee)

  return (
    <View style={globalStyles.margin20}>
      <ItemRow isHeader leftSideValues={['Item']} rightSideValues={['Quantity', 'Price', 'Amount']} />

      {invoice.items
        .filter((itm) => !isTipOrServiceFee(itm.id) && !isProductFee(itm.id))
        .map((itm) => {
          const amount = invoiceItemSubtotal(itm)
          const unitPrice = Math.floor(amount.value / itm.quantity)
          const isCancelled = !!itm.cancelled
          return (
            <ItemRow
              key={itm.id}
              leftSideValues={[`${isCancelled ? '(CANCELLED) ' : ''}${getInvItemDescription(itm)}`]}
              rightSideValues={[
                itm.quantity.toString(),
                formatMoney(unitPrice),
                isCancelled ? formatMoney(Zero) : formatMoney(amount),
              ]}
            />
          )
        })}
      <Divider />
      <View style={styles.totalsContainer}>
        <View style={styles.leftColumn}>
          <Text style={styles.text} size={14} type="medium">
            Subtotal
          </Text>

          {MoneyCalc.isGTZero(discount) && (
            <Text style={styles.text} size={14} type="medium" color={Colors.green}>
              Discounts
            </Text>
          )}

          {tips && MoneyCalc.isGTZero(tips.value) && (
            <Text style={styles.text} size={14} type="medium">
              Covered fees
              {MoneyCalc.isZero(tips.tip) ? '' : '& Tips'}
            </Text>
          )}

          {MoneyCalc.isGTZero(tax) && (
            <Text style={styles.text} size={14} type="medium">
              Taxes
            </Text>
          )}

          {additionalFees.length > 0 &&
            additionalFees.map((fee) => (
              <Text key={fee.id} style={styles.text} size={14} type="medium">
                {fee.description}
              </Text>
            ))}

          {MoneyCalc.isGTZero(fcAmount) && (
            <Text style={styles.text} color={Colors.green} size={14} type="medium">
              Farm credit applied
            </Text>
          )}

          <Text style={styles.text} size={14} type="medium">
            Total
          </Text>
        </View>

        <View style={styles.rightColumn}>
          <Text style={styles.text} size={14} type="medium">
            {formatMoney(invoiceSubtotal(invoice))}
          </Text>

          {MoneyCalc.isGTZero(discount) && (
            <Text style={styles.text} color={Colors.green} size={14} type="medium">
              -{formatMoney(discount)}
            </Text>
          )}

          {tips && MoneyCalc.isGTZero(tips.value) && (
            <Text style={styles.text} size={14} type="medium">
              {formatMoney(tips.value)}
            </Text>
          )}

          {MoneyCalc.isGTZero(tax) && (
            <Text style={styles.text} size={14} type="medium">
              {formatMoney(tax)}
            </Text>
          )}

          {additionalFees.length > 0 &&
            additionalFees.map((fee) => (
              <Text key={`${fee.id}_${fee.description}`} style={styles.text} size={14} type="medium">
                {formatMoney(invoiceItemTotal(fee))}
              </Text>
            ))}

          {MoneyCalc.isGTZero(fcAmount) && (
            <Text style={styles.text} color={Colors.green} size={14} type="medium">
              -{formatMoney(fcAmount)}
            </Text>
          )}
          <Text style={styles.text} size={14} type="medium">
            {formatMoney(MoneyCalc.isGTZero(total) ? total : Zero)}
          </Text>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  text: {
    marginVertical: 5,
  },
  totalsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginVertical: 10,
  },
  leftColumn: {
    marginRight: 30,
    alignItems: 'flex-end',
  },

  rightColumn: {
    alignItems: 'flex-end',
  },
})
