import { CartServiceType } from '@/constants/types/cartService'
import { useCartService } from '@/hooks/useCart'
import { getPickupsCacheAddtoCartFlow } from '@/hooks/useCart/addToCartFlow/helpers'
import { removeComplexDuplicates, removeDuplicates } from '@helpers/helpers'
import { objToStr } from '@helpers/log'
import { matchesAppModeSchedule } from '@helpers/products'
import { sortSchedulesByDailyAndDayOfWeek } from '@helpers/sorting'
import { isSameDay } from '@helpers/time'
import { getFarmCartItems } from '@models/Cart'
import { Distribution } from '@models/Distribution'
import { Location, isNonPickupDistLocation } from '@models/Location'
import { CartItem } from '@models/Order'
import { PhysicalProduct, Product, isPhysical } from '@models/Product'
import { useEffect, useMemo, useState } from 'react'

export type UseAvailableSchedulesProps = {
  prod: PhysicalProduct
  /** If schedule ids are provided, only schedules included here will be returned */
  schedulesIdsFilter?: Distribution['id'][]
  /** If the location filter is provided, only schedules with this location id will be returned */
  locsIdsFilter?: Location['id'][]
  /** Admin mode allows for greater privileges. Should be true when using this component in the order creator. */
  isAdmin: boolean
  /** Will make sure to only consider those schedules that match the current app mode */
  isWholesale: boolean
  cartServiceType: CartServiceType
}

type UseAvailSchedulesReturn = {
  availableSchedules: Distribution[] | undefined
  availableRegions: string[] | undefined
}

/** Returns the product schedules that may be selected by the customer in the addToCart flow */
export function useAvailableSchedules({
  prod,
  schedulesIdsFilter,
  locsIdsFilter,
  isAdmin,
  isWholesale,
  cartServiceType,
}: UseAvailableSchedulesProps): UseAvailSchedulesReturn {
  // This call to useCartService must pass the farmId prop, because this hook needs to consider only the cart items from the farm of the product in process of being added to the cart
  const { cart } = useCartService({ farmId: prod.farm.id, cartServiceType, isWholesale })
  const [availableSchedules, setAvailableSchedules] = useState<Distribution[]>()

  useEffect(() => {
    if (!isPhysical(prod)) return setAvailableSchedules([])
    const { availSchedules } = getAvailableSchedules({
      prod,
      isAdmin,
      schedulesIdsFilter,
      locsIdsFilter,
      isWholesale,
      cart,
    })

    return setAvailableSchedules(availSchedules)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    prod.id,
    isAdmin,
    isWholesale,
    /**
     * we're using a stringified ids list as dependency, which is a simple way to avoid using deep comparison.
     * it's OK for this purpose because the ids are not expected to change or be re-ordered, and even if they do it's not a big deal.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    objToStr(locsIdsFilter),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    objToStr(schedulesIdsFilter),
    // This is saying we only want this to trigger if the cart items' pickups change. And we're passing in the dates as an ISO string for primitive comparison
    // eslint-disable-next-line react-hooks/exhaustive-deps
    objToStr(cart.flatMap((ci) => (ci.pickups ?? []).map((d) => d.toISODate()))),
  ])

  /** When the locationType is nonPickup, this should get the unique regions of the nonPickup locations of each schedule. This represents the regions where the product is available in a general sense (Without any filters applied yet) */
  const availableRegions = useMemo(() => {
    if (!availableSchedules) return undefined
    return removeDuplicates(
      availableSchedules
        .map((sch) => sch.location)
        .filter(isNonPickupDistLocation)
        .flatMap((loc) => loc.regions),
    )
  }, [availableSchedules])

  return { availableSchedules, availableRegions }
}

/** Checks if a single schedule is available for adding to the cart in the addToCart flow based on the most basic set of criteria such as the schedule's fields, its catalog, admin privilege, and whether it has future pickups.
 * - It does not take into account the broader data context like current cart data. In the addtoCart flow there may be other checks required at some steps, and this may be part of a broader filtering logic.
 * - This logic should be reused in every place in the addtoCart flow that checks schedule availability */
export function isScheduleAvailable({
  sch,
  prod,
  isAdmin,
  isWholesale,
}: {
  sch: Distribution
  prod: Product
  isAdmin: boolean
  isWholesale: boolean
}) {
  if (sch.isHidden) return false
  if (!!sch.closed && !isAdmin) return false
  if (!matchesAppModeSchedule(isWholesale)(sch)) return false

  const pickups = getPickupsCacheAddtoCartFlow(sch, prod, {
    excludeClosedDistros: !isAdmin,
    ignoreOrderCutoffWindow: isAdmin,
    ignoreDisableBuyInFuture: isAdmin,
  })
  if (!pickups[0]) return false

  return sch
}

/** Will return a filtered array of schedules that includes only those where the cart dates are available */
function limitSchedulesByCartDates({
  prod,
  schedules,
  cart: cartProp,
  isWholesale,
  isAdmin,
}: {
  prod: Product
  /** These schedules are the main input. This fn will return a filtered copy of this array */
  schedules: Distribution[]
  cart: CartItem[]
  isWholesale: boolean
  isAdmin: boolean
}): Distribution[] {
  if (!isWholesale) return schedules

  // Select only the items of the farm and appMode
  const cart = getFarmCartItems({ items: cartProp, farmId: prod.farm.id, isWholesale })

  if (!cart.length) return schedules

  /** In wholesale if there's items in the cart we should limit the schedules to those where the date/s in the cart can also be selected */
  const cartDates = removeComplexDuplicates(
    cart.flatMap((ci) => ci.pickups ?? []),
    isSameDay,
  )
  if (!cartDates.length) return schedules

  // If there's any date/s in the cart, limit the schedule options to only those schedules where the cart date/s is/are available
  return schedules.filter((sch) => {
    const scheduleDates = getPickupsCacheAddtoCartFlow(sch, prod, {
      excludeClosedDistros: !isAdmin,
      ignoreOrderCutoffWindow: isAdmin,
      ignoreDisableBuyInFuture: isAdmin,
    })

    return scheduleDates.some((scheduleDate) => !!cartDates.find((cartDate) => isSameDay(cartDate, scheduleDate)))
  })
}

/** Filters a set of schedules from a product based on several options related to the schedule selection step of the addToCart flow.
 * - This is meant to consider all aspects of schedule availability for addtoCart purposes, and should return the set of schedules the user can freely select from.
 * - The returned schedules cannot be used for auto-add purposes (Only for user selection) because they come straight from the product data and therefore any NonPickup ones won't have a selected address */
export function getAvailableSchedules({
  prod,
  isAdmin,
  locsIdsFilter,
  schedulesIdsFilter,
  isWholesale,
  cart,
}: {
  prod: PhysicalProduct
  isAdmin: boolean
  schedulesIdsFilter: string[] | undefined
  locsIdsFilter: string[] | undefined
  isWholesale: boolean
  cart: CartItem[]
}): { availSchedules: Distribution[]; wasLimitedByIdsFilters: boolean; wasLimitedByCartDates: boolean } {
  const availSchedulesB4Constraints = prod.distributions.filter((sch) =>
    isScheduleAvailable({ sch, prod, isAdmin, isWholesale }),
  )

  const availSchedulesByIdsFilters = availSchedulesB4Constraints.filter((sch) => {
    if (schedulesIdsFilter && !schedulesIdsFilter.includes(sch.id)) return false
    if (locsIdsFilter && !locsIdsFilter.includes(sch.location.id)) return false
    return true
  })

  const wasLimitedByIdsFilters = availSchedulesByIdsFilters.length !== availSchedulesB4Constraints.length

  const availSchedulesByCartDates = limitSchedulesByCartDates({
    schedules: availSchedulesByIdsFilters,
    isAdmin,
    isWholesale,
    prod,
    cart,
  }).sort(sortSchedulesByDailyAndDayOfWeek)

  const wasLimitedByCartDates = availSchedulesB4Constraints.length !== availSchedulesByCartDates.length

  return {
    availSchedules: availSchedulesByCartDates,
    wasLimitedByIdsFilters,
    wasLimitedByCartDates,
  }
}
