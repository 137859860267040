import { omit } from '@helpers/typescript'
import { UserAddress } from '@models/UserAddress'
import type { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { Address } from '@models/Address'
import { unmarshalAddress } from './Address'
import { prepareMarshal, prepareUnmarshal } from './encoding'

/** Converts the data provided by the Firestore document into the structure expected by the UserAddress model. */
export function unmarshalUserAddress(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): UserAddress {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)

  // We want the address fields to be processed with the logic in unmarshalAddress
  const addressData: Address = {
    street1: data.street1,
    street2: data.street2,
    city: data.city,
    state: data.state,
    zipcode: data.zipcode,
    country: data.country,
    coordinate: data.coordinate,
  }

  const addressDataUnmarshalled = unmarshalAddress(addressData)

  return { ...data, ...addressDataUnmarshalled, id } as UserAddress
}

/** marshalUserAddress converts the address structure into a Firebase document structure. */
export function marshalUserAddress(address: Partial<UserAddress>): DocumentData {
  return omit(prepareMarshal(address), 'id')
}
