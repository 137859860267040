import { getCustomerHistoricTotalPurchaseAmounts } from '@api/Invoices'
import { snapshotFarmBalance } from '@api/Payments'
import { ButtonClear, LoadingView, Modal, TextH2, TextH3, typography } from '@elements'
import { openUrl } from '@helpers/client'
import { formatMoney } from '@helpers/display'
import { Farm } from '@models/Farm'
import { Money } from '@models/Money'
import { RouteProp, useRoute } from '@react-navigation/native'
import { CustomerParamList } from 'admin/navigation/types'
import { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import { useSnapshot } from '@/hooks/useSnapshot'
import { AdjustBalance } from '../AdjustBalance'
import { AdminCustomerHeader } from '../AdminCustomerOrdersSection/components/Header'

import { AdminCard } from '@/admin/components/AdminCard'
import Colors from '@/constants/Colors'
import { useHasPermissionWithFlag } from '@/hooks/useHasPermission'
import { RootState } from '@/redux/reducers/types'
import { adminCurrencySelector, adminFarmSelector, adminParamsSelector } from '@/redux/selectors'
import { AccessRight, Permission } from '@helpers/Permission'
import { MoneyCalc } from '@helpers/money'

interface CustomerOverview {
  allTimePurchases: string
}
export function CustomerInfo({
  title,
  stat,
  goStripeCustomer,
}: {
  title: string
  stat: string
  goStripeCustomer?: () => void
}) {
  const styles = useStyles()
  const hasAccessViewStripe = useHasPermissionWithFlag(Permission.Orders, AccessRight.Edit)
  return (
    <View>
      {title === 'Farm Credit' ? (
        <View style={styles.farmCreditInfo}>
          <TextH3 style={styles.infoTitle}>{title}</TextH3>
          <TextH2 size={24} style={styles.stat}>
            {stat}
          </TextH2>
          {goStripeCustomer && hasAccessViewStripe && (
            <ButtonClear title="View details in Stripe" onPress={goStripeCustomer} />
          )}
        </View>
      ) : (
        <View style={styles.info}>
          <TextH3 style={styles.infoTitle}>{title}</TextH3>
          <TextH2 size={24} style={styles.stat}>
            {stat}
          </TextH2>
        </View>
      )}
    </View>
  )
}

const goStripeCustomer = (customer: string, accountRef: string) => {
  const url = `https://dashboard.stripe.com/${accountRef}/customers/${customer}/balance_transactions`
  openUrl(url)
}

async function getCustomerOverview(
  farmId: string | undefined,
  customerId: string | undefined,
): Promise<CustomerOverview> {
  if (!farmId || !customerId) return { allTimePurchases: '$-.--' }
  const totalAmounts = await getCustomerHistoricTotalPurchaseAmounts(farmId, customerId)

  const allTimePurchases = formatMoney(totalAmounts)

  return { allTimePurchases }
}

export function CustomerOverviewCard({ stripeCust, totalDue }: { stripeCust?: string; totalDue: Money }) {
  const styles = useStyles()
  const { params } = useRoute<RouteProp<CustomerParamList, 'CustomerDetails'>>()
  const customer = useSelector(adminParamsSelector).customer
  const [customerInfo, setCustomerInfo] = useState<CustomerOverview | undefined>()
  const farm = useSelector<RootState, Farm>(adminFarmSelector)
  const farmBalance = useSnapshot(
    'snapshotFarmBalance',
    snapshotFarmBalance,
    [params.custId, farm.id],
    Boolean(params.custId && farm.id),
  )
  const hasAccessEdit = useHasPermissionWithFlag(Permission.Orders, AccessRight.Edit)
  const currency = useSelector(adminCurrencySelector)

  const adjustUserBalance = () => {
    // Gets a string from date unique to 10 seconds intervals
    const uniqueId = `${customer!.id}${farm.id}${Date.now().toString().substring(0, 9)}`
    Modal(
      <AdjustBalance
        userId={params.custId}
        farmId={farm.id}
        uniqueId={uniqueId}
        farmBalance={MoneyCalc.fromCents(farmBalance?.data?.amount || 0, currency)}
      />,
      { title: 'Adjust Customer Balance' },
    )
  }

  useEffect(() => {
    getCustomerOverview(farm?.id, customer?.id).then((data) => {
      setCustomerInfo(data)
    })
  }, [farm?.id, customer?.id])

  return (
    <AdminCard>
      <AdminCustomerHeader
        title="Overview"
        btns={hasAccessEdit ? [{ title: 'Adjust Farm Credit', onPress: adjustUserBalance }] : undefined}
      />
      <View style={styles.wrapper}>
        <CustomerInfo title="Outstanding Balance" stat={formatMoney(totalDue)} />
        <LoadingView loading={farmBalance.loading}>
          {stripeCust ? (
            <CustomerInfo
              title="Farm Credit"
              stat={formatMoney(farmBalance.data?.amount ?? 0)}
              goStripeCustomer={() => goStripeCustomer(stripeCust, farm.accountRef)}
            />
          ) : (
            <CustomerInfo title="Farm Credit" stat={formatMoney(0)} />
          )}
        </LoadingView>
        <LoadingView loading={!customerInfo} style={styles.allTimePurchase}>
          <CustomerInfo title="All-Time Purchases" stat={`${customerInfo?.allTimePurchases}`} />
        </LoadingView>
      </View>
    </AdminCard>
  )
}

const useStyles = CreateResponsiveStyle(
  {
    sectionTitle: {
      marginBottom: 10,
    },
    hyperlink: {
      color: Colors.blue,
      textDecorationLine: 'underline',
    },
    wrapper: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      width: '100%',
    },
    allTimePurchase: {
      flexGrow: 0,
    },
    info: {
      marginHorizontal: 0,
    },
    farmCreditInfo: {
      alignItems: 'center',
    },
    infoTitle: {
      fontFamily: typography.body.regular,
      fontWeight: '400',
      marginBottom: 15,
    },
    stat: {
      fontFamily: typography.body.regular,
      fontWeight: '600',
    },
  },
  {
    [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
      wrapper: {
        flexDirection: 'column',
        marginHorizontal: 'auto',
        justifyContent: undefined,
      },
      sectionTitle: {
        marginBottom: 0,
      },
      info: {
        paddingHorizontal: 0,
        paddingVertical: 20,
        marginHorizontal: 'auto',
      },
      infoTitle: {
        textAlign: 'center',
      },
      stat: {
        textAlign: 'center',
      },
      farmCreditInfo: {
        borderTopWidth: 1,
        borderBottomWidth: 1,
        paddingVertical: 20,
        borderColor: Colors.lightGray,
      },
    },
  },
)
