import { Money } from '@models/Money'
import { PickupItemStatus } from '@models/Order'
import { getBaseUnit, isShare } from '@models/Product'
import { DistributionDetailsSummary, PaymentStatus, ReportType, SelectedUnit, SummaryItem } from '@models/Summary'
import DecimalCalc from './decimal'
import { formatMoney } from './display'
import { MoneyCalc } from './money'

/** formatPaymentStatus returns a formatted payment status string. */
export function formatPaymentStatus(paymentStatus: PaymentStatus): string {
  return paymentStatus.toUpperCase()
}

/** producer returns the name of the producer that sold the item. By default the producer is the farm that created the product. */
export function producer(item: SummaryItem<ReportType>): string | undefined {
  let producer
  if ('producer' in item.product) {
    producer = item.product.producer
  }
  if (!producer) {
    producer = item.product.farm.name
  }
  return producer
}

/** orderedUnits returns the number of units ordered relative to the base unit. */
export function orderedUnits(item: SummaryItem<ReportType>): number {
  let total = 0
  for (const selectedUnit of item.selectedUnits) {
    if (!selectedUnit.unit) {
      total += selectedUnit.quantity
      continue
    }
    total = DecimalCalc.add(total, DecimalCalc.multiply(selectedUnit.quantity, selectedUnit.unit.multiplier))
  }
  return total
}

/** sku returns the SKU related to the supplied summary item. */
export function sku(item: SummaryItem<ReportType>, selectedUnit: SelectedUnit<ReportType>): string | undefined {
  if (isShare(item.product.type)) {
    return item.product.sku
  }
  if (selectedUnit.unit) {
    return selectedUnit.unit.sku
  }

  return undefined
}

/** selectedUnitName returns the name of the unit for the supplied item. If the selected unit is not available, the base unit name will be returned instead. */
export function selectedUnitName(item: SummaryItem<ReportType>, selectedUnit: SelectedUnit<ReportType>): string {
  if (!selectedUnit.unit?.name) {
    return getBaseUnit(item.product)
  }
  return selectedUnit.unit.name
}

/** selectedUnitPrice returns the per-unit amount of the selected item. */
export function selectedUnitPrice(selectedUnit: SelectedUnit<ReportType>): string {
  return formatMoney(MoneyCalc.divide(selectedUnit.amount, selectedUnit.quantity))
}

/** selectedUnitStatus returns the status of the selected pickupItem (product) for singInSheet. */
export function selectedUnitStatus(selectedUnit: SelectedUnit<ReportType.SignIn>): string {
  return selectedUnit.status.toUpperCase()
}

/** qtyOfBuyingOption returns the quantity of the selected item. */
export function qtyOfBuyingOption(selectedUnit: SelectedUnit<ReportType>): number {
  if (selectedUnit.status === PickupItemStatus.Cancelled || selectedUnit.status === PickupItemStatus.Vacation) {
    return 0
  } else {
    return selectedUnit.quantity
  }
}

/** selectedUnitTotal returns the full amount of the selected item. */
export function selectedUnitTotal(selectedUnit: SelectedUnit<ReportType>): Money {
  return selectedUnit.amount
}

/** summaryEmails returns a collection of all of the emails made available in the distribution summary. The results are sorted alphabetically. */
export function summaryEmails(summary: DistributionDetailsSummary): string[] {
  const emails = new Set<string>()

  function addEmail(email: string | undefined) {
    if (!email) {
      return
    }

    const trimmedEmail = email.trim()
    if (trimmedEmail.length === 0) {
      return
    }
    emails.add(trimmedEmail.toLowerCase())
  }

  for (const { user } of summary.items) {
    addEmail(user.email)
    addEmail(user.email2)
  }

  return Array.from(emails).sort()
}
