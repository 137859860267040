import { updateFarm } from '@api/Farms'
import { FormButton, FormInputLabel, FormMoneyInput, FormPickerInput, TextH2, Toast } from '@elements'
import { YUP_MONEY_OPTIONAL } from '@helpers/Yup'
import { MoneyWithCurrency } from '@models/Money'
import { Formik, FormikProps } from 'formik'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { adminCurrencySelector, adminFarmSelector } from '../../../../../redux/selectors'
import { AdminCard } from '../../../../components/AdminCard'
import { WholesaleFormType, getWholesaleUpdateFields, wholesaleDueDateToleranceItems } from './helpers'

const validationSchema: Yup.ObjectSchema<WholesaleFormType> = Yup.object().shape({
  minCartAmount: YUP_MONEY_OPTIONAL('Minimum cart amount', { requireCurrency: true }),
  dueDateTolerance: Yup.number().label('Due date tolerance').required(),
})

export const WholesaleTab = memo(function WholesaleTab() {
  const [loading, setLoading] = useState(false)
  const farm = useSelector(adminFarmSelector)

  const initialValues: WholesaleFormType = useMemo(
    () => ({
      minCartAmount: farm.orderMinimum?.wholesale as MoneyWithCurrency,
      dueDateTolerance: farm.dueDateTolerance?.wholesale ?? 0,
    }),
    [farm],
  )

  const onSubmit = useCallback(
    async (values: WholesaleFormType) => {
      const updateFields = getWholesaleUpdateFields(values, farm)
      if (updateFields) {
        setLoading(true)
        await updateFarm({ id: farm.id, ...updateFields })
        setLoading(false)
        Toast('Changes saved')
      } else {
        Toast('Nothing to save.')
      }
    },
    [farm],
  )

  const currency = useSelector(adminCurrencySelector)

  return (
    <AdminCard>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({
          values,
          errors,
          setFieldValue,
          handleSubmit,
          touched,
          dirty,
          setFieldTouched,
        }: FormikProps<WholesaleFormType>) => (
          <>
            <View style={styles.formHeader}>
              <TextH2 size={18}>Wholesale Terms</TextH2>
              {dirty && <FormButton title="Save" onPress={handleSubmit} loading={loading} />}
            </View>

            <View style={styles.formContainer}>
              <FormMoneyInput
                value={values.minCartAmount}
                onChangeText={async (value) => {
                  await setFieldValue('minCartAmount', value)
                  setFieldTouched('minCartAmount')
                }}
                errorMessage={touched.minCartAmount && errors.minCartAmount ? errors.minCartAmount : ''}
                label={<FormInputLabel label="Minimum Cart Amount" />}
                currency={currency}
              />
              <FormPickerInput
                label="Due Date Tolerance"
                items={wholesaleDueDateToleranceItems}
                value={values.dueDateTolerance.toString()}
                onValueChange={async (val) => {
                  await setFieldValue('dueDateTolerance', Number(val))
                  setFieldTouched('dueDateTolerance')
                }}
                errorMessage={touched.dueDateTolerance && errors.dueDateTolerance ? errors.dueDateTolerance : ''}
              />
            </View>
          </>
        )}
      </Formik>
    </AdminCard>
  )
})

const styles = StyleSheet.create({
  formContainer: {
    maxWidth: 400,
  },
  formHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})
