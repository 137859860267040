import { SignInProviders, User } from '@models/User'

import { signOut as signout } from '@/redux/actions/signout'
import { buildUser } from '@helpers/builders/buildUser'
import { FirebaseAuthTypes } from '@react-native-firebase/auth'
import { ErrorWithCode } from '@shared/Errors'
import { Logger } from '../config/logger'
import { loadUserByEmail } from './Users'
import { auth } from './db'
import { usersCollection } from './framework/ClientCollections'
import { callEndpoint } from './v2'

// These functions have been separated from the Users API to provide a clean separation from platform dependencies
// in order to allow unit testing. Some overlap of concerns exist in these functions that should be revisited
// down the road. For now these functions are left intact to maintain compatibility with legacy code.

export const registerWithPhone = async (
  verificationId: string,
  verificationCode: string,
  phoneNumber: string,
  email: string,
  firstName: string,
  lastName: string,
) => {
  // Check if the user exists or is duplicate
  await checkUserExists(email, SignInProviders.Phone)

  // Sign user in
  const user = await signInWithPhone(verificationId, verificationCode)

  await createNewPhoneUser({ id: user.user!.uid, phoneNumber, email, name: { firstName, lastName } })
}

export const createNewPhoneUser = async (user: Pick<User, 'id' | 'email' | 'phoneNumber' | 'name'>) => {
  // Set new user up in firestore
  const userObject = buildUser(user.id, user.name.firstName, user.name.lastName, user.email, SignInProviders.Phone)
  userObject.phoneNumber = user.phoneNumber

  await usersCollection.createWithId(userObject)
}

export const signInWithPhone = async (verificationId: string, verificationCode: string) => {
  return auth().signInWithPhoneCredential(verificationId, verificationCode)
}

export const signInWithGoogle = async (id_token: string) => {
  // Sign user in
  const user = await auth().signInWithGoogle(id_token)
  if (!user.user?.email || !user.user.displayName) throw new Error('Login with Google failed')

  try {
    // Check if the user exists or is duplicate
    const userDoc = await checkUserExists(user.user.email, SignInProviders.Google)

    // If it is a new user setup in firestore
    if (!userDoc) {
      // Get first and last name if possible
      const hasSpace = user.user.displayName.indexOf(' ')
      const firstName = hasSpace > 0 ? user.user.displayName.slice(0, hasSpace) : user.user.displayName
      const lastName = hasSpace > 0 ? user.user.displayName.slice(hasSpace + 1) : ''

      const userObject = buildUser(
        user.user!.uid,
        firstName,
        lastName,
        user.user.email,
        SignInProviders.Google,
        user.user.photoURL!,
      )
      await usersCollection.createWithId(userObject)
    }
  } catch (e) {
    // We are trying to create a new user with the same email. We should block this and delete the newly created user
    if (e instanceof Error && e.name === 'provider-mismatch') {
      // One more safety check to ensure that the account was created now which is the last signIn time
      if (user.user.metadata.creationTime && user.user.metadata.creationTime === user.user.metadata.lastSignInTime) {
        Logger.debug('Deleting newly created user')
        await auth().deleteUser()
        signout()
      }
    }
    throw e
  }
}

export const signInWithApple = async (
  identityToken: string,
  email: string,
  firstName: string,
  lastName: string,
  nonce: string,
) => {
  // Check if the user exists and get them
  const userDoc = await checkUserExists(email, SignInProviders.Apple)

  // Sign user in
  const user = await auth().signInWithAppleNative(identityToken, nonce)

  // If it is a new user setup in firestore
  // Apple does not return email if it is login, so we want to make sure we don't try and create a new user unless it is signup
  if (!userDoc && email) {
    const userObject = buildUser(user.user!.uid, firstName, lastName, email, SignInProviders.Apple)
    await usersCollection.createWithId(userObject)
    if (email.indexOf('privaterelay') !== -1) {
      // They are using a private relay so we need to prompt for their email
      throw new Error('Private Relay')
    }
  }
}

export const signInWithAppleWeb = async () => {
  // Sign user in
  const user = await auth().signInWithAppleWeb()

  // Check if the user exists and get them
  const userDoc = await usersCollection.fetch(user.user!.uid)

  // If it is a new user setup in firestore
  if (!userDoc && user.user?.email) {
    // Get first and last name if possible
    const displayName = user.user.displayName || 'GrownBy User'
    const hasSpace = displayName.indexOf(' ')
    const firstName = hasSpace > 0 ? displayName.slice(0, hasSpace) : displayName
    const lastName = hasSpace > 0 ? displayName.slice(hasSpace + 1) : ''

    const userObject = buildUser(
      user.user.uid,
      firstName,
      lastName,
      user.user.email,
      SignInProviders.Apple,
      user.user.photoURL!,
    )
    await usersCollection.createWithId(userObject)
    if (user.user.email.indexOf('privaterelay') !== -1) {
      // They are using a private relay, so we need to prompt for their email
      throw new Error('Private Relay')
    }
  }
}

export const registerWithEmailAndPassword = async (
  email: string,
  password: string,
  firstName: string,
  lastName: string,
) => {
  // Check if the user exists or is duplicate
  const userDoc = await checkUserExists(email, SignInProviders.Email)

  if (userDoc) throw new Error('A user already exists with this email')

  // Register user
  const user = await auth().createUserWithEmailAndPassword(email, password)

  // If it is a new user setup in firestore
  const userObject = buildUser(user.user!.uid, firstName, lastName, email, SignInProviders.Email)
  await usersCollection.createWithId(userObject)
  return userObject
}

export const signInWithEmailAndPassword = (email: string, password: string) => {
  return auth().signInWithEmailAndPassword(email, password)
}
export const signInWithCustomToken = (token: string) => auth().signInWithCustomToken(token)

/**
 * These two functions will return the user if they exist and signed in with that provider.
 * If the user does not exist it will return undefined.
 * If the user signed in with a different provider it will throw an error.
 */
const checkUserExists = async (email: string, provider: SignInProviders) => {
  if (!email) return undefined
  let user
  user = await loadUserByEmail(email).catch(() => (user = undefined))
  // Checks if the user already exists or used a different provider in the past
  if (user && user.signInProvider && user.signInProvider !== provider) {
    throw new ErrorWithCode({
      code: 'provider-mismatch',
      devMsg: `An account with this email already exists, please login with ${user.signInProvider}.`,
      data: { user, provider },
    })
  }
  return user
}

/** This will be need to support mobile to check multi-factor enrolled status. */
export async function checkMultiFactorEnrollStatus(userId: string): Promise<boolean> {
  return callEndpoint('v2.User.getMultiFactorEnrollmentStatus', { userId })
}

/**
 * This function can be used when the user is automatically logged into Firebase Native Auth and we need to also sign them
 * into Firebase web auth. This should be used in very limited scenarios like for auto verify on Android which cannot sign
 * the user into the web app.
 */
export async function syncMobileLoginToWebLogin(user: FirebaseAuthTypes.User): Promise<void> {
  // Get the encrypted web token of the users native logged in state
  const nativeLoginToken = await user.getIdToken()
  if (!nativeLoginToken)
    throw new ErrorWithCode({
      code: 'login-token-generation',
      devMsg:
        'Native login token not found, this indicates that the user is attempting to sync login state with web but is not logged in onn native.',
      uiMsg: 'An unknown error occurred while logging you in, Please contact support. (AUTH NOT FOUND)',
    })
  try {
    const res = await callEndpoint('v2.Authentication.generateLoginToken', { nativeLoginToken })

    await signInWithCustomToken(res.customAuthToken)
  } catch (e) {
    throw new ErrorWithCode({
      code: 'login-with-token',
      devMsg: 'createLoginToken call failed indicating invalid nativeLoginToken or an unknown internal error.',
      uiMsg: 'An unknown error occurred while logging you in, Please contact support. (Login token creation failed)',
      data: e,
    })
  }
}
