import { Alert, Icon, Text, typography } from '@elements'
import { isNonNullish } from '@helpers/helpers'
import { getDistributionPickups, mapPickupExceptions } from '@helpers/order'
import { Distribution } from '@models/Distribution'
import { Exception, Frequency, isPatternException, isRescheduleException, isSkipException } from '@models/Schedule'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { Calendar, CalendarProps } from 'react-native-calendars'

import Colors from '@/constants/Colors'
import { addException } from './DistributionDetailScreen/DistributionDetailScreen.helpers'

type Props = {
  distro?: Distribution
  setFieldValue: (field: string, newValue: Exception[]) => void
  exceptions: Exception[]
  calendarProps?: CalendarProps
}

const MARK_DATE = { marked: true, dotColor: Colors.green, title: 'Pickup' }
const SOURCE_DATE = { selected: true, selectedColor: Colors.red }
const SKIP_DATE = { selected: true, selectedColor: Colors.blue }
const TARGET_DATE = { selected: true, selectedColor: Colors.green }

const dateToString = (date: DateTime) => date.toISODate()

export default function DistributionCalendar({ distro, setFieldValue, exceptions, calendarProps }: Props) {
  const [startDate, setStartDate] = useState<string>()

  // Build a list of all pickups and set marks for the calendar
  const pickupDates = distro ? getDistributionPickups(distro) : []
  const pickups = pickupDates.map(dateToString)
  const markedDates: { [key: string]: typeof MARK_DATE } = {}
  pickups.forEach((date) => {
    markedDates[date] = MARK_DATE
  })

  const stringToDate = (date: string) =>
    DateTime.fromISO(date).setZone(distro!.location.timezone, { keepLocalTime: true })

  const exceptionsMap: { [key: string]: typeof SOURCE_DATE } = {}
  exceptions.forEach((e) => {
    // For each exception, add an iso date as key to the exceptions map, with the proper calendar style for the type of exception
    if (isSkipException(e)) {
      exceptionsMap[e.sourceDate.toISODate()] = SKIP_DATE
    }
    if (isRescheduleException(e)) {
      exceptionsMap[e.sourceDate.toISODate()] = SOURCE_DATE
      exceptionsMap[e.targetDate.toISODate()] = TARGET_DATE
    }
    if (isPatternException(e)) {
      // If it's a pattern exception, set all affected pickups as skipped
      pickupDates
        // mapPickupExceptions undefines exception dates, so we need to do the reverse: keep exception dates and undefine regular pickups
        .map((d) => (mapPickupExceptions([e])(d) ? undefined : d))
        .filter(isNonNullish)
        .forEach((d) => (exceptionsMap[d.toISODate()] = SKIP_DATE))
    }
  })

  return (
    <View style={styles.calendarContainer}>
      <View style={styles.infoTextCont}>
        <Text>
          {distro?.schedule.frequency === Frequency.DAILY
            ? 'Click on a day to skip.\nRescheduling not available on daily frequency'
            : !startDate
            ? 'Click on a day to reschedule or skip'
            : 'Select another date to reschedule this pickup to. Or click again on this day to mark it as a skipped day'}
        </Text>
      </View>
      <Calendar
        style={styles.calendar}
        theme={{
          weekVerticalMargin: 15,
          textMonthFontFamily: typography.title.bold,
          textMonthFontSize: 22,
        }}
        headerStyle={styles.calendarHeader}
        enableSwipeMonths
        current={pickups?.[0] || DateTime.now().toISODate()}
        onDayPress={({ dateString }) => {
          if (!distro)
            return Alert(
              'Schedule Incomplete',
              'You must fill out all fields under Schedule Details before you can schedule exceptions.',
            )
          if (startDate) {
            // You can not make changes in the past
            if (dateString < DateTime.now().toISODate()) {
              setStartDate(undefined)
              return Alert('Invalid Date', 'You cannot reschedule from a day in the past')
            }
            // If we have already selected a start date then we are now choosing target
            if (startDate === dateString) {
              // If we chose the same day then we want a skip-date
              addException(pickupDates, setFieldValue, exceptions, { sourceDate: stringToDate(startDate) })
            } else {
              // Pickups cannot be rescheduled to a date before the distribution starts
              if (dateString < pickups[0]) {
                setStartDate(undefined)
                return Alert('Invalid Date', 'You cannot reschedule to a date before the distribution starts.')
              }

              // Don't allow rescheduling to a current pickup
              if (pickups.includes(dateString)) {
                setStartDate(undefined)
                return Alert('Invalid Date', 'You cannot reschedule to an active pickup date')
              }
              // Don't allow rescheduling more than one, pickups to the same target date
              if (exceptions.find((ex) => ex.targetDate && dateToString(ex.targetDate) === dateString)) {
                setStartDate(undefined)
                return Alert('Invalid Date', 'You cannot add more than one exception to a day')
              }
              // We have chosen a different day, so it should be set as the target date
              addException(pickupDates, setFieldValue, exceptions, {
                sourceDate: stringToDate(startDate),
                targetDate: stringToDate(dateString),
              })
            }
            setStartDate(undefined)
          } else {
            // You can not reschedule a day in the past
            if (dateString < DateTime.now().toISODate()) {
              return Alert('Invalid Date', 'You can not reschedule to a day in the past')
            }

            // Each pickup can only be rescheduled to one date
            if (exceptions.find((ex) => (ex.sourceDate ? dateToString(ex.sourceDate) === dateString : false))) {
              return Alert('Invalid Date', 'You cannot add more than one exception to a day')
            }

            // We are selecting the startDate, so we need to check that it is a valid pickup day
            if (!pickups.includes(dateString)) {
              return Alert('Invalid Date', 'You must select one of your current pickup dates.')
            }

            // On daily frequency, skip date on first press
            if (distro.schedule.frequency === Frequency.DAILY) {
              addException(pickupDates, setFieldValue, exceptions, { sourceDate: stringToDate(dateString) })
            }
            // For other frequencies, do rescheduling or skipping in second step
            else setStartDate(dateString)
          }
        }}
        markedDates={{ ...markedDates, ...exceptionsMap, ...(startDate ? { [startDate]: SOURCE_DATE } : {}) }}
        renderArrow={(direction) => {
          if (direction === 'left') {
            return <Icon name="arrow-left" />
          } else {
            return <Icon name="arrow-right" />
          }
        }}
        {...calendarProps}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  calendarContainer: {
    maxWidth: 550,
    width: '100%',
  },
  calendarHeader: {
    backgroundColor: Colors.lightGreen,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
  calendar: {
    margin: 3,
    borderWidth: 1,
    borderColor: Colors.shades[100],
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    paddingLeft: 0,
    paddingRight: 0,
  },
  infoTextCont: {
    height: 40,
    justifyContent: 'center',
    paddingLeft: 10,
  },
})
