import { stateHelpersUS } from '@/assets/data/states'
import { stateHelpersCA } from '@/assets/data/statesCanada'
import { entries, keys } from '@helpers/typescript'
import { CurrencyCode } from '@models/Money'

/** 2-letter country code from the ISO 3166-1 alpha-2 specification https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2.
 * - We are using upper case for ISO codes because they can be displayed as-is in the UI. That pattern is the same as other ISO codes in our models, like the codes for US states and Canadian provinces.
 * - When more countries are added their 2-letter code should be taken from the same ISO specification.
 */
export type CountryCode = 'US' | 'CA'

/** 2-letter language code from ISO 639 https://en.wikipedia.org/wiki/List_of_ISO_639_language_codes
 * - When more languages are added their 2-letter code should be taken from the same ISO specification.
 */
export type LanguageCode = 'EN' | 'FR' | 'ES'

export type CountryData = {
  name: string
  code: CountryCode
  currency: CurrencyCode
  language: LanguageCode
  states: StateHelpers
}

export const countryCodeMap: Record<CountryCode, CountryData> = {
  CA: { name: 'Canada', code: 'CA', currency: 'cad', language: 'EN', states: stateHelpersCA },
  US: { name: 'United States', code: 'US', currency: 'usd', language: 'EN', states: stateHelpersUS },
}

/** The full list of supported country codes */
export const countryCodes: readonly CountryCode[] = keys(countryCodeMap)

/** Lists the countries as an array of items for picker inputs */
export const countryItemsList: { label: string; value: CountryCode }[] = entries(countryCodeMap).map(
  ([code, { name }]) => ({ label: name, value: code }),
)

/** Returns the international country data for a given country
 * - If the string is not a valid country or country code, it will return null
 */
export function findCountryData(country: string): CountryData | null {
  return (
    entries(countryCodeMap).find(([code, { name }]) => {
      const processed = country.trim().toLowerCase()
      return code.toLowerCase() === processed || name.toLowerCase() === processed
    })?.[1] ?? null
  )
}

export type StateHelpers<
  StatesList extends readonly { name: string; abbreviation: string }[] = readonly {
    name: string
    abbreviation: string
  }[],
> = {
  /** Will try to get the state abbreviation from either the full name or abbreviation  **/
  getShortState(name: string): StatesList[number]['abbreviation'] | ''

  /** Takes in the state in any format and returns an object with the abbreviation and full name */
  getState(name: string): StatesList[number] | undefined

  /** Lists the abbreviated state names */
  listStateAbbr(): StatesList extends readonly { abbreviation: infer T }[] ? T[] : never

  /** Lists the state names as labels and abbreviations as values */
  stateItemList(): {
    label: string
    value: string
  }[]
}
