import { InvoicePaymentMethod } from '@models/Invoice'
import { InfinitePaymentMethodDefinition, PaymentMethodsDefinitionInterface } from './PaymentDefinition'
import { PaymentSources } from '@models/PaymentMethod'

/** Offline is an infinite payment source that indicates a payment was made outside GrownBy */
export class OfflinePaymentDefinition
  extends InfinitePaymentMethodDefinition<PaymentSources.OFFLINE>
  implements PaymentMethodsDefinitionInterface<PaymentSources.OFFLINE>
{
  constructor() {
    super(PaymentSources.OFFLINE, 'Offline')
  }

  formatInvPayMethod(_: InvoicePaymentMethod<PaymentSources.OFFLINE>) {
    return this.formatShort()
  }
}
