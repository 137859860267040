import { Farm } from '@models/Farm'
import { useNavigationState } from '@react-navigation/native'
import { useCallback, useMemo } from 'react'

/** Hook that detects the latest accessed shop route */
export function useLastAccessedShopFarm() {
  const routes = useNavigationState((state) => state.routes)

  const lastAccessedFarmId = useMemo(() => {
    const prevRoute = routes.length > 1 ? routes[routes.length - 2] : undefined
    if (!prevRoute) return undefined

    if (!['CSADetails', 'FarmShop'].includes(prevRoute.name)) return undefined

    if (!prevRoute.params || !('farmSlug' in prevRoute.params)) return undefined

    return prevRoute.params.farmSlug as string
  }, [routes])

  /** Whether the provided farm is last accessed */
  const isFarmAccessed = useCallback(
    (farm: Pick<Farm, 'id' | 'urlSafeSlug'>) => {
      if (!lastAccessedFarmId) return false

      return [farm.id, farm.urlSafeSlug].includes(lastAccessedFarmId)
    },
    [lastAccessedFarmId],
  )

  return {
    isFarmAccessed,
  }
}
