import { CustomShare } from '@models/CustomShare'
import React from 'react'
import { AlgoliaAdminProduct } from '@models/Algolia'
import { ProductType } from '@models/Product'
import { Alert, Divider, Text, UniversalTag } from '@elements'
import { ArrElement, pick } from '@helpers/typescript'
import { AdminCard } from '../../../components/AdminCard'
import { ProductAutoComplete } from '../../../components/ProductAutoComplete'
import { StyleSheet, View } from 'react-native'
import Colors from '../../../../constants/Colors'

const getScoreIcon = (score: number) => {
  switch (score) {
    case 1:
      return 'thumbs-down'
    case 2:
      return 'thumbs-down'
    case 4:
      return 'thumbs-up'
    case 5:
      return 'star'
    default:
      return 'minus'
  }
}

type RankedProduct = ArrElement<CustomShare['rankedProducts']>

type Props = {
  share: Pick<CustomShare, 'rankedProducts' | 'csa'>
  onUpdateRankedProduct: (rankedProd: RankedProduct, type: 'add' | 'remove') => void
}

/** Card to show the products that have a custom ranking in the share */
export function ProductRankingCard({ share: { rankedProducts, csa }, onUpdateRankedProduct }: Props) {
  const onProdSelect = (item: AlgoliaAdminProduct, score: number) => {
    if (item.type !== ProductType.Standard || !item.csas.some(({ id }) => id === csa.id)) {
      return Alert('Cannot add product', "You can only add standard products that are in the custom share's CSA group.")
    }
    if (rankedProducts.find((prod) => prod.product.id === item.id)) {
      return Alert(
        'Cannot add product',
        'This product is already in your rankings, please remove it first to re-add to this group.',
      )
    }

    const newProdRanking = {
      product: pick(item, 'name', 'id'),
      score,
    }
    onUpdateRankedProduct(newProdRanking, 'add')
  }

  const onRemoveSuggested = (item: RankedProduct) => {
    onUpdateRankedProduct(item, 'remove')
  }

  return (
    <AdminCard>
      <Text size={16} type="bold">
        Featured Products
      </Text>
      <ProductAutoComplete inline onSelect={(item) => onProdSelect(item, 4)} />
      {rankedProducts.some((itm) => itm.score > 3) ? (
        <View style={styles.rankedProductsContainer}>
          {rankedProducts
            .filter((itm) => itm.score > 3)
            .map((item) => (
              <UniversalTag
                key={item.product.id}
                label={item.product.name}
                icon={getScoreIcon(item.score)}
                onPress={() => onRemoveSuggested(item)}
              />
            ))}
        </View>
      ) : (
        <Text color={Colors.shades[400]}>
          Add featured products here. These will be up voted and prioritized in shares.
        </Text>
      )}
      <Divider clear large />
      <Text size={16} type="bold">
        Less-Preferred Products
      </Text>
      <ProductAutoComplete inline onSelect={(item) => onProdSelect(item, 2)} />
      {rankedProducts.some((itm) => itm.score < 3) ? (
        <View style={styles.rankedProductsContainer}>
          {rankedProducts
            .filter((itm) => itm.score < 3)
            .map((item) => (
              <UniversalTag
                key={item.product.id}
                label={item.product.name}
                icon={getScoreIcon(item.score)}
                onPress={() => onRemoveSuggested(item)}
              />
            ))}
        </View>
      ) : (
        <Text color={Colors.shades[400]}>
          Add products here to down vote them for your shares. This will only add them to the share if there are no
          other options.
        </Text>
      )}
    </AdminCard>
  )
}

const styles = StyleSheet.create({
  rankedProductsContainer: {
    marginVertical: 6,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
})
