import { StyleProp, TextStyle } from 'react-native'
import { Input, InputProps } from 'react-native-elements'
import Colors from '../../../constants/Colors'
import { globalStyles } from '../../../constants/Styles'
import { FormInputLabel } from './FormInputLabel'
import { isLabelAccessible } from './helpers/helpers'
import { formStyles, useDynamicFormStyles } from './helpers/styles'
import { FormSharedProps } from './helpers/types'

export type FormInputProps = FormSharedProps &
  InputProps & {
    /** This text will be shown whenever there's no error message */
    helperText?: string
    /** Will be applied to the helper text */
    helperTextStyle?: StyleProp<TextStyle>
  }

/** This input component can be used to produce consistently styled inputs*/
export function FormInput({
  label,
  labelStyle,
  inputStyle,
  inputContainerStyle,
  containerStyle,
  errorStyle,
  style,
  errorMessage,
  helperText,
  helperTextStyle,
  ...props
}: FormInputProps) {
  const styles = useDynamicFormStyles(!!errorMessage, !!props.row)

  const shouldDisplayHelper = !errorMessage?.length && helperText?.length

  return (
    <Input
      placeholderTextColor={Colors.shades['200']}
      label={label === null ? undefined : <FormInputLabel labelStyle={labelStyle} label={label ?? props.placeholder} />}
      accessibilityLabel={isLabelAccessible(label) ? label : props.placeholder}
      labelStyle={[formStyles.inputLabel, labelStyle]}
      inputStyle={[styles.inputStyle, inputStyle]}
      inputContainerStyle={[styles.inputContainer, inputContainerStyle]}
      containerStyle={[props.row && globalStyles.flex1, containerStyle]}
      style={[styles.main, style]}
      errorMessage={shouldDisplayHelper ? helperText : errorMessage}
      errorStyle={shouldDisplayHelper ? [formStyles.helper, helperTextStyle] : [formStyles.error, errorStyle]}
      {...props}
    />
  )
}
