import { Button, Icon, Text } from '@elements'
import { formatInvoiceNum, formatMoney, getOrderNum } from '@helpers/display'
import { format } from '@helpers/time'
import { Invoice, InvoiceStatus, invoiceSubtotal } from '@models/Invoice'
import React from 'react'
import { View } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import { globalStyles } from '../../../constants/Styles'
import { SplitInvoiceSummary } from './SplitInvoiceSummary'

import Colors from '@/constants/Colors'
import { chainPaymentsMethod } from '@helpers/paymentMethodDisplay'

type PaidInvoiceProps = {
  invoice: Invoice
  invStatus: InvoiceStatus
  showInvoiceDetails?: boolean
  onDownloadPress?: () => void
  isFarmManager?: boolean
}

/** PaidInvoice components will be used in customer side and admin side, so it should be careful the usage of hooks and redux selector. */
export function PaidInvoice({
  invoice,
  invStatus,
  showInvoiceDetails = false,
  onDownloadPress,
  isFarmManager = false,
}: PaidInvoiceProps) {
  const styles = useStyles()

  function InvoiceIcon() {
    if (invStatus === InvoiceStatus.Refunded) {
      return <Icon size={18} solid name="history" color={Colors.red} />
    }
    if (invStatus === InvoiceStatus.Void) {
      return <Icon size={20} solid name="times-circle" color={Colors.blue} />
    }

    return <Icon size={20} solid name="check-circle" />
  }

  return (
    <>
      <View style={styles.borderedCont}>
        <View style={styles.subtitle}>
          <Text size={12}>Invoice {formatInvoiceNum(invoice.invoiceNum)}</Text>
          <Text size={12}>{invoice.order.orderNum ? `Order ${getOrderNum(invoice.order.orderNum)}` : ''}</Text>
        </View>

        <Text type="medium" size={32}>
          {formatMoney(invoice.status === InvoiceStatus.Void ? invoiceSubtotal(invoice) : invoice.amountTotal)}
        </Text>

        <View style={globalStyles.alignCenter}>
          <View style={globalStyles.flexRowCenter}>
            <InvoiceIcon />
            {invoice.datePaid ? (
              <Text size={14} style={styles.text}>
                {invStatus} on {format(invoice.datePaid, 'MMM. dd, yyyy')}
              </Text>
            ) : (
              <Text size={14} style={styles.text}>
                Invoice {invStatus.toLowerCase()}
              </Text>
            )}
          </View>
          {chainPaymentsMethod(invoice, true).map((txt) => (
            <Text key={txt} size={14} style={[styles.text, { marginLeft: 0 }]}>
              {txt}
            </Text>
          ))}
          {/* This is the note that only allow farmer to see */}
          {!!invoice.note && isFarmManager && (
            <Text key={invoice.note} size={14} style={[styles.text, { marginLeft: 0 }]}>
              {`Notes: ${invoice.note}`}
            </Text>
          )}
          <Button style={globalStyles.margin20} title="Download Receipt" onPress={onDownloadPress} />
        </View>
      </View>
      {showInvoiceDetails && invoice && <SplitInvoiceSummary invoice={invoice} />}
    </>
  )
}
const useStyles = CreateResponsiveStyle(
  {
    borderedCont: {
      borderWidth: 1,
      borderColor: Colors.shades[100],
      borderRadius: 10,
      marginVertical: 10,
      alignItems: 'center',
      overflow: 'hidden',
    },
    text: {
      marginVertical: 5,
      marginLeft: 10,
    },
    subtitle: {
      backgroundColor: '#F2F4F2',
      padding: 15,
      alignSelf: 'stretch',
      marginBottom: 10,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  {
    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      borderedCont: {
        padding: 5,
      },
    },
  },
)
