import { Farm } from '@models/Farm'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { marshalAddress, unmarshalAddress } from './Address'
import { marshalDate, unmarshalDate } from './Time'
import { prepareMarshal, prepareUnmarshal } from './encoding'

/** marshalFarm returns a Firebase data structure for the supplied farm. */
export function marshalFarm(farm: Partial<Farm>): DocumentData {
  const data = prepareMarshal(farm) as DocumentData

  if (farm.name) {
    data.nameUppercase = farm.name.toUpperCase()
  }
  if (farm.joinDate) {
    data.joinDate = marshalDate(farm.joinDate)
  }
  if (farm.acceptedTerms) {
    data.acceptedTerms = marshalDate(farm.acceptedTerms)
  }

  if (!farm.managers) delete farm.managers

  if (farm.address) {
    data.address = marshalAddress(farm.address)
  }

  return data
}

/** unmarshalFarm returns a farm constructed from the Firestore data. */
export function unmarshalFarm(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): Farm {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)
  const farm = { ...data, id } as Farm

  if (data.joinDate) {
    farm.joinDate = unmarshalDate(data.joinDate)
  }
  if (data.acceptedTerms) {
    farm.acceptedTerms = unmarshalDate(data.acceptedTerms)
  }
  if (data.address) {
    farm.address = unmarshalAddress(data.address)
  }

  return farm
}
