import { ButtonClear, Divider, Hoverable, Icon, MenuOverlay, Modal, Text, Touchable } from '@elements'
import { Feather } from '@expo/vector-icons'
import { UserRole, hasGrownByPrivilege } from '@models/User'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useCallback, useRef, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import Colors from '../constants/Colors'
import { WithAuthCallback } from '../hooks/WithAuthCallback'
import { AppStackParamList } from '../navigation/types'
import {
  internationalSelector,
  isAdminSelector,
  unreadMessagesNoSelector,
  userSelector,
  wholesaleSelector,
} from '../redux/selectors'
import { Image, ResizedSuffix } from './Image'
import { AccountHelp } from './modals/AccountHelp'

import { useDeviceSize } from '@/hooks/useLayout'
import { setWholesale } from '@/redux/actions/appPersist'
import { signOut } from '@/redux/actions/signout'
import { RootState } from '@/redux/reducers/types'

import { globalStyles } from '@/constants/Styles'
import { isWholesaleBuyer } from '@helpers/wholesale'
import { COOP_WEB_ONBOARD_URL, getBaseUrl } from '@shared/BaseUrl'
import { releaseChannel } from '../config/Environment'
import { isWeb } from '../constants/Layout'

const overlayWidth = 214 //This should be a good guess of the initial width of the overlay container

const accountHelpModal = () => Modal(<AccountHelp />, { title: 'Account Help' })

/** A dropdown menu showing navigation options for the user */
export function UserDropdown(props: { admin?: boolean }) {
  const { isWholesale } = useSelector(wholesaleSelector)
  const unreadMessagesCount = useSelector(unreadMessagesNoSelector)
  const dispatch = useDispatch()
  const { isSmallDevice } = useDeviceSize()
  const user = useSelector(userSelector)
  const isAdmin = useSelector<RootState, boolean | undefined>(isAdminSelector)
  const [showLogin, setShowLogin] = useState(false)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const btnRef = useRef<View>(null)
  const { country } = useSelector(internationalSelector)

  const [showMenu, setShowMenu] = useState(false)

  function handleSignout() {
    setShowMenu(false)
    signOut()
  }

  const profilePressed = () => {
    /** Measure needs to run on btn press because the user button first renders further right, and then the shopping cart component becomes wider after the cart items are fetched, which causes this button to be pushed to the left,and the measure state remains at the position further right when it initially rendered. onLayout doesn't re-run when it gets shifted left so that doesn't update the measure */
    if (!isSmallDevice) {
      return setShowMenu((a) => !a)
    }
    if (user.id) {
      /** In the AdminDrawerNavigator Tree, there is no Screen User, so we need to navigate to Root tree to access Screen User/Profile  */
      navigation.navigate('Consumer', { screen: 'User', params: { screen: 'Profile' } })
    } else {
      setShowLogin(true)
      setShowMenu(false)
    }
  }

  const toggleWholesaleRetail = useCallback(async () => {
    if (__DEV__ || !isWeb) {
      // If we are in development mode, or we are on mobile we should change the redux state to use wholesale mode
      dispatch(setWholesale({ isWholesale: !isWholesale }))

      // If switching the environment in the current app then we should sign the user out if they are an institution

      // If they are not an institution then the switch will work as expected and the account can be used in both retail or wholesale.
      // (NOTE: In wholesale, they won't be able to access if they are not a wholesale user, but it will behave as expected. )
      if (user.institution?.isInstitution) {
        await signOut()
      }
    } else {
      const currentBaseURL = getBaseUrl(releaseChannel, { isWholesale })
      const newBaseURL = getBaseUrl(releaseChannel, { isWholesale: !isWholesale })
      // If it is live web app we should navigate the user to the current URL they are on in the opposite environment
      const newURL = window.location.href.replace(currentBaseURL, newBaseURL)
      window.open(newURL, '_self')
    }
  }, [isWholesale, user.institution?.isInstitution, dispatch])

  return (
    <View ref={btnRef} style={styles.marginHorizontal20} testID="user_dropdown_view" collapsable={false}>
      {showLogin && (
        <WithAuthCallback
          onDismiss={() => {
            setShowLogin(false)
          }}
          callback={() => {
            setShowLogin(false)
          }}
        />
      )}
      <Hoverable>
        {(isHovered) => (
          <Touchable style={[styles.headerIcon, isHovered && styles.onHover]} onPress={profilePressed}>
            <View>
              {user.avatar ? (
                <Image
                  type="profile"
                  style={styles.avatar}
                  source={{ uri: user.avatar }}
                  resizeSuffix={ResizedSuffix.NONE}
                />
              ) : (
                <Icon name="user-circle" solid size={25} style={styles.margin5} />
              )}
              {unreadMessagesCount > 0 && <View style={styles.drodownUnreadCont} />}
            </View>
            {!isSmallDevice && (
              <Feather
                name={showMenu ? 'chevron-up' : 'chevron-down'}
                size={20}
                color={Colors.green}
                style={styles.paddingHorizontal5}
              />
            )}
          </Touchable>
        )}
      </Hoverable>
      <MenuOverlay
        value="user-dropdown"
        isVisible={showMenu}
        sourceRef={btnRef}
        onBackdropPressed={() => setShowMenu(false)}
        otherProps={{ contentContStyle: { padding: 10, borderRadius: 10 } }}
      >
        {props.admin ? (
          <ButtonClear
            title="GrownBy Marketplace"
            url="/"
            onPress={() => {
              setShowMenu(false)
            }}
          />
        ) : (
          <>
            <ButtonClear title="Orders" icon="box" url="/orders" onPress={() => setShowMenu(false)} />
            <ButtonClear title="Notifications" icon="bell" url="/notifications" onPress={() => setShowMenu(false)} />
            <ButtonClear title="Favorite Farms" icon="heart" url="/farms" onPress={() => setShowMenu(false)} />
            <Divider />
            {isAdmin === true && !isWholesaleBuyer(user) && (
              <ButtonClear
                title="Farmer Dashboard"
                url="/admin"
                onPress={() => {
                  setShowMenu(false)
                }}
              />
            )}
            {isAdmin === false && !isWholesaleBuyer(user) && (
              <ButtonClear title="Start Selling" url={COOP_WEB_ONBOARD_URL} onPress={() => setShowMenu(false)} />
            )}
            {hasGrownByPrivilege(user, UserRole.Admin) && (
              <ButtonClear
                icon="user-shield"
                color={Colors.gold}
                title="Analytics Dashboard"
                url="/farm-generations/analytics"
                onPress={() => setShowMenu(false)}
              />
            )}
          </>
        )}
        <ButtonClear
          title="Account"
          url="/profile"
          onPress={() => {
            setShowMenu(false)
          }}
        />
        <ButtonClear
          title="Help"
          onPress={() => {
            setShowMenu(false)
            accountHelpModal()
          }}
        />
        {user.id ? (
          <>
            <View style={globalStyles.flexRowCenter}>
              <ButtonClear
                style={styles.messagesBtn}
                title="Messages"
                onPress={() => setShowMenu(false)}
                url="/profile/messages"
              />
              {unreadMessagesCount > 0 && <Badge value={unreadMessagesCount} />}
            </View>

            <ButtonClear title="Sign out" onPress={handleSignout} />
          </>
        ) : (
          <ButtonClear
            title="Sign in"
            onPress={() => {
              setShowLogin(true)
              setShowMenu(false)
            }}
            testID="sign_in_button"
          />
        )}
        {/* We should always show switch to retail, but only show switch to wholesale for GrownBy Admin or in Localhost */}
        {isWholesale ? (
          <ButtonClear title="Switch to retail" onPress={toggleWholesaleRetail} />
        ) : hasGrownByPrivilege(user, UserRole.Admin) || __DEV__ ? (
          <ButtonClear
            icon="user-shield"
            color={Colors.gold}
            title="Switch to wholesale"
            onPress={toggleWholesaleRetail}
          />
        ) : null}
      </MenuOverlay>
    </View>
  )
}

const styles = StyleSheet.create({
  headerIcon: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 20,
    borderWidth: 2,
    borderColor: Colors.shades['100'],
    backgroundColor: Colors.white,
  },
  avatar: {
    width: 40,
    height: 40,
    borderWidth: 2,
    borderRadius: 20,
    borderColor: Colors.transparent,
  },
  onHover: {
    borderColor: Colors.green,
    borderWidth: 2,
  },
  link: {
    color: Colors.black,
    padding: 10,
    backgroundColor: Colors.transparent,
  },
  activeLink: {
    color: Colors.white,
    padding: 10,
    backgroundColor: Colors.green,
    overflow: 'visible',
  },
  overlay: {
    width: overlayWidth,
    borderWidth: 1,
    borderColor: Colors.shades['100'],
    backgroundColor: Colors.white,
    padding: 0, //it adds 10 padding automatically, but we need the total size to be calculated from the inner View
    borderRadius: 10, //it uses radius 4 by default
  },
  margin5: {
    margin: 5,
  },
  marginHorizontal20: {
    marginHorizontal: 20,
  },
  paddingHorizontal5: {
    paddingHorizontal: 5,
  },

  badgeCont: {
    minWidth: 20,
    minHeight: 20,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.red,
    paddingHorizontal: 3,
  },
  messagesBtn: {
    paddingRight: 4,
  },
  drodownUnreadCont: {
    width: 12,
    height: 12,
    borderRadius: 20,
    backgroundColor: Colors.red,
    right: 0,
    top: 0,
    position: 'absolute',
  },
})

/** Badge UI that shows unread messages no */
function Badge({ value }: { value: number }) {
  return (
    <View style={styles.badgeCont}>
      <Text size={10} type="medium" color={Colors.white}>
        {value > 10 ? `10+` : value}
      </Text>
    </View>
  )
}
