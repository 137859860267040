import { Logger } from '@/config/logger'
import { changeUser } from '@api/Users'
import { ConsumerScroll, PermissionDenied } from '@components'
import { Alert, FormButton, FormInput, Toast } from '@elements'
import { deepClone } from '@helpers/helpers'
import { User } from '@models/User'
import { Formik, FormikProps } from 'formik'
import { useCallback, useMemo, useState } from 'react'
import { KeyboardAvoidingView, Platform, StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { globalStyles } from '@/constants/Styles'
import { withAuth } from '@/hooks/withAuth'
import { setUser } from '@/redux/actions/user'
import { userSelector } from '@/redux/selectors'
import { isErrorWithCode } from '@shared/Errors'

type FormType = {
  businessName?: string
}

const registerValidationSchema = Yup.object<FormType>().shape({
  businessName: Yup.string().label('Business name'),
})

/** This is the page for Personal Information */
export function BusinessInformationScreenComp() {
  const user = useSelector(userSelector)

  const [isSaving, setIsSaving] = useState(false)
  const dispatch = useDispatch()

  const onSubmit = useCallback(
    async (data: FormType) => {
      setIsSaving(true)
      const newUser: User = deepClone(user)

      if (!newUser.institution) {
        Alert('Warning', 'User does not have wholesale access')
        return
      }

      newUser.institution.businessName = data.businessName?.trim()

      try {
        await changeUser(user.id, user, newUser)
        dispatch(setUser({ ...newUser }))
        Toast('Data saved successfully.')
      } catch (err) {
        Logger.debug(err)
        if (isErrorWithCode(err)) {
          Toast(err.uiMsg)
          return
        }
        Toast(`Something wen wrong. Please try again`)
      } finally {
        setIsSaving(false)
      }
    },
    [dispatch, user],
  )

  const initialValues: FormType = useMemo(() => {
    return {
      businessName: user.institution?.businessName,
    }
  }, [user])

  if (!user.institution?.isInstitution) {
    return <PermissionDenied />
  }

  return (
    <KeyboardAvoidingView
      style={globalStyles.flex1}
      keyboardVerticalOffset={100}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    >
      <ConsumerScroll>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={registerValidationSchema}>
          {({ handleChange, values, errors, touched, handleBlur, handleSubmit }: FormikProps<FormType>) => (
            <View style={globalStyles.margin10}>
              <FormInput
                numberOfLines={1}
                value={values.businessName}
                placeholder="Business Name"
                label="Business Name"
                onChangeText={handleChange('businessName')}
                onBlur={handleBlur('businessName')}
                errorMessage={touched.businessName ? errors.businessName : ''}
              />
              <View style={globalStyles.margin20} />
              <FormButton loading={isSaving} title="Save" onPress={handleSubmit} style={styles.buttonStyle} />
            </View>
          )}
        </Formik>
      </ConsumerScroll>
    </KeyboardAvoidingView>
  )
}
export const BusinessInformationScreen = withAuth(BusinessInformationScreenComp, {
  noSafeAreaInsets: true,
})

const styles = StyleSheet.create({
  main: {
    flex: 1,
    borderWidth: 1,
    justifyContent: 'space-between',
  },
  buttonStyle: {
    minWidth: 200,
  },
})
