import { Coordinate } from '@models/Coordinate'
import * as yup from 'yup'

export const coordinateSchema: yup.ObjectSchema<Coordinate> = yup
  .object({
    latitude: yup
      .number()
      .required()
      .test('Not zero', 'The latitude should not be zero', (val) => val !== 0),
    longitude: yup
      .number()
      .required()
      .test('Not zero', 'The longitude should not be zero', (val) => val !== 0),
  })
  .defined()
