import Colors, { withAlpha } from '@/constants/Colors'
import { setInternational } from '@/redux/actions/appPersist'
import { internationalSelector } from '@/redux/selectors'
import { Hoverable, Icon, Loader, MenuOverlay, Text } from '@elements'
import { wait } from '@helpers/helpers'
import { CountryCode, countryCodeMap, countryItemsList } from '@helpers/international/types'
import { DateTime } from 'luxon'
import React, { useRef, useState } from 'react'
import { ImageSourcePropType, Pressable, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { Image } from '../Image'

const flagsMap: Record<CountryCode, ImageSourcePropType> = {
  CA: require('../../assets/images/icons/ca-flag.png'),
  US: require('../../assets/images/icons/us-flag.png'),
}

type CountryDropdownProps = {
  contStyle?: StyleProp<ViewStyle>
  textColor?: string
}

/** Dropdown component that allows selecting a specific country */
export function CountryDropdown({ contStyle, textColor }: CountryDropdownProps) {
  const [isMenuVisible, setIsMenuVisible] = useState(false)

  const { country } = useSelector(internationalSelector)
  const dispatch = useDispatch()

  const btnRef = useRef(null)

  const onCountryPress = (value: CountryCode) => {
    const data = countryCodeMap[value]
    Loader(true)

    dispatch(
      setInternational({
        country: data.code,
        currency: data.currency,
        language: data.language,
        timestamp: DateTime.now().toMillis(),
      }),
    )

    // Artificial loader is added so the user has a UI feedback
    wait(1000).then(() => Loader(false))

    setIsMenuVisible(false)
  }

  return (
    <>
      <Hoverable>
        {(isHovered) => (
          <Pressable
            ref={btnRef}
            onPress={() => setIsMenuVisible((c) => !c)}
            style={[styles.main, contStyle, isHovered && styles.hovered]}
          >
            <View style={styles.flagCont}>
              <Image style={styles.flag} source={flagsMap[country]} />
            </View>
            <Text size={14} color={textColor ?? Colors.white}>
              {country}
            </Text>
          </Pressable>
        )}
      </Hoverable>
      <MenuOverlay
        isVisible={isMenuVisible}
        value="country-dropdown"
        onBackdropPressed={() => setIsMenuVisible(false)}
        sourceRef={btnRef}
      >
        {countryItemsList.map(({ value }) => (
          <Option key={value} onPress={onCountryPress} value={value} isSelected={country === value} />
        ))}
      </MenuOverlay>
    </>
  )
}

type OptionProps = {
  isSelected: boolean
  onPress: (value: CountryCode) => void
  value: CountryCode
}

function Option({ isSelected, onPress, value }: OptionProps) {
  return (
    <Hoverable>
      {(isHovered) => (
        <Pressable onPress={() => onPress(value)} style={[styles.optionCont, isHovered && styles.optHovered]}>
          <Text size={14} color={isSelected ? Colors.green : undefined}>
            {countryCodeMap[value].name}
          </Text>
          {isSelected && <Icon iconSet="Feather" name="check" />}
        </Pressable>
      )}
    </Hoverable>
  )
}

const styles = StyleSheet.create({
  main: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    paddingHorizontal: 16,
    borderRadius: 10,
  },
  hovered: {
    backgroundColor: withAlpha(Colors.white, 0.1),
  },
  flagCont: {
    width: 25,
    backgroundColor: Colors.white,
    aspectRatio: 1.4,
    borderRadius: 4,
    borderWidth: 2,
    borderColor: Colors.white,
    overflow: 'hidden',
  },

  flag: {
    width: '100%',
    height: '100%',
  },
  optionCont: {
    width: 200,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    justifyContent: 'space-between',
  },
  optHovered: {
    backgroundColor: Colors.fadedGreen,
  },
})
