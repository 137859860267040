import { Logger } from '@/config/logger'
import { loadUserAddresses, removeUserAddress } from '@api/UserAddress'
import { ConsumerView } from '@components'
import { Alert, ButtonClear, HeaderText, Icon, Loader, Modal, Spinner, Text, Toast, Touchable } from '@elements'
import { FontAwesome5 } from '@expo/vector-icons'
import { errorToString, hasOwnProperty } from '@helpers/helpers'
import { UserAddress } from '@models/UserAddress'
import { RouteProp, useRoute } from '@react-navigation/native'
import React, { useCallback, useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { withAuth } from '@/hooks/withAuth'
import { getState } from '@helpers/address'
import { ColumnSize, ResponsiveList } from '../../components/ResponsiveList'
import { Divider } from '../../components/elements/Divider'
import Colors from '../../constants/Colors'
import { globalStyles } from '../../constants/Styles'
import { useDeviceSize } from '../../hooks/useLayout'
import { UserStackParamList } from '../../navigation/types'
import { userSelector } from '../../redux/selectors'
import AddAddress from './AddAddress'

function AddressScreenComp() {
  const user = useSelector(userSelector)
  const { isLargeDevice } = useDeviceSize()
  const { params } = useRoute<RouteProp<UserStackParamList, 'AddressScreen'>>()
  const [loading, setLoading] = useState(false)
  const [addresses, setAddresses] = useState<UserAddress[]>([])

  const onNewAddress = useCallback((address: UserAddress) => {
    setAddresses((prev) => {
      // Will take the existing addresses and update the new one or add it
      let newAddresses = prev.filter((itm) => itm.id !== address.id)
      newAddresses = newAddresses.map((itm) =>
        address.isDefault && itm.isDefault ? { ...itm, isDefault: false } : itm,
      )
      return [address, ...newAddresses]
    })
  }, [])

  useEffect(() => {
    if (params?.id === 'new') {
      Modal(<AddAddress onAdded={onNewAddress} />, {
        title: 'Add Address',
      })
    }
  }, [params?.id, onNewAddress])

  useEffect(() => {
    if (!user.id) return
    setLoading(true)
    loadUserAddresses(user.id)
      .then((res) => {
        // Will open the modal to edit a certain address if passed
        if (params?.id && params.id !== 'new') {
          const address = res.find((itm) => itm.id === params.id)
          if (address) {
            Modal(<AddAddress onAdded={onNewAddress} address={address} />, {
              title: 'Add Address',
            })
          }
        }

        setAddresses(res)
      })
      .finally(() => setLoading(false))
  }, [user.id, onNewAddress, params?.id])

  const addAddress = (address?: UserAddress) =>
    Modal(<AddAddress onAdded={onNewAddress} address={address} addresses={addresses} />, {
      title: 'Add Address',
    })

  const removeAddress = (address: UserAddress) => {
    if (address.isDefault) {
      return Alert('Cannot remove default address', 'To remove this address you must first set a new default address')
    }

    Alert(
      'Are you sure you want to remove this address?',
      'Removing this address will not affect any past orders or upcoming deliveries',
      [
        {
          text: 'Remove',
          style: 'destructive',
          onPress: () => {
            const newAddresses = addresses.filter((itm) => itm.id !== address.id)
            setAddresses(newAddresses)
            Loader(true)
            removeUserAddress(user.id, address)
              .then(() => {
                Toast(`Your address has been removed`)
              })
              .catch((err) => {
                Loader(false)
                const msg = errorToString(err)
                Logger.error(err)
                Toast(`There was an error removing this address: ${msg}`)
              })
              .finally(() => Loader(false))
          },
        },
        {
          text: 'Cancel',
          style: 'cancel',
          onPress: () => {},
        },
      ],
    )
  }

  return (
    <ConsumerView>
      {!loading ? (
        <ResponsiveList
          data={[{ addAddress: true, id: 'addAddress' }, ...addresses]}
          columnSize={ColumnSize.Large}
          keyExtractor={(item) => item.id}
          renderItem={(data) =>
            hasOwnProperty(data, 'addAddress') ? (
              <Touchable style={[styles.addressContainer, styles.addAddress]} onPress={() => addAddress()}>
                <Icon name="plus" size={30} />
                <Divider clear />
                <HeaderText>Add Address</HeaderText>
              </Touchable>
            ) : (
              <View style={[styles.addressContainer, isLargeDevice ? styles.header : globalStyles.flex1]}>
                <HeaderText size={16}>{data.street1}</HeaderText>
                {data.isDefault ? (
                  <Text color={Colors.green}>
                    <FontAwesome5 name="check" /> Default
                  </Text>
                ) : (
                  //Will be the same height as the default text
                  <Divider clear top={13} />
                )}
                <Divider clear />
                <Text>{data.street2}</Text>
                <Text>{data.city}</Text>
                <Text>{getState(data.state, data.country)?.name || 'Invalid state or province'}</Text>
                <Text>{data.zipcode}</Text>
                <Text style={styles.notes} numberOfLines={1}>
                  {data.notes}
                </Text>
                <View style={styles.footerButtons}>
                  <ButtonClear title="Edit" onPress={() => addAddress(data)} size={14} />
                  <ButtonClear title="Remove" onPress={() => removeAddress(data)} size={14} color={Colors.red} />
                </View>
              </View>
            )
          }
        />
      ) : (
        <Spinner />
      )}
    </ConsumerView>
  )
}
export const AddressScreen = withAuth(AddressScreenComp, { noSafeAreaInsets: true })

const styles = StyleSheet.create({
  addressContainer: {
    borderWidth: 1,
    borderColor: Colors.shades[200],
    borderRadius: 20,
    margin: 10,
    padding: 10,
  },
  addAddress: {
    flex: 1,
    borderStyle: 'dashed',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    width: '31%',
  },
  footerButtons: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-around',
  },
  notes: { marginVertical: 5, color: Colors.shades[300] },
})
