import {
  DateRange,
  DayOfWeek,
  Frequency,
  Hours,
  isPatternException,
  isRescheduleException,
  isSeasonalSchedule,
  isSkipException,
  isYearRoundSchedule,
  PatternException,
  RescheduleException,
  Schedule,
  ScheduleBase,
  SkipException,
} from '@models/Schedule'
import { ErrorTypes, ErrorWithCode } from '@shared/Errors'
import * as yup from 'yup'
import { Builder } from './Builder'
import { isValidationError, validateFromSchema, ValidationError } from './validators/helpers'

const hoursSchema: yup.ObjectSchema<Hours> = yup.object().shape({
  startTime: yup.string().required(),
  endTime: yup.string().required(),
})

const dateRangeSchema: yup.ObjectSchema<DateRange> = yup.object().shape({
  startDate: yup.mixed().isDateTime(),
  endDate: yup.mixed().isDateTime(),
})

const skipExceptionSchema: yup.ObjectSchema<SkipException> = yup.object().shape({
  sourceDate: yup.mixed().isDateTime().required(),
  targetDate: yup.mixed().isUndefined(),
  dayOfWeek: yup.mixed().isUndefined(),
})

const rescheduleExceptionSchema: yup.ObjectSchema<RescheduleException> = yup.object().shape({
  sourceDate: yup.mixed().isDateTime().required(),
  targetDate: yup.mixed().isDateTime().required(),
  dayOfWeek: yup.mixed().isUndefined(),
})

const patternExceptionSchema: yup.ObjectSchema<PatternException> = yup.object().shape({
  sourceDate: yup.mixed().isUndefined(),
  targetDate: yup.mixed().isUndefined(),
  dayOfWeek: yup
    .mixed<DayOfWeek>()
    .oneOf(Object.values(DayOfWeek) as number[])
    .required(),
})

const scheduleBaseSchema: yup.ObjectSchema<ScheduleBase> = yup.object().shape({
  frequency: yup.mixed<Frequency>().oneOf(Object.values(Frequency)).required(),
  hours: hoursSchema.required(),
  week: yup.number().optional(),
  dayOfWeek: yup
    .mixed<DayOfWeek>()
    .oneOf(Object.values(DayOfWeek) as number[])
    .required(),
  exceptions: yup
    .array()
    .of(
      yup.lazy((value) => {
        if (value) {
          if (isSkipException(value)) {
            return skipExceptionSchema
          }
          if (isRescheduleException(value)) {
            return rescheduleExceptionSchema
          }
          if (isPatternException(value)) {
            return patternExceptionSchema
          }
        }
        throw new yup.ValidationError('Invalid exception object')
      }),
    )
    .optional(),
})

const yearroundScheduleSchema: yup.ObjectSchema<Schedule> = scheduleBaseSchema.shape({
  pickupStart: yup.mixed().isDateTime().required(),
})
const seasonalScheduleSchema: yup.ObjectSchema<Schedule> = scheduleBaseSchema.shape({
  season: dateRangeSchema.required(),
})

/**
 * This builder will create a schedule object */
export class ScheduleBuilder extends Builder<Schedule> {
  constructor() {
    super('schedule')
  }

  build(schedule: Partial<Schedule>): Schedule {
    return this.validate(schedule)
  }

  validate(schedule: unknown): Schedule {
    try {
      if (isYearRoundSchedule(schedule as Schedule)) {
        return validateFromSchema(yearroundScheduleSchema, schedule)
      } else if (isSeasonalSchedule(schedule as Schedule)) {
        return validateFromSchema(seasonalScheduleSchema, schedule)
      } else {
        throw new ErrorWithCode({
          type: ErrorTypes.Validation,
          code: 'schedule-invalid-type',
          devMsg: 'Data passed to validate schedule is not a valid type',
        })
      }
    } catch (error) {
      if (isValidationError(error)) {
        throw new ValidationError({ path: 'schedule.' + (error.data?.path ?? ''), msg: error.message })
      }
      throw error
    }
  }
}
