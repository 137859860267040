import Colors from '@/constants/Colors'
import { internationalSelector } from '@/redux/selectors'
import { GooglePlacesSearch } from '@components'
import { DropdownMenu, Icon, Text } from '@elements'
import { useLogistics } from '@screens/SearchScreen/hooks/useLogistics'
import { memo } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import { SearchScreenInput } from './SearchScreenInput'

/** Search screen header content component, that includes Search input & Logistics selection */
export const SearchScreenHeaderComp = memo(function SearchScreenHeaderComp() {
  const {
    ref,
    onSelectEstablishment,
    locTypeButtons,
    locTypeDisplay,
    initialAddressFx,
    onClearAddress,
    setInputValue,
  } = useLogistics()

  const { country } = useSelector(internationalSelector)

  return (
    <View style={styles.main}>
      <SearchScreenInput />

      <DropdownMenu contentViewStyle={styles.dropdownContent} buttons={locTypeButtons}>
        <Text children={locTypeDisplay} />
        <Icon size={16} color={Colors.shades['500']} name="chevron-down" />
      </DropdownMenu>
      <View style={styles.addressInputCont}>
        <GooglePlacesSearch
          ref={ref}
          // This should use "geocode" because here we want to allow searching for zip codes, cities or full addresses
          types="geocode"
          placeholder="Enter your location"
          initialValue={initialAddressFx.data}
          enableReinitialize
          editable={!initialAddressFx.loading}
          autoComplete="postal-address"
          hasClearBtn
          onClearText={onClearAddress}
          style={styles.addressInput}
          onSelectGooglePlace={onSelectEstablishment}
          onChangeText={setInputValue} // Here we are tracking a copy of the input value outside the input component
          country={country}
        />
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  main: {
    maxWidth: 800,
    alignSelf: 'center',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    marginLeft: 70, // Nedeed in order to avoid overfowing the sidebar width (250)
  },
  dropdownContent: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: 8,
    height: 40,
    paddingHorizontal: 10,
    backgroundColor: Colors.white,
    width: 110, // Set in order to avoid the change of the dropdown width when options change
    marginLeft: 30,
  },

  addressInputCont: {
    flex: 1,
    backgroundColor: Colors.white,
    borderRadius: 8,
  },
  addressInput: {
    height: 40,
  },
})
