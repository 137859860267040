import { addInvoiceAdjustment } from '@api/Invoices'
import {
  Alert,
  Divider,
  ErrorText,
  FormButton,
  FormInput,
  FormMoneyInput,
  FormUndefinedInitialValue,
  hideModal,
} from '@elements'
import { Formik, FormikProps } from 'formik'
import { useState } from 'react'
import { Text, View } from 'react-native'
import * as Yup from 'yup'

import { adminCurrencySelector } from '@/redux/selectors'
import { YUP_MONEY_REQUIRED } from '@helpers/Yup'
import { errorToString } from '@helpers/helpers'
import { MoneyWithCurrency } from '@models/Money'
import { isErrorWithCode } from '@shared/Errors'
import { useSelector } from 'react-redux'
import { Logger } from '../../../../../config/logger'
import { globalStyles } from '../../../../../constants/Styles'

type FormType = {
  reason: string
  amount: MoneyWithCurrency
}

const validationSchema = Yup.object<FormType>().shape({
  reason: Yup.string()
    .label('Reason')
    .required()
    .min(5, 'Please add a reason with at least 5 characters')
    .max(100, 'The adjustment reason should be under 100 characters'),
  amount: YUP_MONEY_REQUIRED('Amount', { requireCurrency: true }),
})

type Props = {
  invoiceId: string
  onSuccess?: () => void
}

const initialValues: FormType = {
  reason: '',
  amount: FormUndefinedInitialValue,
}

export function AdjustInvoice({ invoiceId, onSuccess }: Props) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()

  const onSubmitHandler = async (values: FormType) => {
    setLoading(true)
    setError(undefined)
    try {
      await addInvoiceAdjustment(invoiceId, values.amount, values.reason)
      setLoading(false)
      hideModal()
      Alert('Success!', 'Invoice has been adjusted successfully. The changes will show up shortly.', [
        {
          text: 'OK',
          onPress: onSuccess,
          style: 'default',
        },
      ])
    } catch (e) {
      if (isErrorWithCode(e)) {
        setError(errorToString(e))
      } else {
        // Only log error if it is unexpected
        Logger.error(e)
        setError(
          'Could not add adjustment to invoice, please try again later or contact support if this issue persists.',
        )
      }
      setLoading(false)
    }
  }

  const currency = useSelector(adminCurrencySelector)

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmitHandler} validationSchema={validationSchema}>
      {({ touched, errors, values, setFieldValue, handleSubmit }: FormikProps<FormType>) => (
        <View style={globalStyles.margin10}>
          <Text style={globalStyles.margin10}>
            An invoice adjustment can be used to decrease the amount due on an invoice. It is useful if a customer pays
            a portion of the invoice offline, the invoice can be adjusted and they can pay the rest online.
          </Text>
          <Divider clear />
          <FormMoneyInput
            value={values.amount}
            onChangeText={(val) => setFieldValue('amount', val)}
            placeholder="Adjustment Amount"
            errorMessage={touched.amount ? errors.amount?.value : ''}
            currency={currency}
          />
          <FormInput
            multiline
            numberOfLines={2}
            label="Adjustment Reason"
            placeholder="Eg. Customer paid with check on Date or Adjustment for strawberry shortage"
            maxLength={100}
            value={values.reason}
            onChangeText={(val) => setFieldValue('reason', val)}
            errorMessage={touched.reason ? errors.reason : ''}
          />
          <FormButton loading={loading} title="Submit" onPress={handleSubmit} />
          {!!error && <ErrorText>{error}</ErrorText>}
        </View>
      )}
    </Formik>
  )
}
