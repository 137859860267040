import { CountryDropdown, useCreateSearchParams } from '@components'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { memo, useCallback } from 'react'
import { ImageBackground, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { LogoResponsive } from '../../admin/components/LogoResponsive'
import HamburgerMenu from '../../components/HamburgerMenu'
import { ShoppingCart } from '../../components/ShoppingCart'
import { UserDropdown } from '../../components/UserDropdown'
import { ButtonClear } from '../../components/elements/ButtonClear'
import { Divider } from '../../components/elements/Divider'
import { HeaderText } from '../../components/elements/Text'
import WebLink from '../../components/elements/WebLink'
import Colors from '../../constants/Colors'
import { isWeb } from '../../constants/Layout'
import { HomeParamList } from '../../navigation/types'
import { setSearchLocation } from '../../redux/actions/appState'
import { RootState } from '../../redux/reducers/types'
import { isAdminSelector, searchLocationSelector, sessionLocationSelector, userSelector } from '../../redux/selectors'
import { HomeSearchBar } from './HomeSearchBar'

import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { useLayout } from '@/hooks/useLayout'

import { isWholesaleBuyer } from '@helpers/wholesale'
import { COOP_WEB_ONBOARD_URL } from '@shared/BaseUrl'

export const SmallHomeHeader = memo(function SmallHomeHeader() {
  const layout = useLayout()
  const isAdmin = useSelector<RootState, boolean | undefined>(isAdminSelector)
  const dispatch = useDispatch()
  const navigation = useNavigation<StackNavigationProp<HomeParamList>>()
  const searchLoc = useSelector(searchLocationSelector)
  const sessionLoc = useSelector(sessionLocationSelector)
  const user = useSelector(userSelector)

  const styles = useStyles()

  const createSearchParams = useCreateSearchParams()

  const goToMapSearch = useCallback(() => {
    const newLoc = searchLoc || sessionLoc
    if (!newLoc) return

    dispatch(setSearchLocation({ ...newLoc }))

    navigation.navigate('ExploreScreen', createSearchParams({ coords: newLoc.coordinate }))
  }, [createSearchParams, sessionLoc, dispatch, navigation, searchLoc])

  return (
    <View style={styles.wrapper}>
      <ImageBackground source={require('../../assets/images/home/home-header-small.jpg')} style={styles.fill}>
        <View style={styles.overImageLayer}>
          {isWeb ? (
            <View style={styles.header}>
              <HamburgerMenu clear />
              <LogoResponsive white style={styles.logo} />

              <View style={styles.leftContent}>
                <UserDropdown />
                <ShoppingCart color={Colors.white} />
              </View>
            </View>
          ) : (
            <View style={styles.logoCont}>
              <LogoResponsive white type="logo" />
              <View style={styles.shoppingCartCont}>
                <ShoppingCart color={Colors.white} />
              </View>
            </View>
          )}
          <View style={styles.searchWrapper}>
            <HeaderText style={styles.title} size={24}>
              Shop locally from farms
            </HeaderText>

            <Divider clear />

            <HomeSearchBar />
            {!isAdmin && !isWholesaleBuyer(user) && (
              <WebLink url={COOP_WEB_ONBOARD_URL} style={styles.webLink}>
                Are you a farmer? Start selling on GrownBy
              </WebLink>
            )}
            <View style={styles.exploreCont}>
              {layout.isSmallDevice && (
                <ButtonClear
                  size={16}
                  color={Colors.white}
                  title="Explore nearby farms"
                  onPress={goToMapSearch}
                  icon="map"
                />
              )}
              <CountryDropdown />
            </View>
          </View>
        </View>
      </ImageBackground>
    </View>
  )
})

const useStyles = () =>
  useLayoutFnStyles(({ top }) => ({
    header: {
      flexDirection: 'row',
      padding: 10,
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 10,
      paddingTop: top || 10,
    },
    logoCont: {
      alignItems: 'center',
      paddingTop: top,
    },
    webLink: {
      color: Colors.white,
      fontSize: 14,
      marginVertical: 10,
    },
    exploreCont: {
      marginTop: 10,
      alignItems: 'center',
    },
    btn: {
      justifyContent: 'center',
      flex: 0.3,
      flexGrow: 1,
    },
    logo: {
      padding: 10,
    },
    wrapper: {
      zIndex: 1,
    },
    fill: {
      flex: 1,
    },
    overImageLayer: {
      backgroundColor: Colors.black66,
    },
    searchWrapper: {
      alignItems: 'center',
      justifyContent: 'center',
      marginHorizontal: 20,
      marginTop: 40,
      marginBottom: 20,
    },
    title: {
      color: Colors.white,
    },

    leftContent: {
      flexDirection: 'row',
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginRight: 10,
    },
    shoppingCartCont: {
      position: 'absolute',
      right: 8,
      bottom: 8,
    },
  }))
