import { ConversationView, Image } from '@/components'
import Colors from '@/constants/Colors'
import { useFocusFx } from '@/hooks/useFocusFx'
import { useDeviceSize } from '@/hooks/useLayout'
import { MessagesParamList } from '@/navigation/types'
import { ConversationsList } from '@components'
import { ButtonClear, Icon, LoadingView, Text } from '@elements'
import { getOtherParticipant } from '@helpers/messaging'
import { userName } from '@helpers/user'
import { ChatSession, isAccountParticipant } from '@models/Messaging'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { useMessages } from './MessagesScreen.context'

/** UI Layer for the Messages screen */
export function MessagesScreenView({ adminFarmId }: { adminFarmId?: string }) {
  const { unreadChatsSnap, user, messagesSnap, selectedChatId, onIncreaseMessageLimit, chatsSnap, setCanLoadData } =
    useMessages()
  const navigation = useNavigation<StackNavigationProp<MessagesParamList, 'Chat'>>()

  const { isSmallDevice } = useDeviceSize()

  const selectedChat = [...(chatsSnap.data ?? []), ...(unreadChatsSnap.data ?? [])].find(
    (el) => el.id === selectedChatId,
  )

  const participantInfo = useMemo(() => {
    return getParticipantsInfo(selectedChat, user.id)
  }, [selectedChat, user.id])

  /** This will auto-navigate to the chat screen if a chat is selected and it is a small device
   * - This can happen mainly on navigating to a specific conversation via link
   * - If that's not the case, will trigger data load for this screen
   */
  useFocusFx(() => {
    if (isSmallDevice && selectedChatId) {
      navigation.setParams({ id: undefined })
      navigation.navigate('Chat', { id: selectedChatId })
    } else {
      // If the this screen is meant to be displayed, then data can be loaded
      setCanLoadData(true)
    }
  }, [isSmallDevice, navigation, selectedChatId, setCanLoadData])

  return (
    <LoadingView style={styles.content} error={chatsSnap.error} loading={chatsSnap.loading}>
      <View style={[styles.leftContainer, isSmallDevice && styles.noBorder]}>
        <ConversationsList />
      </View>
      {!isSmallDevice && (
        <View style={styles.rightContainer}>
          {selectedChat ? (
            <>
              {participantInfo && (
                <View style={styles.headerCont}>
                  <View style={styles.headerInfoCont}>
                    {participantInfo.logo ? (
                      <Image source={{ uri: participantInfo.logo }} style={styles.otherImg} />
                    ) : (
                      <Icon noHover name="user-circle" solid size={45} />
                    )}

                    <Text size={16}>{participantInfo.name}</Text>
                  </View>
                  <ButtonClear title={participantInfo.title} url={participantInfo.url} />
                </View>
              )}
              <ConversationView
                onLoadMore={onIncreaseMessageLimit}
                adminFarmId={adminFarmId}
                chatSession={selectedChat}
                loading={messagesSnap.loading}
                currUserId={user.id}
                data={messagesSnap.data}
              />
            </>
          ) : (
            <View style={styles.centered}>
              <Text>Please select a conversation </Text>
            </View>
          )}
        </View>
      )}
    </LoadingView>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    flexDirection: 'row',
    gap: 14,
  },
  rightContainer: {
    borderWidth: 1,
    borderRadius: 10,
    flex: 2,
    overflow: 'hidden',
    borderColor: Colors.shades['200'],
  },
  leftContainer: {
    borderWidth: 1,
    borderRadius: 10,
    flex: 1,
    minWidth: 250,
    overflow: 'hidden',
    borderColor: Colors.shades['200'],
  },
  centered: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
  headerCont: {
    borderBottomWidth: 1,
    borderColor: Colors.shades['200'],
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 10,
    paddingVertical: 5,
    backgroundColor: Colors.white,
  },
  otherImg: {
    width: 50,
    height: 50,
    borderRadius: 50,
  },
  headerInfoCont: {
    gap: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  noBorder: {
    borderWidth: 0,
  },
})

/** Adapter for the others participant info that will include a title, logo and url */
const getParticipantsInfo = (selectedChat: ChatSession | undefined, participantId: string) => {
  if (!selectedChat) return undefined

  const other = getOtherParticipant(selectedChat.participants, participantId)
  if (!other) return undefined

  if (isAccountParticipant(other)) {
    return {
      name: other.account.name,
      title: 'Go to farm profile',
      // We assume that the user is on the consumer side
      url: `/farms/${other.account.id}`,
      logo: other.account.logo,
    }
  }

  return {
    name: userName(other.user),
    title: 'Go to customer profile',
    // We assume that the user is on the admin side
    url: `/admin/customers/${other.user.id}`,
    logo: other.user.avatar,
  }
}
