import { AdminView } from '@/admin/components/AdminView'
import { OfflineTable, OfflineTableProps } from '@/admin/components/OfflineTable/OfflineTable'
import { isWeb } from '@/constants/Layout'
import { globalStyles } from '@/constants/Styles'
import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { useDeviceSize } from '@/hooks/useLayout'
import { useSnapshot } from '@/hooks/useSnapshot'
import { withAdminAuth } from '@/hooks/withAdminAuth'
import { adminFarmIdSelector } from '@/redux/selectors'
import { snapshotCustomShareByFarm } from '@api/CustomShares'
import { ToolTips } from '@components'
import { Alert, Button, HeaderText, Tooltip } from '@elements'
import { CustomShare } from '@models/CustomShare'
import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { CustomShareRow } from './components/CustomShareRow'

function AdminCustomSharesListComp() {
  const { isSmallDevice } = useDeviceSize()
  const adminFarmId = useSelector(adminFarmIdSelector)
  const shareApi = useSnapshot('snapshotCustomShareByFarm', snapshotCustomShareByFarm, [adminFarmId!], !!adminFarmId)
  const styles = useStyles()

  const renderItem: NonNullable<OfflineTableProps<CustomShare>['renderItem']> = ({ item: customShare, index }) => {
    return <CustomShareRow customShare={customShare} index={index} />
  }

  const onPressAdd = () => {
    if (shareApi.data && shareApi.data.length >= 1) {
      return Alert(
        'Add Custom Share',
        'We only allow one custom share at a time for now, please reach out to customer support to discuss your needs.',
      )
    }
    Alert(
      'Add Custom Share',
      'Please reach out to customer support to receive assistance in setting up your custom share.',
    )
  }

  return (
    <AdminView hideFooter style={styles.container}>
      <View style={styles.header}>
        <View style={globalStyles.flexRowCenter}>
          <HeaderText size={isSmallDevice ? 22 : 30}>Custom Shares</HeaderText>
          <Tooltip size={15} id={ToolTips.SHARE_CUSTOMIZATION} />
        </View>

        <Button style={styles.addButton} small title="New Custom Share" onPress={onPressAdd} />
      </View>
      <OfflineTable<CustomShare>
        isLoading={shareApi.loading}
        data={shareApi.data}
        headerColumns={[
          { title: 'Primary Shares', widthFlex: 2 },
          { title: 'Ordering Opens', widthFlex: 1 },
          { title: 'Ordering Closes', widthFlex: 1 },
          { title: 'Status', widthFlex: 1 },
          { widthFlex: 0.5 },
          { widthFlex: 0.5 },
        ]}
        renderItem={renderItem}
        minWidth={600}
        scrollEnabled={isWeb || !isSmallDevice}
        containerStyle={styles.offlineTableContainer}
      />
    </AdminView>
  )
}

const useStyles = () => {
  return useLayoutFnStyles(({ isSmallDevice, height, bottom }) => ({
    container: {
      paddingHorizontal: isSmallDevice ? 10 : 30,
      paddingTop: isSmallDevice ? 10 : 30,
      // Adds safe area padding at the bottom, with a minimum of 10 if no safe area exists
      paddingBottom: bottom || 10,
    },
    offlineTableContainer: {
      /** If it is not smallDevice or not extraSmallDevice, it means custom shares table is enabled to have nested scroll, so the maxHeight should be set to height * 0.78 (depend on the the table size and best view) to help to achieve scroll functionality. Otherwise, the maxHeight should be auto adjusted to correct height of table to be able to show all rows from scrolling entire screen if need.
       * Some case 'auto' maxHeight is not working, so we have to set maxHeight to 100% to make it work.
       */
      maxHeight: !isSmallDevice ? height * 0.78 : '100%',
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 20,
    },
    addButton: {
      marginRight: 20,
    },
  }))
}

export const AdminCustomSharesList = withAdminAuth(AdminCustomSharesListComp)
