import { Image } from '@components'
import { Text } from '@elements'
import { MoneyCalc } from '@helpers/money'
import { Farm } from '@models/Farm'
import { Money } from '@models/Money'
import { FarmMinimum } from '@screens/Shopping/Checkout/MyCart/components/FarmMinimum'
import { StyleSheet, View } from 'react-native'

type CartSidebarListHeaderProps = {
  farm: Farm
  orderMin: Money | undefined
  standardProdSubtotal: Money
  subtotal: Money
}

/** Renders the header for the cart sidebar list, displaying farm information and order minimum details.
 */
export function CartSidebarListHeader({ farm, orderMin, subtotal, standardProdSubtotal }: CartSidebarListHeaderProps) {
  return (
    <View style={styles.main}>
      {orderMin && (
        <View>
          <FarmMinimum
            subtotal={standardProdSubtotal}
            orderMin={orderMin}
            hasNonStandard={!MoneyCalc.isEqual(subtotal, standardProdSubtotal)}
          />
        </View>
      )}
      <View style={styles.farmInfoWrapper}>
        <Image style={styles.logo} source={{ uri: farm.logo }} />
        <Text numberOfLines={2} type="medium" style={styles.farmName}>
          {farm.name}
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    gap: 15,
  },
  logo: {
    height: 45,
    width: 45,
    borderRadius: 8,
  },
  farmInfoWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  farmName: {
    textDecorationLine: 'underline',
    flex: 1,
  },
})
