import { DateTime } from 'luxon'
import * as Yup from 'yup'

declare module 'yup' {
  // @ts-ignore
  interface MixedSchema<T = any, C = object, O = T> {
    isUndefined(): MixedSchema<T | undefined, C, O | undefined>
    isDateTime(): MixedSchema<DateTime, C>
  }
  // @ts-ignore
  interface ObjectSchema<
    TIn extends object | null | undefined,
    TContext = any,
    TDefault = any,
    TFlags extends Yup.Flags = '',
  > {
    isUndefined(): ObjectSchema<TIn, TContext, TDefault, TFlags>
  }
  interface StringSchema {
    isUndefined(): StringSchema
  }
  interface ArraySchema<
    TIn extends any[] | null | undefined,
    TContext = any,
    TDefault = undefined,
    TFlags extends Yup.Flags = '',
  > {
    isUndefined(): ArraySchema<TIn, TContext, TDefault, TFlags>
  }
}
/** This method will mark a field that is expected to be undefined and throw an error if it is not. */
Yup.addMethod(Yup.mixed, 'isUndefined', function () {
  return this.test(
    'is-undefined',
    ({ path, value }) => `Expected ${path} to be undefined, found: ${typeof value}`,
    (value) => value === undefined,
  )
})

/** This method will mark a field that is expected to be undefined and throw an error if it is not. */
Yup.addMethod(Yup.object, 'isUndefined', function () {
  return this.test(
    'is-undefined',
    ({ path, value }) => `Expected ${path} to be undefined, found: ${typeof value}`,
    (value) => value === undefined,
  )
})

Yup.addMethod(Yup.string, 'isUndefined', function () {
  return this.test(
    'is-undefined',
    ({ path, value }) => `Expected ${path} to be undefined, found: ${typeof value}`,
    (value) => value === undefined,
  )
})

Yup.addMethod(Yup.array, 'isUndefined', function () {
  return this.test(
    'is-undefined',
    ({ path, value }) => `Expected ${path} to be undefined, found: ${typeof value}`,
    (value) => value === undefined,
  )
})

/** This method will mark a field as a DateTime and validate it is correct. */
Yup.addMethod(Yup.mixed, 'isDateTime', function () {
  return this.test(
    'is-DateTime',
    ({ path, value }) => `${path} is not a valid Date, found: ${typeof value}`,
    (value) => {
      // If it is undefined we should not check if it is a date, a .required() or .optional() check will confirm that
      return value === undefined || (DateTime.isDateTime(value) && value.isValid)
    },
  )
})
