import { DisplayInvStatus, Invoice, InvoiceNextStep, InvoiceStatus, isPaid } from '@models/Invoice'
import { dateTimeInZone } from '@models/Timezone'
import { InvalidAmount, formatShortPickupDate } from './display'
import { PartialPick } from './typescript'

/**
 * Displays InvStatus with custom logic to mutate the invoice status with certain conditions.
 * Used in Admin Customer Invoice section, summaryInvoices and detailInvoices reports.
 */
export function displayInvStatus(invoice: PartialPick<Invoice, 'status' | 'dueDate'>): DisplayInvStatus {
  switch (invoice.status) {
    case InvoiceStatus.Due:
      return dateTimeInZone(invoice.dueDate.zoneName) < invoice.dueDate ? 'Unpaid' : InvoiceStatus.Due
    case InvoiceStatus.Incomplete:
      if (invoice.nextStep?.type === InvoiceNextStep.STRIPE_3D_SECURE) {
        return 'Pending (3D)'
      } else if (invoice.nextStep?.type === InvoiceNextStep.PENDING_BANK_AUTHORIZATION) {
        return 'Pending (ACH)'
      }
      return 'Pending'
    default:
      return invoice.status
  }
}

/**
 * Displays the formatted date of the invoice status.
 * Used in summaryInvoices and detailInvoices reports.
 */
export function displayInvStatusDate(invoice: Invoice): string {
  const datePaid = invoice.datePaid ? formatShortPickupDate(invoice.datePaid) : InvalidAmount
  const dateVoided = invoice.dateVoided ? formatShortPickupDate(invoice.dateVoided) : InvalidAmount
  const dueDate = formatShortPickupDate(invoice.dueDate)

  return isPaid(invoice) ? datePaid : invoice.status === InvoiceStatus.Void ? dateVoided : dueDate
}
