import { TextH2 } from '@elements'
import { MoneyCalc, Zero, withCurrency } from '@helpers/money'
import { Money, MoneyWithCurrency } from '@models/Money'
import {
  EbtCardTypes,
  EbtPaymentMethod,
  PaymentMethod,
  isEbtPayment,
  isFarmCreditPayment,
  pmt_FarmCredit,
} from '@models/PaymentMethod'
import { useCallback, useContext, useMemo } from 'react'
import { View } from 'react-native'
import { EbtPaymentSelection } from './EbtPaymentSelection'
import { FarmBalanceSelection } from './FarmBalanceSelection'
import { PaymentOptionsContext } from './PaymentOptionsContext'
import { sharedStyles } from './helpers/styles'

/** This section will show the options available to the user that have a finite limit, (EBT, Farm Credit) */
export function FinitePaymentSection({ payments }: { payments: PaymentMethod[] }) {
  const {
    userFarmBalance,
    options: { amountEbt },
  } = useContext(PaymentOptionsContext)

  const farmBalanceMoney = useMemo<MoneyWithCurrency>(
    () => MoneyCalc.fromCents(userFarmBalance?.amount ?? 0, amountEbt.currency),
    [userFarmBalance?.amount, amountEbt.currency],
  )

  // Get the EBT payment if one exists. The user can never have more than one
  const ebtPaymentMethod = payments.find(isEbtPayment)

  // We only want to show this section if the user has an ebt card or a farm credit amount
  const shouldShowSection =
    (MoneyCalc.isGTZero(amountEbt) && !!ebtPaymentMethod) || MoneyCalc.isGTZero(farmBalanceMoney)

  if (!shouldShowSection) return null
  return (
    <View>
      <TextH2 size={16} style={sharedStyles.headerText}>
        Apply Credits and Benefits
      </TextH2>
      {MoneyCalc.isGTZero(farmBalanceMoney) && <FarmBalanceSection farmBalanceAmt={farmBalanceMoney} />}
      {/* We should only show the EBT card when there is EBT eligible amount on the order and the user has a card*/}
      {MoneyCalc.isGTZero(amountEbt) && ebtPaymentMethod ? (
        <EbtPaymentSection ebtPaymentMethod={ebtPaymentMethod} />
      ) : null}
    </View>
  )
}

/** This component holds the payment selection for Farm Credit payments */
function FarmBalanceSection({ farmBalanceAmt }: { farmBalanceAmt: MoneyWithCurrency }) {
  const {
    splitTender,
    updateSplitTender,
    options: { amountTotal },
  } = useContext(PaymentOptionsContext)
  const selectedFarmBalanceAmount =
    splitTender.find(({ paymentMethod }) => isFarmCreditPayment(paymentMethod))?.amount ?? Zero

  const maxFarmBalanceAllowed = useMemo(() => MoneyCalc.min(amountTotal, farmBalanceAmt), [amountTotal, farmBalanceAmt])

  const updateFarmBalanceSplitTender = useCallback(
    (amt: Money) => {
      updateSplitTender({ paymentMethod: pmt_FarmCredit, amount: amt })
    },
    [updateSplitTender],
  )

  return (
    <FarmBalanceSelection
      selectedAmt={selectedFarmBalanceAmount}
      maxAmount={maxFarmBalanceAllowed}
      onChangeSelectedAmount={updateFarmBalanceSplitTender}
      currency={farmBalanceAmt.currency}
    />
  )
}

/** This component holds the payment selection for EBT payments */
function EbtPaymentSection({ ebtPaymentMethod }: { ebtPaymentMethod: EbtPaymentMethod }) {
  const {
    updateSplitTender,
    splitTender,
    options: { amountEbt: maxEbtAllowed },
  } = useContext(PaymentOptionsContext)

  const selectedEbtAmount = useMemo(
    () =>
      withCurrency(
        splitTender.find(({ paymentMethod }) => isEbtPayment(paymentMethod))?.amount ?? Zero,
        maxEbtAllowed.currency,
      ),
    [splitTender, maxEbtAllowed],
  )

  const updateEbtAmount = useCallback(
    (amt: Money) => {
      updateSplitTender({
        paymentMethod: { ...ebtPaymentMethod, card_type: ebtPaymentMethod.card_type ?? EbtCardTypes.SNAP },
        amount: amt,
      })
    },
    [updateSplitTender, ebtPaymentMethod],
  )

  return (
    <EbtPaymentSelection
      paymentMethod={ebtPaymentMethod}
      selectedAmt={selectedEbtAmount}
      maxEbtAmount={maxEbtAllowed}
      onChangeSelectedAmount={updateEbtAmount}
    />
  )
}
