import env from '../../../../../config/Environment'
import Colors from '../../../../../constants/Colors'

export const FORTIS_SCRIPT_URL =
  env.APP_ENV === 'prod'
    ? 'https://js.fortis.tech/commercejs-v1.0.0.min.js'
    : 'https://js.sandbox.fortis.tech/commercejs-v1.0.0.min.js'

// Options here: https://docs.fortis.tech/v/1_0_0.html#/rest/elements/configuration-options
export const DEFAULT_CONFIG = {
  theme: 'default',
  environment: env.APP_ENV === 'prod' ? 'production' : 'sandbox',
  showReceipt: false,
  floatingLabels: true,
  showSubmitButton: false,
  showValidationAnimation: false,
  fields: {
    billing: false,
  },
  appearance: {
    colorFieldBackground: Colors.white,
    colorFieldBorder: Colors.shades['100'],
    colorText: Colors.shades[600],
    colorLink: '#485fc7',
    fontSize: '16px',
    marginSpacing: '15px',
    rowMarginSpacing: '10px',
    borderRadius: '10px',
  },
  sectionHeaderLabels: {
    main: ' ',
  },
}
