import { YUP_MONEY_REQUIRED } from '@helpers/Yup'
import { isValidShortState, isValidZipcode } from '@helpers/address'
import { Address } from '@models/Address'
import { FeeWaiveOptionType, Location, LocationTypes, isLocalPickup } from '@models/Location'
import { Money } from '@models/Money'
import { ErrorTypes, ErrorWithCode } from '@shared/Errors'
import * as yup from 'yup'
import { TestContext } from 'yup'
import { hasOwnProperty } from '../helpers'
import { addressSchema } from './AddressBuilder'
import { Builder } from './Builder'
import { ValidationError, isValidationError, validateFromSchema } from './validators/helpers'
import { FSASchemaCanada } from './validators/sharedSchemasAddress'

/** This context object must be passed to the location schema whenever it is used, because some field validations depend on this type in the context */
export type LocationSchemaContext = {
  type: LocationTypes
}

/** Validates the regions field of a Nonpickup location */
export const regionsSchema: yup.ArraySchema<string[], LocationSchemaContext, ''> = yup
  .array<LocationSchemaContext>()
  /** Should be required() because this schema is only used within the location schema when the type is nonpickup */
  .required()
  .min(1, 'There must be at least one region')
  .of(
    yup
      .string<string, LocationSchemaContext>()
      .required()
      .test({
        name: 'regions-validation',
        exclusive: true,
        message: 'Invalid regions',
        test(value, context: TestContext) {
          if (!value) return false
          const ctx = this.options.context as LocationSchemaContext
          if (!ctx) return this.createError({ message: 'Cannot access schema context' })

          const locationType = ctx.type

          if (!locationType) {
            return context.createError({ message: 'Cannot access type from schema context' })
          }

          if (isLocalPickup(locationType)) {
            // This would never happen because a pickup location would not have used this schema in the first place
            // See the conditions in which the main location schema references this regions schema
            return true
          }

          /** We can't know the country that needs to be validated so we will check the region is valid in any country */
          if (locationType === LocationTypes.Delivery) {
            return isValidZipcode(value, undefined) || FSASchemaCanada.isValidSync(value)
          } else if (locationType === LocationTypes.Shipping) {
            return isValidShortState(value, undefined)
          } else {
            return context.createError({ message: 'Type not implemented' })
          }
        },
      }),
  )

// @ts-expect-error
export const locationSchema: yup.ObjectSchema<Location, LocationSchemaContext> = yup
  .object<LocationSchemaContext>()
  .shape({
    id: yup.string().required(),
    farm: yup
      .object({
        id: yup.string().required(),
        urlSafeSlug: yup.string().required(),
      })
      .required(),
    name: yup.string().required(),
    abbreviation: yup.string().optional(),
    timezone: yup.string().required(),
    type: yup
      .string<LocationTypes>()
      .required()
      .when('$type', {
        is: (type: LocationTypes) => isLocalPickup(type),
        then: (schema) =>
          schema.oneOf([LocationTypes.MARKET, LocationTypes.STAND, LocationTypes.COMMUNITY, LocationTypes.FARM]),
        otherwise: (schema) => schema.oneOf([LocationTypes.Shipping, LocationTypes.Delivery]),
      }),
    address: yup.mixed<Address>().when('$type', {
      is: (type: LocationTypes) => isLocalPickup(type),
      then: () => addressSchema.required(),
      otherwise: (schema) => schema.isUndefined(),
    }),
    cost: yup.mixed<Money>().when('$type', {
      is: (type: LocationTypes) => isLocalPickup(type),
      then: (schema) => schema.isUndefined(),
      otherwise: () => YUP_MONEY_REQUIRED('Cost', { allowZero: true, requireCurrency: true }),
    }),
    feeWaiveOption: yup.string<FeeWaiveOptionType>().when('$type', {
      is: (type: LocationTypes) => isLocalPickup(type),
      then: (schema) => schema.isUndefined(),
      otherwise: () => yup.mixed<FeeWaiveOptionType>().oneOf(Object.values(FeeWaiveOptionType)).required(),
    }),
    regions: yup.mixed<string[]>().when('$type', {
      is: (type: LocationTypes) => isLocalPickup(type),
      then: (schema) => schema.isUndefined(),
      otherwise: () => regionsSchema.required(),
    }),
  })
  .defined()

/**
 * This builder will create a location object */
export class LocationBuilder extends Builder<Location> {
  constructor() {
    super('location')
  }

  build(location: Partial<Location>): Location {
    return this.validate(location as Location)
  }

  /**
   * This function will validate a location object
   * @param location the location object to validate
   */
  validate(location: Location): Location {
    try {
      if (!hasOwnProperty(location, 'type')) {
        throw new ErrorWithCode({
          type: ErrorTypes.Validation,
          code: 'missing-location-type',
          devMsg: 'Data passed to validate location is not a valid type',
        })
      }
      // Context is used for the region validator to know what type of location it is
      const context: LocationSchemaContext = {
        type: location.type as LocationTypes,
      }
      return validateFromSchema(locationSchema, location, {
        context,
      })
    } catch (error) {
      if (isValidationError(error)) {
        throw new ValidationError({ path: 'location.' + (error.data?.path ?? ''), msg: error.message })
      }
      throw error
    }
  }
}
