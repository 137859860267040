import { Alert, Button, CheckBox, ErrorText, Toast } from '@elements'
import { PaymentForms, PaymentMethod } from '@models/PaymentMethod'
import { View } from 'react-native'

import { logAddPaymentMethod } from '@api/FBAnalytics'
import { addStripePaymentMethod } from '@api/Payments'
import { errorToString } from '@helpers/helpers'
import { CurrencyCode } from '@models/Money'
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useState } from 'react'
import env from '../../../config/Environment'
import { Logger } from '../../../config/logger'
import { globalStyles } from '../../../constants/Styles'
import { addNewStripeBankAccount, addNewStripeCreditCard } from './helpers'

const stripePromise = loadStripe(env.STRIPE_KEY)

export type AddPaymentProps = {
  userId: string
  /** Will be called either on create or on abort */
  onDismiss: (newPayment?: PaymentMethod) => void
  /** The type of payment method to accept */
  paymentMethod: PaymentForms.BANK | PaymentForms.CARD
  currency: CurrencyCode
}

/** This component handles adding a new credit card or Bank account through Stripe Elements. This is the web version, see
 * AddStripePayment.native.tsx for the native version */
export function AddStripePayment(props: AddPaymentProps) {
  const paymentMethodTypes = props.paymentMethod === PaymentForms.BANK ? ['us_bank_account'] : ['card']

  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: 'setup',
        currency: props.currency,
        setup_future_usage: 'off_session',
        paymentMethodCreation: 'manual',
        paymentMethodTypes,
      }}
    >
      <Internal {...props} />
    </Elements>
  )
}

/** Modal for adding a new credit card */
function Internal({ onDismiss, userId, paymentMethod }: AddPaymentProps) {
  const [error, setError] = useState<string | null>()
  const [loading, setLoading] = useState(false)
  const [updateFuture, setUpdateFuture] = useState(true)
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async () => {
    setError(null)
    if (!stripe || !elements) return
    // Trigger Stripe form validation
    const { error: submitError } = await elements.submit()
    if (submitError) {
      setError(submitError.message)
      return
    }

    setLoading(true)
    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod({ elements })
      if (!paymentMethod?.id) {
        setLoading(false)
        return setError(error?.message || 'There was an error adding this payment method. Please try again later.')
      }
      const setupInt = await addStripePaymentMethod({
        userId,
        type: paymentMethod.type,
        paymentMethodId: paymentMethod.id,
        updateFuture,
        isMobile: false,
      })

      if (paymentMethod.type !== 'card' && paymentMethod.type !== 'us_bank_account') {
        setLoading(false)
        return setError(`${paymentMethod.type} is not currently supported by GrownBy, please contact support.`)
      }
      if (!setupInt.client_secret) {
        setLoading(false)
        return setError(`Unable to add this payment method, please contact support.`)
      }

      const request = paymentMethod.type === 'card' ? addNewStripeCreditCard : addNewStripeBankAccount
      const payment = await request(stripe, paymentMethod, setupInt.client_secret)

      if (payment === undefined) {
        // This payment method requires more action before it can be used, so we should show an Alert and not auto select
        onDismiss()
        Alert(
          'Additional Verification Required',
          'If you entered your bank details manually, you will receive two emails. The second one will take 1-2 days to arrive and include instructions for verification. If your farm allows it please check out with Offline, then when your bank has been verified you can complete the payment in GrownBy. ',
        )
      } else {
        Toast('Payment method added successfully')
        onDismiss(payment)
      }

      logAddPaymentMethod(setupInt.status)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      Logger.debug(err)
      return setError(errorToString(err))
    }
  }

  return (
    <>
      <View style={globalStyles.margin10}>
        <PaymentElement />
      </View>
      <CheckBox
        style={globalStyles.marginHorizontal10}
        checked={updateFuture}
        onChange={setUpdateFuture}
        title="Update all future invoices to this payment"
      />
      <ErrorText>{error}</ErrorText>
      <Button
        loading={loading}
        title={`Add ${paymentMethod === PaymentForms.BANK ? 'Bank Account' : 'Credit Card'}`}
        onPress={handleSubmit}
      />
    </>
  )
}
