import { isValidException } from '@helpers/schedule'
import { getDayOfWeek, getWeekOfMonth, isValidDateRange } from '@helpers/time'
import { Distribution } from '@models/Distribution'
import { Farm } from '@models/Farm'
import { Location, LocationTypes, isNonPickup } from '@models/Location'
import {
  DayOfWeek,
  Exception,
  Frequency,
  SeasonalSchedule,
  YearRoundSchedule,
  isRescheduleException,
  isSeasonalSchedule,
  isSkipException,
} from '@models/Schedule'
import { dateTimeInZone } from '@models/Timezone'
import { DateTime } from 'luxon'
import * as Yup from 'yup'

import { Logger } from '@/config/logger'
import { HexColor, colorBank } from '@/constants/Colors'
import { Alert } from '@elements'
import { capitalize } from '@helpers/display'
import { getScheduleColorFromId } from '@helpers/distributions'
import { isPickupSkippedByException } from '@helpers/order'
import { keys } from '@helpers/typescript'
import { DefaultCatalog } from '@models/Product'
import { invalidPickupSkippedPrompt } from '../helpers/validationPrompts'

export type FormType = Pick<Distribution, 'name' | 'orderCutoffWindow' | 'closed'> & {
  notes?: string
  locationId: string
  scheduleType: 'Seasonal' | 'Year Round'
  frequency: Frequency
  /** This start date form value will be used for both seasonal startDate and yearRound pickupStart */
  startDate: DateTime
  endDate?: DateTime
  startTime: DateTime
  endTime: DateTime
  week: string
  color: HexColor
  exceptions: Exception[]
  defaultCatalog: DefaultCatalog
}

export const frequencyOptions = [
  { label: 'Daily', value: Frequency.DAILY },
  {
    label: 'Weekly',
    value: Frequency.WEEKLY,
  },
  {
    label: 'Biweekly',
    value: Frequency.BIWEEKLY,
  },
  {
    label: 'Monthly',
    value: Frequency.MONTHLY,
  },
]

/** Schema used for distribution form */
export const validationSchema: Yup.ObjectSchema<Omit<FormType, 'week'>> = Yup.object().shape({
  locationId: Yup.string().label('Location').required('Location is required'),
  name: Yup.string().trim().label('Schedule name').required(),
  defaultCatalog: Yup.string<DefaultCatalog>().required().oneOf(Object.values(DefaultCatalog)),
  orderCutoffWindow: Yup.number()
    .label('Order cutoff window')
    .min(0, 'You cannot have a negative cutoff window')
    .test(
      'Is whole?',
      'You can only specify whole numbers as cutoff windows',
      (value) => typeof value === 'number' && Math.round(value) === value,
    )
    .required(),
  notes: Yup.string().label('Notes').optional(),
  scheduleType: Yup.string<FormType['scheduleType']>()
    .label('Schedule Type')
    .required()
    .oneOf(['Seasonal', 'Year Round']),
  startDate: Yup.mixed<DateTime>()
    .label('Start Date')
    .required()
    .when('scheduleType', {
      is: 'Seasonal',
      then: (schema: Yup.MixedSchema<DateTime>) =>
        schema.test('isBefore', 'Start date must be before end date', function (date) {
          return isValidDateRange({ startDate: date, endDate: this.parent.endDate as DateTime })
        }),
    }),
  endDate: Yup.mixed<DateTime>()
    .label('End Date')
    .when('scheduleType', {
      is: (scheduleType: FormType['scheduleType']) => scheduleType === 'Seasonal',
      then: (schema) =>
        schema.required().test('isAfter', 'End date must be after the start date', function (date) {
          return isValidDateRange({ startDate: this.parent.startDate as DateTime, endDate: date })
        }),
      otherwise: (schema) => schema.optional(),
    }),
  startTime: Yup.mixed<DateTime>()
    .label('Start Time')
    .required()
    .test('isBefore', 'Start time must be before end time', function (date) {
      return (date as FormType['startTime']) < (this.parent.endTime as FormType['endTime'])
    }),
  endTime: Yup.mixed<DateTime>()
    .required()
    .label('End Time')
    .test('isAfter', 'End time must be after start time', function (date) {
      return (date as FormType['endTime']) > (this.parent.startTime as FormType['startTime'])
    }),
  frequency: Yup.mixed<Frequency>()
    .label('Frequency')
    .required()
    .oneOf([Frequency.MONTHLY, Frequency.BIWEEKLY, Frequency.WEEKLY, Frequency.DAILY]),
  closed: Yup.boolean(),
  color: Yup.string<FormType['color']>().required().oneOf(colorBank),
  exceptions: Yup.array<Exception>()
    .required()
    .test('isValid', 'Invalid exception', function (exceptions) {
      return exceptions.every((exception) => isValidException(exception))
    }),
})

/** Creates an INCOMPLETE distribution from the form values, for use by the calendar in this screen. Should not be used for onSubmit */
export function createDummyDistro(values: FormType, farm: Farm): Distribution | undefined {
  // Make sure we have the required values to build the distro
  if (!values.startDate || !values.frequency) return
  if (values.scheduleType === 'Seasonal' && !values.endDate) return

  let tempDist: Distribution
  if (values.scheduleType === 'Seasonal') {
    const seasonalDefault: Distribution<Location, SeasonalSchedule> = {
      schedule: {
        dayOfWeek: getDayOfWeek(values.startDate),
        frequency: values.frequency,
        hours: {
          startTime: values.startTime.toFormat('HH:mm'),
          endTime: values.endTime.toFormat('HH:mm'),
        },
        season: {
          startDate: values.startDate,
          endDate: values.endDate!,
        },
      },
      id: '',
      name: values.name,
      farm,
      location: {
        id: values.locationId,
        name: '',
        type: LocationTypes.FARM,
        timezone: farm.timezone,
        address: farm.address,
      },
      notes: '',
      orderCutoffWindow: !isNaN(Number(values.orderCutoffWindow)) ? Number(values.orderCutoffWindow) : 1,
      priceGroup: { type: 'default-catalog', catalog: values.defaultCatalog },
    }
    tempDist = seasonalDefault
  } else {
    const yearlyDefault: Distribution<Location, YearRoundSchedule> = {
      schedule: {
        dayOfWeek: getDayOfWeek(values.startDate),
        frequency: values.frequency,
        hours: {
          startTime: values.startTime.toFormat('HH:mm'),
          endTime: values.endTime.toFormat('HH:mm'),
        },
        pickupStart: values.startDate,
      },
      id: '',
      name: values.name,
      farm,
      location: {
        id: values.locationId,
        name: '',
        type: LocationTypes.FARM,
        timezone: farm.timezone,
        address: farm.address,
      },
      notes: values.notes ?? '',
      orderCutoffWindow: !isNaN(Number(values.orderCutoffWindow)) ? Number(values.orderCutoffWindow) : 1,
      priceGroup: { type: 'default-catalog', catalog: values.defaultCatalog },
    }
    tempDist = yearlyDefault
  }
  if (values.frequency !== Frequency.WEEKLY) {
    tempDist.schedule.week = getWeekOfMonth(values.startDate) - 1
  }

  return tempDist
}

/** Gets the initial form values for when a distro needs to be added */
export const getInitialAddValues = (farm: Farm): FormType => {
  return {
    name: '',
    defaultCatalog: DefaultCatalog.Retail,
    locationId: '',
    notes: '',
    orderCutoffWindow: 1,
    scheduleType: 'Seasonal',
    frequency: Frequency.WEEKLY,
    startDate: dateTimeInZone(farm.timezone).startOf('day'),
    endDate: dateTimeInZone(farm.timezone).plus({ month: 12 }).startOf('day'),
    startTime: dateTimeInZone(farm.timezone).startOf('day').plus({ hours: 9 }),
    endTime: dateTimeInZone(farm.timezone).startOf('day').plus({ hours: 17 }),
    week: '',
    closed: false,
    color: colorBank[0],
    exceptions: [],
  }
}

/** Gets the inital form values for edit, based on the existing distribution data */
export const getInitialEditValues = (distro: Distribution): FormType => {
  return {
    name: distro.name,
    defaultCatalog: distro.priceGroup?.type === 'default-catalog' ? distro.priceGroup.catalog : DefaultCatalog.Retail,
    locationId: distro.location.id,
    notes: distro.notes,
    orderCutoffWindow: distro.orderCutoffWindow,
    scheduleType: isSeasonalSchedule(distro.schedule) ? 'Seasonal' : 'Year Round',
    frequency: distro.schedule.frequency,
    startDate: isSeasonalSchedule(distro.schedule) ? distro.schedule.season.startDate : distro.schedule.pickupStart,
    endDate: isSeasonalSchedule(distro.schedule) ? distro.schedule.season.endDate : undefined,
    startTime: DateTime.fromFormat(distro.schedule.hours.startTime, 'HH:mm'),
    endTime: DateTime.fromFormat(distro.schedule.hours.endTime, 'HH:mm'),
    week: distro.schedule.week?.toString() || '',
    closed: distro.closed,
    color: distro.color || getScheduleColorFromId(distro.id) || colorBank[0],
    exceptions: distro.schedule.exceptions || [],
  }
}

/** Adds a specific exception in the exceptions array */
export const addException = (
  pickupDates: DateTime[],
  setFieldValue: (field: string, newValue: Exception[]) => void,
  currentExceptions: Exception[],
  e: Exception,
) => {
  const newExceptions = [...currentExceptions, e]
  const isFirstPickupSkipped = isPickupSkippedByException(pickupDates[0], newExceptions)
  const isLastPickupSkipped = isPickupSkippedByException(pickupDates[pickupDates.length - 1], newExceptions)

  if (isFirstPickupSkipped) {
    return invalidPickupSkippedPrompt('first')
  }
  if (isLastPickupSkipped) {
    return invalidPickupSkippedPrompt('last')
  }

  setFieldValue('exceptions', newExceptions)
}

/** Removes a specific exception from the exceptions array */
export const removeException = (
  setFieldValue: (field: string, newValue: Exception[]) => void,
  currentExceptions: Exception[],
  e: Exception,
) => {
  if (isSkipException(e) || isRescheduleException(e)) {
    setFieldValue(
      'exceptions',
      currentExceptions.filter((currEx) => e.sourceDate !== currEx.sourceDate),
    )
  } else {
    setFieldValue(
      'exceptions',
      currentExceptions.filter((currEx) => e.dayOfWeek !== currEx.dayOfWeek),
    )
  }
}

/** Will make sure that the user is not switching the location type between pickup and non-pickup types for the new schedule */
export const validateLocationChange = (
  locations: Location[],
  newLocId: string,
  currLocId: string,
  setFieldValue: (field: string, newValue: string) => void,
) => {
  const current = locations.find((loc) => loc.id === currLocId)
  const newLoc = locations.find((loc) => loc.id === newLocId)
  if (!newLoc) {
    Logger.error('Could not find location from selected ID')
    setFieldValue('locationId', currLocId)
    return
  }
  // If there is no current selection like on a new distro then allow the change
  if (!current) return setFieldValue('locationId', newLocId)

  // If the two locations are not the same pickup type then we should not save
  if (
    (isNonPickup(current.type) && !isNonPickup(newLoc.type)) ||
    (!isNonPickup(current.type) && isNonPickup(newLoc.type))
  ) {
    Alert('Unable to change location', 'You cannot switch this schedule to a location with a different type.')
    setFieldValue('locationId', currLocId)
  } else {
    setFieldValue('locationId', newLocId)
  }
}

/** Gets the daily values for the week, indicating whether each day is enabled based on exceptions. */
export const getDailyValues = (exceptions: Exception[]) => {
  const daysOfWeek = keys(DayOfWeek)
    .filter((key) => isNaN(Number(key)))
    .map((key) => ({
      key: DayOfWeek[key],
      dayName: capitalize(key),
      isSelected: !!exceptions.find((e) => e.dayOfWeek === DayOfWeek[key]),
    }))

  return daysOfWeek
}
