import { Text } from '@elements'
import { getOrderNum } from '@helpers/display'
import { format } from '@helpers/time'
import { Order } from '@models/Order'
import React from 'react'
import { StyleSheet, View } from 'react-native'

import Colors from '@/constants/Colors'
import { getBusinessNameOrUsername } from '@helpers/user'

export function OrderDetailsHeader({ order }: { order: Order }) {
  return (
    <View style={styles.orderHeaderCont}>
      <View style={styles.marginTop}>
        <Text size={14} style={styles.header}>
          Customer Info
        </Text>
        <Text size={14} style={styles.text}>
          {getBusinessNameOrUsername(order.user)}
        </Text>
        <Text size={14} style={styles.text}>
          {order.user.email}
        </Text>
      </View>
      <View style={styles.marginTop}>
        <Text size={14} style={styles.header}>
          Order Info
        </Text>
        <Text size={14} style={styles.text}>
          Order number: {getOrderNum(order.orderNum)}
        </Text>
        {order.purchaseOrder !== undefined && (
          <Text size={14} style={styles.text}>
            Purchase Order: {order.purchaseOrder}
          </Text>
        )}
        <Text size={14} style={styles.text}>
          Placed on: {format(order.date, 'MMMM dd, yyyy')}
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  header: {
    color: Colors.primaryGray,
  },
  text: {
    marginVertical: 5,
  },
  headerText: {
    fontSize: 14,
    marginTop: 15,
  },
  orderHeaderCont: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  marginTop: {
    marginTop: 10,
  },
})
