import { deepClone } from '@helpers/helpers'
import { MoneyCalc, Zero } from '@helpers/money'
import { isInfinitePayment } from '@helpers/paymentMethods'
import { sortByProperty } from '@helpers/sorting'
import { SplitTenderPayment, SplitTenderPaymentItemBase } from '@models/Order'
import {
  PaymentForms,
  PaymentSources,
  isAchPayment,
  isCashPayment,
  isEbtPayment,
  isFarmCreditPayment,
  mapSourceToType,
} from '@models/PaymentMethod'
import { needsDeliveryPayment, needsFuturePaymentOption } from './display'
import { PaymentSelectorOptions } from './types'

/**
 * This array holds the order that payment sources should be ranked in the split tender array based on how we want to
 * apply it. EBT comes first because there are specific items that are EBT eligible, so we want to make sure that we
 * maximize the amount that can be paid by EBT. Then we have farm credit because it is a finite amount, and we should use
 * that second. We don't need to include the infinite payment methods because they don't require any specific order.
 */
export const PAYMENT_PRECEDENCE: PaymentSources[] = [PaymentSources.WORLD_PAY_EBT, PaymentSources.FARM_CREDIT]

/**
 * This function will take in the current splitTender and new options and attempt to adjust the split tender to work with
 * the new options. This is just to improve user experience, so it does not need to cover all cases, it just reduces the
 * number of times that the user will be required to make changes to the split tender.
 * @param splitTender The payment split to consider for the adjustment
 * @param prevOpts The old options to compare against
 * @param newOpts The new options to make adjustments with
 */
export function adjustSplitTenderWithOptions(
  splitTender: SplitTenderPayment,
  prevOpts: PaymentSelectorOptions,
  newOpts: PaymentSelectorOptions,
): SplitTenderPayment {
  const newSplitTender = deepClone(splitTender)
  //TODO: Right now these are just some ideas that can help the user have to make as few changes as possible. We can expand
  // this over time to include a bunch of assumptions that will auto scale the split tender to the new options. This does
  // not need to be complete as the validate is called at the end, and if it can't fix the tender then it will just mark
  // it invalid and the user will have to manually make these changes.

  // If there is now a farm credit item in the cart then we should remove farm credit
  if (newOpts.hasFarmBalanceItem) {
    const farmCreditIdx = newSplitTender.findIndex((tender) => isFarmCreditPayment(tender.paymentMethod))
    if (farmCreditIdx !== -1) {
      newSplitTender.splice(farmCreditIdx, 1)
    }
  }

  // If the payment is not due now we should remove farm credit if it exists
  if (!newOpts.isInvoiceDueToday) {
    const farmCreditIdx = newSplitTender.findIndex((tender) => isFarmCreditPayment(tender.paymentMethod))
    if (farmCreditIdx !== -1) {
      newSplitTender.splice(farmCreditIdx, 1)
    }
  }

  // If the EBT amount has decreased then we should scale the EBT payment down to match if there is one
  if (MoneyCalc.isGreaterThan(prevOpts.amountEbt, newOpts.amountEbt)) {
    const ebtPmtIdx = newSplitTender.findIndex((tender) => isEbtPayment(tender.paymentMethod))
    if (ebtPmtIdx !== -1) {
      newSplitTender[ebtPmtIdx].amount = MoneyCalc.min(newOpts.amountEbt, newSplitTender[ebtPmtIdx].amount ?? Zero)
    }
  }

  // We will adjust the total here so that if we have extraneous payments they will be removed
  return removeOrAlterExcessiveSplitTender(newSplitTender, newOpts)
}

/**
 * This function will add a new tender or update an existing one following the practices for precedence in the tender array
 * @param newTender The new tender that was added or updated
 * @param prevSplitTender The existing split tender configuration
 * @param opts The options that should be used for configuring the new payment
 * */
export function addUpdateSplitTender(
  newTender: SplitTenderPaymentItemBase,
  prevSplitTender: SplitTenderPayment,
  opts: PaymentSelectorOptions,
): SplitTenderPayment {
  const splitTender = deepClone(prevSplitTender)
  const newSource = newTender.paymentMethod.source

  // Look for an existing payment method that should be replaced by the new type
  const existingIdx = splitTender.findIndex((tender) => {
    // Infinite payments are swappable between each other as they have no finite limits and can apply to any transaction
    // So if the new payment is infinite then we will look for any existing infinite payment to replace
    if (isInfinitePayment(newSource)) {
      return isInfinitePayment(tender.paymentMethod.source)
    } else {
      // For finite payments we can have more than one and want to be sure that we are replacing them for their same type
      return tender.paymentMethod.source === newSource
    }
  })

  // If the new tender has an amount of zero then we should remove it from the split tender
  if (newTender.amount && MoneyCalc.isZero(newTender.amount)) {
    // Confirm that the tender exists before trying to remove it, if it doesn't then ignore
    if (existingIdx !== -1) {
      splitTender.splice(existingIdx, 1)
    }
  } else {
    // We are adding/updating, so we should replace the existing or add the new tender if none exists
    if (existingIdx !== -1) {
      splitTender[existingIdx] = newTender
    } else {
      splitTender.push(newTender)
    }
  }

  // If we have a finite payment then we should remove any offline payments or ACH payments
  if (!isInfinitePayment(newSource)) {
    const pmtIdx = splitTender.findIndex(
      (tender) => isCashPayment(tender.paymentMethod) || isAchPayment(tender.paymentMethod),
    )
    if (pmtIdx !== -1) {
      splitTender.splice(pmtIdx, 1)
    }
  }

  // Sort the split tender based on the precedence array
  splitTender.sort(sortByProperty(PAYMENT_PRECEDENCE, (pmt) => pmt.paymentMethod.source))

  return removeOrAlterExcessiveSplitTender(splitTender, opts, newSource)
}

/**
 * This function will take the new tender and remove any that it deems unnecessary after the new tender has been added.
 * For example, if EBT is selected and someone then adds farm credit, we should adjust the EBT amount so that it covers
 * only the remaining amount.
 * @param splitTender the split tender after the new tender has been added
 * @param opts The options that should be used for configuring the new payment
 * @param newTenderSource the new tender source, used to determine what should be prioritized. Not required.
 */
export function removeOrAlterExcessiveSplitTender(
  splitTender: SplitTenderPayment,
  opts: PaymentSelectorOptions,
  newTenderSource?: PaymentSources,
): SplitTenderPayment {
  // This will tell us if we are removing the tender instead of adding/updating it. If so we should skip this function
  // as this function only removes excess tender which won't happen after one is removed
  const isRemovingTender =
    newTenderSource && !splitTender.some((tender) => tender.paymentMethod.source === newTenderSource)
  if (isRemovingTender) return splitTender

  // Adding/updating EBT and farm credit already exists (we should use full ebt amount and scale farm credit down to cover remaining)
  if (newTenderSource && mapSourceToType(newTenderSource) === PaymentForms.EBT) {
    // Check if we have farm credit in the split tender
    const farmCreditIdx = splitTender.findIndex((tender) => isFarmCreditPayment(tender.paymentMethod))
    if (farmCreditIdx !== -1) {
      // This should never not exist because it is what we just added
      const ebtTender = splitTender.find((tender) => isEbtPayment(tender.paymentMethod))
      if (ebtTender === undefined)
        throw new Error('EBT payment type should exist but was not found in payment methods.')

      // Calculate the remaining amount that needs to be covered by farm credit after the EBT tender is applied
      const farmCreditTender = splitTender[farmCreditIdx]
      const remaining = MoneyCalc.subtract(opts.amountTotal, ebtTender.amount!)
      // We will change the farm credit amount but not allow it to go more than what was originally entered
      const newFarmCreditAmt = MoneyCalc.min(farmCreditTender.amount!, remaining)

      // Update the FC payment or delete it if the amount is $0
      if (MoneyCalc.isGTZero(newFarmCreditAmt)) {
        splitTender[farmCreditIdx].amount = newFarmCreditAmt
      } else {
        splitTender.splice(farmCreditIdx, 1)
      }
    }
  }

  // Adding/updating farm credit and EBT already exists (we should use full farm credit amount and scale ebt down to cover remaining)
  if (newTenderSource === PaymentSources.FARM_CREDIT) {
    // Check if we have EBT in the split tender
    const ebtPmtIdx = splitTender.findIndex((tender) => isEbtPayment(tender.paymentMethod))
    if (ebtPmtIdx !== -1) {
      // This should never not exist because it is what we just added
      const farmCreditTender = splitTender.find((tender) => isFarmCreditPayment(tender.paymentMethod))
      if (farmCreditTender === undefined)
        throw new Error('Farm credit payment type should exist but was not found in payment methods.')

      // Calculate the remaining amount that needs to be covered by EBT after the farm credit tender is applied
      const ebtTender = splitTender[ebtPmtIdx]
      const remaining = MoneyCalc.subtract(opts.amountTotal, farmCreditTender.amount!)
      // We will change the farm credit amount but not allow it to go more than what was originally entered
      const newEbtAmount = MoneyCalc.min(ebtTender.amount!, remaining)

      // Update the EBT payment or delete it if the amount is $0
      if (MoneyCalc.isGTZero(newEbtAmount)) {
        splitTender[ebtPmtIdx].amount = newEbtAmount
      } else {
        splitTender.splice(ebtPmtIdx, 1)
      }
    }
  }

  // Get the total of all finite payments for the split tender
  const finitePayments = splitTender.filter((tender) => !isInfinitePayment(tender.paymentMethod.source))
  const splitTenderFiniteTotal = finitePayments.reduce(
    (total, tender) => MoneyCalc.add(total, tender.amount ?? Zero),
    Zero,
  )

  // If we are not adding or updating a tender then it means the options have just changed, and we should scale down the
  // finite payments to match the new total if applicable
  if (!newTenderSource) {
    // Calculate the excess amount
    let excessAmount = MoneyCalc.subtract(splitTenderFiniteTotal, opts.amountTotal)

    // If the finite payments are greater than the total, adjust the EBT and/or Farm Credit amounts
    if (MoneyCalc.isGTZero(excessAmount)) {
      // Try to reduce the EBT amount first
      const ebtPmtIdx = splitTender.findIndex((tender) => isEbtPayment(tender.paymentMethod))
      if (ebtPmtIdx !== -1) {
        const ebtTender = splitTender[ebtPmtIdx]
        const newEbtAmount = MoneyCalc.subtract(ebtTender.amount!, excessAmount)

        // Update the EBT payment or delete it if the amount is $0 or less
        if (MoneyCalc.isGTZero(newEbtAmount)) {
          splitTender[ebtPmtIdx].amount = newEbtAmount
        } else {
          splitTender.splice(ebtPmtIdx, 1)
        }
        // We should update the excess amount to reflect the new amount and continue to farm credit if we have more excess
        if (MoneyCalc.isLessThan(newEbtAmount, Zero)) {
          excessAmount = MoneyCalc.multiply(newEbtAmount, -1)
        } else {
          excessAmount = Zero
        }
      }

      // If there's still excess, try to reduce the Farm Credit amount
      const farmCreditIdx = splitTender.findIndex((tender) => isFarmCreditPayment(tender.paymentMethod))
      if (farmCreditIdx !== -1 && MoneyCalc.isGTZero(excessAmount)) {
        const farmCreditTender = splitTender[farmCreditIdx]
        const newFarmCreditAmt = MoneyCalc.subtract(farmCreditTender.amount!, excessAmount)

        // Update the Farm Credit payment or delete it if the amount is $0 or less
        if (MoneyCalc.isGTZero(newFarmCreditAmt)) {
          splitTender[farmCreditIdx].amount = newFarmCreditAmt
        } else {
          splitTender.splice(farmCreditIdx, 1)
        }
      }
    }
  }

  // If there is no delivery or future payment and the finite amounts cover the total we should remove infinite payments
  if (!needsFuturePaymentOption(splitTender, opts) && !needsDeliveryPayment(opts)) {
    if (MoneyCalc.isGTE(splitTenderFiniteTotal, opts.amountTotal)) {
      // If the total of all finite payments is greater than or equal to the total and there are no installments or delivery
      // then we should remove the infinite payment
      const infiniteIdx = splitTender.findIndex((tender) => isInfinitePayment(tender.paymentMethod.source))
      if (infiniteIdx !== -1) {
        splitTender.splice(infiniteIdx, 1)
      }
    }
  }

  // We allow a payment to have an amount of $0 only when the order total is also $0. So we want to check if the
  // order total is not $0, then we should remove any payments that have an amount of $0
  if (MoneyCalc.isGTZero(opts.amountTotal)) {
    splitTender = splitTender.filter((tender) => !tender.amount || !MoneyCalc.isZero(tender.amount))
  }

  return splitTender
}
