import * as Yup from 'yup'

import {
  Alert,
  CheckBox,
  Divider,
  FormButton,
  FormInput,
  KeyboardAvoidingScrollView,
  MoneyInput,
  Text,
  TextH2,
  TextH4,
} from '@elements'
import { MoneyCalc, Zero, getZero, makeMoney, withCurrency } from '@helpers/money'
import { Invoice, InvoicePayment, InvoicePaymentMethod } from '@models/Invoice'
import { CurrencyCode, Money, MoneyWithCurrency } from '@models/Money'
import { Formik, FormikProps } from 'formik'
import { StyleSheet, View } from 'react-native'

import { Logger } from '@/config/logger'
import { adminCurrencySelector } from '@/redux/selectors'
import { createPartialRefund } from '@api/Invoices'
import { YUP_MONEY_REQUIRED } from '@helpers/Yup'
import { formatMoney } from '@helpers/display'
import { errorToString } from '@helpers/helpers'
import { EbtCardTypes, PaymentSources } from '@models/PaymentMethod'
import { Payment } from '@shared/types/v2/invoice'
import { useState } from 'react'
import { useSelector } from 'react-redux'

type FormType = {
  stripeCCAmount: MoneyWithCurrency
  stripeACHAmount: MoneyWithCurrency
  fortisCCAmount: MoneyWithCurrency
  fortisACHAmount: MoneyWithCurrency
  farmCreditAmount: MoneyWithCurrency
  ebtAmount: MoneyWithCurrency
  note?: string
}

/** A modal that will show to allow the farmer to specify refund amounts */
export function AdminRefundInvoiceModal({ invoice }: { invoice: Invoice }) {
  const [isLoading, setLoading] = useState(false)
  const currency = useSelector(adminCurrencySelector)
  const [hasNote, setHasNote] = useState(false)

  const { stripe, stripe_ach, worldpay_ebt, farmcredit, fortis_ach, fortis_card_online } = invoice.payments

  const stripeCCPaymentExist = isPaymentExist(stripe)
  const stripeACHPaymentExist = isPaymentExist(stripe_ach)
  const fortisCCPaymentExist = isPaymentExist(fortis_card_online)
  const fortisACHPaymentExist = isPaymentExist(fortis_ach)
  const ebtPaymentExist = isPaymentExist(worldpay_ebt)
  const farmcreditPaymentExist = isPaymentExist(farmcredit)

  //check if ebt is cash or snap
  const isEBTCash =
    !!worldpay_ebt?.paymentMethod &&
    (worldpay_ebt?.paymentMethod as InvoicePaymentMethod<PaymentSources.WORLD_PAY_EBT>)?.card_type === EbtCardTypes.CASH

  const isEBTSnap =
    !!worldpay_ebt?.paymentMethod &&
    (worldpay_ebt?.paymentMethod as InvoicePaymentMethod<PaymentSources.WORLD_PAY_EBT>)?.card_type === EbtCardTypes.SNAP

  const stripeCCRemainingValue = getRemainingValue(invoice, PaymentSources.STRIPE, currency)
  const stripeACHRemainingValue = getRemainingValue(invoice, PaymentSources.STRIPE_ACH, currency)

  const fortisCCRemainingValue = getRemainingValue(invoice, PaymentSources.FORTIS_CARD_ONLINE, currency)
  const fortisACHRemainingValue = getRemainingValue(invoice, PaymentSources.FORTIS_ACH, currency)

  //remaining value from ebt
  const ebtRemainingValue = getRemainingValue(invoice, PaymentSources.WORLD_PAY_EBT, currency)

  //remaining value from farm credit
  const farmCreditRemainingValue = getRemainingValue(invoice, PaymentSources.FARM_CREDIT, currency)

  //confirm refund handler
  const confirmRefund = (values: FormType) => {
    const { stripeCCAmount, stripeACHAmount, farmCreditAmount, ebtAmount, fortisCCAmount, fortisACHAmount, note } =
      values

    const refundNote = hasNote ? note : ''

    const payments: Payment[] = []

    if (stripe) {
      payments.push({
        processor: stripe.source,
        amount: stripeCCAmount,
      })
    }

    if (stripe_ach) {
      payments.push({
        processor: stripe_ach.source,
        amount: stripeACHAmount,
      })
    }

    if (fortis_card_online) {
      payments.push({
        processor: fortis_card_online.source,
        amount: fortisCCAmount,
      })
    }

    if (fortis_ach) {
      payments.push({
        processor: fortis_ach.source,
        amount: fortisACHAmount,
      })
    }

    if (worldpay_ebt) {
      payments.push({
        processor: worldpay_ebt.source,
        amount: ebtAmount,
      })
    }

    //If farmer decided to refund farm credit instead of stripe, then we need to add the farmcredit as one payment object if the amount is greater than 0
    if (farmcredit || farmCreditAmount.value > 0) {
      payments.push({
        processor: farmcredit?.source || PaymentSources.FARM_CREDIT,
        amount: farmCreditAmount,
      })
    }

    // nothing to refund as amounts are all 0
    if (payments.filter((amt) => !MoneyCalc.isZero(amt.amount)).length === 0) {
      Alert('Fail', 'There is no refund to process.')
      return
    }

    setLoading(true)
    createPartialRefund(invoice, payments, refundNote)
      .then((res) => {
        setLoading(false)
        if (res.success) Alert('Success', 'Refund Successful. The changes will show up shortly.')
        else {
          // If all refunds are unsuccessful make the message clear none failed
          if (res?.messages?.length === Object.keys(payments).length) {
            Alert('Refund Failed', res?.messages?.join('\n'))
          } else {
            Alert(
              'Refund Failed',
              'This invoice has been partially refunded however some refunds failed. You can handle these failed refunds through the customer details page.\n' +
                res?.messages?.join('\n'),
            )
          }
        }
      })
      .catch((err) => {
        Logger.error(err)
        setLoading(false)
        Alert('Refund Failed', `${errorToString(err)}`)
      })
  }

  const validationSchema: Yup.ObjectSchema<FormType> = Yup.object().shape({
    stripeCCAmount: stripeCCPaymentExist
      ? YUP_MONEY_REQUIRED('Stripe Amount', { requireCurrency: true, allowZero: true })
          .label('Credit Card Amount')
          .test(
            'stripeCCAmount',
            `Credit Card refund amount cannot exceed ${formatMoney(stripeCCRemainingValue)}`,
            (val: any) => {
              return MoneyCalc.isLTE(val as Money, stripeCCRemainingValue)
            },
          )
          .test(
            'stripeAmount',
            'Credit Card refund amount cannot be less than $0.50',
            (val: any) => MoneyCalc.isZero(val) || MoneyCalc.isGTE(val as Money, makeMoney(50)),
          )
      : YUP_MONEY_REQUIRED('Stripe Amount', { requireCurrency: true, allowZero: true }),
    stripeACHAmount: stripeACHPaymentExist
      ? YUP_MONEY_REQUIRED('Stripe ACH Amount', { requireCurrency: true, allowZero: true })
          .label('Bank Account Amount')
          .test(
            'stripeACHAmount',
            `Bank Account refund amount cannot exceed ${formatMoney(stripeACHRemainingValue)}`,
            (val: any) => {
              return MoneyCalc.isLTE(val as Money, stripeACHRemainingValue)
            },
          )
          .test(
            'stripeACHAmount',
            'Bank Account refunds cannot be less than $0.50',
            (val: any) => MoneyCalc.isZero(val) || MoneyCalc.isGTE(val as Money, makeMoney(50)),
          )
      : YUP_MONEY_REQUIRED('Stripe ACH Amount', { requireCurrency: true, allowZero: true }),
    fortisCCAmount: fortisCCPaymentExist
      ? YUP_MONEY_REQUIRED('Fortis Credit Card Amount', { requireCurrency: true, allowZero: true })
          .label('Credit Card Amount')
          .test(
            'fortisCCAmount',
            `Credit Card refund amount cannot exceed ${formatMoney(fortisCCRemainingValue)}`,
            (val: any) => {
              return MoneyCalc.isLTE(val as Money, fortisCCRemainingValue)
            },
          )
          .test(
            'fortisCCAmount',
            'Credit Card refunds cannot be less than $0.50',
            (val: any) => MoneyCalc.isZero(val) || MoneyCalc.isGTE(val as Money, makeMoney(50)),
          )
      : YUP_MONEY_REQUIRED('Fortis Credit Card Amount', { requireCurrency: true, allowZero: true }),
    fortisACHAmount: fortisACHPaymentExist
      ? YUP_MONEY_REQUIRED('Fortis ACH Amount', { requireCurrency: true, allowZero: true })
          .label('Bank Account Amount')
          .test(
            'fortisACHAmount',
            `Bank Account refund amount cannot exceed ${formatMoney(fortisACHRemainingValue)}`,
            (val: any) => {
              return MoneyCalc.isLTE(val as Money, fortisACHRemainingValue)
            },
          )
          .test(
            'fortisACHAmount',
            'Bank Account refunds cannot be less than $0.50',
            (val: any) => MoneyCalc.isZero(val) || MoneyCalc.isGTE(val as Money, makeMoney(50)),
          )
      : YUP_MONEY_REQUIRED('Fortis ACH Amount', { requireCurrency: true, allowZero: true }),
    farmCreditAmount: YUP_MONEY_REQUIRED('Farm Credit Amount', { requireCurrency: true, allowZero: true })
      .label('Farm Credit')
      .test(
        'farmCreditAmount',
        `Farm Credit refund amount cannot exceed ${formatMoney(
          MoneyCalc.add(
            stripe?.totalPaid || Zero,
            stripe_ach?.totalPaid || Zero,
            fortis_card_online?.totalPaid || Zero,
            fortis_ach?.totalPaid || Zero,
            farmcredit?.totalPaid || Zero,
          ),
        )}`,
        function (val: unknown) {
          const farmCreditAmt = val as Money
          // This will get the amount paid with Credit Card, ACH and farm credit, and this represents the max amount you can refund
          // to farm credit
          const totalRefundAmt = MoneyCalc.add(
            stripe?.totalPaid || Zero,
            stripe_ach?.totalPaid || Zero,
            fortis_card_online?.totalPaid || Zero,
            fortis_ach?.totalPaid || Zero,
            farmcredit?.totalPaid || Zero,
          )
          // Make sure we are not attempting to refund more than the max combined amount
          return MoneyCalc.isLTE(farmCreditAmt, totalRefundAmt)
        },
      )
      .test(
        'farmCreditAmount',
        'Refund amounts for Credit Card or Bank Account transactions must be returned to either the farm credit or the original Credit Card/Bank Account; partial refunds or splitting the refund between these options are not allowed.',
        function (val: unknown) {
          const farmCreditAmt = val as Money
          // eslint-disable-next-line react/no-this-in-sfc
          const cardAmount = (this.parent?.stripeCCAmount || this.parent?.fortisCCAmount) as Money
          // eslint-disable-next-line react/no-this-in-sfc
          const bankAmount = (this.parent?.stripeACHAmount || this.parent?.fortisACHAmount) as Money

          // If we are only refunding farm credit to farm credit then this check is meaningless
          if (MoneyCalc.isLTE(farmCreditAmt, farmcredit?.totalPaid || Zero)) return true

          // If the refund for farm credit includes part of the Credit Card amount or ACH amount, make sure the Credit Card amount or ACH amount is Zero
          return MoneyCalc.isZero(cardAmount) || MoneyCalc.isZero(bankAmount)
        },
      ),
    ebtAmount: YUP_MONEY_REQUIRED('EBT Amount', { requireCurrency: true, allowZero: true }),
    note: Yup.string().optional(),
  })

  return (
    <KeyboardAvoidingScrollView>
      <Formik
        initialValues={{
          stripeCCAmount: stripeCCRemainingValue,
          stripeACHAmount: stripeACHRemainingValue,
          fortisCCAmount: fortisCCRemainingValue,
          fortisACHAmount: fortisACHRemainingValue,
          farmCreditAmount: farmCreditRemainingValue,
          ebtAmount: ebtRemainingValue,
        }}
        onSubmit={confirmRefund}
        validationSchema={validationSchema}
      >
        {({ values, errors, setFieldValue, handleSubmit, handleBlur, touched }: FormikProps<FormType>) => (
          <>
            <Divider clear />
            <View style={styles.refundOptionsHeader}>
              <TextH2 size={14}>Refund Options</TextH2>
              <Text size={10}>
                You may issue a refund to a customer through Credit Card, Bank, Farm Credit or EBT, depending on how the
                invoice was paid.
              </Text>
              <Divider clear />
              {stripeCCPaymentExist && (
                <View style={styles.refundCont}>
                  <TextH4 style={styles.payTypeCont}>Credit Card</TextH4>
                  <MoneyInput
                    containerStyle={styles.inputCont}
                    inputStyle={styles.moneyInputStyle}
                    maxLength={11}
                    onBlur={handleBlur('stripeCCAmount')}
                    onChangeText={(value) => setFieldValue('stripeCCAmount', value ?? getZero(currency))}
                    value={values.stripeCCAmount}
                    errorMessage={
                      touched.stripeCCAmount && !!errors.stripeCCAmount ? (errors.stripeCCAmount as string) : ''
                    }
                    currency={currency}
                  />

                  <View style={styles.centeredContent}>
                    <Text size={10} style={styles.maxAmount}>
                      {formatMoney(stripe?.totalPaid || Zero)} paid on credit card
                    </Text>
                    <Text size={10} style={styles.maxAmount}>
                      ({formatMoney(stripeCCRemainingValue)} refundable value)
                    </Text>
                  </View>
                </View>
              )}
              {stripeACHPaymentExist && (
                <View style={styles.refundCont}>
                  <TextH4 style={styles.payTypeCont}>Bank</TextH4>
                  <MoneyInput
                    containerStyle={styles.inputCont}
                    inputStyle={styles.moneyInputStyle}
                    maxLength={11}
                    onBlur={handleBlur('stripeACHAmount')}
                    onChangeText={(value) => setFieldValue('stripeACHAmount', value ?? getZero(currency))}
                    value={values.stripeACHAmount}
                    errorMessage={
                      touched.stripeACHAmount && !!errors.stripeACHAmount ? (errors.stripeACHAmount as string) : ''
                    }
                    currency={currency}
                  />

                  <View style={styles.centeredContent}>
                    <Text size={10} style={styles.maxAmount}>
                      {formatMoney(stripe_ach?.totalPaid || Zero)} paid by bank account
                    </Text>
                    <Text size={10} style={styles.maxAmount}>
                      ({formatMoney(stripeACHRemainingValue)} refundable value)
                    </Text>
                  </View>
                </View>
              )}
              {fortisCCPaymentExist && (
                <View style={styles.refundCont}>
                  <TextH4 style={styles.payTypeCont}>Credit Card</TextH4>
                  <MoneyInput
                    containerStyle={styles.inputCont}
                    inputStyle={styles.moneyInputStyle}
                    maxLength={11}
                    onBlur={handleBlur('fortisCCAmount')}
                    onChangeText={(value) => setFieldValue('fortisCCAmount', value ?? getZero(currency))}
                    value={values.fortisCCAmount}
                    errorMessage={
                      touched.fortisCCAmount && !!errors.fortisCCAmount ? (errors.fortisCCAmount as string) : ''
                    }
                    currency={currency}
                  />

                  <View style={styles.centeredContent}>
                    <Text size={10} style={styles.maxAmount}>
                      {formatMoney(fortis_card_online?.totalPaid || Zero)} paid on credit card
                    </Text>
                    <Text size={10} style={styles.maxAmount}>
                      ({formatMoney(fortisCCRemainingValue)} refundable value)
                    </Text>
                  </View>
                </View>
              )}
              {fortisACHPaymentExist && (
                <View style={styles.refundCont}>
                  <TextH4 style={styles.payTypeCont}>Bank</TextH4>
                  <MoneyInput
                    containerStyle={styles.inputCont}
                    inputStyle={styles.moneyInputStyle}
                    maxLength={11}
                    onBlur={handleBlur('fortisACHAmount')}
                    onChangeText={(value) => setFieldValue('fortisACHAmount', value ?? getZero(currency))}
                    value={values.fortisACHAmount}
                    errorMessage={
                      touched.fortisACHAmount && !!errors.fortisACHAmount ? (errors.fortisACHAmount as string) : ''
                    }
                    currency={currency}
                  />

                  <View style={styles.centeredContent}>
                    <Text size={10} style={styles.maxAmount}>
                      {formatMoney(fortis_ach?.totalPaid || Zero)} paid by bank account
                    </Text>
                    <Text size={10} style={styles.maxAmount}>
                      ({formatMoney(fortisACHRemainingValue)} refundable value)
                    </Text>
                  </View>
                </View>
              )}
              {(farmcreditPaymentExist ||
                stripeCCPaymentExist ||
                stripeACHPaymentExist ||
                fortisCCPaymentExist ||
                fortisACHPaymentExist) && (
                <View style={styles.refundCont}>
                  <TextH4 style={styles.payTypeCont}>Farm Credit</TextH4>

                  <MoneyInput
                    containerStyle={styles.inputCont}
                    inputStyle={styles.moneyInputStyle}
                    maxLength={11}
                    onBlur={handleBlur('farmCreditAmount')}
                    onChangeText={(value) => setFieldValue('farmCreditAmount', value ?? getZero(currency))}
                    value={values.farmCreditAmount}
                    errorMessage={
                      touched.farmCreditAmount && !!errors.farmCreditAmount ? (errors.farmCreditAmount as string) : ''
                    }
                    currency={currency}
                  />
                </View>
              )}
              {ebtPaymentExist && isEBTSnap && (
                <View style={styles.refundCont}>
                  <TextH4 style={styles.payTypeCont}>EBT SNAP</TextH4>
                  <TextH4 size={14} style={[styles.payTypeCont, styles.inputCont]}>
                    {formatMoney(ebtRemainingValue)}
                  </TextH4>
                  <View style={styles.flexEndContent}>
                    <Text size={10} style={styles.maxAmount}>
                      {formatMoney(worldpay_ebt?.totalPaid)} paid on EBT Card
                    </Text>
                  </View>
                </View>
              )}
              {ebtPaymentExist && isEBTCash && (
                <View style={styles.refundCont}>
                  <TextH4 style={styles.payTypeCont}>EBT CASH</TextH4>
                  <TextH4 size={14} style={[styles.payTypeCont, styles.inputCont]}>
                    {formatMoney(ebtRemainingValue)}
                  </TextH4>
                  <View style={styles.flexEndContent}>
                    <Text size={10} style={styles.maxAmount}>
                      {formatMoney(worldpay_ebt?.totalPaid)} paid on EBT Card
                    </Text>
                  </View>
                </View>
              )}
            </View>

            <CheckBox
              checked={hasNote}
              style={styles.checkBoxStyle}
              onChange={(val) => setHasNote(val)}
              title="Add a refund note to the invoice (Optional)"
            />
            {hasNote && (
              <FormInput
                multiline
                label={null}
                numberOfLines={2}
                placeholder="Message..."
                value={values.note}
                onChangeText={(val) => setFieldValue('note', val)}
                onBlur={handleBlur('note')}
                containerStyle={styles.textInputBoxStyle}
              />
            )}

            <View style={styles.formButtonContainer}>
              <FormButton loading={isLoading} disabled={isLoading} title="Confirm Refund" onPress={handleSubmit} />
            </View>
          </>
        )}
      </Formik>
    </KeyboardAvoidingScrollView>
  )
}

const styles = StyleSheet.create({
  payTypeCont: {
    minWidth: 100,
    marginTop: 10,
  },
  inputCont: {
    width: 120,
    minHeight: 0,
  },
  refundCont: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: 8,
    marginRight: 0,
  },
  maxAmount: {
    marginLeft: 5,
    marginBottom: 5,
  },
  refundOptionsHeader: {
    marginLeft: 15,
  },
  textInputBoxStyle: {
    marginLeft: 10,
    width: '90%',
  },
  formButtonContainer: {
    marginTop: 10,
  },
  checkBoxStyle: {
    marginLeft: 20,
  },
  centeredContent: {
    justifyContent: 'center',
  },
  flexEndContent: {
    justifyContent: 'flex-end',
  },
  moneyInputStyle: { width: 120 },
})

/** Check if a payment exists. This is helpful to check if the source payment exists in a invoice
 * @param payment - payment object
 */
const isPaymentExist = (payment?: InvoicePayment): boolean => {
  return !!payment?.totalPaid && payment?.totalPaid?.value > 0
}

/** Get remaining value from different PaymentSources for a invoice */
const getRemainingValue = (
  invoice: Invoice,
  paymentSource: PaymentSources,
  currency: CurrencyCode,
): MoneyWithCurrency => {
  return withCurrency(
    MoneyCalc.max(
      MoneyCalc.subtract(
        invoice.payments[paymentSource]?.totalPaid || getZero(currency),
        invoice.payments[paymentSource]?.refundedAmount || getZero(currency),
      ),
      getZero(currency),
    ),
    currency,
  )
}
