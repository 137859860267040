import {
  CountryCodeSchema,
  StateSchemaCountry,
  ZipCodeSchemaCountry,
  addressFieldsGenericSchemas,
} from '@helpers/builders/validators/sharedSchemasAddress'
import { capitalize, mainNumPatternForms } from '@helpers/display'
import { Address } from '@models/Address'
import * as yup from 'yup'
import { TestContext } from 'yup'

type FormAddress = Partial<Omit<Address, 'coordinate'>>

/*
 * Yup validation for address fields
 * If any of the address fields are filled, all of them must be filled
 */
export interface CustomerDetailFormType extends FormAddress {
  firstName: string
  lastName: string
  email: string
  email2?: string
  phoneNumber?: string
  pronouns?: string
  notes?: string
}

/**
 * Yup validation for address fields
 * If any of the address fields are filled, all of them must be filled
 */
const isAddressRequiredTest = (
  fieldName: keyof CustomerDetailFormType,
  schemaIfDefined: yup.Schema,
): [string, string, () => boolean] => {
  return [
    fieldName,
    /** I'm setting this error message to "is invalid", instead of "is required" because when the field schema runs (I.e. when the value is defined) it may reject the value for a reason that will not be the "required" scenario, but the real reason won't show at this level anyway, so it would be an awkward situation for example, to enter an invalid state with a misspelling and be told "state is required". In a case like that the more generic message of "is invalid" is more useful. */
    `${capitalize(fieldName)} is invalid`,
    function (this: TestContext) {
      // formParent accesses the parent of the form and can use the form's values
      const formParent = this.parent as CustomerDetailFormType

      // If any of the address fields are filled, all of them must be filled
      const shouldRequire = formParent.street1 || formParent.city || formParent.state || formParent.zipcode

      const value = formParent[fieldName]

      if (!value) {
        return !shouldRequire
      }

      return schemaIfDefined.isValidSync(formParent[fieldName], this.options)
    },
  ]
}

export const customerDetailSchema: yup.ObjectSchema<CustomerDetailFormType> = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().email('Email is invalid').required('Email is required'),
  email2: yup.string().email('Email2 is invalid'),
  phoneNumber: yup.string().matches(mainNumPatternForms, 'Please enter a valid phone number.').label('Phone Number'),
  pronouns: yup.string(),

  // This schema isn't using the reusable address schema for forms because in this form we want the address fields to be required only if one address field is filled.
  // If an address field is filled, we will enforce the regular field validation from the address schema
  // If all the address fields are empty the address fields should all be optional
  street1: yup.string().test(...isAddressRequiredTest('street1', addressFieldsGenericSchemas.street1)),
  street2: yup.string(),
  city: yup.string().test(...isAddressRequiredTest('city', addressFieldsGenericSchemas.city)),
  zipcode: yup.string().test(...isAddressRequiredTest('zipcode', ZipCodeSchemaCountry)),

  state: yup.string().test(...isAddressRequiredTest('state', StateSchemaCountry({ isFreeInput: true }))),
  country: CountryCodeSchema,
  notes: yup.string(),
})
