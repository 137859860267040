import { listPaymentMethods, removePaymentMethod } from '@api/Payments'
import { ConsumerScroll } from '@components'
import { Alert, Loader, LoadingView, Modal, Picker, Toast } from '@elements'
import { errorToString, nonEmptyString } from '@helpers/helpers'
import {
  isAchPayment,
  isEbtPayment,
  isFortisPayment,
  isStripePayment,
  PaymentForms,
  PaymentMethod,
} from '@models/PaymentMethod'
import { FarmAssociation, Role } from '@models/User'
import { AddPaymentMethod } from '@screens/PaymentMethods/AddPaymentMethod/AddPaymentMethod'
import React, { useCallback, useMemo, useState } from 'react'
import { StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'

import { withAuth } from '@/hooks/withAuth'
import { Logger } from '../../config/logger'
import Colors from '../../constants/Colors'
import { useApiFx } from '../../hooks/useApiFx'
import { RootState } from '../../redux/reducers/types'
import { farmsSelector, internationalSelector, userSelector } from '../../redux/selectors'
import { AddPaymentMethodButton } from '../PaymentMethods/AddPaymentMethod/AddPaymentButtons'
import { PaymentButton } from '../PaymentMethods/PaymentButton'

const filterPaymentsByProcessor = (payments: PaymentMethod[], isFortis: boolean) => {
  return payments.filter((payment) => {
    // Always show EBT payments regardless of processor
    if (isEbtPayment(payment)) return true

    if (isFortis) {
      return isFortisPayment(payment) || isAchPayment(payment)
    } else {
      return isStripePayment(payment)
    }
  })
}

const getFarmPickerItems = (farms: FarmAssociation[]) => {
  return farms.map((farmAssociation) => ({
    label: farmAssociation.name,
    value: farmAssociation.id,
  }))
}

/** Payments screen accessed from user profile */
function PaymentsScreenComp() {
  const { currency } = useSelector(internationalSelector)
  const user = useSelector(userSelector)
  const farms = useSelector<RootState, FarmAssociation[]>(farmsSelector).filter((fa) => fa.role !== Role.Prospect)
  const [selectedFarm, setSelectedFarm] = useState<FarmAssociation | null>(farms[0] ?? null)

  const payMethods = useApiFx(listPaymentMethods, [user.id], nonEmptyString(user.id))
  const paymentsWithoutCash = useMemo(() => {
    return payMethods.data?.filter((p) => p.token !== 'cash') ?? []
  }, [payMethods.data])
  const shouldUseFortis = useMemo(() => !!selectedFarm?.farm?.fortis?.isEnabled, [selectedFarm])

  const filteredPayments = useMemo(() => {
    if (!selectedFarm) return paymentsWithoutCash

    return filterPaymentsByProcessor(paymentsWithoutCash, shouldUseFortis)
  }, [selectedFarm, paymentsWithoutCash, shouldUseFortis])

  const handleRemovePayment = useCallback(
    async (pm: PaymentMethod) => {
      if (pm.type === PaymentForms.CASH) {
        Toast('Cannot remove this payment method')
        return
      }

      Alert('Remove Payment Method', 'Are you sure you want to remove this payment method?', [
        {
          text: 'Cancel',
          style: 'cancel',
        },
        {
          text: 'Remove',
          onPress: async () => {
            try {
              Loader(true)
              await removePaymentMethod(pm, user.id)
              payMethods.refresh()
              Toast('Removed payment method')
            } catch (e) {
              Logger.debug(e)
              Alert(
                'Unable to remove your payment method',
                `An error occurred, please try again later. ${errorToString(e)}`,
              )
            } finally {
              Loader(false)
            }
          },
        },
      ])
    },
    [payMethods, user.id],
  )

  const handleAddPaymentMethod = useCallback(() => {
    if (!selectedFarm) {
      Toast('Please select a farm first')
      return
    }

    Modal(
      <AddPaymentMethod
        userId={user.id}
        onDismiss={payMethods.refresh}
        ebtEnabled
        hasEbtCard={payMethods.data?.find(isEbtPayment) !== undefined}
        useFortis={shouldUseFortis}
        farmId={selectedFarm.id}
        currency={currency}
      />,
      {
        noPadding: true,
        title: `Enter your card details`,
      },
    )
  }, [payMethods.data, payMethods.refresh, selectedFarm, user.id, shouldUseFortis, currency])

  const handleFarmChange = useCallback(
    (farmId: string) => setSelectedFarm(farms.find((f) => f.id === farmId) ?? null),
    [farms],
  )

  return (
    <ConsumerScroll>
      <Picker
        value={selectedFarm?.id}
        onValueChange={handleFarmChange}
        items={getFarmPickerItems(farms)}
        style={styles.picker}
      />
      <LoadingView loading={payMethods.loading}>
        {filteredPayments.map((pay: PaymentMethod) => (
          <PaymentButton
            key={pay.id}
            payment={pay}
            onPress={handleRemovePayment}
            isSelected={false}
            isEditable
            style={styles.paymentButtons}
          />
        ))}
        <AddPaymentMethodButton onPress={handleAddPaymentMethod} style={styles.paymentButtons} />
      </LoadingView>
    </ConsumerScroll>
  )
}

export const PaymentsScreen = withAuth(PaymentsScreenComp, { noSafeAreaInsets: true })

const styles = StyleSheet.create({
  paymentButtons: {
    borderColor: Colors.shades[200],
    borderWidth: 1,
    borderRadius: 5,
  },
  picker: {
    marginBottom: 16,
  },
})
