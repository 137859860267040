import { StateZipInput } from '@/admin/screens/LocationsAndZones/StateZipsInput'
import { Logger } from '@/config/logger'
import { globalStyles } from '@/constants/Styles'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { adminCurrencySelector, adminFarmIdSelector, adminFarmSelector } from '@/redux/selectors'
import { addProductFee, setProductFee } from '@api/ProductFees'
import {
  ErrorText,
  FormButton,
  FormDisplayRow,
  FormInput,
  FormInputLabel,
  FormMoneyInput,
  FormPickerInput,
  Icon,
  KeyboardAvoidingScrollView,
  Text,
  Toast,
  hideModal,
} from '@elements'
import { YUP_MONEY_REQUIRED, YUP_WHOLE_NUMBER_REAL } from '@helpers/Yup'
import { productFeeBuilder } from '@helpers/builders'
import { productFeeSchema } from '@helpers/builders/ProductFeeBuilder'
import DecimalCalc from '@helpers/decimal'
import { getPostalCodeKind, getStateKind, plural } from '@helpers/display'
import { errorToString } from '@helpers/helpers'
import { isMoney } from '@helpers/money'
import { RegionType } from '@models/Location'
import { MoneyWithCurrency } from '@models/Money'
import {
  FeeType,
  FeeValueType,
  NoneValue,
  ProductFee,
  isFixedProductFee,
  isPercentProductFee,
} from '@models/ProductFee'
import { Formik, FormikProps } from 'formik'
import { memo, useMemo, useState } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import * as yup from 'yup'
import { InferType } from 'yup'

const feeTypesPickerItems = [
  { label: 'Tax', value: FeeType.Tax },
  { label: 'Fee', value: FeeType.Fee },
]

/** Schema for add/edit productFee form */
const validationSchema = productFeeSchema.concat(
  yup.object().shape({
    /** form doesn't have to validate ID. */
    id: yup.string().optional(),
    /** This field schema in the form is different from the DB one. Here, we need to modify the schema to fit the form usage. This is used to validate value from FormInput which has it as a string. */
    value: yup
      .mixed<string | MoneyWithCurrency>()
      .defined()
      .when('type', ([type]) => {
        if (type === FeeType.Tax)
          return YUP_WHOLE_NUMBER_REAL('Value', { allowDecimal: true }).max(100, 'Cannot be greater than 100%')
        else if (type === FeeType.Fee) return YUP_MONEY_REQUIRED('Value', { requireCurrency: true })
        throw new Error('Wrong fee type')
      }),
  }),
)

type FormType = InferType<typeof validationSchema>

/** Props for AddEditProductFee component */
type AddEditProductFeeProps = {
  /** If defined, it's in edit mode */
  productFee?: ProductFee
}

/** Modal component used for editing and creating product fees */
export const AddEditProductFee = memo(function AddEditProductFee({ productFee }: AddEditProductFeeProps) {
  const [error, setError] = useState('')
  const styles = useStyles()
  const farmId = useSelector(adminFarmIdSelector)
  const { address } = useSelector(adminFarmSelector)
  // If the productFee exists then we are editing, otherwise we are adding
  const isEdit = !!productFee?.id

  const initialValues: FormType = {
    id: productFee?.id,
    farm: productFee?.farm ?? { id: farmId ?? '' },
    name: productFee?.name ?? '',
    type: productFee?.type ?? FeeType.Tax,
    value: getInitialFormFeeValue(productFee),
    valueType: productFee?.valueType ?? FeeValueType.Percent,
    regionType: productFee?.regionType ?? NoneValue,
    regions: productFee?.regions ?? [],
    archived: productFee?.archived ?? false,
  }

  const locationTypePickerItems = useMemo(
    () => [
      { label: address.country === 'CA' ? 'Province' : 'State', value: RegionType.State },
      { label: address.country === 'CA' ? 'FSA Code' : 'Zip code', value: RegionType.Zipcode },
    ],
    [address],
  )

  /** submit handler to add and update product fee.*/
  const onSubmitHandler = async (values: FormType) => {
    if (error) setError('')
    if (!farmId) return setError('Could not load your farm, please reload and try again.')

    try {
      // This is the value that will be saved to the DB. It must be parsed and transformed however is necessary to conform to the product fee model requirements
      let value: ProductFee['value'] = 0

      if (isPercentProductFee(values as ProductFee)) {
        // We use FormInput to allow unlimited decimal, so the value will be a string and we have to parse it to a number
        const valueParsed = parseFloat(values.value as string)
        // Must divide by 100 because the DB model has this as a decimal from zero to one
        value = DecimalCalc.divide(valueParsed, 100)
      } else {
        value = values.value as MoneyWithCurrency
      }

      // This is the resulting form data after transforms. But it first needs to be validated
      const partialFee: Partial<ProductFee> = {
        ...values,
        value,
      }

      if (isEdit) {
        const newProductFee = productFeeBuilder.validate(partialFee)
        await setProductFee(newProductFee)
      } else {
        await addProductFee(partialFee)
      }

      hideModal()
      Toast(`This ${values.type} has been ${isEdit ? 'updated' : 'added'} successfully`)
    } catch (e) {
      Logger.error(errorToString(e))
      setError(`Unable to save ${values.type}: Please try again later. If the problem persists, contact support.`)
    }
  }
  const currency = useSelector(adminCurrencySelector)

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmitHandler} validationSchema={validationSchema}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }: FormikProps<FormType>) => (
        <KeyboardAvoidingScrollView>
          <FormInput
            label={<FormInputLabel required label="Name" />}
            placeholder="County tax or flat fee"
            errorMessage={touched.name ? errors.name : ''}
            value={values.name}
            onChangeText={(val) => setFieldValue('name', val)}
            onBlur={handleBlur('name')}
          />
          <FormDisplayRow>
            <FormPickerInput
              label={<FormInputLabel label="Type" required />}
              placeholder={null}
              disabled={isEdit}
              items={feeTypesPickerItems}
              value={values.type}
              onValueChange={(val) => {
                handleChange('type')(val)
                // Also update the valueType when the main type changes
                if (val === FeeType.Tax) {
                  setFieldValue('valueType', FeeValueType.Percent)
                } else {
                  setFieldValue('valueType', FeeValueType.Fixed)
                }
              }}
              useWebNativePicker
            />
            {values.type === FeeType.Tax && (
              <FormInput
                row
                label={<FormInputLabel required label="Rate" />}
                value={!values.value || typeof values.value !== 'string' ? '' : values.value}
                placeholder="20"
                keyboardType="decimal-pad"
                onChangeText={handleChange('value')}
                onBlur={handleBlur('value')}
                rightIcon={<Icon name="percent" />}
                rightIconContainerStyle={styles.iconContainer}
                errorMessage={touched.value ? errors.value : ''}
              />
            )}
            {values.type === FeeType.Fee && (
              <FormMoneyInput
                row
                label={<FormInputLabel required label="Rate" />}
                maxLength={11}
                value={isMoney(values.value) ? values.value : undefined}
                onChangeText={(val) => setFieldValue('value', val)}
                onBlur={handleBlur('value')}
                errorMessage={touched.value ? errors.value : ''}
                currency={currency}
              />
            )}
          </FormDisplayRow>
          <View style={globalStyles.marginHorizontal10}>
            <Text size={14} type="medium">
              Limit to certain locations
            </Text>
            <Text>{`You can specify ${plural(2, getStateKind(address.country))} or ${plural(
              2,
              address.country === 'CA' ? 'FSA code' : getPostalCodeKind(address.country),
            )} for this tax or flat fee. Leave blank to apply to all.`}</Text>
          </View>
          <FormPickerInput
            label="Location Type"
            placeholder={{ label: 'Not selected', value: NoneValue }}
            items={locationTypePickerItems}
            value={values.regionType}
            onValueChange={(val) => {
              setFieldValue('regionType', val)
              // Reset regions when regionType is changed
              setFieldValue('regions', [])
            }}
            containerStyle={styles.locationPicker}
            useWebNativePicker
          />
          {values.regionType !== NoneValue && (
            <StateZipInput
              country={address.country}
              values={values.regions}
              containerStyle={globalStyles.marginHorizontal10}
              inputContainerStyle={styles.stateZipInputContainer}
              label="Location"
              onUpdate={(val) => setFieldValue('regions', val)}
              onBlur={handleBlur('regions')}
              // When the region type is postal code and the farm is in Canada it should use FSA instead of postal code
              type={values.regionType === RegionType.Zipcode && address.country === 'CA' ? 'FSA' : values.regionType}
              errorMessage={touched.regions ? (errors.regions as string) : ''}
            />
          )}
          <FormButton
            title="Save"
            onPress={handleSubmit}
            loading={isSubmitting}
            disabled={isSubmitting}
            style={styles.formButton}
          />
          {!!error && <ErrorText>{error}</ErrorText>}
        </KeyboardAvoidingScrollView>
      )}
    </Formik>
  )
})

const useStyles = () =>
  useSizeFnStyles(({ isExtraSmallDevice }) => ({
    iconContainer: {
      marginVertical: 0,
    },
    stateZipInputContainer: {
      marginRight: 20,
    },
    locationPicker: {
      width: isExtraSmallDevice ? '100%' : '40%',
    },
    formButton: {
      marginTop: 15,
      alignSelf: 'flex-end',
    },
  }))

/** local helper to get correct and formatted initial productFee value for the fee form. */
const getInitialFormFeeValue = (productFee: ProductFee | undefined): FormType['value'] => {
  // This allows the default to be blank instead of 0
  if (!productFee) return ''
  else if (isPercentProductFee(productFee)) return DecimalCalc.multiply(productFee.value, 100).toString()
  else if (isFixedProductFee(productFee)) return productFee.value

  Logger.error('getInitialFormFeeValue: Cannot get initial value for product fee')
  return ''
}
