import { globalStyles } from '@/constants/Styles'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { adminCurrencySelector } from '@/redux/selectors'
import { ButtonClear, FormInputLabel, FormMoneyInput } from '@elements'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { BuyingOptionSectionProps } from '../../AdvancedPricing-helpers'

/** Buying option inputs used for Farm Balance*/
export function FarmBalanceBuyingOption({
  formik: { values: boValues, setFieldValue, showSimpleError, handleBlur },
  arrayHelpers,
  index,
  formValues,
}: BuyingOptionSectionProps) {
  const isFirst = index === 0
  const canDeleteBo = formValues.buyingOptions.length > 1
  const styles = useStyles()
  const currency = useSelector(adminCurrencySelector)

  return (
    <View style={globalStyles.flexRow}>
      <View style={styles.buyingOptCont}>
        <FormMoneyInput
          // This component uses prices[0] because a farm-balance product is expected to be retail only so there would be a single price
          value={boValues.prices[0].amount}
          label={isFirst ? <FormInputLabel label="Credit options" required /> : null}
          onChangeText={async (value) => {
            await Promise.all([
              setFieldValue('multiplier', 1),
              setFieldValue('prices[0].amount', value),
              setFieldValue('name', value ? `$${value.value / 100}` : ''),
            ])
          }}
          errorMessage={showSimpleError('prices[0].amount')}
          onBlur={handleBlur('prices[0].amount')}
          currency={currency}
        />
      </View>
      <ButtonClear
        disabled={!canDeleteBo}
        icon="times"
        style={styles.removeBuyingOptBtn}
        size={14}
        onPress={() => {
          arrayHelpers.remove(index)
        }}
      />
    </View>
  )
}

const useStyles = () =>
  useSizeFnStyles(({ isSmallDevice }) => ({
    removeBuyingOptBtn: {
      alignSelf: 'flex-end',
      height: 41.5, // matches the input height in order to be centered
      width: 41.5,
      marginBottom: 15,
    },
    buyingOptCont: isSmallDevice
      ? {
          flexDirection: 'column',
          flex: 1,
          marginVertical: 5,
        }
      : {
          alignItems: 'center',
          marginVertical: 5,
          flex: 1,
          flexDirection: 'row',
          flexWrap: 'wrap',
        },
  }))
