import { MessageWithIcon } from '@components'
import React from 'react'

/** UI component that displays a message indicating that the user does not have permission to access specific page */
export function PermissionDenied() {
  return (
    <MessageWithIcon icon="user-times" title="Permission denied">
      You don't have permission to access this page.
    </MessageWithIcon>
  )
}
