import { HeaderText } from '@elements'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { getDepositRange, readableCancellation } from '@helpers/display'
import { hyperlinkOpenUrl } from '@helpers/links'
import { getReadableEbtEligibility } from '@helpers/products-display'
import { Farm } from '@models/Farm'
import { Product, isPrimary, isStandard } from '@models/Product'
import { memo } from 'react'
import { View } from 'react-native'
import HyperLink from 'react-native-hyperlink'
import { useSelector } from 'react-redux'
import { Divider } from '../../../components/elements/Divider'
import ReadMore from '../../../components/elements/ReadMore'
import { wholesaleSelector } from '../../../redux/selectors'
import FarmSnippet from '../FarmSnippet'
import { AvailabilitySection } from './AvailabilitySection'
import { DetailItem } from './DetailItem'
import { styles } from './ProductDetails'
import { estimateNPickups, getMinCutoffWindowText } from './helpers'

export const Details = memo(function Details({ product, farm }: { product?: Product; farm?: Farm }) {
  const { isWholesale } = useSelector(wholesaleSelector)

  if (!product) return null

  const nPickupsEstimate = estimateNPickups(product, isWholesale)

  return (
    <View>
      <HeaderText>Description</HeaderText>
      <HyperLink linkStyle={styles.blue} onPress={hyperlinkOpenUrl}>
        <ReadMore>{product.longDescription}</ReadMore>
      </HyperLink>
      <Divider large clear />
      <HeaderText>Details</HeaderText>
      <AvailabilitySection product={product} />
      <DetailItem name="Category">{product.category || 'None'}</DetailItem>
      {isStandard(product) && !!product.producer && <DetailItem name="Producer">{product.producer}</DetailItem>}
      {!!product.productionMethod?.length && (
        <DetailItem name="Production method">{product.productionMethod}</DetailItem>
      )}
      {isStandard(product) && (
        <DetailItem name="Cancellation Policy">{getMinCutoffWindowText(product, isWholesale)}</DetailItem>
      )}
      {isStandard(product) && farm?.paymentTypes.ebt && (
        <DetailItem name="SNAP Eligibility">{getReadableEbtEligibility(product.ebtEligibility)}</DetailItem>
      )}
      {isPrimary(product) && (
        <>
          {!!nPickupsEstimate && <DetailItem name="Number of shares">{nPickupsEstimate}</DetailItem>}
          {!!product.shareInfo.suggestedFamilySize && (
            <DetailItem name="Family size">{product.shareInfo.suggestedFamilySize}</DetailItem>
          )}
          <DetailItem name="Payment options">
            {product.paymentSchedules
              .map((payment) => payment.frequency.toLowerCase())
              .join(', ')
              .replace('once', 'Pay in full')}
          </DetailItem>
          {product.shareInfo.minimumNumberOfItems !== undefined &&
            product.shareInfo.maximumNumberOfItems !== undefined && (
              <DetailItem name="Number of items">{`${product.shareInfo.minimumNumberOfItems} - ${product.shareInfo.maximumNumberOfItems}`}</DetailItem>
            )}
          {product.vacationWeeks !== undefined && (
            <DetailItem name="Vacation weeks">{product.vacationWeeks}</DetailItem>
          )}
          <DetailItem name="Cancellation policy">{readableCancellation(product.cancellationPolicy)}</DetailItem>
          {getDepositRange(product) !== '$0.00' && <DetailItem name="Deposit">{getDepositRange(product)}</DetailItem>}
        </>
      )}
      <Divider clear large />
      <FarmSnippet farm={farm} />
    </View>
  )
}, propsAreDeepEqual)
