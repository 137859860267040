import { isNonNullish } from '@helpers/helpers'
import { FarmAssociation, Role } from '@models/User'

/** The Permission Category */
export enum Permission {
  /** AccountSetup permission
   * - Admin Page : Welcome Page, Sales Report ,Analytics, Farm Profile Page ,Payments & EBT Page, Integrations Page
   */
  AccountSetup = 'AccountSetup',
  /** Accounting permission
   * - Admin Page :  Farm Info Page(Payouts, stripe section)
   */
  Accounting = 'Accounting',
  /** ProductSetup permission
   * - Admin Page : Locations & Zones, Schedules, CSA Groups, Products, Promotions
   */
  ProductSetup = 'ProductSetup',
  /** Orders permission
   * - Admin Page : Customers, Orders, Invoices
   * - Tips: Orders permission with 'edit' access right is used to limit the actions across Customers, Orders, and Invoices page. For examples:'Create Order' and 'Mark invoice as paid' */
  Orders = 'Orders',
  /** Distributions permission
   * - Admin Page : Distributions
   */
  Distributions = 'Distributions',
  /** Messaging permission
   * Admin Page: Messaging screen
   */
  Messaging = 'Messaging',
}

/** Enum representing different levels of access rights. */
export enum AccessRight {
  /** Grants permission to view the page or component */
  View = 'view',
  /** Grants permission to edit the page or component, includes view permissions. */
  Edit = 'edit',
  /**  Denies any access to the page or component i.e. deny message access for some admins */
  Prohibited = 'prohibited',
}

/** SecurityCheck (default Permissions Check) shows all the permission with access right on each role. (If the access right does not exist on certain role at all, it means that role has no permission to access at all.)
 * This is a reusable standard security check, can be used for most of the admin pages. (From admin page down to the component level)
 * - view: means that the role can access the page or component
 * - edit: means that the role can access the page or component (same as view), but can also edit the page or component (if the page or component has 'edit' permission level).
 For example: If some actions or components require permission level is 'edit', then the role must have 'edit' access right to conduct any actions on page or component, but if the permission level is 'view', then the role can have free access right to access the page or component. (The accessRight 'edit' is a higher level than 'view', and 'view' is a higher level than undefined. The 'edit' right can limit the action of the role for the page or component.)
 */
export const SecurityCheck: Record<Permission, Partial<Record<Role, AccessRight.View | AccessRight.Edit>>> = {
  [Permission.AccountSetup]: {
    [Role.Manager]: AccessRight.View,
    [Role.Admin]: AccessRight.View,
  },
  [Permission.Accounting]: {
    [Role.Manager]: AccessRight.View,
    [Role.Admin]: AccessRight.View,
    [Role.Accountant]: AccessRight.View,
  },
  [Permission.ProductSetup]: {
    [Role.Manager]: AccessRight.Edit,
    [Role.Admin]: AccessRight.Edit,
  },
  [Permission.Orders]: {
    [Role.Manager]: AccessRight.Edit,
    [Role.Admin]: AccessRight.Edit,
    [Role.Distributor]: AccessRight.View,
    [Role.Accountant]: AccessRight.View,
    [Role.Seller]: AccessRight.Edit,
  },
  [Permission.Distributions]: {
    [Role.Manager]: AccessRight.View,
    [Role.Admin]: AccessRight.View,
    [Role.Distributor]: AccessRight.View,
  },
  [Permission.Messaging]: {
    [Role.Manager]: AccessRight.Edit,
    [Role.Admin]: AccessRight.Edit,
  },
}

/**
 * This is the type for role and custom permission.
 * @param role: role for current user
 * @param permissions: The custom permission for current user (this can overwrite the default permission rule)
 */
export type RolePermissions = { role: FarmAssociation['role']; permissions: FarmAssociation['customPermissions'] }

/** This function will be used to check permission to access anything in admin side that need to have security check to pass. */
export function hasFarmAdminPermission(
  roleData: RolePermissions | undefined,
  permission: Permission | undefined,
  type: AccessRight = AccessRight.View,
): boolean {
  // If roleData does not exist, then it will not have any permission to access anything in admin side until the roleData exist.
  if (!isNonNullish(roleData) || !isNonNullish(roleData.role)) return false

  // If the Role is Prospect or Customer, then it will not have any permission to access anything in admin side.
  if (roleData.role === Role.Prospect || roleData.role === Role.Customer) return false

  // If the permission doesn't exist, then no permission check is needed. Permission is granted.
  if (!isNonNullish(permission)) return true

  // Return false if access is Prohibited
  if (roleData.permissions?.[permission] === AccessRight.Prohibited) return false

  //TODO: Should have custom permission for each role (should have this later)
  if (roleData.permissions?.[permission] === type) return true
  else if (roleData.permissions?.[permission] === AccessRight.Edit && type === AccessRight.View) return true

  /** Check if the role pass the permission */
  if (!isNonNullish(SecurityCheck[permission][roleData.role])) return false
  else if (SecurityCheck[permission][roleData.role] === type) return true
  else if (SecurityCheck[permission][roleData.role] === AccessRight.Edit && type === AccessRight.View) return true
  else return false
}

/** This will check if the role is manager. */
export const isManager = (roleData: RolePermissions | Role | undefined): boolean => {
  if (typeof roleData === 'string') return roleData === Role.Manager
  else return roleData?.role === Role.Manager
}
