import { globalStyles } from '@/constants/Styles'
import {
  FormButton,
  FormDateTimeInput,
  FormDisplayRow,
  FormInput,
  FormPickerInput,
  HeaderText,
  PickerProps,
} from '@elements'
import { isAfter, isBefore } from '@helpers/time'
import { DateRange } from '@models/Schedule'
import { BigQueryLogsRequest } from '@shared/types/v2/farmGenAdmin'
import { Formik, FormikProps } from 'formik'
import { DateTime } from 'luxon'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import * as Yup from 'yup'
import { BigQueryModal } from './components/BigQueryModal'

const collections: PickerProps['items'] = [
  { label: 'distributions', value: 'distributions' },
  { label: 'farms', value: 'farms' },
  { label: 'invoices', value: 'invoices' },
  { label: 'orders', value: 'orders' },
  { label: 'pickups', value: 'pickups' },
  { label: 'products', value: 'products' },
  { label: 'template_products', value: 'template_products' },
  { label: 'users', value: 'users' },
]

type FormType = {
  selectedCollection: BigQueryLogsRequest['collection'] | undefined
  dataId: string
  startDate: DateTime
  endDate: DateTime
}

const validationSchema: Yup.ObjectSchema<FormType> = Yup.object()
  .shape({
    selectedCollection: Yup.mixed<BigQueryLogsRequest['collection']>()
      .required('Please select a table')
      .test('is-collection-type', 'Please select a valid table', (value) =>
        collections.some((collection) => collection.value === value),
      ),
    dataId: Yup.string().required('Data ID is required'),
    startDate: Yup.mixed<DateTime>()
      .required('Start date is required')
      .test('valid-date', 'Start date must be before end date', function (value) {
        const { endDate }: { endDate: DateTime } = this.parent
        if (!value || !endDate) return true
        return isBefore(value, endDate, { orEqual: true })
      }),
    endDate: Yup.mixed<DateTime>()
      .required('End date is required')
      .test('valid-date', 'End date must be after start date', function (value) {
        const { startDate }: { startDate: DateTime } = this.parent
        if (!value || !startDate) return true
        return isAfter(value, startDate, { orEqual: true })
      }),
  })
  .defined()

/** This component allows admins to inspect data from BigQuery. */
export function FarmGenAdminDataInspector() {
  const initialValues: FormType = {
    selectedCollection: undefined,
    dataId: '',
    startDate: DateTime.now(),
    endDate: DateTime.now(),
  }

  const onSubmitHandler = async (values: FormType) => {
    if (!values.selectedCollection) return
    const dateRange: DateRange = {
      startDate: values.startDate,
      endDate: values.endDate,
    }

    BigQueryModal(values.selectedCollection, values.dataId, dateRange)
  }

  return (
    <View style={globalStyles.flex1}>
      <HeaderText>Data Inspector</HeaderText>
      <Formik initialValues={initialValues} onSubmit={onSubmitHandler} validationSchema={validationSchema}>
        {({ values, errors, touched, handleSubmit, setFieldValue, isSubmitting }: FormikProps<FormType>) => (
          <View style={styles.container}>
            <FormDisplayRow>
              <FormPickerInput
                label="Table"
                items={collections}
                value={values.selectedCollection}
                onValueChange={(value) => setFieldValue('selectedCollection', value)}
                placeholder="Select a table"
                errorMessage={touched.selectedCollection ? errors.selectedCollection : undefined}
              />
              <FormInput
                label="Data ID"
                placeholder="Enter Data ID"
                value={values.dataId}
                onChangeText={(value) => setFieldValue('dataId', value)}
                errorMessage={touched.dataId ? errors.dataId : undefined}
              />
            </FormDisplayRow>
            <FormDisplayRow>
              <FormDateTimeInput
                label="Start Date"
                value={values.startDate}
                onChange={(value) => setFieldValue('startDate', value)}
                timezone="America/New_York"
                mode="date"
                errorMessage={touched.startDate && errors.startDate ? String(errors.startDate) : undefined}
              />
              <FormDateTimeInput
                label="End Date"
                value={values.endDate}
                onChange={(value) => setFieldValue('endDate', value)}
                timezone="America/New_York"
                mode="date"
                errorMessage={touched.endDate && errors.endDate ? String(errors.endDate) : undefined}
              />
            </FormDisplayRow>
            <View style={styles.buttonContainer}>
              <FormButton title="Submit" onPress={handleSubmit} style={styles.minWidth} loading={isSubmitting} />
            </View>
          </View>
        )}
      </Formik>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 32,
    gap: 24,
    padding: 16,
  },
  buttonContainer: {
    alignItems: 'center',
    marginTop: 24,
  },
  minWidth: {
    minWidth: 200,
  },
})
