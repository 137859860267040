import { values } from '@helpers/typescript'
import { PaymentType } from '@models/Payment'
import { PaymentSchedule } from '@models/Product'
import * as Yup from 'yup'
import { YUP_MONEY_REQUIRED } from '../Yup'

export const paymentScheduleSchema: Yup.ObjectSchema<PaymentSchedule> = Yup.object({
  paymentType: Yup.mixed<PaymentType>()
    .oneOf(values(PaymentType) as PaymentType[])
    .defined(),
  frequency: Yup.string<PaymentSchedule['frequency']>().oneOf(['ONCE', 'WEEKLY', 'MONTHLY', 'PER-PICKUP']).defined(),
  paymentDates: Yup.object()
    .shape({
      startDate: Yup.mixed().isDateTime().optional(),
      endDate: Yup.mixed().isDateTime().defined(),
    })
    .defined(),
  amount: YUP_MONEY_REQUIRED('Schedule Amount', { allowZero: true, requireCurrency: true }),
  deposit: YUP_MONEY_REQUIRED('Deposit', { allowZero: true, requireCurrency: true }),
}).defined()
