import { EventBridge } from '../../eProtect/helpers/EventBridge'
import { DEFAULT_CONFIG } from './constants'
import { parseResponse } from './helpers'
import { FortisClientInterface, FortisEvent, FortisResponse } from './types'

/** This is the type for the Commerce script loaded for fortis elements*/
declare const Commerce: {
  elements: new (clientToken: string) => {
    create: (config: any) => void
    on: (event: string, callback: (result: any) => void) => void
    submit: () => Promise<void>
  }
}

/** This holds methods to handle events and actions in a universal way on native and web. */
export class FortisClientWrapper implements FortisClientInterface {
  private event: EventBridge<FortisEvent>
  private elements: any
  private readonly clientId: string

  constructor(clientId: string) {
    this.event = new EventBridge('fortis-response')
    this.clientId = clientId
  }

  async initialize() {
    return new Promise<void>((resolve) => {
      this.elements = new Commerce.elements(this.clientId)
      this.elements.create({
        container: '#fortis-payment',
        ...DEFAULT_CONFIG,
      })

      this.elements.on('done', this.event.dispatch.bind(this.event))
      this.elements.on('validationError', this.event.dispatch.bind(this.event))
      this.elements.on('error', this.event.dispatch.bind(this.event))
      this.elements.on('tokenExpired', this.event.dispatch.bind(this.event))
      this.elements.on('ready', () => {
        resolve(undefined)
      })
    })
  }

  async handleResponse(_: FortisEvent) {
    // NO-OP: This is a no-op on web
  }

  async submit(): Promise<FortisResponse> {
    // Send the request to the iframe to submit the form
    this.elements.submit()

    // Wait for results from the submit event and return the response
    const res = await this.event.listen(20000)
    return parseResponse(res)
  }
}
