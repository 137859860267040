import { Logger } from '@/config/logger'
import { adminFarmIdSelector } from '@/redux/selectors'
import { writeFarmAssociationRole } from '@api/FarmAssociations'
import { Button, CheckBox, Divider, hideModal, Loader, Toast, typography } from '@elements'
import { capitalize } from '@helpers/display'
import { userName } from '@helpers/user'
import { ManagerType } from '@models/Farm'
import { getRoleDescription, Role } from '@models/User'
import { useCallback, useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { useSelector } from 'react-redux'
import { useMessagingPermission } from './hooks/useMessagingPermission'

type Props = {
  manager: ManagerType
}

/** This component is used to change admin role by farm manager */
export function ChangeAdminRole({ manager }: Props) {
  const farmId = useSelector(adminFarmIdSelector)
  const [role, setRole] = useState<Role>(manager.farmAssociation.role || Role.Admin)
  const { hasMsgPermission, setHasMsgPermission, currCustomPermission } = useMessagingPermission({
    role,
    manager,
  })

  const onSubmitHandler = useCallback(async () => {
    try {
      hideModal()
      Loader(true)
      await writeFarmAssociationRole(farmId, manager.user.id, role, 'change', currCustomPermission)
      Loader(false)
      Toast(`Successfully assigned ${userName(manager.user)} as ${capitalize(role)}.`)
    } catch (err) {
      Loader(false)
      Toast('Error assigning role, please try again later.')
      Logger.error(err)
    }
  }, [manager.user, farmId, currCustomPermission, role])

  return (
    <View style={styles.container}>
      <View style={styles.subTitle}>
        <Text>
          You can adjust the role of each admin for your farm giving each person access to certain parts of GrownBy
          required for their duties.
        </Text>
      </View>
      <CheckBox title={capitalize(Role.Admin)} checked={role === Role.Admin} onChange={() => setRole(Role.Admin)} />
      <Text style={styles.roleHelperText}>{getRoleDescription(Role.Admin)}</Text>
      <CheckBox
        title={capitalize(Role.Distributor)}
        checked={role === Role.Distributor}
        onChange={() => setRole(Role.Distributor)}
      />
      <Text style={styles.roleHelperText}>{getRoleDescription(Role.Distributor)}</Text>
      <CheckBox
        title={capitalize(Role.Accountant)}
        checked={role === Role.Accountant}
        onChange={() => setRole(Role.Accountant)}
      />
      <Text style={styles.roleHelperText}>{getRoleDescription(Role.Accountant)}</Text>
      <Divider />
      <Text>Additional Resources</Text>
      <Divider clear />
      <CheckBox
        size={15}
        title="Messaging"
        titleStyle={styles.msgCheckboxTitle}
        checked={hasMsgPermission}
        onChange={() => setHasMsgPermission((curr) => !curr)}
      />
      <Text style={styles.roleHelperText}>
        Allow viewing farm messages and responding to messages on behalf of the farm
      </Text>

      {/* TODO: Will un comment when role seller is used in the future. */}
      {/*<CheckBox title={capitalize(Role.Seller)} checked={role === Role.Seller} onChange={() => setRole(Role.Seller)} />*/}
      {/*<Text style={styles.roleHelperText}>*/}
      {/*  {getRoleDescription(Role.Seller)}*/}
      {/*</Text>*/}
      <Button title="Save Change" style={styles.button} onPress={onSubmitHandler} />
      <Text>Changes will be reflected shortly after saving your changes</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 25,
  },
  subTitle: {
    marginBottom: 15,
  },
  button: {
    marginHorizontal: 0,
  },
  roleHelperText: {
    marginLeft: 35,
    marginBottom: 15,
  },
  msgCheckboxTitle: {
    fontFamily: typography.body.regular,
  },
})
