import { Logger } from '@/config/logger'
import { EbtIcon, makeTestIdPrefix } from '@components'
import { Text } from '@elements'
import { formatMoney, getPriceString } from '@helpers/display'
import { findPriceForAppMode, getUnits } from '@helpers/products'
import { Product, Unit, UnitProduct, hasUnits } from '@models/Product'
import { DataError } from '@shared/Errors'
import { memo, useCallback, useMemo } from 'react'
import { ExpandableRow, ExpandableRowProps } from '../../../../../components/OfflineTable/ExpandableRow'
import { AddBtnStandardProps } from '../AddCartButtons/StandardBtn'

type StandardRowProps_Reusable = {
  prod: Product
  index: number
  isWholesale: boolean
  AddCartBtn: React.FC<AddBtnStandardProps>
}

/** Reusable component for the standard prods of the order creator and order edit tables */
export const StandardRow_Reusable = memo(function StandardRow_Reusable({
  prod,
  index,
  isWholesale,
  AddCartBtn,
}: StandardRowProps_Reusable) {
  const genSubRows = useCallback<NonNullable<ExpandableRowProps<UnitProduct>['generateSubRows']>>(
    (p) => {
      // Get the BOs compatible with the current catalog mode
      const catalogBOs = getUnits(p, { isWholesale })

      return catalogBOs?.length
        ? // If there's at least one available BO the row will have a sub row for each
          catalogBOs.map((u, i) => (
            <ExpandableRow<Unit>
              item={u}
              columns={[
                { process: () => u.name, widthFlex: 2 },
                {
                  process: () => {
                    const price = findPriceForAppMode(u.prices, isWholesale)
                    if (!price) {
                      Logger.error(
                        new DataError(
                          'A catalog price was not found for a buying option expected to be available and compatible with the current catalog',
                          { unit: u, isWholesale },
                        ),
                      )
                    }
                    return price ? formatMoney(price.amount) : ''
                  },
                },
                { process: () => 'x' + u.multiplier },
                {
                  process: () => <AddCartBtn prod={p} unit={u} key={makeTestIdPrefix(p, u)} />,
                },
              ]}
              // This indent makes the first column of the sub row align with the first column of the parent row, despite the parent row having a chevron taking additional space to the left
              indent={30}
              index={i}
              key={`subRow-p.name:${p.name}_p.id:${p.id}_unit:${u.id}`}
            />
          ))
        : [
            <ExpandableRow
              item={null}
              columns={[{ process: () => 'No available buying options', widthFlex: 2 }, {}, {}, {}]}
              // This indent makes the first column of the sub row align with the first column of the parent row, despite the parent row having a chevron taking additional space to the left
              indent={30}
              index={0}
              key="subRow-p.name:n/a_p.id:n/a_unit:n/a"
            />,
          ]
    },
    [isWholesale, AddCartBtn],
  )

  const cols = useMemo<ExpandableRowProps<UnitProduct>['columns']>(
    () => [
      {
        process: (product: Product) => (
          <Text>
            <EbtIcon product={product} />
            {product.name}
          </Text>
        ),
        widthFlex: 2,
      },
      {
        process: (p) => getPriceString(p, { isWholesale }),
      },
      { process: (p) => p.baseUnit },
      {},
    ],
    [isWholesale],
  )

  if (!hasUnits(prod)) return null

  return <ExpandableRow item={prod} index={index} columns={cols} generateSubRows={genSubRows} expandInitially />
})
