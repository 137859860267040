import { Distribution, DistroLocation } from '@models/Distribution'
import { FarmStatus } from '@models/Farm'
import { LocationTypes } from '@models/Location'
import { ErrorTypes, ErrorWithCode } from '@shared/Errors'
import * as yup from 'yup'
import { YUP_WHOLE_NUMBER_REAL } from '../Yup'
import { hasOwnProperty } from '../helpers'
import { Builder } from './Builder'
import { LocationSchemaContext, locationSchema } from './LocationBuilder'
import { scheduleBuilder } from './index'
import { validateFromSchema } from './validators/helpers'

// We omit location and schedule as they are validated using their own validators
const distributionSchema = yup.object<Omit<Distribution, 'location' | 'schedule'>>().shape({
  id: yup.string().required(),
  name: yup.string().required(),
  farm: yup
    .object()
    .shape({
      id: yup.string().required(),
      name: yup.string().required(),
      status: yup.mixed<FarmStatus>().oneOf(Object.values(FarmStatus)).required(),
      timezone: yup.string().required(),
    })
    .required(),
  orderCutoffWindow: YUP_WHOLE_NUMBER_REAL('orderCutoffWindow', { allowZero: true }).required(),
  notes: yup.string().defined(),
  isHidden: yup.boolean().optional(),
  closed: yup.boolean().optional(),
  color: yup
    .string()
    .matches(/^#[0-9a-f]{6}$/i, 'color must be a valid hex color')
    .optional() as yup.Schema<any>,
})

// We are omitting farm from validation as distro.location should not have farm
const distributionLocationSchema: yup.ObjectSchema<DistroLocation> = locationSchema.shape({
  farm: yup.mixed().isUndefined(),
})

/**
 * This builder will create a distribution object */
export class DistributionBuilder extends Builder<Distribution> {
  constructor() {
    super('distribution')
  }

  build(distribution: Partial<Distribution>): Distribution {
    return this.validate(distribution)
  }

  validate(distribution: unknown): Distribution {
    if (
      !hasOwnProperty(distribution, 'schedule') ||
      !hasOwnProperty(distribution, 'location') ||
      !hasOwnProperty(distribution.location, 'type')
    ) {
      const missingData = !hasOwnProperty(distribution, 'schedule') ? 'schedule' : 'location'
      throw new ErrorWithCode({
        type: ErrorTypes.Validation,
        code: `missing-${missingData}`,
        devMsg: 'Data passed to validate distribution is not a valid type',
      })
    }
    const validatedSchedule = scheduleBuilder.validate(distribution.schedule)

    // The distribution location schema must use the location context because it depends on the location schema which needs the context
    const locationCtx: LocationSchemaContext = {
      type: distribution.location.type as LocationTypes,
    }
    const validatedLocation = validateFromSchema(distributionLocationSchema, distribution.location, {
      context: locationCtx,
    })

    return {
      ...validateFromSchema(distributionSchema, distribution),
      location: validatedLocation,
      schedule: validatedSchedule,
    }
  }
}
