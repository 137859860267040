import type { DocumentData } from 'firebase/firestore'

import { getShortState, parsePostalCodeGeneric } from '@helpers/address'
import { Address } from '@models/Address'
import { prepareMarshal, prepareUnmarshal } from './encoding'

/** Identifies a country string as referring to the US */
const isUS = (country: string): boolean => {
  const pattern = new RegExp(/\b(?:United States|US|USA|U\.?S\.?|U\.?S\.?A\.?|America|Am\.?|U\.?S\.? of A\.?)\b/i)

  return pattern.test(country)
}

/** Converts the data provided by the Firestore document into the structure expected by the Address model. */
export function unmarshalAddress(incomingData: DocumentData): Address {
  const [_, data] = prepareUnmarshal<Partial<Address>>('', incomingData)

  const address = { ...data } as Address

  if (!address.country || isUS(address.country)) {
    // If the country is missing we will add the US country code
    // This should be removed once we do a migration to fill the missing country data
    // This happened because our Address model previously had the country as optional but it shouldn't be necessary for new data
    address.country = 'US'
  }

  if (address.state) {
    /** The data is currently bad because:
     * - Some models say the state should be in the short code form, but some forms may have allowed saving the state in its long form as well.
     * - The models for states have the enum as upper case, but both lower and upper case may have been allowed over time.
     * So this is touching up the data so the abbreviated state code is used always, as the model would indicate */
    address.state = getShortState(address.state, address.country) || 'invalid state'
  }

  if (address.zipcode) {
    /**
     * - This should not be necessary but some code in the app used to rely on an old display helper that would format the zipcode value from a possible number to a string. That is no longer necessary and it is not used anymore. But the data might still have some zipcodes as numbers
     */
    address.zipcode = typeof address.zipcode === 'number' ? (address.zipcode as number).toString() : address.zipcode

    if (address.country === 'US') {
      /** This should not be necessary but the previous presence of formatZipcode() (now gone) suggests this may have been necessary at some point and perhaps some old US addresses might need this padStart */
      address.zipcode = address.zipcode.padStart(5, '0')
    }

    // Just in case there's any other anomalies with the zip code data
    address.zipcode = parsePostalCodeGeneric(address.zipcode)
  }

  return address
}

/** marshalUserAddress converts the address structure into a Firebase document structure. */
export function marshalAddress(address: Partial<Address>): DocumentData {
  return prepareMarshal(address)
}
