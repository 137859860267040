import { MessageWithIcon } from '@components'
import { hideModal } from '@elements'
import { PaymentForms, PaymentMethod } from '@models/PaymentMethod'
import { useCallback, useMemo, useState } from 'react'
import { StyleSheet, View } from 'react-native'

import { isNonNullish } from '@helpers/helpers'
import { CurrencyCode } from '@models/Money'
import ButtonGroup from '../../../components/elements/ButtonGroup'
import { AddEbtCard } from './AddEbtCard'
import { AddFortisPayment } from './AddFortisPayment'
import { AddStripePayment } from './AddStripePayment'

export type AddPaymentMethodProps = {
  /** Called on successful creation */
  onDismiss: (method?: PaymentMethod) => void
  ebtEnabled?: boolean
  hasEbtCard?: boolean
  userId: string
  farmId: string
  /** If we pass a certain payment method, then we restrict the form to that type*/
  paymentForm?: PaymentForms
  /** If we should use Fortis instead of Stripe for Online payment processing*/
  useFortis: boolean
  currency: CurrencyCode
}

/** Modal for adding any type of payment method */
export function AddPaymentMethod({
  onDismiss,
  ebtEnabled,
  hasEbtCard,
  userId,
  farmId,
  paymentForm,
  useFortis,
  currency,
}: AddPaymentMethodProps) {
  const [currIndex, updateIndex] = useState(
    paymentForm === PaymentForms.BANK ? 1 : paymentForm === PaymentForms.EBT ? 2 : 0,
  )
  const buttons = [
    'Credit Card',
    currency === 'usd' ? 'Bank' : undefined,
    ebtEnabled && currency === 'usd' ? 'EBT/ SNAP' : undefined,
  ].filter(isNonNullish)

  const onSubmit = useCallback(
    (payMethod?: PaymentMethod) => {
      hideModal()
      onDismiss(payMethod)
    },
    [onDismiss],
  )

  const PaymentComponent = useMemo(() => (useFortis ? AddFortisPayment : AddStripePayment), [useFortis])

  return (
    <View style={styles.container}>
      {!paymentForm && <ButtonGroup onSelect={updateIndex} selectedIndex={currIndex} buttons={buttons} />}

      {currIndex === 0 ? (
        <PaymentComponent
          userId={userId}
          farmId={farmId}
          onDismiss={onSubmit}
          paymentMethod={PaymentForms.CARD}
          currency={currency}
        />
      ) : currIndex === 1 ? (
        <AddStripePayment userId={userId} onDismiss={onSubmit} paymentMethod={PaymentForms.BANK} currency={currency} />
      ) : hasEbtCard ? (
        <View style={styles.minHeight}>
          <MessageWithIcon title="Can't add EBT card" icon="id-card">
            Only one EBT card can be added to your account at a time. To add a new card, first delete your current
            EBT/SNAP card in your Account &rarr; Payment Methods page.
          </MessageWithIcon>
        </View>
      ) : (
        <AddEbtCard userId={userId} onDismiss={onSubmit} />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  minHeight: { minHeight: 300 },
  container: {
    marginHorizontal: 20,
    marginBottom: 20,
  },
})
