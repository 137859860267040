import { Logger } from '@/config/logger'
import { acceptFarmUserInvite, loadFarmUserInvite } from '@api/ExternalLinks'
import { loadFarm } from '@api/Farms'
import { Alert, HeaderText, Icon, SafeAreaView, Text } from '@elements'
import { capitalize, formatAddressCityState } from '@helpers/display'
import { userName } from '@helpers/user'
import { InviteType, UserInvite } from '@models/ExternalLink'
import { Farm } from '@models/Farm'
import { dateTimeInZone } from '@models/Timezone'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { Image } from '../../components/Image'
import LoaderWithMessage from '../../components/LoaderWithMessage'
import { Button } from '../../components/elements/Button'
import Colors from '../../constants/Colors'
import { globalStyles } from '../../constants/Styles'
import { withAuth } from '../../hooks/withAuth'
import { ExternalLinkingParamList } from '../../navigation/types'
import { userSelector } from '../../redux/selectors'

type ErrorType = {
  title: string
  icon: string
  message: string
}

const Errors: { [keys: string]: ErrorType } = {
  expired: {
    title: 'Expired Invitation',
    icon: 'user-clock',
    message: 'This invite has expired, please contact your farm administrator to get a new invite.',
  },
  email: {
    title: 'Invalid Email',
    icon: 'user-shield',
    message:
      'This invite is intended for a different user. Please make sure you are signed in with the account this invite was sent to, or request a new invite.',
  },
  invalid: {
    title: 'Invalid Invitation',
    icon: 'exclamation-triangle',
    message: 'This invite does not exist, if you reached this page in error click the X to exit and return home.',
  },
}

function FarmInvitation() {
  const { params } = useRoute<RouteProp<ExternalLinkingParamList, 'FarmInvitation'>>()
  const navigation = useNavigation<StackNavigationProp<ExternalLinkingParamList, 'FarmInvitation'>>()
  const [invite, setInvite] = useState<UserInvite>()
  const user = useSelector(userSelector)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<ErrorType>(Errors.invalid)
  const [farm, setFarm] = useState<Farm>()

  useEffect(() => {
    if (params.id)
      loadFarmUserInvite(params.id)
        .then((data) => {
          const now = dateTimeInZone()
          if (data.type !== InviteType.Admin && data.type !== InviteType.Customer) return setError(Errors.invalid)
          if (data.expireDate < now) return setError(Errors.expired)
          if (data.user.email.toLowerCase() !== user.email.toLowerCase()) return setError(Errors.email)
          loadFarm(data.farm.id).then(setFarm)
          setInvite(data)
        })
        .catch((err) => {
          Logger.error(err)
          setError(Errors.invalid)
        })
        .finally(() => setIsLoading(false))
  }, [params.id, user.email])

  const acceptInvitation = () => {
    if (!invite) return
    acceptFarmUserInvite(user.id, invite)
      .then(() => {
        if (invite.type === InviteType.Admin) {
          navigation.navigate('AdminDrawerNavigator')
        } else {
          navigation.navigate('Consumer')
        }
      })
      .catch((err) => {
        Logger.error(err)
        Alert('Error', 'There was an error accepting the invitation, please try again later.')
      })
  }

  return (
    <SafeAreaView style={styles.safeAreaView}>
      <Icon
        name="times"
        size={36}
        color={Colors.shades[500]}
        style={globalStyles.margin20}
        onPress={() => navigation.navigate('Consumer')}
      />
      {invite ? (
        <View style={styles.container}>
          {!!farm && (
            <View style={styles.farmInfo}>
              <Image type="logo" style={styles.farmLogo} source={{ uri: farm.logo }} />
              <HeaderText size={12} numberOfLines={2}>
                {farm.name}
              </HeaderText>
              <Text size={10} type="medium" color={Colors.shades['300']}>
                {formatAddressCityState(farm.address)}
              </Text>
            </View>
          )}
          <Text style={styles.title}>Hi {userName(user)}</Text>
          {invite.type === InviteType.Admin ? (
            <Text style={styles.subtitle}>
              You've been invited to be a role {capitalize(invite.role)} for {invite.farm.name}. Click below to accept
              your invitation.
            </Text>
          ) : (
            <Text style={styles.subtitle}>
              You've been invited as a customer for {invite.farm.name}. Click below to accept your invitation.
            </Text>
          )}
          <Button loading={isLoading} title="Accept Invitation" onPress={acceptInvitation} />
          <Text style={styles.instruction}>
            Signed in as:{'\n'}
            <Text type="bold">{user?.email}</Text>.
          </Text>
        </View>
      ) : (
        <LoaderWithMessage loading={isLoading} icon={error.icon} title={error.title}>
          <Text>{error.message}</Text>
        </LoaderWithMessage>
      )}
    </SafeAreaView>
  )
}

export default withAuth(FarmInvitation)

const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
    alignContent: 'center',
  },
  container: {
    padding: 16,
    alignItems: 'center',
    maxWidth: 600,
    alignSelf: 'center',
  },
  title: {
    fontSize: 24,
    margin: 12,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 20,
    margin: 12,
    textAlign: 'center',
  },
  instruction: {
    textAlign: 'center',
    marginVertical: 8,
    lineHeight: 20,
  },
  input: {
    marginTop: 8,
    borderColor: Colors.black,
    borderWidth: 1,
  },
  farmInfo: {
    marginVertical: 20,
    alignItems: 'center',
    padding: 20,
    backgroundColor: Colors.semiGreen,
    borderRadius: 10,
  },
  farmLogo: {
    width: 50,
    height: 50,
    marginBottom: 10,
    borderRadius: 25,
  },
})
