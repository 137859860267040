import { FormikArrayRenderArgs } from '@/admin/components/elements/FormikArray'
import { ProductSchemaContext, buyingOptionsArraySchema } from '@helpers/builders/buildProduct'
import { createId, hasOwnProperty, isArray } from '@helpers/helpers'
import { Zero } from '@helpers/money'
import { findPriceForAppMode, getUnits } from '@helpers/products'
import { PartialPick } from '@helpers/typescript'
import {
  DefaultCatalog,
  DefaultCatalogPriceGroup,
  Product,
  ProductType,
  StockType,
  Unit,
  UnitBase,
  UnitPrice,
  UnitProduct,
  hasGlobalStock,
  hasUnitStock,
  hasUnits,
  isStandard,
} from '@models/Product'
import * as Yup from 'yup'
import { AdvancedPricingForm, BuyingOptionBuilderOpts, ExtendedPricingForm, FormUnit } from './AdvancedPricing'
import { FormRouteData, ProductFormikComponent } from './helpers/ProductFormikComponent'

export const advancedPricingSchema: Yup.ObjectSchema<AdvancedPricingForm, ProductSchemaContext> =
  Yup.object<ProductSchemaContext>().shape({
    catalog: Yup.mixed<DefaultCatalog>().required().oneOf(Object.values(DefaultCatalog)),
    unitSkuPrefix: Yup.string(),
    unitStock: Yup.boolean().required(),
    // Should only validate when we are on advanced pricing
    buyingOptions: Yup.array<ProductSchemaContext, UnitBase>()
      .required()
      .when('type', {
        is: (type: ProductType) => hasUnits({ type }),
        then: () => buyingOptionsArraySchema,
      }),
  })

/** removes the StandardCommon['unitSkuPrefix'] from the concatenated unit.sku to display correct data in the screen */
export const noPrefixUnitSku = (product: UnitProduct, unit: Unit): string => {
  if (product.type === ProductType.FarmBalance) {
    return ''
  }
  if (product.unitSkuPrefix) {
    const prefix = product.unitSkuPrefix
    return unit.sku.replace(prefix + '-', '')
  } else {
    return unit.sku
  }
}

/** From the catalog of the form, chooses a price catalog which must be only either wholesale or retail but not both */
export const toPriceCatalog = (catalog: DefaultCatalog): DefaultCatalogPriceGroup['catalog'] =>
  catalog === DefaultCatalog.Retail ? DefaultCatalog.Retail : DefaultCatalog.Wholesale

/** Generates a new unit price */
export function buildPrice(catalog: DefaultCatalog): UnitPrice {
  return {
    id: createId(),
    name: 'default',
    amount: Zero,
    maxCount: undefined,
    skuSuffix: '',
    priceGroup: {
      type: 'default-catalog',
      catalog: toPriceCatalog(catalog),
    },
  }
}

/** Builds a new buying option */
export function buildBuyingOption({ catalog = DefaultCatalog.Retail, unitStock }: BuyingOptionBuilderOpts): Unit {
  return {
    id: createId(),
    name: '',
    sku: '',
    multiplier: 1,
    quantity: unitStock ? 0 : undefined,
    prices: [buildPrice(catalog)],
  }
}

/** When a single price must be set on a buying option, this will return the most appropriate price to use. */
export const getSinglePrice = (bo: FormUnit, catalog: DefaultCatalog, unitStock: boolean): UnitPrice =>
  bo.prices.length === 1
    ? /**  If there's a single price use it */
      bo.prices[0]
    : /** else find a price for the currently selected catalog */
      findPriceForAppMode(bo.prices, catalog !== DefaultCatalog.Retail) ??
      /** or create a new one */
      buildBuyingOption({ catalog, unitStock }).prices[0]

export const toFormik = (product: PartialPick<Product, 'type'>, route?: FormRouteData): AdvancedPricingForm => {
  // When creating a new product, the catalog value can be set from route
  const routeCatalog = route?.name === 'AddProduct' && route.params.type ? route.params.catalog : undefined

  // If no catalog is set from route, default to retail
  const initialFormCatalog =
    (isStandard(product) ? product.defaultCatalog : undefined) ?? routeCatalog ?? DefaultCatalog.Retail

  const initialPriceCatalog = toPriceCatalog(initialFormCatalog)

  // If it is not a unit product, we stop here and return dummy form data
  if (!hasUnits(product)) {
    return {
      catalog: initialFormCatalog,
      buyingOptions: [],
      unitStock: false,
    }
  }

  // This case handles product creation, for which there's no units yet
  if (!hasOwnProperty(product, 'units') || !isArray(product.units) || !product.units.length) {
    return {
      catalog: initialFormCatalog,
      buyingOptions: [buildBuyingOption({ unitStock: hasUnitStock(product), catalog: initialPriceCatalog })],
      unitStock: false, // false is the initial value for unitStock
    }
  }

  return {
    catalog: initialFormCatalog,
    buyingOptions: getUnits(product).map((unit): Unit => {
      return {
        ...unit,
        sku: noPrefixUnitSku(product, unit),
        quantity: hasUnitStock(product) ? unit.quantity : undefined,
      }
    }),
    unitSkuPrefix: product.unitSkuPrefix,
    unitStock: product.unitStock, // we assign the current value of the product unitStock
  }
}

// insert the unitSkuPrefix to the beginning of the buyingOption.sku and save it in buyingOption.sku
// if the sku is falsy, use the multiplier as default
const getSku = (buyingOption: FormUnit, unitSkuPrefix?: string) =>
  (unitSkuPrefix ? unitSkuPrefix + '-' : '') + (buyingOption.sku || buyingOption.multiplier.toString())

export const fromFormik = (values: ExtendedPricingForm): Partial<Product> => {
  const base: PartialPick<Product, 'type'> = {
    type: values.type,
  }
  // If this product type shouldn't have units, then don't return anything
  if (!hasUnits(base)) return {}

  base.unitSkuPrefix = values.unitSkuPrefix

  const newUnits: Unit[] = values.buyingOptions.map(
    (buyingOption): Unit => ({
      id: buyingOption.id,
      name: buyingOption.name,
      sku: getSku(buyingOption, values.unitSkuPrefix),
      quantity: values.unitStock ? Number(buyingOption.quantity!) : undefined,
      multiplier: Number(buyingOption.multiplier),
      prices: buyingOption.prices,
    }),
  )

  base.units = hasGlobalStock(base)
    ? (newUnits as UnitBase<StockType.Global>[])
    : (newUnits as UnitBase<StockType.Unit>[])

  if (isStandard(base)) {
    base.defaultCatalog = values.catalog
  }

  return base
}

export const AdvancedPricing = new ProductFormikComponent(advancedPricingSchema, toFormik, fromFormik)

export type FormikArrayBO = FormikArrayRenderArgs<AdvancedPricingForm, 'buyingOptions'>['formik']
export type ArrayHelpersBO = FormikArrayRenderArgs<AdvancedPricingForm, 'buyingOptions'>['arrayHelpers']

export type BuyingOptionSectionProps = {
  formik: FormikArrayBO
  arrayHelpers: ArrayHelpersBO
  index: number
  formValues: ExtendedPricingForm
}
