import { Modal } from '@elements'
import { pick } from '@helpers/typescript'
import { userName } from '@helpers/user'
import { Farm } from '@models/Farm'
import { ParticipantType } from '@models/Messaging'
import { User } from '@models/User'
import { SendChatMessage } from './SendChatMessage'

/** Adapter callback that opens a message modal and sends a message to a farm
 * - This assumes that the button is placed on the consumer side, so the redirect will be on the Consumer's messages screen
 */
export function onSendMessageToFarmCallback(from: Pick<User, 'id' | 'name'>, to: Pick<Farm, 'id' | 'name'>) {
  Modal(
    <SendChatMessage
      from={{ id: from.id, type: ParticipantType.user, sender: pick(from, 'id', 'name') }}
      to={{ id: to.id, type: ParticipantType.farm }}
      isAdmin={false}
    />,
    { title: `Send message to ${to.name}` },
  )
}

/** Adapter callback that opens a message modal and sends a message to a user
 * - This assumes that the button is placed on the admin side, so the redirect will be on the Admin's messages screen
 */
export function onSendMessageToUserCallback(
  farm: Pick<Farm, 'id'>,
  farmManager: Pick<User, 'id' | 'name'>,
  customer: Pick<User, 'id' | 'name'>,
) {
  Modal(
    <SendChatMessage
      from={{ id: farm.id, type: ParticipantType.farm, sender: pick(farmManager, 'id', 'name') }}
      to={{ id: customer.id, type: ParticipantType.user }}
      isAdmin
    />,
    { title: `Send message to ${userName(customer)}` },
  )
}
