import { Logger } from '@/config/logger'
import { useApiFx } from '@/hooks/useApiFx'
import { useCoords } from '@/hooks/useCoords/useCoords'
import { SearchScreenParams } from '@/navigation/types'
import { internationalSelector, userSelector } from '@/redux/selectors'
import { geocodeZipcode, getParsedAddressFromCoords } from '@api/Addresses'
import { getState, isValidZipcode } from '@helpers/address'
import { parseCoordString } from '@helpers/coordinate'
import { dequal } from '@helpers/customDequal'
import { formatAddress } from '@helpers/display'
import { Coordinate } from '@models/Coordinate'
import { UserAddress } from '@models/UserAddress'
import { useSelector } from 'react-redux'

type ParamsForInitialAddr = Pick<SearchScreenParams, 'center' | 'region' | 'locType'>

/** Provides the string that will be used as initial value in the google places search input */
export function useInitialAddress({
  currentParams: params,
}: {
  /** The current navigation params state when running in the search screen */
  currentParams?: ParamsForInitialAddr
} = {}) {
  /** This will try to obtain a set of coords to use for the initial address, based on the params and other context data. If it results in undefined, it means it wasn't possible to obtain any useable value for the initial coordinates
   * This instance of useCoords should receive the params.center because it will prioritize using those coords if they're defined */
  const defaultCoords = useCoords(params?.center)
  const { address: signedInUserAddr } = useSelector(userSelector)
  const { country } = useSelector(internationalSelector)

  /**There must be EITHER the default coords or the center or region params defined */
  const shouldRun: boolean = (() => {
    if (params) {
      // Check that the params have the minimum necessary data based on the location type
      if (params.locType === 'coord') return !!params.center
      else if (params.locType === 'state' || params.locType === 'zip') return !!params.region
    }
    if (defaultCoords) return true
    return false
  })()

  return useApiFx(
    async (
      coords: Coordinate | undefined,
      signedInUserAddr: UserAddress | undefined,
      /** The nav params of the search screen. Optional so this can run elsewhere */
      params?: ParamsForInitialAddr,
    ): Promise<string> => {
      if (params) {
        // If params are defined they take precedence
        if (params.region) {
          /** If the params include an existing region, the initial address should be based on the region */
          // parse region parameter based on the location type
          if (params.locType === 'zip' && isValidZipcode(params.region, country)) {
            /** based on this zip code we must obtain an address string such that if the user changes the location type with this value in the input the new search will still be valid.
             * this means we can't just return the zipcode itself because if the zipcode gets used as a search value for pickup or state search it will produce a wrong result if the location type is later changed to those options */
            try {
              const { city, state, zipcode } = await geocodeZipcode(params.region, country)
              return `${city}, ${state}, ${zipcode}`
            } catch (error) {
              Logger.error(error)
              return params.region
            }
          } else if (params.locType === 'state') {
            // We can't get a full address based on this state so just show the state name in the input
            const state = getState(params.region, country)

            return state?.name || ''
          }

          return params.region
        } else if (params.center) {
          // If the params include an existing center, the initial address should be reverse geocoded
          const coordsFromParams = parseCoordString(params.center)
          if (!coordsFromParams) {
            // This would mean the coords param is bad
            return ''
          }
          try {
            const parser = await getParsedAddressFromCoords(coordsFromParams)
            return formatAddress(parser.getAddress())
          } catch (error) {
            Logger.error(error)
            return ''
          }
        }
      }
      if (coords) {
        // In this scenario the coords are being determined by the last search location or the current device location. The coords will be reverse geocoded to get the initial address for the input
        if (signedInUserAddr && dequal(coords, signedInUserAddr?.coordinate)) {
          // If the default coords are equal to those in the user address it should not reverse-geocode the coordinates. Just return the formatted user address
          return formatAddress(signedInUserAddr)
        }
        try {
          const parser = await getParsedAddressFromCoords(coords)
          return formatAddress(parser.getAddress())
        } catch (error) {
          Logger.error(error)
          return ''
        }
      }

      throw new Error("This effect should only run if there's either params or coords. None of them were defined")
    },
    [defaultCoords, signedInUserAddr, params],
    shouldRun,
    {
      initialState: { data: undefined },
      failedConditionMode: 'stop-loading',
      // This should only run once because we don't want a new initial address to be set when we clear the value
      once: true,
      noRefocus: true,
    },
  )
}
