import { omit } from '@helpers/typescript'
import { User } from '@models/User'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { IncludeMeta } from '@models/Meta'
import { unmarshalMeta } from './Meta'
import { unmarshalUserAddress } from './UserAddress'
import { prepareMarshal, prepareUnmarshal } from './encoding'

/** marshalUser returns the Firestore document data for the supplied user. */
export function marshalUser(user: Partial<User>): DocumentData {
  const data = omit(prepareMarshal(user), 'id') as DocumentData

  return data
}

/** unmarshalUser transforms the data into the User structure or IncludeMeta<User> */
export function unmarshalUser<T extends User, U extends boolean = false>(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
  opts?: { withMeta?: U },
): U extends true ? IncludeMeta<T> : T {
  const [id, data] = prepareUnmarshal(idOrSnapshot as string | FirebaseFirestore.DocumentSnapshot, incomingData, opts)
  const user = { ...data, id } as T

  if (data.meta && opts?.withMeta === true) {
    return { ...user, meta: unmarshalMeta(data.meta) }
  }

  if (data.address) user.address = unmarshalUserAddress(data.address)

  return user
}
