import { BackTo } from '@/admin/components/BackTo'
import { withAdminAuth } from '@/hooks/withAdminAuth'
import { adminFarmIdSelector } from '@/redux/selectors'
import { HeaderText, SafeAreaView } from '@elements'
import { Permission } from '@helpers/Permission'
import { ChatScreenComp_Reusable } from '@screens/ChatScreen/ChatScreen'
import { StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'

function AdminChatScreenComp() {
  const adminFarmId = useSelector(adminFarmIdSelector)

  return (
    <SafeAreaView style={styles.main}>
      <BackTo url="/admin/messages" title="Messages" />
      <HeaderText size={24}>Chat</HeaderText>
      <ChatScreenComp_Reusable adminFarmId={adminFarmId} />
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  main: {
    flex: 1,
    padding: 10,
    gap: 10,
    marginTop: 0, /// there is no need of safearea top margin because we have the header
  },
})

/** Admin Chat Screen. Will be the same as on the consumer side but with Admin Auth Check and a wrapper*/
export const AdminChatScreen = withAdminAuth(AdminChatScreenComp, Permission.Messaging)
