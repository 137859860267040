import { uploadImageAsync } from '@api/FirebaseStorage'
import { Alert } from '@elements'
import { TemplateProduct } from '@models/Product'
import { Media } from '@models/shared/Media'
import { StackNavigationProp } from '@react-navigation/stack'
import sha1 from 'sha1'

import { AdminProductsParamList } from '@/admin/navigation/types'
import { Logger } from '@/config/logger'

interface GoBackOpts {
  navigation: StackNavigationProp<AdminProductsParamList, 'AddTemplate' | 'EditTemplate'>
  isDirty: boolean
  params?: AdminProductsParamList['AddTemplate'] | AdminProductsParamList['EditTemplate']
}

//handle go back action
export const goBack = ({ navigation, isDirty, params }: GoBackOpts) => {
  const go = () => {
    if (params?.goBack) {
      if (params.goBack === 'EditProduct') {
        if (params.prodId) return navigation.replace('EditProduct', { prodId: params.prodId })
        // If no prodId, will go back to ProductList
      } else if (params.goBack === 'AddProduct') {
        if (params.prodType) return navigation.replace('AddProduct', { type: params.prodType })
        // If no prodType, will go back to ProductList
      } else {
        return navigation.replace(params.goBack)
      }
    }

    return navigation.replace('ProductList')
  }

  if (isDirty) {
    Alert("Your changes aren't saved!", 'Do you want to continue without saving or want to go back?', [
      { text: 'Cancel', style: 'cancel' },
      {
        text: 'Continue without saving',
        onPress: go,
      },
    ])
  } else {
    go()
  }
}

//check is Edit or not Edit
export function isEdit(template?: TemplateProduct): template is TemplateProduct {
  return !!template
}

//handle image upload
// if the image is already uploaded don't upload the uploaded version which will cause a break in the url
export async function imageUploadAsync(media: Media[]) {
  try {
    const result = await Promise.all(
      media.map((m: Media) => {
        // Check if image is already hosted
        if (m.storageUrl.includes('http://') || m.storageUrl.includes('https://')) return m
        else return uploadImageAsync(sha1(m.storageUrl), m)
      }),
    )
    return result.map((m) => m.storageUrl)
  } catch (err) {
    Alert('Error saving images', 'There was an error saving your images. Please try again.')
    Logger.error('Error saving images', err)
  }
}
