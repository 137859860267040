import { capitalize } from '@helpers/display'
import { MoneyCalc, Zero } from '@helpers/money'
import { InvoicePaymentMethod } from '@models/Invoice'
import { Money } from '@models/Money'
import { PaymentSources } from '@models/PaymentMethod'
import { STRIPE_CREDIT_ADDITIONAL_FEE, STRIPE_CREDIT_CARD_FEE_PERCENT } from '../../../constants/CONSTANTS'
import { InfinitePaymentMethodDefinition, PaymentMethodsDefinitionInterface } from './PaymentDefinition'

/** Our implementation of credit card processing through Stripe */
export class StripeCreditPaymentDefinition
  extends InfinitePaymentMethodDefinition<PaymentSources.STRIPE>
  implements PaymentMethodsDefinitionInterface<PaymentSources.STRIPE>
{
  chargePlatformFee = true
  constructor() {
    super(PaymentSources.STRIPE, 'Card')
  }

  calculateFee(amount: Money) {
    // If the amount to charge fees on is Zero then don't charge any fees. Without this it will charge the flat fee like $0.30
    if (MoneyCalc.isZero(amount)) return Zero

    const percentageFee = MoneyCalc.math(Math.ceil, MoneyCalc.multiply(amount, STRIPE_CREDIT_CARD_FEE_PERCENT / 100))

    // Add the additional $0.30 Stripe charges on top of the percentage
    return MoneyCalc.add(percentageFee, STRIPE_CREDIT_ADDITIONAL_FEE)
  }

  formatInvPayMethod({ last4, card_type }: InvoicePaymentMethod<PaymentSources.STRIPE>) {
    const baseStr = card_type ? capitalize(card_type) : this.formatShort()

    if (last4) return `${baseStr} *${last4}`
    return baseStr
  }
}
