import { omit } from '@helpers/typescript'
import { FarmAssociation } from '@models/User'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { prepareMarshal, prepareUnmarshal } from './encoding'
import { unmarshalFarm } from './Farm'

/** marshalFarmAssociation returns the farm association structure for Firestore. */
export function marshalFarmAssociation(farmAssociation: Partial<FarmAssociation>): DocumentData {
  return omit(prepareMarshal(farmAssociation), 'farm')
}

/** unmarshalFarmAssociation returns the farm association structure for Firestore. */
export function unmarshalFarmAssociation(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): FarmAssociation {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)
  const fa = { ...data, id } as FarmAssociation
  if (data.farm) {
    fa.farm = unmarshalFarm(data.farm)
  }
  return fa
}
