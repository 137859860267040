import { useFocusFx } from '@/hooks/useFocusFx'
import { loadFarmBalance } from '@api/Payments'
import { Alert } from '@elements'
import { dequal } from '@helpers/customDequal'
import { errorToString } from '@helpers/helpers'
import { pick } from '@helpers/typescript'
import { SplitTenderPaymentItemBase } from '@models/Order'
import { PaymentMethod, isEbtPayment } from '@models/PaymentMethod'
import { User } from '@models/User'

import { createContext, useCallback, useMemo } from 'react'
import { Logger } from '../../../config/logger'
import { useApiFx } from '../../../hooks/useApiFx'
import useKeyedState from '../../../hooks/useKeyedState'
import { configureDefaultTender, listPaymentsWithFilters } from './helpers/helpers'
import { addUpdateSplitTender, adjustSplitTenderWithOptions } from './helpers/splitTender'
import { PaymentOptionsContextType, SplitTenderErrors } from './helpers/types'
import { validateOptions, validateSplitTenderWithOptions } from './helpers/validation'

type State = Pick<PaymentOptionsContextType, 'isValid' | 'validationError' | 'splitTender' | 'options'>

const getInitialState = (options: PaymentOptionsContextType['options']): State => ({
  isValid: false,
  validationError: undefined,
  splitTender: [],
  options,
})

/** This hook handles all the logic for updating and storing the context values */
export const usePaymentOptionsData = (
  userId: User['id'],
  /** When the options change in the external component they are passed into here for validation. */
  unvalidatedOptions: PaymentOptionsContextType['options'],
  /** This unique ID will determine if we should reset the payment configuration. Each place that implements PaymentSelector
   * can determine the appropriate uniqueID */
  uniqueId: string,
  onSplitTenderUpdated: PaymentOptionsContextType['onSplitTenderUpdated'],
): PaymentOptionsContextType => {
  const [state, set, setState] = useKeyedState<State>(getInitialState(unvalidatedOptions))

  const userFarmBalanceFx = useApiFx(loadFarmBalance, [userId, state.options.farm.id], !!state.options.farm.id)

  const userPaymentMethodsFx = useApiFx(
    listPaymentsWithFilters,
    [userId, state.options.amountTotal.currency, pick(state.options, 'farm', 'allowOfflinePayments')],
    !!state.options.farm.id,
  )

  /** This function will be called whenever the user selects a new payment method and will update the splitTender */
  const updateSplitTender = useCallback(
    (newTender: SplitTenderPaymentItemBase) => {
      const updatedSplitTender = addUpdateSplitTender(newTender, state.splitTender, state.options)
      set('splitTender', updatedSplitTender)
    },
    [state.splitTender, state.options, set],
  )

  /** This function handles updating the local state when we add a new payment method */
  const onAddNewPaymentMethod = useCallback(
    (newPmt: PaymentMethod) => {
      // When a new payment method is added we will locally mutate the state so that it has the new payment method
      userPaymentMethodsFx.setState((prev) => ({ ...prev, data: [...(prev.data || []), newPmt] }))

      // If we are adding an EBT payment method then we should set the amount to the max EBT amount, otherwise undefined so
      // that it is treated as infinite
      updateSplitTender({ paymentMethod: newPmt, amount: isEbtPayment(newPmt) ? state.options.amountEbt : undefined })
    },
    [userPaymentMethodsFx, state.options.amountEbt, updateSplitTender],
  )

  /** This will update the options whenever they change */
  useFocusFx(() => {
    // We only want to run this effect when the options have changed, this prevents it triggering itself when the split tender is updated
    if (dequal(unvalidatedOptions, state.options)) return

    // If the farmId is changing than we don't want to update state as the state will be cleared by the reset effect
    if (unvalidatedOptions.farm.id !== state.options.farm.id) return

    // Validate that the options provided are correct
    try {
      validateOptions(unvalidatedOptions)
    } catch (e) {
      // Options should never be invalid, and if they are we want to mark invalid and show an error to the user
      set('isValid', false)
      set('validationError', SplitTenderErrors.OPTIONS_INVALID)

      Logger.error(e)
      return Alert(
        'Invalid payment options',
        `The provided payment selection options are invalid, please contact support: ${errorToString(e)}`,
      )
    }

    // Update the options now that they have been validated
    set('options', unvalidatedOptions)

    // If there is no previous options or splitTender doesn't exist then we don't need to adjust the split tender
    if (state.splitTender.length === 0) return

    // We attempt to adjust the split tender with the new options
    const updatedTender = adjustSplitTenderWithOptions(state.splitTender, state.options, unvalidatedOptions)
    set('splitTender', updatedTender)
  }, [state.options, unvalidatedOptions, set, state.splitTender])

  /** This will reset the context data when the farm user or uniqueId changes */
  useFocusFx(() => {
    setState(getInitialState(unvalidatedOptions))
    // We don't want this to update when options change, only when the farm user or uniqueId changes
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, uniqueId, unvalidatedOptions.farm.id, setState])

  /** This will notify the outer scope anytime the payment selection becomes invalid */
  useFocusFx(() => {
    if (!state.isValid) {
      onSplitTenderUpdated(undefined)
    }
  }, [state.isValid, onSplitTenderUpdated])

  /** This is where we select default payment methods for the invoice */
  useFocusFx(() => {
    // We only want to run this once all data has been loaded, and it should not be run if split tender has already been set
    if (!userPaymentMethodsFx.loading && !userFarmBalanceFx.loading && state.splitTender.length === 0) {
      const initialTender = configureDefaultTender(userFarmBalanceFx, userPaymentMethodsFx, state.options)
      set('splitTender', initialTender)
    }
    // We do not want to update when splitTender changes only when the data is loaded initially
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPaymentMethodsFx, userFarmBalanceFx, state.options, set])

  /** This effect will run whenever splitTender or options change to ensure that the isValid marker is always up-to-date */
  useFocusFx(() => {
    // Check if the new tender is valid and if not set the validation error
    const validationError = validateSplitTenderWithOptions(state.splitTender, state.options)

    // update the validation error and isValid state
    set('validationError', validationError)
    set('isValid', !validationError)

    // We should call the callback with the new split tender or undefined if it is not valid
    if (!validationError) {
      onSplitTenderUpdated(state.splitTender)
    } else {
      onSplitTenderUpdated(undefined)
    }
  }, [state.splitTender, state.options, onSplitTenderUpdated, set])

  return useMemo(() => {
    return {
      ...state,
      userId,
      updateSplitTender,
      userFarmBalance: userFarmBalanceFx.data,
      userPaymentMethods: userPaymentMethodsFx.data,
      refreshPaymentMethods: userPaymentMethodsFx.refresh,
      isLoading: userFarmBalanceFx.loading || userPaymentMethodsFx.loading,
      onSplitTenderUpdated,
      onAddNewPaymentMethod,
    }
  }, [
    state,
    userId,
    updateSplitTender,
    userFarmBalanceFx,
    userPaymentMethodsFx,
    onSplitTenderUpdated,
    onAddNewPaymentMethod,
  ])
}

export const PaymentOptionsContext = createContext<PaymentOptionsContextType>({} as PaymentOptionsContextType)
