import { searchUserByEmail, toggleDisableUser, updateProviderToEmail } from '@api/FarmGenAdmin'
import { unmarshalPhoneNumber } from '@helpers/display'
import { userName } from '@helpers/user'
import { SignInProviders, User } from '@models/User'
import { useEffect, useState } from 'react'
import { FlatList, StyleSheet, View } from 'react-native'

import { FormInput, Spinner } from '@elements'
import { errorToString, isNonNullish } from '@helpers/helpers'
import { Tag } from '../../admin/components/ProductTag'
import { Image } from '../../components/Image'
import LoaderWithMessage from '../../components/LoaderWithMessage'
import { DropdownMenu, DropdownMenuBtn } from '../../components/elements/DropdownMenu'
import { Icon } from '../../components/elements/Icon/Icon'
import { Alert, Toast } from '../../components/elements/Overlays/Popups'
import { HeaderText, Text } from '../../components/elements/Text'
import Colors from '../../constants/Colors'
import { globalStyles } from '../../constants/Styles'
import { useDebouncedValue } from '../../hooks/useDebounce'
import LoginAsUser from './LoginAsUser'
import { EmailLogsModal } from './components/EmailLogsModal'

const generateButtons = (user: User): DropdownMenuBtn<User>[] =>
  [
    {
      title: user.isDisabled ? 'Remove Suspension' : 'Suspend User',
      onPress: (user: User) => suspendUser(user, !!user.isDisabled),
    },
    user.signInProvider !== SignInProviders.Email
      ? { title: 'Change Login Method', onPress: changeAuthProviderToEmail }
      : undefined,
    {
      title: 'View Email Logs',
      onPress: (user: User) => EmailLogsModal(user),
    },
  ].filter(isNonNullish)

const suspendUser = (user: User, isDisabled: boolean) => {
  toggleDisableUser(user.id, !isDisabled)
    .then(() => {
      if (isDisabled) return Toast(`You have successfully un-suspended ${userName(user)}.`)
      Toast(`You have successfully suspended ${userName(user)}.`)
    })
    .catch(() => {
      Toast('Unable to update this account. Try again later.')
    })
}

const changeAuthProviderToEmail = (user: User) => {
  Alert(
    'Change Auth Provider',
    `Would you like to change the sign in method from ${user.signInProvider?.toUpperCase()} to EMAIL`,
    [
      {
        text: 'Update',
        onPress: () => {
          updateProviderToEmail(user.id)
            .then(() =>
              Alert(
                'Success',
                `${userName(user)} is now set to login with EMAIL, and a password reset has been sent to ${user.email}`,
              ),
            )
            .catch((err) => Alert('Error Updating', errorToString(err)))
        },
      },
      {
        text: 'Cancel',
        style: 'cancel',
      },
    ],
  )
}

/** Component that renders the admin user card and actions for users */
export function FarmGenAdminUser() {
  const [userText, setUserText] = useState('')
  const [loading, setLoading] = useState(false)
  const debouncedQuery = useDebouncedValue(userText)
  const [users, setUsers] = useState<User[]>([])

  useEffect(() => {
    if (debouncedQuery.length === 0) setUsers([])
    if (debouncedQuery.length < 3) return
    setLoading(true)
    searchUserByEmail(debouncedQuery).then((users) => {
      setUsers(users)
      setLoading(false)
    })
  }, [debouncedQuery])

  return (
    <View>
      <HeaderText>Users</HeaderText>
      <FormInput
        label={null}
        placeholder="Search by Email"
        onChangeText={setUserText}
        rightIcon={debouncedQuery !== userText ? <Spinner /> : <></>}
      />
      <FlatList
        data={users}
        renderItem={({ item }) => (
          <View style={styles.row}>
            <Image type="profile" style={styles.avatar} source={{ uri: item.avatar }} />
            <View style={styles.innerRow}>
              <Text size={15} type="medium">
                {userName(item)} {item.isDisabled && <Tag color={Colors.red} title="suspended" />}
              </Text>
              <Text>{item.email}</Text>
              {item.phoneNumber !== undefined && <Text>{unmarshalPhoneNumber(item.phoneNumber)}</Text>}
            </View>
            <View style={globalStyles.flexRowCenter}>
              <LoginAsUser item={item} />
              <DropdownMenu buttons={generateButtons(item)} data={item}>
                <Icon name="ellipsis-v" style={styles.ellipsis} />
              </DropdownMenu>
            </View>
          </View>
        )}
        ListEmptyComponent={() =>
          debouncedQuery.length > 0 ? (
            <LoaderWithMessage loading={loading} icon="user-slash" title="No Results">
              <Text>There are no users matching this search.</Text>
            </LoaderWithMessage>
          ) : (
            <LoaderWithMessage loading={false} icon="user-secret" title="No Results">
              <Text>Search for a user by email to see actions that can be taken.</Text>
            </LoaderWithMessage>
          )
        }
      />
    </View>
  )
}

const styles = StyleSheet.create({
  avatar: {
    borderWidth: 2,
    borderColor: Colors.green,
    width: 60,
    height: 60,
    borderRadius: 40,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 10,
  },
  innerRow: {
    margin: 5,
    marginLeft: 10,
    flex: 2,
  },
  ellipsis: {
    padding: 5,
  },
})
