import { Farm } from '@models/Farm'
import { PaymentSources } from '@models/PaymentMethod'
import { ErrorCode, ErrorWithCode } from '@shared/Errors'
import { PmtDef } from '@shared/helpers/PaymentDefinitions'

/** Will return if the provided source is considered an infinite source like cash, card or bank as opposed to finite like ebt
 or farm credit */
export function isInfinitePayment(source: PaymentSources) {
  return !PmtDef({ source }).isFinite
}

/** Returns the Fortis credentials for the given farm or throws an error if the farm is not configured with Fortis */
export const getFarmFortisCredentials = (farm: Farm) => {
  if (!farm.fortis) {
    throw new ErrorWithCode({
      code: ErrorCode.UnauthorizedError,
      devMsg: 'This farm is not setup to process payments with Fortis',
      data: { farmId: farm.id },
    })
  }

  return {
    ...farm.fortis,
    credentials: {
      userId: farm.fortis.apiUserId,
      userApiKey: farm.fortis.apiUserKey,
    },
  }
}
