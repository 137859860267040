import { YUP_MONEY_OPTIONAL } from '@helpers/Yup'
import { isValidShortState, isValidZipcode } from '@helpers/address'
import { FSASchemaCanada } from '@helpers/builders/validators/sharedSchemasAddress'
import { getPostalCodeKind, getStateKind, plural } from '@helpers/display'
import { nonEmptyString } from '@helpers/helpers'
import { Farm } from '@models/Farm'
import { FeeWaiveOptionType, LocationTypes, NonPickup, NonPickupLocationTypes } from '@models/Location'
import { MoneyWithCurrency } from '@models/Money'
import * as Yup from 'yup'

export const locAbbreviationSchema = Yup.string()
  .trim()
  .label('Label Abbreviation')
  .optional()
  .max(4, 'Abbreviation must be 4 characters or less')
  .matches(/^[a-zA-Z0-9]+$/, 'Only letters and numbers are allowed')

/** Returns the schema for the addDeliveryShipping screen */
export function getSchemaDeliveryShipping(
  locationType: NonPickupLocationTypes,
  farm: Farm,
): Yup.ObjectSchema<{
  type: NonPickupLocationTypes
  enterFSACodes: boolean
  locationName: string
  abbreviation?: string
  cost?: MoneyWithCurrency
  regions: NonPickup['regions']
  feeWaiveOption: FeeWaiveOptionType
}> {
  return Yup.object().shape({
    type: Yup.string<NonPickupLocationTypes>()
      .oneOf([locationType === LocationTypes.Delivery ? LocationTypes.Delivery : LocationTypes.Shipping])
      .required(),
    enterFSACodes: Yup.boolean().required(),
    locationName: Yup.string().trim().label('Location Name').required(),
    abbreviation: locAbbreviationSchema,
    cost: YUP_MONEY_OPTIONAL('cost', { allowZero: true, requireCurrency: true }),
    regions:
      locationType === LocationTypes.Delivery
        ? Yup.array()
            .required()
            .min(1, `You must select at least one ${getPostalCodeKind(farm.address.country)}`)
            .test(
              'Valid postal codes',
              `One or more ${plural(2, getPostalCodeKind(farm.address.country))} are not valid for the farm country`,
              (v) => {
                return (
                  !!v &&
                  v.every((code) => {
                    if (!nonEmptyString(code)) return false

                    if (isValidZipcode(code, farm.address.country)) return true
                    // If Canada, also allow FSA code
                    if (farm.address.country === 'CA' && FSASchemaCanada.isValidSync(code)) return true
                    return false
                  })
                )
              },
            )
            .test(
              'max600',
              `You can enter a maximum of 600 ${plural(2, getPostalCodeKind(farm.address.country))}`,
              (v) => !!v && v.length <= 600,
            )
            .required()
        : Yup.array()
            .required()
            .min(1, `You must select at least one ${getStateKind(farm.address.country)}`)
            .test(
              'Valid states',
              `One or more ${plural(2, getStateKind(farm.address.country))} are not valid for the farm country`,
              (v) => {
                return !!v && v.every((s) => nonEmptyString(s) && isValidShortState(s, farm.address.country))
              },
            ),
    feeWaiveOption: Yup.mixed<FeeWaiveOptionType>().oneOf(Object.values(FeeWaiveOptionType)).required(),
  })
}
