import { ButtonClear, hideModal, Icon, TextH1 } from '@elements'
import Modal from 'modal-enhanced-react-native-web'
import { useCallback } from 'react'
import { View } from 'react-native'

import Colors from '../../../../constants/Colors'
import { ModalComponentProps } from '../../../../constants/types/modalTypes'

import { useLayoutFnStyles } from '@/hooks/useFnStyles'

/** Wraps a component to be rendered as a full-screen modal with transparent background in any platform.
 * - Has a web and mobile version. It's the building block for other overlay-type components.
 * - Should access it through higher level wrappers like ModalView */
export function ModalComponent({
  visible,
  webWidth = 600,
  header = true,
  title,
  children,
  dismissable = true,
  onDismiss: onDismissProp,
  noPadding = false,
  onShow,
  goBack,
  ...modalProps
}: ModalComponentProps) {
  const styles = useStyles(noPadding, webWidth, title)
  const onDismiss = useCallback(() => {
    onDismissProp?.()
    hideModal()
  }, [onDismissProp])

  return (
    <Modal
      isVisible={visible}
      onBackdropPress={dismissable ? onDismiss : undefined}
      onDismiss={
        undefined /** !!! onDismiss shouldn't be passed directly here because it would mess up the dismissable feature. Some components rely on controling whether the modal is dismissable. The dismissable control in web is implemented through onBackdropPress. So if you pass it here it would be run twice and would make this component confusing, since only onBackDropPress is supposed to run onDismiss. If that were not the case, then we'd remove dismissable, and onBackdropPress and pass onDismiss directly since we wouldn't assume to have control over it */
      }
      onShow={useCallback(() => onShow?.(), [onShow])}
      {...modalProps}
    >
      <View style={styles.modal}>
        {header && (
          <View style={styles.header}>
            {goBack && <Icon name="chevron-left" onPress={goBack} color={Colors.black} />}
            {!!title && <TextH1 numberOfLines={2}>{title}</TextH1>}
            {/* Only show the close icon if modal is dismissable */}
            {dismissable && (
              <ButtonClear style={styles.icon} title="" icon="times" onPress={onDismiss} color={Colors.black} />
            )}
          </View>
        )}
        {children}
      </View>
    </Modal>
  )
}

const useStyles = (noPadding: boolean, webWidth: number, title?: string) =>
  useLayoutFnStyles(
    (layout, noPadding, webWidth, title) => ({
      modal: {
        width: webWidth ? Math.min(webWidth, layout.screenWidth) : undefined,
        alignSelf: 'center',
        // On small device we don't want to limit the layout less than 100%.
        maxHeight: layout.isSmallDevice ? '100%' : layout.height - 100,
        backgroundColor: Colors.white,
        borderRadius: 10,
        overflow: 'hidden',
        padding: noPadding ? 0 : 10,
      },
      header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: title ? 'space-between' : 'flex-end',
        paddingLeft: 20,
        paddingRight: 10,
        paddingTop: 10,
      },
      icon: { alignSelf: 'flex-end' },
    }),
    noPadding,
    webWidth,
    title,
  )
