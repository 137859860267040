import { SafeAreaView } from '@elements'
import { StyleSheet } from 'react-native'

import { isEmul, releaseChannel } from '../config/Environment'
import Colors from '../constants/Colors'
import { Icon } from './elements/Icon/Icon'
import { HeaderText } from './elements/Text'
import { Touchable } from './elements/Touchable'

import { useControlledState } from '@/hooks/useControlledState'
import { internationalSelector, wholesaleSelector } from '@/redux/selectors'
import { useSelector } from 'react-redux'

/** Shows the current stage/ environment the app is running on */
export function EnvironmentBanner() {
  const { country } = useSelector(internationalSelector)
  const { isWholesale } = useSelector(wholesaleSelector)
  const [isHidden, [hide]] = useControlledState(false, [true])
  if (releaseChannel === 'prod') return null
  if (isHidden) return null

  const stage = releaseChannel.toUpperCase()

  return (
    <SafeAreaView style={styles.container}>
      <Touchable style={styles.inner} onPress={hide}>
        <HeaderText style={styles.headerText} color={Colors.white}>
          {stage + (isEmul ? '(emul)' : '') + (isWholesale ? '-Wholesale' : '') + `-${country.toUpperCase()}`}
        </HeaderText>
        <Icon name="times-circle" color={Colors.white} onPress={hide} />
      </Touchable>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.red,
    padding: 10,
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 100,
  },
  inner: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerText: { marginHorizontal: 4 },
})
