import { useApiFx } from '@/hooks/useApiFx'
import { useSnapshot } from '@/hooks/useSnapshot'
import { MessagesParamList } from '@/navigation/types'
import { userSelector } from '@/redux/selectors'
import { chatSessionsCollection } from '@api/framework/ClientCollections'
import { snapshotChatMessages } from '@api/Messaging'
import { DEFAULT_MSG_LIMIT } from '@helpers/messaging'
import { RouteProp, useRoute } from '@react-navigation/native'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

/** Data layer hook that handles the business logic for  Chat screen */
export function useChatScreenData() {
  const route = useRoute<RouteProp<MessagesParamList, 'Chat'>>()
  const user = useSelector(userSelector)
  const [msgLimit, setMsgLimit] = useState(DEFAULT_MSG_LIMIT)

  const { id } = route.params ?? {}

  /**  First will get the chat sessions so we ensure it exists*/
  const chatSessionFx = useApiFx(chatSessionsCollection.fetch.bind(chatSessionsCollection), [id], !!id)

  /** Run snapshot only when the chat session is retrieved, as the listener will return empty list if session does not exist*/
  const messagesSnap = useSnapshot(
    'snapshotChatMessages',
    snapshotChatMessages,
    [id, msgLimit],
    !!id && !!user.id && !!chatSessionFx.data,
  )

  const onIncreaseMessageLimit = useCallback(() => {
    // If we loaded data and it doesn't reach the limit it means there is no more to load
    if (messagesSnap.data?.length && messagesSnap.data?.length < msgLimit) return

    setMsgLimit((c) => c + DEFAULT_MSG_LIMIT)
  }, [messagesSnap.data?.length, msgLimit])

  return {
    messages: messagesSnap.data,
    currUserId: user.id,
    chatSession: chatSessionFx.data,
    error: chatSessionFx.err ?? messagesSnap.error,
    loading: messagesSnap.loading ?? chatSessionFx.loading,
    onIncreaseMessageLimit,
  }
}

export type UseChatScreenDataType = ReturnType<typeof useChatScreenData>
