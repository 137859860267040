import { Money } from '@models/Money'
import { FarmBalance } from '@models/Payment'
import {
  EbtPaymentMethod,
  isStripePayment,
  PaymentForms,
  PaymentMethod,
  PaymentSources,
  pmt_CashMethod,
} from '@models/PaymentMethod'
import { isNotFound } from '@shared/Errors'

import { CreateFortisPaymentMethodRequest } from '@shared/types/v2/fortis'
import { CreatePaymentMethodRequest } from '@shared/types/v2/stripe'
import { farmBalancesCollection, paymentMethodsCollection } from './framework/ClientCollections'
import { callEndpoint } from './v2'

export const loadFarmBalance = async (userId: string, farmId: string) => {
  try {
    return await farmBalancesCollection.resolve(userId).fetch(farmId)
  } catch (e) {
    if (isNotFound(e)) return undefined
    throw e
  }
}

// Will watch for changes to farm balance and update the callback with changes
export const snapshotFarmBalance = (
  callback: (bal?: FarmBalance) => void,
  onError: (err: Error) => void,
  userId: string,
  farmId: string,
): (() => void) => {
  return farmBalancesCollection.resolve(userId).snapshotDoc(farmId, callback, onError)
}

export const loadFarmBalances = async (userId: string) => {
  const balances = await farmBalancesCollection.resolve(userId).fetchAll()
  if (balances.length === 0) return []
  return balances
}

/** Will load the users farm balance directly from Stripe */
export const loadStripeFarmBalance = (userId: string, farmId: string) => {
  return loadAvailableBalance(PaymentSources.FARM_CREDIT, PaymentSources.FARM_CREDIT, userId, farmId)
}

/** Will load the available balance for a payment method */
export async function loadAvailableBalance<
  T extends PaymentSources,
  Return = T extends PaymentSources.WORLD_PAY_EBT
    ? {
        snap: Money
        cash: Money
      }
    : {
        balance: Money
      },
>(paymentMethodId: string, paymentMethodSource: T, userId: string, farmId: string): Promise<Return> {
  const res = await callEndpoint('v2.Invoice.checkAvailableBalanceService', {
    paymentMethod: {
      id: paymentMethodId,
      source: paymentMethodSource as PaymentMethod['source'],
    },
    userId,
    farmId,
  })
  return res.balance as Return
}

/** If the amount is negative we charge the user and if it is positive we credit the user **/
export const adjustBalance = async (
  userId: string,
  farmId: string,
  amount: number,
  message: string,
  uniqueId: string,
) => {
  return await callEndpoint('v2.Stripe.adjustFarmBalance', { userId, farmId, amount, message, uniqueId })
}

/**
 * Payment Methods
 **/

/**
 * Will add the given data as a payment method in stripe
 */
export const addStripePaymentMethod = async (data: CreatePaymentMethodRequest) => {
  return callEndpoint('v2.Stripe.addStripePaymentMethod', data)
}

export const listPaymentMethods = async (userId: string): Promise<PaymentMethod[]> => {
  try {
    const res = await callEndpoint('v2.Invoice.listPaymentMethodsService', {
      userId,
    })
    return res.paymentMethods
  } catch (err) {
    // If the request failed then return only cash as the payment options
    return [pmt_CashMethod]
  }
}

/**
 * This function will add a new payment method for a specific user.
 * @param userId The unique identifier of the user for whom the payment method is to be added.
 * @param payMethod The payment method details to be added, excluding the 'id'.
 * @return A promise that resolves with the newly created payment method.
 */
export function addPaymentMethod(userId: string, payMethod: Exclude<PaymentMethod, 'id'>) {
  return paymentMethodsCollection.resolve(userId).create({ ...payMethod, id: '' })
}

/**
 * This function will remove a specific payment method for a specific user.
 * If the payment method is an EBT payment, it will be marked as deleted.
 * Otherwise, we call the server function to delete the Stripe payment method
 * @param pm The payment method to be removed.
 * @param userId The unique identifier of the user for whom the payment method is to be removed.
 * @return A promise that resolves when the payment method has been removed.
 */
export function removePaymentMethod(pm: PaymentMethod, userId: string) {
  if (isStripePayment(pm)) {
    return callEndpoint('v2.Stripe.removePaymentMethod', { token: pm.token })
  } else {
    return paymentMethodsCollection.resolve(userId).update({ id: pm.id, isDeleted: true })
  }
}

/**
 * This function will add a one-time PIN for a specific user's EBT payment method.
 * @param userId The unique identifier of the user for whom the PIN is to be added.
 * @param payMethod The EBT payment method details including 'id' and 'pin'.
 * @return A promise that resolves when the PIN has been added.
 */
export function addOneTimePin(userId: string, payMethod: Required<Pick<EbtPaymentMethod, 'id' | 'pin'>>) {
  return paymentMethodsCollection.resolve(userId).update(payMethod)
}

/**
 * API call to fetch the client id for Fortis
 */
export async function loadFortisClientId(
  userId: string,
  farmId: string,
  paymentMethod: PaymentForms.BANK | PaymentForms.CARD,
): Promise<string> {
  const res = await callEndpoint('v2.Fortis.transactionIntentionId', { userId, farmId, paymentMethod })
  return res.token
}

/**
 * API call to add a payment method to Fortis
 */
export async function addFortisPaymentMethod(request: CreateFortisPaymentMethodRequest): Promise<PaymentMethod> {
  const res = await callEndpoint('v2.Fortis.addPaymentMethod', request)
  return res.paymentMethod
}
