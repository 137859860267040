import {
  Button,
  ButtonGroup,
  ButtonGroupButton,
  CheckBox,
  Divider,
  ErrorText,
  hideModal,
  Hoverable,
  LoadingView,
  Modal,
  Picker,
  PickerProps,
  Text,
} from '@elements'
import { Distribution } from '@models/Distribution'
import { isShare, isStandard, PhysicalProduct, Standard, Unit } from '@models/Product'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { Pressable, View } from 'react-native'

import { AppStatus } from '../../screens/AppStatus'

import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { CartServiceType, getIsAdminForCartServiceType } from '@/constants/types/cartService'
import { useCartService } from '@/hooks/useCart'
import { getPickupsCacheAddtoCartFlow } from '@/hooks/useCart/addToCartFlow/helpers'
import { useDiffFx } from '@/hooks/useDiffFx'
import { useDeepCompareLayoutFnStyles } from '@/hooks/useFnStyles'
import useKeyedState from '@/hooks/useKeyedState'
import { useWindowSizeFromWidth, WindowSizers } from '@/hooks/useLayout'
import { findItemInCart, getRemaining, isCartUnique } from '@helpers/canUpdateQuantity'
import { capitalize, formatMoney, formatPickupDate, formatPickupTime, getDayofWeekName, plural } from '@helpers/display'
import { isTruthy, removeComplexDuplicates } from '@helpers/helpers'
import { formatDistributionType } from '@helpers/location'
import { Zero } from '@helpers/money'
import { getOrderDeadlineFromPickup } from '@helpers/order'
import { format, isSameDay } from '@helpers/time'
import { Replace } from '@helpers/typescript'
import { isDelivery, isLocalPickup, isShipping } from '@models/Location'
import { CartItem, isCartStandard } from '@models/Order'
import { Frequency } from '@models/Schedule'
import { DataError } from '@shared/Errors'
import { FlashList, ListRenderItem } from '@shopify/flash-list'
import { DateTime } from 'luxon'
import { getAvailableSchedules, useAvailableSchedules } from './useAvailableSchedules'

/** Props for the modal components */
type SetScheduleAndDatesComponentProps = {
  prod: PhysicalProduct
  /** The selection will be limited to these product schedules. This is used to limit the selection based on additional criteria like matching schedules in the cart */
  schedulesIdsFilter?: Distribution['id'][]
  /** The location/s selected in a previous step. For localPickup, this will have a single location; For nonPickup it may have one or more locations compatible with the selected address */
  locsIdsFilter?: Distribution['location']['id'][]
  /** Handler for the result. Will receive the schedule selected and the dates selected. In shares, the dates will be calculated automatically. */
  onSelect: (schedule: Distribution, dates: DateTime[]) => void | Promise<void>
  /** This schedule will appear as currently selected when the component loads */
  initialSchedule?: Distribution
  /** This is the buying option selected in a previous step. This is needed to calculate if the dates selected exceed the stock available */
  unit?: Unit
  cartServiceType?: CartServiceType
  /** Will customize behaviors for the current app mode. Only schedules compatible with the current catalog mode should be considered */
  isWholesale: boolean
}

type StateSetScheduleAndDates = {
  schedule?: Distribution
  selectionMap: { [dateString: string]: boolean }
  isShowingMoreDates: boolean
  /** Current width of the modal component parent View */
  currWidth: number
}

const SELECT_ALL = 'Select All' as const

/** Modal component for selecting schedules and manual date selection in a single modal */
export function SetScheduleAndDates({
  prod,
  locsIdsFilter,
  onSelect,
  initialSchedule,
  schedulesIdsFilter,
  unit,
  cartServiceType = 'consumer',
  isWholesale,
}: Replace<SetScheduleAndDatesComponentProps, 'prod', Standard>) {
  const [{ schedule, selectionMap, isShowingMoreDates, currWidth }, , , setters] =
    useKeyedState<StateSetScheduleAndDates>({
      schedule: undefined,
      selectionMap: {},
      isShowingMoreDates: false,
      currWidth: 0,
    })
  const compSizers = useWindowSizeFromWidth(currWidth)
  const { isSmallWindow } = compSizers
  // This cart selector needs to specify the farm id because there's a part that needs to look at the cart items from the item's farm only, to ensure there's a single cart date per farm in wholesale
  const { cart, isAdmin } = useCartService({ farmId: prod.farm.id, cartServiceType, isWholesale })

  /** These schedules all have pickups and belong to the location specified */
  const { availableSchedules: availSchedules } = useAvailableSchedules({
    prod,
    locsIdsFilter,
    schedulesIdsFilter,
    isAdmin,
    isWholesale,
    cartServiceType,
  })

  /** auto-selects an initial schedule */
  const hasSetInitial = useRef(false)
  useEffect(() => {
    if (hasSetInitial.current || !availSchedules?.length) return

    // autoselect the initialSchedule from props if it is available
    if (initialSchedule && availSchedules.find((sch) => sch.id === initialSchedule.id)) {
      setters.schedule(initialSchedule)
    } else {
      // If no initialSchedule, or if not found, auto-select the first one
      setters.schedule(availSchedules[0])
    }
    hasSetInitial.current = true // don't run again
  }, [availSchedules, initialSchedule, setters])

  /** These are the dates available and selectable for the currently selected schedule */
  const { availDates, availDatesLimited, minimumDates, shouldShowDatesFilter } = useMemo(() => {
    const minimumDates = isWholesale ? 1 : prod.minPickups ?? 1

    if (!schedule) return { minimumDates }

    let availDates = getPickupsCacheAddtoCartFlow(schedule, prod, {
      excludeClosedDistros: !isAdmin,
      ignoreDisableBuyInFuture: isAdmin,
      ignoreOrderCutoffWindow: isAdmin,
    })

    if (isWholesale) {
      // In wholesale we must limit the date selection to the date/s in the cart if there's any
      // The cart array must have only the items belonging to the farm of the product being added, to ensure there's a single cart date per farm in wholesale
      const cartDates = removeComplexDuplicates(
        cart.filter(isCartStandard).flatMap((itm) => itm.pickups),
        isSameDay,
      )
      if (cartDates.length) {
        availDates = availDates.filter((avDate) => !!cartDates.find((cDate) => isSameDay(cDate, avDate)))
      }
    }

    /* limit the number of dates to show in the list to either 5 or the min number of dates required (whichever is larger) */
    const availDatesLimited = availDates.slice(0, Math.max(minimumDates, 5))

    const shouldShowDatesFilter = availDates.length > availDatesLimited.length

    return { availDates, availDatesLimited, minimumDates, shouldShowDatesFilter }
  }, [schedule, isAdmin, prod, isWholesale, cart])

  /** When the showMore button is used to hide dates, reset the selected dates to prevent having any selection outside of the current view */
  useDiffFx(
    ({ isShowingMoreDates }) => {
      if (!isShowingMoreDates) return

      const { previous, current } = isShowingMoreDates
      if (previous && !current) setters.selectionMap({})
    },
    { isShowingMoreDates },
  )

  /** The order deadline for the first pickup of the selected schedule */
  const orderDeadline = useMemo(() => {
    if (!schedule) return ''

    const date = getDateForOrderDeadline(availDates)

    if (!date) return ''

    return format(
      getOrderDeadlineFromPickup(date, isAdmin ? 0 : schedule.orderCutoffWindow, schedule.schedule.hours.endTime),
      "MM/dd/yyyy 'at' h:mma",
    )
  }, [availDates, schedule, isAdmin])

  /** These buttons allow the user to select a schedule */
  const scheduleButtons: ButtonGroupButton[] = useMemo(
    () =>
      availSchedules?.map((sch) => ({ label: getScheduleLabel(sch.schedule), onPress: () => setters.schedule(sch) })) ??
      [],
    [availSchedules, setters],
  )
  const schedulePickerItems: PickerProps['items'] = useMemo(
    () => availSchedules?.map((sch) => ({ label: getScheduleLabel(sch.schedule), value: sch.id })) ?? [],
    [availSchedules],
  )

  /** This should be total number of dates that can be selected based on the stock available
   * - It should take into account the amounts from other items (and the same item) in the cart which may count toward the same stock.
   * - It should correctly handle the stock for the buying option if it is a UnitProduct, handling either global or unit stock, as well as unit multiplier for globalstock units. */
  const nDatesRemaining = useMemo(() => {
    if (!prod) return null

    // pass the quantity from the existing item in the cart if any
    const quantity = findItemInCart({ product: prod, unit }, cart)?.quantity ?? 1

    // we filter this item from the cart because it's necessary for this to work correctly when modifying dates. We are framing the calculation as if it were the first time the item is being added
    const cartWithoutItem = cart.filter((ci) => !isCartUnique({ product: prod, unit }, ci))

    // We add one because the calculation adds one date by default, and we want the total number of dates we can add
    const compensation = 1

    return (
      getRemaining({
        cart: cartWithoutItem,
        cartItem: { product: prod, unit, quantity },
        attribute: 'pickups',
        isWholesale,
      }) + compensation
    )
  }, [cart, isWholesale, prod, unit])

  const selectedDates = useMemo(
    () => availDates?.filter((date) => selectionMap[formatPickupDate(date)]) ?? [],
    [availDates, selectionMap],
  )

  /** This will be true if additional dates can still be selected. When this is false, no more dates can be selected */
  const canSelectMore = useMemo<boolean>(() => {
    if (!availDates || !prod || !nDatesRemaining) return false

    // this should find any non-selected date left in the available dates array
    const additionalDate = availDates.find((d) => !selectionMap[formatPickupDate(d)])

    if (!additionalDate) return false

    // We want to add an extra date to the current selection because we want to know if we can add more than the current selection
    return selectedDates.length + 1 <= nDatesRemaining
  }, [nDatesRemaining, availDates, prod, selectionMap, selectedDates])

  const styles = useStyles({ compSizers })

  const stockRemainingUI = useMemo(() => {
    if (!canSelectMore)
      return (
        <ErrorText
          style={styles.stockRemainingText}
        >{`Maximum number of dates selected (${selectedDates.length})`}</ErrorText>
      )

    if (nDatesRemaining !== null && nDatesRemaining < 10)
      return (
        <Text style={styles.stockRemainingText}>{`You can select up to ${nDatesRemaining} dates left in stock.`}</Text>
      )

    return null
  }, [canSelectMore, nDatesRemaining, selectedDates.length, styles.stockRemainingText])

  /** If there is only one available date then we should auto select it*/
  useEffect(() => {
    if (availDates?.length === 1 && canSelectMore && Object.keys(selectionMap).length === 0) {
      const firstPickupKey = formatPickupDate(availDates[0])
      setters.selectionMap({ [firstPickupKey]: true })
    }
  }, [availDates, canSelectMore, setters, selectionMap])

  /** Conditions in which to disable the button for proceeding with the current date selection */
  const disableAddCart = useMemo(
    () => !schedule || !availDates?.length || !selectedDates.length || selectedDates.length < minimumDates,
    [schedule, availDates?.length, selectedDates.length, minimumDates],
  )

  /** Creates a copy of the available dates which is ready to be shown in the UI
   * - Selects either the full avail dates or the limited ones based on the user's preference.
   * - Adds the "Select All" option to the availDates data, which should be shown above the dates list */
  const availDatesForUI = useMemo<(DateTime | typeof SELECT_ALL)[] | undefined>(() => {
    /** IMPORTANT: This new array of filtered dates must be initialized by copying the original arrays because otherwise the originals might be mutated when we insert the SELECT_ALL in the following step */
    const availDatesFiltered: (DateTime | typeof SELECT_ALL)[] | undefined = isShowingMoreDates
      ? availDates?.slice()
      : availDatesLimited?.slice()

    if (!isWholesale && availDatesFiltered && !availDatesFiltered.find((item) => item === SELECT_ALL)) {
      // In retail mode we add the Select All item to the list of dates. In wholesale it's not applicable because only one date can be selected
      availDatesFiltered.unshift(SELECT_ALL)
    }

    return availDatesFiltered
  }, [isShowingMoreDates, availDates, availDatesLimited, isWholesale])

  const renderDate: ListRenderItem<typeof SELECT_ALL | DateTime> = useCallback(
    ({ item: date }) => {
      if (!schedule || !availDates || nDatesRemaining === null) return null

      if (date === SELECT_ALL) {
        // In wholesale mode there won't be an item for SELECT_ALL. So this section assumes this is happening in retail mode

        const isAllSelected =
          availDates.every((d) => selectionMap[formatPickupDate(d)]) ||
          Object.values(selectionMap).filter(isTruthy).length >= nDatesRemaining

        return (
          <View style={styles.deadlineCont}>
            <CheckBox
              checked={isAllSelected}
              onChange={(val) =>
                setters.selectionMap(
                  val
                    ? availDates
                        .slice(0, nDatesRemaining)
                        .map((d) => ({ [formatPickupDate(d)]: true }))
                        .reduce((agg, curr) => ({ ...agg, ...curr }), {})
                    : {},
                )
              }
              title="Select all dates"
              textWeight="regular"
            />
          </View>
        )
      }

      const key = formatPickupDate(date)
      const isSelected = !!selectionMap[key]

      const formatted = formatPickupDate(date) + ' ' + formatPickupTime(schedule.schedule.hours, schedule.location.type)

      const shouldShowOrderDeadline = getShouldShowOrderDeadline({ availDates, date })

      return (
        <View style={styles.deadlineCont}>
          <CheckBox
            checked={isSelected}
            onChange={(val) => {
              if (!isWholesale) {
                // In retail this selects multiple dates
                setters.selectionMap((prev) => ({ ...prev, [key]: val }))
              } else {
                // In wholesale this selects only one date at a time
                setters.selectionMap({ [key]: val })
              }
            }}
            disabled={!isSelected && !canSelectMore}
            title={formatted}
            textWeight="regular"
          />
          {shouldShowOrderDeadline && (
            <ErrorText numberOfLines={2} style={styles.deadlineText}>
              {`Order deadline: ${orderDeadline}`}
            </ErrorText>
          )}
        </View>
      )
    },
    [selectionMap, schedule, setters, orderDeadline, styles, canSelectMore, availDates, nDatesRemaining, isWholesale],
  )

  useEffect(() => {
    // this will trigger the "Show More Dates" button to appear if the user has selected at least the initial limited number of dates
    // and the user is not currently seeing all the dates
    if (shouldShowDatesFilter && availDatesLimited && selectedDates.length >= availDatesLimited.length)
      setters.isShowingMoreDates(true)
  }, [availDatesLimited, selectedDates, setters, shouldShowDatesFilter])

  return (
    <AppStatus scope="consumer">
      <View onLayout={(evt) => setters.currWidth(evt.nativeEvent.layout.width)} style={styles.container}>
        <Text>Select one or more dates that work with your schedule.</Text>
        <Divider clear />
        {scheduleButtons.length > 1 &&
          (!isSmallWindow ? (
            <ButtonGroup
              buttons={scheduleButtons}
              selectedIndex={availSchedules?.findIndex((sch) => sch.id === schedule?.id)}
            />
          ) : (
            <Picker
              value={schedule?.id}
              items={schedulePickerItems}
              onValueChange={(val) => {
                // The schedule won't be found if the user picks the placeholder item which is included by default as picker option
                const schedule = availSchedules?.find((sch) => sch.id === val)
                // Should only set it if it's found (I.e. if they selected an actual schedule and not the placeholder)
                if (schedule) setters.schedule(schedule)
              }}
              useWebNativePicker
            />
          ))}
        <LoadingView loading={!availDatesForUI} success={availDatesForUI} style={globalStyles.flex1}>
          {(availDates) => (
            <FlashList
              estimatedItemSize={25}
              data={availDates}
              renderItem={renderDate}
              extraData={[
                // extraData must have the same dependencies as the renderItem callback, to ensure the table will update the component
                selectionMap,
                schedule,
                setters,
                orderDeadline,
                styles,
                canSelectMore,
                availDates,
                nDatesRemaining,
              ]}
            />
          )}
        </LoadingView>
        {selectedDates.length < minimumDates && (
          <View style={styles.upperFooter}>
            <ErrorText style={globalStyles.margin10}>{`Select at least ${minimumDates} ${plural(
              minimumDates,
              'date',
            )}`}</ErrorText>
          </View>
        )}
        <View style={styles.upperFooter}>{stockRemainingUI}</View>
        <View style={styles.footer}>
          {shouldShowDatesFilter && (
            <Button
              title={`${isShowingMoreDates ? 'Hide' : 'See'} More Dates`}
              onPress={() => setters.isShowingMoreDates(!isShowingMoreDates)}
              outline
            />
          )}
          <View style={styles.footerFlexEndChild}>
            <Button
              title="Add to cart"
              onPress={() => onSelect(schedule!, selectedDates!)}
              disabled={disableAddCart}
              style={styles.footerBtn}
            />
          </View>
        </View>
      </View>
    </AppStatus>
  )
}

/** Modal component for selecting a schedule. Dates will get selected automatically on schedule selection. */
export function SetSchedule({
  prod,
  schedulesIdsFilter,
  locsIdsFilter,
  onSelect,
  initialSchedule,
  cartServiceType = 'consumer',
  isWholesale,
}: SetScheduleAndDatesComponentProps) {
  const [{ schedule, currWidth }, , , setters] = useKeyedState<{
    schedule: Distribution | undefined
    currWidth: number
  }>({ schedule: initialSchedule, currWidth: 0 })
  const compSizers = useWindowSizeFromWidth(currWidth)
  const { isSmallWindow } = compSizers
  const { isAdmin } = useCartService({ cartServiceType, isWholesale, farmId: prod.farm.id })

  /** These schedules all have pickups and belong to the location specified */
  const { availableSchedules } = useAvailableSchedules({
    prod,
    locsIdsFilter,
    schedulesIdsFilter,
    isAdmin,
    isWholesale,
    cartServiceType,
  })

  /** These are the dates available for each available schedule */
  const availDates = useMemo(() => {
    if (!availableSchedules) return undefined
    const availDates: { [id: Distribution['id']]: DateTime[] } = {}
    availableSchedules.forEach(
      (sch) =>
        (availDates[sch.id] = getPickupsCacheAddtoCartFlow(sch, prod, {
          excludeClosedDistros: !isAdmin,
          ignoreDisableBuyInFuture: isAdmin,
          ignoreOrderCutoffWindow: isAdmin,
        })),
    )
    return availDates
  }, [availableSchedules, isAdmin, prod])

  const styles = useStyles({ compSizers })

  const renderSchedule: ListRenderItem<Distribution> = useCallback(
    ({ item: scheduleItem }) => {
      if (!availDates) return null
      const dates = availDates[scheduleItem.id]

      if (!dates?.length) return null

      const orderDeadline = formatPickupDate(
        getOrderDeadlineFromPickup(
          dates[0],
          isAdmin ? 0 : scheduleItem.orderCutoffWindow,
          scheduleItem.schedule.hours.endTime,
        ),
      )
      const space = isSmallWindow ? ' ' : '\n'
      return (
        <Hoverable>
          {(isHovered) => (
            <Pressable
              key={scheduleItem.id}
              style={[
                styles.scheduleItem,
                scheduleItem.id === schedule?.id && styles.selectedItem,
                isHovered && styles.hovered,
              ]}
              onPress={() => setters.schedule(scheduleItem)}
            >
              <Text style={[styles.scheduleItemTextLine, globalStyles.flex2]} type="bold" size={15} numberOfLines={2}>
                {scheduleItem.name}
              </Text>
              <Text
                style={[styles.scheduleItemTextLine, globalStyles.flex1]}
                numberOfLines={2}
              >{`Next date:${space}${formatPickupDate(dates[0])}`}</Text>
              <Text style={[styles.scheduleItemTextLine, globalStyles.flex1]} numberOfLines={2}>
                {isLocalPickup(scheduleItem.location)
                  ? `Duration:${space}${dates.length} ${scheduleItem.schedule.frequency} ${formatDistributionType(
                      scheduleItem.location,
                      {
                        plural: dates.length > 1,
                      },
                    )}`
                  : `Delivery fee:${space}${formatMoney(scheduleItem.location.cost ?? Zero)}`}
              </Text>
              <Text
                style={[styles.scheduleItemTextLine, globalStyles.flex1]}
                numberOfLines={2}
              >{`Order deadline:${space}${orderDeadline}`}</Text>
            </Pressable>
          )}
        </Hoverable>
      )
    },
    [availDates, isAdmin, schedule, styles, isSmallWindow, setters],
  )

  return (
    <AppStatus scope="consumer">
      <View style={styles.container} onLayout={(evt) => setters.currWidth(evt.nativeEvent.layout.width)}>
        <LoadingView
          loading={!availableSchedules || !availDates}
          success={availableSchedules}
          style={globalStyles.flex1}
        >
          {(availableSchedules) => (
            <FlashList
              estimatedItemSize={isSmallWindow ? 180 : 160}
              data={availableSchedules}
              renderItem={renderSchedule}
              extraData={[availDates, schedule]}
            />
          )}
        </LoadingView>
        <Button
          style={styles.setScheduleAddBtn}
          title="Add to cart"
          onPress={() => onSelect(schedule!, availDates![schedule!.id]!)}
          disabled={!schedule || !availDates?.[schedule.id]}
        />
      </View>
    </AppStatus>
  )
}

type StyleOpts = { compSizers: WindowSizers }

const useStyles = (opts: StyleOpts) =>
  useDeepCompareLayoutFnStyles(
    (layout, { compSizers }) => ({
      container: {
        flex: 1,
        backgroundColor: Colors.white,
        padding: 15,
        /** It's only using the bottom inset on small size because the mobile large modal by default is a window that floats in the center of the screen, and is far away from the screen edges. */
        paddingBottom: layout.isSmallDevice ? layout.bottom : undefined,
      },
      footer: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
      },
      upperFooter: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      footerFlexEndChild: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      dateItem: {
        flexDirection: 'row',
        margin: 5,
        padding: 5,
      },
      scheduleItem: {
        flexDirection: compSizers.isSmallWindow ? 'column' : 'row',
        margin: 5,
        padding: 10,
        paddingBottom: 30,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: Colors.shades['100'],
      },
      scheduleItemTextLine: {
        marginVertical: 10,
      },
      selectedItem: {
        backgroundColor: Colors.lightGray,
        borderWidth: 1,
        borderColor: Colors.green,
      },
      deadlineCont: {
        flex: 1,
        flexDirection: compSizers.isExtraSmallWindow ? 'column' : 'row',
        alignItems: compSizers.isExtraSmallWindow ? 'flex-start' : 'center',
        margin: 5,
      },
      deadlineText: {
        marginLeft: 10,
      },
      stockRemainingText: {
        margin: 10,
      },
      footerBtn: {
        maxWidth: 200,
      },
      hovered: {
        borderColor: Colors.green,
        borderWidth: 1,
        backgroundColor: Colors.lightGray,
      },
      setScheduleAddBtn: {
        alignSelf: 'flex-end',
        marginTop: 20,
      },
    }),
    opts,
  )

/** Options for the entry function that opens the modal */
type SetScheduleAndDatesFnOpts = Omit<
  SetScheduleAndDatesComponentProps,
  'onSelect' | 'prod' | 'locsIdsFilter' | 'schedulesIdsFilter'
> & {
  prod: PhysicalProduct
  /** The location filter has the location selected by the user in an earlier step, or the locations compatible with the delivery address entered in an earlier step */
  locsFilter: Distribution['location'][]
  schedulesFilter: Distribution[]
  modalOpts?: Omit<Parameters<typeof Modal>[1], 'onDismiss'>
  cart: CartItem[]
}

const getScheduleLabel = ({ frequency, dayOfWeek }: Distribution['schedule']) => {
  if (frequency === Frequency.DAILY || frequency === Frequency.MONTHLY) {
    return capitalize(frequency)
  }

  if (frequency === Frequency.WEEKLY) {
    return getDayofWeekName(dayOfWeek) + 's'
  }

  return `${getDayofWeekName(dayOfWeek)}s, ${capitalize(frequency)}`
}

/** Flow function that gets the schedule and dates for the addToCart flow, for any type of product and location.
 * - The modal components in this flow should only be accessed through this flow function
 */
export function setScheduleAndDates({
  prod,
  locsFilter,
  schedulesFilter,
  modalOpts,
  initialSchedule,
  unit,
  cartServiceType = 'consumer',
  isWholesale,
  cart,
}: SetScheduleAndDatesFnOpts): Promise<{ schedule: Distribution; dates: DateTime[] } | undefined> {
  return new Promise(async (resolve, reject) => {
    const isAdmin = getIsAdminForCartServiceType(cartServiceType)

    if (isShare(prod)) {
      // Get the available schedules for the schedule selection step. This should use the EXACT SAME logic for the getting the schedules as that used inside the SetSchedule component for the same operation
      const { availSchedules } = getAvailableSchedules({
        prod,
        isAdmin,
        schedulesIdsFilter: schedulesFilter.map((sch) => sch.id),
        locsIdsFilter: locsFilter.map((loc) => loc.id),
        isWholesale,
        cart,
      })

      if (availSchedules.length === 1) {
        // If there is only one available schedule use it as the initial schedule
        initialSchedule = availSchedules[0]
      }

      return Modal(
        <SetSchedule
          prod={prod}
          schedulesIdsFilter={schedulesFilter.map((sch) => sch.id)}
          locsIdsFilter={locsFilter.map((loc) => loc.id)}
          onSelect={(schedule, dates) => {
            // For shares this is the last step because there is no manual date selection
            hideModal()
            return resolve({ schedule, dates })
          }}
          cartServiceType={cartServiceType}
          initialSchedule={initialSchedule}
          unit={unit}
          isWholesale={isWholesale}
        />,
        {
          webWidth: 1000,
          title: 'Select Schedule',
          ...modalOpts,
          onDismiss: () => {
            hideModal()
            resolve(undefined)
          },
        },
      )
    }

    if (isStandard(prod)) {
      const formatTitle = () => {
        if (locsFilter.some(isDelivery)) {
          return 'When do you want your deliveries?'
        }
        if (locsFilter.some(isShipping)) {
          return 'When do you want your shipments?'
        }
        return 'When do you want to pickup?'
      }

      // For physical products that support manual date selection
      return Modal(
        <SetScheduleAndDates
          prod={prod}
          schedulesIdsFilter={schedulesFilter.map((sch) => sch.id)}
          locsIdsFilter={locsFilter.map((loc) => loc.id)}
          cartServiceType={cartServiceType}
          onSelect={(schedule, dates) => {
            hideModal()
            resolve({ schedule, dates })
          }}
          initialSchedule={initialSchedule}
          unit={unit}
          isWholesale={isWholesale}
        />,
        {
          webWidth: 1000,
          title: formatTitle(),
          ...modalOpts,
          onDismiss: () => {
            hideModal()
            resolve(undefined)
          },
        },
      )
    } else {
      return reject(new DataError('Invalid product for this modal', prod))
    }
  })
}

/** Intends to show the order deadline on the same date item that was used for the order deadline text */
function getShouldShowOrderDeadline({ availDates, date }: { availDates: DateTime[]; date: DateTime }): boolean {
  if (!availDates) return false

  const dateForDeadline = getDateForOrderDeadline(availDates)

  if (!dateForDeadline) return false

  return formatPickupDate(dateForDeadline) === formatPickupDate(date)
}

function getDateForOrderDeadline(availDates: DateTime[] | undefined): DateTime | undefined {
  return availDates?.[0]
}
