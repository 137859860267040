import { Farm } from './Farm'
import { RegionType } from './Location'
import { Money, MoneyWithCurrency } from './Money'

/** Fee type for Product fee */
export enum FeeType {
  Tax = 'tax',
  /** flat fee */
  Fee = 'fee',
}

/** Value type for Product fee */
export enum FeeValueType {
  Fixed = 'fixed',
  Percent = 'percent',
}

/** none general means no value for it. It is used in ProductFee.regionType. If regionType is not State or Zipcode. It should be none. */
export const NoneValue = 'none'

/** Identifies product fees like taxes or flat fees for farms and should be applied for products */
export type ProductFee<Type = FeeType, Region = RegionType> = {
  id: string

  /** Name of the tax or fee */
  name: string

  farm: Pick<Farm, 'id'>

  /** The type of fee, which can be a tax or flat fee */
  type: Type

  /** It should be fixed or percent type. For now all taxes are percentage and all fees are fixed */
  valueType: Type extends FeeType.Tax ? FeeValueType.Percent : Type extends FeeType.Fee ? FeeValueType.Fixed : never

  /** Will be the value of the Fee, either money amount or percent amount. Percent represented as decimal, ex. 20% = 0.2. For now, all taxes values are number and all fees are Money */
  value: Type extends FeeType.Tax ? number : Type extends FeeType.Fee ? MoneyWithCurrency : never

  /** If the tax or fee is archived then it will be hidden */
  archived: boolean

  /** The type of region we will apply the fee to */
  regionType: Region | typeof NoneValue

  /** A list of states or postal codes that the tax or fee will apply to */
  regions: string[]
}

/**
 * Represents an array of product fees for an invoice.
 * Each element contains details of a product fee and its associated amount.
 */
export type ProductFeesForInvoice = { productFee: ProductFee; amount: Money }[]

/** Identifies a product fee whose value is a percentage */
export function isPercentProductFee<T extends ProductFee>(
  fee: Pick<T, 'valueType'>,
): fee is Omit<T, 'value'> & { value: number } {
  return fee.valueType === FeeValueType.Percent
}

/** Identifies a product fee whose value is a fixed money amount */
export function isFixedProductFee<T extends ProductFee>(
  fee: Pick<T, 'valueType'>,
): fee is Omit<T, 'value'> & { value: MoneyWithCurrency } {
  return fee.valueType === FeeValueType.Fixed
}

/** Identifies a ProductFee of Fee type */
export function isFeeProductFee(productFee: Pick<ProductFee, 'type'>): productFee is ProductFee<FeeType.Fee> {
  return productFee.type === FeeType.Fee
}

/** Identifies a ProductFee of Tax type */
export function isTaxProductFee(productFee: Pick<ProductFee, 'type'>): productFee is ProductFee<FeeType.Tax> {
  return productFee.type === FeeType.Tax
}
