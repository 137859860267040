import { useRangeRefinement } from '@/hooks/useAlgoliaRangeRefinement'
import { useFocusFx } from '@/hooks/useFocusFx'
import { internationalSelector, wholesaleSelector } from '@/redux/selectors'
import { getNearbyZipcodes } from '@api/Addresses'
import { getCoordString } from '@helpers/coordinate'
import { isNum } from '@helpers/helpers'
import { Coordinate } from '@models/Coordinate'
import { LocType } from '@screens/SearchScreen/searchScreen-helpers'
import { DateTime } from 'luxon'
import { useMemo, useRef, useState } from 'react'
import { useConfigure } from 'react-instantsearch'
import { useSelector } from 'react-redux'
import { useApiFx } from '../useApiFx'
import { getFiltersString } from './helpers'

type SearchConfigureProps = {
  center?: Coordinate
  radius?: number
  locType?: LocType
  region?: string
  includeNearbyZipcodes?: boolean
  farmIdsFilter?: string[]
}

const SEARCH_HITS_PER_PAGE = 400

/** Wrapper for algolia useConfigure for the search screen */
export function useSearchScreenConfigure({
  center,
  radius,
  locType,
  region,
  includeNearbyZipcodes,
  farmIdsFilter,
}: SearchConfigureProps) {
  const { isWholesale = false } = useSelector(wholesaleSelector)
  const [minDate, setMinDate] = useState(getMinAvailDate())
  const { country } = useSelector(internationalSelector)

  const updateCount = useRef(0)
  useFocusFx(() => {
    const intervalId = setInterval(() => {
      updateCount.current++

      if (updateCount.current > 5) {
        clearInterval(intervalId)
        return
      }

      setMinDate(getMinAvailDate())
    }, MIN_MINUTES_FROM_NOW * 60 * 1000)

    return () => clearInterval(intervalId)
  }, [])

  useRangeRefinement('lastAvailStamp', { min: minDate })

  // If there's a region zip code should fetch nearby zip codes
  const nearbyZipFx = useApiFx(getNearbyZipcodes, [region, undefined, country], includeNearbyZipcodes === true, {
    noRefocus: true,
  })

  // Calculates the geolocation search configuration
  const { aroundLatLng, aroundRadius } = useMemo(() => {
    const aroundLatLng = getCoordString(center) || undefined
    const aroundRadius = !aroundLatLng
      ? undefined // If there's no center coords it doesn't make sense to use the radius parameter
      : typeof radius === 'number'
      ? Math.floor(radius) // No floats
      : typeof radius === 'string' && isNum(radius)
      ? parseInt(radius, 10)
      : undefined

    if (locType === undefined || locType === 'coord') {
      /** Returning a value for these options will include products that have a pickup option, because if you pass the aroundLatLng option
       * algolia will include products that have the _geoloc property for their coordinates (And only pickup products have the _geoloc property).
       *
       * Then if aroundRadius is defined, it will further narrow those products based on their distance from the center coords. But if the aroundRadius is undefined then it should include all the products in the index that have a pickup option.
       */

      return { aroundLatLng, aroundRadius }
    }

    // This will deactivate any filtering based on the geolocation field, which is necessary when filtering by nonPickup loc types because those documents have undefined geolocation data, so if the location type is not 'coord' then these values should be undefined. This will ensure if the location type is delivery or shipping the search will not be based on geolocation data, but rather based on the delivery region.
    return { aroundLatLng: undefined, aroundRadius: undefined }
  }, [locType, radius, center])

  useConfigure({
    filters: getFiltersString({
      isWholesale,
      locType,
      region,
      includeNearbyZipcodes,
      nearbyZipFx,
      center,
      country,
      farmIdsFilter,
    }),
    aroundLatLng,
    aroundRadius,
    // Hits are needed only on Search screen
    hitsPerPage: SEARCH_HITS_PER_PAGE,
    // Will make sure that the filter counts are taking in account only one version of the product
    facetingAfterDistinct: true,
  })
}

/** How many minutes from now is the minimum acceptable value for the last available timestamp filter. That means the filter is including products whose last available timestamp is at least this much time from now. */
export const MIN_MINUTES_FROM_NOW = 5

/** Returns a future timestamp based on the current time, which will be used as a filter for the minimum last available timestamp for a product */
export const getMinAvailDate = () => DateTime.now().plus({ minutes: MIN_MINUTES_FROM_NOW }).toMillis()
