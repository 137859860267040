import { CheckBox, Text, TextH2, Tooltip } from '@elements'

import React, { memo } from 'react'
import { StyleSheet, View } from 'react-native'

import { AdminCard } from '@/admin/components/AdminCard'
import { globalStyles } from '@/constants/Styles'
import { ToolTips } from '@components'
import { sharedStyles } from '../styles'
import { EBTPayments } from './EbtPayments'
import { OfflinePayments } from './OfflinePayments'
import { UsePaymentsDataType } from './PaymentsTab.controller'
import { StripeConnection } from './StripeConnection'
import { TipOptions } from './TipOptions'

/** View layer of the payments tab*/
export const PaymentsTab = memo(function PaymentsTab(props: UsePaymentsDataType) {
  const { formik, hasGrownByAdminPrivilege, farm } = props
  return (
    <View style={sharedStyles.baseGap}>
      <View style={sharedStyles.tabHeaderCont}>
        <TextH2 size={16}>Receive Payments</TextH2>
      </View>

      <AdminCard noMargins>
        <TextH2 size={18} style={styles.titleCard}>
          Connect with Payment Processor
        </TextH2>
        <StripeConnection />
      </AdminCard>

      <EBTPayments {...formik} farm={farm} hasGrownByAdminPrivilege={hasGrownByAdminPrivilege} />

      <View style={sharedStyles.tabHeaderCont}>
        <TextH2 size={16}>Additional Options</TextH2>
      </View>

      {farm.address.country === 'US' && (
        <AdminCard style={sharedStyles.baseGap} noMargins>
          <View style={globalStyles.flexRowCenter}>
            <TextH2 size={18}>Bank Account Transfer (ACH)</TextH2>
            <Tooltip size={14} id={ToolTips.ACH_PAYMENT_SETTING} />
          </View>

          <CheckBox
            checked={formik.values.achPaymentEnabled}
            onChange={(val) => formik.setFieldValue('achPaymentEnabled', val)}
            title="Enable Bank Account Transfers (ACH)"
          />

          <Text>
            Fees for ACH are 1% of the sale, capped at $5. Farm accounts are charged $1.50 for each new customer who
            signs up for bank deposit payment.
          </Text>
        </AdminCard>
      )}

      <OfflinePayments {...formik} />

      <TipOptions {...formik} />
    </View>
  )
})

const styles = StyleSheet.create({
  titleCard: {
    marginBottom: 6,
  },
})
