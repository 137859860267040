import { StateHelpers } from '@helpers/international/types'

const statesJSONCanada = [
  { name: 'Alberta', abbreviation: 'AB' },
  { name: 'British Columbia', abbreviation: 'BC' },
  { name: 'Manitoba', abbreviation: 'MB' },
  { name: 'New Brunswick', abbreviation: 'NB' },
  { name: 'Newfoundland and Labrador', abbreviation: 'NL' },
  { name: 'Nova Scotia', abbreviation: 'NS' },
  { name: 'Ontario', abbreviation: 'ON' },
  { name: 'Prince Edward Island', abbreviation: 'PE' },
  { name: 'Quebec', abbreviation: 'QC' },
  { name: 'Saskatchewan', abbreviation: 'SK' },
  { name: 'Northwest Territories', abbreviation: 'NT' },
  { name: 'Nunavut', abbreviation: 'NU' },
  { name: 'Yukon', abbreviation: 'YT' },
] as const

export type StateMapCanada = typeof statesJSONCanada extends readonly (infer T)[] ? T : never

export type ShortStateCanada = StateMapCanada['abbreviation']

function getShortState(name: string): ShortStateCanada | '' {
  return getState(name)?.abbreviation ?? ''
}

function getState(name: string): StateMapCanada | undefined {
  return statesJSONCanada.find(
    (stMap) =>
      stMap.name.toLowerCase() === name.trim().toLowerCase() ||
      stMap.abbreviation.toLowerCase() === name.trim().toLowerCase(),
  )
}

function listStateAbbr(): ShortStateCanada[] {
  return statesJSONCanada.map((state) => state.abbreviation)
}

function stateItemList(): {
  label: string
  value: string
}[] {
  return statesJSONCanada.map((state) => ({ label: state.name, value: state.abbreviation }))
}

export const stateHelpersCA: StateHelpers<typeof statesJSONCanada> = {
  getShortState,
  getState,
  listStateAbbr,
  stateItemList,
}
