import { Divider, fontSize, Text, typography } from '@elements'
import {
  formatAddress,
  formatMoney,
  formatPickupTime,
  formatShortDate,
  formatTime,
  getOrderNum,
  InvalidAmount,
  unmarshalPhoneNumber,
} from '@helpers/display'
import { format } from '@helpers/time'
import { getBusinessNameOrUsername } from '@helpers/user'
import { isDelivery, isNonPickup, isShipping } from '@models/Location'
import { Money } from '@models/Money'
import { PackListSummary, PaymentStatus } from '@models/Summary'
import { ScrollView, StyleSheet, View } from 'react-native'

import { adminCard } from '../../constants/AdminCards'

import Colors from '@/constants/Colors'
import { useLayout } from '@/hooks/useLayout'
import { formatPaymentStatus, selectedUnitName, selectedUnitPrice, selectedUnitTotal } from '@helpers/reports'

type PackingProps = {
  summary: PackListSummary
}

export default function PackingSheets({ summary }: PackingProps) {
  const layout = useLayout()

  // Prepare the report data.
  const rows: ReportRow[] = []
  for (const summaryItem of summary.items) {
    const selectedUnits: ReportRow['selectedUnits'] = []
    for (const item of summaryItem.items) {
      for (const selectedUnit of item.selectedUnits) {
        selectedUnits.push({
          sku: selectedUnit.unit?.sku,
          productName: item.product.name,
          unit: selectedUnitName(item, selectedUnit),
          quantity: selectedUnit.quantity,
          amount: selectedUnitPrice(selectedUnit),
          total: selectedUnitTotal(selectedUnit),
        })
      }
    }
    rows.push({
      customerName: getBusinessNameOrUsername(summaryItem.user),
      customerEmail: summaryItem.user.email,
      customerAddress: summaryItem.user.address ? formatAddress(summaryItem.user.address) : '',
      customerPhone: summaryItem.user.phoneNumber ? unmarshalPhoneNumber(summaryItem.user.phoneNumber, false) : '',
      locationZoneName: summaryItem.location.name,
      locationHours: summaryItem.location.hours
        ? formatPickupTime(summaryItem.location.hours, summaryItem.location.type)
        : '',
      orderNum: getOrderNum(summaryItem.order.orderNum),
      orderDateTime: `${formatShortDate(summaryItem.order.date)} at ${formatTime(summaryItem.order.date)}`,
      type: isShipping(summaryItem.location) ? 'Shipping' : isDelivery(summaryItem.location) ? 'Delivery' : '',
      address: summaryItem.location.address ? formatAddress(summaryItem.location.address) : InvalidAmount,
      addressNotes:
        summaryItem.location.address && isNonPickup(summaryItem.location)
          ? summaryItem.location.address.notes
          : undefined,
      deliveryDate: format(summaryItem.deliveryDate, 'MM/dd/yyyy'),
      paymentStatus: summaryItem.paymentStatus,
      selectedUnits,
    })
  }

  return (
    <View>
      {rows.map((item, index) => (
        <View
          key={`${item.orderNum}_${index}`}
          style={[
            adminCard(layout),
            packStyles.adminContStyle,
            {
              width: layout.width / 1.1,
            },
          ]}
        >
          <ScrollView
            horizontal
            style={{
              width: !layout.isSmallDevice ? layout.width / 1.1 : undefined,
            }}
            contentContainerStyle={[
              packStyles.scrollViewContentContain,
              {
                width: !layout.isSmallDevice ? layout.width / 1.1 : undefined,
              },
            ]}
          >
            <View style={packStyles.packingSheetsContainer}>
              <View>
                <Text type="bold" style={packStyles.subtext}>
                  Order Details
                </Text>
                <Text style={packStyles.subtext}>Order: {item.orderNum}</Text>
                <Text style={packStyles.subtext}>{item.orderDateTime}</Text>
                <Text style={packStyles.subtext}>{formatPaymentStatus(item.paymentStatus)}</Text>
              </View>

              <View>
                <Text type="bold" style={packStyles.subtext}>
                  {item.type || 'Pickup'} Details
                </Text>
                <Text style={packStyles.subtext}>
                  {item.deliveryDate} {item.locationHours}
                </Text>
                <Text style={packStyles.subtext}>{item.locationZoneName}</Text>
                <Text style={packStyles.subtext}>{item.address}</Text>
                {!!item.addressNotes && <Text style={packStyles.subtext}>Notes: {item.addressNotes}</Text>}
              </View>

              <View>
                <Text type="bold" style={[packStyles.subtext, packStyles.textAlignRight]}>
                  Customer Details
                </Text>
                <Text style={[packStyles.subtext, packStyles.textAlignRight]}>{item.customerName}</Text>
                <Text style={[packStyles.subtext, packStyles.textAlignRight]}>{item.customerEmail}</Text>
                <Text style={[packStyles.subtext, packStyles.textAlignRight]}>{item.customerAddress}</Text>
                <Text style={[packStyles.subtext, packStyles.textAlignRight]}>{item.customerPhone}</Text>
              </View>
            </View>

            <View>
              <View style={packStyles.tableHeader}>
                <Text style={packStyles.divTextHeader}>Item</Text>
                <View style={packStyles.groupDivTextHeader}>
                  <Text style={packStyles.divTextHeader}>Quantity|Buying Option</Text>
                  <Text style={packStyles.divTextHeader}>Price ea.</Text>
                  <Text style={packStyles.divTextHeader}>Total Price</Text>
                </View>
              </View>

              {item.selectedUnits.map((selectedUnit) => (
                <View key={`${selectedUnit.productName}_${selectedUnit.sku}_${selectedUnit.unit || 'share'}`}>
                  <Divider />
                  <View style={packStyles.itemWrapper}>
                    <Text style={packStyles.subtext}>{selectedUnit.productName}</Text>
                    <View style={packStyles.groupSubText}>
                      <Text style={packStyles.subtext}>
                        {selectedUnit.quantity}x {selectedUnit.unit}
                      </Text>
                      <Text style={packStyles.subtext}>{selectedUnit.amount}</Text>
                      <Text style={packStyles.subtext}>{formatMoney(selectedUnit.total)}</Text>
                    </View>
                  </View>
                </View>
              ))}
            </View>
          </ScrollView>
        </View>
      ))}
    </View>
  )
}

type ReportRow = {
  customerName: string
  customerEmail: string
  customerAddress: string
  customerPhone: string
  locationZoneName: string
  locationHours: string
  orderNum: string
  orderDateTime: string
  type: string
  address: string
  addressNotes?: string
  deliveryDate: string
  paymentStatus: PaymentStatus
  selectedUnits: {
    sku: string | undefined
    productName: string
    unit: string
    quantity: number
    total: Money
    amount: string
  }[]
}

const packStyles = StyleSheet.create({
  packingSheetsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 10,
    marginBottom: 10,
  },
  groupSubText: {
    fontSize: fontSize(15, 2),
    padding: 5,
    color: Colors.black,
    justifyContent: 'space-between',
    flex: 1.5,
    flexDirection: 'row',
    textAlign: 'right',
  },
  subtext: {
    fontSize: fontSize(15, 2),
    padding: 5,
    color: Colors.black,
    justifyContent: 'space-between',
    flex: 1,
  },
  tableHeader: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingHorizontal: 10,
    paddingVertical: 10,
    marginHorizontal: 10,
    justifyContent: 'space-between',
    backgroundColor: '#F8FBF7',
    borderWidth: 0,
    flex: 1,
  },
  groupDivTextHeader: {
    flexDirection: 'row',
    fontSize: 18,
    padding: 5,
    fontFamily: typography.body.regular,
    color: '#555555',
    textAlign: 'right',
    flex: 1.5,
  },
  divTextHeader: {
    flexDirection: 'row',
    fontSize: 18,
    padding: 5,
    fontFamily: typography.body.regular,
    color: '#555555',
    flex: 1,
  },
  itemWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingHorizontal: 10,
    paddingVertical: 10,
    justifyContent: 'space-between',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  scrollViewContentContain: {
    flexDirection: 'column',
    minWidth: 800,
  },
  adminContStyle: {
    alignSelf: 'center',
    marginBottom: 20,
    padding: 0,
    paddingVertical: 20,
    flexDirection: 'column',
  },
})
