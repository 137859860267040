import { Hit } from '@algolia/client-search'
import { defaultAroundRadius, getCoordString } from '@helpers/coordinate'
import { InvalidAmount } from '@helpers/display'
import { errorToString, removeObjDuplicates } from '@helpers/helpers'
import { isActive, matchesAppModeProduct } from '@helpers/products'
import { omit } from '@helpers/typescript'
import { AlgoliaGeoDoc, AlgoliaGeoProduct, FILTERS, FacetFilterBase, asFilter, asNumFilter } from '@models/Algolia'
import { Coordinate } from '@models/Coordinate'
import { Farm } from '@models/Farm'
import { isPhysical, PhysicalProduct, Product, ProductType } from '@models/Product'
import {
  and,
  limit,
  or,
  where,
  type QueryCompositeFilterConstraint,
  type QueryNonFilterConstraint,
  runTransaction,
} from 'firebase/firestore'
// eslint-disable-next-line no-restricted-imports
import { getDoc, doc } from 'firebase/firestore'

import { consumerIndex } from '../config/Algolia'
import { updateFarm } from './Farms'
import { productsCollection } from './framework/ClientCollections'

import { OrderProductType } from '@/admin/navigation/types'
import { getProdTypes } from '@/admin/screens/Order/OrderCreatorScreen/OrderCreatorScreen.helper'
import { Logger } from '@/config/logger'
import { createAvailabilityFilter, parseAlgoliaResults } from '@helpers/algolia-client'
import { buildProduct } from '@helpers/builders/buildProduct'
import { CountryCode } from '@helpers/international/types'
import { sortByRankAndName } from '@helpers/sorting'
import { formatToSafeSlug } from '@helpers/urlSafeSlug'
import { GooglePlace } from '@models/Address'
import { ErrorWithCode } from '@shared/Errors'
import { SearchIndex } from 'algoliasearch'
import { DateTime } from 'luxon'
import { extractLocationComponents } from './Addresses'
import { errorCatcher } from './Errors'
import { buildUrlSafeSlug } from './UrlSafeSlugs'
import { callEndpoint } from './v2'
import { db } from './db'
import { unmarshalDistribution } from './encoding/Distribution'
import { marshalProduct } from './encoding/Product'
import { marshalDate } from './encoding/Time'
import { collectionMap } from './encoding/CollectionMap'

/** loadProductsByFarm returns all of the available products for a farm.
 * - If the farm has too many products, there might be an untraceable crash with no error on mobile, therefore filtering by isHidden is best done server-side, and limit should also prevent that.
 */
export async function loadProductsByFarm(farmSlug: string, limitN = 100): Promise<Product[]> {
  return productsCollection.fetchAll(
    and(
      or(where('farm.urlSafeSlug', '==', farmSlug), where('farm.id', '==', farmSlug)),
      where('isHidden', '==', false),
    ),
    limit(limitN),
  )
}

/** loadProductsByDistribution returns all of the available products for a distribution.
 * - This api should NOT filter by "isHidden: false". schedule editing validation depends on getting ALL products for the distId
 */
export async function loadProductsByDistribution(distId: string, farmSlug: string): Promise<Product[]> {
  return productsCollection.fetchAll(
    and(
      or(where('farm.urlSafeSlug', '==', farmSlug), where('farm.id', '==', farmSlug)),
      where(`distributions.${distId}.id`, '==', distId),
    ),
  )
}

/** Loads all the featured farm products that are not hidden*/
export async function loadFeaturedByFarm(farmSlug: string, isWholesale: boolean | undefined): Promise<Product[]> {
  const prods = await productsCollection.fetchAll(
    and(
      or(where('farm.urlSafeSlug', '==', farmSlug), where('farm.id', '==', farmSlug)),
      where('isFeatured', '==', true),
      where('isHidden', '==', false),
    ),
  )

  // Filter out any products that do not match the app mode. This is done as a post-processing step because adding this to the above query is too complex for Firestore
  const prodsForAppMode = prods.filter(matchesAppModeProduct(isWholesale))
  // This fulfills the requirement of the "Only show in CSA" field, for the FarmDetail screen, which is to exclude any product that has this value set to true
  return prodsForAppMode.filter((p) => !p.hideFromShop).sort(sortByRankAndName)
}

/** loadProductsByFarmAndType returns all non-hidden products for a farm filtered by the supplied product type. */
export async function loadProductsByFarmAndType(
  farmSlug: string,
  productType: ProductType,
  /** Optional limit, if undefined will not limit the query */
  limitN?: number,
): Promise<Product[]> {
  const queryParams: [QueryCompositeFilterConstraint, ...QueryNonFilterConstraint[]] = [
    and(
      or(where('farm.urlSafeSlug', '==', farmSlug), where('farm.id', '==', farmSlug)),
      where('type', '==', productType),
      where('isHidden', '==', false),
    ),
  ]

  // Only add a limit if we explicitly pass one to this function
  if (limitN) queryParams.push(limit(limitN))

  return productsCollection.fetchAll(...queryParams)
}

/** loadProductsByCSA returns all of the available products for a CSA. */
export async function loadProductsByCSA(csaId: string): Promise<Product[]> {
  return productsCollection.fetchAll(where('csa', 'array-contains', csaId))
}

/** loadProduct returns the product stored at the supplied ID. */
export async function loadProduct<T extends Product = Product>(productId: string): Promise<T> {
  return productsCollection.fetch(productId) as Promise<T>
}

/** addProduct saves a new product to the database with validation. */
export async function addProduct(product: Product, farm?: Farm): Promise<Product> {
  if (!product.farm?.id && !farm?.id)
    throw new ErrorWithCode({
      code: 'farm-id-required',
      devMsg: 'The farmId is required to add a product',
    })
  const newId = productsCollection.reference().id
  // Validate the urlSafeSlug
  let validSafeSlug = formatToSafeSlug(product.name)
  validSafeSlug = await buildUrlSafeSlug({
    slug: validSafeSlug,
    type: 'create',
    collection: 'products',
    id: newId,
    farmId: product.farm.id ?? farm?.id,
  })

  if (!validSafeSlug) {
    throw new ErrorWithCode({
      code: 'invalid-slug',
      devMsg:
        /**TODO: Use this when urlSafeSlug feature is implemented. Please choose a distinct name for your product. The product name is essential for creating a unique and secure URL. */
        'Please choose a distinct name for your product to separate it from others.',
    })
  }
  // Assign valid urlSafeSlug
  const newProduct = { ...product, id: newId, urlSafeSlug: validSafeSlug }

  // Validate the product
  buildProduct(newProduct)

  await productsCollection.createWithId(newProduct)

  // Update onboard walk-through
  if (farm && farm.onboardSteps && !farm.onboardSteps?.products) {
    updateFarm({ id: farm.id, onboardSteps: { ...(farm.onboardSteps || {}), products: true } })
  }
  return newProduct
}

/** updateProductInfo updates the product with a validated update */
export async function updateProduct(updatedProduct: Product): Promise<Product> {
  // When the product name is included or changed and, we need to validate the urlSafeSlug
  if (updatedProduct.name) {
    if (!updatedProduct.farm?.id)
      throw new ErrorWithCode({
        code: 'farm-id-required',
        devMsg: 'The farmId is required to update the product name.',
      })

    let validSafeSlug = formatToSafeSlug(updatedProduct.name)

    validSafeSlug = await buildUrlSafeSlug({
      slug: validSafeSlug,
      type: 'update',
      collection: 'products',
      farmId: updatedProduct.farm.id,
      id: updatedProduct.id,
    })

    if (!validSafeSlug) {
      throw new ErrorWithCode({
        code: 'invalid-slug',
        devMsg:
          /**TODO: Use this when urlSafeSlug feature is implemented. Please choose a distinct name for your product. The product name is essential for creating a unique and secure URL. */
          'Please choose a distinct name for your product to separate it from others.',
      })
    }
    // Assign valid urlSafeSlug
    updatedProduct.urlSafeSlug = validSafeSlug
  }

  // Validate the product
  buildProduct(updatedProduct)

  // @ts-expect-error: The update method is problematic
  await productsCollection.update({
    ...omit(
      updatedProduct,
      'farm' /** Omitting the farm just in case, because it shouldn't be updated in this manner anyway */,
    ),
    isDraft: false,
  })

  return updatedProduct
}

/** overWriteProduct sets the product with a validated update and prevent bad data from editing every time */
export async function overWriteProduct(updatedProduct: Product): Promise<Product> {
  // When the product name is included or changed and, we need to validate the urlSafeSlug
  if (updatedProduct.name) {
    if (!updatedProduct.farm?.id)
      throw new ErrorWithCode({
        code: 'farm-id-required',
        devMsg: 'The farmId is required to update the product name.',
      })

    let validSafeSlug = formatToSafeSlug(updatedProduct.name)

    validSafeSlug = await buildUrlSafeSlug({
      slug: validSafeSlug,
      type: 'update',
      collection: 'products',
      farmId: updatedProduct.farm.id,
      id: updatedProduct.id,
    })

    if (!validSafeSlug) {
      throw new ErrorWithCode({
        code: 'invalid-slug',
        devMsg:
          /**TODO: Use this when urlSafeSlug feature is implemented. Please choose a distinct name for your product. The product name is essential for creating a unique and secure URL. */
          'Please choose a distinct name for your product to separate it from others.',
      })
    }
    // Assign valid urlSafeSlug
    updatedProduct.urlSafeSlug = validSafeSlug
  }

  // Validate the product
  buildProduct(updatedProduct)

  // If there are no distributions we can save the product directly
  if (!isPhysical(updatedProduct)) {
    await productsCollection.set(updatedProduct)
    return updatedProduct
  }

  // If the product has distributions we must confirm that we are saving the product with the most up-to-date distributions
  await saveProductWithUpdatedDistros(updatedProduct)
  return updatedProduct
}

/** This function will use a transaction to always save a product with the most up-to-date distributions available. */
async function saveProductWithUpdatedDistros(updatedProduct: PhysicalProduct): Promise<void> {
  // We need to do all the below so that we can get the metadata from the existing document to persist after the update
  const prodRef = doc(db(), 'products', updatedProduct.id)
  const now = marshalDate(DateTime.now())
  const existingProdMeta = (await getDoc(prodRef)).data()?.meta
  const newMeta = {
    updatedAt: now,
    createdAt: existingProdMeta?.createdAt ?? now,
    lastAccessed: existingProdMeta?.lastAccessed ?? now,
    // Will get the data version from the current products collection
    version: collectionMap['products'][3],
  }

  await runTransaction(db(), async (tx) => {
    // In a transaction you can't do fetch all so we are loading them all one at a time
    const distRefs = updatedProduct.distributions.map((dist) => doc(db(), 'distributions', dist.id))
    const docSnapshots = await Promise.all(distRefs.map((ref) => tx.get(ref)))

    // Check that all distributions exist
    if (docSnapshots.some((doc) => !doc.exists())) {
      throw new ErrorWithCode({
        code: 'invalid-distributions',
        devMsg:
          'One or more distributions on this product could not be found. Please verify the distributions and resave this product.',
      })
    }

    // Unmarshal the distros and remove farm to match the expected type on products
    const prodDistros = docSnapshots.map((dist) => unmarshalDistribution(dist)).map((dist) => omit(dist, 'farm'))
    tx.set(prodRef, {
      ...marshalProduct({ ...updatedProduct, distributions: prodDistros } as Product),
      meta: newMeta,
    })
  })
}

/** Deletes a product by id. Deleting a product should always use this logic and not the collection.delete() api directly.
 * - If successful, returns  { success: true }
 * - Won't remove products with sales
 *  */
export async function deleteProduct(productId: string, farmId: string): Promise<{ success: boolean; error?: string }> {
  try {
    //Don't allow deleting a product that has sales
    const hasSales = await productHasSales(productId, farmId)
    if (hasSales) return { success: false }

    await productsCollection.delete(productId)
    return { success: true }
  } catch (error) {
    Logger.error(error)

    return { success: false, error: errorToString(error) }
  }
}

/** Checks whether any orders have been made for the product */
export async function productHasSales(productId: string, farmId: string): Promise<boolean> {
  return await callEndpoint('v2.Product.productHasSale', { productId, farmId })
}

/** Will load the nearest shares to a users location */
export async function getNearbyShares(
  coords: Coordinate,
  { limit = 10 }: { limit?: number } = { limit: 10 },
): Promise<Hit<AlgoliaGeoDoc<AlgoliaGeoProduct>>[]> {
  const result = await consumerIndex.search<AlgoliaGeoDoc<AlgoliaGeoProduct>>('', {
    length: limit,
    aroundLatLng: getCoordString(coords),
    facetFilters: [
      FILTERS.Product,
      FILTERS.NotHidden,
      FILTERS.Registered,
      FILTERS.Retail,
      asFilter<Pick<AlgoliaGeoProduct, 'priceInMap'>>(`priceInMap:-${InvalidAmount}`), // Filter out prods with invalid price
      asFilter<Pick<AlgoliaGeoProduct, 'isInStock'>>(`isInStock:true`),
      asFilter<Pick<AlgoliaGeoProduct, 'isPrivate'>>(`isPrivate:false`),
      [
        //Only select shares
        asFilter<Pick<AlgoliaGeoProduct, 'type'>>(`type:${ProductType.PrimaryShare}`),
        asFilter<Pick<AlgoliaGeoProduct, 'type'>>(`type:${ProductType.AddonShare}`),
      ],
    ],
    numericFilters: [asNumFilter(`lastAvailStamp > ${DateTime.now().plus({ minutes: 5 }).toMillis()}`)],
  })

  return parseAlgoliaResults(result).hits
}

type SearchOptions = Parameters<SearchIndex['search']>[1]
type ProductSearchResult = Hit<AlgoliaGeoDoc<AlgoliaGeoProduct>>

/**
 * Creates the base facet filters for product search
 * @param isWholesale - Whether to search for wholesale or retail products
 */
export const getAvailableProductFacetFilters = (isWholesale: boolean) => [
  FILTERS.Product,
  FILTERS.NotHidden,
  FILTERS.Registered,
  isWholesale ? FILTERS.Wholesale : FILTERS.Retail,
  asFilter<Pick<AlgoliaGeoProduct, 'priceInMap'>>(`priceInMap:-${InvalidAmount}`),
  asFilter<Pick<AlgoliaGeoProduct, 'isInStock'>>(`isInStock:true`),
  asFilter<Pick<AlgoliaGeoProduct, 'isPrivate'>>(`isPrivate:false`),
]

/**
 * Searches for products by geographical location
 */
const searchProductsByGeoLocation = async (
  coords: Coordinate,
  facetFilters: string[],
  searchOpts?: SearchOptions,
): Promise<ProductSearchResult[]> => {
  const result = await consumerIndex.search<AlgoliaGeoDoc<AlgoliaGeoProduct>>('', {
    facetFilters,
    numericFilters: createAvailabilityFilter(),
    aroundLatLng: getCoordString(coords),
    aroundRadius: defaultAroundRadius,
    facetingAfterDistinct: true,

    ...searchOpts,
  })

  return parseAlgoliaResults(result).hits
}

/**
 * Searches for products by region (state and zipcode)
 */
const searchProductsByRegion = async (
  state: string | undefined,
  zipCode: string | undefined,
  country: CountryCode | undefined,
  baseFacetFilters: string[],
  searchOpts?: SearchOptions,
): Promise<ProductSearchResult[]> => {
  const regionFilters: FacetFilterBase[] = []

  if (zipCode) {
    regionFilters.push(asFilter<AlgoliaGeoProduct, 'regions'>(`regions:${zipCode}`))

    if (country === 'CA' && zipCode.length > 3) {
      // Should match both full postal codes and FSA codes
      regionFilters.push(asFilter<AlgoliaGeoProduct, 'regions'>(`regions:${zipCode.slice(0, 3)}`))
    }
  }
  if (state) {
    regionFilters.push(asFilter<AlgoliaGeoProduct, 'regions'>(`regions:${state}`))
  }
  if (!regionFilters.length) return []

  const result = await consumerIndex.search<AlgoliaGeoDoc<AlgoliaGeoProduct>>('', {
    facetFilters: [...baseFacetFilters, regionFilters],
    numericFilters: createAvailabilityFilter(),
    facetingAfterDistinct: true,
    ...searchOpts,
  })

  return parseAlgoliaResults(result).hits
}

/**
 * Searches for products near a specified location, combining results from both
 * geographical proximity and regional searches.
 */
export const getNearbyProducts = async (
  place: GooglePlace,
  opts: { isWholesale: boolean },
  searchOpts?: Parameters<SearchIndex['search']>[1],
): Promise<Hit<AlgoliaGeoDoc<AlgoliaGeoProduct>>[]> => {
  const baseFacetFilters = getAvailableProductFacetFilters(opts.isWholesale)
  const { zipcode, state, coords, country } = await extractLocationComponents(place)

  const geoSearchPromise = searchProductsByGeoLocation(coords, baseFacetFilters, searchOpts)

  const regionSearchPromise = searchProductsByRegion(state, zipcode, country, baseFacetFilters, searchOpts)

  const results = (await Promise.all([geoSearchPromise, regionSearchPromise])).flat()

  // Prevent products showing multiple times
  return removeObjDuplicates(results, (prod) => prod.id)
}

/** Snapshots of products by farm id and product type. Returns only active products
 *
 * - The query might be more specific to include distroId and csaId but there's a firebase limitation of only one array filter in the query. The type filter already is considered an array filter, it seems. Plus, the new or() query syntax isn't available in our sdk version. Not a big deal though.
 * - If the query were more specific, the only benefit might be to fetch less data, but either way the initial query will have the full data because the initial state of the filters is undefined. So by the time the query became narrower, the larger-scope query would've already been sent, and cached by the firebase client sdk
 */
export function snapshotProductsOrderCreator(
  callback: (products: Product[]) => void,
  onError = errorCatcher,
  farmId: string,
  orderType: OrderProductType,
  isWholesale?: boolean,
): () => void {
  const qParams = [where('isHidden', '==', false), where('farm.id', '==', farmId)]
  const prodTypes = getProdTypes(orderType)
  if (prodTypes.length) {
    qParams.push(where('type', 'in', prodTypes))
  }

  const q = productsCollection.query(...qParams)
  return productsCollection.snapshotMany(
    q,
    (prods) =>
      callback(
        prods.filter((p) =>
          isActive(p, {
            excludeClosedDistros: false,
            ignoreOrderCutoffWindow: true,
            isWholesale,
          }),
        ),
      ),
    onError,
  )
}
