import { Media } from '@models/shared/Media'
import { ErrorCode, ErrorWithCode, isErrorWithCode, NotFoundError } from '@shared/Errors'

import { getTimeZone } from '@helpers/getTimeZone'
import { hasOwnProperty } from '@helpers/helpers'
import { omit, PartialExcept, PartialPick } from '@helpers/typescript'
import { AlgoliaGeoDoc, AlgoliaGeoFarm, asFilter, FILTERS } from '@models/Algolia'
import { Farm, FarmStatus } from '@models/Farm'
import { User } from '@models/User'
import { consumerIndex } from '../config/Algolia'
import { geocode } from './Addresses'
import { uploadImageAsync } from './FirebaseStorage'
import { farmsCollection } from './framework/ClientCollections'
import { callEndpoint } from './v2'

import env, { isEmul } from '@/config/Environment'
import { parseAlgoliaResults } from '@helpers/algolia-client'
import { formatToSafeSlug } from '@helpers/urlSafeSlug'
import { SearchIndex } from 'algoliasearch'
import { limit, or, where } from 'firebase/firestore'
import { buildUrlSafeSlug } from './UrlSafeSlugs'

/** Searches the geosearch index for farms by name */
export async function searchFarmByName(
  name: string,
  opts?: { isWholesale?: boolean },
  searchOpts: Parameters<SearchIndex['search']>[1] = {},
) {
  const facetFilters = [FILTERS.Farm, FILTERS.NotHidden, FILTERS.NotInactiveFarm]

  if (opts?.isWholesale !== undefined) {
    facetFilters.push(opts.isWholesale ? FILTERS.Wholesale : FILTERS.Retail)
  }
  const searchResults = await consumerIndex.search<AlgoliaGeoDoc<AlgoliaGeoFarm>>(name, {
    hitsPerPage: 20,
    facetFilters,
    ...searchOpts,
  })

  return parseAlgoliaResults(searchResults)
}

/** loadFarm returns the farm identified by the supplied farm ID or Slug. */
export async function loadFarm(farmSlug: string): Promise<Farm> {
  const farm = await farmsCollection.fetchAll(
    // TODO: Replace id with documentId() when it becomes available in the SDK, track issue -> https://github.com/firebase/firebase-js-sdk/issues/8049
    // it now throws an error of indexing the collection and we'll file an issue to fix it
    or(where('id', '==', farmSlug), where('urlSafeSlug', '==', farmSlug)),
    limit(1),
  )
  if (!farm.length) {
    throw new NotFoundError('farms', farmSlug)
  }
  return farm[0]
}

/** snapshotFarm listens for farm changes and signals to the supplied callback when the status property for a farm changes. The callback listener may call the cancel function to stop listening for changes. */
export async function snapshotFarm(farmId: string, callback: (farm?: Farm) => void): Promise<() => void> {
  return farmsCollection.snapshotDoc(farmId, callback)
}

type NewFarmPartial = Pick<Farm, 'name' | 'about' | 'media'> & { address: Omit<Farm['address'], 'coordinate'> }

/** Adds a new farm to the database. If the farm object contains media entries, they will also be uploaded to remote storage.
 * - The address should not include coordinate since it will be geocoded within this api
 */
export async function addFarm(farmData: NewFarmPartial): Promise<Farm> {
  const ref = farmsCollection.reference()
  const address = await geocode(farmData.address)
  const timezone = await getTimeZone(address)

  const farm: Farm = {
    ...farmData,
    id: ref.id,
    address,
    timezone,

    // Default values for properties not collected by the form.
    status: FarmStatus.Listed,
    email: '',
    phoneNumber: '',
    website: '',
    paymentTypes: {
      card: false,
      cash: false,
      check: false,
      ebt: false,
    },
    properties: {},
    accountRef: '',
    practices: [],

    locationCount: 0,
    productCount: 0,
    numFavorites: 0,
    urlSafeSlug: '',
  }

  // Validate the urlSafeSlug
  let validSafeSlug = formatToSafeSlug(farm.name)
  validSafeSlug = await buildUrlSafeSlug({
    slug: validSafeSlug,
    type: 'create',
    collection: 'farms',
    farmId: farm.id,
    id: farm.id,
  })

  if (!validSafeSlug) {
    throw new ErrorWithCode({
      code: 'invalid-slug',
      devMsg:
        'Please choose a distinct name for your farm. The farm name is essential for creating a unique and secure URL.',
    })
  }
  // Assign valid urlSafeSlug
  farm.urlSafeSlug = validSafeSlug

  // Store images in Firebase storage and add new urls here
  farm.media = await storeMedia(farm)
  await farmsCollection.createWithId(farm)

  return farm
}

/** claimFarm executes on the claim to a farm by the supplied user. The user takes ownership of the farm upon successful completion of this function. */
export async function claimFarm(farm: PartialExcept<Farm, 'id'>, user: PartialExcept<User, 'id'>): Promise<void> {
  return await callEndpoint('v2.Farm.claimFarmService', { farmId: farm.id, userId: user.id })
}

/** Updates the farm
 * - The address should not include coordinates since they will be geocoded within this api
 */
export async function updateFarm(
  farm: PartialExcept<Omit<Farm, 'address'>, 'id'> & {
    address?: Omit<Farm['address'], 'coordinate'>
  },
): Promise<void> {
  const updateFarm: PartialPick<Farm, 'id'> = { ...omit(farm, 'address') }

  // When the farm name is included or changed, we need to validate the urlSafeSlug
  if (farm.name) {
    // Validate the urlSafeSlug
    let validSafeSlug = formatToSafeSlug(farm.name)
    validSafeSlug = await buildUrlSafeSlug({
      slug: validSafeSlug,
      type: 'update',
      collection: 'farms',
      farmId: farm.id,
      id: farm.id,
    })

    if (!validSafeSlug) {
      throw new ErrorWithCode({
        code: 'invalid-slug',
        devMsg:
          'Please choose a distinct name for your farm. The farm name is essential for creating a unique and secure URL.',
      })
    }
    // Assign valid urlSafeSlug
    updateFarm.urlSafeSlug = validSafeSlug
  }

  if (farm.address) {
    updateFarm.address = await geocode(farm.address)
    updateFarm.timezone = await getTimeZone(updateFarm.address)
  }
  return farmsCollection.update(updateFarm)
}

/** setFarmVerificationCode calls the setFarmVerificationCodeHandler on the server. */
export async function setFarmVerificationCode(farmId: string) {
  await callEndpoint('v2.Farm.setVerificationCodeHandler', { farmId })
}

/** linkStripeAccount returns a URL used to access the Stripe account authorization flow. */
export async function linkStripeAccount(accountRef: string): Promise<string> {
  return await callEndpoint('v2.Stripe.connectAccountLink', { accountRef })
}

// storeMedia uploads the media attached to the farm object. This function return the stored values
async function storeMedia(farm: PartialPick<Farm, 'id' | 'media'>): Promise<Media[]> {
  if (!farm.media || farm.media.length === 0) {
    return []
  }

  try {
    return await Promise.all(
      farm.media.map((media: Media, idx: number) => uploadImageAsync(`${farm.id}/header${idx}`, media)),
    )
  } catch (err) {
    if (hasOwnProperty(err, 'code') && err.code === 'storage/unauthorized') {
      throw new Error('You must be signed in to add a farm')
    }
    throw err
  }
}

/** InvalidMerchantIdError is thrown when the merchant ID is invalid. */
export class InvalidMerchantIdError extends Error {}

/** Will check if the merchantId is valid */
export async function checkIsValidWorldPayMerchantId(merchantId: string): Promise<void> {
  try {
    await callEndpoint('v2.Farm.farmIsValidWorldPayMerchantIDService', { merchantId })
  } catch (err) {
    if (isErrorWithCode(err, ErrorCode.INVALID_MERCHANT_ID)) {
      throw new InvalidMerchantIdError(err.message)
    }
    throw err
  }
}

/** Algolia farms that have their id included in @param ids */
export async function getAlgoliaFarmsByIds(ids: string[], isWholesale: boolean) {
  if (!ids.length) return []

  const result = await consumerIndex.search<AlgoliaGeoDoc<AlgoliaGeoFarm>>('', {
    facetFilters: [
      FILTERS.Farm,
      isWholesale ? FILTERS.Wholesale : FILTERS.Retail,
      ids.map((id) => asFilter<AlgoliaGeoDoc, 'id'>(`id:${id}`)),
    ],
  })

  return parseAlgoliaResults(result).hits
}

export const FEATURED_FARMS = !isEmul
  ? env.APP_ENV === 'prod'
    ? {
        FOOTPRINT_FARM: 'farmid-4018295',
        RIVER_QUEEN_GREENS: 'farmid-4012738',
        MASSARO_FARM: 'farmid-4002109',
        HEARTY_ROOTS: 'farmid-4001158',
        NORTHPOINT_COMMUNITY_FARM: 'u5x4TupHATy5C6avemz6',
        GORGEOUS_GOAT_CREAMERY: 'TcEOMxIvEAiiOsZZtDhA',
        WHERE_PIGS_FLY_FARM: '0UmdGKcJyXJfp8Tm3sqF',
        TIPI_PRODUCE: 'farmid-4001883',
        RANCHITO_MILKYWAY: '7myoYaNwhXfyyhcBdu0y',
        HAWTHORNE_VALLEY_FARM_AND_CREAMERY: 'farmid-4019386',
        MILKY_FORK: 'GtMCHFGBJUZ6JELf34a5',
        CHOY_DIVISION: '8S2DAs0EH7WHQ2r7dkOx',
      }
    : {
        FOOTPRINT_FARM: 'farmid-4018295',
        RIVER_QUEEN_GREENS: 'farmid-4012738',
        MASSARO_FARM: 'farmid-4002109',
        HEARTY_ROOTS: 'farmid-4001158',
      }
  : {}

/** Dev farms that have wholesale enabled. This should be updated with production data */
export const WHOLESALE_FEATURED_FARMS =
  env.APP_ENV === 'prod'
    ? {
        SLEEPING_LION: '1Aax6t5bYjaK5TYnTJl6',
        HEARTY_ROOTS: 'farmid-4001158',
        NORTH_POINT_COMMUNITY_FARM: 'u5x4TupHATy5C6avemz6',
        '607_CSA': '9sgPDaxxafI6F2dI6uY6',
        CHASEHOLM_FARM_CREAMERY: 'qAGV03lm4roBk5pLtkdd',
        SAMASCOTT_ORCHARDS: 'farmid-4004364',
        HAWTHORNE_VALLEY_FARM: 'farmid-4019386',
        SAWKILL_FARM: 'Eoe1nWKMhY2Vi9HuRzpI',
      }
    : {
        GROWNBY_FARM: '6dFtiqdMAP9828umw38M',
        THE_NEW_YORK_STATE_TOMATO_FARM: 'xJ6yPdcaejFp3ZDX44St',
      }
