import { omit, pick } from '@helpers/typescript'
import { Farm } from '@models/Farm'
import { SignInProviders, User, UserType } from '@models/User'
import { UserAddress } from '@models/UserAddress'
import { isNotFound } from '@shared/Errors'

import { buildUser } from '@helpers/builders/buildUser'
import { geocode } from './Addresses'
import { callEndpoint } from './v2'

import { loadUserByEmail, validatePhoneNumber } from './Users'

import { Logger } from '@/config/logger'
import { addressBuilder } from '@helpers/builders'
import { AddCustomerDataForm } from '@models/Customer'

/** Creates a user and adds it as a farm customer*/
export async function createFarmCustomer(data: AddCustomerDataForm, farm: Farm) {
  try {
    const customerData = await createUserData(data, farm)

    return await callEndpoint('v2.Customer.createOrInviteFarmCustomerService', {
      customerType: data.userType,
      farmId: farm.id,
      type: 'create',
      customerData,
    })
  } catch (err) {
    Logger.error(err)
    throw err
  }
}

/** Adds an existing user to a farm, by sending an invitation to that user */
export async function addCustomerToFarm(customer: User, farm: Farm, farmAdmin: User, userType: UserType) {
  try {
    return await callEndpoint('v2.Customer.createOrInviteFarmCustomerService', {
      customerType: userType,
      farmId: farm.id,
      type: 'invite',
      customerId: customer.id,
      farmAdminId: farmAdmin.id,
    })
  } catch (err) {
    Logger.error(err)
    throw err
  }
}

export const findUserByEmail = async (email: string): Promise<User | undefined> => {
  try {
    return await loadUserByEmail(email)
  } catch (e) {
    if (isNotFound(e)) return undefined
    throw e
  }
}

/** Generates a new User object with geocoded address coordinates, valid phone number, without assigning Id for it yet. */
const createUserData = async (data: AddCustomerDataForm, farm: Farm): Promise<Omit<User, 'id'>> => {
  data.email = data.email.trim()
  data.email2 = data.email2?.trim()

  const user: Omit<User, 'id'> = omit(
    buildUser('', data.firstName, data.lastName, data.email, SignInProviders.Email, ''),
    'id',
  )

  user.invitedByFarm = pick(farm, 'id', 'name')

  if (data.phoneNumber) {
    //The user.id part should be empty string because the new user we would like to add is not in the database yet
    user.phoneNumber = await validatePhoneNumber(data.phoneNumber, '', false)
  }

  if (data.userType === UserType.wholesale) {
    user.institution = {
      accountType: 'wholesale-buyer',
      isInstitution: true,
      businessName: data.businessName,
      authorizedUserIds: [],
      authorizedUsers: {},
    }
  }

  if (data.city && data.state && data.street1 && data.zipcode && data.country) {
    // A user with no address is allowed. Here we must only geocode the address and set it to the user object if the required fields are defined in the data
    const userAddress: UserAddress = {
      id: '',
      city: data.city,
      state: data.state,
      street1: data.street1,
      street2: data.street2,
      zipcode: data.zipcode,
      country: data.country,
      // This is a temporary dummy coordinate
      coordinate: { latitude: 0, longitude: 0 },
    }

    const { coordinate } = await geocode(userAddress)
    userAddress.coordinate = coordinate

    // If an address was used validate it
    const errors = addressBuilder.getAddressErrors(userAddress)
    if (errors) {
      throw new Error(Object.values(errors).join(', '))
    }

    // Adds the address to the new user
    user.address = userAddress
  }

  return user
}
