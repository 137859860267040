import { Product } from '@models/Product'
import { memo, useContext } from 'react'
import { StandardRow_Reusable } from '../../../OrderCreatorScreen/components/Rows/StandardRow_Reusable'
import { OrderEditContext } from '../../useOrderEditData'
import { AddBtnStandard } from '../AddCartButtons/StandardBtn'

type StandardRowProps = {
  prod: Product
  index: number
}

/** Row component for standard products of the order edit table */
export const StandardRow = memo(function StandardRow({ prod: p, index }: StandardRowProps) {
  const {
    draftOrderFx: { data: draft },
  } = useContext(OrderEditContext)

  if (!draft || draft.isWholesale === undefined) return null

  return <StandardRow_Reusable AddCartBtn={AddBtnStandard} index={index} prod={p} isWholesale={draft.isWholesale} />
})
