import { userName } from '@helpers/user'
import { Farm } from '@models/Farm'
import { SignInProviders, User } from '@models/User'
import { limit, where } from 'firebase/firestore'

import { CustomShare } from '@models/CustomShare'
import { DateRange } from '@models/Schedule'
import { GROWNBY_APP_URL } from '@shared/BaseUrl'
import { BigQueryLogsRequest, EmailEventLog, UpdateFarmDetailsRequest } from '@shared/types/v2/farmGenAdmin'
import { ZohoCRMLeadData } from '@shared/types/v2/zoho'
import { marshalDateObject, unmarshalDateObject } from './encoding/Time'
import { customSharesCollection, farmsCollection, usersCollection } from './framework/ClientCollections'
import { callEndpoint } from './v2'

/** searchUserByEmail returns the user identified by the supplied email address. */
export async function searchUserByEmail(email: string): Promise<User[]> {
  return usersCollection.fetchAll(where('email', '>=', email.toLowerCase()), limit(5))
}

/** loadFarmByName returns the farm identified by the supplied name. */
export async function searchFarmByName(name: string): Promise<Farm[]> {
  return farmsCollection.fetchAll(where('nameUppercase', '>=', name.toUpperCase()), limit(5))
}

/**
 * Allows Farm Generations Admin team to make select changes to a farm from the admin panel
 * @param farmId the farmId to make the changes for
 * @param properties the properties to change
 */
export async function updateFarmProperty(farmId: string, properties: Omit<UpdateFarmDetailsRequest, 'farmId'>) {
  return await callEndpoint('v2.FarmGenAdmin.updateFarmDetails', { farmId, ...properties })
}

/** Will generate a URL for the admin user to login as (impersonate) any GrownBy user. */
export async function loginAsUser(userId: string) {
  return callEndpoint('v2.FarmGenAdmin.loginAsUser', { userId })
}
/**
 * Will toggle the user as enabled or disabled
 * @param userId the user to toggle
 * @param disable True if the user should be disabled or False to be enabled
 */
export async function toggleDisableUser(userId: string, disable: boolean) {
  return callEndpoint('v2.FarmGenAdmin.toggleDisableUser', { userId, disable })
}
/**
 * Will change a customers auth provider to email
 * @param userId the user to toggle
 */
export async function updateProviderToEmail(userId: string) {
  return callEndpoint('v2.FarmGenAdmin.updateAuthProvider', { userId, newProvider: SignInProviders.Email })
}

/**
 * Will get the email logs for a user
 * @param userEmail the email to get the logs for
 */
export async function getEmailLogs(userEmail: string) {
  const logs = await callEndpoint('v2.FarmGenAdmin.getEmailLogs', { userEmail })
  return logs.map((log) => unmarshalDateObject<EmailEventLog>(log))
}

/**
 * Will add a new lead to Zoho CRM saying this farm was invited to our platform by a customer
 * @param farm The farm we are adding as a lead
 * @param user The user who invited the farm, can be undefined
 * @param message An optional message the user left for the farm
 */
export async function addNewLead(farm: Farm, user?: User, message?: string) {
  const customMessage = message ? ` They added a message to the farm saying: "${message}"` : ''
  const userString = user ? `by the customer ${userName(user)} (${user.email})` : 'by an unknown user.'
  const lead: ZohoCRMLeadData = {
    data: [
      {
        Company: farm.name,
        Email: farm.email,
        Last_Name: '.',
        Lead_Source: 'Referral',
        Lead_Status: 'Warm',
        State: farm.address.state,
        Website: `${GROWNBY_APP_URL}/farms/${farm.urlSafeSlug}`,
        Description: `This farm was invited to GrownBy ${userString}.${customMessage}`,
      },
    ],
  }
  return await callEndpoint('v2.Zoho.addNewZohoLead', { lead })
}

/** Returns all custom shares */
export async function fetchAllCustomShares(): Promise<CustomShare[]> {
  return customSharesCollection.fetchAll(limit(100))
}

/**
 * Will fetch the change logs for a given BigQuery collection and documentId
 * @param collection The collection to fetch the logs for
 * @param documentId The documentId to fetch the logs for
 * @param dateRange The date range to fetch the logs for
 */
export async function fetchBigQueryLogs(
  collection: BigQueryLogsRequest['collection'],
  documentId: string,
  dateRange: DateRange,
) {
  const marhsalledDateRange = marshalDateObject(dateRange)
  return callEndpoint('v2.FarmGenAdmin.fetchBigQueryLogs', { collection, documentId, dateRange: marhsalledDateRange })
}
