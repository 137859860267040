import { cancelOrder } from '@api/Orders'
import { Alert } from '@elements'
import { formatMoney, plural } from '@helpers/display'
import { errorToString } from '@helpers/helpers'
import { Order, OrderItem } from '@models/Order'
import { isFarmBalance } from '@models/Product'
import { OrderCancelPreflightResponse } from '@shared/types/v2/order'

import { Logger } from '@/config/logger'
import { MoneyCalc } from '@helpers/money'
import { DateTime } from 'luxon'

/** Represent the ratio of paid and total amount for each order item */
export const paidAndTotalAmountRatio = (
  id: OrderItem['id'],
  data: Pick<OrderCancelPreflightResponse, 'invoiceCounters'>,
) => {
  return (
    formatMoney(data.invoiceCounters[id].totalPaid) +
    ' / ' +
    formatMoney(MoneyCalc.add(data.invoiceCounters[id].totalPaid, data.invoiceCounters[id].unpaid))
  )
}

/** Represent how many pickups has been claimed from all pickups */
export const claimedPickupRatio = (id: OrderItem['id'], data: Pick<OrderCancelPreflightResponse, 'pickupCounters'>) => {
  const { missed, received, vacation } = data.pickupCounters[id]
  const total = missed + received + vacation
  if (total === 0) return 'N / A'
  return received + vacation + ' / ' + total
}

/** This function is getting all the required arguments and process cancelOrder and refund with server request */
export const confirmCancelOrder = async (
  currentOrder: Order,
  cancelledItems: OrderItem[],
  refundAmounts: Partial<OrderCancelPreflightResponse['refundAmounts']>,
  entireOrder: boolean,
  cancelDate: string,
) => {
  const cancelledItemIds = cancelledItems.map((item) => item.id)

  const resultTitle = entireOrder ? 'Order Cancelled' : `${plural(cancelledItems.length, 'Item')} Cancelled`
  const errorTitle = entireOrder
    ? 'Order cancellation failed'
    : `${plural(cancelledItems.length, 'Item')} cancellation failed`
  const contentMsg = entireOrder
    ? 'This Order was'
    : `${cancelledItems.length > 1 ? cancelledItems.length : 'An'} ${plural(cancelledItems.length, 'item')} ${plural(
        cancelledItems.length,
        'was',
      )}`

  try {
    const messages = await cancelOrder({
      orderId: currentOrder.id,
      items: cancelledItemIds,
      refundAmounts,
      preflight: false,
      cancelDate: DateTime.fromISO(cancelDate),
    })
    if (messages.length > 0) {
      return Alert(
        resultTitle,
        `${contentMsg} cancelled successfully, however some refunds failed. You can handle these failed refunds through the customer details page.\n` +
          messages.join('\n'),
      )
    } else {
      Alert(resultTitle, `${contentMsg} successfully cancelled and any refunds are in progress.`)
    }
  } catch (err) {
    const msg = errorToString(err)
    Logger.error(err)
    Alert(errorTitle, msg)
  }
}

/** This function helps to decide if only farm balance product is left in an order */
export const isFarmCreditOnlyFunc = (currentOrder: Order) => {
  const notCancelledItems = currentOrder.items.filter((item) => !item.cancelled)
  let farmCreditOnly = true
  notCancelledItems.forEach((item) => {
    if (!isFarmBalance(item.product)) {
      farmCreditOnly = false
    }
  })
  return farmCreditOnly
}
