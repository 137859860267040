import { Image } from '@components'
import { ButtonClear, MoneyInput, Text, Toggle } from '@elements'
import { formatMoney } from '@helpers/display'
import { MoneyCalc, Zero } from '@helpers/money'
import { CurrencyCode, Money } from '@models/Money'
import { useContext } from 'react'
import { View } from 'react-native'
import Colors from '../../../constants/Colors'
import { globalStyles } from '../../../constants/Styles'
import { PaymentOptionsContext } from './PaymentOptionsContext'
import { finitePaymentStyles } from './helpers/styles'
import { useFinitePayment } from './hooks/useFinitePayment'

type Props = {
  selectedAmt: Money
  maxAmount: Money
  onChangeSelectedAmount(amt: Money): void
  currency: CurrencyCode
}

/** This component handles selecting the farm balance amount to be used for an order */
export function FarmBalanceSelection({ selectedAmt, onChangeSelectedAmount, maxAmount, currency }: Props) {
  const {
    options: { hasFarmBalanceItem, isInvoiceDueToday, farm },
  } = useContext(PaymentOptionsContext)
  const { isSelected, isEditingAmt, updatedValue, onTogglePmt, updatePmtAmount, setValue } = useFinitePayment({
    selectedAmt,
    maxAmount,
    setSelectedAmount: onChangeSelectedAmount,
  })

  return (
    <View style={finitePaymentStyles.wrapper}>
      <View style={finitePaymentStyles.containerStyle}>
        <Image
          style={finitePaymentStyles.imageStyle}
          source={require('../../../assets/images/icons/farm-credit-card.png')}
        />
        <View style={finitePaymentStyles.textCont}>
          <Text type="medium" size={14}>
            {farm.name} Credit
          </Text>
          {hasFarmBalanceItem ? (
            <Text color={Colors.shades[200]}>Farm credit cannot be used when purchasing more credit.</Text>
          ) : !isInvoiceDueToday ? (
            <Text>
              Farm credit cannot be selected for future payments. However at the time of payment if you have farm
              balance it will automatically be applied.
            </Text>
          ) : (
            <Text>
              {formatMoney(MoneyCalc.isZero(selectedAmt) || isEditingAmt ? maxAmount : selectedAmt)}{' '}
              {isSelected && !isEditingAmt ? 'will' : 'can'} be applied
            </Text>
          )}
        </View>
        <Toggle value={isSelected} disabled={hasFarmBalanceItem || !isInvoiceDueToday} onChange={onTogglePmt} />
      </View>
      <View>
        <View>
          {isEditingAmt ? (
            <View style={globalStyles.flexRowCenter}>
              <MoneyInput
                inputContainerStyle={finitePaymentStyles.moneyInputInnerContainer}
                style={finitePaymentStyles.moneyInput}
                renderErrorMessage={false}
                containerStyle={finitePaymentStyles.moneyInputOuterContainer}
                value={updatedValue}
                onChangeText={(value) => setValue('updatedValue', value)}
                currency={currency}
              />
              <ButtonClear
                style={finitePaymentStyles.actionButton}
                small
                title="Update"
                onPress={() => updatePmtAmount(updatedValue)}
                disabled={MoneyCalc.isGreaterThan(updatedValue ?? Zero, maxAmount)}
              />
            </View>
          ) : (
            isSelected && <ButtonClear small title="Edit Amount" onPress={() => setValue('isEditingAmt', true)} />
          )}
        </View>
      </View>
    </View>
  )
}
