import { useApiFx } from '@/hooks/useApiFx'
import { useSnapshot } from '@/hooks/useSnapshot'
import { loadAllConversations, snapshotConversations } from '@api/Messaging'
import { removeObjDuplicates } from '@helpers/helpers'
import { DEFAULT_CONV_LIMIT } from '@helpers/messaging'
import { User } from '@models/User'
import { useMemo, useState } from 'react'

/** Data layer hook that is handling the fetching of conversations */
export const useConversationsApi = ({ userId }: { userId: User['id'] }) => {
  const [onlyUnread, setOnlyUnread] = useState(false)
  const [canLoadAll, setCanLoadAll] = useState(false)
  const [canLoadData, setCanLoadData] = useState(false)

  const chatsSnap = useSnapshot(
    'snapshotConversations',
    snapshotConversations,
    [userId, false, DEFAULT_CONV_LIMIT],
    !!userId && canLoadData,
  )
  const unreadChatsSnap = useSnapshot(
    'snapshotConversations',
    snapshotConversations,
    [userId, true],
    !!userId && canLoadData,
  )

  /** FX for all the user's conversations. Should run only when Load More is pressed or when the user is filtering by search values */
  const allConversationsFx = useApiFx(loadAllConversations, [userId], !!userId && canLoadAll, {
    noRefocus: true,
    once: true,
    initialState: { loading: false },
  })

  /** Merges snapshot messages with db messages, prioritizing snap messages */
  const conversations = useMemo(() => {
    if (!chatsSnap.data) return undefined

    return removeObjDuplicates([...chatsSnap.data, ...(allConversationsFx.data ?? [])])
  }, [allConversationsFx.data, chatsSnap.data])

  const onFetchAllConversations = async () => {
    setCanLoadAll(true)
  }

  /** Whether the user can load more conversations */
  const canLoadMoreConversations = useMemo(() => {
    if (!chatsSnap.data) return false
    if (chatsSnap.data.length < DEFAULT_CONV_LIMIT) return false
    if (canLoadAll) return false

    return true
  }, [chatsSnap.data, canLoadAll])

  return {
    chatsSnap: { ...chatsSnap, data: conversations },
    unreadChatsSnap,
    onShowOnlyUnread: setOnlyUnread,
    onlyUnread,
    canLoadMoreConversations,
    onFetchAllConversations,
    setCanLoadData,
    /** Whether the data can be loaded. Can be false when the accessed screen is an intermediary screen*/
    canLoadData,
  }
}
