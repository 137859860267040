import { UserAddress } from '@models/UserAddress'
import * as Yup from 'yup'
import { addressSchema } from './AddressBuilder'

export const userAddressSchema: Yup.ObjectSchema<UserAddress> = addressSchema.concat(
  Yup.object().shape({
    id: Yup.string().required(),
    isDefault: Yup.boolean().optional(),
    notes: Yup.string().optional(),
    deleted: Yup.boolean().optional(),
  }),
)
