import { Zero } from '@helpers/money'
import { InvoicePaymentMethod } from '@models/Invoice'
import { CurrencyCode, Money } from '@models/Money'
import { mapSourceToType, PaymentForms, PaymentSources } from '@models/PaymentMethod'

export interface PaymentMethodsDefinitionInterface<Source extends PaymentSources> {
  /** A short readable name like Card */
  readableName: string
  source: PaymentSources
  type: PaymentForms
  /** If we should charge the platform fee for this payment method */
  chargePlatformFee: boolean
  /** If the payment is finite like farm credit and ebt or infinite like offline, ach or card*/
  isFinite: boolean
  /** Will return the readableName */
  formatShort(): string
  /** Will format a payment method including card or bank issuer and last4 */
  formatInvPayMethod(paymentMethod: InvoicePaymentMethod<Source>): string
  /** Will compute the processor fee for the payment method */
  calculateFee(amount: Money): Money
  /** Will return if the currency can be processed by this processor. Allows certain payment definitions to only support specific currencies. */
  hasCurrencySupport(currency: CurrencyCode): boolean
}

/** This base payment def holds properties and methods that apply to all payment methods */
export abstract class BasePaymentMethodDefinition<Source extends PaymentSources> {
  readableName: string
  source: PaymentSources
  type: PaymentForms
  chargePlatformFee = false

  constructor(source: Source, readableName: string) {
    this.source = source
    this.readableName = readableName
    this.type = mapSourceToType(source)
  }

  formatShort() {
    return this.readableName
  }

  /** If a fee is not specified on the payment definition then it has no fee*/
  calculateFee(_: Money) {
    return Zero
  }

  /** By default, a payment processor is not country specific */
  hasCurrencySupport(_currency: CurrencyCode): boolean {
    return true
  }
}

// TODO: If we don't find methods and properties that apply to all infinite or finite methods we can remove the below and set
//  isFinite on each payment method

/** The infinite payment method definition adds all methods and properties that apply to infinite payment methods */
export abstract class InfinitePaymentMethodDefinition<
  Source extends PaymentSources,
> extends BasePaymentMethodDefinition<Source> {
  isFinite = false
}

/** The finite payment method definition adds all methods and properties that apply to finite payment methods */
export abstract class FinitePaymentMethodDefinition<
  Source extends PaymentSources,
> extends BasePaymentMethodDefinition<Source> {
  isFinite = true
}
