import { hasOwnProperty } from '@helpers/helpers'
import { PaymentSources } from '@models/PaymentMethod'
import { ErrorWithCode } from '@shared/Errors'
import { FarmCreditPaymentDefinition } from './FarmCreditPaymentDefinition'
import { OfflinePaymentDefinition } from './OfflinePaymentDefinition'
import { PaymentMethodsDefinitionInterface } from './PaymentDefinition'
import { StripeACHPaymentDefinition } from './StripeACHPaymentDefinition'
import { StripeCreditPaymentDefinition } from './StripeCreditPaymentDefinition'
import { StripeInvoicePaymentDefinition } from './StripeInvoicePaymentDefinition'
import { WorldPayEbtPaymentDefinition } from './WorldPayEbtPaymentDefinition'
import { FortisCreditOnlinePaymentDefinition } from './FortisCreditOnlinePaymentDefinition'
import { FortisACHPaymentDefinition } from './FortisACHPaymentDefinition'

const PaymentDefinitions: PaymentMethodsDefinitionInterface<PaymentSources>[] = [
  new OfflinePaymentDefinition(),
  new FarmCreditPaymentDefinition(),
  new StripeCreditPaymentDefinition(),
  new StripeInvoicePaymentDefinition(),
  new StripeACHPaymentDefinition(),
  new WorldPayEbtPaymentDefinition(),
  new FortisCreditOnlinePaymentDefinition(),
  new FortisACHPaymentDefinition(),
]

export function PmtDef<Source extends PaymentSources>(source: Source): PaymentMethodsDefinitionInterface<Source>
export function PmtDef<Source extends PaymentSources>(pmt: {
  source: Source
}): PaymentMethodsDefinitionInterface<Source>
/**
 * This is a helper that returns a payment definition for the provided invoice payment or payment source. It can be used to
 * access any processor or method specific attributes
 * @param pmtOrSource The invoice payment method or source to get the definition for
 */
export function PmtDef<Source extends PaymentSources>(
  pmtOrSource: Source | { source: Source },
): PaymentMethodsDefinitionInterface<Source> {
  const source = hasOwnProperty(pmtOrSource, 'source') ? pmtOrSource.source : pmtOrSource
  const pmtDef = PaymentDefinitions.find((pd) => pd.source === source)
  if (!pmtDef)
    throw new ErrorWithCode({
      code: 'UNKNOWN-PAY-TYPE',
      devMsg: 'Unknown payment method specified, it must be defined in PaymentDefinitions',
      uiMsg: 'Unknown payment method',
    })

  return pmtDef
}
