import { ButtonClear, TextH2 } from '@elements'
import { Fontisto } from '@expo/vector-icons'
import { memo } from 'react'
import { StyleSheet, View } from 'react-native'

import { GooglePlacesSearch } from '@components'

import Colors from '@/constants/Colors'
import { useDeviceSize } from '@/hooks/useLayout'
import { internationalSelector } from '@/redux/selectors'
import { GooglePlace } from '@models/Address'
import { useSelector } from 'react-redux'

type Props = {
  /** The initial value to show in the input. If defined this represents the city used for the most recent nearby product search */
  initialValue: string | undefined
  onSelectCity: (item: GooglePlace) => void
  onExplorePress: () => void
}

/** Displays the product search UI where the user can enter a city */
export const NearbyProdsInput = memo(function NearbyProdsInput({ initialValue, onSelectCity, onExplorePress }: Props) {
  const { isSmallDevice } = useDeviceSize()

  const { country } = useSelector(internationalSelector)

  return (
    <View style={styles.screen}>
      <View style={styles.mainContent}>
        <TextH2 size={18}>Farms selling near </TextH2>
        <View style={styles.searchInputWrapper}>
          <View style={styles.iconCont}>
            <Fontisto name="map-marker-alt" size={20} />
          </View>
          <GooglePlacesSearch
            types="geocode"
            placeholder="Enter your location"
            initialValue={initialValue}
            enableReinitialize
            autoComplete="postal-address"
            hasClearBtn
            style={styles.inputStyle}
            contStyle={styles.googleplacesCont}
            onSelectGooglePlace={onSelectCity}
            country={country}
          />
        </View>
      </View>
      {!isSmallDevice && (
        <ButtonClear style={styles.exploreBtn} title="Explore on a map" onPress={onExplorePress} icon="map" />
      )}
    </View>
  )
})

const styles = StyleSheet.create({
  exploreBtn: {
    justifyContent: 'center',
    alignSelf: 'flex-start',
  },
  inputStyle: {
    fontSize: 18,
    height: 35,
  },
  googleplacesCont: {
    borderWidth: 1,
    width: 260,
    paddingLeft: 30,
    backgroundColor: Colors.white,
    borderColor: Colors.shades['100'],
  },
  searchInputWrapper: {
    marginLeft: 30,
    height: 35,
  },
  mainContent: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    flex: 1,
  },
  screen: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconCont: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    top: 0,
    width: 35,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 5,
  },
})
