import { omit } from '@helpers/typescript'
import { TemplateProduct } from '@models/Product'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { prepareMarshal, prepareUnmarshal } from './encoding'
import { unmarshalFarm } from './Farm'

/** unmarshalTemplateProduct converts the data provided by the Firestore document into the structure expected by the TemplateProduct model. */
export function unmarshalTemplateProduct(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): TemplateProduct {
  const [id, data] = prepareUnmarshal<Partial<TemplateProduct>>(idOrSnapshot, incomingData)
  const prod = { ...data, id } as TemplateProduct

  if (data.farm) {
    prod.farm = unmarshalFarm(data.farm.id, data.farm)
  }
  return prod
}

/** marshalTemplateProduct converts the templateProduct structure into a Firebase document structure. */
export function marshalTemplateProduct(templateProduct: Partial<TemplateProduct>): DocumentData {
  return omit(prepareMarshal(templateProduct), 'id')
}
