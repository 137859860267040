import { useCallback } from 'react'
import { InputProps } from 'react-native-elements'
import { MaskInputProps, formatWithMask } from 'react-native-mask-input'

import { FormInput } from '../Form'

export type MaskedInputProps = Omit<InputProps, 'onChangeText' | 'defaultValue'> & {
  onChangeText: MaskInputProps['onChangeText']
  mask: MaskInputProps['mask']
}

/** MaskedInput is an input that will mask the text inside based on the provided mask */
export function MaskedInput({ value, onChangeText, mask, ...rest }: MaskedInputProps) {
  const { masked } = formatWithMask({
    text: value,
    mask,
  })

  const onChangeTextMask = useCallback(
    (text: string) => {
      if (onChangeText) {
        const { masked, unmasked, obfuscated } = formatWithMask({ text, mask })
        onChangeText(masked, unmasked, obfuscated)
      }
    },
    [onChangeText, mask],
  )

  return <FormInput value={masked} onChangeText={onChangeTextMask} {...rest} />
}
