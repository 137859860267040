import { AccessRight, hasFarmAdminPermission, Permission, SecurityCheck } from '@helpers/Permission'
import { ManagerType } from '@models/Farm'
import { Role } from '@models/User'
import { useMemo, useState } from 'react'

type UseMessagingPermissionProps = {
  role: Role
  manager: ManagerType
}

/** Custom hook to manage messaging permissions for a user based on their role and manager type. */
export function useMessagingPermission({ role, manager }: UseMessagingPermissionProps) {
  const [hasMsgPermission, setHasMsgPermission] = useState(getDefaultPermissionValue(manager, role))

  /** Determines if we should save the custom permission value and what that value is */
  const currCustomPermission = useMemo(() => {
    let newAccessRight: AccessRight | undefined

    if (SecurityCheck[Permission.Messaging][role]) {
      // If is an admin, prohibit if the value is false
      newAccessRight = hasMsgPermission ? undefined : AccessRight.Prohibited
    } else {
      // Else, set to Edit if checked
      newAccessRight = hasMsgPermission ? AccessRight.Edit : undefined
    }

    return {
      ...manager.farmAssociation.customPermissions,
      Messaging: newAccessRight,
    }
  }, [hasMsgPermission, manager.farmAssociation.customPermissions, role])

  return { currCustomPermission, hasMsgPermission, setHasMsgPermission }
}

const getDefaultPermissionValue = (manager: ManagerType, role: Role): boolean => {
  return hasFarmAdminPermission(
    {
      role,
      permissions: manager.farmAssociation.customPermissions,
    },
    Permission.Messaging,
    AccessRight.Edit,
  )
}
