import { Alert, Button, Text, Toast } from '@elements'
import Colors from '../../../constants/Colors'
import { globalStyles } from '../../../constants/Styles'
import { View } from 'react-native'
import { hasGrownByPrivilege, UserRole } from '@models/User'
import React, { useCallback } from 'react'
import { getNextShareCloseDateTime, getNextShareOpenDateTime } from '@helpers/custom_shares/share_window'
import { isBefore } from '@helpers/time'
import { CustomShare } from '@models/CustomShare'
import { closeOrderingWindow, openOrderingWindow } from '@api/CustomShares'
import { isErrorWithCode } from '@shared/Errors'
import { errorToString } from '@helpers/helpers'
import { Logger } from '../../../config/logger'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../redux/selectors'
import { alertRunAction } from '../../../admin/screens/CustomShares/AdminCustomShareDetailScreen'

type Props = {
  share: CustomShare
  hasActiveRun: boolean
}

/** This function has one purpose it will tell us if we can open the ordering window, there are specific conditions that
 * prevent us from opening the ordering window so this will check them all. */
function canOpenShareWindow(share: CustomShare) {
  // We are after the last close date and before the order window is starting so we should not allow opening the
  // ordering window. This is because the pickup window can't support early opening. If it is too early to open we should
  // enable auto-run and if we can open then we should just open. The problem occurs when the order window is opened early and
  // someone tries to customize it will pick up the last window instead of current one.
  if (share.isOrderingOpen) return false
  const nextOpenDate = getNextShareOpenDateTime(share)
  const nextCloseDate = getNextShareCloseDateTime(share)
  if (isBefore(nextOpenDate, nextCloseDate)) return false

  return true
}

/** This component has controls for managing share runs like auto-running and manual runs*/
export function ShareRunControl({ share, hasActiveRun }: Props) {
  const user = useSelector(userSelector)
  const [isRunning, setIsRunning] = React.useState(false)
  const autoRunEnabled = share.nextRun !== undefined

  const toggleOrderingWindow = useCallback(
    async (showWarning: boolean) => {
      // If this is a manual run then we should show a warning before proceeding
      const shouldContinue = !showWarning || (await alertRunAction())
      if (!shouldContinue) return

      setIsRunning(true)
      try {
        if (share.isOrderingOpen) {
          await closeOrderingWindow(share, false)
        } else {
          if (!canOpenShareWindow(share)) {
            return Alert(
              "Can't open share window yet.",
              'It is not possible to open the ordering window early, please wait until the ordering window to allow orders.',
            )
          }
          await openOrderingWindow(share, false)
        }
        Toast('Run finished, see below for results')
      } catch (err) {
        //TODO: We should catch timeout errors and ignore them
        const title = `Failed to ${share.isOrderingOpen ? 'close' : 'open'} ordering window`
        if (isErrorWithCode(err)) {
          Alert(title, errorToString(err))
        } else {
          Logger.error(err)
          Alert(title, 'See run details for more information or contact support.')
        }
      } finally {
        setIsRunning(false)
      }
    },
    [setIsRunning, share],
  )

  const testShareOrdering = useCallback(async () => {
    setIsRunning(true)
    try {
      if (share.isOrderingOpen) {
        await closeOrderingWindow(share, true)
      } else {
        await openOrderingWindow(share, true)
      }
      Toast('Run finished, see below for results')
    } catch (err) {
      Alert('Error testing share', errorToString(err))
    } finally {
      setIsRunning(false)
    }
  }, [setIsRunning, share])

  return (
    <View style={globalStyles.alignCenter}>
      <Text
        size={18}
        type="bold"
        color={share.isOrderingOpen ? Colors.green : Colors.red}
        style={globalStyles.marginVertical10}
      >
        Ordering is currently {share.isOrderingOpen ? 'OPEN' : 'CLOSED'}
      </Text>
      <Text
        size={18}
        type="bold"
        color={autoRunEnabled ? Colors.green : Colors.red}
        style={globalStyles.marginVertical10}
      >
        Autorun is {autoRunEnabled ? 'ENABLED' : 'DISABLED'}
      </Text>
      {!autoRunEnabled && (
        <View style={globalStyles.flexRow}>
          {canOpenShareWindow(share) ? (
            <Button
              loading={isRunning || hasActiveRun}
              title="Open Ordering"
              onPress={() => toggleOrderingWindow(false)}
            />
          ) : (
            <Button loading={isRunning || hasActiveRun} title="Enable Autorun" onPress={testShareOrdering} />
          )}
        </View>
      )}
      {hasGrownByPrivilege(user, UserRole.Admin) && (
        <View style={globalStyles.flexRow}>
          <Button
            icon="user-shield"
            color={Colors.gold}
            loading={isRunning || hasActiveRun}
            title={share.isOrderingOpen ? 'Test Closing' : 'Test Opening'}
            onPress={testShareOrdering}
          />
          <Button
            outline
            icon="user-shield"
            color={Colors.gold}
            loading={isRunning || hasActiveRun}
            title={share.isOrderingOpen ? 'Close Ordering' : 'Open Ordering'}
            onPress={() => toggleOrderingWindow(true)}
          />
        </View>
      )}
    </View>
  )
}
