import { wholesaleSelector } from '@/redux/selectors'
import { MoneyCalc, isMoney } from '@helpers/money'
import { cartSubtotal } from '@helpers/order'
import { Farm } from '@models/Farm'
import { CartItem, isCartShare } from '@models/Order'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

type UseCartFarmDetailsProps = {
  /** The farm to show */
  farm: Farm
  /** Cart items that belong to a farm*/
  items: CartItem[]
}

/** Custom hook to retrieve detailed information about a farm's cart, including order minimums and subtotals. */
export function useFarmCartDetails({ farm, items }: UseCartFarmDetailsProps) {
  const { isWholesale } = useSelector(wholesaleSelector)

  const orderMin = useMemo(() => {
    const value = farm.orderMinimum?.[isWholesale ? 'wholesale' : 'retail']
    return isMoney(value) ? value : undefined
  }, [farm.orderMinimum, isWholesale])

  // Only include standard product in the calculation because shares can be purchased even if they don't meet the minimum
  const standardProdSubtotal = useMemo(() => {
    return cartSubtotal(
      items.filter((itm) => !isCartShare(itm)),
      farm.id,
      { isWholesale: !!isWholesale, excludeClosedDistros: true },
    )
  }, [items, farm.id, isWholesale])

  // Only include standard product in the calc
  const subtotal = useMemo(
    () => cartSubtotal(items, farm.id, { isWholesale: !!isWholesale, excludeClosedDistros: true }),
    [items, farm.id, isWholesale],
  )

  /** Should disable checkout button if we have an order minimum and standard products and they don't reach the minimum */
  const disableCheckout =
    orderMin && MoneyCalc.isGTZero(standardProdSubtotal) && MoneyCalc.isLessThan(standardProdSubtotal, orderMin)

  return { orderMin, standardProdSubtotal, disableCheckout, subtotal }
}
