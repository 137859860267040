import { Farm } from '@models/Farm'
import { User } from '@models/User'

import { DEFAULT_CONV_LIMIT, DEFAULT_MSG_LIMIT } from '@helpers/messaging'
import { ChatMessage, ChatSession, SendMessageProps, SetConversationAsReadProps } from '@models/Messaging'
import { limit, orderBy, where } from 'firebase/firestore'
import { chatMessagesCollection, chatSessionsCollection } from './framework/ClientCollections'
import { callEndpoint } from './v2'

/** TODO: Should be replaced by instant messaging feature now */
/** sendUserMessage sends a message to the user. */
export async function sendUserMessage(
  to: User,
  from: Farm,
  farmerId: string,
  subject: string,
  message: string,
): Promise<void> {
  return await callEndpoint('v2.Messaging.sendUserMessageService', {
    customerId: to.id,
    farmId: from.id,
    farmerId,
    subject,
    message,
  })
}

/** Will send a chat message to a participant */
export async function sendChatMessage(props: SendMessageProps) {
  return callEndpoint('v2.Messaging.sendChatMessageService', props)
}

/** Will set the conversation as read for a specific user */
export async function setConversationAsRead(props: SetConversationAsReadProps) {
  return callEndpoint('v2.Messaging.setConversationAsReadService', props)
}

/** Will create a snapshot listener for the conversations that a participant is in */
export function snapshotConversations(
  callback: (conversations: ChatSession[]) => void,
  onError: (err: Error) => void,
  userId: string,
  onlyUnread: boolean,
  maxLimit = DEFAULT_CONV_LIMIT,
) {
  const participantQuery = onlyUnread
    ? // This will select conversations where the participantId is included and has unread notifications
      where('unreadIds', 'array-contains', userId)
    : // This will select conversations where the participantId is included
      where('participantIds', 'array-contains', userId)

  const query = chatSessionsCollection.query(
    // Load only conversations that the user is apart of
    participantQuery,
    orderBy('lastMessage.sentAtUtc.utc', 'desc'),
    limit(maxLimit),
  )

  return chatSessionsCollection.snapshotMany(query, callback, onError)
}

/** Listener used for showing the number of unread messages badges on the UI */
export const unreadConversationListener = (userId: string, callback: (conversations: ChatSession[]) => void) => {
  const query = chatSessionsCollection.query(where('unreadIds', 'array-contains', userId), limit(11))

  return chatSessionsCollection.snapshotMany(query, callback)
}

/** Will create a snapshot listener for the chat messages of a conversation */
export function snapshotChatMessages(
  callback: (messages: ChatMessage[]) => void,
  onError: (err: Error) => void,
  chatSessionId: string,
  maxLimit = DEFAULT_MSG_LIMIT,
) {
  const query = chatMessagesCollection.resolve(chatSessionId).query(orderBy('sentAtUtc.utc', 'desc'), limit(maxLimit))

  return chatMessagesCollection.snapshotMany(query, callback, onError)
}

/** Loads all conversations for a specific user */
export function loadAllConversations(userId: string) {
  return chatSessionsCollection.fetchAll(
    where('participantIds', 'array-contains', userId),
    orderBy('lastMessage.sentAtUtc.utc', 'desc'),
  )
}
