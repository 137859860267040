import { capitalize } from '@helpers/display'
import { MoneyCalc, Zero } from '@helpers/money'
import { isInfinitePayment } from '@helpers/paymentMethods'
import { Farm } from '@models/Farm'
import { Money } from '@models/Money'
import { SplitTenderPayment } from '@models/Order'
import {
  EbtCardTypes,
  isAchPayment,
  isCashPayment,
  isCreditPayment,
  isEbtPayment,
  isFarmCreditPayment,
} from '@models/PaymentMethod'
import { PaymentSelectorOptions } from './types'

/** For payments in the future, we must have either infinite or EBT to cover the future amount */
export const needsFuturePaymentOption = (splitTender: SplitTenderPayment, options: PaymentSelectorOptions) =>
  options.hasFuturePayments && !splitTender.some(({ paymentMethod }) => isEbtPayment(paymentMethod))

/** For delivery fees, we must have infinite or EBT to cover the installment amount */
export const needsDeliveryPayment = (options: PaymentSelectorOptions) => options.hasDelivery

/**
 * This helper computes whether infinite payments should be shown and if so what they should be shown for
 * @param splitTender the split tender to check for payment configuration
 * @param options the options to compare with
 */
export function showInfinitePaymentsHelper(splitTender: SplitTenderPayment, options: PaymentSelectorOptions) {
  // This will tell us if we have split tender that contains an infinite payment
  const hasInfinitePayment =
    splitTender.length > 0 && splitTender.some((tender) => isInfinitePayment(tender.paymentMethod.source))

  const totalAmountSelected = splitTender.reduce((total, tender) => MoneyCalc.add(total, tender.amount ?? Zero), Zero)

  // Return the reason for needing an infinite payment
  //We should show the default infinite payment if we don't have enough to cover the total
  if (!MoneyCalc.isGTE(totalAmountSelected, options.amountTotal)) return 'default'
  if (needsFuturePaymentOption(splitTender, options)) return 'installment'
  if (needsDeliveryPayment(options)) return 'delivery'
  // If we have an infinite payment selected, and it is not for delivery or installments, then we should show the default
  if (hasInfinitePayment) return 'default'
  return undefined
}

type FormatSplitTenderOptions = {
  isWholesale?: boolean
  farm: Pick<Farm, 'id' | 'dueDateTolerance'>
}

/**
 * Formats the offline payment name based on wholesale status and due date tolerance
 * @param isWholesale Whether the order is wholesale
 * @param dueDateTolerance The wholesale due date tolerance
 * @returns The formatted offline payment name
 */
export function formatOfflinePaymentName(isWholesale: boolean, dueDateTolerance: number | undefined): string {
  if (isWholesale && dueDateTolerance !== undefined) {
    return dueDateTolerance === 0 ? 'Due on delivery' : `Terms (Net ${dueDateTolerance})`
  }
  return 'Offline'
}

/**
 * This helper will format the split tender for display and add the amounts next to the type
 */
export function formatSplitTender(tender: SplitTenderPayment, totalAmount: Money, options: FormatSplitTenderOptions) {
  const tenderTotal = tender.reduce((total, tender) => MoneyCalc.add(total, tender.amount ?? Zero), Zero)
  // Farm credit is displayed in a separate box, so we should not show it here
  const filteredTender = tender.filter((tender) => !isFarmCreditPayment(tender.paymentMethod))

  return filteredTender.map(({ paymentMethod, amount }): [string, Money] => {
    // Get the amount for the certain tender type
    const amt = amount ?? MoneyCalc.subtract(totalAmount, tenderTotal)

    if (isCreditPayment(paymentMethod)) {
      return [`${capitalize(paymentMethod.card_type)} *${paymentMethod.last4}`, amt]
    }
    if (isAchPayment(paymentMethod)) {
      return [`${capitalize(paymentMethod.bank_name)} *${paymentMethod.last4}`, amt]
    }
    if (isEbtPayment(paymentMethod)) {
      const typeString = paymentMethod.card_type === EbtCardTypes.SNAP ? 'SNAP' : 'EBT Cash'
      return [`${typeString} *${paymentMethod.last4}`, amt]
    }
    if (isCashPayment(paymentMethod)) {
      const dueDateTolerance = options.farm.dueDateTolerance?.wholesale
      const offlinePaymentName = formatOfflinePaymentName(!!options.isWholesale, dueDateTolerance)
      return [offlinePaymentName, amt]
    }
    return ['Offline', amt]
  })
}
