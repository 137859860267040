import { CustomShare } from '@models/CustomShare'
import { DateRange, DayOfWeek } from '@models/Schedule'
import { dateTimeInZone } from '@models/Timezone'
import { DateTime } from 'luxon'

type WindowConfig = {
  dayOfWeek: DayOfWeek
  time: string
}

/**
 * Calculates the next occurrence of a specified window (open or close) based on a weekday and time.
 * If the specified time has already passed for today's date, it will find the next occurrence.
 * The returned DateTime will always be in the future.
 *
 * @param customShare - The custom share to calculate the next window for
 * @param window - Configuration object containing dayOfWeek and time (HH:mm format)
 * @param baseDate - Optional base date to start from
 * @returns DateTime representing the next occurrence of the specified window
 */
function getNextShareWindowDateTime(
  customShare: Pick<CustomShare, 'farm'>,
  window: WindowConfig,
  baseDate?: DateTime,
): DateTime {
  const now = baseDate || dateTimeInZone(customShare.farm.timezone)
  const windowTime = DateTime.fromFormat(window.time, 'HH:mm')

  let nextWindow = now.set({
    hour: windowTime.hour,
    minute: windowTime.minute,
    second: 0,
    millisecond: 0,
  })

  if (nextWindow <= now) {
    nextWindow = nextWindow.plus({ days: 1 })
  }

  const currentDay = nextWindow.weekday
  const targetDay = window.dayOfWeek
  const daysToAdd = (targetDay - currentDay + 7) % 7

  return nextWindow.plus({ days: daysToAdd })
}

/**
 * Will get the next date coming up that will be the share close date, it will include hours
 */
export function getNextShareCloseDateTime(customShare: Pick<CustomShare, 'closeWindow' | 'farm'>) {
  // We get the time just before the close time, this allows us to say 11:59pm instead of closing at 12am
  return getNextShareWindowDateTime(customShare, customShare.closeWindow).minus({ millisecond: 1 })
}

/**
 * Will get the next date coming up that will be the share open date, it will include hours
 */
export function getNextShareOpenDateTime(customShare: Pick<CustomShare, 'openWindow' | 'farm'>) {
  return getNextShareWindowDateTime(customShare, customShare.openWindow)
}

/** Will return the range of pickups we will consider part of this shares customization. It assumes that the pickup dates
 * are between the close and open dates */
export function getCustomSharePickupRange(customShare: CustomShare): DateRange {
  const startDate = customShare.isOrderingOpen
    ? getLastShareOpenDateTime(customShare)
    : dateTimeInZone(customShare.farm.timezone)

  const nextClose = getNextShareWindowDateTime(customShare, customShare.closeWindow, startDate)
    .startOf('day')
    .minus({ milliseconds: 1 })

  return {
    // If we are opening the share window, we want to consider all pickups within 7 days of the close date
    startDate: nextClose,
    endDate: nextClose.plus({ days: 7 }),
  }
}

/**
 * Will get the start date of the current share ordering window if the share is open, otherwise will be the start of the last ordering window, it will include hours
 */
export function getLastShareOpenDateTime(customShare: CustomShare): DateTime {
  // Get the last open date by first moving back 7 days and finding the next occurrence from there
  const lastWeek = dateTimeInZone(customShare.farm.timezone).minus({ days: 7 })
  return getNextShareWindowDateTime(customShare, customShare.openWindow, lastWeek)
}
