import { Logger } from '@/config/logger'
import { useCancelableDeepCompareFx } from '@/hooks/useCancelablePromise'
import { setInternational } from '@/redux/actions/appPersist'
import { internationalSelector, sessionLocationSelector, userSelector } from '@/redux/selectors'
import { getParsedAddressFromCoords } from '@api/Addresses'
import { extendErr } from '@helpers/helpers'
import { CountryData, findCountryData } from '@helpers/international/types'
import { DateTime } from 'luxon'
import { useDispatch, useSelector } from 'react-redux'

/** Global hook intended to set the international state configuration */
export function useSetInternational() {
  const { timestamp } = useSelector(internationalSelector)
  const loc = useSelector(sessionLocationSelector)
  const user = useSelector(userSelector)
  const dispatch = useDispatch()

  useCancelableDeepCompareFx(
    async (isCurrent) => {
      if (timestamp && timestamp > DateTime.now().toMillis() - 60 * 60 * 1000) return

      let countryData: CountryData | null = null

      if (loc?.coordinate) {
        // Try to determine the country from the current session location first
        const res = await getParsedAddressFromCoords(loc.coordinate).catch((err) => {
          extendErr(
            err,
            'Failed to determine the user country form their location coordinates. Location: ' + loc.coordinate,
          )
          Logger.error(err)
          return null
        })
        if (!isCurrent) return

        const country = res?.getResult().country
        if (country) {
          countryData = findCountryData(country)
        }
      }

      if (!countryData && user.address?.country) {
        // If the country couldn't be obtained from the location and user has an address, use the country from their primary address
        countryData = findCountryData(user.address.country)
      }

      if (!countryData) return

      dispatch(
        setInternational({
          country: countryData.code,
          currency: countryData.currency,
          language: countryData.language,
          timestamp: DateTime.now().toMillis(),
        }),
      )
    },
    [loc?.coordinate, user.address, dispatch, timestamp],
  )
}
