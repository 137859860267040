import { ButtonClear, Modal, Text } from '@elements'
import { format, fromJSDate } from '@helpers/time'
import { UserRole, hasGrownByPrivilege } from '@models/User'
import * as Updates from 'expo-updates'
import { useState } from 'react'
import { StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'

import Colors from '@/constants/Colors'
import { signOut as signout } from '@/redux/actions/signout'
import { userSelector } from '@/redux/selectors'
import { SupportTicketType } from '@models/SupportTicket'

import { nativeAppVersion, updatesAppVersion } from '@/config/Environment'
import Feedback from '../Feedback'

// Used to format the app update date from utc to local time
const debugDate = (date: Date | null) => (date ? format(fromJSDate(date, 'UTC'), 'MM/dd/yy HH:mm') : 'unknown')

/**
 * Footer component for the User Screen that displays various user actions and app information.
 */
export function UserScreenFooter() {
  const [showUpdateId, setShowUpdateId] = useState(false)
  const user = useSelector(userSelector)

  return (
    <>
      <ButtonClear style={styles.signOut} title="Sign out" onPress={signout} />
      <ButtonClear
        style={styles.signOut}
        color={Colors.red}
        title="Delete Account"
        onPress={() => Modal(<Feedback type={SupportTicketType.DELETE_REQUEST} />, { title: 'Delete Account' })}
      />
      <Text style={styles.versionText} onPress={() => setShowUpdateId((prev) => !prev)}>
        {showUpdateId
          ? `${debugDate(Updates.createdAt)} (${nativeAppVersion || 'unknown'})`
          : `Version: ${updatesAppVersion || nativeAppVersion}`}
      </Text>
      {hasGrownByPrivilege(user, UserRole.Admin) && (
        <ButtonClear
          icon="user-shield"
          color={Colors.gold}
          style={styles.signOut}
          title="GrownBy Admin"
          url="/farm-generations/admin"
        />
      )}
    </>
  )
}
const styles = StyleSheet.create({
  signOut: {
    alignSelf: 'center',
  },
  versionText: {
    textAlign: 'center',
    color: Colors.semiTransparent,
  },
})
