import { KeyboardAvoidingScrollView, LoadingView, Text } from '@elements'
import { PaymentOptionsContext } from './PaymentOptionsContext'
import { isEbtPayment } from '@models/PaymentMethod'
import { memo, useContext } from 'react'
import { StyleSheet, View } from 'react-native'
import { AddNewPaymentOptions } from './AddNewPaymentOptions'
import Colors from '../../../constants/Colors'
import { FinitePaymentSection } from './FinitePaymentSection'
import { InfinitePaymentSection } from './InfinitePaymentSection'
import { SubmitPaymentButton } from './SubmitPaymentButton'
import { isInfinitePayment } from '@helpers/paymentMethods'

/** This modal handles all logic related to payment selection */
export const PaymentSelectionModal = memo(function PaymentSelectionModal() {
  const {
    options: { farm, allowOfflinePayments },
    userPaymentMethods,
    isLoading,
  } = useContext(PaymentOptionsContext)

  const hasEbtPaymentMethod = userPaymentMethods?.find(isEbtPayment)

  return (
    <LoadingView loading={isLoading} success={userPaymentMethods} switchMode>
      {(pmtMethods) => (
        <View style={styles.wrapper}>
          <KeyboardAvoidingScrollView>
            <FinitePaymentSection payments={pmtMethods.filter((pmt) => !isInfinitePayment(pmt.source))} />
            <InfinitePaymentSection payments={pmtMethods.filter((pmt) => isInfinitePayment(pmt.source))} />
            {/* If the farm allows cash payments then we should show the offline message here*/}
            {farm.paymentTypes.cash && allowOfflinePayments && (
              <View style={styles.offlineMsgWrapper}>
                <Text>
                  {farm?.offlinePayments ||
                    `To pay offline please contact ${
                      farm?.name || 'this farm'
                    } to see how they handle offline payments.`}
                </Text>
              </View>
            )}
            <AddNewPaymentOptions hasEbtPaymentMethod={!!hasEbtPaymentMethod} />
          </KeyboardAvoidingScrollView>
          {/* The submit button and error message should always be pinned to the bottom and not scroll */}
          <SubmitPaymentButton />
        </View>
      )}
    </LoadingView>
  )
})

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    padding: 10,
  },
  offlineMsgWrapper: {
    margin: 10,
    padding: 10,
    backgroundColor: Colors.lightGreen,
    borderRadius: 5,
  },
})
