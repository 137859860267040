import Colors from '@/constants/Colors'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { HomeParamList } from '@/navigation/types'
import { internationalSelector } from '@/redux/selectors'
import { Button } from '@elements'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import * as Linking from 'expo-linking'
import React from 'react'
import { StyleProp, TextStyle, ViewStyle } from 'react-native'
import { useSelector } from 'react-redux'

interface ButtonProps {
  inverse?: boolean
  style?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
}
/**
 * `OpenStoreButton` is a refactored button component used in multiple areas.
 * It navigates the user to the 'RegisterFarm' screen and can be styled inversely.
 *
 * @param {boolean} [inverse=false] - Determines if the button should be styled inversely.
 * @param style optional style params for container
 * @param textStyle optional style params for text
 * @returns {JSX.Element} The rendered `Button` component.
 */
export function FarmerOnboardOpenStoreButton({ inverse = false, style, textStyle }: ButtonProps): JSX.Element {
  const navigation = useNavigation<StackNavigationProp<HomeParamList>>()
  const styles = useButtonStyles(inverse)
  const { country } = useSelector(internationalSelector)

  return (
    <Button
      onPress={() =>
        navigation.navigate('FarmerWalkthroughNavigator', {
          // Only US customers should be directed to the screen where they search for farms to claim, because our claimable farms dataset only includes USDA directory. So farmers from other countries should go straight to the screen where they will manually enter their farm details
          screen: country === 'US' ? 'RegisterFarm' : 'AddFarmScreen',
        })
      }
      title="Open your Store"
      style={[styles.buttonStyle, style]}
      textStyle={[styles.textStyle, textStyle]}
    />
  )
}

/**
 * `DemoButton` is a refactored button component used in multiple areas.
 * It opens a URL when pressed and can be styled inversely.
 *
 * @param {boolean} [inverse=false] - Determines if the button should be styled inversely.
 * @param style optional style params for container
 * @param textStyle optional style params for text
 * @returns {JSX.Element} The rendered `Button` component.
 */
export function FarmerOnboardDemoButton({ inverse = false, style, textStyle }: ButtonProps): JSX.Element {
  const styles = useButtonStyles(inverse)

  return (
    <Button
      onPress={() => {
        Linking.openURL('https://www.farmgenerations.coop/farmers')
      }}
      title="Schedule a Demo"
      style={[styles.buttonStyle, style]}
      textStyle={[styles.textStyle, textStyle]}
    />
  )
}

const useButtonStyles = (inverse: boolean) =>
  useSizeFnStyles(({ isLargeDevice }) => ({
    buttonStyle: {
      justifyContent: 'center',
      minWidth: isLargeDevice ? 168 : '67%',
      backgroundColor: inverse ? Colors.white : Colors.green,
      borderWidth: inverse ? 1 : 0,
      borderColor: inverse ? Colors.green : 'transparent',
      marginHorizontal: 3,
    },
    textStyle: {
      color: inverse ? Colors.green : 'white',
    },
  }))
