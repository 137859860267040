import { OfflineTable, OfflineTableProps } from '@/admin/components/OfflineTable/OfflineTable'
import { OrderProductType } from '@/admin/navigation/types'
import { useLayout } from '@/hooks/useLayout'
import { Product } from '@models/Product'
import { memo } from 'react'
import { ProductRowProps } from './components/Rows/ProductRow'

type OrderCreatorTableProps = Omit<OfflineTableProps<Product>, 'renderItem'> & {
  orderType: OrderProductType
  ProductRow: React.FC<ProductRowProps>
}

/** Table shared between the order creator and order edit screens for displaying farm products */
export const OrderCreatorTable = memo(function OrderCreatorTable({
  data,
  orderType,
  isLoading,
  isLoadingNonBlock,
  search,
  searchTerm,
  ProductRow,
}: OrderCreatorTableProps) {
  const { isLargeDevice, height } = useLayout()

  const renderItem: NonNullable<OfflineTableProps<Product>['renderItem']> = ({ item, index }) => {
    return <ProductRow orderType={orderType} prod={item} index={index} />
  }

  /** Props common to the different types of the offline table */
  const commonProps: Pick<
    OfflineTableProps<Product>,
    | 'searchTerm'
    | 'search'
    | 'placeholder'
    | 'filters'
    | 'isLoading'
    | 'extraData'
    | 'containerStyle'
    | 'maxHeight'
    | 'renderItem'
    | 'scrollEnabled'
    | 'isLoadingNonBlock'
  > = {
    /** maxHeight is necessary on non-large size because the order summary will go below the table. a maxHeight less than the screenHeight should ensure the screen can be scrollable without relying on the narrow borders. For example, to prevent the iOS scroll being stuck in nested scroll. */
    maxHeight: isLargeDevice ? undefined : height * 0.8,
    searchTerm,
    search,
    placeholder: 'Search for products',
    isLoading,
    isLoadingNonBlock,
    extraData: [isLoading], //variables that should make the flatlist re-render. Similar to an effect trigger
    containerStyle: { marginVertical: 15 },
    renderItem,
    scrollEnabled: true,
  }

  return orderType === 'standard' ? (
    <OfflineTable
      data={data}
      headerColumns={[{ title: 'Product Name', widthFlex: 2 }, { title: 'Price' }, { title: 'Base Unit' }, {}]}
      minWidth={600}
      needsHeaderSpacer /** Needs spacer on standard row because row component has subRows */
      {...commonProps}
    />
  ) : orderType === 'share' ? (
    <OfflineTable
      data={data}
      headerColumns={[
        { title: 'Share Name', widthFlex: 2 },
        { title: 'Payment' },
        { title: 'Type' },
        { title: 'Start-End Dates' },
        { title: 'Price' },
        { widthFlex: 2 },
      ]}
      minWidth={750}
      needsHeaderSpacer={false} /** No spacer on share row because row component has NO subRows */
      {...commonProps}
    />
  ) : orderType === 'digital' ? (
    <OfflineTable
      data={data}
      headerColumns={[{ title: 'Product Name', widthFlex: 2 }, { title: 'Price' }, { title: 'Base Unit' }, {}]}
      minWidth={600}
      needsHeaderSpacer /** Needs spacer on digital row because row component has subRows */
      {...commonProps}
    />
  ) : null
})
