import { Alert, TextH1, TextH3 } from '@elements'
import { openUrl } from '@helpers/client'
import Constants from 'expo-constants'
import * as React from 'react'
import { Platform, StyleSheet, View } from 'react-native'

import { Image } from '../../components/Image'
import { Button } from '../../components/elements/Button'
import { Divider } from '../../components/elements/Divider'
import { nativeAppVersion, updatesAppVersion } from '../../config/Environment'
import { Logger } from '../../config/logger'
import Catch, { ErrorBoundaryProps } from './functional-error-boundary'

import { signOut as signout } from '@/redux/actions/signout'
import { InvalidAmount } from '@helpers/display'
import { SUPPORT_EMAIL } from '@shared/BaseUrl'

const getHelp = (error: Error) => {
  if (!error) return
  // Mail new line
  const newLine = '%0d%0a'

  const mailContent =
    `mailto:${SUPPORT_EMAIL}?` +
    `subject=GrownBy Support Request (APP CRASH) &` +
    `body=` +
    newLine.repeat(4) +
    `-----------------PLEASE TYPE ABOVE THIS LINE, DO NOT EDIT BELOW----------------` +
    newLine.repeat(2) +
    `PLATFORM: ${Platform.OS}\nDEVICE: ${Constants.deviceName}` +
    newLine +
    `APP VERSION: ${nativeAppVersion || InvalidAmount}` +
    newLine +
    `UPDATE VERSION: ${updatesAppVersion || InvalidAmount}` +
    newLine +
    `ERROR NAME: ${error.name}` +
    newLine +
    `ERROR MESSAGE: ${error.message}` +
    newLine +
    `ERROR STACK: ${error.stack}`

  openUrl(mailContent).catch(() => Alert('Unable to open Email app', `Please email ${SUPPORT_EMAIL}.`))
  Logger.error(error)
}

const ErrorBoundary = Catch(function MyErrorBoundary(props: ErrorBoundaryProps, error?: Error) {
  if (error) {
    return (
      <View style={styles.container}>
        <Image source={require(`../../assets/images/text_logo.png`)} style={styles.textLogo} />
        <Divider bottom={10} clear />
        <TextH1>Uh Oh! Something went wrong.</TextH1>
        <TextH3>Please contact support so that we can help you fix this.</TextH3>
        <Divider clear />
        <View style={{ width: 300 }}>
          <Button title="Contact Support" onPress={() => getHelp(error)} />
          <Button title="Clear App Data" onPress={signout} />
        </View>
      </View>
    )
  } else {
    return props.children
  }
})

export default ErrorBoundary

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  textLogo: {
    height: 50,
    width: 202,
    resizeMode: 'contain',
  },
})
