import { CurrencyCodes, Money, MoneyWithCurrency } from '@models/Money'
import * as Yup from 'yup'
import { isNum } from './helpers'
import { MoneyCalc, Zero, isMoney } from './money'

export const YUP_MONEY_REQUIRED = <RC extends boolean = false>(
  label: string,
  {
    allowZero = false,
    requireCurrency,
    allowNegative = false,
  }: { allowZero?: boolean; requireCurrency?: RC; allowNegative?: boolean } = {},
): Yup.ObjectSchema<RC extends true ? MoneyWithCurrency : Money> =>
  //@ts-expect-error
  Yup.object()
    .defined(`${label} is a required field`)
    .typeError(`${label} must be a valid amount`)
    .test('is-valid-money', `${label} must be a valid amount`, (money) => isMoney(money))
    .test('is-greater-than-zero', `${label} must be greater than $0`, (money) => {
      // There is no reason this will be false as we check it before, but this casts the type as money so we can be confident
      if (!isMoney(money)) return false

      if (!allowNegative && MoneyCalc.isLessThan(money, Zero)) return false

      if (!allowZero && MoneyCalc.isEqual(money, Zero)) return false

      return true
    })
    .test('has-currency', `${label} must have a currency`, (money) => {
      if (!isMoney(money)) return false
      if (!requireCurrency) return true
      return !!money.currency && CurrencyCodes.includes(money.currency)
    })

export const YUP_MONEY_OPTIONAL = <RC extends boolean = false>(
  label: string,
  { allowZero = false, requireCurrency }: { allowZero?: boolean; requireCurrency?: RC } = {},
): Yup.ObjectSchema<(RC extends true ? MoneyWithCurrency : Money) | undefined> =>
  //@ts-expect-error
  Yup.object()
    .typeError(`${label} must be a valid amount`)
    .optional()
    .test('is-valid-money', `${label} must be a valid amount`, (money) => money === undefined || isMoney(money))
    .test('is-greater-than-zero', `${label} must be greater than $0`, (money) => {
      // This field is optional, so if it is undefined we should pass the test
      if (money === undefined) return true
      // There is no reason this will be false as we check it before, but this casts the type as money so we can be confident
      if (!isMoney(money)) return false
      return allowZero ? MoneyCalc.isGTE(money, Zero) : MoneyCalc.isGTZero(money)
    })
    .test('has-currency', `${label} must have a currency`, (money) => {
      if (money === undefined) return true

      if (!isMoney(money)) return false
      if (!requireCurrency) return true
      return !!money.currency && CurrencyCodes.includes(money.currency)
    })

/** FYI: Keep in mind number schemas might receive the value as a string in some forms. */
export const YUP_WHOLE_NUMBER_REAL = (label: string, { allowDecimal = false, allowZero = false } = {}) =>
  Yup.number()
    .label(label)
    .required(`${label} is required`)
    .typeError(`${label} must be a number`)
    .test(
      'Is a valid number',
      'This is not a valid number',
      // This handles the possibility that a form has this as a string that can't be parsed into a number
      (val) => isNum(val),
    )
    .test(
      'Is whole?',
      `You can only specify whole numbers for ${label}`,
      (val) => typeof val === 'number' && (allowDecimal ? true : Math.round(val) === val),
    )
    .test(
      'Is greater than zero?',
      `${label} must be greater than zero`,
      (val) => typeof val === 'number' && (allowZero ? val >= 0 : val > 0),
    )

/** FYI: Keep in mind number schemas might receive the value as a string in some forms. */
export const YUP_WHOLE_NUMBER_OPTIONAL_REAL = (label: string, { allowDecimal = false, allowZero = false } = {}) =>
  Yup.number()
    .label(label)
    .typeError(`${label} must be a number`)
    .test(
      'Is a valid number',
      'This is not a valid number',
      // This handles the possibility that a form has this as a string that can't be parsed into a number
      (val) => val === undefined || isNum(val),
    )
    .test(
      'Is whole?',
      `You can only specify whole numbers for ${label}`,
      (val) => val === undefined || (typeof val === 'number' && (allowDecimal ? true : Math.round(val) === val)),
    )
    .test(
      'Is greater than zero?',
      `${label} must be greater than zero`,
      (val) => val === undefined || (typeof val === 'number' && (allowZero ? val >= 0 : val > 0)),
    )
