import { marshalPhoneNumber } from '@helpers/display'
import { limit, where } from 'firebase/firestore'

import { dequal } from '@helpers/customDequal'
import { keys, pick } from '@helpers/typescript'
import { isWholesaleBuyer } from '@helpers/wholesale'
import { User } from '@models/User'
import { isNotFound, NotFoundError } from '@shared/Errors'
import { ChangeUserRequest } from '@shared/types/v2/user'
import { marshalUser } from './encoding/User'
import { usersCollection } from './framework/ClientCollections'
import { callEndpoint } from './v2'

/** Will snapshot a user and pass live updates */
export function snapshotUser(userId: string, callback: (user: User) => void): () => void {
  return usersCollection.snapshotDoc(userId, (user) => {
    if (user) callback(user)
  })
}

/** loadUserByPhone returns the user identified by the supplied phone number. Phone numbers are expected to be unique to a single user. */
export async function loadUserByPhone(phoneNumber: string): Promise<User> {
  const users = await usersCollection.fetchAll(where('phoneNumber', '==', phoneNumber), limit(1))
  if (users.length === 0) {
    throw new NotFoundError('users', { phoneNumber })
  }
  return users[0]
}

/** loadUserByEmail returns the user identified by the supplied email address. */
export async function loadUserByEmail(email: string): Promise<User> {
  const users = await usersCollection.fetchAll(where('email', '==', email.toLowerCase().trim()), limit(1))
  if (users.length === 0) {
    throw new NotFoundError('users', { email })
  }
  return users[0]
}

/** loadUser returns the user identified by the supplied user ID. It raises a NotFoundError if the user is not found. */
export async function loadUser(userId: string): Promise<User> {
  return usersCollection.fetch(userId)
}

/** Checks whether a number is in the right format, and it doesnt exist in the db, except by the same user. If checks are passed, will return the marshalled number, else will throw errors */
export async function validatePhoneNumber(
  number: string,
  userId: string,
  hasCountryCode: boolean | undefined = true,
): Promise<string> {
  const formatted = marshalPhoneNumber(number, hasCountryCode)
  if (!formatted) throw new Error('There was an error while validating the phone number')
  let loadedU: User | undefined = undefined
  try {
    loadedU = await loadUserByPhone(formatted)
  } catch (e) {
    //If the user wasn't found, means it doesn't exist, so the number can be added
    return formatted
  }
  //If number was found, and it belongs to a different user, it can't be used
  if (loadedU.id !== userId) throw new Error('Phone number already exists')
  return formatted
}

/** Only these fields can be updated on the user without full validation */
type SimpleUpdateUserfields = keyof Pick<
  User,
  'notifications' | 'notificationBadgeCount' | 'promotions' | 'avatar' | 'preferences' | 'phoneNumber'
>

const simpleUpdateUserfields: SimpleUpdateUserfields[] = [
  'notifications',
  'notificationBadgeCount',
  'promotions',
  'avatar',
  'preferences',
  'phoneNumber',
]

/** Performs a simple mutation on a user document without full validation.
 * - This should only be used for updating simple fields in the user. For complete validation of updates, use @see {changeUser} */
export async function updateUser(user: Pick<User, 'id'> & Pick<Partial<User>, SimpleUpdateUserfields>): Promise<void> {
  // This step should pick only the allowed fields, for safety
  const update = pick(user, 'id', ...simpleUpdateUserfields)
  return usersCollection.update(update)
}

/** Allows for changing account related user fields, with thorough validation server side.
 * - This should be used whenever the user changes involve fields that require validation and/or non-duplicate fields, such as email or phone number.
 */
export async function changeUser(userId: string, oldUser: User, newUser: Partial<User>): Promise<void> {
  const { deltas, oldDeltas } = getDeltas(oldUser, newUser)
  await callEndpoint('v2.User.changeUserService', { userId, deltas, oldDeltas })
}

/** check if the customer details can be edited by farmers */
export async function canEditCustomer(farmId: string, customerId: string): Promise<boolean> {
  return await callEndpoint('v2.User.canEditCustomerService', { farmId, customerId })
}

/** one call to create firebase user and auth user */
export async function createFirestoreAndAuthUser(userData: Omit<User, 'id'>, farmId: string): Promise<void> {
  return await callEndpoint('v2.User.createFirestoreAndAuthUserService', {
    userData: marshalUser(userData),
    farmId,
  })
}

/**
 * This function checks if a user, identified by their email, is a Wholesale Buyer.
 * It returns true if the user is an institution account or an institution member (wholesale authorized user). Otherwise, it returns false.
 */
export async function checkIsWholesaleBuyerByEmail(email: string): Promise<boolean> {
  try {
    const user = await loadUserByEmail(email)
    return isWholesaleBuyer(user)
  } catch (e) {
    // If the user is not found then they can't be a wholesale buyer so we should return false
    if (isNotFound(e)) return false
    // Any other error should not be handled and thrown
    throw e
  }
}

/** Calculates the differences (deltas) between two user objects.*/
export function getDeltas(oldUser: User, newUser: Partial<User>) {
  const deltas = {} as ChangeUserRequest['deltas']
  const oldDeltas = {} as ChangeUserRequest['oldDeltas']

  keys(newUser).forEach((field) => {
    if (!dequal(oldUser[field], newUser[field])) {
      //@ts-expect-error
      deltas[field] = newUser[field]
      //@ts-expect-error
      oldDeltas[field] = oldUser[field]
    }
  })

  return { deltas, oldDeltas }
}

/** Converts a retail user to wholesale user */
export async function makeUserWholesale(userId: string, institution?: Partial<User['institution']>) {
  return callEndpoint('v2.User.makeUserWholesaleService', { userId, institution })
}
