import { InvoicePaymentMethod } from '@models/Invoice'
import { FinitePaymentMethodDefinition, PaymentMethodsDefinitionInterface } from './PaymentDefinition'
import { PaymentSources } from '@models/PaymentMethod'

/** Farm credit is a finite payment method that is used to track customers credit with a farm. */
export class FarmCreditPaymentDefinition
  extends FinitePaymentMethodDefinition<PaymentSources.FARM_CREDIT>
  implements PaymentMethodsDefinitionInterface<PaymentSources.FARM_CREDIT>
{
  constructor() {
    super(PaymentSources.FARM_CREDIT, 'Farm Credit')
  }

  formatInvPayMethod(_: InvoicePaymentMethod<PaymentSources.FARM_CREDIT>) {
    return this.formatShort()
  }
}
