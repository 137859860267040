import { Logger } from '@/config/logger'
import { errorToString } from '@helpers/helpers'
import { NotFoundError } from '@shared/Errors'
import type { SWRSubscriptionOptions } from 'swr/subscription'
import useSWRSubscription from 'swr/subscription'

type UseSnapshotOpts<Return> = {
  /** Will be called with the new state on change */
  onStateChange?: (state: UseSnapshotReturn<Return>) => void
}

type Unsubscribe = () => void
type SnapshotFunction<Args extends Parameters<any>, Return> = (
  handler: (data?: Return) => void,
  onErr: (err: unknown) => void,
  ...args: Args
) => Unsubscribe
type UseSnapshotReturn<Return> = { loading: boolean; error?: string; data: Return | undefined }

/**
 * Custom hook to manage a snapshot listener with loading and error states.
 * This hook utilizes a snapshot function to listen for data changes and manage
 * the loading and error states accordingly. It integrates with SWR for data
 * subscription and caching.
 * - Additional documentation: https://swr.vercel.app/docs/subscription
 */
export function useSnapshot<Args extends Parameters<any>, Return>(
  /** A unique used string as base in the `react-swr` in order to correctly use caching.
   * - Should always be the function name in order to ensure consistency & correct functionality*/
  baseKey: string,
  snapshotFn: SnapshotFunction<Args, Return>,
  args: Args,
  shouldRun = true,
  { onStateChange }: UseSnapshotOpts<Return> = {},
): UseSnapshotReturn<Return> {
  /** Key for SWR cache; it must be unique for each function, and it should include all the deps, in order to retrigger on change.
   * - `shouldRun` is also included in order to retrigger the subscription when it's true*/
  const key = `${baseKey}-${JSON.stringify(args)}-${shouldRun}`

  const { data, error } = useSWRSubscription<Return, string, string>(
    key,
    (
      _,
      {
        // A function that accepts an error and data, and updates the state with the latest data received from the real-time data source.
        next,
      }: SWRSubscriptionOptions<Return, string>,
    ) => {
      if (!shouldRun) {
        return () => {}
      }

      const onError = (err: unknown) => {
        Logger.error(err)

        const error = errorToString(err)
        next(error)
        onStateChange?.({ loading: false, data: undefined, error })
      }

      const unsubscribe = snapshotFn(
        (data) => {
          if (!data) {
            // data can be undefined if the resource does not exist (i.e. snapshotDoc with incorrect id)
            // Note: it won't trigger if the data is an empty array (i.e. snapshotMany)
            return onError(new NotFoundError(snapshotFn.name, args))
          }

          next(null, data)
          onStateChange?.({ loading: false, data, error: undefined })
        },
        onError,
        ...args,
      )

      return unsubscribe
    },
  )
  const loading = shouldRun ? !data && !error : false

  return {
    loading,
    data,
    error,
  }
}
