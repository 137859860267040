import { addCustomerToFarm, findUserByEmail } from '@api/Customers'
import { Alert, FormButton, FormInput, FormPickerInput, hideModal, Text, Toast } from '@elements'
import { Farm } from '@models/Farm'
import { Formik } from 'formik'
import { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import { Logger } from '@/config/logger'
import { UserType } from '@models/User'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { isErrorWithCode } from '@shared/Errors'

import { CustomerApiError } from '@models/Customer'
import Colors from '../../constants/Colors'
import { RootState } from '../../redux/reducers/types'
import { adminFarmSelector, userSelector } from '../../redux/selectors'
import { CustomerParamList } from '../navigation/types'
import { onInvitingAdminCustomerAlert, onInvitingExistingRetailCustomerAlert } from '../screens/Customer/alerts'

type FormType = typeof initialValues

const CustomerEmailSchema = Yup.object({
  email: Yup.string().email('Please enter a valid email').trim().label('Email Address').required(),
  userType: Yup.string<UserType>().required().oneOf(Object.values(UserType)),
})

const initialValues = {
  email: '',
  userType: UserType.retail,
}

/** Prompt that handles email input and user type selection*/
export function InviteCustomerPrompt() {
  const farm = useSelector<RootState, Farm>(adminFarmSelector)
  const farmAdmin = useSelector(userSelector)
  const navigation = useNavigation<StackNavigationProp<CustomerParamList, 'CustomerList'>>()

  const [loading, setLoading] = useState<boolean>(false)

  /** Handles the case when customer was not found */
  const onCustomerNotFound = (values: FormType) => {
    hideModal()

    Alert(
      'New Customer',
      'This email is not a GrownBy customer yet. Enter their information to add them as a customer.',
      [
        {
          text: 'Cancel',
          style: 'default',
        },
        {
          text: 'Continue',
          style: 'default',
          onPress: () => {
            navigation.navigate('AddCustomer', { email: values.email, type: values.userType })
          },
        },
      ],
    )
  }

  /**
   * The function handles the submission of the form and checks if the customer exists in the database.
   * If the customer exists, the function will invite the customer to the farm. If the customer does
   * not exist, the function will proceed to the next page to enter details of the customer
   */
  const handleSubmit = async (values: FormType) => {
    setLoading(true)

    const customer = await findUserByEmail(values.email)
    try {
      setLoading(false)

      if (!customer) {
        return onCustomerNotFound(values)
      }

      await addCustomerToFarm(customer, farm, farmAdmin, values.userType)
      setLoading(false)
      hideModal()
    } catch (error) {
      Logger.error(error)
      hideModal()

      if (!isErrorWithCode(error)) {
        return Toast('Something went wrong. Please try again')
      }

      if (error.name === CustomerApiError.UserTypeMismatch && customer) {
        return onInvitingExistingRetailCustomerAlert(customer, () => navigation.navigate('CustomerList'))
      }

      if (error.name === CustomerApiError.UserRoleMismatch) {
        return onInvitingAdminCustomerAlert()
      }

      return Toast(error.uiMsg)
    }
  }

  return (
    <View>
      <Text style={styles.description}>
        To add a customer, enter their email here and GrownBy will send them an email to complete account creation. If a
        customer's email or phone number is already associated with an existing GrownBy account, GrownBy will send them
        an invitation to be your customer.
      </Text>

      <Formik
        validationSchema={CustomerEmailSchema}
        initialValues={initialValues}
        validateOnChange
        validateOnBlur
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, handleBlur, handleChange, values, errors, touched }) => (
          <View style={styles.form}>
            {/* Include user type selector only if farm is wholesale. By default, retail is selected */}
            {farm.isWholesale && (
              <FormPickerInput
                useWebNativePicker
                containerStyle={styles.pickerCont}
                value={values.userType}
                items={userTypes}
                placeholder={null}
                onValueChange={handleChange('userType')}
                onBlur={handleBlur('userType')}
              />
            )}
            <FormInput
              label={null}
              placeholder="Customer Email"
              keyboardType="email-address"
              value={values.email}
              onChangeText={handleChange('email')}
              onBlur={handleBlur('email')}
              errorMessage={touched.email ? errors.email : ''}
            />
            <FormButton loading={loading} onPress={handleSubmit} title="Invite" />
          </View>
        )}
      </Formik>
    </View>
  )
}

const userTypes = [
  { value: UserType.retail, label: 'Retail Customer' },
  { value: UserType.wholesale, label: 'Wholesale Customer' },
]

const styles = StyleSheet.create({
  error: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10,
    color: Colors.red,
    fontWeight: 'bold',
  },
  description: {
    marginLeft: 20,
    marginBottom: 50,
    color: Colors.shades['400'],
  },
  form: {
    paddingHorizontal: 10,
  },
  pickerCont: {
    alignSelf: 'flex-start',
  },
})
