import { formatAddress } from '@helpers/display'
import { groupBy } from '@helpers/helpers'
import { createAddressString, getDeliveryFee, getUniqueDates } from '@helpers/location'
import { MoneyCalc, Zero } from '@helpers/money'
import { calculatePayments } from '@helpers/order'
import { isAfter } from '@helpers/time'
import { isNonPickup } from '@models/Location'
import { Pickup, isCartPhysical } from '@models/Order'
import { CartItemGroupType } from '@screens/Shopping/Checkout/CartItemsCheckout'
import { initialTotal } from '@screens/Shopping/Checkout/helpers'
import { DateTime } from 'luxon'
import { useMemo } from 'react'

import { OrderCreatorStatePayments } from './OrderCreatorScreen.helper'

import { CartService } from '@/constants/types/cartService'
import { UseApiFxReturn } from '@/hooks/useApiFx'
import { useDeepCompareFocusFx, useFocusFx } from '@/hooks/useFocusFx'
import { KeyedState } from '@/hooks/useKeyedState'
import { Farm } from '@models/Farm'
import { isFeeProductFee, isTaxProductFee } from '@models/ProductFee'

/** Data layer for payments-related state which is reused between the order creator and order edit */
export function usePaymentsAndTotalsData_reusable({
  custPickupsFx,
  keyedState: [{ splitTender }, set],
  farm,
  cartService: { cart, discounts, isAdmin },
  isWholesale,
}: {
  custPickupsFx: UseApiFxReturn<(userId: string, farmId: string) => Promise<Pickup[]>>
  keyedState: KeyedState<OrderCreatorStatePayments>
  farm: Farm | undefined
  cartService: CartService
  isWholesale: boolean | undefined
}) {
  /** Sets the total delivery fee data into context */
  useDeepCompareFocusFx(() => {
    if (custPickupsFx.loading) {
      return set('deliveryFeesData', (prev) => ({ ...prev, loading: true }))
    }
    if (custPickupsFx.err) {
      return set('deliveryFeesData', (prev) => ({ ...prev, err: 'Could not load delivery fee data' }))
    }
    const deliveryFeeData = getDeliveryFee(cart, { pickups: custPickupsFx.data })
    const hasDeliveryFees = deliveryFeeData?.itemsDeliveryFees && MoneyCalc.isGTZero(deliveryFeeData?.itemsDeliveryFees)
    set('deliveryFeesData', {
      ...deliveryFeeData,
      hasDeliveryFees,
      err: undefined,
      loading: false,
    })
  }, [cart, set, custPickupsFx])

  /** Sets into context a grouping of items by schedule location, allows managing delivery fee totals per location */
  useFocusFx(() => {
    // groups of cart items which have the same address
    const deliveryGroups = groupBy(cart, (itm) =>
      isCartPhysical(itm)
        ? //group items by distro id and address.
          itm.distribution.location.id + createAddressString(itm.distribution.location.address!)
        : 'digital',
    ).map((group): CartItemGroupType => {
      const locId = group[0].distribution?.location.id
      const { itemsDeliveryFees, combinedDates, combinedPickups } = getDeliveryFee(group, {
        pickups: custPickupsFx.data,
        locId,
      })

      return {
        items: group,
        address: isCartPhysical(group[0]) ? formatAddress(group[0].distribution.location.address!) : undefined,
        locationFee:
          isCartPhysical(group[0]) && isNonPickup(group[0].distribution.location)
            ? group[0].distribution.location.cost
            : undefined,
        groupDeliveryTotal: itemsDeliveryFees,
        groupDeliveryTotalDueNow: undefined, //hard-coded because we invoice delivery fees always in the future
        combinedDeliveryDates: combinedDates,
        combinedDeliveryPickups: combinedPickups,
        uniqueDates: getUniqueDates(group),
        locType: isCartPhysical(group[0]) ? group[0].distribution.location.type : undefined,
      }
    })
    set('itemGroups', deliveryGroups)
  }, [cart, custPickupsFx.data, set])

  /** Array of installment payments for the current state */
  const payments = useMemo(() => {
    if (!cart.length || !farm || typeof isWholesale !== 'boolean') return undefined

    return calculatePayments(
      {
        items: cart,
        isAdmin,
        discounts,
      },
      {
        isWholesale,
        // Should only consider items from the current admin farm in the calculation
        farmId: farm.id,
        splitTender,
        dueDateTolerance: farm.dueDateTolerance,
        timezone: farm.timezone,
      },
    )
    // splitTender is intentionally left out because we only need to update it when discounts change. Otherwise, there will
    // be an infinite loop of discount making total less which makes splitTender less which makes discount less and so on.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, farm, discounts, isWholesale, isAdmin])

  /** Gets the cart total amounts due now */
  useFocusFx(() => {
    if (!farm?.timezone || !payments) return

    // If there is no payment due today don't list it in the checkout page. For wholesale, we will always show the first payment
    if (isAfter(payments[0].date, DateTime.now(), { granularity: 'day', zone: farm.timezone }) && !isWholesale) {
      return set('total', initialTotal)
    }

    set('additionalFees', payments[0].taxesAndFees?.filter((itm) => isFeeProductFee(itm.productFee)) ?? [])

    const tax =
      payments[0].taxesAndFees
        ?.filter((itm) => isTaxProductFee(itm.productFee))
        .reduce((acc, itm) => MoneyCalc.add(acc, itm.amount), Zero) ?? Zero

    set('total', {
      subtotal: payments[0].subtotal,
      total: payments[0].total,
      discounts: payments[0].discounts,
      ebtEligibleAmount: payments[0].ebtEligibleAmount,
      tax,
    })
  }, [payments, farm, set, isWholesale])

  return { payments }
}
