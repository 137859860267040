import { FormikErrors } from 'formik'
import { ValidationError } from 'yup'
import { deepClone } from './helpers'

/** used to safely access deeply nested properties in an object. If a property doesn't exist along the given path, it should return undefined (or a default value). */
function getIn(obj: Record<any, any>, path: string | string[], defaultValue: any = undefined): any {
  const pathArray = Array.isArray(path) ? path : path.split('.')

  return pathArray.reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : defaultValue), obj)
}

/** used to set a deeply nested property at a specific path in an object. This function should return a new object with the updated value, leaving the original object unchanged (to maintain immutability). */
function setIn(obj: Record<any, any>, path: string | string[], value: any): any {
  const pathArray = Array.isArray(path) ? path : path.split('.')
  const newObj = deepClone(obj) // Clone the original object to maintain immutability
  let current = newObj

  // Traverse the path and create intermediate objects if necessary
  pathArray.forEach((key, index) => {
    if (index === pathArray.length - 1) {
      current[key] = value // Set the final value
    } else {
      current[key] = { ...current[key] } // Clone the intermediate object if needed
      current = current[key] // Move deeper into the structure
    }
  })

  return newObj
}

/** Custom implementation of Formik's yupToFormErrors. This will convert a yup validation error into an object of errors whose structure resembles the schema.
 * - This must be used whenever yupToFormErrors is used in the server because formik is not installed on the server.
 */
export function yupToFormErrors<Values extends Record<any, any>>(error: unknown): FormikErrors<Values> {
  let errorsObj: FormikErrors<any> = {}

  if (error instanceof ValidationError) {
    error.inner.forEach((err) => {
      // Only set the error if the path does not already have an error
      if (err.path && !getIn(errorsObj, err.path)) {
        errorsObj = setIn(errorsObj, err.path, err.message)
      }
    })
  }

  return errorsObj
}
