import { AdminHalfCardsContainer } from '@/admin/components/AdminCard'
import { AdminView } from '@/admin/components/AdminView'
import { EditHeader } from '@/admin/components/EditHeader'
import { CustomerParamList } from '@/admin/navigation/types'
import { globalStyles } from '@/constants/Styles'
import { withAdminAuth } from '@/hooks/withAdminAuth'
import { adminFarmSelector } from '@/redux/selectors'
import { PhoneInput } from '@components'
import { FormInput, FormStatePicker, FormZipcodeInput, Text } from '@elements'
import { Permission } from '@helpers/Permission'
import { AddCustomerDataForm } from '@models/Customer'
import { Farm } from '@models/Farm'
import { UserType } from '@models/User'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Formik, FormikProps } from 'formik'
import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { customerDetailSchema } from '../helpers/customerDetailYup'
import { useAddCustomerData } from './hooks/useAddCustomerData'

/** Component that shows the add user form. It is exported so it can be used in story. */
export function AddCustomerScreenComp() {
  const route = useRoute<RouteProp<CustomerParamList, 'AddCustomer'>>()
  const navigation = useNavigation<StackNavigationProp<CustomerParamList, 'AddCustomer'>>()
  const isWholesaleBuyer = route?.params?.type === UserType.wholesale
  const farm = useSelector(adminFarmSelector)

  const { handleSubmit, loading } = useAddCustomerData({ userType: route.params.type })

  return (
    <AdminView>
      <Formik<AddCustomerDataForm>
        validationSchema={customerDetailSchema}
        initialValues={getInitialValues(route.params.email, isWholesaleBuyer, farm)}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ touched, errors, values, handleChange, handleBlur, handleSubmit }: FormikProps<AddCustomerDataForm>) => (
          <>
            <EditHeader
              goBack={() => navigation.navigate('CustomerList')}
              title="Add Customer"
              actionTitle="Save"
              isLoading={loading}
              onPress={handleSubmit}
            />

            <AdminHalfCardsContainer>
              <View>
                <Text style={globalStyles.marginVertical10} size={14} type="medium">
                  Name & Email
                </Text>
                <FormInput
                  placeholder="First Name"
                  value={values.firstName}
                  onChangeText={handleChange('firstName')}
                  onBlur={handleBlur('firstName')}
                  errorMessage={touched.firstName ? errors.firstName : undefined}
                />
                <FormInput
                  placeholder="Last Name"
                  value={values.lastName}
                  onChangeText={handleChange('lastName')}
                  onBlur={handleBlur('lastName')}
                  errorMessage={touched.lastName ? errors.lastName : undefined}
                />
                <FormInput
                  placeholder="Email Address 1"
                  keyboardType="email-address"
                  value={values.email}
                  onChangeText={handleChange('email')}
                  onBlur={handleBlur('email')}
                  errorMessage={touched.email ? errors.email : undefined}
                />
                <FormInput
                  placeholder="Email Address 2"
                  keyboardType="email-address"
                  value={values.email2}
                  onChangeText={handleChange('email2')}
                  onBlur={handleBlur('email2')}
                  errorMessage={touched.email2 ? errors.email2 : undefined}
                />
                <PhoneInput
                  value={values.phoneNumber}
                  maskHandler={handleChange('phoneNumber')}
                  onBlur={handleBlur('phoneNumber')}
                  errorMessage={touched.phoneNumber ? errors.phoneNumber : undefined}
                />
              </View>
              <View>
                <Text style={globalStyles.marginVertical10} size={14} type="medium">
                  {isWholesaleBuyer ? 'Business information' : 'Billing Address'}
                </Text>
                {isWholesaleBuyer && (
                  <FormInput
                    placeholder="Business Name"
                    value={values.businessName}
                    onChangeText={handleChange('businessName')}
                    onBlur={handleBlur('businessName')}
                    errorMessage={touched.businessName ? errors.businessName : undefined}
                  />
                )}
                <FormInput
                  placeholder="Address Line 1"
                  value={values.street1}
                  onChangeText={handleChange('street1')}
                  onBlur={handleBlur('street1')}
                  errorMessage={touched.street1 ? errors.street1 : undefined}
                />
                <FormInput
                  placeholder="Address Line 2"
                  value={values.street2}
                  onChangeText={handleChange('street2')}
                  onBlur={handleBlur('street2')}
                  errorMessage={touched.street2 ? errors.street2 : undefined}
                />
                <FormInput
                  placeholder="City"
                  value={values.city}
                  onChangeText={handleChange('city')}
                  onBlur={handleBlur('city')}
                  errorMessage={touched.city ? errors.city : undefined}
                />
                <FormStatePicker
                  country={values.country}
                  errorMessage={touched.state ? errors.state : undefined}
                  value={values.state}
                  onValueChange={handleChange('state')}
                  onBlur={handleBlur('state')}
                />
                <FormZipcodeInput
                  country={values.country}
                  value={values.zipcode}
                  onChangeText={handleChange('zipcode')}
                  onBlur={handleBlur('zipcode')}
                  errorMessage={touched.zipcode ? errors.zipcode : undefined}
                />
              </View>
            </AdminHalfCardsContainer>
          </>
        )}
      </Formik>
    </AdminView>
  )
}

export const AddCustomerScreen = withAdminAuth(AddCustomerScreenComp, Permission.Orders)

const getInitialValues = (email: string, isWholesaleBuyer: boolean, farm: Farm): AddCustomerDataForm => {
  const baseData = {
    firstName: '',
    lastName: '',
    email,
    email2: '',
    phoneNumber: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    zipcode: '',
    country: farm.address.country,
  }

  if (!isWholesaleBuyer) {
    return { ...baseData, userType: UserType.retail }
  }

  return { ...baseData, businessName: '', userType: UserType.wholesale }
}
