import { CustomerFilters, openCustomersCSV } from '@helpers/links'
import { AlgoliaAdminCustomer, AlgoliaAdminDoc } from '@models/Algolia'
import { Farm } from '@models/Farm'
import * as React from 'react'
import { StateResultsProvided } from 'react-instantsearch-core'
import { connectStateResults } from 'react-instantsearch-native'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/reducers/types'
import { adminFarmSelector } from '../../../../redux/selectors'

import Export from '@/admin/components/Export'
import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { UseLayoutReturn } from '@/hooks/useLayout'

/** connect to Customers list CSV EXCEL document */
export const ExportCSVButton = connectStateResults(
  ({ searchState }: StateResultsProvided<AlgoliaAdminCustomer & AlgoliaAdminDoc>) => {
    const farm = useSelector<RootState, Farm>(adminFarmSelector)
    const styles = useStyles()

    let searchQuery: string | undefined
    const filters: CustomerFilters = {
      csaName: searchState.menu?.['csas.name'],
      productName: searchState.menu?.['products.name'],
      locationName: searchState.menu?.['locations.name'],
      distributionName: searchState.menu?.['distributions.name'],
    }

    if (searchState.query) {
      searchQuery = searchState.query
    }

    return (
      <Export
        style={styles.exportButton}
        title="Export to CSV"
        onPress={() => {
          openCustomersCSV(farm.id, filters, searchQuery)
        }}
      />
    )
  },
)

const useStyles = () =>
  useLayoutFnStyles((layout: UseLayoutReturn) => ({
    exportButton: {
      marginRight: 10,
      marginBottom: layout.isExtraSmallDevice ? 10 : 0,
      alignSelf: layout.isExtraSmallDevice ? 'flex-start' : 'auto',
    },
  }))
