import { loadFarm } from '@api/Farms'
import { loadInvoicesByOrderAndUser } from '@api/Invoices'
import { snapshotOrder } from '@api/Orders'
import { ConsumerScroll, ProcessedOrderSummary } from '@components'
import { HeaderText, Spinner, Text, TextH1, TextH2 } from '@elements'
import { nonEmptyString } from '@helpers/helpers'
import { sortByEarliest, sortCartItems } from '@helpers/sorting'
import { Invoice, isOffline } from '@models/Invoice'
import { Order } from '@models/Order'
import { HeaderBackButton } from '@react-navigation/elements'
import { RouteProp } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useLayoutEffect, useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch } from 'react-redux'

import LoaderWithMessage from '../../components/LoaderWithMessage'
import { OrderItem } from '../../components/OrderItem'
import { Divider } from '../../components/elements/Divider'
import Colors from '../../constants/Colors'
import { globalStyles } from '../../constants/Styles'
import { withAuth } from '../../hooks/withAuth'
import { OrdersParamList } from '../../navigation/types'
import { addNavProp } from '../../redux/actions/appState'
import { PaymentCard } from './PaymentCard'

import { isMobile } from '@/constants/Layout'
import { useApiFx } from '@/hooks/useApiFx'
import { useFocusFx } from '@/hooks/useFocusFx'
import { useSnapshot } from '@/hooks/useSnapshot'

interface Props {
  route: RouteProp<OrdersParamList, 'OrderSummary'>
  navigation: StackNavigationProp<OrdersParamList, 'OrderSummary'>
}
function OrderSummaryComp({ route, navigation }: Props) {
  const { orderId, confirmation } = route.params
  const dispatch = useDispatch()
  const { data: order, loading: loadingOrder } = useSnapshot(
    'snapshotOrder',
    snapshotOrder,
    [orderId],
    nonEmptyString(orderId),
  )
  const { data: farm } = useApiFx(loadFarm, [order?.farm.id], !!order) //You cannot use the farm navProp in cache because that is set by consumer screens and may not match the order farm id, and you also shouldn't set it here because it's only needed for this screen.
  const { data: invoices } = useApiFx(loadInvoicesByOrderAndUser, [orderId], nonEmptyString(orderId))

  useFocusFx(() => {
    if (order) {
      // Will keep the navprop in sync with order changes
      dispatch(addNavProp({ order }))
    }
  }, [order, dispatch])

  // Check if we are navigating to another order
  const isDifferentOrder = orderId !== order?.id

  // Persist loader until order fetch (refresh) is complete
  // Persist loader until all invoices are loaded (show only when everything loaded)
  const isLoading = loadingOrder || !order?.orderNum || !invoices?.length || isDifferentOrder

  useLayoutEffect(() => {
    // This overrides the default back behavior and forces the back button to
    // always show for this screen, even when navigating to it from checkout
    if (isMobile)
      navigation.setOptions({
        headerLeft: () => (
          <HeaderBackButton
            onPress={() =>
              navigation.reset({
                index: 0,
                routes: [{ name: 'Orders' }],
              })
            }
          />
        ),
      })
  }, [navigation])

  return (
    <ConsumerScroll>
      {confirmation && (
        <View style={styles.headerCard}>
          <HeaderText style={styles.margin}>Success! Thank you for your order!</HeaderText>
          <Text size={16} type="medium" style={styles.margin}>
            Here is your order summary:
          </Text>
        </View>
      )}
      {order && !isLoading ? (
        <>
          <OrderReceipt order={order} invoices={invoices} />

          {/* As long as one invoice is paid or is chose to pay by offline, then we show this text.  */}
          {invoices.some((inv) => isOffline(inv)) && (
            <>
              <HeaderText>Offline Payments</HeaderText>
              <View style={globalStyles.margin10}>
                {farm?.offlinePayments ? (
                  <>
                    <TextH2>A note from {order.farm.name}</TextH2>
                    <Text>{farm.offlinePayments}</Text>
                    <Divider />
                  </>
                ) : (
                  <Text>To pay offline please contact {order.farm.name} to see how they handle offline payments.</Text>
                )}
                <Text>
                  NOTE: You may also pay your outstanding invoices by Credit Card at anytime via the links above.
                </Text>
              </View>
            </>
          )}
          <Divider bottom={10} clear />
        </>
      ) : (
        <LoaderWithMessage loading={isLoading} icon="shopping-bag" title="Not Found!">
          <Text>This order could not be loaded, please go back and try again.</Text>
        </LoaderWithMessage>
      )}
    </ConsumerScroll>
  )
}

export const OrderSummary = withAuth(OrderSummaryComp)

type ReceiptProps = {
  order: Order
  invoices?: Invoice[]
  isAdmin?: boolean
}

/** Component to render the order information */
export function OrderReceipt({ order, invoices, isAdmin }: ReceiptProps) {
  const firstInvoice = useMemo(() => {
    if (!invoices) return undefined
    const inv = [...invoices].sort(sortByEarliest('dueDate'))[0]
    if (!inv) return undefined
    return inv
  }, [invoices])

  const getNumPayments = (id: string) => {
    // Get payments number for a specific item.
    // Exclude upfront payments (no 'installment' in description)
    if (!invoices) return 0
    let invLen = 0
    invoices.forEach((inv) => {
      const hasItem = inv.items.filter((itm) => itm.id === id && itm.description.toLowerCase().includes('installment'))
      invLen += hasItem.length > 0 ? 1 : 0
    })
    return invLen
  }

  if (!order) return <Spinner size="large" />
  return (
    <View>
      <ProcessedOrderSummary currentOrder={order} firstInvoice={firstInvoice} />
      <View style={styles.itemsWrapper}>
        <TextH1 style={styles.marginBottom} size={18}>
          Order items
        </TextH1>

        {[...order.items].sort(sortCartItems).map((item, idx) => (
          <View key={idx}>
            <OrderItem item={item} numPayments={getNumPayments(item.id)} showAdmin={isAdmin} />
            <Divider clear />
          </View>
        ))}
      </View>

      <Divider clear style={styles.divider} />
      <HeaderText>Payments</HeaderText>
      {invoices?.length ? (
        [...invoices].sort(sortByEarliest('dueDate')).map((pay) => (
          <View key={pay.id}>
            <PaymentCard invoice={pay} />
          </View>
        ))
      ) : (
        <View style={globalStyles.flex1}>
          <LoaderWithMessage loading icon="exclamation-triangle" title="Could not load invoices!">
            <Text>Failed to load invoices</Text>
          </LoaderWithMessage>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  detailContainer: {
    marginLeft: 10,
  },
  margin: globalStyles.margin10,
  paddingTop: {
    paddingTop: 5,
  },
  textLarger: {
    fontSize: 16,
  },
  itemsWrapper: {
    marginVertical: 20,
    padding: 20,
    borderRadius: 10,
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: Colors.lightGray,
  },
  paymentCont: {
    flexDirection: 'row',
    padding: 10,
    borderRadius: 10,
    alignItems: 'center',
  },
  iconStyle: {
    fontSize: 25,
    color: Colors.shades['500'],
    paddingRight: 5,
  },
  headerCard: {
    backgroundColor: Colors.secondaryGreen3,
    padding: 10,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: Colors.green,
    borderRadius: 10,
  },
  divider: {
    backgroundColor: Colors.secondaryGreen3,
    marginHorizontal: -10,
    padding: 10,
  },
  totalItem: {
    flexDirection: 'row',
    marginVertical: 5,
    justifyContent: 'space-between',
  },
  totalContainer: {
    marginHorizontal: 20,
    marginVertical: 10,
  },
  marginBottom: {
    marginBottom: 20,
  },
})
