import { Farm } from '@models/Farm'
import { arrayRemove, arrayUnion, collection, doc, getDoc, limit, where } from 'firebase/firestore'

import { consumerIndex } from '@/config/Algolia'
import { MenuItem } from '@/hooks/useAlgoliaFarmData/useAlgoliaFarmData-helpers'
import { getAvailableProductFacetFilters, loadProductsByFarm } from '@api/Products'
import { callEndpoint } from '@api/v2'
import { createAvailabilityFilter } from '@helpers/algolia-client'
import { defaultAroundRadius, getCoordString } from '@helpers/coordinate'
import { sortByName } from '@helpers/sorting'
import { Coordinate } from '@models/Coordinate'
import { Product } from '@models/Product'
import { EditLocalCategoryRequest, EditLocalCategoryResponse } from '@shared/types/v2/product'
import { ImageSource } from 'expo-image'
import { db } from './db'
import { farmsCollection, productsCollection, templateProductsCollection } from './framework/ClientCollections'

export type Category = {
  name: string
  isDefault: boolean
}

export type CategoryWithCount = Category & {
  count: number
}

/** This returns the default global categories only */
export async function loadDefaultCategories(): Promise<string[]> {
  const buyingOptions = await getDoc(doc(collection(db(), 'buying_options'), 'all_options'))
  return buyingOptions.get('categories')
}

/** This receives the farm-specific categories, and concatenates them with the global ones.
 * - Use this when you already have the farm data */
export async function loadFarmCategories(localCategories: Farm['localCategories']): Promise<Category[]> {
  const categories = await loadDefaultCategories()
  const formattedDefaultCategories = categories.map((category) => {
    return {
      name: category,
      isDefault: true,
    }
  })
  const formattedLocalCategories = localCategories?.map((category) => {
    return {
      name: category,
      isDefault: false,
    }
  })
  return formattedDefaultCategories.concat(...(formattedLocalCategories ?? [])).sort(sortByName)
}

/** fetches the entire farm categories only from the farm id.
 * - Use this when you don't have the farm data.
 */
export async function loadFarmCategoriesFromId(farmId: string) {
  const farm = await farmsCollection.fetch(farmId)
  return loadFarmCategories(farm.localCategories)
}

/** This receives the farm-specific custom category and add it to the farm firestore doc. */
export async function addLocalCategory(cat: string, currFarm: string): Promise<'already-exists' | 'added'> {
  const farm = await farmsCollection.fetch(currFarm)
  const currCatetories = (await loadFarmCategories(farm.localCategories)).map((category) => category.name)

  // block duplicates
  if (currCatetories.includes(cat)) {
    return Promise.resolve('already-exists')
  }

  await farmsCollection.update({
    id: currFarm,
    localCategories: arrayUnion(cat) as any,
  })

  return 'added'
}

/**
 * Count the number of products in each local category for a specific farm.
 * Each object contains a local category name as the key and the number of products in that category as the value.
 */
export async function listLocalCategoriesAndCountAssociatedProducts(
  farmId: string,
  localCategoriesFromFarm: Farm['localCategories'],
): Promise<Record<string, CategoryWithCount>[]> {
  const localCategories = await loadFarmCategories(localCategoriesFromFarm)
  const products = await loadProductsByFarm(farmId)

  const categoriesWithCount = countProductsPerCategory(localCategories, products)

  return Object.keys(categoriesWithCount).map((category) => ({
    [category]: {
      ...categoriesWithCount[category],
    },
  }))
}
/**
 * Delete a local category from a specific farm.
 * This function is useful for removing an existing category from a farm's categories.
 */
export async function deleteLocalCategoryFromFarm(farmId: string, categoryName: string): Promise<boolean> {
  try {
    // If there are no associated products, proceed with deletion
    await farmsCollection.update({
      id: farmId,
      localCategories: arrayRemove(categoryName) as any,
    })
    return true
  } catch (err) {
    return false
  }
}

/**
 * Edit a local category for a specific farm by updating the category name in-place.
 * This also updates the category name for all products and template products associated with the old category.
 */
export async function editLocalCategory(data: EditLocalCategoryRequest): Promise<EditLocalCategoryResponse> {
  return await callEndpoint('v2.Product.editLocalCategoryService', data)
}

/** Checks if at least one product or one shareTemplate exists with the given categoryName, on that farm */
export async function canDeleteLocalCategory(farmId: string, categoryName: string): Promise<boolean> {
  const prodsWithCat = await productsCollection.fetchAll(
    where('farm.id', '==', farmId),
    where('category', '==', categoryName),
    limit(1),
  )

  if (prodsWithCat.length > 0) return false

  const templatesWithCat = await templateProductsCollection.fetchAll(
    where('farm.id', '==', farmId),
    where('category', '==', categoryName),
    limit(1),
  )

  return templatesWithCat.length === 0
}

function countProductsPerCategory(localCategories: Category[], products: Product[]): Record<string, CategoryWithCount> {
  const categoryCounts: Record<string, CategoryWithCount> = {}

  localCategories.forEach((localCategory) => {
    // Initialize each local category's count at zero
    categoryCounts[localCategory.name] = {
      ...localCategory,
      count: 0,
    }
  })

  products.forEach((product) => {
    if (product.category && categoryCounts[product.category]) {
      categoryCounts[product.category].count++
    }
  })

  return categoryCounts
}

type CategoryImage = {
  /** The text that will show with the image */
  title: string
  /** URL of the image. This will be replaced in a future PR with image URLs so for now we hardcode the folder images */
  imageURL: ImageSource
  /** Values to search for, specific to the category */
  searchValues: string[]
}

/** Loads static categories from the db [WIP]*/
export async function loadCategoryImages() {
  return categories
}

const categories: CategoryImage[] = [
  {
    title: 'Apples',
    imageURL: require('../assets/images/home_wholesale/apples.jpeg'),
    searchValues: ['Apple'],
  },
  {
    title: 'Root Vegetables',
    imageURL: require('../assets/images/home_wholesale/root-vegetables.jpeg'),
    searchValues: [
      'Carrots',
      'Potatoes',
      'Beets',
      'Turnips',
      'Radish',
      'Parsnips',
      'Rutabagas',
      'Ginger',
      'Turmeric',
      'Yams',
      'Celeriac',
      'Jerusalem artichokes',
      'Cassava',
      'Taro',
      'Onions',
      'Garlic',
      'Kohlrabi',
    ],
  },
  {
    title: 'Local Meat',
    imageURL: require('../assets/images/home_wholesale/meat.jpeg'),
    searchValues: ['Beef', 'Steak', 'Chicken', 'Pork', 'Veal', 'Duck', 'Hen', 'Lamb', 'Fish', 'Steelhead'],
  },
  {
    title: 'Greens',
    imageURL: require('../assets/images/home_wholesale/greens.jpeg'),
    searchValues: ['Kale', 'Chard', 'Lettuce', 'Spinach', 'Arugula', 'Salad mix', 'Microgreens', 'Beet', 'Greens'],
  },
  {
    title: 'Vegetables',
    imageURL: require('../assets/images/home_wholesale/vegetables.jpeg'),
    searchValues: ['Vegetables'],
  },
  {
    title: 'Eggs & Dairy',
    imageURL: require('../assets/images/home_wholesale/eggs.jpeg'),
    searchValues: ['Eggs', 'Milk', 'Cheese', 'Dairy'],
  },
  {
    title: 'Local Grains',
    imageURL: require('../assets/images/home_wholesale/grains.jpeg'),
    searchValues: ['Grains', 'Flour', 'Wheat', 'Rye', 'Rice', 'Millet', 'Buckwheat', 'Groats', 'Amaranth', 'Eikhorn'],
  },
  {
    title: 'Cheese',
    imageURL: require('../assets/images/home_wholesale/cheese.jpeg'),
    searchValues: ['Cheese'],
  },
]

/**
 * Searches for product categories by geographical location
 */
const searchCategoriesByGeoLocation = async (coords: Coordinate, facetFilters: string[]): Promise<MenuItem[]> => {
  const result = await consumerIndex.searchForFacetValues('category', '', {
    facetFilters,
    aroundLatLng: getCoordString(coords),
    aroundRadius: defaultAroundRadius,
    numericFilters: createAvailabilityFilter(),
    facetingAfterDistinct: true,
  })

  return result.facetHits.map((el) => ({ ...el, isRefined: false, label: el.value }))
}

/** Searches for global categories based on the provided facet filters.*/
const searchGlobalCategories = async (facetFilters: string[]) => {
  const result = await consumerIndex.searchForFacetValues('category', '', {
    facetFilters,
    facetingAfterDistinct: true,
    numericFilters: createAvailabilityFilter(),
  })

  return result.facetHits.map((el) => ({ ...el, isRefined: false, label: el.value }))
}

/**
 * Searches for popular categories for current available products, globally
 */
export async function loadPopularCategories(): Promise<MenuItem[]> {
  const facetFilters = getAvailableProductFacetFilters(true)

  return searchGlobalCategories(facetFilters)
}

/**
 * Merges two arrays of items with counts by adding the counts of matching items.
 * Items are matched based on their 'value' property.
 */
export const mergeFacetHits = (arr1: MenuItem[], arr2: MenuItem[]): MenuItem[] => {
  if (!arr1.length) return arr2
  if (!arr2.length) return arr1

  const result = [...arr1]

  arr2.forEach((secondaryItem) => {
    const existingIndex = result.findIndex((r) => r.value === secondaryItem.value)
    if (existingIndex >= 0) {
      result[existingIndex] = {
        ...result[existingIndex],
        count: result[existingIndex].count + secondaryItem.count,
      }
    } else {
      result.push(secondaryItem)
    }
  })

  result.sort((a, b) => b.count - a.count)
  return result
}
