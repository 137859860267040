import { useSizeFnStyles } from '@/hooks/useFnStyles'
import useKeyedState from '@/hooks/useKeyedState'
import { Button, FormInputLabel, Picker, hideModal } from '@elements'
import { openPackListLabelPDF } from '@helpers/links'
import { values } from '@helpers/typescript'
import { DateRange, SummaryFilter } from '@models/Schedule'
import { PickerItemProps } from '@react-native-picker/picker'
import { ItemsCount, LabelSize, LabelType } from '@shared/types/v2/label'
import { memo, useCallback, useMemo } from 'react'
import { View } from 'react-native'

type ChooseLabelModalProps = {
  farmId: string
  dateRange: DateRange
  filters: SummaryFilter
}

type StateType = {
  itemsPerLabel: string
  labelSize: LabelSize
  type: LabelType
}

const labelsSizeOptions: PickerItemProps<LabelSize>[] = Object.values(LabelSize).map((val) => ({
  label: val,
  value: val,
}))

/** This Modal offers multiple options for label generation*/
export const ChooseLabelModal = memo(function ChooseLabelModal({ farmId, dateRange, filters }: ChooseLabelModalProps) {
  const [{ itemsPerLabel, labelSize, type }, set] = useKeyedState<StateType>({
    itemsPerLabel: ItemsCount.TEN,
    labelSize: LabelSize.FOURxSIX,
    type: LabelType.ROLL,
  })

  const labelTypeOptions = useMemo<PickerItemProps<LabelType>[]>(() => {
    const items = values(LabelType).map((val) => ({
      label: val,
      // We only want to distinguish between CONTINUOUS_ROLL and ROLL in the UI not in the code
      value: val === LabelType.CONTINUOUS_ROLL ? LabelType.ROLL : val,
    }))

    set('type', LabelType.ROLL)

    if (labelSize === LabelSize.TWO_FOURxTHREE) {
      return items.filter((item) => item.label === LabelType.CONTINUOUS_ROLL)
    }

    return items.filter((item) => item.label !== LabelType.CONTINUOUS_ROLL)
  }, [labelSize, set])

  const itemsPerLabelOptions = useMemo<PickerItemProps<string>[]>(() => {
    let items = values(ItemsCount).map((val) => ({
      label: val,
      value: val,
    }))

    // we should only allow a max of 7 if it isn't FOURxSIX
    if (labelSize !== LabelSize.FOURxSIX) {
      items = items.slice(0, 7)

      if (parseInt(itemsPerLabel, 10) > 7) {
        set('itemsPerLabel', ItemsCount.SEVEN)
      }
    }

    return items
  }, [itemsPerLabel, labelSize, set])

  const styles = useStyles()

  /** exportPDF will build required extra queryParams and call the server to build chosen PDF format */
  const exportPDF = useCallback(() => {
    const extraQueryString = `itemsPerLabel=${itemsPerLabel}&labelSize=${labelSize.replaceAll('"', '')}&type=${type}`
    openPackListLabelPDF(farmId, dateRange, filters, extraQueryString)
    hideModal()
  }, [farmId, dateRange, filters, itemsPerLabel, labelSize, type])

  return (
    <View style={styles.container}>
      <View style={styles.pickerGroup}>
        <View>
          <FormInputLabel label="Label size" />
          <Picker
            style={styles.picker}
            value={labelSize}
            placeholder={null}
            items={labelsSizeOptions}
            onValueChange={(val) => set('labelSize', val as LabelSize)}
          />
        </View>

        <View>
          <FormInputLabel label="Page type" />
          <Picker
            style={styles.picker}
            value={type}
            placeholder={null}
            items={labelTypeOptions}
            onValueChange={(val) => set('type', val as LabelType)}
          />
        </View>

        <View>
          <FormInputLabel label="Items per label" />
          <Picker
            style={styles.picker}
            value={itemsPerLabel}
            placeholder={null}
            items={itemsPerLabelOptions}
            onValueChange={(val) => set('itemsPerLabel', val)}
          />
        </View>
      </View>

      <Button title="Submit" onPress={exportPDF} style={styles.button} />
    </View>
  )
})

const useStyles = () =>
  useSizeFnStyles(({ isSmallDevice }) => ({
    container: {
      marginHorizontal: 20,
      marginBottom: 20,
    },
    pickerGroup: {
      flexDirection: isSmallDevice ? 'column' : 'row',
      gap: 20,
    },
    picker: {
      marginTop: 8,
    },
    button: {
      marginTop: 30,
      marginHorizontal: 0,
      alignSelf: isSmallDevice ? 'stretch' : 'flex-end',
    },
  }))
