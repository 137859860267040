import { Logger } from '@/config/logger'
import { addFortisPaymentMethod, loadFortisClientId } from '@api/Payments'
import { Alert, Button, CheckBox, Divider, ErrorText, LoadingView } from '@elements'
import { errorToString } from '@helpers/helpers'
import { PaymentForms, PaymentMethod } from '@models/PaymentMethod'
import { DataError } from '@shared/Errors'
import { CreateFortisPaymentMethodRequest } from '@shared/types/v2/fortis'
import { useCallback, useRef, useState } from 'react'
import { useApiFx } from '../../../hooks/useApiFx'
import { FortisElements } from './fortis/FortisElements'
import { FortisClientInterface } from './fortis/helpers/types'
import { globalStyles } from '../../../constants/Styles'

//TODO: - Needs more testing (iOS needs to figure out sizing of form)

type AddFortisPaymentProps = {
  userId: string
  farmId: string
  /** Will be called either on create or on abort */
  onDismiss: (newPayment?: PaymentMethod) => void
  /** The type of payment method to accept */
  paymentMethod: PaymentForms.BANK | PaymentForms.CARD
}

/** Modal to add a new Fortis payment method */
export function AddFortisPayment({ userId, farmId, onDismiss, paymentMethod }: AddFortisPaymentProps) {
  const clientIdApi = useApiFx(loadFortisClientId, [userId, farmId, paymentMethod])

  return (
    <LoadingView loading={clientIdApi.loading} success={clientIdApi.data} error={clientIdApi.err}>
      {(clientId) => (
        <>
          <FortisElementsWrapper
            userId={userId}
            clientId={clientId}
            paymentMethod={paymentMethod}
            onDismiss={onDismiss}
          />
        </>
      )}
    </LoadingView>
  )
}

type FortisElementsWrapperProps = {
  userId: string
  clientId: string
  paymentMethod: PaymentForms.BANK | PaymentForms.CARD
  onDismiss: (newPayment?: PaymentMethod) => void
}

/** Wrapper for the FortisElements component. Need to do this to avoid duplication of the onSubmit logic in web and mobile versions of the component. */
function FortisElementsWrapper({ userId, clientId, paymentMethod, onDismiss }: FortisElementsWrapperProps) {
  const fortisRef = useRef<FortisClientInterface>()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)
  const [updateFuture, setUpdateFuture] = useState(true)

  const onSubmitHandler = useCallback(async () => {
    if (!fortisRef.current) {
      return setError('Unable to load payment method form, please refresh the page and try again.')
    }
    try {
      setLoading(true)
      // Submit the form
      const res = await fortisRef.current.submit()

      if (res.success) {
        const getRequest = (): CreateFortisPaymentMethodRequest => {
          switch (res.data.payment_method) {
            case 'cc':
              return {
                userId,
                updateFuture,
                type: PaymentForms.CARD,
                id: res.data.saved_account.id,
                card_type: res.data.saved_account.account_type,
                last4: res.data.saved_account.last_four,
                exp_date: res.data.saved_account.exp_date,
              }
            case 'ach':
              return {
                userId,
                updateFuture,
                type: PaymentForms.BANK,
                id: res.data.id,
                last4: res.data.last_four,
                account_type: res.data.account_type,
              }
            default: {
              const err = new DataError('Unrecognized payment method', { response: res })
              Logger.error(err)
              throw err
            }
          }
        }

        // Save the payment method to Firestore and call onDismiss to return
        const payMethod = await addFortisPaymentMethod(getRequest())
        onDismiss(payMethod)
      } else {
        // If the result failed then show the error message
        setError(res.error)
      }
      // The result will be handled by the paymentFinished event
    } catch (e) {
      const paymentMethodString = paymentMethod === PaymentForms.CARD ? 'card' : 'bank account'
      Alert(
        `Error adding ${paymentMethodString}`,
        `There was an error adding your ${paymentMethodString}: ${errorToString(e)}`,
      )
      // Handle error
    } finally {
      setLoading(false)
    }
  }, [userId, paymentMethod, onDismiss, updateFuture])

  return (
    <>
      <FortisElements clientId={clientId} fortisRef={fortisRef} onFinishLoading={() => setLoading(false)} />
      <CheckBox
        style={globalStyles.marginHorizontal10}
        checked={updateFuture}
        onChange={setUpdateFuture}
        title="Update all future invoices to this payment"
      />
      <ErrorText>{error}</ErrorText>
      <Button
        loading={loading}
        title={paymentMethod === PaymentForms.CARD ? 'Add Card' : 'Add Bank Account'}
        onPress={onSubmitHandler}
      />
      <Divider clear bottom={20} />
    </>
  )
}
