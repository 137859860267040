import Colors from '@/constants/Colors'
import { useDebouncedValue } from '@/hooks/useDebounce'
import { useFocusFx } from '@/hooks/useFocusFx'
import { ShoppingStackParamList } from '@/navigation/types'
import { SearchInputBar } from '@elements'
import { Farm } from '@models/Farm'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { memo, useState } from 'react'
import { StyleSheet } from 'react-native'

type Props = {
  farmName: Farm['name']
  initialQuery: string
}

/** Search bar component that syncs the input value with the URL params with a certain debounce */
export const ProductTermFilter = memo(function ProductTermFilter({ initialQuery, farmName }: Props) {
  const navigation = useNavigation<StackNavigationProp<ShoppingStackParamList, 'FarmShop'>>()
  const {
    params: { searchTerm },
  } = useRoute<RouteProp<ShoppingStackParamList, 'FarmShop'>>()

  const [inputValue, setInputValue] = useState(initialQuery)
  const debouncedValue = useDebouncedValue(inputValue, 500)

  useFocusFx(
    () => {
      // Reset the input when navigation param is cleared (i.e. Reset Filters btn is pressed)
      if (searchTerm === undefined) {
        setInputValue('')
      }
    },
    [searchTerm],
    { noRefocus: true },
  )

  useFocusFx(() => {
    navigation.setParams({ searchTerm: debouncedValue.length ? debouncedValue : undefined })
  }, [debouncedValue, navigation])

  return (
    <SearchInputBar
      placeholder={`Search ${farmName}`}
      contStyle={styles.searchCont}
      value={inputValue}
      onChangeText={setInputValue}
      inputMode="search"
      numberOfLines={1}
      clear={inputValue ? () => setInputValue('') : undefined}
      placeholderTextColor={Colors.shades['300']}
    />
  )
})

const styles = StyleSheet.create({
  searchCont: {
    height: 40,
  },
})
