import { addCoupon, archiveCoupon, setCoupon } from '@api/Coupons'
import {
  Alert,
  ButtonClear,
  CheckBox,
  Divider,
  ErrorText,
  FormButton,
  FormInput,
  FormMoneyInput,
  Icon,
  KeyboardAvoidingScrollView,
  TextH2,
  Toast,
  hideModal,
} from '@elements'
import { YUP_MONEY_REQUIRED, YUP_WHOLE_NUMBER_REAL } from '@helpers/Yup'
import { errorToString } from '@helpers/helpers'
import { Coupon, CouponType, isFixedCoupon, isPercentCoupon } from '@models/Coupon'
import { Formik, useFormikContext } from 'formik'
import * as React from 'react'
import { useCallback, useState } from 'react'
import { StyleSheet, View } from 'react-native'

import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import { couponBuilder } from '@helpers/builders'
import DecimalCalc from '@helpers/decimal'
import { isMoney, makeMoney } from '@helpers/money'
import { PartialPick } from '@helpers/typescript'
import { CurrencyCode, MoneyWithCurrency } from '@models/Money'
import { isErrorWithCode } from '@shared/Errors'
import { Logger } from '../../../config/logger'
import Colors from '../../../constants/Colors'
import { globalStyles } from '../../../constants/Styles'
import { adminCurrencySelector, adminFarmIdSelector } from '../../../redux/selectors'
import { RadioButton } from '../Schedules/components/RadioButton'
import { CSAGroupSelection } from './components/CSAGroupSelection'
import { CategoriesSelection } from './components/CategoriesSelection'
import { ProducersSelection } from './components/ProducersSelection'

export type CouponFormType = Omit<Coupon, 'id' | 'farm' | 'timesRedeemed' | 'archived' | 'value'> & {
  /** In the form, the value will be either a Money object, or a string representation of a decimal percentage from 0-100%, with Zero being invalid. (Valid value examples: '0.001', '1', '99.99', '100')
   * - On submit, it must be converted from a percentage string to a decimal (I.e. '50' to 0.5)
   * - The field type in this form MUST be string because we need to have a free-hand input where the user can enter whatever they want. But the string will be validated to make sure it's a valid number.
   * */
  value: string | MoneyWithCurrency
}

/** Generates the value for form's coupon value field
 * @param value the current value in the coupon or form
 * @param type the coupon's type
 * @param isFormData whether the current value comes from the form, or from an existing Coupon object. If this is called with the value from the form, this should be true. If this is called with the value directly from a db Coupon, it should be false.
 */
function getCouponValue_formType(
  value: CouponFormType['value'] | Coupon['value'] | undefined,
  type: CouponFormType['type'],
  isFormData: boolean,
  currency: CurrencyCode,
): CouponFormType['value'] {
  if (!value) return ''

  if (isPercentCoupon({ type })) {
    // must return a string for the form input
    if (isMoney(value)) {
      // This would be incompatible and should never happen
      return ''
    }
    if (isFormData) {
      // must not multiply by 100, because it's already expected to be a percentage in the form
      return typeof value === 'number' ? value.toString() : value
    } else {
      // must convert from decimal to percentage, because it's coming from the DB, so it's a decimal
      const parsed = typeof value === 'string' ? parseFloat(value) : value
      return (parsed * 100).toString()
    }
  } else if (isFixedCoupon({ type })) {
    // must return Money
    return isMoney(value) ? value : makeMoney(0, currency)
  } else {
    throw new Error('Something went wrong while generating form data')
  }
}

/** Generates the initial form data for the coupon form.
 * @param coupon Is the coupon to be edited by the form. If this is a new coupon, it will be undefined.
 */
function getInitialFormValues(
  coupon: PartialPick<CouponFormType, 'type'> | PartialPick<Coupon, 'type'> | undefined,
  opts: {
    /** If true, this will interpret the coupon input as a coupon of the form type. Not a coupon of the Coupon type. When the form values are being re-created, this should be true. When the form values are being initialized for the first time from an existing coupon, it should be false. */
    isFormData: boolean
    currency: CurrencyCode
  },
): CouponFormType {
  if (!coupon) {
    return {
      name: '',
      type: CouponType.Percent,
      // For a blank form, initial value in context will be blank, which is an invalid value in the form schema. However,formik will be configured to not show errors on the initial values by using the "validateOnMount" prop
      value: '',
      ebtOnly: false,
      categories: [],
      producers: [],
      isSystemCoupon: false,
    }
  }

  return {
    name: coupon.name || '',
    type: coupon.type,
    value: getCouponValue_formType(coupon.value, coupon.type, opts?.isFormData, opts?.currency),
    ebtOnly: coupon.ebtOnly ?? false,
    categories: coupon.categories ?? [],
    producers: coupon.producers ?? [],
    csaGroups: coupon.csaGroups ?? [],
    isSystemCoupon: coupon.isSystemCoupon ?? false,
  }
}

export const couponFormSchema: Yup.ObjectSchema<CouponFormType> = Yup.object().shape({
  name: Yup.string().trim().label('Name').required(),
  type: Yup.mixed<CouponType>().label('Coupon Type').required().oneOf([CouponType.Fixed, CouponType.Percent]),
  // The value in the form schema differs from the Coupon model.
  // In the form the value is a string representation of a percentage (from '0' to '100')
  // In the DB it is a decimal number (from 0 to 1)
  value: Yup.mixed<string | MoneyWithCurrency>()
    .required()
    .when('type', {
      is: CouponType.Percent,
      // Here we're using a number schema although the form has the value as a string. That's OK and it is supported by the number schema.
      then: () => YUP_WHOLE_NUMBER_REAL('Value', { allowDecimal: true }).max(100, 'Cannot be greater than 100%'),
      otherwise: () => YUP_MONEY_REQUIRED('Value', { requireCurrency: true }),
    }),
  ebtOnly: Yup.boolean().label('EBT Only'),
  categories: Yup.array().of(Yup.string().required()),
  producers: Yup.array().of(Yup.string().required()),
  csaGroups: Yup.array().of(Yup.string().required()),
  isSystemCoupon: Yup.boolean().optional(),
})

type AddEditCouponProps = {
  coupon?: Coupon
  onAdded?: (coupon: Coupon) => void
  onUpdate?: (coupon: Coupon) => void
}

/** Modal component to add or edit a coupon */
export function AddEditCoupon({ coupon, onAdded, onUpdate }: AddEditCouponProps) {
  const [error, setError] = useState('')
  const farmId = useSelector(adminFarmIdSelector)
  const currency = useSelector(adminCurrencySelector)
  const isEdit = !!coupon?.id

  const onSubmitHandler = useCallback(
    async (values: CouponFormType) => {
      if (!farmId) return setError('Could not load your farm, please reload and try again.')
      setError('')

      try {
        // Get the coupon value from the form state. This value could be assumed to be correct because this should only run if the schema validation passed
        // DO NOT pass the entire values object into `isPercentCoupon(values)` because it will tell typescript this is a coupon. It is not. It is an object of the form type.
        const value = isPercentCoupon({ type: values.type })
          ? // Convert percentage to decimal by dividing over 100. Must parse the string into a number before dividing.
            DecimalCalc.divide(parseFloat(values.value as string), 100)
          : isFixedCoupon({ type: values.type })
          ? // Keep the value intact which is expected to be a Money object
            (values.value as MoneyWithCurrency)
          : // undefined would not pass the builder validation
            undefined

        const partialCoupon: Partial<Coupon> = {
          ...values,
          value,
          id: coupon?.id,
          farm: {
            id: farmId,
          },
          timesRedeemed: coupon?.timesRedeemed ?? 0,
          archived: coupon?.archived ?? false,
        }

        if (isEdit) {
          const newCoupon = couponBuilder.validate(partialCoupon)
          await setCoupon(newCoupon)
          Toast('This coupon has been updated successfully')
          onUpdate?.(newCoupon)
        } else {
          // adding a new coupon
          const addedCoupon = await addCoupon(partialCoupon)
          Toast('This coupon has been added successfully')
          onAdded?.(addedCoupon)
        }
        hideModal()
      } catch (e) {
        if (!isErrorWithCode(e)) {
          Logger.error(e)
        }

        setError(`Unable to add coupon: ${errorToString(e)} `)
      }
    },
    [coupon, farmId, onAdded, onUpdate, isEdit],
  )

  return (
    <Formik
      initialValues={getInitialFormValues(coupon, { isFormData: false, currency })}
      onSubmit={onSubmitHandler}
      validationSchema={couponFormSchema}
      validateOnMount={false}
      initialTouched={false}
    >
      <FormUI error={error} setError={setError} coupon={coupon} onAdded={onAdded} onUpdate={onUpdate} />
    </Formik>
  )
}

type UIProps = AddEditCouponProps & { error: string; setError(error: string): void }

function FormUI({ coupon, onUpdate, error, setError }: UIProps) {
  const [isArchiving, setIsArchiving] = useState(false)
  const isEdit = !!coupon?.id

  const {
    handleChange,
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    setErrors,
    setTouched,
    setValues,
  } = useFormikContext<CouponFormType>()

  const onPressArchive = async () => {
    if (!coupon)
      return Alert('Unable to archive coupon', 'This coupon does not exist or failed to load, please try again.')

    try {
      setIsArchiving(true)
      await archiveCoupon(coupon)
      onUpdate?.({ ...coupon, archived: true })
      setIsArchiving(false)
      Toast('This coupon has been archived')
      hideModal()
    } catch (e) {
      Logger.warn(e)
      setError(`Unable to archive coupon: ${errorToString(e)} `)
      setIsArchiving(false)
    }
  }

  /** Will change the coupon type and rebuild the form values */
  const changeCouponType = (newType: CouponType) => {
    // We cannot change the coupon type after it has been created
    if (isEdit) return

    // Form values must be recreated when the type changes, because some fields have types that differ between the kinds of coupons
    const newFormValues: CouponFormType = getInitialFormValues(
      { ...values, type: newType },
      { isFormData: true, currency },
    )
    setValues(newFormValues)
    setTouched({})
    setErrors({})
  }

  const currency = useSelector(adminCurrencySelector)

  return (
    <KeyboardAvoidingScrollView contentContainerStyle={styles.container}>
      <FormInput
        onChangeText={handleChange('name')}
        onBlur={handleBlur('name')}
        value={values.name}
        placeholder="Coupon Name"
        errorMessage={touched.name ? errors.name : ''}
      />
      <TextH2>Coupon Type</TextH2>
      {isEdit && <ErrorText>Coupon type and amount cannot be changed after it is created.</ErrorText>}
      <RadioButton
        value="Percentage discount"
        isSelected={values.type === CouponType.Percent}
        disabled={isEdit}
        onSelect={() => changeCouponType(CouponType.Percent)}
      />
      <RadioButton
        value="Fixed amount discount"
        isSelected={values.type === CouponType.Fixed}
        disabled={isEdit}
        onSelect={() => changeCouponType(CouponType.Fixed)}
      />
      <Divider clear />
      {isPercentCoupon({ type: values.type }) ? (
        <FormInput
          disabled={isEdit}
          placeholder="20"
          keyboardType="decimal-pad"
          label="Percentage off"
          value={typeof values.value !== 'string' ? '' : values.value}
          onChangeText={handleChange('value')}
          onBlur={handleBlur('value')}
          rightIcon={<Icon name="percent" />}
          errorMessage={touched.value ? errors.value : ''}
        />
      ) : isFixedCoupon({ type: values.type }) ? (
        <FormMoneyInput
          disabled={isEdit}
          label="Discount amount"
          maxLength={11}
          value={!isMoney(values.value) ? undefined : values.value}
          onChangeText={(val) => setFieldValue('value', val)}
          onBlur={handleBlur('value')}
          errorMessage={touched.value ? errors.value : ''}
          currency={currency}
        />
      ) : null}
      {isPercentCoupon({ type: values.type }) && (
        <CheckBox
          style={styles.inputPadding}
          checked={!!values.ebtOnly}
          onChange={(val) => setFieldValue('ebtOnly', val)}
          title="Only apply this discount to items purchased with EBT"
        />
      )}
      <CategoriesSelection />
      <ProducersSelection />
      <CSAGroupSelection />
      <View style={globalStyles.flexRowCenter}>
        {isEdit && (
          <ButtonClear
            disabled={isSubmitting}
            loading={isArchiving}
            style={globalStyles.flex1}
            title="Archive Coupon"
            color={Colors.red}
            onPress={onPressArchive}
          />
        )}
        <FormButton
          loading={isSubmitting}
          disabled={isArchiving}
          style={globalStyles.flex1}
          title="Save Coupon"
          onPress={handleSubmit}
        />
      </View>

      {!!error && <ErrorText>{error}</ErrorText>}
    </KeyboardAvoidingScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 20,
  },
  maskedInput: {
    fontSize: 20,
    margin: 10,
    paddingBottom: 5,
    borderBottomWidth: 1,
    borderBottomColor: Colors.shades['300'],
  },
  error: { color: Colors.red, marginBottom: 5 },
  inputPadding: {
    marginVertical: 10,
  },
})
