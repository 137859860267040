import { PaymentForms, PaymentSources, StripeAchPaymentMethod, StripeCardPaymentMethod } from '@models/PaymentMethod'
import { PaymentMethod, Stripe } from '@stripe/stripe-js'

/** Will build a new Stripe bank account payment method and confirm the setup intent */
export const addNewStripeBankAccount = async (
  stripe: Stripe,
  pmt: PaymentMethod,
  client_secret: string,
): Promise<StripeAchPaymentMethod | undefined> => {
  if (!pmt.us_bank_account) throw new Error('Invalid bank account, please contact support')
  const bank: StripeAchPaymentMethod = {
    source: PaymentSources.STRIPE_ACH,
    id: pmt.id,
    type: PaymentForms.BANK,
    isDefault: true,
    token: pmt.id,
    bank_name: pmt.us_bank_account.bank_name,
    last4: pmt.us_bank_account.last4,
    account_type: pmt.us_bank_account.account_type,
  }

  const res = await stripe.confirmUsBankAccountSetup(client_secret)
  if (res.error) throw res.error

  // If the setup intent is not successful, return undefined as the payment method was not added
  if (res.setupIntent.status !== 'succeeded') return undefined

  return bank
}

/** Will build a new Stripe credit card payment method and confirm the setup intent */
export const addNewStripeCreditCard = async (
  stripe: Stripe,
  pmt: PaymentMethod,
  client_secret: string,
): Promise<StripeCardPaymentMethod> => {
  if (!pmt.card) throw new Error('Invalid credit card, please contact support')
  const card: StripeCardPaymentMethod = {
    source: PaymentSources.STRIPE,
    id: pmt.id,
    type: PaymentForms.CARD,
    isDefault: true,
    token: pmt.id,
    card_type: pmt.card.brand,
    last4: pmt.card.last4,
    expiration: {
      month: pmt.card.exp_month,
      year: pmt.card.exp_year,
    },
  }

  const res = await stripe.confirmCardSetup(client_secret)
  if (res.error) throw res.error

  return card
}
