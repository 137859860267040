import { Spinner } from '@elements'
import { loadScript } from '@helpers/client'
import { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { FortisElementsProps } from './FortisElements'
import { FortisClientWrapper } from './helpers/FortisClientWrapper'
import { FORTIS_SCRIPT_URL } from './helpers/constants'

/** Will render the web version of the Fortis payment elements for entering payment information. */
export function FortisElements(props: FortisElementsProps) {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    loadScript(
      FORTIS_SCRIPT_URL,
      async () => {
        props.fortisRef.current = new FortisClientWrapper(props.clientId)
        await props.fortisRef.current.initialize()
        setLoading(false)
        props.onFinishLoading()
      },
      'fortis-elements',
    )
    // We only want this to load the script the first time this component is mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <View>
      {loading && <Spinner style={styles.spinner} />}
      <div id="fortis-payment" />
    </View>
  )
}

const styles = StyleSheet.create({
  spinner: {
    height: 100,
  },
})
