import { changeInvoicePaymentMethod } from '@api/Invoices'
import { listPaymentMethods, loadStripeFarmBalance } from '@api/Payments'
import { Alert, Button, CheckBox, ErrorText, FormPickerInput, hideModal, LoadingView, Text } from '@elements'
import { MoneyCalc } from '@helpers/money'
import { Invoice } from '@models/Invoice'
import { Money } from '@models/Money'
import { SplitTenderPayment } from '@models/Order'
import { isCreditPayment, isEbtPayment, PaymentMethod, pmt_FarmCredit } from '@models/PaymentMethod'
import { Formik, FormikProps } from 'formik'
import * as React from 'react'
import { useCallback, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import * as Yup from 'yup'

import { Logger } from '../../../../../config/logger'
import { useApiFx } from '../../../../../hooks/useApiFx'
import { PmtDef } from '@shared/helpers/PaymentDefinitions'
import { isInfinitePayment } from '@helpers/paymentMethods'

type FormType = {
  // The id of the payment method that has been selected
  paymentId?: string

  // Whether to update all future invoices for the user
  updateFuture: boolean
}

function formatPmt(pmt: PaymentMethod) {
  const formattedPmt = PmtDef(pmt).formatInvPayMethod(pmt)

  if (isCreditPayment(pmt)) {
    return `${pmt.holder_name}, ${formattedPmt} ${pmt.expiration?.month}/${pmt.expiration?.year}`
  }

  return formattedPmt
}

// Will load payments and include farm credit if it covers the entire invoice
const loadAllPayments = async (userId: string, farmId: string, invoiceAmt: Money) => {
  const [pmtMethods, farmCredit] = await Promise.all([
    listPaymentMethods(userId),
    loadStripeFarmBalance(userId, farmId),
  ])
  if (MoneyCalc.isGTE(farmCredit.balance, invoiceAmt)) {
    pmtMethods.push(pmt_FarmCredit)
  }
  return pmtMethods
}

/** Modal only for GrownBy Admin to switch a customers payment method for an invoice. */
export function ChangePayments({ invoice }: { invoice: Invoice }) {
  const [loading, setLoading] = useState(false)
  const payments = useApiFx(loadAllPayments, [invoice.user.id, invoice.farm.id, invoice.amountTotal])

  const validationSchema = Yup.object<FormType>().shape({
    paymentId: Yup.string().required('You must specify a payment method.'),
    updateFuture: Yup.boolean().required(),
  })

  const onSubmitHandler = async (values: FormType) => {
    setLoading(true)

    // Assign the new split tender payment configuration
    const pmt_card = payments.data?.find((pmt) => pmt.id === values.paymentId)
    if (!pmt_card) return Alert('Error saving payment', 'You must specify a payment method to update this invoice')
    const splitTender: SplitTenderPayment = [{ paymentMethod: pmt_card }]

    try {
      await changeInvoicePaymentMethod(invoice.id, invoice.user.id, splitTender, values.updateFuture)
      setLoading(false)
      hideModal()
      Alert('Success!', 'You have changed the payment method for this invoice. The changes will show up shortly.')
    } catch (e) {
      Logger.error(e)
      setLoading(false)
      hideModal()
      Alert(
        'Failed',
        'Could not change invoice payments, please try again later or contact support if this issue persists.',
      )
    }
  }
  // Will determine if the provided payment is valid as an installment payment option
  const canApplyToFuture = useCallback(
    (paymentId?: string): boolean => {
      if (!paymentId) return false
      const source = payments.data!.find((pmt) => pmt.id === paymentId)?.source
      return !!source && isInfinitePayment(source)
    },
    [payments.data],
  )

  const formOpts =
    payments.data
      ?.filter((pmt) => !isEbtPayment(pmt))
      .map((pmt) => ({
        label: formatPmt(pmt),
        value: pmt.id,
      })) ?? []

  return (
    <Formik
      initialValues={{
        updateFuture: false,
      }}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      {({ values, errors, touched, handleSubmit, setFieldValue }: FormikProps<FormType>) => (
        <View style={styles.container}>
          <Text>
            Right now this is limited to single payments. Farm credit will only show when it completely covers the
            payment total.
          </Text>
          <LoadingView loading={payments.loading}>
            <FormPickerInput
              items={formOpts}
              placeholder="Select a payment method"
              value={values.paymentId}
              onValueChange={(val) => {
                setFieldValue('paymentId', val)
                if (!canApplyToFuture(val)) {
                  setFieldValue('updateFuture', false)
                }
              }}
              errorMessage={touched.paymentId ? errors.paymentId : ''}
            />
          </LoadingView>
          {!!values.paymentId && (
            <CheckBox
              disabled={!canApplyToFuture(values.paymentId)}
              checked={values.updateFuture}
              onChange={(val) => setFieldValue('updateFuture', val)}
              title="Update all future invoices to this payment"
            />
          )}
          {!!values.paymentId && !canApplyToFuture(values.paymentId) && (
            <ErrorText>You can only update future payments to be credit card, ACH, or handled offline</ErrorText>
          )}

          <Button loading={loading} title="Submit" onPress={() => handleSubmit()} />
        </View>
      )}
    </Formik>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 10,
  },
})
