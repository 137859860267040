import { formatAddressCityState } from '@helpers/display'
import { hyperlinkOpenUrl } from '@helpers/links'
import { Farm } from '@models/Farm'
import { StyleSheet, View } from 'react-native'
import HyperLink from 'react-native-hyperlink'

import { FarmBadges, Image, Rating } from '@components'
import { ButtonClear } from '../../components/elements/ButtonClear'
import { Icon } from '../../components/elements/Icon/Icon'
import ReadMore from '../../components/elements/ReadMore'
import { HeaderText, Text } from '../../components/elements/Text'
import Colors from '../../constants/Colors'
import { isSmallDevice } from '../../constants/Layout'
import { globalStyles } from '../../constants/Styles'

export default function FarmSnippet({ farm }: { farm?: Farm }) {
  if (!farm) return null
  if (isSmallDevice()) return null
  return (
    <View>
      <View style={styles.farmHeader}>
        <Image type="logo" style={styles.farmLogo} source={{ uri: farm.logo }} />
        <View style={globalStyles.flex1}>
          <HeaderText numberOfLines={1}>{farm.name}</HeaderText>
          <ButtonClear
            url={`/farms/${farm.id}/shop`}
            title="View all products"
            textStyle={{ fontSize: 14 }}
            style={{ paddingHorizontal: 0 }}
          />
        </View>
      </View>
      <View style={styles.farmInfoList}>
        <View style={styles.address}>
          <Icon name="map-marker-alt" color={Colors.shades['600']} size={25} style={{ marginRight: 5 }} />
          <Text>{formatAddressCityState(farm.address)}</Text>
        </View>
        <Rating ratings={farm.reviews} />
      </View>
      <HyperLink linkStyle={{ color: Colors.blueLink }} onPress={hyperlinkOpenUrl}>
        <ReadMore>{farm.about}</ReadMore>
      </HyperLink>
      <FarmBadges practices={farm.practices} />
    </View>
  )
}

const styles = StyleSheet.create({
  farmInfoList: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxWidth: 400,
  },
  address: {
    flexDirection: 'row',
    marginVertical: 20,
    alignItems: 'flex-end',
  },
  farmHeader: {
    marginVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  farmLogo: {
    width: 60,
    height: 60,
    marginRight: 15,
    borderRadius: 30,
  },
})
