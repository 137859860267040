import { DigitalProduct, Standard, Unit } from '@models/Product'
import { memo, useContext } from 'react'

import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { AddBtnStandard_Reusable } from '../../../OrderCreatorScreen/components/AddCartButtons/StandardBtn_Reusable'
import { OrderEditContext } from '../../useOrderEditData'

export type AddBtnStandardProps = {
  prod: Standard | DigitalProduct
  unit: Unit
}

export const AddBtnStandard = memo(function AddBtnStandard({ prod, unit }: AddBtnStandardProps) {
  const {
    draftOrderFx: { data: draft },
    keyedStateProds: [{ schedule, pickupDate }],
  } = useContext(OrderEditContext)

  if (!draft || draft.isWholesale === undefined) return null

  return (
    <AddBtnStandard_Reusable
      cartServiceType="orderEdit"
      isWholesale={draft.isWholesale}
      pickupDate={pickupDate}
      prod={prod}
      schedule={schedule}
      unit={unit}
    />
  )
}, propsAreDeepEqual)
