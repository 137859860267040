import { InvoicePaymentMethod } from '@models/Invoice'
import { EbtCardTypes, PaymentSources } from '@models/PaymentMethod'
import { FinitePaymentMethodDefinition, PaymentMethodsDefinitionInterface } from './PaymentDefinition'
import { CurrencyCode } from '@models/Money'

/** This is a finite payment method handled by WorldPay. */
export class WorldPayEbtPaymentDefinition
  extends FinitePaymentMethodDefinition<PaymentSources.WORLD_PAY_EBT>
  implements PaymentMethodsDefinitionInterface<PaymentSources.WORLD_PAY_EBT>
{
  constructor() {
    super(PaymentSources.WORLD_PAY_EBT, 'EBT')
  }

  formatInvPayMethod({ last4, card_type }: InvoicePaymentMethod<PaymentSources.WORLD_PAY_EBT>) {
    const baseStr = card_type === EbtCardTypes.SNAP ? 'EBT SNAP' : card_type === EbtCardTypes.CASH ? 'EBT CASH' : 'EBT'

    if (last4) return `${baseStr} *${last4}`
    return baseStr
  }

  /** EBT does not exist in Canada and should only be available to pay for a USD purchase */
  hasCurrencySupport(currency: CurrencyCode): boolean {
    return ['usd'].includes(currency)
  }
}
