import { PickRequire } from '@helpers/typescript'
import { Address } from './Address'
import { UserType } from './User'

type AddCustomerData = Omit<Address, 'coordinate'> & {
  firstName: string
  lastName: string
  email: string
  email2?: string
  phoneNumber?: string
  businessName?: string
  // UserType is needed as businessName is not enough, as it can be an empty string, even if it's a wholesale buyer
  userType: UserType
}

export type AddCustomerDataForm = PickRequire<AddCustomerData, 'country'>

export enum CustomerApiError {
  UserTypeMismatch = 'UserTypeMismatch',
  UserRoleMismatch = 'UserRoleMismatch',
  UserIsAlreadyCustomer = 'UserIsAlreadyCustomer',
  UserFarmTypeMismatch = 'UserFarmTypeMismatch',
  UserAlreadyExists = 'UserAlreadyExists',
}
