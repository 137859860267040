import { adjustBalance } from '@api/Payments'
import { Alert, Button, ButtonGroup, ErrorText, FormInput, MoneyInput } from '@elements'
import { YUP_MONEY_REQUIRED } from '@helpers/Yup'
import { formatMoney } from '@helpers/display'
import { MoneyCalc } from '@helpers/money'
import { MoneyWithCurrency } from '@models/Money'
import { Formik, FormikProps } from 'formik'
import { useCallback, useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import * as Yup from 'yup'

import { Logger } from '../../../config/logger'

import { globalStyles } from '@/constants/Styles'
import { adminCurrencySelector } from '@/redux/selectors'
import { nonEmptyString } from '@helpers/helpers'
import { useSelector } from 'react-redux'

enum BalType {
  Credit = 0,
  Charge = 1,
}

type FormType = {
  amount: MoneyWithCurrency
  message: string
}

const validationSchema: Yup.ObjectSchema<FormType> = Yup.object().shape({
  amount: YUP_MONEY_REQUIRED('Amount', { requireCurrency: true }),
  message: Yup.string()
    .trim()
    .min(2, 'Please enter a message at least 2 characters')
    .max(350, 'Message cannot be more than 350 characters')
    .trim()
    .label('Message')
    .required(),
})

export function AdjustBalance({
  userId,
  farmId,
  uniqueId,
  farmBalance,
}: {
  userId: string
  farmId: string
  uniqueId: string
  farmBalance: MoneyWithCurrency
}) {
  const currency = useSelector(adminCurrencySelector)
  const [currIndex, updateIndex] = useState(0)
  const [loading, setLoading] = useState(false)

  const onSubmitHandler = useCallback(
    async (values: FormType) => {
      if (values.amount === undefined) return

      let amount = values.amount
      if (currIndex === BalType.Charge) {
        if (MoneyCalc.isLessThan(farmBalance, amount)) {
          return Alert(
            'Farm Credit Failed',
            `You can not charge ${formatMoney(amount)}. That exceeds current farm credit ${formatMoney(farmBalance)}`,
          )
        } else {
          amount = MoneyCalc.multiply(amount, -1)
        }
      }
      setLoading(true)
      try {
        const newBal = await adjustBalance(userId, farmId, amount.value, values.message, uniqueId)
        setLoading(false)
        Alert(
          'Farm Credit Update Successful',
          `You have successfully updated your customers farm credit to ${formatMoney(newBal)}`,
        )
      } catch (err) {
        setLoading(false)
        Logger.error(err)
        Alert(
          'Update Farm Credit Failed',
          'Failed to update farm credit, please try again later or contact support if this issue persists.',
        )
      }
    },
    [currIndex, farmBalance, farmId, uniqueId, userId],
  )
  return (
    <Formik
      initialValues={{
        amount: { value: 0, currency },
        message: '',
      }}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      {({ values, errors, setFieldValue, handleSubmit, handleBlur, touched }: FormikProps<FormType>) => (
        <View style={styles.container}>
          <ButtonGroup
            onSelect={updateIndex}
            selectedIndex={currIndex}
            buttons={['Add Farm Credit', 'Debit Farm Credit']}
          />
          <MoneyInput
            label={`Amount to ${currIndex === BalType.Credit ? 'credit' : 'charge'} the customer`}
            maxLength={11}
            onBlur={handleBlur('amount')}
            onChangeText={(value) => setFieldValue('amount', value)}
            value={values.amount}
            currency={currency}
          />
          {!!touched.amount && nonEmptyString(errors.amount) && <ErrorText>{errors.amount}</ErrorText>}
          <FormInput
            multiline
            numberOfLines={2}
            placeholder="Message to Customer"
            errorMessage={touched.message ? errors.message : ''}
            value={values.message}
            onChangeText={(val) => setFieldValue('message', val)}
            onBlur={handleBlur('message')}
          />
          <Button loading={loading} title="Update Farm Credit" onPress={() => handleSubmit()} />
          <Text style={globalStyles.margin10}>Customers will be notified of this change.</Text>
        </View>
      )}
    </Formik>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 10,
  },
  reloadCont: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
})
