import { MessageWithIcon } from '@/components'
import Colors from '@/constants/Colors'
import { useApiFx } from '@/hooks/useApiFx'
import { fetchBigQueryLogs } from '@api/FarmGenAdmin'
import { LoadingView, Modal, Text } from '@elements'
import { displayTimeStamp } from '@helpers/time'
import { DateRange } from '@models/Schedule'
import { BigQueryLogsRequest } from '@shared/types/v2/farmGenAdmin'
import { DateTime } from 'luxon'
import { FlatList, ScrollView, StyleSheet, View } from 'react-native'

interface BigQueryLogsProps {
  collectionName: BigQueryLogsRequest['collection']
  documentId: string
  dateRange: DateRange
}

/** Component to display BigQuery logs */
function BigQueryLogs({ collectionName, documentId, dateRange }: BigQueryLogsProps) {
  const bigQueryLogsFx = useApiFx(fetchBigQueryLogs, [collectionName, documentId, dateRange])

  return (
    <ScrollView style={styles.container}>
      <View style={styles.header}>
        <Text type="medium">Collection: {collectionName}</Text>
        <Text type="medium">Document ID: {documentId}</Text>
      </View>
      <LoadingView loading={bigQueryLogsFx.loading} error={bigQueryLogsFx.err} success={bigQueryLogsFx.data}>
        {(data) => (
          <View style={styles.logsContainer}>
            <FlatList
              data={data.logs}
              ListEmptyComponent={
                <MessageWithIcon icon="database" title="No Logs" children="No BigQuery logs found." />
              }
              renderItem={({ item: log }) => (
                <View style={styles.logItem}>
                  <Text type="medium" size={15}>
                    Timestamp: {displayTimeStamp(DateTime.fromFormat(log.timestampRef, 'yyyy-MM-dd hh:mm:ss a'))}
                  </Text>
                  <ScrollView horizontal contentContainerStyle={styles.fieldsRow}>
                    <View style={styles.fieldsContainer}>
                      <Text style={styles.sectionHeader} type="bold">
                        Current Values:
                      </Text>
                      <Text style={styles.fieldText} size={11}>
                        {JSON.stringify(log.curr, null, 2)}
                      </Text>
                    </View>
                    <View style={styles.fieldsContainer}>
                      <Text style={styles.sectionHeader} type="bold">
                        Previous Values:
                      </Text>
                      <Text style={styles.fieldText} size={11}>
                        {JSON.stringify(log.prev, null, 2)}
                      </Text>
                    </View>
                  </ScrollView>
                </View>
              )}
            />
          </View>
        )}
      </LoadingView>
    </ScrollView>
  )
}

/** Modal component to display BigQuery logs */
export function BigQueryModal(
  collectionName: BigQueryLogsRequest['collection'],
  documentId: string,
  dateRange: DateRange,
) {
  return Modal(<BigQueryLogs collectionName={collectionName} documentId={documentId} dateRange={dateRange} />, {
    title: `BigQuery Logs: ${collectionName}`,
    dismissable: true,
  })
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
    flex: 1,
  },
  header: {
    marginBottom: 16,
    padding: 10,
    backgroundColor: Colors.shades[100],
    borderRadius: 4,
  },
  logItem: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: Colors.shades[300],
    marginBottom: 10,
  },
  fieldsContainer: {
    marginTop: 8,
    padding: 8,
    borderRadius: 4,
  },
  fieldText: {
    fontFamily: 'monospace',
    marginVertical: 2,
  },
  sectionHeader: {
    marginTop: 10,
    marginBottom: 5,
  },
  fieldsRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: Colors.shades[100],
    padding: 4,
    borderRadius: 4,
  },
  logsContainer: {
    backgroundColor: Colors.shades[100],
  },
})
