import { ArrElement, PartialExcept } from '@helpers/typescript'
import { Invoice, InvoiceItemTypes } from '@models/Invoice'
import { PaymentMethod, PaymentMethodBase } from '@models/PaymentMethod'
import { DateTime } from 'luxon'

import { orderItemProductSchema } from '@helpers/builders/OrderBuilder'
import { hasOwnProperty } from '@helpers/helpers'
import { Address } from './Address'
import { CSA } from './CSA'
import { Distribution } from './Distribution'
import { Farm } from './Farm'
import { isNonPickup, Location, LocationTypes, NonPickup } from './Location'
import { Money } from './Money'
import {
  CancellationTypes,
  DigitalProduct,
  GlobalStandard,
  hasUnits,
  isDigital,
  isPayInFull,
  isPhysical,
  PayInFull,
  PaymentSchedule,
  PayPerPickup,
  Product,
  ProductType,
  Share,
  Standard,
  StockType,
  Unit,
  UnitBase,
  UnitPrice,
  UnitStandard,
} from './Product'
import { Hours } from './Schedule'
import { SignInSummary } from './SignInSummary'
import { Institution, User } from './User'
import { UserAddress } from './UserAddress'

/** OrderState identifies what state the order is in through its lifecycle. */
export enum OrderState {
  // Preparing is the initial state, used when the order is being prepared.
  Preparing = 'preparing',
  // Created is assigned when the order record is stored.
  Created = 'created',
  // Numbered is assigned when the order has been numbered.
  Numbered = 'numbered',
  // Invoiced is assigned when the order has associated invoices.
  Invoiced = 'invoiced',
  // Finalized is assigned when the order has finished processing. An order is considered to be processed after
  // it has been numbered an invoiced.
  Finalized = 'finalized',
}

// Order Status should tell entire order items condition

export enum OrderStatusType {
  //TODO: Maybe call this final??
  Active = 'active',
  Cancelled = 'cancelled',
  ItemCancelled = 'item_cancelled',
}

/** isSpecialOrder returns true if the order is not a regular order. A special order has a particular ID value that we can look for. */
export function isSpecialOrder({ id }: Pick<Order, 'id'>): boolean {
  return id === 'manual_invoice' || id === 'balance_transaction'
}

/** Snapshot of the order item at time of purchase. */
export type OrderItem = Pick<CartItem, 'id' | 'quantity' | 'unadjustedQuantity'> & {
  /** The date this item was cancelled, only if all items are cancelled */
  cancelled?: DateTime

  /** The denormalized product. */
  product: Pick<Product, 'id' | 'type' | 'name' | 'description'> & {
    image: ArrElement<Product['images']>

    /** If the product is a share, will inherit the value. Else will be zero */
    vacationWeeks: number

    /** Will be true if the product is either EBT-eligible or EBT-only.
     * Only relevant for standard products (default undefined for non-standard products) */
    isEbtEligible: boolean | undefined

    /** If the product is a share with a cancellation policy, will inherit the value. Else will be null */
    cancellationPolicy: CancellationTypes | null
  }

  /** The unit and price of the product if it is standard. Otherwise null */
  purchasedUnit: Unit | null
  purchasedUnitPrice: UnitPrice | null

  /** A CSA optionally associated with the item. */
  csa?: Omit<CSA, 'images' | 'numOfCustomers' | 'numOfSharesSold' | 'farm' | 'description'>

  /** Distribution so that we can get the id for pickups. For digital products it will be undefined. */
  distribution?: Pick<Distribution, 'id' | 'name'> & { location: Pick<Location, 'id' | 'timezone' | 'name' | 'type'> }

  /** The planned payment schedule for an item. */
  paymentSchedule: PaymentSchedule

  /** The pickup dates for this item, at time of purchase. */
  pickups?: CartItem['pickups']

  /**  The number of pickups for a share, useful for refunding from claim vacation, or the number of pickups for a multi-pickup standard product */
  numPickups?: number

  /** If the Location was a non-pickup type, the address at checkout will be stored here */
  deliveryAddress?: NonPickup['address']
}

/** Identifies the nested product object type from an order item */
export const isOrderItemProduct = (prod: object): prod is OrderItem['product'] => {
  return orderItemProductSchema.isValidSync(prod)
}

/** The Order encompasses the products purchased during a checkout session. An order is independent of billing. */
export interface Order {
  /** The document identifier. */
  id: string | 'balance_transaction' | 'manual_invoice'

  /** The state of order creation. */
  state: OrderState

  /** Order number */
  orderNum: number

  /** The user who placed the order. */
  user: Pick<User, 'id' | 'name' | 'email'> & { institution: Pick<Institution, 'businessName'> | undefined }

  /** The farm under which the order was placed. */
  farm: Pick<Farm, 'id' | 'name' | 'offlinePayments' | 'timezone'>

  /** The shopping cart items that were ordered for purchase. */
  items: OrderItem[]

  /** The order date */
  date: DateTime

  /** Whether it is a wholesale order (Wholesale orders may be created from either the wholesale marketplace or the order creator in wholesale mode) */
  isWholesale?: boolean

  /** Whether the order was created from a draft order */
  wasDraft?: boolean

  /** The buyer can enter some “special requests” or notes */
  note?: string

  /** An additional option to allow wholesale buyers to track orders internally. Optional and wholesale only. */
  purchaseOrder?: string
}

export type CartItemBase<P extends Product, PS extends PaymentSchedule = PaymentSchedule> = {
  /** The id must encode the product id, unit id and distro id. Should be updated to reflect changes in these */
  id: string

  /** The quantity of product being ordered. */
  quantity: number

  /** The rounded quantity of product being ordered. If this is undefined then there have been no adjustments, so the default quantity can be used. When this is defined it is used only for distribution reports and product inventory or computing available inventory for a cart.
   *
   * The reason to only use it in distribution reports and product inventory is these are the two places in the app besides sales reports where we aggregate amounts across orders. And we should never aggregate based on adjusted quantity as it will lead to unexpected numbers in inventory and reports.
   *
   * Example. For variable weights if
   * the quantity is adjusted from 2 to 1.98. This will remain at 2 so that the farmer knows they need to pack 2 units even
   * if they are slightly less weight than expected. */
  unadjustedQuantity?: number

  /** The product added to cart */
  product: P

  /** The planned payment schedule for an item. In shares, we may have pay-full, monthly or weekly. In standard: If single-pickup, only pay-full. If multi-pickup standard, could be pay-full or pay-per-pickup. */
  paymentSchedule: PS

  /** The dates the product will be picked up. In a standard prod, these dates are selected by the user, and allows buying in the future, as well as multiple dates. In a share, it is automatically calculated by getPickups(). In digital, it is undefined. */
  pickups?: DateTime[]
} & ProductDependent<P>

type ProductDependent<P extends Product> = P extends Share
  ? CartItemBaseShare
  : P extends Standard
  ? CartItemBaseStandard<P>
  : CartItemBaseDigital

type CartItemBaseShare = {
  // A CSA optionally associated with the item.
  csa: CSA
  unit?: undefined
  price?: undefined
  // The selected distribution schedule.
  distribution: Distribution
  pickups: DateTime[]
}

type CartItemBaseStandard<P extends Standard> = {
  csa?: CSA
  // The selected unit, for standard products
  unit: P extends GlobalStandard
    ? UnitBase<StockType.Global>
    : P extends UnitStandard
    ? UnitBase<StockType.Unit>
    : unknown
  price: UnitPrice
  // The selected distribution schedule.
  distribution: Distribution
  pickups: DateTime[]
}

type CartItemBaseDigital = {
  csa?: CSA
  unit: UnitBase<StockType.Global>
  price: UnitPrice
  pickups?: undefined
  distribution?: undefined
}

/** A cartItem with a standard product and a single pickup date, with payInFull as only valid payment schedule */
export type CartStandardSingle = CartItemBase<Standard, PayInFull>

/** A cartItem with standard product, and multiple pickup dates, with either payInFull or payPerPickup payment schedules */
export type CartStandardMulti = CartItemBase<Standard, PayPerPickup | PayInFull>

export type CartStandard = CartStandardMulti | CartStandardSingle

/** FIXME: The CartShare type should only have payment options: full, monthly, weekly, but no per-pickup */
export type CartShare = CartItemBase<Share>

export type CartPhysical = CartShare | CartStandard

export type CartDigital = CartItemBase<DigitalProduct, PayInFull>

export type CartItem = CartShare | CartStandardMulti | CartStandardSingle | CartDigital

export type ItemNonPickup = CartPhysical & {
  distribution: Distribution<NonPickup>
}

export type LocationFeeMapping = {
  amount: Money

  type: InvoiceItemTypes.SHIPPING_FEE | InvoiceItemTypes.DELIVERY_FEE

  invoice: Pick<Invoice, 'id'> // Maybe we want more fields here, we need a denormalizer if so
}

export type Pickup = {
  /** The ID of the pickup */
  id: string

  /** User id for querying based on user */
  user: Pick<User, 'id'>

  /** Farm id for querying */
  farm: Pick<Farm, 'id' | 'name' | 'logo' | 'email' | 'phoneNumber'>

  /** The date of the pickup */
  date: DateTime

  /** This field will contain the original distribution for rescheduled items, here it means that an item in the pickup was rescheduled */
  oldDistribution?: Pick<Distribution, 'id'>

  /** locationFee will hold and shipping or delivery fee necessary for this pickup */
  locationFee?: LocationFeeMapping

  /** Items to be picked up */
  items: PickupItem[]

  /** The distribution information */
  distribution: {
    id: string

    name: string
    // TODO: In the future we should move the location to be a nested object like this:
    //  location: Pick<Location, 'id' | 'type' | 'name' | 'abbreviation'>
    /** locationType in the Pickup model is intended to help distinguish between delivery and pickup locations */
    locationType: LocationTypes

    locationName: string

    locationAbbreviation?: string

    notes?: string

    /** Address if it's a local pickup or UserAddress if it's a non-pickup (delivery or shipment) */
    address: Address | UserAddress

    hours: Hours
  }

  /** If a sign in summary has started that includes this pickup then we will store the id here */
  signInSummary?: Pick<SignInSummary, 'id'>

  /** A draft pickup can be used to temporarily show data for distributions before the order has been finalized. We store the
   * draft orderId here to help with querying */
  draftOrderId?: string
}

export type SplitTenderPaymentItemBase = {
  paymentMethod: PaymentMethod

  /** If amount undefined, will be treated as Infinite */
  amount?: Money
}

type SplitTenderPaymentItemPartial = Omit<SplitTenderPaymentItemBase, 'paymentMethod'> & {
  paymentMethod: Pick<PaymentMethodBase, 'source'>
}

export type SplitTenderPaymentPartial = SplitTenderPaymentItemPartial[]

/** Defines the split of money between payment items. One must be an infinite amount. */
export type SplitTenderPayment = SplitTenderPaymentItemBase[]

/** isPickupCancelled returns true if a pickup has no available items to pickup. An item is considered unavailable if it won't be picked up */
export function isPickupCancelled(pickup: Pickup): boolean {
  return !pickup.items.length || pickup.items.every((item) => isPickupItemSkipped(item))
}

/** returns true if a pickup is a draft and should not be edited, combined or altered. It should be read-only */
export function isDraftPickup(pickup: Pick<Pickup, 'draftOrderId'>): boolean {
  return !!pickup.draftOrderId
}

export enum PickupItemStatus {
  Cancelled = 'cancelled',
  Vacation = 'vacation',
  Active = 'active',
  Received = 'received',
  Missed = 'missed',
  Donated = 'donated',
}

export type PickupItem = {
  /** This ID matches the ID of the order item and invoice item, however it does not guarantee it is unique across this pickup, so you must compare both id and orderId to ensure uniqueness across a pickup */
  id: CartItem['id']

  /** Only for standard product pickup items */
  invoiceId?: string

  /** The status of the current pickup item */
  status:
    | PickupItemStatus.Active
    | PickupItemStatus.Cancelled
    | PickupItemStatus.Vacation
    | PickupItemStatus.Received
    | PickupItemStatus.Missed
    | PickupItemStatus.Donated

  /** This field will contain the original distribution for rescheduled items */
  oldDistribution?: Pick<Distribution, 'id'>

  /** Additional options that control rescheduling for CSAs */
  csaChangeOptions?: {
    blockLocationSwitching?: true
    blockRescheduling?: true
    changeWindow: number
  }
  orderItem: Pick<OrderItem, 'quantity' | 'unadjustedQuantity' | 'purchasedUnit' | 'paymentSchedule'>
  //TODO: Why isn't this working as expected
  // product: Pick<OrderItem['product'], 'id' | 'name' | 'image' | 'type'>
  product: {
    id: string
    image: ArrElement<Product['images']>
    name: string
    type: ProductType
  }
  order: Pick<Order, 'id' | 'orderNum' | 'date'>
}

/** isPickupItemActive returns true if the pickup item is active */
export function isPickupItemActive(item: PartialExcept<PickupItem, 'status'>): boolean {
  return item.status === PickupItemStatus.Active
}

/** isPickupItemCancelled returns true if the pickup item is cancelled */
export function isPickupItemCancelled(item: PartialExcept<PickupItem, 'status'>): boolean {
  return item.status === PickupItemStatus.Cancelled
}

/** isPickupItemOnVacation returns true if the pickup item is on vacation */
export function isPickupItemOnVacation(item: PartialExcept<PickupItem, 'status'>): boolean {
  return item.status === PickupItemStatus.Vacation
}

/** isPickupItemSkipped returns true if the pickup item status is cancelled or on vacation */
export function isPickupItemSkipped(item: PartialExcept<PickupItem, 'status'>): boolean {
  return isPickupItemCancelled(item) || isPickupItemOnVacation(item)
}

/** findOrderItem returns the order item matching the supplied identifier. It will return undefined if no item matches the identifier. */
export function findOrderItem(order: Order, itemId: string): OrderItem | undefined {
  return order.items.find((item) => item.id === itemId)
}

/** Identifies a cart item with a share product */
export function isCartShare(item: Pick<CartItem, 'product'>): item is CartShare {
  return item.product.type === ProductType.PrimaryShare || item.product.type === ProductType.AddonShare
}

/** Identifies a cart item with a standard product */
export function isCartStandard(item: Pick<CartItem, 'product'>): item is CartStandard {
  return item.product.type === ProductType.Standard
}

/** Identifies a cart item with a digital product */
export function isCartDigital(item: Pick<CartItem, 'product'>): item is CartDigital {
  return isDigital(item.product)
}

/** Identifies a cart item with a physical product */
export function isCartPhysical(item: { product: Pick<Product, 'type'> }): item is CartPhysical {
  return isPhysical(item.product)
}

/**
 * Will determine if a cart item is a single pickup standard product. It is more useful for type narrowing than checking for multiPickup
 */
export function isCartStandardSingle(
  item: Pick<CartItem, 'product' | 'pickups' | 'paymentSchedule'>,
): item is CartStandardSingle {
  return (
    item.product.type === ProductType.Standard &&
    item.pickups?.length === 1 &&
    item.paymentSchedule.frequency === 'ONCE'
  )
}

/** Type that represents a cartItem with multiple pickups while extending both CartItem and OrderItem types */
type ItemStandardMulti<T> = T extends CartItem
  ? CartStandardMulti
  : Omit<OrderItem, 'pickups' | 'paymentSchedule'> & {
      pickups: DateTime[]
      paymentSchedule: CartStandardMulti['paymentSchedule']
    }

/**
 * Will determine if a cart item is a single pickup standard product. It is more useful for type narrowing than checking for multiPickup.
 * - It works with OrderItems as well
 */
export function isCartStandardMulti<T extends CartItem | OrderItem>(
  item: Pick<T, 'product' | 'pickups' | 'paymentSchedule'>,
): item is ItemStandardMulti<T> {
  return !!(
    item.product.type === ProductType.Standard &&
    item.pickups &&
    item.pickups.length > 1 &&
    (isPayInFull(item.paymentSchedule) || item.paymentSchedule.frequency === 'PER-PICKUP')
  )
}

/** Defferentiates a cart item from an order item */
export function isCartItem(item: CartItem | OrderItem): item is CartItem {
  // This works because 'purchasedUnit' and 'numPickups' are properties of OrderItem, which has a different representation in the CartItem model

  if (hasUnits(item.product)) {
    return !hasOwnProperty(item, 'purchasedUnit')
  } else {
    return !hasOwnProperty(item, 'numPickups')
  }
}

/** Identifies a cartItem with a delivery location */
export function isCartItemNonPickup(item: CartPhysical): item is ItemNonPickup {
  return isNonPickup(item.distribution.location)
}
