import { geoLocateIPAddr, getParsedAddressFromCoords, loadExactCoords } from '@api/Addresses'
import { validCoords } from '@helpers/coordinate'
import { Coordinate } from '@models/Coordinate'
import { useDispatch, useSelector } from 'react-redux'

import { errorCatcher } from '@api/Errors'
import { extendErr, nonEmptyString, settleUnwrap } from '@helpers/helpers'
import { ErrorCode, isErrorWithCode } from '@shared/Errors'
import { DateTime } from 'luxon'
import { useEffect } from 'react'
import { Logger } from '../config/logger'
import { CurrentLocation } from '../constants/types'
import { setSessionLocation } from '../redux/actions/appPersist'
import { sessionLocationSelector } from '../redux/selectors'

/** Returns the current location by either IP or exact device location as best as possible
 * - This should NOT return a default location as a fallback. If a fallback location is desired, it could be obtained outside of this function when the return value is undefined
 */
async function getCurrentLocation(
  cachedLoc?: CurrentLocation | null,
): Promise<{ coords: Coordinate; name?: string } | undefined> {
  // Only run if we haven't checked in the last hour, OR if there's no cached location yet
  if (
    !cachedLoc || // If it's not set
    cachedLoc.timestamp < DateTime.now().toMillis() - 60 * 60 * 1000 || // If we haven't checked in the last hour
    !validCoords(cachedLoc.coordinate) ||
    !cachedLoc.name
  ) {
    /** Design considerations:
     * - The IP location call is expected to fail often. Therefore it's not a good idea to bundle the two api calls into a single Promise.all() because that would force both to fail together.
     */
    const [ipLoc, exactCoords] = await settleUnwrap([
      geoLocateIPAddr().catch((err) => {
        Logger.error(
          isErrorWithCode(err, ErrorCode.AbortedError)
            ? err
            : extendErr(err, 'Something went wrong while trying to get IP location: '),
        )
        return undefined
      }),
      loadExactCoords().catch((err) => {
        Logger.error(extendErr(err, 'Something went wrong while trying to get exact coordinates: '))
        return undefined
      }),
    ])

    if (!ipLoc && !exactCoords) {
      // If no data could be obtained from the IP or the exact location, return undefined
      return undefined
    }

    // prioritize exact coords over ip coords
    const coords = exactCoords ?? ipLoc?.coordinate
    if (!coords) return undefined

    let name = ipLoc?.name
    // Load the city from geocoding if IP did not detect the city
    if (validCoords(coords) && !name) {
      try {
        const parser = await getParsedAddressFromCoords(coords)
        // This parsed address does not need to be fully valid because for this purpose we only need the city, and optionally the state
        const addr = parser.getResult()
        if (nonEmptyString(addr.city)) name = `${addr.city}${addr.state ? `, ${addr.state}` : ''}`
      } catch (err) {
        Logger.error(extendErr(err, 'Error getting city from reverse geocoder.'))
      }
    }

    return { coords, name }
  }
}

/** When used inside a component, the screen will request device location and set it to redux */
export function useLocation() {
  const dispatch = useDispatch()
  const cachedLoc = useSelector(sessionLocationSelector)

  useEffect(
    () => {
      getCurrentLocation(cachedLoc)
        .then((data) => dispatch(setSessionLocation(data ? { name: data.name, coordinate: data.coords } : null)))
        .catch(errorCatcher)
    },
    // This function updates cached location, so we do not want the effect to run in a loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
}
