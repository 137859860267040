import { addFarm } from '@api/Farms'
import {
  Alert,
  Divider,
  ErrorText,
  FormInput,
  FormPickerInput,
  FormStatePicker,
  FormZipcodeInput,
  HeaderText,
  KeyboardAvoidingScrollView,
  ScreenView,
  Toast,
} from '@elements'
import { errorToString } from '@helpers/helpers'
import { Farm } from '@models/Farm'
import { StackNavigationProp } from '@react-navigation/stack'
import { SaveFormat } from 'expo-image-manipulator'
import { Formik, FormikProps } from 'formik'
import { useCallback, useState } from 'react'
import { Text, View } from 'react-native'
import { Input } from 'react-native-elements'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize, minSize } from 'rn-responsive-styles'
import * as Yup from 'yup'

import { GooglePlacesSearch } from '../../../components/GooglePlacesSearch'
import ImageSelect from '../../../components/ImageSelect'
import { Button } from '../../../components/elements/Button'
import Colors from '../../../constants/Colors'
import { GoogleAddressParser } from '../../../constants/GoogleAddressParser'
import { FarmerWalkthroughParamList } from '../../../navigation/types'

import { Logger } from '@/config/logger'
import { globalStyles } from '@/constants/Styles'
import { withAuth } from '@/hooks/withAuth'
import { internationalSelector } from '@/redux/selectors'
import { addressSchemaForm } from '@helpers/builders/AddressBuilder'
import { countryItemsList } from '@helpers/international/types'
import { Address, GooglePlace } from '@models/Address'
import { useSelector } from 'react-redux'
import { withBuyerBlock } from '../../../hooks/withRetailCheck/withBuyerBlock'

interface Props {
  navigation: StackNavigationProp<FarmerWalkthroughParamList>
}

type FormType = {
  image: string
  name: string
  about: string
} & Omit<Address, 'coordinate'>

const schema: Yup.ObjectSchema<FormType> = addressSchemaForm.shape({
  name: Yup.string().trim().label('Name').required().min(3, 'Farm name must have at least 3 characters '),
  image: Yup.string().label('Image').required('Please upload an image'),
  about: Yup.string().trim().label('Description').required(),
})

/** Screen to register a new farm to claim */
function AddFarmScreenComp({ navigation }: Props) {
  const [isLoading, setIsLoading] = useState(false)
  const styles = useStyles()
  const { country } = useSelector(internationalSelector)

  const onSubmitHandler = useCallback(
    (values: FormType) => {
      const { name } = values
      setIsLoading(true)
      addFarm({
        name: values.name.trim(),
        about: values.about,
        address: {
          street1: values.street1,
          street2: values.street2,
          city: values.city,
          state: values.state,
          zipcode: values.zipcode,
          country: values.country,
        },
        media: [{ storageUrl: values.image, type: 'image' }],
      })
        .then((farm: Farm) => {
          Alert(
            `Hooray! You just added ${name} to our records.`,
            'In order to start selling on GrownBy, please follow the steps to claim your farm.',
            [
              {
                text: 'Claim Farm',
                style: 'cancel',
              },
            ],
          )

          navigation.navigate('RegisterFarm', { farmId: farm.id })
        })
        .catch((err) => {
          Logger.error(err)
          Alert('Error while adding farm', errorToString(err))
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [navigation],
  )

  const setGooglePlace = (details: GooglePlace, handler: (newValues: Partial<FormType>) => void) => {
    if (!details.address_components) return
    try {
      const address = new GoogleAddressParser(details.address_components).getAddress()
      const newValues: Pick<FormType, 'name' | 'street1' | 'city' | 'state' | 'zipcode' | 'country'> = {
        name: details.name || '',
        street1: address.street1,
        city: address.city,
        state: address.state,
        zipcode: address.zipcode,
        country: address.country,
      }
      handler(newValues)
    } catch (error) {
      Toast('The place you selected cannot be used for farm registration')
    }
  }

  return (
    <ScreenView>
      <KeyboardAvoidingScrollView
        style={globalStyles.flex1}
        contentContainerStyle={styles.mainContent}
        keyboardShouldPersistTaps="always"
      >
        <Formik<FormType>
          initialValues={{
            image: '',
            name: '',
            about: '',
            street1: '',
            street2: '',
            city: '',
            state: '',
            zipcode: '',
            // The initial country selected is from the country mode
            country,
          }}
          onSubmit={onSubmitHandler}
          validationSchema={schema}
        >
          {({ handleChange, setValues, values, errors, touched, handleSubmit, handleBlur }: FormikProps<FormType>) => (
            <View>
              <HeaderText style={styles.headerText}>Register your farm</HeaderText>
              <GooglePlacesSearch
                onSelectGooglePlace={(item) =>
                  setGooglePlace(item, ({ name = '', ...newValues }) =>
                    setValues({
                      ...values,
                      ...newValues,
                      // Should only set the name if the form value for name is empty
                      name: values.name || name,
                    }),
                  )
                }
                placeholder="Search for your farm"
                types="establishment"
                contStyle={styles.placesCont}
                style={styles.placesInput}
                inline
                country={country}
              />
              <Divider large />
              <View>
                <View style={styles.rowOrColumn}>
                  <View style={styles.mainFields}>
                    <HeaderText style={styles.headerText}>Contact information</HeaderText>
                    <Input
                      placeholder="Farm Name"
                      errorStyle={{ color: Colors.red }}
                      errorMessage={touched.name ? errors.name : ''}
                      value={values.name}
                      onChangeText={handleChange('name')}
                      onBlur={handleBlur('name')}
                    />

                    <Input
                      placeholder="Address"
                      errorStyle={{ color: Colors.red }}
                      errorMessage={touched.street1 ? errors.street1 : ''}
                      value={values.street1}
                      onChangeText={handleChange('street1')}
                      onBlur={handleBlur('street1')}
                    />
                    <Input
                      placeholder="Address 2"
                      errorStyle={{ color: Colors.red }}
                      errorMessage={touched.street2 ? errors.street2 : ''}
                      value={values.street2}
                      onChangeText={handleChange('street2')}
                      onBlur={handleBlur('street2')}
                    />
                    <View style={styles.rowOrColumn}>
                      <FormInput
                        placeholder="City"
                        errorStyle={{ color: Colors.red }}
                        errorMessage={touched.city ? errors.city : ''}
                        value={values.city}
                        onChangeText={handleChange('city')}
                        onBlur={handleBlur('city')}
                        containerStyle={styles.inputContainer}
                        inputStyle={styles.inputStyle}
                      />
                      <FormStatePicker
                        country={values.country}
                        errorMessage={touched.state ? errors.state : ''}
                        value={values.state}
                        onValueChange={handleChange('state')}
                        onBlur={handleBlur('state')}
                        containerStyle={styles.inputContainer}
                        style={styles.inputStyle}
                      />
                      <FormZipcodeInput
                        country={values.country}
                        errorMessage={touched.zipcode ? errors.zipcode : ''}
                        value={values.zipcode}
                        onChangeText={handleChange('zipcode')}
                        onBlur={handleBlur('zipcode')}
                        containerStyle={styles.inputContainer}
                        inputStyle={styles.inputStyle}
                      />
                      <FormPickerInput
                        label="Country"
                        placeholder={{ label: 'Select a Country...', value: '' }}
                        errorMessage={touched.country ? errors.country : ''}
                        items={countryItemsList}
                        value={values.country}
                        onValueChange={handleChange('country')}
                        onBlur={handleBlur('country')}
                        containerStyle={styles.inputContainer}
                        style={styles.inputStyle}
                      />
                    </View>
                    <HeaderText style={styles.headerText}>About your farm</HeaderText>
                    <FormInput
                      label={null}
                      onBlur={handleBlur('about')}
                      onChangeText={handleChange('about')}
                      multiline
                      numberOfLines={5}
                      inputContainerStyle={styles.aboutContainer}
                      value={values.about}
                      inputStyle={styles.aboutInput}
                      placeholder="3-5 sentences about your farm. This will appear on your farm profile for customers to see."
                    />
                  </View>
                  <View>
                    <HeaderText>Add an Image</HeaderText>
                    <Text>Recommended Aspect Ratio is 3:2 (ex. 1200x800)</Text>
                    <View style={styles.imgSelectCont}>
                      <ImageSelect
                        defaultImage={values.image}
                        editOptions={{ width: 1200, format: SaveFormat.JPEG }}
                        onChange={handleChange('image')}
                      />
                    </View>
                    {!!errors.image && <ErrorText>{errors.image}</ErrorText>}
                  </View>
                </View>

                <Text style={{ color: Colors.red }}>{touched.about ? errors.about : ''}</Text>
                <Button
                  title="Register farm"
                  loading={isLoading}
                  onPress={() => handleSubmit()}
                  style={styles.submit}
                />
              </View>
            </View>
          )}
        </Formik>
      </KeyboardAvoidingScrollView>
    </ScreenView>
  )
}

export const AddFarmScreen = withBuyerBlock(withAuth(AddFarmScreenComp), { blockWholesaleAuthorizedUser: true })

const useStyles = CreateResponsiveStyle(
  {
    mainContent: {
      paddingHorizontal: 20,
      paddingVertical: 20,
    },
    rowOrColumn: {
      flexDirection: 'column',
    },
    mainFields: { flexDirection: 'column', width: '60%' },
    inputContainer: {
      maxWidth: '100%',
    },
    errMsg: {
      color: Colors.red,
      fontSize: 12,
      marginHorizontal: 10,
    },
    missingFarmButton: {
      fontSize: 32,
      margin: 20,
    },
    imgSelectCont: {
      borderWidth: 1,
      borderColor: Colors.shades[100],
      margin: 10,
      borderRadius: 10,
      height: 100,
      width: 150,
      overflow: 'hidden',
    },
    placesCont: {
      borderWidth: 1,
      borderRadius: 10,
      borderColor: Colors.shades[200],
      padding: 10,
    },
    placesInput: { color: Colors.shades[600], fontSize: 16, height: 30 },
    headerText: { marginBottom: 16 },
    inputStyle: { maxWidth: '100%' },
    aboutContainer: { padding: 10, maxWidth: 700 },
    aboutInput: { height: 136 },
    submit: { maxWidth: 195, marginLeft: 0 },
  },
  {
    [minSize(DEVICE_SIZES.LARGE_DEVICE)]: {
      inputContainer: {
        maxWidth: '30%',
      },
      rowOrColumn: {
        flexDirection: 'row',
      },
    },
    [maxSize(DEVICE_SIZES.MD)]: {
      mainFields: { width: '100%' },
    },
  },
)
