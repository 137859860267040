import { YUP_MONEY_REQUIRED } from '@helpers/Yup'
import { pick } from '@helpers/typescript'
import { PaymentSources } from '@models/PaymentMethod'
import { FeeType, Payout, Transaction, TransactionFee, failureReasons } from '@models/Payout'
import * as yup from 'yup'
import { Builder } from './Builder'
import { invoiceOrderSchema } from './InvoiceBuilder'
import { ValidationError, isValidationError, validateFromSchema } from './validators/helpers'

export const transactionFeeSchema: yup.ObjectSchema<TransactionFee> = yup.object({
  type: yup.string<FeeType>().defined().oneOf(['platform', 'processor', 'tax']),
  description: yup.string().defined(),
  amount: YUP_MONEY_REQUIRED('Amount', { requireCurrency: true, allowZero: true, allowNegative: true }),
})

export const transactionSchema: yup.ObjectSchema<Transaction> = yup.object({
  user: yup
    .object({
      id: yup.string().defined(),
    })
    .default(undefined),
  invoice: yup
    .object({
      id: yup.string().defined(),
      invoiceNum: yup.number().defined(),
      order: invoiceOrderSchema,
    })
    .default(undefined),
  description: yup.string().defined(),
  amountPaid: YUP_MONEY_REQUIRED('Amount Paid', { requireCurrency: true, allowZero: true, allowNegative: true }),
  fees: yup.array(transactionFeeSchema).defined(),
  type: yup.string<NonNullable<Transaction['type']>>().oneOf(['charge', 'refund']),
})

export const payoutSchema: yup.ObjectSchema<Payout> = yup.object({
  id: yup.string().defined(),
  source: yup.string().defined().oneOf(Object.values(PaymentSources)),
  ref: yup.string().defined(),
  farm: yup
    .object({
      id: yup.string().defined(),
    })
    .defined(),
  amount: YUP_MONEY_REQUIRED('Amount', { requireCurrency: true, allowZero: true, allowNegative: true }),
  destinationAccount: yup.string(),
  arrivalDate: yup.mixed().isDateTime(),
  failure: yup.string().oneOf(failureReasons),
  transactions: yup.array(transactionSchema).defined(),
})

export class PayoutBuilder extends Builder<Payout> {
  constructor() {
    super('payout')
  }

  public build({
    id,
    source,
    ref,
    farm,
    amount,
    destinationAccount,
    arrivalDate,
    transactions,
    failure,
  }: Partial<Payout>): Payout {
    const assembled: Partial<Payout> = {
      id,
      source, // set the source of the payout as 'stripe' for a name that covers (stripe credit and strip ach payment.)
      ref,
      farm: farm ? pick(farm, 'id') : undefined,
      amount,
      destinationAccount,
      arrivalDate,
      failure,
      transactions,
    }
    return payoutSchema.validateSync(assembled)
  }

  validate(data: Payout): Payout {
    try {
      return validateFromSchema(payoutSchema, data)
    } catch (error) {
      if (isValidationError(error)) {
        throw new ValidationError({ path: 'payout.' + (error.data?.path ?? ''), msg: error.message })
      }
      throw error
    }
  }
}
