import { getZero, MoneyCalc } from '@helpers/money'
import { entries, values } from '@helpers/typescript'
import { CurrencyCode, MoneyWithCurrency } from '@models/Money'
import { PaymentSources } from '@models/PaymentMethod'

interface SourceAmountsTrackerInterface {
  /** Add an amount to a specific payment source */
  addAmount(source: PaymentSources, toAdd: MoneyWithCurrency): void

  /** Subtract an amount from a specific payment source */
  subtractAmount(source: PaymentSources, toSubtract: MoneyWithCurrency): void

  /** Get the total refundable amount for a specific payment source */
  getSourceAmount(source: PaymentSources): MoneyWithCurrency

  /** Add or overwrite the amount for a specific payment source */
  setSourceAmount(source: PaymentSources, newAmount: MoneyWithCurrency): void

  /** Get the total tracked source amount for all payment sources */
  getAllSourceAmounts(removeZero: boolean): TrackedSourceAmounts

  /** Get the total tracked source amount for all payment sources (non-existing payment source should show Zero) */
  displayAllSourceAmounts(): Required<TrackedSourceAmounts>
}

export type TrackedSourceAmounts = Partial<Record<PaymentSources, MoneyWithCurrency>>

/** Tracks amounts of each payment source  */
export class SourceAmountsTracker implements SourceAmountsTrackerInterface {
  private readonly currency: CurrencyCode
  private readonly sourceAmounts: TrackedSourceAmounts = {}

  constructor(currency: CurrencyCode, initialAmounts?: Partial<Record<PaymentSources, MoneyWithCurrency>>) {
    this.currency = currency
    if (initialAmounts) {
      this.sourceAmounts = { ...initialAmounts }
    }
  }

  /** Generate a zeroed out source amounts object */
  static generateZeroSourceAmounts(currency: CurrencyCode): Required<TrackedSourceAmounts> {
    return values(PaymentSources).reduce((acc, source) => {
      acc[source] = getZero(currency)
      return acc
    }, {} as Required<TrackedSourceAmounts>)
  }

  public addAmount(source: PaymentSources, toAdd: MoneyWithCurrency) {
    this.sourceAmounts[source] = MoneyCalc.add(this.sourceAmounts[source] ?? getZero(this.currency), toAdd)
  }

  public subtractAmount(source: PaymentSources, toSubtract: MoneyWithCurrency) {
    const newAmount = MoneyCalc.subtract(this.sourceAmounts[source] ?? getZero(this.currency), toSubtract)

    this.sourceAmounts[source] = newAmount
  }

  public getSourceAmount(source: PaymentSources): MoneyWithCurrency {
    return this.sourceAmounts[source] ?? getZero(this.currency)
  }

  public setSourceAmount(source: PaymentSources, newAmount: MoneyWithCurrency) {
    this.sourceAmounts[source] = newAmount
  }

  public getAllSourceAmounts(removeZero = false): TrackedSourceAmounts {
    // Always merge STRIPE_INVOICE into STRIPE since STRIPE_INVOICE is deprecated, and we are replacing it with stripe
    if (this.sourceAmounts[PaymentSources.STRIPE_INVOICE]) {
      this.addAmount(PaymentSources.STRIPE, this.getSourceAmount(PaymentSources.STRIPE_INVOICE))
      delete this.sourceAmounts[PaymentSources.STRIPE_INVOICE]
    }

    if (removeZero) {
      return entries(this.sourceAmounts).reduce((acc, [source, amount]) => {
        if (!MoneyCalc.isZero(amount)) {
          acc[source] = amount
        }
        return acc
      }, {} as TrackedSourceAmounts)
    }

    return this.sourceAmounts
  }

  public displayAllSourceAmounts(): Required<TrackedSourceAmounts> {
    return values(PaymentSources).reduce((acc, source) => {
      acc[source] = this.sourceAmounts[source] ?? getZero(this.currency)
      return acc
    }, {} as Required<TrackedSourceAmounts>)
  }
}
