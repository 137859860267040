import { globalStyles } from '@/constants/Styles'
import { useCallback } from 'react'
import { Input, InputProps } from 'react-native-elements'

export type NumberInputProps = Omit<InputProps, 'onChangeText' | 'value'> & {
  value?: number
  onChangeText?: (value?: number) => void
}

/** Input for whole Number, ex: 10 */
export function NumberInput({ value, onChangeText, rightIconContainerStyle, label, ...props }: NumberInputProps) {
  // Convert the string value back to a money object to be returned
  const onChangeTextMask = useCallback(
    (text: string) => {
      const numVal = parseFloat(text)

      if (onChangeText) {
        // If it is not a number than set the input to empty
        if (isNaN(numVal)) return onChangeText()
        onChangeText(numVal)
      }
    },
    [onChangeText],
  )

  return (
    <Input
      value={value?.toString() ?? ''}
      onChangeText={onChangeTextMask}
      // This prevents the placeholder from becoming the label unless we explicitly pass a label in
      label={label ?? null}
      placeholder="20"
      keyboardType="decimal-pad"
      rightIconContainerStyle={[rightIconContainerStyle, globalStyles.marginHorizontal10]}
      {...props}
    />
  )
}
