import { deepClone } from '@helpers/helpers'
import { MoneyCalc } from '@helpers/money'
import { Invoice, InvoiceItem } from '@models/Invoice'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { PaymentSources } from '@models/PaymentMethod'
import { marshalPromoCode, unmarshalCoupon, unmarshalPromoCode } from './Coupons'
import { unmarshalLocation } from './Location'
import { marshalDate, unmarshalDate } from './Time'
import { prepareMarshal, prepareUnmarshal } from './encoding'

/** marshalInvoice transforms an Invoice structure to Firestore data. */
export function marshalInvoice(inv: Partial<Invoice>): DocumentData {
  // We want to deepClone the invoice so that when we are marshalling internal pieces we don't modify the original invoice
  const invoice = deepClone(inv)
  const data = prepareMarshal(invoice) as DocumentData

  if (invoice.dueDate) {
    data.dueDate = marshalDate(invoice.dueDate)
  }
  if (invoice.datePaid) {
    data.datePaid = marshalDate(invoice.datePaid)
  }
  if (invoice.payments) {
    data.payments = marshalInvoicePayments(invoice.payments)
  }
  if (invoice.dateVoided) {
    data.dateVoided = marshalDate(invoice.dateVoided)
  }
  if (invoice.amountTotal) {
    data.amountTotal = MoneyCalc.round(invoice.amountTotal)
  }
  //handle stripe payout
  if (invoice.stripePayout) {
    data.stripePayout.arrivalDate = marshalDate(invoice.stripePayout.arrivalDate)
  }
  //handle worldpay payout
  if (invoice.worldpayPayout) {
    data.worldpayPayout.arrivalDate = marshalDate(invoice.worldpayPayout.arrivalDate)
  }
  // Remove note if it is undefined
  if (!invoice.note) {
    delete data.note
  }
  if (Array.isArray(invoice.items)) {
    data.items = invoice.items.map((item) => marshalInvoiceItem(item))
  }

  if (invoice.couponApplied?.promo) {
    data.couponApplied.promo = marshalPromoCode(invoice.couponApplied.promo)
  }

  return data
}

/** unmarshalInvoice transforms the Firestore data to an Invoice object. */
export function unmarshalInvoice(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): Invoice {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)
  const invoice = { ...data, id } as Invoice

  if (data.dueDate) {
    invoice.dueDate = unmarshalDate(data.dueDate)
  }
  if (data.datePaid) {
    invoice.datePaid = unmarshalDate(data.datePaid)
  }
  if (data.payments) {
    invoice.payments = unmarshalInvoicePayments(data.payments)
  }
  if (data.dateVoided) {
    invoice.dateVoided = unmarshalDate(data.dateVoided)
  }
  //handle stripe payout
  if (data.stripePayout && invoice.stripePayout) {
    invoice.stripePayout.arrivalDate = unmarshalDate(data.stripePayout.arrivalDate)
  }
  //handle worldpay payout
  if (data.worldpayPayout && invoice.worldpayPayout) {
    invoice.worldpayPayout.arrivalDate = unmarshalDate(data.worldpayPayout.arrivalDate)
  }

  if (Array.isArray(data.items)) {
    invoice.items = data.items.map((item) => unmarshalInvoiceItem(item))
  }

  if (data.couponApplied) {
    invoice.couponApplied = {
      promo: data.couponApplied.promo ? unmarshalPromoCode(data.couponApplied.promo) : undefined,
      coupon: unmarshalCoupon(data.couponApplied.coupon),
    }
  }

  return invoice
}

// marshalInvoiceItem transforms the Invoice structure into a Firestore data.
export function marshalInvoiceItem(invoiceItm: Partial<InvoiceItem>): DocumentData {
  const data = { ...invoiceItm } as DocumentData

  if (invoiceItm.cancelled) {
    data.cancelled = marshalDate(invoiceItm.cancelled)
  }

  return data
}

// unmarshalInvoiceItem transforms the Firestore data to an Invoice object.
export function unmarshalInvoiceItem(data: DocumentData): InvoiceItem {
  const invoiceItm = { ...data } as InvoiceItem

  if (data.cancelled) {
    invoiceItm.cancelled = unmarshalDate(data.cancelled)!
  }

  if (data.location) invoiceItm.location = unmarshalLocation(data.location)

  return invoiceItm
}

// marshalInvoiceItem transforms the Invoice Payment structure into a Firestore data.
export function marshalInvoicePayments(invPayments: Invoice['payments']): DocumentData {
  const data = { ...invPayments } as DocumentData

  Object.entries(invPayments).forEach(([key, value]) => {
    const newPayment = { ...data[key] }
    if (value.refunds) {
      newPayment.refunds = value.refunds.map((refund) => {
        const refundPayout = refund.refundPayout
        const arrivalDate = refundPayout?.arrivalDate
        return {
          ...refund,
          date: marshalDate(refund.date),
          refundPayout: refundPayout
            ? {
                ...refundPayout,
                arrivalDate: arrivalDate ? marshalDate(arrivalDate) : undefined,
              }
            : undefined,
        }
      })
    }
    data[key] = newPayment
  })

  return data
}

// unmarshalInvoicePayment transforms the Firestore data to an Invoice Payment object.
export function unmarshalInvoicePayments(data: DocumentData): Invoice['payments'] {
  const invPayments = { ...data } as Invoice['payments']

  Object.entries(data).forEach(([key, value]) => {
    const newPayment = { ...invPayments[key as PaymentSources]! }
    if (value.refunds) {
      newPayment.refunds = value.refunds.map((refund: DocumentData) => {
        const refundPayout = refund.refundPayout
        const arrivalDate = refundPayout?.arrivalDate
        return {
          ...refund,
          date: unmarshalDate(refund.date),
          refundPayout: refundPayout
            ? {
                ...refundPayout,
                arrivalDate: arrivalDate ? unmarshalDate(arrivalDate) : undefined,
              }
            : undefined,
        }
      })
    }
    invPayments[key as PaymentSources] = newPayment
  })

  return invPayments
}
