import { Spinner } from '@elements'
import { loadScript } from '@helpers/client'
import { MutableRefObject, useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'

import { EProtectClientWrapper } from './helpers/EProtectClientWrapper'
import { EPROTECT_URL } from './helpers/constants'
import { EProtectClientInterface } from './helpers/types'

type Props = {
  mode: 'pin' | 'combined'
  eProtectRef: MutableRefObject<EProtectClientInterface | undefined>
  // Should be required, as eProtect always takes some time to load
  onFinishLoading: () => void
}

/** Will render the web version of the eProtect payment elements for entering payment information. */
export function EProtect(props: Props) {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    loadScript(
      EPROTECT_URL,
      () => {
        props.eProtectRef.current = new EProtectClientWrapper(props.mode)
        setLoading(false)
        props.onFinishLoading()
      },
      'worldpay-eProtect',
    )
    // We only want this to load the script the first time this component is mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <View>
      {loading && <Spinner style={styles.spinner} />}
      <div id="payframe" />
    </View>
  )
}

const styles = StyleSheet.create({
  spinner: {
    height: 100,
  },
})
