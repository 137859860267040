import { ButtonClear, LoadingView, Text } from '@elements'
import { groupByObj, isTruthy, removeDuplicates } from '@helpers/helpers'
import { StackScreenProps } from '@react-navigation/stack'
import { useCallback, useMemo, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { ConsumerScroll } from '../../../../components/ConsumerView'
import Colors from '../../../../constants/Colors'
import { isWeb } from '../../../../constants/Layout'
import { useApiFx } from '../../../../hooks/useApiFx'
import { useCartService } from '../../../../hooks/useCart'
import { ShoppingStackParamList } from '../../../../navigation/types'
import { wholesaleSelector } from '../../../../redux/selectors'

import { useFocusFx } from '@/hooks/useFocusFx'
import { useLayout } from '@/hooks/useLayout'
import { farmsCollection } from '@api/framework/ClientCollections'
import { MessageWithIcon } from '@components'
import { sortByConstraint } from '@helpers/sorting'
import { Farm } from '@models/Farm'
import { useCartValidation } from '../useCartValidation'
import { FarmCartGroup } from './FarmCartGroup/FarmCartGroup'
import { useLastAccessedShopFarm } from './hooks/useLastAccessedShopFarm'

type Props = StackScreenProps<ShoppingStackParamList, 'MyCart'>

/** Screen that serves as a preview of cart items before checking out */
export function MyCart({ navigation }: Props) {
  const { isWholesale } = useSelector(wholesaleSelector)
  const [expandedFarmIds, setExpandedFarmIds] = useState<string[]>([])

  const { isValidating, goToCheckout } = useCartValidation()

  const { isLargeDevice } = useLayout()
  const { cart: cartItems, loadingCart } = useCartService({ isWholesale })
  const farmIds = useMemo(() => removeDuplicates(cartItems.map((el) => el.product.farm.id)), [cartItems])
  const { isFarmAccessed } = useLastAccessedShopFarm()

  const farmsFx = useApiFx(
    farmsCollection.fetchByIds.bind(farmsCollection),
    /** This will trigger a refresh when all the products from a farm are removed, but it will be addressed in a future pr if neccessary */
    [farmIds],
    /** Should run only when cart items are finished loading, to prevent empty cart flash. This won't trigger a re-fetch*/
    !loadingCart,
    {
      failedConditionMode: 'keep-loading',
    },
  )

  const sections = useMemo(() => {
    if (!farmsFx.data) return undefined

    const cartItemGroups = groupByObj(cartItems, (ci) => ci.product.farm.id)

    return Object.keys(cartItemGroups)
      .map((farmId) => {
        const farm = farmsFx.data?.find((farm) => farm.id === farmId)
        const items = cartItemGroups[farmId]
        if (!farm || !items.length) return undefined

        const isLastAccessed = isFarmAccessed(farm)

        return { farm, items, isLastAccessed }
      })
      .filter(isTruthy)
      .sort(sortByConstraint((a) => a.isLastAccessed))
  }, [cartItems, farmsFx.data, isFarmAccessed])

  useFocusFx(() => {
    // If there is only one farm, it should be expanded by default
    if (sections?.length === 1) {
      setExpandedFarmIds([sections[0].farm.id])
    }
  }, [sections])

  const onToggleExpanded = useCallback(
    (farmId: string) => {
      const updatedList = expandedFarmIds.includes(farmId)
        ? expandedFarmIds.filter((el) => el !== farmId)
        : [...expandedFarmIds, farmId]

      setExpandedFarmIds(updatedList)
    },
    [expandedFarmIds],
  )

  useFocusFx(() => {
    if (!isWeb && isLargeDevice)
      navigation.setOptions({
        headerBackTitle: 'Back',
        headerTitle: 'My Cart',
      })
  }, [isLargeDevice, navigation])

  /** If any farm section is expanded, collapses all sections.
   * If none are expanded, expands all sections. */
  const onToggleAll = useCallback(() => {
    setExpandedFarmIds(expandedFarmIds.length ? [] : farmIds)
  }, [expandedFarmIds.length, farmIds])

  const onGoToShopPress = useCallback(
    (farm: Farm) => {
      if (navigation.canGoBack() && isFarmAccessed(farm)) {
        navigation.goBack()
      } else {
        navigation.navigate('FarmShop', { farmSlug: farm.urlSafeSlug })
      }
    },
    [navigation, isFarmAccessed],
  )

  return (
    <ConsumerScroll contentContainerStyle={styles.contGap}>
      {sections && sections?.length >= 2 && (
        <View style={styles.contGap}>
          <Text size={18}>{`${cartItems.length} items in your Cart from ${sections.length} farms`}</Text>
          <ButtonClear
            small
            onPress={onToggleAll}
            title={`${expandedFarmIds.length ? 'Collapse' : 'Expand'} all items`}
          />
        </View>
      )}
      <LoadingView loading={farmsFx.loading} style={styles.cartWrapper}>
        {sections?.length ? (
          sections.map((group) => (
            <FarmCartGroup
              loading={isValidating}
              onCheckoutPress={goToCheckout}
              key={group.farm.id}
              // Prevent collapse feature when only one cart is available
              isExpanded={sections.length === 1 ? null : expandedFarmIds.includes(group.farm.id)}
              onToggleExpanded={onToggleExpanded}
              farm={group.farm}
              items={group.items}
              multipleExistingFarms={sections.length > 1}
              onGoToShopPress={onGoToShopPress}
            />
          ))
        ) : (
          <MessageWithIcon
            title="Empty Cart!"
            style={styles.emptyCart}
            icon="shopping-cart"
            iconColor={Colors.shades['300']}
          >
            <Text>Your cart is empty, click continue shopping to add products to your cart.</Text>
          </MessageWithIcon>
        )}
      </LoadingView>
    </ConsumerScroll>
  )
}

const styles = StyleSheet.create({
  cartWrapper: {
    gap: 20,
  },
  emptyCart: {
    flex: 1,
    padding: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  contGap: {
    gap: 16,
  },
})
