import { Modal, Spinner } from '@elements'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import * as React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import { SignedInState } from '@models/User'
import { adminFarmSelector, isAdminSelector, userSelector } from '../redux/selectors'
import { useFocusFx } from './useFocusFx'
import useKeyedState from './useKeyedState'

import { AdminTerms } from '@/admin/components/AdminTerms'
import { AppStackParamList } from '@/navigation/types'
import { onUserAuthChange } from '@/redux/actions/user'
import { MessageWithIcon, PermissionDenied } from '@components'
import { AccessRight, Permission } from '@helpers/Permission'
import { getDisplayName } from '@helpers/client'
import { memo } from 'react'
import { useHasPermission } from './useHasPermission'

export const withAdminAuth = <P extends object>(
  Component: React.ComponentType<P>,
  permission?: Permission,
  type?: AccessRight,
) => {
  const NewComp = memo(function (props: P): JSX.Element {
    const [{ authState, loadingState }, , setState] = useKeyedState<{
      authState: SignedInState
      loadingState: boolean
    }>({
      authState: SignedInState.UNKNOWN,
      loadingState: true,
    })
    const farm = useSelector(adminFarmSelector)
    const isAdmin = useSelector(isAdminSelector)
    const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
    const user = useSelector(userSelector)
    const hasPermission = useHasPermission()

    useFocusFx(() => {
      return onUserAuthChange(({ state: newState }) => {
        setState({ authState: newState, loadingState: false })
      })
    }, [setState])

    useFocusFx(() => {
      if (farm?.id && !farm.acceptedTerms)
        Modal(<AdminTerms onSubmit={() => navigation.navigate('AdminDrawerNavigator', { screen: 'Onboard' })} />, {
          header: false,
          dismissable: false,
          webWidth: 800,
        })
    }, [farm.id, farm.acceptedTerms, navigation])

    const isLoading =
      loadingState ||
      authState === SignedInState.UNKNOWN ||
      (authState === SignedInState.SIGNEDIN && (!user.id || !user.email))

    if (isLoading) {
      return (
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Spinner size="large" />
        </View>
      )
    } else if (!isAdmin) {
      // User is not an admin navigate to consumer app
      navigation.navigate('Consumer')
      return <></>
    } else if (!farm?.id) {
      return <MessageWithIcon title="Please register your farm!" />
      // Should pass the permission check to show the screen
    } else if (permission && !hasPermission(permission, type)) {
      return <PermissionDenied />
    } else if (authState === SignedInState.SIGNEDIN) {
      return <Component {...props} />
    }

    // User is logged in and is an admin so we will show the screen they requested
    return <Component {...props} />
  })

  NewComp.displayName = `WithAdminAuth(${getDisplayName(Component)})`

  return NewComp
}
