import { searchFarmByName, updateFarmProperty } from '@api/FarmGenAdmin'
import { Image } from '@components'
import {
  Alert,
  DropdownMenu,
  DropdownMenuBtn,
  FormInput,
  HeaderText,
  Icon,
  Loader,
  Prompt,
  Spinner,
  Text,
  Toast,
} from '@elements'
import { formatAddressCityState } from '@helpers/display'
import { Farm, FarmStatus } from '@models/Farm'
import { useState } from 'react'
import { FlatList, StyleSheet, View } from 'react-native'

import { DEFAULT_APP_FEE } from '@/constants/CONSTANTS'
import { useApiFx } from '@/hooks/useApiFx'
import { getApplicationFee } from '@helpers/applicationFee'
import { Features } from '@models/Features'
import { Tag } from '../../admin/components/ProductTag'
import LoaderWithMessage from '../../components/LoaderWithMessage'
import { Logger } from '../../config/logger'
import Colors from '../../constants/Colors'
import { globalStyles } from '../../constants/Styles'
import { useDebouncedValue } from '../../hooks/useDebounce'
import LoginAsUser from './LoginAsUser'

const generateButtons = (farm: Farm, refresh: () => void): DropdownMenuBtn<Farm>[] => [
  {
    title: farm.status === FarmStatus.Inactive ? 'Add to GrownBy' : 'Remove from GrownBy',
    onPress: (item) => {
      updateStatus(item).then(() => refresh())
    },
  },
  {
    title: 'COOP Membership',
    onPress: (item) =>
      Prompt('Set Member #', {
        onSubmit: (member) => {
          updateMemberId(item, member).then(() => refresh())
        },
        inputProps: { placeholder: 'Member ID', defaultValue: item.memberId || '' },
      }),
  },
  {
    title: 'Service Fee',
    onPress: (item) =>
      Prompt('Update Farm service Fee', {
        onSubmit: (appFeePercent) => {
          updateServiceFee(item, parseInt(appFeePercent, 10)).then(() => refresh())
        },
        inputProps: {
          placeholder: 'Farm service fee',
          defaultValue: String(item?.pricingModel ? item.pricingModel.appFeePercent : DEFAULT_APP_FEE),
          keyboardType: 'number-pad',
        },
      }),
  },
  {
    title: farm.customProps?.ebt ? 'Farm is EBT Active' : 'EBT Go Live',
    onPress: (item) => {
      if (farm.customProps?.ebt) {
        return Alert(
          'Cannot disable EBT',
          'You cannot disable EBT for a farm that has it enabled. This feature can be added if it is required.',
        )
      }
      Alert(
        'Enable EBT for this farm',
        'This will enable the farm for live EBT transactions. This is not meant for starting testing',
        [{ text: 'Cancel' }, { text: 'Enable', onPress: () => updateFeatureFlag(item, 'ebt').then(() => refresh()) }],
      )
    },
  },
  {
    title: farm.isWholesale ? 'Wholesale Enabled Farm' : 'Enable Wholesale',
    onPress: (item) => {
      if (farm.isWholesale) {
        return Alert(
          'Cannot disable wholesale',
          'You cannot disable wholesale for a farm that has it enabled. This feature can be added if it is required.',
        )
      }
      Alert('Enable wholesale for this farm', 'This will enable the farm for wholesale transactions', [
        { text: 'Cancel' },
        { text: 'Enable', onPress: () => updateToWholesale(item).then(() => refresh()) },
      ])
    },
  },
]

const updateMemberId = async (farm: Farm, memberId: string) => {
  Loader(true)
  try {
    await updateFarmProperty(farm.id, { memberId })
    if (!memberId) return Toast(`${farm.name} is no longer a COOP member.`)
    Toast(`${farm.name} is now a COOP member. (ID: ${memberId})`)
  } catch (error) {
    Logger.debug(error)
    Toast('Unable to update this farms COOP membership. Try again later.')
  } finally {
    Loader(false)
  }
}

const updateFeatureFlag = async (farm: Farm, flag: Features) => {
  Loader(true)
  try {
    await updateFarmProperty(farm.id, { enableFeatureFlag: flag })
    Toast(`${farm.name} now has the feature flag ${flag} enabled.`)
  } catch (error) {
    Toast(`Unable to update the feature flag ${flag}. Try again later.`)
  } finally {
    Loader(false)
  }
}

const updateStatus = async (farm: Farm) => {
  try {
    await updateFarmProperty(farm.id, {
      status: farm.status === FarmStatus.Inactive ? FarmStatus.Registered : FarmStatus.Inactive,
    })
    if (farm.status === FarmStatus.Inactive)
      return Toast(`${farm.name} has been added back in as a ${farm.previousStatus} farm.`)
    Toast(`${farm.name} has been removed from GrownBy listings.`)
  } catch (error) {
    Toast('Unable to change this farms status. Try again later.')
  }
}

const updateServiceFee = async (farm: Farm, appFeePercent: number) => {
  Loader(true)
  try {
    await updateFarmProperty(farm.id, { appFeePercent })
    Toast(`${farm.name} service fee has been updated to ${appFeePercent}.`)
  } catch (error) {
    Toast('Unable to update this farms service fee. Try again later.')
  } finally {
    Loader(false)
  }
}

const updateToWholesale = async (farm: Farm) => {
  Loader(true)
  try {
    await updateFarmProperty(farm.id, { isWholesale: true })
    Toast(`${farm.name} is now a wholesale farm.`)
  } catch (error) {
    Toast(`Unable to update ${farm.name} to wholesale. Try again later.`)
  } finally {
    Loader(false)
  }
}

const getApplicationFeeHelperText = (farm: Farm): string => {
  const isMember = typeof farm.memberId === 'string'
  const hasCustomFeeSet = farm.pricingModel?.appFeePercent !== undefined

  if (!hasCustomFeeSet) {
    return '(default)'
  } else if (isMember && hasCustomFeeSet) {
    return '(custom)'
  } else if (!isMember && hasCustomFeeSet) {
    return '(custom)'
  } else if (isMember) {
    return '(default member)'
  } else {
    return '(default)'
  }
}

/**
 * This screen allows Farm Generations Admin to view and manage farms
 * It provides a search functionality to find specific farms by name
 * and displays detailed information about each farm.
 */
export function FarmGenAdminFarm() {
  const [farmText, setUserText] = useState('')
  const debouncedQuery = useDebouncedValue(farmText)
  const farmsFx = useApiFx(searchFarmByName, [debouncedQuery], debouncedQuery.length > 3)

  return (
    <View>
      <HeaderText>Farms</HeaderText>
      <FormInput
        label={null}
        placeholder="Search by Farm Name"
        onChangeText={setUserText}
        rightIcon={debouncedQuery !== farmText ? <Spinner color={Colors.green} /> : <></>}
      />
      {farmsFx.loading ? (
        <Spinner color={Colors.green} />
      ) : (
        <FlatList
          data={farmsFx.data}
          renderItem={({ item }) => (
            <View style={styles.row}>
              <Image type="logo" style={styles.avatar} source={{ uri: item.logo }} />
              <View style={styles.innerContent}>
                <Text size={15} type="medium">
                  {item.name}
                  {!!item.memberId && <Tag color={Colors.purple} title={`MEMBER #: ${item.memberId}`} />}
                  {!!item.customProps?.ebt && <Tag color={Colors.gold} title="EBT LIVE" />}
                  {!!item.isWholesale && <Tag color={Colors.darkGreen} title="Wholesale" />}
                </Text>
                <Text>{formatAddressCityState(item.address)}</Text>
                <Text>{item.status.toUpperCase()}</Text>
                <Text color={Colors.gold} type="bold">
                  FEE {getApplicationFee(item)} % {getApplicationFeeHelperText(item)}
                </Text>
              </View>
              <View style={globalStyles.flexRowCenter}>
                {!!item.managers?.length && item.managers[0] && <LoginAsUser item={item.managers[0].user} />}
                <DropdownMenu buttons={generateButtons(item, farmsFx.refresh)} data={item}>
                  <Icon name="ellipsis-v" style={styles.icon} />
                </DropdownMenu>
              </View>
            </View>
          )}
          ListEmptyComponent={() =>
            debouncedQuery.length > 0 ? (
              <LoaderWithMessage loading={farmsFx.loading} icon="search" title="No Results">
                <Text>There are no farms matching this search.</Text>
              </LoaderWithMessage>
            ) : (
              <LoaderWithMessage loading={false} icon="search" title="No Results">
                <Text>Search for a farm by name to see actions that can be taken.</Text>
              </LoaderWithMessage>
            )
          }
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  avatar: {
    borderWidth: 2,
    borderColor: Colors.green,
    width: 60,
    height: 60,
    borderRadius: 40,
  },

  row: { flexDirection: 'row', alignItems: 'center', margin: 10 },
  innerContent: { margin: 5, marginLeft: 10, flex: 2 },
  icon: { padding: 5 },
})
