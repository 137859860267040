import { Institution, User } from '@models/User'

/** Returns the institution's businessName if available; otherwise, returns the user's full name */
export function getBusinessNameOrUsername(
  user: Pick<User, 'name'> & { institution: Pick<Institution, 'businessName'> | undefined },
): string {
  if (user.institution?.businessName) {
    return user.institution.businessName
  }

  return userName(user)
}

/** userName returns a user's full name. */
export function userName(user: Pick<User, 'name'>): string {
  const { firstName, lastName } = user.name
  return [firstName, lastName].filter((element) => element.length).join(' ')
}
