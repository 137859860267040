import { DateTime } from 'luxon'

import { keys } from '@helpers/typescript'
import { Farm } from './Farm'
import { Invoice } from './Invoice'
import { MoneyWithCurrency } from './Money'
import { PaymentSources } from './PaymentMethod'
import { User } from './User'

// Payout identifies the payout from the payment processor to the farm's bank account.
export type Payout = {
  id: string

  // Payout source type, identifies the source of the payout.
  source: PaymentSources

  // ref holds a reference to the originating Stripe payout object.
  ref: string

  // farm references the farm associated with the payout.
  farm: Pick<Farm, 'id'>

  /** The amount paid in the payout */
  amount: MoneyWithCurrency

  // The destination bank account number (last four digits)
  destinationAccount?: string

  // The expected date when the funds will arrive in the bank.
  arrivalDate?: DateTime

  // The reason for failure. This will be undefined if the payout was successful.
  failure?: FailureReason

  // The transactions associated with the payout.
  transactions: Transaction[]
}

// A Transaction identifies a line item transaction of a payout.
export type Transaction = {
  // The user associated with the charge.
  user?: Pick<User, 'id'>

  // The invoice associated with the transaction.
  invoice?: Pick<Invoice, 'id' | 'invoiceNum' | 'order'>

  // Details about the nature of the transaction.
  description: string

  /** The amount paid in the transaction. */
  amountPaid: MoneyWithCurrency

  // Any applicable fees related to the transaction.
  fees: TransactionFee[]

  // The transaction type only for stripe payouts.
  type?: 'charge' | 'refund'
}

// FeeType identifies the fees found in the system.
export type FeeType = 'platform' | 'processor' | 'tax'

/** Any fees related to a transaction. */
export type TransactionFee = {
  // The type of transaction.
  type: FeeType

  // The reason for the fee.
  description: string

  /** The amount charged */
  amount: MoneyWithCurrency
}

/** The possible payout failure states */
type FailureReason =
  | 'account_closed'
  | 'account_frozen'
  | 'bank_account_restricted'
  | 'bank_ownership_changed'
  | 'could_not_process'
  | 'debit_not_authorized'
  | 'declined'
  | 'insufficient_funds'
  | 'invalid_account_number'
  | 'incorrect_account_holder_name'
  | 'incorrect_account_holder_address'
  | 'incorrect_account_holder_tax_id'
  | 'invalid_currency'
  | 'no_account'
  | 'unsupported_card'

const failureReasonsHelperObj: Record<FailureReason, 1> = {
  account_closed: 1,
  account_frozen: 1,
  bank_account_restricted: 1,
  bank_ownership_changed: 1,
  could_not_process: 1,
  debit_not_authorized: 1,
  declined: 1,
  insufficient_funds: 1,
  invalid_account_number: 1,
  incorrect_account_holder_name: 1,
  incorrect_account_holder_address: 1,
  incorrect_account_holder_tax_id: 1,
  invalid_currency: 1,
  no_account: 1,
  unsupported_card: 1,
}

/** The complete list of failure reasons as constants that can be used for validation */
export const failureReasons: FailureReason[] = keys(failureReasonsHelperObj)
