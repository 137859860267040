import { Address } from '@models/Address'
import { CurrencyCode, Money, MoneyWithCurrency } from '@models/Money'
import DecimalCalc from './decimal'
import { hasOwnProperty, isObject } from './helpers'
import { findCountryData } from './international/types'

/** Will perform computations on money */
export class MoneyCalc {
  static checkCompatible(a: Money, b: Money) {
    const isDiffCurrency = hasCurrency(a) && hasCurrency(b) && a.currency !== b.currency

    // If one value is zero or Infinity the operation can still be completed regardless of the currencies
    const hasZero = a.value === 0 || b.value === 0

    const hasInfinity = a.value === Infinity || b.value === Infinity

    if (isDiffCurrency && !hasZero && !hasInfinity) {
      throw new Error(`${a.currency} is not compatible with ${b.currency}`)
    }
  }

  /** Operations */

  /** Returns the sum of all monies passed */
  static add(value1: MoneyWithCurrency, value2: Money): MoneyWithCurrency
  static add(value1: Money, value2: MoneyWithCurrency): MoneyWithCurrency
  static add(...values: MoneyWithCurrency[]): MoneyWithCurrency
  static add(...values: Money[]): Money
  static add(...values: Money[]): Money {
    return values.reduce(
      (prev, curr): Money => {
        this.checkCompatible(prev, curr)

        return {
          value: prev.value + curr.value,
          currency: prev.currency ?? curr.currency,
        }
      },
      { value: 0, currency: undefined },
    )
  }

  static subtract<T extends Money | MoneyWithCurrency>(lhs: T, rhs: Money): T {
    MoneyCalc.checkCompatible(lhs, rhs)

    return {
      value: DecimalCalc.subtract(lhs.value, rhs.value),
      currency: lhs.currency || rhs.currency,
    } as T
  }

  static multiply<T extends Money | MoneyWithCurrency>(a: T, b: number): T {
    if (Number.isNaN(b)) {
      throw new Error(`${b} is not a number`)
    }

    return {
      value: DecimalCalc.multiply(a.value, b),
      currency: a.currency,
    } as T
  }

  static divide<T extends Money | MoneyWithCurrency>(a: T, b: number): T {
    if (Number.isNaN(b)) {
      throw new Error(`${b} is not a number`)
    }

    return {
      value: DecimalCalc.divide(a.value, b),
      currency: a.currency,
    } as T
  }

  // Comparisons
  static isEqual(a: Money, b: Money) {
    MoneyCalc.checkCompatible(a, b)

    return a.value === b.value
  }

  static isGreaterThan(a: Money, b: Money) {
    MoneyCalc.checkCompatible(a, b)

    return a.value > b.value
  }

  static isGTZero(a: Money) {
    return MoneyCalc.isGreaterThan(a, { value: 0 })
  }

  static isLessThan(a: Money, b: Money) {
    MoneyCalc.checkCompatible(a, b)

    return a.value < b.value
  }

  static isGTE(a: Money, b: Money) {
    MoneyCalc.checkCompatible(a, b)

    return MoneyCalc.isGreaterThan(a, b) || MoneyCalc.isEqual(a, b)
  }

  static isLTE(a: Money, b: Money) {
    MoneyCalc.checkCompatible(a, b)

    return MoneyCalc.isLessThan(a, b) || MoneyCalc.isEqual(a, b)
  }

  // Other
  /** If both values have currency the result will also have currency */
  static max(a: MoneyWithCurrency, b: MoneyWithCurrency): MoneyWithCurrency
  /** If both values are simple Money the result will also be a simple Money */
  static max(a: Money, b: Money): Money
  static max(a: Money | MoneyWithCurrency, b: Money | MoneyWithCurrency): Money | MoneyWithCurrency {
    MoneyCalc.checkCompatible(a, b)

    return MoneyCalc.isGTE(a, b) ? a : b
  }

  /** If both values have currency the result will also have currency */
  static min(a: MoneyWithCurrency, b: MoneyWithCurrency): MoneyWithCurrency
  /** If both values are simple Money the result will also be a simple Money */
  static min(a: Money, b: Money): Money
  static min(a: Money | MoneyWithCurrency, b: Money | MoneyWithCurrency): Money | MoneyWithCurrency {
    MoneyCalc.checkCompatible(a, b)

    return MoneyCalc.isLTE(a, b) ? a : b
  }

  static isZero(a: Money) {
    return a.value === 0
  }

  static isInfinite(a: Money) {
    return a.value === Infinity
  }

  /** When Currency is provided it'll return a money with currency */
  static fromCents(cents: number, currency: CurrencyCode): MoneyWithCurrency
  /** when Currency is undefined it will return a simple Money object */
  static fromCents(cents: number, currency?: CurrencyCode | undefined): Money
  static fromCents(cents: number, currency?: CurrencyCode): Money | MoneyWithCurrency {
    return { value: cents, currency }
  }

  static cents(a: Money): number {
    return Math.round(a.value)
  }

  /** When Currency is provided it'll return a money with currency */
  static fromString(amount: string, currency: CurrencyCode): MoneyWithCurrency
  /** when Currency is undefined it will return a simple Money object */
  static fromString(amount: string, currency?: CurrencyCode | undefined): Money
  /**
   * Converts the given string representation of an amount into a Money object.
   * @param amount The string representation of the amount.
   * @param currency The currency code (optional).
   * @returns The Money object representing the amount and currency.
   */
  static fromString(amount: string, currency?: CurrencyCode): Money | MoneyWithCurrency {
    // Check for negative numbers, denoted by '-' at the start of the string
    const isNegative = amount.startsWith('-')
    // Remove any non-numeric and non-decimal characters (this will also remove '$')
    const sanitizedAmount = amount.replace(/[^0-9.]/g, '')

    // Parse the string to float and convert to cents
    const centAmount = Math.round(parseFloat(sanitizedAmount) * 100)

    // Account for negative numbers by multiplying by -1 if needed
    const finalCentAmount = isNegative ? centAmount * -1 : centAmount

    if (isNaN(finalCentAmount)) throw new Error(`${amount} is not a valid amount`)

    return MoneyCalc.fromCents(finalCentAmount, currency)
  }

  /**
   * Converts a Money object to a string in the format '10.00'
   * All amounts are rounded to the nearest cent
   * @param amount The amount to convert to string
   */
  static toString(amount: Money): string {
    const rounded = MoneyCalc.round(amount)
    return MoneyCalc.divide(rounded, 100).value.toFixed(2)
  }

  /**
   * Rounds a Money amount to the nearest cent
   * @param amt the money object to round
   */
  static round<T extends Money | MoneyWithCurrency>(amt: T): T {
    return { ...amt, value: Math.round(amt.value) }
  }

  /**
   * Allows any numerical operation on the money value. Example: MoneyCalc.round(money)
   * @param mathOp the operation to perform
   * @param a the money object to perform the operation on
   */
  static math<T extends Money | MoneyWithCurrency>(mathOp: (x: number) => number, a: T): T {
    return { ...a, value: mathOp(a.value) }
  }
}

/** makeMoney returns a Money structure. */
export const makeMoney = MoneyCalc.fromCents

export function getZero(currency: CurrencyCode): MoneyWithCurrency
export function getZero(currency?: CurrencyCode | undefined): Money
/** Returns a Money object with value zero in the specified currency */
export function getZero(currency?: CurrencyCode): Money | MoneyWithCurrency {
  return MoneyCalc.fromCents(0, currency)
}

/** Zero identifies a zero value for the money type, in no particular currency. With undefined currency the Zero value should be compatible with any currency */
export const Zero = MoneyCalc.fromCents(0)

export function withCurrency(money: Money, currency: CurrencyCode): MoneyWithCurrency
export function withCurrency(money: Money, currency: CurrencyCode | undefined): Money
/** Returns a new money object with the specified currency */
export function withCurrency(money: Money, currency: CurrencyCode | undefined): Money | MoneyWithCurrency {
  return { value: money.value, currency }
}

/** Identifies a Money object that has a defined currency */
export function hasCurrency(money: Money): money is MoneyWithCurrency {
  return isMoney(money) && hasOwnProperty(money, 'currency') && !!money.currency
}

/** Identifies a value as a Money object */
export function isMoney(money: any): money is Money {
  if (!isObject(money) || !hasOwnProperty(money, 'value')) return false

  const keys = Object.keys(money)
  return keys.every((key) => ['value', 'currency'].includes(key))
}

/** Infinite identifies an infinite amount of money */
export const Infinite = MoneyCalc.fromCents(Infinity)

/** Returns a farm's currency based on its address country */
export function getCurrency(farm: { address: Address }): CurrencyCode {
  const data = findCountryData(farm.address.country)
  if (!data) throw new Error('Invalid country')
  return data.currency
}
