import { MoneyCalc } from '@helpers/money'
import { CurrencyCode, Money, MoneyWithCurrency } from '@models/Money'
import { useCallback } from 'react'
import { InputProps } from 'react-native-elements'
import { MaskedInput } from './MaskedInput'
import Masks from './Masks'

/**
 * @param useCustomInput Specifies if the mask should use the CustomInput component instead of defalt Input
 */
export type MoneyInputProps<Currency extends CurrencyCode | undefined = undefined> = Omit<
  InputProps,
  'onChangeText' | 'value'
> & {
  value?: Money
  /** The type of the value will depend on the currency. If currency is defined the value will be a MoneyWithCurrency. If currency undefined, value will be Money */
  onChangeText?: (value?: Currency extends undefined ? Money : MoneyWithCurrency) => void
  /** If currency is provided it will be assigned to the resulting Money object */
  currency?: Currency
}

/** MoneyInput is a wrapper around our input component that will format the UI to display it as a $ amount instead of cents */
export function MoneyInput<Currency extends CurrencyCode | undefined = undefined>({
  value,
  onChangeText,
  currency,
  label,
  ...props
}: MoneyInputProps<Currency>) {
  // Convert the money to a string or leave blank if undefined
  const valueMasked = value ? MoneyCalc.cents(value).toString() : undefined

  // Convert the string value back to a money object to be returned
  const onChangeTextMask = useCallback(
    (_: string, unmasked: string) => {
      const numVal = parseInt(unmasked, 10)
      if (onChangeText) {
        // If it is not a number than set the input to empty, or if it is 00 meaning empty. If it is truly a 0 then the string will just be 0, so we can safely treat 00 as an empty input, and clear it.
        if (isNaN(numVal) || unmasked === '00') {
          return onChangeText()
        }
        //@ts-expect-error // the currency will be assigned if defined. Otherwise this will create a Money object with no currency
        onChangeText(MoneyCalc.fromCents(numVal, currency || value.currency))
      }
    },
    [onChangeText, currency, value?.currency],
  )

  return (
    <MaskedInput
      mask={Masks.MONEY_MASK(valueMasked?.length ?? 0)}
      value={valueMasked ?? ''}
      onChangeText={onChangeTextMask}
      // This prevents the placeholder from becoming the label unless we explicitly pass a label in
      label={label ?? null}
      placeholder="$0.00"
      keyboardType="decimal-pad"
      {...props}
    />
  )
}
