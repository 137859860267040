import { useSnapshot } from '@/hooks/useSnapshot'
import { snapshotChatMessages } from '@api/Messaging'
import { DEFAULT_MSG_LIMIT } from '@helpers/messaging'
import { ChatSession } from '@models/Messaging'
import { useCallback, useState } from 'react'

/** Data layer hook that is handling the fetching of messages*/
export function useMessagesApi({
  chatSessionId,
  canLoadData,
}: {
  chatSessionId?: ChatSession['id']
  canLoadData: boolean
}) {
  const [msgLimit, setMsgLimit] = useState(DEFAULT_MSG_LIMIT)

  const messagesSnap = useSnapshot(
    'snapshotChatMessages',
    snapshotChatMessages,
    [chatSessionId!, msgLimit],
    !!chatSessionId && canLoadData,
  )

  const onIncreaseMessageLimit = useCallback(() => {
    if (messagesSnap.data?.length && messagesSnap.data?.length < msgLimit) return

    setMsgLimit((c) => c + DEFAULT_MSG_LIMIT)
  }, [messagesSnap.data?.length, msgLimit])

  return {
    messagesSnap,
    onIncreaseMessageLimit,
  }
}
