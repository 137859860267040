import { FortisEvent, FortisResponse } from './types'

/** Will convert a fortis event into a usable response */
export function parseResponse(response: FortisEvent): FortisResponse {
  if (response['@type'] === 'error') {
    //TODO: Should have better error logging here
    return {
      success: false,
      error: 'There was an error handling this request, please check your connection and try again.',
    }
  }
  if (response['@type'] === 'validationError') {
    return {
      success: false,
      error: 'Please check your information and re-submit',
    }
  }
  // This will only happen if the customer is waiting on the actual payment details page for more than 15 minutes
  if (response['@type'] === 'tokenExpired') {
    return {
      success: false,
      error: 'The payment request has expired, please refresh the page or leave the screen and come back to try again.',
    }
  }
  return { success: true, data: response.data }
}
