import { Alert } from '@elements'
import { locationBuilder } from '@helpers/builders'
import { dequal } from '@helpers/customDequal'
import { PartialExcept, PartialPick, pick } from '@helpers/typescript'
import { LocalPickup, NonPickup } from '@models/Location'

export enum ReturnStates {
  GO_BACK,
  DO_NOTHING,
  SAVE,
}

type ReturnTypes<T> =
  | {
      status: ReturnStates.GO_BACK | ReturnStates.DO_NOTHING
    }
  | {
      status: ReturnStates.SAVE
      data: T
    }

/**
 * Will check that there are updates and if anything important is being changed.
 * @param oldLoc the old location to compare against
 * @param newLoc the new location to compare to
 * @return value a promise containing the data that was updated
 */
export function validateLocationEdit(
  oldLoc: LocalPickup,
  newLoc: LocalPickup,
): Promise<ReturnTypes<PartialExcept<LocalPickup, 'id'>>> {
  return new Promise((resolve) => {
    locationBuilder.validate(newLoc)

    const editableFields = ['address', 'name', 'abbreviation', 'type', 'timezone'] as const

    // Select the values that could've been updated to compare with
    const newData = pick(newLoc, ...editableFields)

    // If we are editing the get the old values to compare with
    const oldData = pick(oldLoc, ...editableFields)

    // Check that we actually made a change
    if (dequal(newData, oldData)) {
      return resolve({ status: ReturnStates.GO_BACK })
    }

    // If we update the address warn the farmer to contact customers
    if (!dequal(newData.address, oldData.address)) {
      Alert(
        'Warning! Important Change',
        'You are changing your locations address, this will affect all past pickups as well as future orders. GrownBy will not contact your customers, so be sure to communicate clearly the change with them.',
        [
          {
            text: 'Proceed',
            style: 'destructive',
            onPress: () => resolve({ status: ReturnStates.SAVE, data: { ...newData, id: oldLoc.id } }),
          },
          {
            text: 'Cancel',
            style: 'cancel',
            onPress: () => resolve({ status: ReturnStates.DO_NOTHING }),
          },
        ],
      )
    } else {
      resolve({ status: ReturnStates.SAVE, data: { ...newData, id: oldLoc.id } })
    }
  })
}

/** The fields allowed to change for a location */
const editableFields = [
  'address',
  'name',
  'abbreviation',
  'type',
  'timezone',
  'cost',
  'regions',
  'feeWaiveOption',
] as const

/**
 * Will check that there are updates and if anything important is being changed.
 *
 * @param oldLoc the old shipping or delivery to compare against
 * @param newLoc the new shipping or delivery to compare to
 *
 * @return value a promise containing the data that was updated and is considered editable
 */
export function validateDeliveryShippingEdit(
  oldLoc: NonPickup,
  newLoc: NonPickup,
): ReturnTypes<PartialPick<NonPickup, 'id'>> {
  // Validate the new location. Throws if invalid
  locationBuilder.validate(newLoc)

  // Select the editable values to compare the old and new data
  const newData = pick(newLoc, ...editableFields)
  const oldData = pick(oldLoc, ...editableFields)

  // Check that we actually made a change
  if (dequal(newData, oldData)) {
    return { status: ReturnStates.GO_BACK }
  }

  return { status: ReturnStates.SAVE, data: { ...newData, id: oldLoc.id } }
}
