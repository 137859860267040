import { Text } from '@elements'
import { DayOfWeek, Exception } from '@models/Schedule'
import React, { memo } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import Colors from '@/constants/Colors'
import { getDistributionPickups } from '@helpers/order'
import { Distribution } from '@models/Distribution'
import {
  addException,
  getDailyValues,
  removeException,
} from '../DistributionDetailScreen/DistributionDetailScreen.helpers'

export type WeekDaySelectorProps = {
  distro?: Distribution
  setFieldValue: (field: string, newValue: Exception[]) => void
  exceptions: Exception[]
}

/** Component used to select the weekdays for a schedule. */
export const WeekdaySelector = memo(function WeekdaySelector({
  distro,
  exceptions,
  setFieldValue,
}: WeekDaySelectorProps) {
  const pickupDates = distro ? getDistributionPickups(distro) : []
  const values = getDailyValues(exceptions)

  const onItemPress = (v: { key: DayOfWeek; dayName: string; isSelected: boolean }) => {
    if (v.isSelected) {
      removeException(setFieldValue, exceptions, { dayOfWeek: v.key })
    } else {
      addException(pickupDates, setFieldValue, exceptions, { dayOfWeek: v.key })
    }
  }

  return (
    <View style={styles.main}>
      {values.map((v) => (
        <TouchableOpacity
          key={v.key}
          style={[styles.weekdayCircle, v.isSelected && styles.weekDayCircleSelected]}
          onPress={() => onItemPress(v)}
        >
          <Text>{v.dayName.slice(0, 2)}</Text>
        </TouchableOpacity>
      ))}
    </View>
  )
})

const styles = StyleSheet.create({
  main: {
    flexDirection: 'row',
    gap: 8,
    marginVertical: 10,
  },
  weekdayCircle: {
    aspectRatio: 1,
    maxWidth: 50,
    flex: 1,
    borderRadius: 50,
    backgroundColor: Colors.green,
    alignItems: 'center',
    justifyContent: 'center',
  },
  weekDayCircleSelected: {
    borderWidth: 1,
    borderColor: Colors.blue,
    backgroundColor: Colors.white,
  },
})
