import { ExpandableRow } from '@/admin/components/OfflineTable/ExpandableRow'
import { OfflineTable, OfflineTableProps } from '@/admin/components/OfflineTable/OfflineTable'
import { ActionsMenuComponent } from '@/admin/components/elements/ActionsMenuComponent'
import { LoaderWithMessage, ToolTips } from '@/components'
import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { useSnapshot } from '@/hooks/useSnapshot'
import { adminFarmIdSelector } from '@/redux/selectors'
import { snapshotProductFee } from '@api/ProductFees'
import { Button, Modal, Text, TextH2, ToggleButton, Tooltip } from '@elements'
import { formatProductFeeRate } from '@helpers/productFee'
import { FeeType, ProductFee } from '@models/ProductFee'
import { memo, useCallback, useMemo, useState } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { sharedStyles } from '../styles'
import { TabScreen } from '../types'
import { ProductFeeReportPopup } from './ProductFeeReportPopup'
import { addEditProductFees, getActions } from './helpers'

/** Taxes and Flat fees related info tab */
export const ProductFeesTab = memo(function ProductFeesTab({ isVisible }: TabScreen) {
  const farmId = useSelector(adminFarmIdSelector)
  const productFees = useSnapshot('snapshotProductFee', snapshotProductFee, [farmId!], !!farmId)
  const [showArchived, setShowArchived] = useState(false)
  const styles = useStyles()

  const visibleProductFee = useMemo(
    () => (productFees.data ?? []).filter((item) => (showArchived ? true : !item.archived)),
    [productFees.data, showArchived],
  )

  const renderItem = useCallback<NonNullable<OfflineTableProps<ProductFee>['renderItem']>>(
    ({ item, index }) => (
      <ExpandableRow<ProductFee>
        item={item}
        index={index}
        key={item.id}
        rowContainerStyle={item.archived ? styles.rowContainer : {}}
        columns={[
          {
            process: (item) => (
              <Text color={Colors.blue} onPress={() => addEditProductFees(item)}>
                {item.name}
              </Text>
            ),
          },
          { process: (item) => (item.type === FeeType.Tax ? 'Tax' : 'Flat fee') },
          { process: (item) => formatProductFeeRate(item) },
          {
            process: (item) => <ActionsMenuComponent<ProductFee> data={item} buttons={getActions(item)} />,
            widthFlex: 0.5,
          },
        ]}
      />
    ),
    [styles.rowContainer],
  )

  if (!isVisible) {
    return <View />
  }

  return (
    <View>
      <View style={sharedStyles.tabHeaderCont}>
        <View style={globalStyles.flexRowCenter}>
          <TextH2 size={16}>Taxes & Fees</TextH2>
          <Tooltip id={ToolTips.TAXES_AND_FEES_TAB} />
        </View>
        <View style={styles.tabHeaderBtsCont}>
          <Button
            icon="calendar"
            title="Export CSV"
            outline
            onPress={() => Modal(<ProductFeeReportPopup />, { title: 'Download a .csv report' })}
          />
          <Button title="Add New" onPress={() => addEditProductFees()} />
        </View>
      </View>
      <ToggleButton title="Show Archived" value={showArchived} onChange={setShowArchived} style={styles.toggle} />
      <OfflineTable
        containerStyle={styles.offlineTableContainer}
        headerColumns={[{ title: 'Name' }, { title: 'Type' }, { title: 'Rate' }, { title: 'Actions', widthFlex: 0.5 }]}
        minWidth={500}
        renderItem={renderItem}
        data={visibleProductFee}
        isLoading={productFees.loading}
        ListEmptyComponent={
          <LoaderWithMessage loading={productFees.loading} icon="dollar-sign" title="No taxes or flat fees added yet">
            <Button title="Add new" onPress={() => addEditProductFees()} />
          </LoaderWithMessage>
        }
      />
    </View>
  )
})

const useStyles = () =>
  useLayoutFnStyles(({ isSmallDevice, isExtraSmallDevice, height }) => {
    return {
      offlineTableContainer: {
        /** If it is not smallDevice or not extraSmallDevice, it means productFee table is enabled to have nested scroll, so the maxHeight should be set to height * 0.635 (depend on the table size and best view) to help to achieve scroll functionality. Otherwise, the maxHeight should be auto adjusted to correct height of table to be able to show all rows from scrolling entire screen if need.
         * Some case 'auto' maxHeight is not working, so we have to set maxHeight to 100% to make it work.
         */
        maxHeight: !isSmallDevice ? height * 0.635 : '100%',
        borderWidth: 1,
      },
      tabHeaderBtsCont: { flexDirection: 'row', alignItems: 'flex-end' },
      toggle: {
        justifyContent: 'flex-end',
        marginTop: isExtraSmallDevice ? 10 : 0,
      },
      rowContainer: { backgroundColor: Colors.shades[100] },
    }
  })
