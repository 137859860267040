import { memo } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import IconWithBadge from './elements/Icon/IconWithBadge'

import { useCartService } from '@/hooks/useCart'
import { getFarmCartItems } from '@models/Cart'
import { useSelector } from 'react-redux'
import { wholesaleSelector } from '../redux/selectors'

type ShoppingCartProps = {
  color?: string
  style?: StyleProp<ViewStyle>
  testID?: string
}

export const ShoppingCart = memo(function ShoppingCart({ color, style, testID }: ShoppingCartProps) {
  const { cart } = useCartService()
  const { isWholesale } = useSelector(wholesaleSelector)

  return (
    <IconWithBadge
      style={style}
      size={22}
      name="shopping-cart"
      badgeValue={getFarmCartItems({ items: cart, isWholesale }).length}
      url="/farms/shop/my-cart"
      color={color}
      testID={testID}
    />
  )
})
