import { addUserAddress, updateUserAddress } from '@api/UserAddress'
import {
  CheckBox,
  ErrorText,
  FormButton,
  FormStatePicker,
  FormZipcodeInput,
  KeyboardAvoidingScrollView,
  hideModal,
} from '@elements'
import { UserAddress } from '@models/UserAddress'
import { Formik, FormikProps } from 'formik'
import * as React from 'react'
import { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { Input } from 'react-native-elements'
import { useSelector } from 'react-redux'
import * as yup from 'yup'

import { FormBuilder } from '../../components/FormBuilder'
import Colors from '../../constants/Colors'
import { internationalSelector, userSelector } from '../../redux/selectors'

import { Logger } from '@/config/logger'
import { addressSchemaForm } from '@helpers/builders/AddressBuilder'

type FormType = Omit<UserAddress, 'id' | 'coordinate'>

const schema: yup.ObjectSchema<FormType> = addressSchemaForm.shape({
  notes: yup.string(),
  isDefault: yup.bool(),
  deleted: yup.bool(),
})

export default function AddAddress({
  address,
  onAdded,
  addresses,
}: {
  address?: UserAddress
  onAdded?: (address: UserAddress) => void
  /** The current user addresses*/ addresses?: UserAddress[]
}) {
  const [error, setError] = useState('')
  const userId = useSelector(userSelector).id
  const { country } = useSelector(internationalSelector)

  const initialValues: FormType = address || {
    street1: '',
    street2: '',
    city: '',
    state: '',
    zipcode: '',
    country,
    isDefault: !addresses?.find((itm) => itm.isDefault),
  }

  const onSubmitHandler = async (values: FormType) => {
    if (error) setError('')

    const newAddress: Omit<UserAddress, 'coordinate'> = {
      ...values,
      id: address?.id || '',
      isDefault: values.isDefault || !addresses?.find((itm) => itm.isDefault),
    }

    try {
      if (address?.id) {
        // updating an address
        const resAddress = await updateUserAddress(userId, newAddress)
        onAdded?.(resAddress)
      } else {
        // adding a new address
        const resAddress = await addUserAddress(userId, newAddress)
        onAdded?.(resAddress)
      }
      hideModal()
    } catch (e) {
      Logger.warn(e)
      setError('Address is invalid. Please double-check and try again')
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmitHandler} validationSchema={schema}>
      {({
        handleChange,
        values,
        errors,
        touched,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }: FormikProps<FormType>) => (
        <KeyboardAvoidingScrollView contentContainerStyle={styles.container}>
          <FormBuilder>
            <Input
              onChangeText={handleChange('street1')}
              onBlur={handleBlur('street1')}
              value={values.street1}
              placeholder="Street Address 1"
              errorMessage={touched.street1 ? errors.street1 : ''}
            />
            <Input
              onChangeText={handleChange('street2')}
              onBlur={handleBlur('street2')}
              value={values.street2}
              placeholder="Street Address 2"
              errorMessage={touched.street2 ? errors.street2 : ''}
            />
            <FormBuilder row>
              <Input
                onChangeText={handleChange('city')}
                onBlur={handleBlur('city')}
                value={values.city}
                placeholder="City"
              />
              <FormStatePicker
                country={values.country}
                errorMessage={touched.state ? errors.state : ''}
                value={values.state}
                onValueChange={handleChange('state')}
                onBlur={handleBlur('state')}
              />
              <FormZipcodeInput
                country={values.country}
                value={values.zipcode}
                onChangeText={handleChange('zipcode')}
                onBlur={handleBlur('zipcode')}
              />
            </FormBuilder>
            {touched.city && errors.city ? <ErrorText>{errors.city}</ErrorText> : <></>}
            {touched.state && errors.state ? <ErrorText>{errors.state}</ErrorText> : <></>}
            {touched.zipcode && errors.zipcode ? <ErrorText>{errors.zipcode}</ErrorText> : <></>}
            <Input
              multiline
              numberOfLines={3}
              label="Delivery Instructions"
              value={values.notes}
              onChangeText={handleChange('notes')}
              onBlur={handleBlur('notes')}
              errorMessage={touched.notes ? errors.notes : ''}
            />
            <View style={{ margin: 15 }}>
              {/*Don't allow users to remove a default address without adding a different one */}
              {!address?.isDefault && (
                <CheckBox
                  checked={!!values.isDefault}
                  onChange={(val) => setFieldValue('isDefault', val)}
                  title="Set as Default"
                />
              )}
            </View>
          </FormBuilder>
          <FormButton loading={isSubmitting} title="Save Address" onPress={handleSubmit} />
          {!!error && <ErrorText>{error}</ErrorText>}
        </KeyboardAvoidingScrollView>
      )}
    </Formik>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 20,
  },
  maskedInput: {
    fontSize: 20,
    margin: 10,
    paddingBottom: 5,
    borderBottomWidth: 1,
    borderBottomColor: Colors.shades['300'],
  },
  error: { color: Colors.red, marginBottom: 5 },
})
