import { addPromoCode, updatePromoCode } from '@api/Coupons'
import {
  CheckBox,
  DateTimePickerForm,
  Divider,
  ErrorText,
  FormButton,
  FormInput,
  FormNumberInput,
  KeyboardAvoidingScrollView,
  MoneyInput,
  Text,
  TextH2,
  Toast,
  UniversalTag,
  hideModal,
} from '@elements'
import { YUP_MONEY_OPTIONAL, YUP_WHOLE_NUMBER_OPTIONAL_REAL } from '@helpers/Yup'
import { errorToString } from '@helpers/helpers'
import { pick } from '@helpers/typescript'
import { Coupon, PromoCode } from '@models/Coupon'
import { Timezone } from '@models/Timezone'
import { Formik, FormikProps } from 'formik'
import { DateTime } from 'luxon'
import * as React from 'react'
import { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import { User } from '@models/User'
import { Logger } from '../../../config/logger'
import Colors from '../../../constants/Colors'
import { adminCurrencySelector, adminFarmIdSelector } from '../../../redux/selectors'
import { AdminUserAutoComplete } from '../../components/AdminUserAutocomplete'

type FormType = Omit<PromoCode, 'id' | 'coupon' | 'timesRedeemed'>

export const promoValidation: Yup.ObjectSchema<FormType> = Yup.object()
  .shape({
    code: Yup.string()
      .trim()
      .label('Code')
      .matches(/^[A-Z0-9]+$/, 'Promo codes can only contain upper case letters and numbers.')
      .defined(),
    orderMinimum: YUP_MONEY_OPTIONAL('Order Minimum', { allowZero: true, requireCurrency: true }),
    maxRedemptions: YUP_WHOLE_NUMBER_OPTIONAL_REAL('Max Redemptions', { allowZero: true }),
    expiration: Yup.mixed<DateTime>().label('Expiration Date'),
    customers: Yup.array()
      .of<Pick<User, 'id' | 'email'>>(
        Yup.object({
          id: Yup.string().defined(),
          email: Yup.string().defined(),
        }).defined(),
      )
      .optional(),
    oncePerCustomer: Yup.boolean().defined().label('Once Per Customer'),
  })
  .defined()

export function AddEditPromo({
  promo,
  coupon,
  timezone,
  onAdded,
}: {
  promo?: PromoCode
  coupon: Coupon
  timezone: Timezone
  onAdded?: (promo: PromoCode) => void
}) {
  const [error, setError] = useState('')
  const farmId = useSelector(adminFarmIdSelector)
  // If the promo exists then we are editing, otherwise we are adding
  const isEdit = !!promo?.id

  const initialValues: FormType = promo ?? {
    code: '',
    oncePerCustomer: false,
  }

  const onSubmitHandler = async (values: FormType) => {
    if (error) setError('')
    if (!farmId) return setError('Could not load your farm, please reload and try again.')

    const newPromo: PromoCode = {
      ...values,
      id: promo?.id || '',
      coupon,
      timesRedeemed: promo?.timesRedeemed ?? 0,
    }

    try {
      if (promo?.id) {
        // updating a coupon
        await updatePromoCode(newPromo)
        Toast('This promo code has been updated successfully')
        onAdded?.(newPromo)
      } else {
        // adding a new promo code
        const addedPromo = await addPromoCode(newPromo, coupon)
        Toast('This promo code has been added successfully')
        onAdded?.(addedPromo)
      }
      hideModal()
    } catch (e) {
      Logger.warn(e)
      setError(`Unable to add promo code: ${errorToString(e)} `)
    }
  }

  const currency = useSelector(adminCurrencySelector)

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmitHandler} validationSchema={promoValidation}>
      {({ values, errors, touched, handleBlur, handleSubmit, setFieldValue, isSubmitting }: FormikProps<FormType>) => (
        <KeyboardAvoidingScrollView contentContainerStyle={styles.scroll}>
          <FormInput
            disabled={isEdit}
            onChangeText={(value) => setFieldValue('code', value.trim().toUpperCase())}
            onBlur={handleBlur('code')}
            value={values.code}
            placeholder="Promo Code"
            errorMessage={touched.code ? errors.code : ''}
          />
          {isEdit && (
            // Extra view to prevent styling from being overwritten from FormBuilder
            <View style={styles.horizontal10}>
              <ErrorText>Promo Code cannot be changed after it is created.</ErrorText>
            </View>
          )}
          <Divider clear />
          <TextH2> Optional Promo Code Rules</TextH2>
          <Text style={styles.margin}>
            The below options are all optional and can provide more customization for how this coupon can be used
          </Text>
          <Divider />
          <CheckBox
            style={styles.margin}
            checked={values.oncePerCustomer}
            onChange={(val) => setFieldValue('oncePerCustomer', val)}
            title="Only allow one redemption per customer"
          />
          <MoneyInput
            label="Order minimum (before fees)"
            maxLength={11}
            value={values.orderMinimum}
            onChangeText={(val) => setFieldValue('orderMinimum', val)}
            onBlur={handleBlur('orderMinimum')}
            errorMessage={touched.orderMinimum ? errors.orderMinimum : ''}
            currency={currency}
          />
          <FormNumberInput
            label="Max redemptions"
            value={values.maxRedemptions}
            onChangeText={(val) => setFieldValue('maxRedemptions', val)}
            onBlur={handleBlur('maxRedemptions')}
            errorMessage={touched.maxRedemptions ? errors.maxRedemptions : ''}
          />
          <View>
            <View style={styles.margin}>
              <Text type="medium" color={Colors.shades[500]}>
                Expiration Date
              </Text>
              <Text size={10}>Orders must be placed on or by this date</Text>
            </View>
            <DateTimePickerForm
              containerStyle={styles.dateContainer}
              onChange={(item) => setFieldValue('expiration', item)}
              value={values.expiration}
              timezone={timezone}
            />
            {touched.expiration && !!errors.expiration && <ErrorText>{errors.expiration}</ErrorText>}
          </View>
          <View style={styles.horizontal10}>
            <Text type="medium" color={Colors.shades[500]} style={styles.limitUsersText}>
              Limit to Certain Users
            </Text>
            <AdminUserAutoComplete
              inline
              onSelect={(cust) => {
                const newCust = pick(cust, 'id', 'email')

                if (values.customers?.find((existing) => existing.id === newCust.id)) {
                  return Toast('This customer has already been added')
                }
                setFieldValue('customers', [...(values.customers ?? []), newCust])
              }}
            />
            {!!values.customers && values.customers.length > 0 && (
              <View style={styles.customersListCont}>
                {values.customers.map((cust) => (
                  <UniversalTag
                    key={cust.id}
                    label={cust.email}
                    onPress={() => {
                      const newCusts = values.customers?.filter((user) => user.id !== cust.id)
                      setFieldValue('customers', newCusts)
                    }}
                  />
                ))}
              </View>
            )}
            {touched.customers && !!errors.customers && <ErrorText>{errors.customers.toString()}</ErrorText>}
          </View>
          <FormButton loading={isSubmitting} title="Save Promo Code" onPress={handleSubmit} />
          {!!error && <ErrorText>{error}</ErrorText>}
        </KeyboardAvoidingScrollView>
      )}
    </Formik>
  )
}

const styles = StyleSheet.create({
  margin: {
    margin: 10,
  },
  scroll: {
    margin: 10,
    /** This paddingBottom is actually required for the user autocomplete to expand correctly when focused inside the modal portal in mobile (tested in android in this writing). It appears the keyboardavoidingscrollview will calculate the height before the autocomplete input expands in inline mode. Therefore it doesn't allow you to scroll correctly after it focuses. Having an extra bottom padding guarantees there will be enough extra space for the keyboardavoidingscrollview to show the inline results, even if the height is measured before inline results are shown */
    paddingBottom: 200,
  },
  error: { color: Colors.red, marginBottom: 5 },
  customersListCont: {
    alignItems: 'flex-start',
  },
  dateContainer: {
    marginLeft: 10,
    maxWidth: 200,
  },
  limitUsersText: {
    marginTop: 10,
    fontSize: 14,
  },
  horizontal10: {
    marginHorizontal: 10,
  },
})
