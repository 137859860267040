import { CustomShare } from '@models/CustomShare'
import { AdminCard } from '../../components/AdminCard'
import { Divider, Text } from '@elements'
import { View } from 'react-native'
import { formatMoney } from '@helpers/display'
import React from 'react'

/** Card to show the products that have a custom ranking in the share */
export function PrimarySharesCard({ primaryShares }: Pick<CustomShare, 'primaryShares'>) {
  return (
    <AdminCard>
      <Text size={16} type="bold">
        Included Primary Shares
      </Text>
      <Divider clear />
      <View>
        {primaryShares.map(({ id, name, value, minShareItems }) => (
          <View key={id}>
            <Text>
              <Text type="bold">{name}</Text>: {formatMoney(value)}{' '}
              {minShareItems !== undefined ? `(Min Share Size: ${minShareItems})` : ''}
            </Text>
          </View>
        ))}
      </View>
    </AdminCard>
  )
}
